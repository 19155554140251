export default [
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 Nh5',
    'Re1 Nf4',
    'Bf1 f5',
    'Bxf4 exf4',
    'e5 h6',
    'Qd2 g5',
    'exd6 cxd6',
    'Nd4 Ng6',
    'Rad1 Ne5',
    'c5 a5',
    'a3 axb4',
    'axb4 Qf6',
    'Ncb5 dxc5',
    'bxc5 Bd7',
    'Nc7 Rac8',
    'd6 b6',
    'Nd5 Qf7',
    'Ne7+ Kh8',
    'Nxc8 bxc5',
    'Nf3 Nxf3+',
    'gxf3 Bc6',
    'Ne7 Bxf3',
    'Be2 Bb7',
    'Qd3 g4',
    'Qxf5 Qxf5',
    'Nxf5 Rxf5',
    'd7 Bf6',
    'd8=Q+ Bxd8',
    'Rxd8+ Kg7',
    'Rd7+ Rf7',
    'Rxf7+ Kxf7',
    'Bxg4 c4',
    'Rc1 Bd5',
    'Be2 Kf6',
    'Bxc4 Be4',
    'Re1 Bf5',
    'f3 h5',
    'h4 Kg6',
    'Kg2 Kf6',
    'Re8 Bd7',
    'Rh8 Kg6',
    'Bd3+ Kf6',
    'Rxh5 Kg7',
    'Rg5+ Kf6',
    'Be4 Bc8',
    'Rg4 Ke5',
    'Rg8 Bd7',
    'h5 Kf6',
    'h6 Be6',
    'h7 Bxg8',
    'hxg8=Q Ke7',
    'Qg4 Kf6',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 dxe4',
    'Nxe4 Be7',
    'Bxf6 Bxf6',
    'c3 Nc6',
    'Nf3 e5',
    'Nxf6+ Qxf6',
    'dxe5 Nxe5',
    'Qe2 O-O',
    'Nxe5 Re8',
    'O-O-O h6',
    'Re1 Qf4+',
    'Qe3 Qf5',
    'Bd3 Qe6',
    'Bc4 Qf5',
    'Bxf7+ Kf8',
    'Ng6+ Kxf7',
    'Qxe8+ Kf6',
    'Re5 Qxe5',
    'Nxe5 b6',
    'Qc6+ Kxe5',
    'Re1+ Kf4',
    'g3+ Kg4',
    'Re4+ Kf3',
    'Rf4+ Ke2',
    'Qf3+ Kf1',
    'Re4 Bb7',
    'Qh1+ Kxf2',
    'Rf4+ Ke2',
    'Qd1+ Ke3',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 Nf6',
    'Nf3 Bg4',
    'Bc4 Nc6',
    'Bb5 e6',
    'Bxc6+ bxc6',
    'h3 Bh5',
    'O-O Be7',
    'Re1 Qf5',
    'Re3 Bg6',
    'Ne1 Bd6',
    'Bd2 Nd5',
    'Nxd5 cxd5',
    'c4 O-O',
    'Rf3 Qh5',
    'c5 Be4',
    'cxd6 Bxf3',
    'Nxf3 cxd6',
    'Bf4 Rfd8',
    'Qe2 Qf5',
    'Qd2 Rac8',
    'Rc1 Rc4',
    'b3 Rxc1+',
    'Qxc1 f6',
    'Bxd6 h6',
    'Bg3 g5',
    'Qc7 Re8',
    'Qxa7 h5',
    'Kh2 h4',
    'Bd6 e5',
    'dxe5 Qf4+',
    'Kh1 fxe5',
    'Qd7 Ra8',
    'Bxe5 Qe4',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd3 d6',
    'g3 Nc6',
    'Bg2 Nge7',
    'O-O g6',
    'Nbd2 Bg7',
    'Re1 O-O',
    'a4 b6',
    'h4 h5',
    'c3 d5',
    'e5 f6',
    'exf6 Bxf6',
    'Nf1 Nf5',
    'Bg5 Kg7',
    'Bxf6+ Qxf6',
    'Qe2 Re8',
    'Ng5 Bd7',
    'Nd2 Rad8',
    'Ndf3 e5',
    'Rac1 Re7',
    'c4 Ncd4',
    'Nxd4 Nxd4',
    'Qf1 Bc6',
    'cxd5 Bxd5',
    'Bxd5 Rxd5',
    'Re3 Red7',
    'Rc3 a6',
    'a5 b5',
    'Ne4 Qe7',
    'Qc1 c4',
    'Kg2 Nb3',
    'Qb1 Nxa5',
    'Qa2 b4',
    'dxc4 bxc3',
    'cxd5 c2',
    'Rc3 Qb4',
    'Nc5 Qxc3',
    'bxc3 c1=Q',
    'Nxd7 Qxc3',
    'Nxe5 Qxe5',
    'Qxa5 Qe4+',
    'Kf1 Qh1+',
    'Ke2 Qe4+',
    'Kd1 Qh1+',
    'Kd2 Qg2',
    'Ke3 Qf1',
    'Qc7+ Kh6',
    'd6 Qe1+',
    'Kd3 Qd1+',
    'Ke4 Qe2+',
    'Kd5 Qxf2',
    'd7 Qg2+',
    'Kc5 Qf2+',
    'Kc6 Qc2+',
    'Kb7 Qb3+',
    'Kc8 Qe6',
    'Qf4+ Kh7',
    'Qf8 a5',
    'Kd8 Qc6',
    'Qe7+ Kh6',
    'Ke8 a4',
    'Kf8 Qf3+',
    'Kg8 Qd5+',
    'Qf7 Qa8+',
    'Qf8+ Qxf8+',
    'Kxf8 a3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'b3 Bg7',
    'Bb2 O-O',
    'g3 d6',
    'Bg2 e5',
    'dxe5 Nfd7',
    'Nf3 dxe5',
    'O-O e4',
    'Bxg7 exf3',
    'Bxf8 fxg2',
    'Kxg2 Qxf8',
    'Nc3 Nf6',
    'f3 Nc6',
    'e4 Qh6',
    'Rf2 Bh3+',
    'Kg1 Qe3',
    'Qe1 Qxe1+',
    'Rxe1 Ne5',
    'Rd1 Bd7',
    'Nd5 Nxd5',
    'Rxd5 f6',
    'Kg2 Bc6',
    'Rd4 Re8',
    'h3 Kg7',
    'h4 f5',
    'Kh3 Re7',
    'b4 a6',
    'c5 h5',
    'a3 fxe4',
    'fxe4 Ng4',
    'Re2 Bxe4',
    'a4 Ne3',
    'Kh2 Bf3',
    'Rb2 Ng4+',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'f4 g6',
    'Nf3 Bg7',
    'Bb5 Nd4',
    'Bd3 d6',
    'Nxd4 cxd4',
    'Ne2 e5',
    'O-O Ne7',
    'c3 O-O',
    'cxd4 exd4',
    'Bc2 Qb6',
    'd3 d5',
    'Ng3 dxe4',
    'Nxe4 Nf5',
    'Kh1 Be6',
    'g4 Nd6',
    'h3 f5',
    'Ng5 Bd5+',
    'Kh2 Bh6',
    'Bd2 Rac8',
    'Ba4 fxg4',
    'Bd7 Bxg5',
    'Bxc8 Nxc8',
    'hxg4 Qd6',
    'Kg3 Bh6',
    'Qe1 Qa6',
    'Qe2 Nd6',
    'Qe5 Qxd3+',
    'Kh4 Qxd2',
    'Qxd5+ Rf7',
    'g5 Qh2+',
    'Kg4 Nf5',
    'Qd8+ Bf8',
    'Kf3 Qg3+',
    'Ke4 Re7+',
    'Kd5 Ne3+',
    'Kxd4 Nf5+',
    'Kc4 Re4+',
    'Kb5 Nd4+',
    'Kc4 Nc6+',
  ],
  [
    'e4 c5',
    'Nc3 e6',
    'f4 Nc6',
    'Nf3 d5',
    'Bb5 Nf6',
    'Bxc6+ bxc6',
    'e5 Nd7',
    'd3 Be7',
    'O-O a5',
    'b3 Nb6',
    'Ba3 c4',
    'Bxe7 Qxe7',
    'bxc4 dxc4',
    'Ne4 O-O',
    'd4 Nd5',
    'Qd2 Ba6',
    'c3 Qa3',
    'Ne1 Rfb8',
    'Nc2 Qb2',
    'f5 exf5',
    'Rxf5 Ne3',
    'Qxe3 Qxc2',
    'Raf1 Qxa2',
    'Nf6+ gxf6',
    'Qh6 Qa3',
    'exf6 Qf8',
    'Rg5+ Kh8',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Bf4 Bg7',
    'e3 d6',
    'h3 O-O',
    'Be2 Nbd7',
    'O-O Qe8',
    'Bh2 e5',
    'c4 Qe7',
    'Nc3 c6',
    'b4 Rd8',
    'Qb3 e4',
    'Nd2 Nb6',
    'c5 dxc5',
    'bxc5 Nbd5',
    'Nc4 Be6',
    'Qb2 Nxc3',
    'Qxc3 Bxc4',
    'Bxc4 Nd5',
    'Qb3 b5',
    'cxb6 Nxb6',
    'Rac1 Nd5',
    'Ba6 Nb4',
    'Qa4 Bf8',
    'Rc5 Rab8',
    'Bxb8 Rxb8',
    'Re5 Qh4',
    'a3 Bd6',
    'axb4 Bxe5',
    'dxe5 Qe7',
    'Qxc6 Qxe5',
    'b5 f5',
    'Rc1 Rd8',
    'Qc4+ Kh8',
    'Qf7 Qb2',
    'Qc7 Rd3',
  ],
  [
    'd4 Nf6',
    'Bg5 Ne4',
    'Bf4 c5',
    'd5 Qb6',
    'Bc1 e6',
    'f3 Nf6',
    'c4 Qb4+',
    'Bd2 Qxc4',
    'e4 Qd4',
    'Nc3 exd5',
    'Nge2 Qe5',
    'Bf4 Qe6',
    'exd5 Qb6',
    'Ng3 d6',
    'Bb5+ Bd7',
    'Qe2+ Kd8',
    'O-O-O a6',
    'Bd3 Be7',
    'Nf5 Bxf5',
    'Bxf5 Nbd7',
    'Rd3 Re8',
    'Re3 Qc7',
    'Bg5 c4',
    'Re1 Ne5',
    'Rxe5 dxe5',
    'd6 Bxd6',
    'Rd1 e4',
    'Bf4 Ke7',
    'Bxd6+ Qxd6',
    'Rxd6 Kxd6',
    'Nxe4+ Nxe4',
    'Bxe4 Rab8',
    'Qd2+ Ke7',
    'Qb4+ Kd7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 Nc6',
    'Bg5 e6',
    'Qd2 a6',
    'O-O-O Bd7',
    'f4 b5',
    'Nxc6 Bxc6',
    'Qd4 Be7',
    'Be2 Rb8',
    'Bf3 Qb6',
    'Bxf6 gxf6',
    'Rhe1 Qxd4',
    'Rxd4 Kd7',
    'Red1 Kc7',
    'Bg4 a5',
    'Re1 h5',
    'Bh3 Bf8',
    'f5 b4',
    'Nd1 e5',
    'Rd3 Bh6+',
    'Kb1 Rb5',
    'c4 Rc5',
    'b3 a4',
    'Nb2 axb3',
    'axb3 Ra8',
    'Kc2 h4',
    'Bg4 Bf4',
    'h3 Bg3',
    'Re2 Ra1',
    'Rd1 Rca5',
    'Bf3 Rxd1',
    'Nxd1 Ra1',
    'Ne3 Kb6',
    'Rd2 Kc5',
    'Nd5 Bf2',
    'Kb2 Rf1',
    'Rd1 Bd4+',
    'Kc2 Rf2+',
    'Rd2 Rf1',
    'Nxf6 Ra1',
    'Rd3 Ra2+',
    'Kc1 Bc3',
    'Nd5 Bxd5',
    'Rxd5+ Kc6',
    'Bh5 f6',
    'Be8+ Kc7',
    'c5 dxc5',
    'Rxc5+ Kd8',
    'Bh5 Rxg2',
    'Rc6 Rg1+',
    'Kc2 Rg2+',
    'Kd3 Rg3+',
    'Kc4 Rxh3',
    'Rxf6 Rg3',
    'Rg6 Ke7',
    'Re6+ Kd8',
    'f6 Bd4',
    'f7 Rc3+',
  ],
  [
    'Nf3 Nf6',
    'g3 g6',
    'Bg2 d6',
    'O-O Bg7',
    'c4 O-O',
    'Nc3 Nc6',
    'Rb1 Bg4',
    'b4 Qc8',
    'd3 h5',
    'Bg5 Nh7',
    'Bd2 e5',
    'Nd5 Qd7',
    'Nh4 Rac8',
    'f3 Be6',
    'f4 exf4',
    'gxf4 Bg4',
    'Qe1 Nd4',
    'Nc3 Bf6',
    'e3 Rce8',
    'Ne4 Qe7',
    'Nxf6+ Qxf6',
    'Qf2 Re7',
    'Rbe1 Nc2',
    'Rc1 Qb2',
    'Bxb7 Nd4',
    'Rfe1 Nc2',
    'Bd5 Kg7',
    'Rf1 Bh3',
    'Bg2 Bd7',
    'f5 g5',
    'Rxc2 Qxc2',
    'f6+ Kg8',
    'fxe7 f6',
    'exf8=Q+ Nxf8',
  ],
  [
    'e4 Nc6',
    'd4 e5',
    'Nf3 exd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Be2 Bd7',
    'Be3 Be7',
    'f3 a6',
    'f4 Qc8',
    'h3 b5',
    'Bf3 Nxd4',
    'Bxd4 O-O',
    'e5 Ne8',
    'Bxa8 Bh4+',
    'Bf2 Bxf2+',
    'Kxf2 Qxa8',
    'Re1 Bc6',
    'Qg4 f5',
    'Qg3 g6',
    'Kg1 Ng7',
    'Rad1 dxe5',
    'Rxe5 Nh5',
    'Qf2 Nf6',
    'a3 Rf7',
    'Re6 Nh5',
    'Rde1 Nf6',
    'Qd4 Ne4',
    'Nxe4 fxe4',
    'Qc5 Bd7',
    'R6xe4 Qb7',
    'Re8+ Bxe8',
    'Rxe8+ Kg7',
    'Qe5+ Kh6',
    'Rg8 Qb6+',
    'Kh2 Qf6',
    'Qxf6 Rxf6',
    'Kg3 a5',
    'Kf3 Rc6',
    'g4 Rxc2',
    'g5+ Kh5',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Bxf6 gxf6',
    'Na3 b5',
    'Nd5 Be7',
    'g3 Be6',
    'Bg2 Rc8',
    'c3 O-O',
    'Nc2 f5',
    'f3 Rb8',
    'O-O Qd7',
    'Nce3 Kh8',
    'Kh1 fxe4',
    'fxe4 Rg8',
    'Nf5 Bxf5',
    'Rxf5 Rbf8',
    'Qh5 Rg6',
    'Raf1 Bd8',
    'Bh3 Qe8',
    'Nf6 Bxf6',
    'Rxf6 Ne7',
    'R6f2 a5',
    'Qh4 Ng8',
    'Rf5 f6',
    'Rh5 Rg7',
    'Bf5 Rff7',
    'Qh3 Re7',
    'Rd1 Qc6',
    'Qg2 Rc7',
    'Qh3 b4',
    'Qf1 bxc3',
    'bxc3 Qxc3',
    'Rxd6 Qb2',
    'Be6 Qb4',
    'Qd3 Qe1+',
    'Kg2 Rc3',
    'Qxc3 Qxc3',
    'Bxg8 Kxg8',
  ],
  [
    'g3 g6',
    'f4 Bg7',
    'Bg2 d6',
    'e4 c5',
    'd3 Nc6',
    'Nf3 e5',
    'c3 Nge7',
    'Be3 h6',
    'fxe5 Nxe5',
    'Nxe5 Bxe5',
    'd4 cxd4',
    'cxd4 Bg7',
    'Qd2 d5',
    'exd5 Nxd5',
    'Nc3 Be6',
    'Nxd5 Bxd5',
    'O-O O-O',
    'Bxd5 Qxd5',
    'Bxh6 Qxd4+',
    'Qxd4 Bxd4+',
    'Kg2 Rfe8',
    'Kf3 Rac8',
    'Rac1 Bxb2',
    'Rxc8 Rxc8',
    'Rf2 Ba3',
    'Ke4 b5',
    'Bg5 a5',
    'Bf6 b4',
    'Rd2 Bc1',
    'Rd8+ Rxd8',
    'Bxd8 a4',
    'Kd3 Kf8',
    'Kc4 b3',
    'axb3 Ke8',
    'Bb6 axb3',
    'Kxb3 Ke7',
    'Kc4 Kf6',
    'Kd3 Kf5',
    'h3 Ba3',
    'Bc7 Bc5',
    'g4+ Kg5',
    'Bg3 Ba3',
    'Ke4 Bc1',
    'Kf3 Bb2',
    'Ke4 Bc3',
    'Kf3 f6',
    'Bf2 Kh6',
    'Be3+ Kg7',
    'Bf2 Kf7',
    'Ke4 Ke6',
    'Bg3 Bb2',
    'Bf2 Be5',
    'Be1 Kd6',
    'Bb4+ Ke6',
    'Be1 Bb2',
    'Bg3 Bc1',
    'Bf2 f5+',
    'gxf5+ gxf5+',
    'Kf3 Kf6',
    'Be3 Bb2',
    'Bd2 Kg6',
    'Be3 Be5',
    'Bf4 Bg7',
    'Be3 Bf8',
    'Bd2 Bd6',
    'Bf4 Bb4',
    'Be3 Bc3',
    'Bc1 Kh5',
    'Kf4 Kg6',
    'Be3 Bf6',
    'Kf3 Bh4',
    'Bf4 Kh5',
    'Be3 Bf6',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'f4 g6',
    'Nf3 Bg7',
    'Bc4 d6',
    'Bb5 Bd7',
    'Bxc6 Bxc6',
    'd3 e6',
    'O-O Ne7',
    'Qe1 O-O',
    'Qh4 b5',
    'f5 exf5',
    'Bh6 b4',
    'Ne2 f6',
    'Nf4 Qd7',
    'Rae1 fxe4',
    'dxe4 Rae8',
    'Bxg7 Kxg7',
    'Qg3 Kh8',
    'Rd1 Nc8',
    'e5 Bxf3',
    'e6 Qg7',
    'Qxf3 g5',
    'Nd5 Rxe6',
    'Qg4 Rfe8',
    'Rd3 Nb6',
    'Nxb6 axb6',
    'Qg3 Qe7',
    'Rdf3 Kg7',
    'Rd3 Re1',
    'Rxd6 Rxf1+',
    'Kxf1 Qe2+',
    'Kg1 Qxc2',
    'h4 Qb1+',
    'Kh2 Re1',
    'Rd7+ Kg6',
    'h5+ Kh6',
    'Qf2 Rh1+',
    'Kg3 Rf1',
    'Qe2 Qf5',
    'Rxh7+ Qxh7',
    'Qxf1 Qc7+',
    'Kh3 Kxh5',
    'g4+ Kg6',
    'Qf5+ Kg7',
    'b3 b5',
    'Qd5 c4',
    'Qxb5 c3',
    'Qf1 c2',
    'Qc1 Qc3+',
    'Kg2 Qd3',
    'Qe1 Kf7',
    'Qg1 Qd1',
    'Qa7+ Kg6',
    'Qa8 Qxg4+',
    'Kh2 Qf4+',
    'Kg2 c1=Q',
    'Qg8+ Kf5',
    'Qh7+ Ke5',
    'Qe7+ Kd5',
    'Qf7+ Kd4',
    'Qd7+ Ke3',
    'Qa7+ Qd4',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Nxc6 bxc6',
    'Bc4 Bb4',
    'Bg5 Bxc3+',
    'bxc3 Qa5',
    'Qf3 d5',
    'Bxf6 Bg4',
    'Qg3 gxf6',
    'O-O Be6',
    'exd5 cxd5',
    'Bd3 Ke7',
    'Qe3 Rhb8',
    'Rab1 Qc7',
    'Be2 a5',
    'Rfd1 a4',
    'c4 d4',
    'Qa3+ Qd6',
    'c5 Qc6',
    'Rb6 Rxb6',
    'cxb6+ Qd6',
    'b7 Rb8',
    'Qxa4 Rxb7',
    'Qa8 Rc7',
    'Bd3 Bd5',
    'Qb8 Qc6',
    'Bb5 Qxc2',
    'Qe8+ Kd6',
    'Ba4 Qc5',
    'h3 Re7',
    'Qd8+ Ke6',
    'Rb1 Rb7',
    'Rxb7 Qc1+',
    'Kh2 Qf4+',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'f4 g6',
    'Nf3 Bg7',
    'Bb5 Nd4',
    'a4 a6',
    'Bc4 e6',
    'e5 d5',
    'exd6 Nh6',
    'a5 Nhf5',
    'Ne4 Nxd6',
    'd3 Nxc4',
    'dxc4 Qc7',
    'c3 Nxf3+',
    'Qxf3 f5',
    'Nf2 O-O',
    'Nd3 Re8',
    'O-O Bd7',
    'Qf2 Rac8',
    'Be3 Bf8',
    'Qg3 Bc6',
    'Ne5 Be4',
    'Rfd1 Red8',
    'h4 Rxd1+',
    'Rxd1 Rd8',
    'Ra1 Qg7',
    'Kf2 Be7',
    'Rh1 Kf8',
    'Rh3 Rd1',
    'Rh2 Rb1',
    'Qh3 Rxb2+',
    'Ke1 Ra2',
    'Bd2 Rxa5',
    'h5 gxh5',
    'Qxh5 Bf6',
    'Rh3 Ra1+',
    'Ke2 Qxg2+',
    'Ke3 Qg1+',
    'Ke2 Qf1+',
    'Ke3 Re1+',
    'Bxe1 Qxe1+',
    'Qe2 Qxc3+',
    'Kf2 Qxh3',
    'Nd7+ Ke7',
    'Nxf6 Qh2+',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd3 d6',
    'g3 Nc6',
    'Bg2 Nge7',
    'O-O g6',
    'Re1 Bg7',
    'c3 O-O',
    'a4 a6',
    'Nbd2 Rb8',
    'Nf1 b5',
    'axb5 Rxb5',
    'h4 h5',
    'Qe2 Qc7',
    'N1d2 Rd8',
    'Nc4 a5',
    'Bg5 Re8',
    'Bh3 Ba6',
    'Bf4 e5',
    'Bc1 Rb7',
    'Ne3 Qd8',
    'Ng5 Rf8',
    'Nf5 d5',
    'Qf3 d4',
    'c4 Qc7',
    'Kh1 Nc8',
    'Nxg7 Kxg7',
    'Be6 Ra7',
    'Bd5 Nd8',
    'Rg1 Bb7',
    'g4 hxg4',
    'Qxg4 Ra6',
    'Rg2 Ne7',
    'h5 Rh8',
    'Kg1 a4',
    'h6+ Kf8',
    'Rh2 Bxd5',
    'exd5 f6',
    'Nf3 Nf7',
    'Kh1 g5',
    'h7 f5',
    'Qh5 Ra8',
    'Qh6+ Nxh6',
    'Rxh6 g4',
    'Ng5 Qa5',
    'Ne6+ Kf7',
    'Bg5 Ng6',
    'Kg2 Rhb8',
    'Rah1 Rh8',
    'Rxg6 Kxg6',
    'Rh6+ Kf7',
    'Rf6+ Ke8',
    'Rf8+ Rxf8',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'f4 g6',
    'Nf3 Bg7',
    'Bb5 Nd4',
    'a4 a6',
    'Bc4 e6',
    'e5 d5',
    'exd6 Qxd6',
    'd3 Ne7',
    'Ne4 Qc7',
    'c3 Nxf3+',
    'Qxf3 O-O',
    'Be3 b6',
    'Nxc5 bxc5',
    'Qxa8 Bb7',
    'Qa7 Qc6',
    'Qxc5 Qxg2',
    'O-O-O Nf5',
    'Rhg1 Qxh2',
    'Bd2 h5',
    'Qb6 Bf3',
    'Rde1 Ng3',
    'Kc2 h4',
    'Qe3 Bb7',
    'Qb6 Bf3',
    'Qe3 Bb7',
    'Qb6 Bf3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'Nf3 O-O',
    'g3 d6',
    'Bg2 Nc6',
    'd5 Na5',
    'Nd2 c5',
    'O-O a6',
    'Qc2 Rb8',
    'b3 b5',
    'Bb2 e5',
    'Rab1 Nh5',
    'Qd3 Bh6',
    'Rfd1 f5',
    'e3 Bd7',
    'Ba1 Bg7',
    'cxb5 e4',
    'Qe2 Bxb5',
    'Nxb5 Rxb5',
    'Bxg7 Kxg7',
    'Nc4 Rf7',
    'Na3 Rb6',
    'Qd2 Rb8',
    'Bf1 Rc7',
    'b4 Nb7',
    'Qb2+ Qf6',
    'bxc5 dxc5',
    'Qb6 Ra8',
    'Qxc7+ Qf7',
    'd6 Nf6',
    'd7 Rd8',
    'Bc4 Qe7',
    'Rxb7 h5',
    'Qd6 Kf8',
    'Rb6 Qxd6',
    'Rbxd6 Kg7',
    'Re6 Rxd7',
    'Rxd7+ Kh6',
    'Rf7 Ng4',
    'Rd6 Kg5',
    'h3 Nh6',
    'h4+ Kg4',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Bb5 Bd7',
    'Bxc6 Bxc6',
    'Nxc6 bxc6',
    'O-O Be7',
    'Be3 O-O',
    'f4 Qd7',
    'h3 Rfb8',
    'Rb1 Bf8',
    'Bd4 Ne8',
    'Rf3 c5',
    'Bxc5 Qe6',
    'Bf2 Rb7',
    'f5 Qc4',
    'b3 Qc6',
    'Bd4 Qd7',
    'Nd5 c6',
    'Nf4 Qe7',
    'Qe1 Nf6',
    'Re3 Re8',
    'Qh4 Nxe4',
    'f6 Qd7',
    'fxg7 Be7',
    'Qe1 d5',
    'Nd3 Bg5',
    'Re2 Qe7',
    'Nc5 Bh4',
    'Qd1 Rb5',
    'Nxe4 dxe4',
    'c4 Rf5',
    'Qc2 c5',
    'Be3 Bf6',
    'a3 Kxg7',
    'b4 Bd4',
    'Rbe1 Re5',
    'Kh1 Bxe3',
    'Rxe3 f5',
    'Qc3 Kf7',
    'Rd1 f4',
    'Ree1 e3',
    'Rf1 Rf5',
    'Qc2 Qe4',
    'Qe2 Rg5',
    'bxc5 Reg8',
    'Qf3 Qxf3',
    'Rxf3 Rf5',
    'Rdf1 e2',
    'Re1 Re8',
    'Rf2 Re4',
    'Rfxe2 Rxc4',
    'Re7+ Kg6',
    'R1e6+ Rf6',
    'Rxf6+ Kxf6',
    'Rxh7 Rxc5',
    'Rxa7 Rc2',
    'Kg1 Rc1+',
    'Kf2 Kf5',
    'Re7 Rc2+',
    'Re2 Rc3',
    'Ra2 Ke5',
    'a4 Kd6',
    'a5 Kc7',
    'Ra4 Rc5',
    'a6 Kb8',
    'a7+ Ka8',
    'Rxf4 Kxa7',
    'Rb4 Rf5+',
    'Kg3 Rg5+',
  ],
  [
    'e4 c5',
    'Bc4 Nc6',
    'c3 g6',
    'f4 Bg7',
    'Nf3 d6',
    'd4 cxd4',
    'cxd4 Qb6',
    'e5 dxe5',
    'fxe5 Bg4',
    'Bxf7+ Kxf7',
    'Ng5+ Ke8',
    'Qxg4 Nxd4',
    'O-O Nh6',
    'Qh3 Bxe5',
    'Kh1 Nhf5',
    'Nc3 Nc2',
    'Rb1 Rd8',
    'Bf4 Bxf4',
    'Rxf4 Qe3',
    'Qxe3 Nfxe3',
    'Rf3 Rd2',
    'Nge4 Rd4',
    'Nc5 b6',
    'Nb3 Rg4',
    'Na1 Rf8',
    'Nxc2 Nxc2',
    'Rc1 Nd4',
    'Rxf8+ Kxf8',
    'Rf1+ Ke8',
    'h3 Rg5',
    'Rf4 Nc6',
    'b4 Rf5',
    'Rh4 h5',
    'b5 Rc5',
    'Ne2 Rxb5',
    'Rc4 Rc5',
    'Re4 Re5',
    'Rc4 Rxe2',
    'Rxc6 Kf7',
    'a4 Re4',
    'a5 bxa5',
    'Ra6 a4',
    'Rxa7 Kf6',
    'Kh2 Rb4',
    'Kg3 Rb3+',
    'Kf4 a3',
    'g4 Rxh3',
    'Ra6+ Kf7',
    'gxh5 gxh5',
    'Kg5 Ke8',
    'Ra8+ Kd7',
    'Ra7+ Kd6',
    'Kf5 e6+',
    'Kg5 Kc5',
    'Kf6 h4',
    'Kxe6 Re3+',
    'Kf5 h3',
    'Kf4 Rb3',
  ],
  [
    'd4 d6',
    'e4 g6',
    'Nc3 Bg7',
    'f4 Nf6',
    'Nf3 c5',
    'Bb5+ Bd7',
    'e5 Ng4',
    'e6 Bxb5',
    'exf7+ Kd7',
    'Nxb5 Qa5+',
    'Nc3 cxd4',
    'Nxd4 Bxd4',
    'Qxd4 Nc6',
    'Qc4 Qb6',
    'Qe2 h5',
    'Bd2 Raf8',
    'h3 Nh6',
    'O-O-O Nxf7',
    'Nd5 Qa6',
    'Qxa6 bxa6',
    'Bc3 Rh7',
    'Rhe1 Nfd8',
    'Re4 Rhf7',
    'Bd2 Rf5',
    'Be3 a5',
    'c4 Nb7',
    'g3 R5f7',
    'Nc3 Kc8',
    'b3 Kb8',
    'Nb5 Rf6',
    'Nd4 Nxd4',
    'Bxd4 R6f7',
    'Rde1 Re8',
    'Re6 h4',
    'Rxg6 hxg3',
    'Re4 Ref8',
    'Rxg3 Rxf4',
    'Rxf4 Rxf4',
    'Be3 Rh4',
    'Rg8+ Kc7',
    'Rg7 Rxh3',
    'Rxe7+ Kc6',
    'Bxa7 Rh2',
    'Kb1 Nc5',
    'Bxc5 Kxc5',
    'Re1 Kb4',
  ],
  [
    'f4 Nf6',
    'Nf3 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'O-O d6',
    'd3 c6',
    'c3 Nbd7',
    'e4 e5',
    'Qe2 Re8',
    'Na3 Qc7',
    'Kh1 a6',
    'fxe5 dxe5',
    'Be3 b5',
    'Nd2 c5',
    'Rac1 Nf8',
    'Nb3 Bg4',
    'Bf3 Bxf3+',
    'Rxf3 N6d7',
    'Qf2 Ne6',
    'Rxf7 Qd6',
    'Rd1 c4',
    'Nc1 Ndc5',
    'Rf1 Nxd3',
    'Nxd3 Qxd3',
    'Rxg7+ Nxg7',
    'Qf7+ Kh8',
    'Bh6 Qxe4+',
    'Kg1 Rg8',
    'Rd1 g5',
    'Rd7 Qg6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be2 e5',
    'Nb3 Be7',
    'O-O O-O',
    'Kh1 Be6',
    'f4 exf4',
    'Bxf4 Nc6',
    'Qe1 Ne5',
    'Rd1 Qc7',
    'Qg3 Kh8',
    'Nd4 b5',
    'a3 Rac8',
    'Bg5 Ned7',
    'Qh4 Rce8',
    'Bd3 h6',
    'e5 dxe5',
    'Bxh6 e4',
    'Nxe4 Nh7',
    'Ng5 Ndf6',
    'Bxg7+ Kg8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 Nf6',
    'Nc3 cxd4',
    'Nxd4 Nc6',
    'Bg5 e6',
    'Qd2 Be7',
    'O-O-O O-O',
    'f4 Nxd4',
    'Qxd4 Qa5',
    'Kb1 Rd8',
    'Be2 h6',
    'Bh4 e5',
    'Qf2 exf4',
    'Nd5 Nxd5',
    'Rxd5 Bxh4',
    'Qxh4 Qc7',
    'Rhd1 Be6',
    'R5d4 Qa5',
    'b3 Qe5',
    'g3 fxg3',
    'Qxg3 Rdc8',
    'Rxd6 Qxe4',
    'Bd3 Qg4',
    'Qxg4 Bxg4',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'O-O Nc6',
    'Be3 exd4',
    'Nxd4 Re8',
    'f3 Nh5',
    'f4 Nf6',
    'Nxc6 bxc6',
    'Bf3 Rb8',
    'b3 Ng4',
    'Bd4 Ne3',
    'Bxe3 Bxc3',
    'Rc1 Bg7',
    'Qd2 c5',
    'Rcd1 Bb7',
    'e5 Bxf3',
    'Rxf3 dxe5',
    'Bxc5 e4',
    'Re3 Qxd2',
    'Rxd2 f5',
    'Bxa7 Rbd8',
    'Ree2 Bc3',
    'Rxd8 Rxd8',
    'Be3 Kf7',
    'Kf2 Ke6',
    'Rc2 Rd3',
    'Ke2 h6',
    'Rc1 Bb4',
    'Rc2 Bc3',
    'Bd2 Bf6',
    'b4 Ra3',
    'Bc1 Ra4',
    'a3 Be7',
    'Rc3 Bf6',
    'Rh3 Bg7',
    'Rg3 Kf7',
    'Kd1 c5',
    'Rb3 cxb4',
    'axb4 Bd4',
    'Bb2 Bg1',
    'h3 g5',
    'g3 Ke6',
    'b5 Ra7',
    'Be5 Bb6',
    'Rb2 e3',
    'Ke2 h5',
    'c5 Bxc5',
    'b6 Rb7',
    'Rb5 Be7',
    'Kxe3 h4',
    'gxh4 gxh4',
    'Bc7 Bf6',
    'Rc5 Be7',
    'Re5+ Kf6',
    'Rxe7 Kxe7',
    'Kd4 Ke6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e5',
    'Nb3 Qc7',
    'f3 Be6',
    'Qd2 b5',
    'O-O-O Nbd7',
    'g4 h6',
    'h4 b4',
    'Nd5 Bxd5',
    'exd5 a5',
    'Kb1 a4',
    'Nc1 Ra5',
    'Qxb4 Nxd5',
    'Rxd5 Rxd5',
    'Bb5 a3',
    'Qa4 Rxb5',
    'Qxb5 Be7',
    'Ne2 O-O',
    'b3 Rc8',
    'c4 Rb8',
    'Qd5 Nb6',
    'Bxb6 Qxb6',
    'Qe4 Qf2',
    'h5 Bg5',
    'Kc2 d5',
    'Qd3 Rb4',
    'Rb1 dxc4',
    'bxc4 Rxb1',
    'Kxb1 Be3',
    'Qd8+ Kh7',
    'Qd3+ g6',
    'hxg6+ Kg7',
    'gxf7 Qxf3',
    'f8=Q+ Kxf8',
    'Qxa3+ Kf7',
    'Qd3 Qf1+',
    'Kc2 Bf4',
    'c5 Qg2',
    'a4 Qxg4',
    'Nc3 Qg2+',
    'Kb3 Qb7+',
    'Nb5 Qc6',
    'Nd6+ Ke6',
    'Kb4 Qg2',
    'Qf5+ Kd5',
    'Qf7+ Kd4',
    'Nf5+ Kd3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Bg5 c5',
    'd5 a6',
    'Qc1 Qa5',
    'Bd2 e6',
    'Bd3 exd5',
    'exd5 Nbd7',
    'f4 Ne8',
    'Nf3 f5',
    'O-O Qd8',
    'Ng5 Nc7',
    'Re1 Nf6',
    'h3 Bd7',
    'Qc2 Rb8',
    'a4 Re8',
    'a5 b6',
    'axb6 Rxb6',
    'Rxe8+ Qxe8',
    'Re1 Qb8',
    'Nd1 h6',
    'Nf3 Nce8',
    'Nh4 Kf7',
    'g4 fxg4',
    'Bxg6+ Kf8',
    'Bh7 Ng8',
    'Ng6+ Kf7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'Bd2 Ne7',
    'Nb5 Bxd2+',
    'Qxd2 O-O',
    'c3 Nbc6',
    'f4 cxd4',
    'cxd4 Nf5',
    'Nf3 a6',
    'Nc3 f6',
    'Rd1 fxe5',
    'fxe5 Bd7',
    'Ne2 Qa5',
    'Nc3 Qd8',
    'Ne2 Rc8',
    'Ng3 Nh4',
    'Ng1 Qb6',
    'a3 Na5',
    'Nh3 Ba4',
    'Qb4 Qxb4+',
    'axb4 Bxd1',
    'bxa5 Ba4',
    'Bd3 Rc1+',
    'Kd2 Rxh1',
    'Nxh1 h6',
    'g3 Nf3+',
    'Ke3 Nxh2',
    'Nf4 Ng4+',
    'Kd2 Bd7',
    'Be2 h5',
    'Nxh5 Nf2',
    'Nxf2 Rxf2',
    'Ke3 Rh2',
    'Nf4 g5',
    'Nh5 Kf8',
    'Nf6 Ke7',
    'b3 Bb5',
    'Bd1 Ra2',
    'Nh7 Rg2',
    'Nxg5 Rxg3+',
    'Nf3 Rg2',
    'Nd2 Rg1',
    'Bc2 Re1+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'Be3 c6',
    'dxe5 dxe5',
    'Qc2 Qe7',
    'O-O h6',
    'Nd2 Be6',
    'c5 Nbd7',
    'Na4 Ne8',
    'f3 f5',
    'Bc4 Nc7',
    'Rad1 Kh8',
    'b4 f4',
    'Bf2 g5',
    'Nb3 Nf6',
    'Nb2 g4',
    'Bxe6 Nxe6',
    'Bh4 gxf3',
    'gxf3 Qf7',
    'Nc4 Qh5',
    'Qf2 Qg6+',
    'Qg2 Qh5',
    'Qf2 Nh7',
    'Nd6 Bf6',
    'Bxf6+ Rxf6',
    'Nf5 Nhg5',
    'Kh1 Rg8',
    'Qh4 Qxh4',
    'Nxh4 Nh3',
    'Rd7 Rg5',
    'Rxb7 Rf8',
    'Rd7 Rfg8',
    'Rfd1 Rg1+',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 d6',
    'Be3 e6',
    'Qd2 Nge7',
    'Bh6 Bxh6',
    'Qxh6 Nd4',
    'Rc1 Qc7',
    'Qg7 Rf8',
    'Qxh7 Qb6',
    'Nd1 Bd7',
    'c3 Ndc6',
    'Nf3 O-O-O',
    'Qh6 Ne5',
    'Nxe5 dxe5',
    'd4 Kb8',
    'dxc5 Qxc5',
    'O-O Rh8',
    'Qe3 Qc7',
    'f4 Nc6',
    'Nf2 exf4',
    'gxf4 g5',
    'Nd3 Rdg8',
    'e5 gxf4',
    'Rxf4 Ne7',
    'Qc5 Bc6',
    'Rf2 Nf5',
    'Nb4 Be4',
    'Qxc7+ Kxc7',
    'Re1 Bxg2',
    'Rxg2 Nh4',
    'Rxg8 Rxg8+',
    'Kf1 Nf3',
    'Re2 Rh8',
    'Kg2 Nh4+',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Bg5 e6',
    'Qd2 a6',
    'O-O-O Bd7',
    'f4 Be7',
    'Nf3 b5',
    'e5 b4',
    'exf6 bxc3',
    'Qxc3 gxf6',
    'Rxd6 Bxd6',
    'Bxf6 Bb4',
    'Bxh8 Bxc3',
    'Bxc3 Qb6',
    'a3 Rc8',
    'Kb1 Na5',
    'Bd4 Qb7',
    'Bd3 Nc4',
    'Ka2 Qd5',
    'b3 Bb5',
    'a4 Bxa4',
    'Bxc4 Rxc4',
    'bxc4 Qxc4+',
    'Kb2 Bxc2',
    'Re1 Qb3+',
    'Kc1 Bf5',
    'Re2 Qb1+',
    'Kd2 Qd3+',
    'Ke1 Bg4',
    'Rd2 Qe4+',
    'Kf2 Bxf3',
    'Bf6 Qd5',
    'Rxd5 Bxd5',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'f4 g6',
    'Nf3 Bg7',
    'Bb5 Nd4',
    'a4 Nh6',
    'O-O O-O',
    'Bd3 d6',
    'h3 f5',
    'Bc4+ e6',
    'd3 Kh8',
    'Nb5 d5',
    'Nbxd4 dxc4',
    'Ne2 cxd3',
    'cxd3 c4',
    'dxc4 fxe4',
    'Ng5 Qc7',
    'Qc2 e5',
    'Nxe4 Be6',
    'b3 exf4',
    'Bb2 f3',
    'Nd4 Bf5',
    'Nxf3 Rae8',
    'Rae1 Bxe4',
    'Rxe4 Nf5',
    'Rfe1 Qc5+',
    'Kh2 Qd6+',
    'Kg1 Qc5+',
    'Qf2 Qxf2+',
    'Kxf2 Rxe4',
    'Bxg7+ Kxg7',
    'Rxe4 Rd8',
    'g4 Nd6',
    'Re7+ Kf6',
    'Rxh7 Ne4+',
    'Ke3 Nc5',
    'g5+ Ke6',
    'Nd4+ Kd6',
    'b4 Nxa4',
    'Rxb7 Nb6',
    'c5+ Kd5',
    'cxb6 axb6',
    'Rxb6 Kc4',
    'Rxg6 Rxd4',
    'Rc6+ Kd5',
  ],
  [
    'c4 e5',
    'g3 f5',
    'Bg2 Nc6',
    'Nc3 Nf6',
    'a3 g6',
    'e3 Bg7',
    'Nge2 O-O',
    'O-O d6',
    'd3 a5',
    'Rb1 Be6',
    'Nd5 Qd7',
    'b4 axb4',
    'axb4 e4',
    'Nef4 Rac8',
    'Bb2 Ne5',
    'Nxf6+ Bxf6',
    'Nxe6 Qxe6',
    'dxe4 c6',
    'exf5 gxf5',
    'c5 d5',
    'Bd4 Kh8',
    'b5 Nd7',
    'bxc6 bxc6',
    'Re1 Rb8',
    'Rxb8 Rxb8',
    'Bh3 Rf8',
    'Qh5 Be5',
    'Ra1 h6',
    'Ra7 Bxd4',
    'exd4 Qe1+',
    'Bf1 Qe6',
    'Qe2 Qf7',
    'Bh3 Qg7',
    'Rc7 Qf7',
    'Rxc6 Qg7',
    'Rc7 Rf7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Bc5',
    'Be3 Qf6',
    'c3 Nge7',
    'Bc4 O-O',
    'O-O Ne5',
    'Be2 d6',
    'f4 N5g6',
    'b4 Bb6',
    'Na3 Nc6',
    'Nc4 Bxd4',
    'cxd4 Qe7',
    'b5 Nd8',
    'f5 Nh8',
    'Bd3 f6',
    'Rf3 g6',
    'Bh6 Re8',
    'Ne3 c6',
    'bxc6 bxc6',
    'fxg6 hxg6',
    'Rc1 Kh7',
    'Bf4 Nhf7',
    'Rg3 Rh8',
    'Qh5+ Kg8',
    'Qxg6+ Kf8',
    'd5 c5',
    'Qg7+ Ke8',
    'Bb5+ Bd7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'h3 e6',
    'g4 d5',
    'Bg2 Bb4',
    'exd5 Nxd5',
    'Qd3 Qc7',
    'Ne2 Nxc3',
    'bxc3 Bd6',
    'O-O O-O',
    'Rd1 Be7',
    'Qe4 Nc6',
    'Be3 e5',
    'Rab1 Be6',
    'Bb6 Qc8',
    'Qa4 h5',
    'gxh5 Bxh3',
    'Rd3 Bf5',
    'Rg3 Kh8',
    'Be4 Bxe4',
    'Qxe4 f5',
    'Qg2 Bf6',
    'Rg6 Ne7',
    'Ba5 Qc4',
    'Ng3 Nxg6',
    'hxg6 Qg4',
    'Nxf5 Qxg2+',
    'Kxg2 Bd8',
    'Bxd8 Rxf5',
    'Be7 b5',
    'Rd1 Re8',
    'Rd7 e4',
    'Bh4 Rfe5',
    'Kg3 R5e6',
    'Kf4 Rxg6',
    'Ke3 Rc6',
    'Kd2 Rec8',
    'Ra7 Rxc3',
    'Rxa6 Rxc2+',
    'Ke3 R2c3+',
    'Ke2 b4',
    'Be7 Rc2+',
    'Kf1 Rb2',
    'Bg5 Rcc2',
    'Be3 Rxa2',
    'Rb6 Rc4',
    'Kg2 Rc3',
    'Bd4 Rd3',
    'Rd6 Rad2',
    'Rh6+ Kg8',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'O-O Nc6',
    'b3 e5',
    'Bb2 e4',
    'Ne1 Bf5',
    'Nc2 Bh6',
    'd5 Ne5',
    'Nc3 Qd7',
    'Qd4 Bg7',
    'Qd2 Rae8',
    'Ne3 Bh3',
    'f4 exf3',
    'exf3 Bh6',
    'f4 Bxg2',
    'Kxg2 Neg4',
    'Nxg4 Nxg4',
    'Rfe1 a6',
    'Ne4 f5',
    'Qd4 Bg7',
    'Qxg7+ Qxg7',
    'Bxg7 Kxg7',
    'h3 Rf7',
    'Nxd6 Rxe1',
    'Rxe1 cxd6',
    'hxg4 fxg4',
    'Re6 Rf6',
    'Re3 h5',
    'b4 h4',
    'gxh4 Rxf4',
    'c5 Rd4',
    'Re7+ Kf6',
    'cxd6 Rxd5',
    'Rxb7 Rxd6',
    'Kg3 Rd4',
    'a4 a5',
    'Rb6+ Kg7',
    'bxa5 Rxa4',
    'a6 Kh6',
    'Rb8 Rxa6',
    'Kxg4 Ra4+',
    'Kg3 g5',
    'hxg5+ Kxg5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'e5 h6',
    'Bd2 Bxc3',
    'bxc3 Ne4',
    'Qg4 g6',
    'Bd3 Nxd2',
    'Kxd2 c5',
    'Nf3 Nc6',
    'h4 c4',
    'Be2 Bd7',
    'Rab1 b6',
    'Qf4 Qe7',
    'h5 g5',
    'Qe3 O-O-O',
    'Nh2 f5',
    'exf6 Qxf6',
    'Ng4 Qf5',
    'Bf3 Rhe8',
    'Rhe1 Re7',
    'a4 Rde8',
    'Nxh6 Qf6',
    'Ng4 Qf5',
    'Ne5 Nxe5',
    'Qxe5 Qf4+',
    'Qxf4 gxf4',
    'Ra1 a5',
    'Re5 Rh7',
    'Rae1 Rg8',
    'Rh1 Rh6',
    'Rh4 Rf8',
    'Bg4 Rff6',
    'Rh1 Kc7',
    'Ra1 Rf8',
    'Rg5 Be8',
    'Ke2 Rfh8',
    'Kf3 Bxh5',
    'Bxh5 Rxh5',
    'Rxh5 Rxh5',
    'Kxf4 Rh4+',
    'g4 Rh8',
    'Kg3 Kc6',
    'Rb1 Rb8',
    'g5 Rh8',
    'f4 Rh7',
    'Re1 Kd6',
    'Re2 Rh1',
    'Rg2 Ra1',
    'Re2 Rf1',
    'Rf2 Ra1',
    'f5 exf5',
    'Rxf5 Rxa4',
    'g6 Ra1',
    'Kg2 Re1',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Qxd4 Nc6',
    'Bb5 Bd7',
    'Bxc6 Bxc6',
    'Nc3 e6',
    'Bg5 Nf6',
    'O-O-O Qa5',
    'Bxf6 gxf6',
    'Qxf6 Rg8',
    'Rd5 Bxd5',
    'Ng5 Qc7',
    'Nxd5 exd5',
    'Qf5 Rc8',
    'c3 Bh6',
    'h4 d4',
    'Qxh7 Bxg5+',
    'hxg5 Kf8',
    'g6 fxg6',
    'Qxg6 Qf7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bc4 Bc5',
    'c3 Nf6',
    'd4 exd4',
    'cxd4 Bb4+',
    'Bd2 Bxd2+',
    'Nbxd2 d6',
    'O-O O-O',
    'h3 d5',
    'Bd3 dxe4',
    'Nxe4 Bf5',
    'Nxf6+ Qxf6',
    'Bxf5 Qxf5',
    'Qb3 Qd7',
    'Rfd1 b6',
    'Rac1 Na5',
    'Qc2 Rac8',
    'b4 Nb7',
    'Ne5 Qd6',
    'Qc6 a5',
    'bxa5 Ra8',
  ],
  [
    'd4 Nf6',
    'Bg5 d5',
    'Bxf6 gxf6',
    'e3 c6',
    'c4 e6',
    'Nc3 f5',
    'Bd3 Bd6',
    'Rc1 a6',
    'cxd5 cxd5',
    'g4 f4',
    'e4 dxe4',
    'Nxe4 Nc6',
    'Nf3 Be7',
    'O-O Rg8',
    'Kh1 Bd7',
    'Qd2 Qb8',
    'a3 Rxg4',
    'h3 Rg6',
    'Nc5 Bxc5',
    'Bxg6 Bd6',
    'Bh5 Ne7',
    'Ng5 Ng6',
    'd5 e5',
    'Rfe1 Kf8',
    'Qd3 Kg7',
    'Rg1 Qe8',
    'Rg2 Kh6',
    'Bg4 Bxg4',
    'hxg4 Kg7',
    'Ne4 Qe7',
    'Rh2 h6',
    'Qh3 Rh8',
    'Qh5 Qd8',
    'f3 Qd7',
    'Qf5 Qe7',
    'Rc3 Qd8',
    'g5 hxg5',
    'Nxg5 Rxh2+',
    'Kxh2 Qe7',
    'Rc2 Qf6',
    'Qg4 Be7',
    'Ne4 Qb6',
    'Rg2 Qd8',
    'd6 Bxd6',
    'Rd2 Qh8+',
    'Kg1 Be7',
    'Qd7 Qh4',
    'Rf2 Qh5',
    'Nd6 Bxd6',
    'Qxd6 Qh4',
    'Qd5 Qg3+',
    'Kf1 Qh3+',
    'Rg2 Qh1+',
    'Kf2 Qh4+',
    'Ke2 Qe7',
    'a4 b6',
    'b3 a5',
    'Kd3 Qf6',
    'Qd7 Kf8',
    'Ke4 Kg7',
    'Rg1 Qh4',
    'Qd6 Qh5',
    'Qc7 Qh2',
    'Qxe5+ Kh7',
    'Qd4 Qe2+',
    'Kf5 Qe6+',
    'Kg5 Qe7+',
    'Kf5 Qe6+',
    'Kg5 Qe7+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f4 e5',
    'Nf3 Nbd7',
    'a4 Be7',
    'Bd3 O-O',
    'O-O exf4',
    'Kh1 Nh5',
    'Ne1 Ne5',
    'Be2 Nf6',
    'Bxf4 Be6',
    'Nf3 Ng6',
    'Be3 Ng4',
    'Bg1 Bf6',
    'Nd4 Bxd4',
    'Qxd4 Qe7',
    'Bxg4 Bxg4',
    'Nd5 Qe5',
    'Qd2 Rac8',
    'c3 Qxe4',
    'Rae1 Qxa4',
    'Nb6 Qc6',
    'Nxc8 Rxc8',
    'Bd4 Be6',
    'Qg5 h6',
    'Qg3 Kh7',
    'Rf2 Qd5',
    'Kg1 b5',
    'Rd2 Rc6',
    'Red1 Qe4',
    'Re1 Qf5',
    'Rf2 Qh5',
    'Rd2 a5',
    'Ra1 a4',
    'Re1 Ra6',
    'Ra1 f6',
    'Re1 Bc4',
    'Qe3 Ra8',
    'Bb6 d5',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 g6',
    'f4 Bg7',
    'Nf3 c5',
    'Bb5+ Bd7',
    'e5 Ng4',
    'e6 Bxb5',
    'exf7+ Kd7',
    'Nxb5 Qa5+',
    'Nc3 cxd4',
    'Nxd4 Bxd4',
    'Qxd4 Nc6',
    'Qc4 Qb6',
    'Qe2 h5',
    'h3 Nf6',
    'Bd2 Raf8',
    'O-O-O Rxf7',
    'Rhe1 Nd8',
    'Qd3 Rg8',
    'Be3 Qa5',
    'Qc4 Rgf8',
    'Kb1 Ne8',
    'Ne4 Qc7',
    'Qa4+ Qc6',
    'Nc5+ Kc7',
    'Qa5+ Kc8',
    'Qxa7 Nc7',
    'Na4 Na6',
    'Qa8+ Kc7',
    'Nb6 Ne6',
    'Qa7 Nxf4',
    'Na8+ Kd7',
    'Bxf4 Rxf4',
    'Nb6+ Kd8',
    'Nd5 R4f7',
    'Nxe7 Rxe7',
    'Qa8+ Kd7',
  ],
  [
    'c4 e5',
    'Nc3 Nc6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 d6',
    'e3 Nge7',
    'Nge2 O-O',
    'O-O Be6',
    'Nd5 Qd7',
    'Re1 f5',
    'f4 Rae8',
    'Rb1 Nc8',
    'Bd2 Nd8',
    'Bc3 c6',
    'Nb4 h6',
    'b3 g5',
    'd4 Qc7',
    'Qd2 gxf4',
    'gxf4 Bf6',
    'Kh1 Kh8',
    'dxe5 dxe5',
    'Qb2 Kh7',
    'fxe5 Bh4',
    'Rf1 Bg5',
    'Nf4 Qb6',
    'Rbe1 Bxf4',
    'exf4 Bf7',
    'Nc2 Ne6',
    'Nd4 Ne7',
    'Nxe6 Bxe6',
    'Rd1 Ng6',
    'Qf2 Qxf2',
    'Rxf2 Nh4',
    'Bh3 Rg8',
    'Rd6 Rg6',
    'Rf1 Bc8',
    'Rd3 Rgg8',
    'Rfd1 Ng6',
    'Bd2 Be6',
    'Be3 a6',
    'Rd6 Rgf8',
    'Kg2 Rf7',
    'Kf2 Nf8',
    'Rd8 Rxd8',
    'Rxd8 Kg7',
    'Bd4 Rd7',
    'Rxd7+ Nxd7',
    'Bg2 Kf7',
    'Ke3 Ke7',
    'Kd3 Kd8',
    'Bf2 Ke8',
    'a4 Kd8',
    'Bf3 Bf7',
    'b4 Kc7',
    'b5 axb5',
    'axb5 cxb5',
    'cxb5 b6',
    'Be3 Nf8',
    'Kc3 Be8',
    'Kc4 Ne6',
    'Bd5 Bd7',
    'Bd2 Nd8',
    'Bg8 Ne6',
    'Kd5 Ng7',
    'e6 Bxb5',
    'Bc3 Ne8',
    'Ke5 Bd3',
    'Bb4 Kd8',
    'Bf7 Nc7',
    'Bg6 b5',
    'Bxf5 Bxf5',
    'Kxf5 Nd5',
    'Bd6 b4',
    'Ke5 b3',
    'Ba3 Ne3',
    'Kf6 Ke8',
    'f5 Nc4',
    'Bc1 Kf8',
  ],
  [
    'e4 c5',
    'c3 e6',
    'd4 d5',
    'exd5 Qxd5',
    'Nf3 Nc6',
    'Na3 Nf6',
    'Be2 cxd4',
    'Nb5 Qd7',
    'Nbxd4 Bc5',
    'O-O Bxd4',
    'Nxd4 Nxd4',
    'cxd4 O-O',
    'Be3 b6',
    'Qd3 Bb7',
    'Rfd1 h6',
    'Rac1 Rac8',
    'Bf4 Nd5',
    'Be5 f6',
    'Bg3 Nb4',
    'Qb3 Qd5',
    'Qxd5 Bxd5',
    'a3 Rxc1',
    'Rxc1 Nc6',
    'Ba6 Nxd4',
    'Rc7 Rf7',
    'Rc8+ Rf8',
    'Rc7 Rf7',
    'Rc8+ Kh7',
    'f3 Bb7',
    'Bd3+ g6',
    'Rd8 e5',
    'Bf2 Ne6',
    'Rd6 Nf4',
    'Bf1 Nh5',
    'a4 Rc7',
    'b4 Bc6',
    'a5 bxa5',
    'bxa5 Kg7',
    'a6 Bd7',
    'Rd1 Nf4',
    'Rb1 Bc8',
    'Rb8 Bxa6',
    'Bxa6 Rc1+',
    'Be1 Rxe1+',
    'Kf2 Ra1',
    'Rb7+ Kf8',
    'Rxa7 Ra2+',
    'Kf1 Nxg2',
    'Ra8+ Ke7',
    'Ra7+ Kd6',
    'Rf7 Ne3+',
    'Kg1 Ke6',
    'Ra7 Rg2+',
    'Kh1 Rc2',
    'Kg1 h5',
    'Bd3 Rg2+',
    'Kh1 Rd2',
    'Ra6+ Ke7',
    'Ra7+ Kd6',
    'Ra6+ Kc5',
  ],
  ['e4 e5', 'Nf3 Nc6', 'd4 exd4', 'Nxd4 Bc5', 'Be3 Qf6', 'Nxc6 Bxe3', 'fxe3 dxc6', 'Qd4 Qg6', 'Nc3 Bd7', 'O-O-O O-O-O'],
  [
    'Nf3 Nf6',
    'b3 g6',
    'Bb2 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'd4 c6',
    'O-O Nbd7',
    'Nbd2 Qc7',
    'e4 e5',
    'dxe5 dxe5',
    'Qe2 Re8',
    'a4 a5',
    'Rfd1 b6',
    'Qe3 Ba6',
    'Bf1 Ng4',
    'Qe1 Bf8',
    'h3 Ngf6',
    'Bxa6 Rxa6',
    'Nc4 b5',
    'Ncd2 Nc5',
    'Qe2 Qb8',
    'Bc3 Bd6',
    'axb5 Qxb5',
    'Qxb5 cxb5',
    'Rxa5 Rxa5',
    'Bxa5 Ncxe4',
    'Nxe4 Nxe4',
    'Nd2 Nf6',
    'c4 Rb8',
    'Rc1 bxc4',
    'bxc4 Kf8',
    'c5 Rb5',
    'cxd6 Rxa5',
    'Rc8+ Kg7',
    'Nc4 Rd5',
    'Kg2 Ne4',
    'Rc6 Rd4',
    'f3 Rxc4',
    'Rxc4 Nxd6',
    'Ra4 Nf5',
    'Kf2 Ne7',
    'Ra5 Kf6',
    'Ra6+ Kg7',
    'Ra7 Kf6',
    'Ra6+ Kg7',
    'Ra1 Kf6',
    'Ke2 Nf5',
    'Rg1 Nd4+',
    'Ke3 Nf5+',
    'Kd3 Nd6',
    'Ra1 Ke6',
    'g4 h5',
    'gxh5 gxh5',
    'Ra6 Ke7',
    'Ke3 f5',
    'f4 Ke6',
    'fxe5 Kxe5',
    'Ra5+ Ke6',
    'Kf4 Kf6',
    'Ra6 Ke6',
    'Kg5 Ke5',
    'Kxh5 Ne4',
    'h4 f4',
    'Kg4 Nf6+',
    'Kf3 Kf5',
    'Ra5+ Kg6',
    'Kxf4 Nh5+',
    'Kg4 Nf6+',
    'Kf3 Nh5',
    'Rg5+ Kh6',
    'Kg4 Nf6+',
    'Kf5 Ne8',
    'Rg8 Ng7+',
    'Kg4 Ne6',
    'Ra8 Kg7',
    'Ra7+ Kg8',
    'Kf5 Nf8',
    'Kf6 Nh7+',
    'Kg6 Nf8+',
    'Kh6 Ne6',
    'Ra1 Nf8',
    'Rg1+ Kh8',
    'Kg5 Kg7',
    'Rg2 Kh7',
    'Kf6 Nd7+',
    'Kg5 Kg7',
    'Re2 Kf7',
    'Kh6 Nf8',
    'Rc2 Kg8',
    'Rc7 Kh8',
    'h5 Kg8',
    'Kg5 Nh7+',
    'Kf5 Nf8',
    'h6 Nh7',
    'Rc8+ Kf7',
    'Rb8 Nf8',
    'Rb7+ Kg8',
    'Kf6 Nh7+',
    'Ke7 Ng5',
    'Ke8 Kh8',
    'Rg7 Ne4',
    'Kf7 Nd6+',
    'Kg6 Nf5',
    'Rf7 Nh4+',
    'Kg5 Ng2',
    'h7 Nh4',
    'Ra7 Ng6',
    'Kh6 Nf8',
    'Rb7 Nd7',
    'Ra7 Nf8',
    'Rg7 Nxh7',
  ],
  [
    'e4 c6',
    'd4 d5',
    'exd5 cxd5',
    'Bd3 Nc6',
    'c3 Nf6',
    'Bf4 e6',
    'Nf3 Bd6',
    'Bxd6 Qxd6',
    'O-O O-O',
    'Qe2 b6',
    'Ne5 Bb7',
    'f4 Rac8',
    'Nd2 g6',
    'Rf3 Ne7',
    'Raf1 Kg7',
    'Rh3 Nfg8',
    'Qf2 Qd8',
    'Ndf3 h6',
    'Ng5 Qd6',
    'Qg3 Nf5',
    'Bxf5 exf5',
    'Ngf3 Rc7',
    'Nh4 Bc8',
    'Qf3 Nf6',
    'Kh1 b5',
    'Nd3 Ne4',
    'Qd1 a5',
    'Nf2 Re8',
    'Nxe4 Rxe4',
    'Nf3 Rxf4',
    'Qd2 h5',
    'g3 Re4',
    'Rh4 Rce7',
    'Rf2 Qe6',
    'Kg2 f6',
    'Kf1 Qc6',
    'Kg1 Qe8',
    'Rg2 Re3',
    'Rf2 g5',
    'Ne5 fxe5',
    'Qxe3 gxh4',
    'Qg5+ Kh7',
    'Qxh4 exd4',
    'Qxd4 Re1+',
    'Rf1 Qe3+',
    'Qxe3 Rxe3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 e6',
    'Nc3 a6',
    'f4 Nc6',
    'Be3 Nf6',
    'Qf3 Qc7',
    'O-O-O Bd7',
    'g4 Nxd4',
    'Bxd4 Bc6',
    'Bxf6 gxf6',
    'f5 Be7',
    'Bc4 b5',
    'Bb3 b4',
    'Ne2 e5',
    'Ng3 a5',
    'Kb1 a4',
    'Bc4 Rc8',
    'b3 Qb7',
    'Qe2 Rb8',
    'Rhe1 Rg8',
    'h3 Qd7',
    'Qd2 Rg5',
    'Nh5 Qb7',
    'Nxf6+ Bxf6',
    'Qxd6 Be7',
    'Qxe5 f6',
    'Qe6 Rg7',
    'e5 Bd7',
    'Qxf6 Bxf6',
    'exf6+ Kf8',
    'fxg7+ Kxg7',
    'Re7+ Kf6',
    'Rexd7 Qe4',
    'g5+ Kxg5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'e5 h6',
    'Bd2 Bxc3',
    'bxc3 Ne4',
    'Qg4 g6',
    'Bd3 Nxd2',
    'Kxd2 c5',
    'Nf3 Nc6',
    'h4 cxd4',
    'cxd4 Bd7',
    'Rhb1 Qc7',
    'c3 O-O-O',
    'Qf4 f5',
    'g4 fxg4',
    'Qxg4 Rdg8',
    'c4 dxc4',
    'Bxc4 Kb8',
    'Bxe6 h5',
    'Qh3 Bxe6',
    'Qxe6 Rf8',
    'Qd5 Rf4',
    'Rb3 Rd8',
    'Qc5 Nxd4',
    'Qxc7+ Kxc7',
    'Rc1+ Kb8',
    'Nxd4 Rfxd4+',
    'Ke3 Rxh4',
    'Rg1 Re8',
    'Rxg6 Rxe5+',
    'Kd2 Rd4+',
    'Kc3 Rd7',
    'f4 Rc5+',
    'Kb4 b6',
    'Rf3 Rd4+',
    'Kb3 Rf5',
    'Rh6 Kb7',
    'Rh7+ Ka6',
    'Kc2 Ra4',
    'a3 Kb5',
    'Kb3 a5',
    'Rh3 Rfxf4',
    'R7xh5+ Ka6',
    'Re5 Rad4',
    'Re2 a4+',
    'Kb2 Rd5',
    'Rc3 Rb5+',
    'Ka2 Rf1',
    'Rc4 Ka5',
    'Rxa4+ Kxa4',
    'Re4+ Ka5',
    'Ra4+ Kxa4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f4 e5',
    'Nf3 Nbd7',
    'a4 Be7',
    'Bd3 O-O',
    'O-O exf4',
    'Kh1 Nh5',
    'Nd5 Bf6',
    'Nd2 Ne5',
    'Be2 g6',
    'Nb3 f3',
    'Bxf3 Nxf3',
    'Qxf3 Be5',
    'Qf2 Bg7',
    'c3 f5',
    'Qe2 Qh4',
    'Bf4 Be5',
    'Qf3 fxe4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'Bd2 Ne7',
    'Nb5 Bxd2+',
    'Qxd2 O-O',
    'c3 Nbc6',
    'f4 cxd4',
    'cxd4 Nf5',
    'Nf3 a6',
    'Nc3 f6',
    'Be2 fxe5',
    'dxe5 Qb6',
    'Bd3 Bd7',
    'Bxf5 Rxf5',
    'Ne2 Rff8',
    'Ned4 Qb4',
    'g3 Rac8',
    'Qxb4 Nxb4',
    'Kd2 Rc7',
    'Rac1 Rfc8',
    'Rxc7 Rxc7',
    'a3 Nc6',
    'Rc1 h6',
    'b4 Kf8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Be7',
    'Qf3 Qc7',
    'O-O-O Nbd7',
    'g4 b5',
    'Bxf6 Nxf6',
    'g5 Nd7',
    'f5 Nc5',
    'f6 gxf6',
    'gxf6 Bf8',
    'Rg1 h5',
    'Rg7 b4',
    'Nd5 exd5',
    'exd5 Bg4',
    'Re1+ Kd8',
    'Qg3 Kc8',
    'Nc6 Kb7',
    'h3 Bxg7',
    'fxg7 Rhe8',
    'hxg4 hxg4',
    'Rxe8 Rxe8',
    'Qxg4 Rg8',
    'Qxb4+ Ka8',
    'Qa3 Qb6',
    'b4 Kb7',
    'bxc5 dxc5',
    'Qg3 a5',
    'Qg5 Qc7',
    'Qf6 Ka8',
    'Ne7 Rxg7',
    'd6 Qd7',
    'Qxg7 Qe6',
    'Bg2+ Ka7',
    'Qe5 Qc4',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Qb3 dxc4',
    'Qxc4 O-O',
    'e4 a6',
    'e5 b5',
    'Qb3 Nfd7',
    'Be3 c5',
    'e6 fxe6',
    'dxc5 Ne5',
    'Nxe5 Bxe5',
    'a4 bxa4',
    'Nxa4 Qd5',
    'Qc4 Bc7',
    'Rd1 Ba5+',
    'b4 Qxc4',
    'Bxc4 Bxb4+',
    'Ke2 Ba5',
    'Rb1 Kf7',
    'Nb6 Ra7',
    'Bd3 Bxb6',
    'cxb6 Rd7',
    'Rhc1 Bb7',
    'Rc7 Rc8',
    'Bf4 Rcd8',
    'Rbc1 Bxg2',
    'Rxd7 Nxd7',
    'Bc7 Rc8',
    'Bxa6 Ra8',
    'Bc4 Bb7',
    'Rc3 Nf6',
    'Bb3 Ra1',
    'Be5 Ba6+',
    'Bc4 Bxc4+',
    'Rxc4 Rb1',
    'Rc6 Nd5',
    'Bd4 Ke8',
    'Rc8+ Kd7',
    'Rh8 Nxb6',
  ],
  [
    'e4 c5',
    'Nc3 g6',
    'f4 Bg7',
    'Nf3 Nc6',
    'Bb5 Nd4',
    'a4 Nxb5',
    'axb5 d6',
    'O-O Nf6',
    'd3 O-O',
    'Qe1 Bd7',
    'Qh4 Ne8',
    'f5 Nc7',
    'Bh6 f6',
    'fxg6 hxg6',
    'Bxg7 Kxg7',
    'Qg3 Rh8',
    'Nh4 g5',
    'Nf5+ Bxf5',
    'Rxf5 e6',
    'Rf2 Rh6',
    'Raf1 Qe7',
    'Rxf6 Rxf6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 Nf6',
    'Nc3 cxd4',
    'Nxd4 a6',
    'Bg5 e6',
    'Qd2 Be7',
    'O-O-O Qc7',
    'f4 Nbd7',
    'Bd3 b5',
    'a3 Bb7',
    'Kb1 O-O',
    'Nf3 Nc5',
    'Rde1 Rfd8',
    'Qe3 d5',
    'Bxf6 Bxf6',
    'e5 Be7',
    'Nd4 b4',
    'axb4 Nxd3',
    'cxd3 Bxb4',
    'Rc1 Qb6',
    'Rc2 Bc6',
    'Rhc1 Rab8',
    'Nd1 Ba4',
    'Nc6 Qxe3',
    'Nxe3 Bxc6',
    'Rxc6 Bd2',
    'Nc2 Bxc1',
    'Kxc1 a5',
    'Rc3 Rdc8',
    'Kd2 Kf8',
    'b3 Ke7',
    'Nd4 Kd7',
    'Nf3 a4',
    'Rxc8 Kxc8',
    'bxa4 Rb2+',
    'Ke3 Rxg2',
    'Ng5 Rxh2',
    'Nxf7 Kd7',
    'Nd6 h5',
    'a5 Ra2',
    'Nb7 h4',
  ],
  [
    'e4 g6',
    'd4 Bg7',
    'Nc3 c6',
    'f4 d5',
    'e5 h5',
    'Nf3 Bg4',
    'Be3 Nh6',
    'Be2 e6',
    'Qd2 Bf8',
    'Nd1 Qb6',
    'c3 Nf5',
    'Bf2 c5',
    'h3 Bxf3',
    'Bxf3 cxd4',
    'g4 hxg4',
    'hxg4 Rxh1+',
    'Bxh1 Ne3',
    'Bxe3 dxe3',
    'Nxe3 Bc5',
    'Nf1 Nc6',
    'O-O-O O-O-O',
    'Qe2 Qc7',
    'Nd2 g5',
    'Nb3 Bb6',
    'fxg5 Qxe5',
    'Bf3 Qxg5+',
    'Kb1 Ne5',
    'Re1 Nc4',
    'Ka1 Rh8',
    'a4 Qf4',
    'Bg2 Ne3',
    'a5 Nxg2',
    'Qxg2 Rh2',
    'Qxh2 Qxh2',
    'axb6 axb6',
    'Rf1 Qh7',
    'Nd4 Kd8',
    'Rf2 Ke8',
    'Nf3 Qe4',
    'g5 Qe3',
    'Rf1 Qe2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bg5 Ne4',
    'cxd5 Nxg5',
    'Nxg5 e6',
    'Qd2 exd5',
    'Qe3+ Kf8',
    'Qf4 Bf6',
    'h4 h6',
    'Nf3 Kg7',
    'e3 Be6',
    'Bd3 c6',
    'O-O-O Nd7',
    'Rdg1 Qb8',
    'Ne2 c5',
    'g4 cxd4',
    'g5 hxg5',
    'hxg5 Qxf4',
    'Nxf4 Be5',
    'Nxe6+ fxe6',
    'Bb5 Rhf8',
    'Rh3 Rxf3',
    'Rxf3 Nc5',
    'b4 Rc8',
    'bxc5 Rxc5+',
    'Kd2 Rxb5',
    'Kd3 Ra5',
    'Rb1 Ra3+',
    'Rb3 Bd6',
    'Rxa3 Bxa3',
    'Kxd4 Bb2+',
    'Kd3 b5',
    'Rh3 a5',
    'Rh1 b4',
    'Kc2 Bc3',
    'Rd1 Kf7',
    'Kb3 Ke7',
    'Rh1 d4',
    'Rh7+ Ke8',
    'Kc4 e5',
    'Ra7 dxe3',
    'fxe3 Bd2',
    'Ra6 Bc3',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 g6',
    'f4 Bg7',
    'Nf3 c5',
    'Bb5+ Bd7',
    'e5 Ng4',
    'e6 fxe6',
    'Ng5 Bxb5',
    'Nxe6 Bxd4',
    'Nxb5 Qa5+',
    'Qd2 Bf2+',
    'Kd1 Ne3+',
    'Ke2 Qxb5+',
    'Kxf2 Ng4+',
    'Kg3 Na6',
    'Re1 Nh6',
    'b3 Nf5+',
    'Kf2 h5',
    'Bb2 Rg8',
    'Rad1 Kd7',
    'c4 Qc6',
    'Ba3 Rae8',
    'Qa5 Kc8',
    'Rd5 Kb8',
    'Red1 Qc8',
    'Re1 Qc6',
    'Red1 Qc8',
    'Re1 b6',
    'Qd2 Nc7',
    'Nxc7 Qxc7',
    'Bb2 e6',
    'Rd3 Kc8',
    'g3 e5',
    'Re4 Nd4',
    'Rde3 Ref8',
    'Bxd4 cxd4',
    'Rf3 Rf5',
    'fxe5 Rxf3+',
    'Kxf3 dxe5',
    'Qg5 Qf7+',
    'Ke2 Rf8',
    'Qxe5 Qf3+',
    'Kd2 Qc3+',
    'Ke2 d3+',
    'Ke3 Qe1+',
    'Kxd3 Rd8+',
    'Kc2 Rd2+',
    'Kc3 Re2+',
    'Kd4 Qd2+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Be3 Nc6',
    'Qd2 a6',
    'Nge2 Rb8',
    'Nc1 e5',
    'Nb3 exd4',
    'Nxd4 Ne5',
    'Be2 c5',
    'Nc2 Be6',
    'Na3 Qa5',
    'Qxd6 Rbc8',
    'Qd2 Rfd8',
    'Nd5 Qxd2+',
    'Bxd2 Nc6',
    'Bc3 Bxd5',
    'cxd5 Nd4',
    'Bxd4 cxd4',
    'Bd3 Nd7',
    'O-O Nc5',
    'Rad1 b5',
    'Rf2 f5',
    'Nc2 fxe4',
    'fxe4 Re8',
    'Re2 Rcd8',
    'Nb4 Rd6',
    'b3 Bf6',
    'Kf1 Be5',
    'g3 Rf6+',
    'Kg2 Ref8',
    'Nc6 Nd7',
    'Bb1 Bd6',
    'Nxd4 Ne5',
    'Ne6 R8f7',
    'Ng5 Rf8',
    'Ne6 R8f7',
    'Nf4 g5',
    'Nd3 Nxd3',
    'Bxd3 Be5',
    'Rc2 Rf3',
    'a4 Kg7',
    'axb5 axb5',
    'Rcd2 Bc3',
    'Re2 Be5',
    'Red2 Bc3',
    'Rc2 Be5',
    'b4 R3f6',
    'Bxb5 Rb7',
    'Bd3 Rxb4',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Na3 b5',
    'Nd5 Be7',
    'Bxf6 Bxf6',
    'c3 O-O',
    'Nc2 Rb8',
    'h4 Be7',
    'Nce3 Be6',
    'Bd3 Qd7',
    'a4 Bd8',
    'axb5 axb5',
    'h5 Bg5',
    'Qf3 h6',
    'Nf5 b4',
    'Ra6 Rfe8',
    'O-O bxc3',
    'bxc3 g6',
    'Nfe3 Kg7',
    'Rd1 Ne7',
    'Be2 Red8',
    'Nxe7 Qxe7',
    'Nc4 Bxc4',
    'Bxc4 Bf6',
    'Qd3 Qc7',
    'Qd5 Rbc8',
    'Rc6 Qe7',
    'hxg6 fxg6',
    'Rb1 Rxc6',
    'Qxc6 Rd7',
    'Qd5 h5',
    'Rb8 Kh6',
    'Qd2+ Kh7',
    'Bg8+ Kg7',
    'Bc4 Kh7',
    'Qd5 Rd8',
    'Rb7 Rd7',
    'Rb8 Rd8',
    'Rb6 Bg7',
    'Qb5 Rc8',
    'Rb7 Rc7',
    'Rb8 Qg5',
    'Bg8+ Kh6',
    'Qb2 Qf4',
    'g3 Qg5',
    'c4 Ra7',
    'Bd5 Rd7',
    'Kh2 Rd8',
    'Rb7 Rf8',
    'Rd7 Qf6',
    'Qd2+ Kh7',
    'Kg1 Kh8',
    'Bc6 Rd8',
    'Rxd8+ Qxd8',
    'c5 Bf8',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Nc3 d5',
    'Bf4 Bg7',
    'Qd2 Ne4',
    'Nxe4 dxe4',
    'Ne5 Nd7',
    'O-O-O Nf6',
    'Bh6 Bxh6',
    'Qxh6 Qd5',
    'c4 Qa5',
    'a3 c6',
    'f3 Be6',
    'Qe3 exf3',
    'gxf3 O-O-O',
    'b4 Qc7',
    'Qc3 Nd7',
    'f4 Nb6',
    'e3 f6',
    'Nf3 Rhe8',
    'h4 Bg4',
    'Bh3 Bxh3',
    'Rxh3 e5',
    'fxe5 fxe5',
    'd5 cxd5',
    'c5 Nc4',
    'e4 dxe4',
    'Rxd8+ Rxd8',
    'Qxc4 Qd7',
    'Qf1 exf3',
    'Rxf3 Qd4',
    'Kc2 Qxh4',
    'Rf8 Qe4+',
    'Kb3 Qd5+',
    'Ka4 Rxf8',
    'Qxf8+ Qd8',
    'Qf2 Qd7+',
    'Ka5 Kb8',
    'b5 Qd8+',
    'b6 axb6+',
    'Kb5 Qd3+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nge2 O-O',
    'Ng3 e5',
    'd5 h5',
    'Bg5 Qe8',
    'Be2 Nh7',
    'Be3 h4',
    'Nf1 f5',
    'exf5 gxf5',
    'f4 a5',
    'Nd2 Na6',
    'Nf3 Qe7',
    'O-O Bd7',
    'Qd2 Rae8',
    'Rae1 e4',
    'Nd4 Qf7',
    'Kh1 Kh8',
    'Nd1 b6',
    'Bf2 Qf6',
    'Be3 Qf7',
    'Nf2 Rb8',
    'Rg1 Bf6',
    'Qd1 Be7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e5',
    'Nb3 Be6',
    'Qd2 Nbd7',
    'f3 Be7',
    'O-O-O b5',
    'Kb1 O-O',
    'g4 b4',
    'Nd5 Bxd5',
    'exd5 a5',
    'g5 Nh5',
    'Bh3 Nb6',
    'Bxb6 Qxb6',
    'Bf5 a4',
    'Nc1 Rfb8',
    'Be4 a3',
    'b3 g6',
    'h4 Bf8',
    'Nd3 Qd4',
    'Rc1 Nf4',
    'c3 bxc3',
    'Qxc3 Ne2',
    'Qxd4 exd4',
    'Rc2 Nc3+',
    'Kc1 Bg7',
    'Kd2 Ra5',
    'Nf2 Nxd5',
    'Bxd5 Rxd5',
    'Rhc1 h6',
    'Rc8+ Rxc8',
    'Rxc8+ Kh7',
    'f4 hxg5',
    'hxg5 Rf5',
    'Nd3 f6',
    'gxf6 Bxf6',
    'Ke2 Rh5',
    'Nb4 Rh3',
    'Ra8 Bh4',
    'Rxa3 Re3+',
    'Kd1 Rf3',
    'Nd5 Bg3',
    'Ra7+ Kh6',
    'Re7 Rf1+',
    'Kd2 Rf2+',
    'Re2 Bxf4+',
    'Kd3 Rf1',
    'Kxd4 Be5+',
    'Ke4 g5',
    'Ne3 Rf4+',
    'Kd5 g4',
    'Rg2 Rd4+',
    'Kc6 Kg5',
    'Rxg4+ Rxg4',
    'Nxg4 Kxg4',
    'a4 Kf3',
    'a5 Ke4',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'Be3 Ng4',
    'Bg5 f6',
    'Bc1 Nc6',
    'h3 Nh6',
    'Be3 Nf7',
    'd5 Ne7',
    'Nd2 f5',
    'f3 c5',
    'g4 Bf6',
    'Bf2 Bg5',
    'Qc2 a6',
    'h4 Bf4',
    'gxf5 gxf5',
    'Bd3 Nh6',
    'Ne2 Bxd2+',
    'Qxd2 f4',
    'Nc3 Kh8',
    'h5 Neg8',
  ],
  ['e4 c6', 'd4 d5', 'exd5 cxd5', 'Bd3 Nf6', 'c3 Nc6', 'Bf4 Bg4', 'Qb3 Qc8', 'Nd2 e6', 'Ngf3 Be7', 'O-O Bh5'],
  [
    'e4 g6',
    'd4 c6',
    'Nc3 d5',
    'e5 Bg7',
    'f4 Nh6',
    'Nf3 Bg4',
    'Be3 Qb6',
    'Qd2 Bxf3',
    'gxf3 Nf5',
    'Bf2 Nd7',
    'Bh3 e6',
    'Bxf5 exf5',
    'O-O-O Nf8',
    'Kb1 Qa6',
    'Ne2 Ne6',
    'h4 h5',
    'Ng1 Bh6',
    'Nh3 O-O',
    'Ng5 Bxg5',
    'fxg5 b6',
    'f4 Rfd8',
    'Be3 c5',
    'c3 Qb7',
    'Ka1 a5',
    'Rb1 Rd7',
    'Rhd1 Rc8',
    'b3 Qc6',
    'Rdc1 Rcd8',
    'Rd1 Ra7',
    'Rdc1 Ra6',
    'Qe2 c4',
    'Kb2 b5',
    'Kc2 cxb3+',
    'axb3 a4',
    'bxa4 bxa4',
    'Qb5 Qc8',
    'Qb7 Qc4',
    'Qb5 Nc7',
    'Qxc4 dxc4',
    'Bd2 a3',
    'Rb7 Nd5',
    'Ra1 Kf8',
    'Ra2 Ke8',
    'Bc1 Rda8',
    'Rb5 Ra5',
    'Rb7 R8a7',
    'Rb8+ Kd7',
    'Rf8 Ke7',
    'Rc8 Nc7',
    'Rb8 Ke6',
    'Rd8 R5a6',
    'Kd2 Ra8',
    'Rxa8 Rxa8',
    'Kc2 Nd5',
    'Bd2 Kd7',
    'Kd1 Ke6',
    'Kc2 Nb6',
    'Kd1 Kd5',
  ],
  [
    'Nf3 Nf6',
    'e3 g6',
    'b3 Bg7',
    'Bb2 O-O',
    'Be2 d6',
    'd4 Nc6',
    'O-O e5',
    'dxe5 Ng4',
    'Nbd2 Ngxe5',
    'Nxe5 Nxe5',
    'Qc1 Qe7',
    'Nc4 Nxc4',
    'Bxg7 Kxg7',
    'Bxc4 Be6',
    'Bd3 f5',
    'c4 Qf6',
    'Be2 Bd7',
    'Bf3 Bc6',
    'Bxc6 bxc6',
    'b4 f4',
    'exf4 Qxf4',
    'Qc2 a5',
    'b5 Rf5',
    'Rae1 Raf8',
    'Re4 Qg5',
    'bxc6 Qf6',
    'Qe2 Rf7',
    'Re6 Qd4',
    'Re7 Rxe7',
    'Qxe7+ Rf7',
    'Qe2 Rf5',
    'Qe7+ Rf7',
    'Qe2 Rf5',
    'Qc2 Rc5',
    'Rc1 d5',
    'Qe2 Rxc4',
    'Qe7+ Kh6',
    'Re1 Qc5',
    'Qxc7 Qxc6',
    'Qxa5 Rd4',
    'Qa3 Qf6',
    'g3 Rc4',
    'Qe3+ Kg7',
    'Qa7+ Kh6',
    'Re7 Rc1+',
    'Kg2 d4',
    'Rxh7+ Kg5',
    'Qa5+ Qf5',
    'f4+ Kf6',
    'Qd8+ Ke6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'Be2 Be7',
    'Qd2 Nbd7',
    'Be3 Qc7',
    'g4 h6',
    'f4 b5',
    'a3 Bb7',
    'Bf3 Nc5',
    'Qg2 Nfd7',
    'O-O Nb6',
    'Qe2 g5',
    'e5 d5',
    'f5 Qxe5',
    'fxe6 fxe6',
    'Nb3 Nbd7',
    'Na5 O-O-O',
    'Nxb7 Nxb7',
    'Qd2 Qxe3+',
    'Qxe3 Bc5',
    'Qxc5+ Ndxc5',
    'b4 Nd7',
    'a4 bxa4',
    'Rxa4 Nb8',
    'Be2 Rd6',
    'Rfa1 Rc6',
    'R1a3 d4',
    'Ne4 Rxc2',
    'Bxa6 Nxa6',
    'Rxa6 Rd8',
    'Rxe6 d3',
    'Ra8+ Kd7',
    'Rxd8+ Kxd8',
    'Rxh6 d2',
    'Nf2 Rc1+',
    'Kg2 d1=Q',
    'Nxd1 Rxd1',
    'Rh5 Rd2+',
    'Kg1 Nd6',
    'Rxg5 Ke7',
    'h4 Kf6',
    'b5 Rb2',
    'b6 Rxb6',
    'Kg2 Rb3',
    'Ra5 Ne4',
    'Rf5+ Ke6',
    'Rf3 Rb2+',
    'Kh3 Re2',
    'g5 Nd6',
    'Kg4 Re4+',
    'Rf4 Re1',
    'Rf3 Nf5',
    'h5 Rg1+',
    'Kf4 Rh1',
    'Rf2 Rh4+',
    'Kf3 Nd4+',
    'Ke3 Ke5',
    'h6 Rh3+',
    'Kd2 Nf3+',
    'Kc2 Nxg5',
    'Rf8 Ne6',
    'Rh8 Kd4',
    'h7 Rh2+',
    'Kb3 Nc5+',
    'Kb4 Nb7',
    'Kb5 Nd6+',
    'Kc6 Nf7',
    'Re8 Rxh7',
    'Re1 Ne5+',
    'Kb6 Nc4+',
    'Kc6 Rh6+',
    'Kc7 Kc5',
    'Kd7 Nb6+',
    'Ke7 Nd5+',
    'Kf7 Rf6+',
    'Kg7 Rf2',
    'Kg6 Kd6',
    'Kg5 Rg2+',
    'Kf5 Rf2+',
    'Kg4 Nc7',
    'Kg3 Rf7',
    'Re4 Ne6',
    'Rb4 Nc5',
    'Rf4 Rb7',
    'Rf5 Ne6',
    'Rh5 Rb3+',
    'Kf2 Nc5',
    'Ke2 Ne4',
    'Ra5 Rh3',
    'Ra8 Kd5',
    'Re8 Nd6',
    'Re3 Rh2+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'Be2 Be7',
    'Qd2 Nbd7',
    'Be3 Qc7',
    'g4 h6',
    'f4 b5',
    'a3 Bb7',
    'Bf3 Nc5',
    'Qg2 Nfd7',
    'O-O Nb6',
    'Qe2 g5',
    'e5 d5',
    'f5 Qxe5',
    'fxe6 fxe6',
    'Nb3 Nbd7',
    'Na5 O-O-O',
    'Nxb7 Nxb7',
    'Qd2 Qxe3+',
    'Qxe3 Bc5',
    'Qxc5+ Nbxc5',
    'b4 Nb7',
    'a4 bxa4',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Bg5 e6',
    'Qd2 Be7',
    'O-O-O O-O',
    'f4 Nxd4',
    'Qxd4 Qa5',
    'Kb1 h6',
    'h4 e5',
    'Qd3 exf4',
    'Bxf4 Be6',
    'Be2 Rac8',
    'Nd5 Bxd5',
    'exd5 Qa4',
    'c4 b5',
    'Rc1 Rfe8',
    'Bxh6 bxc4',
    'Qg3 Bf8',
    'Bf3 c3',
    'b3 Ne4',
    'Qg4 Rb8',
    'Rc2 Rxb3+',
  ],
  [
    'd4 Nf6',
    'Bg5 Ne4',
    'Bf4 c5',
    'f3 Qa5+',
    'c3 Nf6',
    'd5 Qb6',
    'Bc1 e6',
    'c4 d6',
    'e4 g6',
    'Nc3 Bg7',
    'Nge2 O-O',
    'Ng3 Nbd7',
    'Be2 exd5',
    'cxd5 h5',
    'a4 a6',
    'Nf1 Qc7',
    'Ne3 Rb8',
    'Nc4 Nb6',
    'Na3 Qe7',
    'O-O Nh7',
    'a5 Nd7',
    'Nc4 Ne5',
    'Nb6 f5',
    'f4 Ng4',
    'e5 Qh4',
    'Bxg4 hxg4',
    'Nc4 dxe5',
    'fxe5 g3',
    'hxg3 Qxg3',
    'Bf4 Qh4',
    'Qe1 Qh5',
    'Qe2 Qxe2',
    'Nxe2 g5',
    'Be3 Bd7',
    'Bxc5 Rfe8',
    'e6 Bb5',
    'Rac1 f4',
    'Rfe1 Bxc4',
    'Rxc4 Rbd8',
    'Rd1 Rxe6',
    'Bb6 Rd7',
    'Rc8+ Bf8',
    'dxe6 Rxd1+',
    'Kf2 Kg7',
    'Rc7+ Kg6',
    'e7 Bxe7',
    'Rxe7 Nf6',
    'Nc3 Rd2+',
    'Re2 Rd3',
    'Kg1 Kf5',
    'Rc2 Rg3',
    'Ne2 Rg4',
    'Nd4+ Ke4',
    'Nf3 Rg3',
    'Bd8 g4',
  ],
  [
    'e4 Nc6',
    'd4 e5',
    'Nf3 exd4',
    'Nxd4 Qh4',
    'Nc3 Bb4',
    'Nb5 Kd8',
    'Be2 a6',
    'Nd4 Nge7',
    'O-O d6',
    'Nf3 Qf6',
    'Nd5 Nxd5',
    'Bg5 Nf4',
    'Bxf6+ gxf6',
    'Kh1 Rg8',
    'g3 Bh3',
    'Rg1 Ne6',
    'Bf1 Bg4',
    'h3 Bh5',
    'Be2 Bg6',
    'Bd3 Ke7',
    'Nh4 Bc5',
    'Rf1 Ng5',
    'f4 Nxh3',
    'Kg2 Nxf4+',
    'Rxf4 Bd4',
    'c3 Be5',
    'Rg4 Bh5',
    'Nf5+ Kd7',
    'Rxg8 Rxg8',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nf6',
    'Nc3 Bc5',
    'Be3 Nxd4',
    'Bxd4 Bxd4',
    'Qxd4 d6',
    'O-O-O Qe7',
    'Bb5+ Bd7',
    'Bxd7+ Qxd7',
    'e5 dxe5',
    'Qxe5+ Qe7',
    'Qa5 b6',
    'Qa4+ Kf8',
    'Rhe1 Qc5',
    'Ne4 Nxe4',
    'Qxe4 Qg5+',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Bg5 e6',
    'Qd2 a6',
    'f4 Be7',
    'O-O-O Nxd4',
    'Qxd4 b5',
    'Bxf6 gxf6',
    'Be2 Bb7',
    'f5 h5',
    'Rhf1 Rc8',
    'fxe6 fxe6',
    'Bd3 Rg8',
    'Qe3 b4',
    'Ne2 Qa5',
    'Kb1 Qc5',
    'Qh3 Qe5',
    'Nf4 Kf7',
    'Be2 f5',
    'Qxh5+ Kf8',
    'Nxe6+ Qxe6',
    'Rxf5+ Bf6',
    'Rdf1 Ke7',
    'Rxf6 Qxf6',
    'Rxf6 Kxf6',
    'Qf5+ Ke7',
    'Qh7+ Kf6',
  ],
  ['e4 c5', 'Nf3 d6', 'd4 cxd4', 'Nxd4 a6', 'Nc3 b5', 'Bd3 Bb7', 'O-O Qb6', 'Nb3 Qc7', 'f4 b4', 'Ne2 Nf6', 'Ng3 h5', 'Qe2 Nc6', 'h3 h4'],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 d6',
    'c4 Nf6',
    'Nc3 Bg4',
    'Qb3 Qc8',
    'd4 Nd7',
    'd5 Na5',
    'Qb4 b6',
    'Nd2 Be7',
    'Qa3 O-O',
    'b4 Nxc4',
    'Nxc4 b5',
    'Na5 bxa4',
    'Nc6 Bh4',
    'h3 Bh5',
    'g3 Bd8',
    'g4 Bg6',
    'Qxa4 f6',
    'Be3 Be8',
    'Ke2 Rf7',
    'Rhc1 g6',
    'f3 Kh8',
    'Qb3 Nb8',
    'Na5 f5',
    'Na4 Bb5+',
    'Kd2 Qd7',
    'Nc3 Qe8',
    'a4 Bd7',
    'Ke2 Qe7',
    'Nb7 Qh4',
    'Nxd8 Qxd8',
    'b5 Qh4',
    'Bf2 Qf6',
    'gxf5 gxf5',
    'bxa6 Nxa6',
    'Qb2 Rff8',
    'Qb3 Rab8',
    'Qa3 Rb4',
    'Rf1 fxe4',
    'Nxe4 Qf7',
    'Bh4 Qxd5',
    'Bf6+ Rxf6',
    'Nxf6 Qc4+',
    'Kd2 Qd4+',
    'Ke1 Rxa4',
  ],
  [
    'e4 c5',
    'c3 e6',
    'd4 d5',
    'exd5 Qxd5',
    'Nf3 Nc6',
    'Be2 Nf6',
    'Na3 Be7',
    'Nb5 Qd8',
    'dxc5 Bxc5',
    'Qxd8+ Kxd8',
    'Bf4 a6',
    'Nd6 Ke7',
    'Nc4 b5',
    'Be3 Nd7',
    'Bxc5+ Nxc5',
    'Nb6 Rb8',
    'Nxc8+ Rhxc8',
    'O-O b4',
    'cxb4 Nxb4',
    'Nd4 e5',
    'Nf5+ Kf8',
    'Ne3 Nc6',
    'b3 Nd4',
    'Bc4 a5',
    'f3 a4',
    'bxa4 Nxa4',
    'Rf2 f5',
    'Bd3 Rc3',
    'Rd2 Rb2',
    'Rad1 Rxd2',
    'Rxd2 Nc5',
    'Bxf5 Rxe3',
    'Bxh7 Na4',
    'Bd3 Re1+',
    'Kf2 Ra1',
    'Bc4 Nc3',
    'Ke3 Ke7',
    'Kd3 Na4',
    'f4 Nc5+',
    'Ke3 Nf5+',
    'Ke2 Rc1',
    'Bb5 Nd4+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'g3 Bg7',
    'Bg2 d5',
    'cxd5 Nxd5',
    'e4 Nb6',
    'Ne2 O-O',
    'O-O Nc6',
    'd5 Na5',
    'Nbc3 c6',
    'b3 cxd5',
    'exd5 Bg4',
    'Bd2 e6',
    'dxe6 fxe6',
    'Rc1 Qd3',
    'Be4 Qd7',
    'f3 Bh3',
    'Re1 Rad8',
    'Bg5 Qxd1',
    'Rexd1 Rxd1+',
    'Rxd1 Bf5',
    'Rd8 Nc6',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 g6',
    'c4 Bg7',
    'Be3 Nf6',
    'Nd2 O-O',
    'Nb5 d6',
    'Be2 Nd7',
    'Nc3 Nc5',
    'O-O f5',
    'exf5 Bxf5',
    'Nb3 Nxb3',
    'Qxb3 Rb8',
    'c5+ Kh8',
    'Rad1 Qh4',
    'Qc4 Qxc4',
    'Bxc4 dxc5',
    'Bxc5 Rfd8',
    'Be3 Nd4',
    'Bd5 c5',
    'Bf4 Rbc8',
    'Bxb7 Rb8',
    'Bxb8 Rxb8',
    'Bd5 Rxb2',
    'Rfe1 Bf6',
    'h3 Bh4',
    'g3 Bf6',
    'Re8+ Kg7',
    'Kg2 Bd7',
    'Rg8+ Kh6',
    'Ne4 Be5',
    'Nxc5 Nf5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bc4 e6',
    'O-O a6',
    'Bb3 Nf6',
    'Re1 b5',
    'c3 c4',
    'Bc2 Nbd7',
    'a4 Rb8',
    'Nd4 Ne5',
    'axb5 axb5',
    'Na3 Bd7',
    'b3 Be7',
    'Qe2 cxb3',
    'Nxb3 O-O',
    'd4 Ng6',
    'Bd2 e5',
    'Bd3 Qb6',
    'Nc2 Rfe8',
    'Nb4 Ra8',
    'd5 Ra4',
    'Nc6 Nf4',
    'Bxf4 exf4',
    'Nbd4 Bf8',
    'Rab1 Nxd5',
    'Rxb5 Qc7',
    'exd5 Rxe2',
    'Bxe2 g6',
    'Reb1 Bc8',
    'Rb8 Kg7',
    'h3 h5',
    'Bd3 Qd7',
    'Re1 Kh7',
    'Bb5 Ra3',
    'Ne7 Qa7',
    'Nec6 Qd7',
    'Ne7 Qa7',
    'Nec6 Qd7',
    'Kh2 f3',
    'Nxf3 Rxc3',
    'Ng5+ Kg7',
    'Ne6+ fxe6',
    'dxe6 Qxe6',
    'Rxe6 Bxe6',
    'Nd4 Bf7',
    'Be8 Bxe8',
    'Rxe8 Kf7',
    'Ra8 Rc4',
    'Ra7+ Kf6',
    'Nf3 Rc2',
    'Kg3 Rc4',
    'h4 Bh6',
    'Kh3 Rc3',
  ],
  [
    'd4 Nf6',
    'Bg5 g6',
    'Bxf6 exf6',
    'e3 Bg7',
    'c4 O-O',
    'Nc3 f5',
    'g3 d6',
    'Bg2 Nd7',
    'Nge2 Nf6',
    'h4 Re8',
    'Qd3 h6',
    'Bf3 c6',
    'O-O-O Qe7',
    'Rde1 Bd7',
    'Nd1 a6',
    'Nec3 b5',
    'b3 Ne4',
    'h5 g5',
    'Bxe4 fxe4',
    'Qe2 Rab8',
    'f3 exf3',
    'Qxf3 bxc4',
    'bxc4 Rb4',
    'Nb2 Reb8',
    'Re2 Qd8',
    'Rc2 Qa5',
    'Rf1 Be6',
    'Nd3 Rxc4',
    'Kd2 c5',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Na3 b5',
    'Nd5 Qa5+',
    'Bd2 Qd8',
    'Bg5 Qa5+',
    'Bd2 Qd8',
    'Nxf6+ Qxf6',
    'Bd3 Qg6',
    'Qf3 Nd4',
    'Qg3 Bb7',
    'Qxg6 hxg6',
    'c4 f5',
    'cxb5 fxe4',
    'bxa6 Bc6',
    'Bf1 d5',
    'Rc1 Bxa3',
    'bxa3 Kd7',
    'Bb4 Rhc8',
    'Kd2 Ba4',
    'Rxc8 Rxc8',
    'Bc3 Nf5',
    'Bxe5 d4',
    'Bf4 Rc2+',
    'Ke1 Rxa2',
    'g3 Ra1+',
    'Ke2 Rd1',
    'f3 e3',
    'Bxe3 dxe3',
    'a7 Rd2+',
    'Ke1 Nd4',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qd6',
    'd4 Nf6',
    'Nf3 a6',
    'h3 Nbd7',
    'Bd3 c5',
    'Be3 e6',
    'Qe2 Be7',
    'O-O-O O-O',
    'dxc5 Qc7',
    'Ne4 Nxe4',
    'Bxe4 Nxc5',
    'Bxc5 Qxc5',
    'Ne5 a5',
    'f4 Ra6',
    'Rd3 Rd6',
    'Rc3 Qb4',
    'Rc4 Qb5',
    'Bxh7+ Kxh7',
    'Qh5+ Kg8',
    'Rxc8 Rxc8',
    'Qxf7+ Kh7',
    'Qh5+ Kg8',
  ],
  ['e4 c5', 'Nf3 d6', 'd4 cxd4', 'Nxd4 Nf6', 'Nc3 a6', 'Be2 e5', 'Nb3 Be7', 'Be3 Be6', 'Qd2 Nbd7', 'f4 Qc7', 'O-O-O b5', 'g4 Rc8'],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Nc3 Qc7',
    'Ndb5 Qb8',
    'Be3 a6',
    'Bb6 axb5',
    'Nxb5 Bb4+',
    'c3 Ba5',
    'Nc7+ Qxc7',
    'Bxc7 Bxc7',
    'Qg4 g6',
    'Bc4 Nge7',
    'Qe2 b6',
    'O-O O-O',
    'a4 Bb7',
    'b4 d5',
    'exd5 Nxd5',
    'Bxd5 exd5',
    'Rfd1 Ne5',
    'f4 Nc4',
    'Qe7 Rac8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 h6',
    'Qd2 Bb7',
    'O-O-O Nbd7',
    'h4 b4',
    'Na4 Qa5',
    'b3 Nc5',
    'a3 Nxa4',
    'axb4 Qc7',
    'bxa4 d5',
    'e5 Nd7',
    'f4 Nb6',
    'f5 Nxa4',
    'fxe6 Nc3',
    'exf7+ Kxf7',
    'Bd3 Bxb4',
    'Qf2+ Kg8',
    'Rdf1 Ba3+',
    'Kd2 Ne4+',
    'Bxe4 dxe4',
    'g5 Rc8',
    'Qf5 Qc3+',
    'Kd1 Bd5',
    'e6 Qa1+',
    'Ke2 Bc4+',
    'Kf2 Qxf1+',
    'Rxf1 Rf8',
    'g6 Rxf5+',
    'Nxf5 Bxf1',
    'Kxf1 Kf8',
    'Bd4 Rg8',
    'c4 Ke8',
    'Ke2 Rf8',
    'Nxg7+ Ke7',
    'Ke3 Rd8',
    'Kxe4 a5',
    'c5 a4',
    'Ke5 Rg8',
    'Nf5+ Kd8',
  ],
  [
    'e4 c5',
    'c3 Nf6',
    'e5 Nd5',
    'd4 cxd4',
    'Nf3 Nc6',
    'Bc4 Nb6',
    'Bb3 d5',
    'exd6 Qxd6',
    'Na3 a6',
    'O-O Be6',
    'Bxe6 Qxe6',
    'Nxd4 Nxd4',
    'Qxd4 Rd8',
    'Qh4 Qc6',
    'Nc2 e6',
    'Nd4 Qc7',
    'Bg5 Be7',
    'Nf5 exf5',
    'Bxe7 Rd7',
    'Bf6 gxf6',
    'Rae1+ Re7',
    'Qxf6 Rg8',
    'Rxe7+ Qxe7',
    'Qxb6 Qe4',
    'f3 Qe2',
    'Qf2 Qxf2+',
    'Kxf2 Ke7',
    'Rd1 Rc8',
    'Ke3 h6',
    'Rd4 Rc6',
    'Kf4 Kf6',
    'g3 Ke6',
    'Rd8 Rc4+',
    'Ke3 Rc6',
    'Rh8 Ke5',
    'f4+ Kd5',
    'Re8 Rf6',
    'Re5+ Kc6',
    'c4 b6',
    'b4 Kd7',
    'Kd4 Kd8',
    'a4 Kd7',
    'a5 bxa5',
    'Rxa5 Ke7',
    'Re5+ Kd7',
    'b5 axb5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 e6',
    'Bb3 b5',
    'O-O b4',
    'Na4 Bd7',
    'Re1 Nc6',
    'c3 Rb8',
    'Bg5 Be7',
    'f4 bxc3',
    'Nxc6 Bxc6',
    'Nxc3 Rb4',
    'f5 Nxe4',
    'Nxe4 Bxe4',
    'Bxe7 Qxe7',
    'fxe6 fxe6',
    'Qh5+ g6',
    'Qa5 d5',
    'Rf1 Qa7+',
    'Kh1 Qb6',
    'Qa3 Rf8',
    'Ba4+ Ke7',
    'Qc3 d4',
    'Qh3 Bf5',
    'Qxh7+ Kf6',
    'Bb3 Qd8',
    'Qh4+ Kg7',
    'Qe1 Qd6',
    'Rd1 Rh8',
    'g3 e5',
    'Qf2 e4',
  ],
  [
    'e4 c5',
    'Nc3 a6',
    'Nf3 b5',
    'd4 cxd4',
    'Nxd4 e6',
    'Bd3 Bb7',
    'O-O Nc6',
    'Nb3 Qc7',
    'f4 h5',
    'Qf3 Nf6',
    'Bd2 Ng4',
    'Rae1 Qb6+',
    'Kh1 Ne7',
    'a4 Bc6',
    'a5 Qa7',
    'Nd5 O-O-O',
    'Nb6+ Kb8',
    'Qe2 d5',
    'f5 Qc7',
    'e5 Nc8',
    'fxe6 f6',
    'Bc3 Be8',
    'h3 fxe5',
    'Bxe5 Nxe5',
    'Qxe5 Bd6',
    'Qe3 Ne7',
    'c3 g5',
    'Qd4 Bc6',
    'Rf7 g4',
    'Nc5 Bxc5',
    'Qxc5 Rde8',
    'Nd7+ Bxd7',
    'Qxc7+ Kxc7',
    'exd7 Kxd7',
    'Rf6 Nc6',
    'Bf5+ Kc7',
    'Rxe8 Rxe8',
    'hxg4 hxg4',
    'Bxg4 Rh8+',
    'Kg1 Rg8',
    'Bf3 Ne7',
    'Rxa6 b4',
    'cxb4 Rb8',
    'Re6 Kd7',
    'Bg4 Ke8',
    'Rb6 Rxb6',
    'axb6 Kd8',
    'b5 d4',
    'Be6 Ng6',
    'g3 Ne5',
    'g4 Nd3',
    'Kf1 Nf4',
    'Bf7 Nh3',
    'Ke1 Kc8',
    'Bd5 Kb8',
    'Kd2 Nf4',
    'Be4 Nh3',
    'Bc6 Kc8',
    'b3 Kb8',
    'Bg2 Nf4',
    'Bf3 Nh3',
    'Kd3 Nf2+',
    'Kxd4 Nxg4',
    'Bxg4 Kb7',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c4 Bg7',
    'Nc3 d5',
    'Qb3 dxc4',
    'Qxc4 O-O',
    'e4 Na6',
    'Be2 c5',
    'd5 e6',
    'O-O exd5',
    'exd5 Bf5',
    'h3 Re8',
    'Be3 Qb6',
    'g4 Bd7',
    'Rfd1 Rac8',
    'a3 Nxg4',
    'hxg4 Qxb2',
    'Rac1 Bd4',
    'Nxd4 cxd4',
    'Qxd4 Rxc3',
    'Rxc3 Qxe2',
    'd6 Re4',
    'Qxe4 Qxd1+',
    'Kg2 Qxd6',
    'Qxb7 Nb8',
    'Qb4 Qd5+',
    'f3 Nc6',
    'Qc5 Qe6',
    'Bh6 Qe2+',
    'Qf2 Qb5',
    'Qd2 f6',
    'a4 Qa6',
    'Qd5+ Kh8',
    'Re3 Ne5',
  ],
  [
    'e4 c5',
    'Nc3 e6',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Be3 Nc6',
    'f4 Be7',
    'Qf3 O-O',
    'O-O-O Qc7',
    'g4 a6',
    'g5 Nd7',
    'Qg3 Re8',
    'f5 Nxd4',
    'Bxd4 Ne5',
    'Bh3 b5',
    'g6 hxg6',
    'Bxe5 dxe5',
    'fxg6 Bf6',
    'Rdf1 Qe7',
    'Rhg1 Rf8',
    'gxf7+ Rxf7',
    'Bg4 Bg5+',
    'Kb1 Rxf1+',
    'Rxf1 Bf4',
    'Qh3 Bd7',
    'Nd5 Qd6',
    'Nxf4 exf4',
  ],
  [
    'e4 e5',
    'Nf3 Nf6',
    'Nxe5 d6',
    'Nf3 Nxe4',
    'Bd3 Nc5',
    'Be2 Be7',
    'O-O d5',
    'd4 Ne4',
    'c4 c6',
    'Nc3 O-O',
    'Qb3 dxc4',
    'Bxc4 Nd6',
    'Bd3 Bf6',
    'Qc2 h6',
    'Bf4 Bg4',
    'Ne5 Be6',
    'Rad1 Ne8',
    'Rfe1 Bg5',
    'Bxg5 Qxg5',
    'Ne4 Qh4',
    'Nc5 Bd5',
    'Nxb7 Nf6',
    'Nd6 Ng4',
  ],
  [
    'e4 c5',
    'f4 Nc6',
    'Nf3 g6',
    'c3 Bg7',
    'd4 d5',
    'e5 f6',
    'Bb5 Qb6',
    'Qe2 c4',
    'Na3 a6',
    'Ba4 Bf5',
    'Bc2 Bxc2',
    'Nxc2 Nh6',
    'O-O O-O',
    'Ne3 e6',
    'exf6 Rxf6',
    'h3 Qc7',
    'Ng4 Nxg4',
    'hxg4 Re8',
    'Be3 Bf8',
    'g5 Rf5',
    'Nh2 Nb8',
    'Rf3 Bd6',
    'Ng4 Nd7',
    'Raf1 h5',
    'gxh6 Kh7',
    'Nf2 Kxh6',
    'Nh3 Nf6',
    'Ng5 Kg7',
    'Rh3 Rxg5',
    'fxg5 Ne4',
    'Qg4 Rh8',
    'Rff3 Bh2+',
    'Kh1 Bd6',
    'Qxe6 Rxh3+',
    'Rxh3 Qe7',
    'Qg4 Qf7',
    'Rf3 Qe7',
    'Qh4 Kg8',
    'Kg1 b5',
    'Rh3 Qg7',
    'Qg4 Qf8',
    'Qe6+ Kg7',
    'Qxd5 Qe8',
    'Qb7+ Qe7',
    'Qxe7+ Bxe7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 dxe4',
    'Nxe4 Nf6',
    'Bg5 Be7',
    'Bxf6 Bxf6',
    'Nf3 Nd7',
    'c3 Qe7',
    'Bd3 b6',
    'Qe2 Bb7',
    'O-O-O a5',
    'g4 g6',
    'h4 h5',
    'gxh5 Rxh5',
    'Ng3 Rh8',
    'Be4 Ba6',
    'Qe3 O-O-O',
    'h5 g5',
    'Nh2 Qd6',
    'Ng4 Qf4',
    'Bf3 Be7',
    'd5 Bc4',
    'b3 f5',
    'dxe6 Bxe6',
    'Ne2 Qxe3+',
    'Nxe3 Ne5',
    'Bd5 Bd7',
    'Nd4 f4',
    'Nef5 Ba3+',
    'Kb1 Ng4',
    'Rd2 Nf6',
    'c4 c6',
    'Ng7 cxd5',
    'Nde6 Rdg8',
    'h6 Kb7',
    'cxd5 Ng4',
    'Rc2 Rc8',
    'Rxc8 Bxc8',
    'h7 Be7',
    'Rc1 Bf6',
    'Rc7+ Kb8',
    'Rc6 Rxh7',
    'Rxb6+ Ka7',
    'Rc6 Bb7',
    'Rc7 Be5',
    'Re7 Kb6',
    'f3 Ne3',
    'Kc1 Bc3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'g4 b5',
    'g5 Nfd7',
    'Qh5 g6',
    'Qe2 b4',
    'Nd1 Bb7',
    'f3 d5',
    'Nf2 Bg7',
    'O-O-O e5',
    'exd5 exd4',
    'Bd2+ Kf8',
    'Bxb4+ Kg8',
    'Be7 Qa5',
    'Ba3 Qxd5',
    'Qe8+ Nf8',
    'Qe7 Qd7',
    'Ne4 Qxe7',
    'Bxe7 Bxe4',
    'fxe4 Nc6',
    'Bd6 Ne6',
    'Bc4 Rd8',
    'Bg3 Be5',
    'Bxa6 Bxg3',
    'hxg3 Nb4',
    'Bc4 Nxg5',
    'c3 Nc6',
    'Bd5 Ne7',
    'Rxd4 Kg7',
    'a4 f5',
    'Rhd1 Rd7',
    'a5 Ra7',
    'b4 Kf6',
    'c4 Rd8',
    'c5 fxe4',
    'Bxe4 Rxd4',
    'Rxd4 Ke5',
    'Rd6 Nxe4',
    'Rb6 Nxc5',
    'Rb5 Rc7',
    'a6 Nc8',
    'bxc5 Kd5',
    'Rb7 Rxc5+',
    'Kd2 Ra5',
    'Rc7 Nd6',
    'a7 Nb5',
    'Rb7 Nxa7',
    'Rxh7 Nc6',
    'Ke3 Ke6',
    'Kf3 Kf6',
    'Rb7 Nd4+',
    'Kg4 Nf5',
    'Rb6+ Kg7',
    'Rb3 Kh6',
    'Rc3 Ra4+',
    'Kh3 Kh5',
    'Rf3 Rg4',
    'Kh2 Nxg3',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Nc3 Qb6',
    'Bd3 Bxd3',
    'Qxd3 e6',
    'Nge2 Ne7',
    'O-O h5',
    'Nd1 Nd7',
    'Be3 Nf5',
    'Ng3 g6',
    'b3 c5',
    'Nxf5 gxf5',
    'f4 Rc8',
    'Nc3 cxd4',
    'Na4 Qc6',
    'Bxd4 b5',
    'Nc3 a6',
    'a4 b4',
    'Na2 a5',
    'Rac1 Nc5',
    'Qd1 Ne4',
    'c3 bxc3',
    'Nxc3 Ba3',
    'Nxe4 Qxc1',
    'Nd6+ Kd7',
    'Nxc8 Qxc8',
    'Qd2 Qc7',
    'h3 Rb8',
    'Qe2 Rb4',
    'Ba1 Qb6+',
    'Kh2 h4',
    'Qc2 Rxb3',
    'Bc3 Be7',
    'Bxa5 Rb2',
    'Qc3 Rb3',
    'Bxb6 Rxc3',
    'a5 Kc6',
    'Ra1 Kb7',
    'a6+ Ka8',
    'Rb1 Ra3',
    'a7 Ra4',
    'Bf2 d4',
    'Rd1 Rxa7',
    'Rxd4 Rc7',
    'Ra4+ Kb7',
    'Ra2 Kc8',
    'g3 hxg3+',
    'Bxg3 Kd7',
    'h4 Rc8',
    'Ra7+ Ke8',
    'h5 Kf8',
    'Kh3 Bc5',
    'Rb7 Kg8',
    'Bh4 Bf8',
    'Bg5 Bg7',
    'h6 Bh8',
    'Kh4 Rf8',
    'Be7 Rc8',
    'Kh5 Ra8',
    'Rd7 Rc8',
    'Kg5 Rc1',
    'Rd8+ Kh7',
    'Rf8 Rg1+',
    'Kh5 Rh1+',
    'Bh4 Rf1',
    'Rxf7+ Kg8',
  ],
  [
    'd4 Nf6',
    'Bg5 e6',
    'e4 c5',
    'e5 h6',
    'Bc1 Nd5',
    'c4 Nb4',
    'd5 d6',
    'a3 N4a6',
    'exd6 Bxd6',
    'Nc3 O-O',
    'Bd3 Nc7',
    'Nge2 exd5',
    'cxd5 Nd7',
    'O-O Nf6',
    'Bc4 b6',
    'Ng3 Be5',
    'Nh5 Bb7',
    'Bf4 Bxf4',
    'Nxf4 Qd6',
    'Qf3 Ne6',
    'Nxe6 fxe6',
    'Rad1 Nxd5',
    'Nxd5 Rxf3',
    'Nf6+ Rxf6',
    'Rxd6 Re8',
    'Re1 Kf7',
    'b4 cxb4',
    'axb4 Re7',
    'f3 Bc8',
    'Rc1 Bd7',
    'Ra1 Rf4',
    'Rxa7 Bc8',
    'Bxe6+ Bxe6',
    'Rxe7+ Kxe7',
    'Rxb6 Rc4',
    'h3 Rc2',
    'Ra6 Rb2',
    'Rb6 Bc4',
    'h4 Kf7',
    'h5 Bd5',
    'Rd6 Bc4',
    'Rb6 Kg8',
    'Rb8+ Kh7',
    'Rb6 Bf7',
    'Rb5 Be8',
    'Re5 Bc6',
    'Rc5 Ba4',
    'Rc4 Be8',
    'Rh4 Kg8',
    'Kf1 Rd2',
    'g4 Rb2',
    'Rh1 Kf7',
    'Rh3 Kf6',
    'f4 Rxb4',
    'Rf3 Bd7',
    'g5+ hxg5',
    'fxg5+ Kxg5',
    'Rg3+ Bg4',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 e5',
    'Bc4 Be7',
    'd3 Nf6',
    'O-O O-O',
    'h3 d6',
    'Nh2 a6',
    'a4 Be6',
    'f4 exf4',
    'Bxf4 d5',
    'exd5 Nxd5',
    'Nxd5 Bxd5',
    'Bxd5 Qxd5',
    'Ng4 Rad8',
    'Ne3 Qd4',
    'Kh1 f5',
    'Qe2 Qd7',
    'Nc4 Nd4',
    'Qf2 b5',
    'axb5 axb5',
    'Ne5 Qb7',
    'c3 Ne6',
    'Qc2 Nxf4',
    'Rxf4 Bd6',
    'd4 g6',
    'Re1 Rfe8',
    'Rf3 Bxe5',
    'Rfe3 cxd4',
    'cxd4 Rxd4',
    'Rxe5 Rxe5',
    'Rxe5 b4',
    'Re8+ Kf7',
    'Rh8 Kf6',
    'Rf8+ Kg7',
    'Rc8 Qd5',
    'Qc7+ Kh6',
    'Rh8 Rd1+',
    'Kh2 Qd6+',
    'Qxd6 Rxd6',
    'Rb8 Rd4',
    'Rb7 g5',
    'Rb6+ Kh5',
    'Rb5 f4',
    'Rb6 Rc4',
    'Rb7 h6',
    'b3 Re4',
    'Rb6 Rd4',
    'g4+ fxg3+',
    'Kxg3 Rd3+',
    'Kh2 Rxb3',
    'Kg2 Rb1',
    'Kg3 b3',
    'Kf3 g4+',
    'hxg4+ Kg5',
    'Kg3 b2',
    'Kg2 Kxg4',
    'Rb4+ Kf5',
    'Rb5+ Ke4',
    'Rb4+ Kd5',
    'Rb5+ Kc4',
    'Rb8 h5',
    'Rc8+ Kd5',
    'Rd8+ Kc6',
    'Rb8 h4',
    'Rb3 h3+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'h3 b5',
    'g4 Bb7',
    'Bg2 e5',
    'Nde2 b4',
    'Nd5 Nxd5',
    'exd5 g6',
    'O-O Bg7',
    'Qe1 a5',
    'a3 Na6',
    'Bd2 Qb6',
    'Be3 Qc7',
    'Qd2 O-O',
    'g5 Rac8',
    'Rfc1 e4',
    'Rab1 Rfe8',
    'Ng3 Qc4',
    'Bf1 Qa2',
    'Bxa6 Bxa6',
    'axb4 axb4',
    'Qxb4 Rxc2',
    'Rxc2 Qxb1+',
    'Rc1 Qd3',
    'Rc6 Be5',
    'Qxe4 Qd1+',
    'Kg2 Bb5',
    'Rc1 Qb3',
    'Qf3 Bxb2',
    'Rc7 Rf8',
    'Bf4 Bc4',
    'Qxb3 Bxb3',
    'Bxd6 Bxd5+',
    'f3 Re8',
    'Ne4 Bxe4',
    'fxe4 Be5',
    'Re7 Rxe7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nge7',
    'Bc4 Nxd4',
    'Qxd4 Nc6',
    'Qd5 Qe7',
    'Nc3 Ne5',
    'Be2 c6',
    'Qd1 g5',
    'Be3 d6',
    'Qd2 Rg8',
    'O-O-O Be6',
    'Qd4 Qc7',
    'h4 h6',
    'hxg5 hxg5',
    'Rh5 Be7',
    'g3 g4',
    'Bf4 Qb6',
    'Qd2 Qa5',
    'Qd4 Nd3+',
    'Rxd3 Qxh5',
    'Bxd6 Bxd6',
    'Qxd6 Qg5+',
    'Re3 Rd8',
    'Qc7 Qe7',
    'Qe5 Rg5',
    'Qh8+ Qf8',
    'Qh4 Rg6',
    'Nd5 Qd6',
    'Bc4 cxd5',
    'exd5 Qb4',
    'Qh8+ Ke7',
    'Qd4 b5',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'O-O Na6',
    'Be3 Ng4',
    'Bg5 Qe8',
    'dxe5 dxe5',
    'h3 Nf6',
    'a3 Nc5',
    'Qc2 Ne6',
    'c5 Nxg5',
    'Nxg5 Qe7',
    'b4 Nh5',
    'Nf3 Nf4',
    'Rfd1 c6',
    'Rd6 Ne6',
    'Bc4 Nd4',
    'Qd1 b5',
    'Bf1 Be6',
    'Nxd4 exd4',
    'Ne2 Be5',
    'Nxd4 Bd7',
    'Nb3 Rad8',
    'Ra2 Bxd6',
    'cxd6 Qg5',
    'Nc5 Bxh3',
    'Qd4 Be6',
    'Rd2 h5',
    'e5 Bd5',
    'f4 Qg4',
    'Bxb5 h4',
    'Bc4 h3',
    'Ne4 h2+',
    'Kh1 Bxe4',
    'Qxe4 Kg7',
    'Qd4 c5',
    'bxc5 Rb8',
    'Ba2 Qg3',
    'e6+ f6',
    'Rd1 Qg4',
    'e7 Rh8',
    'd7 Rb2',
    'Bd5 Rhb8',
    'e8=Q Rb1',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'g4 b5',
    'f3 Bb7',
    'Qd2 Nfd7',
    'O-O-O Nb6',
    'Qf2 N8d7',
    'Bd3 Rc8',
    'Nce2 Nc5',
    'Kb1 Be7',
    'h4 Nba4',
    'Ng3 Qc7',
    'Bc1 Qb6',
    'Nb3 Nxd3',
    'Qxb6 Nxb6',
    'cxd3 Kd7',
    'Be3 Na4',
    'Bd4 Bf6',
    'Bxf6 gxf6',
    'Na5 Ba8',
    'Rc1 Nc5',
    'Rhd1 Ke7',
    'd4 Nd7',
    'Rxc8 Rxc8',
    'Rc1 Rxc1+',
    'Kxc1 d5',
    'Kd2 e5',
    'Nf5+ Kd8',
    'Ke3 dxe4',
    'fxe4 exd4+',
    'Kxd4 Ne5',
    'g5 Nf3+',
    'Ke3 Ne5',
    'Nh6 fxg5',
    'hxg5 Ke7',
    'Ng8+ Ke6',
    'Nf6 h6',
    'Ne8 Kd7',
    'Nf6+ Ke6',
    'Nh7 hxg5',
    'Nxg5+ Kd6',
    'Nh3 Kc5',
    'Nb3+ Kd6',
    'Nd2 Bc6',
    'Ng5 Bd7',
    'Ngf3 Nxf3',
    'Nxf3 Be6',
    'a3 Kc5',
    'Ne5 a5',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'Qa4+ Bd7',
    'Qh4 Bc6',
    'Qd4 f6',
    'e3 Bg7',
    'b4 a6',
    'Bb2 e5',
    'Qc4 Nb6',
    'Qb3 Bd5',
    'Nxd5 Qxd5',
    'Qxd5 Nxd5',
    'a3 Nd7',
    'Rc1 f5',
    'Bc4 N5b6',
    'Bb3 c6',
    'd3 Bf6',
    'e4 fxe4',
    'dxe4 Nc8',
    'O-O Nd6',
    'Rfd1 Ke7',
    'Rd3 Rhd8',
    'Rcd1 Nxe4',
    'Re1 Ng5',
    'Nxe5 Nxe5',
    'Rde3 Kf8',
    'Bxe5 Bxe5',
    'Rxe5 Re8',
    'Kf1 Rxe5',
    'Rxe5 Nf7',
    'Re3 Nd6',
    'Rd3 Rd8',
    'h4 b6',
    'Rc3 Rc8',
    'Rf3+ Kg7',
    'Rd3 Rd8',
    'Rc3 Ne4',
    'Re3 Rd4',
    'Ke1 c5',
    'Bc2 Nd6',
    'bxc5 bxc5',
    'Re7+ Kg8',
    'h5 gxh5',
    'Ra7 c4',
    'Rxa6 Nb5',
    'Ra5 Nc3',
    'Rxh5 Rd7',
    'Rc5 Ra7',
    'Rxc4 Rxa3',
    'Rc8+ Kg7',
    'Rc7+ Kf6',
    'Bxh7 Ra1+',
    'Kd2 Nd5',
    'Rc1 Ra4',
    'g3 Rd4+',
    'Ke2 Rb4',
    'Rc5 Rd4',
    'Bg8 Ne7',
    'Bc4 Ng6',
    'Ke3 Rg4',
    'Rc6+ Kg7',
    'Rc7+ Kh8',
    'Be6 Rg5',
    'Bf7 Nf8',
    'Kf4 Ra5',
    'Kg4 Nh7',
    'Bc4 Nf6+',
    'Kf4 Nh5+',
    'Kf3 Nf6',
    'Rf7 Nh7',
    'Kg4 Ra4',
    'Rc7 Ra5',
    'f4 Nf6+',
    'Kh3 Rh5+',
    'Kg2 Ra5',
    'Rf7 Ne4',
    'Bd3 Nc5',
    'Bf5 Kg8',
    'Re7 Kf8',
    'Re5 Ra2+',
    'Kh3 Nb7',
    'Rd5 Na5',
    'Rd8+ Ke7',
    'Ra8 Ra3',
    'Kh4 Nc4',
    'Rxa3 Nxa3',
    'g4 Nc4',
    'g5 Nd6',
    'Bd3 Kf8',
    'Kg4 Nf7',
    'Bc4 Nd6',
    'Bd5 Nb5',
    'f5 Kg7',
    'Kh5 Nd6',
    'f6+ Kh8',
    'g6 Ne8',
    'f7 Nf6+',
    'Kg5 Nd7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Qb6',
    'Bxf6 gxf6',
    'Qd2 Qxb2',
    'Rb1 Qa3',
    'Be2 Bg7',
    'O-O f5',
    'Kh1 Qc5',
    'e5 dxe5',
    'Na4 Qxd4',
    'Qxd4 exd4',
    'Nb6 Nd7',
    'Nxa8 b5',
    'Bf3 Nc5',
    'Nb6 Bd7',
    'Rbd1 Ne4',
    'Rd3 Bc6',
    'a4 bxa4',
    'Ra3 Bb5',
    'Bxe4 fxe4',
    'Re1 f5',
    'Nxa4 Kf7',
    'Ra2 Rc8',
    'Nb6 Rc7',
    'g3 Bf8',
    'Rd1 e3',
    'Kg2 e2',
    'Rb1 Bb4',
    'Kf2 Bc5',
    'Na4 d3+',
    'Ke1 dxc2',
  ],
  ['e4 c5', 'Nf3 d6', 'Bc4 Nf6', 'd3 e6', 'Bb3 Nc6', 'c3 Be7'],
  [
    'e4 g6',
    'd4 Bg7',
    'Nc3 d5',
    'exd5 Nf6',
    'Bb5+ Nbd7',
    'Nf3 O-O',
    'O-O Nb6',
    'Be2 Bg4',
    'h3 Bxf3',
    'Bxf3 Nbxd5',
    'Nxd5 Nxd5',
    'Qe2 c6',
    'c4 Nc7',
    'Be3 Bxd4',
    'Bxd4 Qxd4',
    'Qxe7 Rac8',
    'b3 Rfe8',
    'Qb4 Qb6',
    'Qc3 Ne6',
    'Rad1 c5',
    'Kh1 Nd4',
    'Bd5 a5',
    'Rfe1 Qc7',
    'Qg3 Qxg3',
    'fxg3 b6',
    'Rf1 Rc7',
    'Rf6 Re2',
    'g4 Kg7',
    'Rxb6 Rxa2',
    'Rf1 f5',
    'g5 Re2',
    'Rb8 h6',
    'h4 hxg5',
    'hxg5 Ne6',
    'Re8 Kf7',
    'Rxe6 Rxe6',
    'Re1 Rc6',
    'Re5 Ke7',
    'Bxe6 Rxe6',
    'Rxc5 Re3',
    'Rb5 Rc3',
    'Kh2 a4',
    'bxa4 Rxc4',
    'Rb7+ Ke6',
    'a5 f4',
    'Rb5 Rc3',
    'Kg1 Rc2',
    'Rb4 Ra2',
    'Rxf4 Rxa5',
    'Rf6+ Ke7',
    'Rxg6 Kf7',
    'Rc6 Rxg5',
    'Kf2 Kg7',
    'Kf3 Ra5',
    'Kg4 Rb5',
    'Kh4 Ra5',
    'Re6 Kf7',
    'Rh6 Kg7',
    'Rh5 Ra6',
    'g3 Rb6',
    'Rd5 Ra6',
    'Rd7+ Kg8',
    'Kh5 Rb6',
    'Kg5 Ra6',
    'Kf5 Rb6',
    'Re7 Ra6',
    'Re6 Ra7',
    'g4 Kf7',
    'Re5 Ra6',
    'Kg5 Kg7',
    'Rf5 Rb6',
    'Rd5 Ra6',
    'Rd7+ Kg8',
    'Kf5 Rb6',
    'g5 Ra6',
    'Re7 Rb6',
    'Kg4 Ra6',
    'Kh5 Rb6',
    'Kh4 Ra6',
    'Kg3 Ra4',
    'Kf3 Rb4',
    'Kg3 Ra4',
    'Kh3 Rb4',
    'Rd7 Ra4',
    'Kg3 Rb4',
    'Kf3 Ra4',
    'Re7 Rb4',
    'Re4 Rxe4',
    'Kxe4 Kg7',
    'Kf5 Kf7',
    'g6+ Kg7',
    'Kg5 Kg8',
    'Kh6 Kh8',
    'g7+ Kg8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e6',
    'Be3 a6',
    'f3 b5',
    'g4 Bb7',
    'Qd2 Qc7',
    'O-O-O Be7',
    'h4 Nfd7',
    'Bd3 Nb6',
    'Qf2 N8d7',
    'Kb1 Nc5',
    'Bxb5+ axb5',
    'Ndxb5 Qc6',
    'Nxd6+ Bxd6',
    'Rxd6 Qxd6',
    'Bxc5 Qb8',
    'Bxb6 O-O',
    'Rd1 Bc6',
    'b3 Rc8',
    'a4 Be8',
    'Kb2 Ra6',
    'a5 Qb7',
    'Rd6 Rc6',
    'Qd4 Ra8',
    'Rxc6 Bxc6',
  ],
  [
    'c4 Nf6',
    'Nc3 g6',
    'e4 d6',
    'd4 Bg7',
    'f3 O-O',
    'Nge2 c5',
    'd5 e6',
    'Bg5 h6',
    'Be3 exd5',
    'cxd5 Nbd7',
    'Qd2 Ne5',
    'Ng3 h5',
    'Bg5 h4',
    'Nge2 h3',
    'Ng3 hxg2',
    'Bxg2 b5',
    'O-O Qb6',
    'b3 Re8',
    'Kh1 Nh7',
    'Bh6 Bh8',
    'Be3 Qa5',
    'Rac1 Ba6',
    'f4 Nd3',
    'Rc2 c4',
    'bxc4 bxc4',
    'e5 f5',
    'exf6 Nxf6',
    'f5 Nb4',
    'Rcc1 Nd3',
    'Bd4 Nxc1',
    'Qg5 Qc7',
    'fxg6 Nd3',
    'Nf5 Ng4',
    'g7 Nge5',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Bf4 Bg7',
    'e3 O-O',
    'Be2 d6',
    'h3 Nbd7',
    'O-O Ne4',
    'c4 e5',
    'Bh2 Qe7',
    'Qc2 f5',
    'dxe5 dxe5',
    'Nbd2 Nec5',
    'Nb3 a5',
    'Rad1 b6',
    'Nxc5 Nxc5',
    'Nd2 Bb7',
    'Bf3 e4',
    'Be2 Rad8',
    'Nb1 g5',
    'Nc3 f4',
    'Nd5 Bxd5',
    'Rxd5 Rxd5',
    'cxd5 Be5',
    'Bc4 Qd6',
    'exf4 gxf4',
    'b3 Kh8',
    'Qd1 Qg6',
    'Qg4 Qxg4',
    'hxg4 Bd6',
    'Rd1 Rg8',
    'f3 h5',
    'Be2 hxg4',
    'fxg4 Kg7',
    'Kf1 Kf6',
    'Bc4 Kg6',
    'Kf2 Kg5',
    'Ke1 Kxg4',
    'Be2+ f3',
    'gxf3+ exf3',
    'Bxd6 cxd6',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 a5',
    'Ba3 Nd7',
    'bxa5 Rxa5',
    'Bb4 Ra8',
    'a4 Bh6',
    'Nd2 f5',
    'Bd3 Nf6',
    'c5 Bxd2',
    'Qxd2 fxe4',
    'Nxe4 Nxe4',
    'Bxe4 Bf5',
    'f3 Qd7',
    'a5 Ra6',
    'h3 Nc8',
    'Qc3 Qe7',
    'Rac1 Rf7',
    'Rce1 dxc5',
    'Bxc5 Nd6',
    'Bxf5 Rxf5',
    'g4 Rf4',
    'Rxe5 Qh4',
    'Kg2 Ra4',
    'Rf2 Qd8',
    'Rfe2 Rf4',
    'Qe3 Rxa5',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 g6',
    'f4 Bg7',
    'Nf3 O-O',
    'Be2 c6',
    'O-O Na6',
    'e5 Ne8',
    'Be3 Nac7',
    'a4 b6',
    'Nd2 Bb7',
    'Bf3 Rb8',
    'Nb3 f6',
    'exd6 Nxd6',
    'Bf2 e5',
    'dxe5 fxe5',
    'Bh4 Qxh4',
    'Qxd6 Rf7',
    'Rad1 exf4',
    'Bxc6 Bf8',
    'Qd4 Bxc6',
    'Qc4 Qf6',
    'Nd4 Bc5',
    'b4 Bxd4+',
    'Rxd4 f3',
    'gxf3 Kg7',
    'Ne4 Bxe4',
    'fxe4 Rd8',
  ],
  [
    'e4 d5',
    'Nc3 d4',
    'Nce2 e5',
    'Ng3 Nf6',
    'Nf3 Bd6',
    'Bc4 O-O',
    'O-O Bg4',
    'h3 Bh5',
    'Nxh5 Nxh5',
    'Nxd4 Ng3',
    'fxg3 exd4',
    'd3 Bxg3',
    'Rxf7 Rxf7',
    'Bxf7+ Kh8',
    'Qg4 Be5',
    'Bg5 Bf6',
    'Bxf6 gxf6',
    'Rf1 Nd7',
    'Be6 Nc5',
    'Bf5 Qe7',
    'Bxh7 Qxh7',
    'Rf5 Rg8',
    'Qf3 Rg5',
    'Rxg5 fxg5',
    'Qf8+ Qg8',
    'Qxc5 Qg7',
    'Qxa7 b6',
    'Qa8+ Kh7',
    'Qc8 Qe5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Be3 e5',
    'Nge2 c6',
    'Qd2 exd4',
    'Nxd4 a6',
    'O-O-O b5',
    'Bh6 Qb6',
    'Bxg7 Kxg7',
    'g4 Nbd7',
    'h4 Ne5',
    'Be2 bxc4',
    'h5 Rb8',
    'hxg6 fxg6',
    'Na4 Qb4',
    'Qxb4 Rxb4',
    'Nc3 Ne8',
    'Rhf1 Rf4',
    'Nc2 Rb8',
    'Ne3 Be6',
    'Ng2 Rf6',
    'Ne3 Rf4',
    'Ng2 Rf7',
    'Ne3 g5',
    'Nf5+ Bxf5',
    'gxf5 Rfb7',
    'Rd2 h5',
    'f4 gxf4',
    'Rxf4 Nf6',
    'Rh4 Rd7',
    'Bxh5 Nd3+',
    'Kb1 Rdb7',
    'Rg2+ Kf8',
    'Bd1 Rxb2+',
    'Rxb2 Rxb2+',
    'Ka1 Rg2',
    'a3 Nc5',
    'e5 Nd5',
    'Rxc4 Nxc3',
    'Rxc3 Rg1',
    'Rc1 Nb3+',
    'Bxb3 Rxc1+',
    'Kb2 Rf1',
    'exd6 Rxf5',
    'd7 Ke7',
    'Be6 Rf3',
    'Bg4 Rg3',
    'Bf5 c5',
    'Ka2 Rf3',
    'Bg4 Rd3',
    'Bf5 Rd4',
    'Kb2 Kf6',
    'Bh3 Ke5',
    'Kc3 Kf6',
    'Kb2 Ke7',
    'Bf5 Rf4',
    'Bh3 Rf2+',
    'Kb1 Rf3',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'f4 g6',
    'Nf3 Nc6',
    'Bb5 Bd7',
    'Bxc6 Bxc6',
    'd3 Bg7',
    'O-O e6',
    'Qe1 Ne7',
    'Qh4 O-O',
    'Bd2 b5',
    'Rae1 b4',
    'Nd1 a5',
    'Ne3 d5',
    'Bc1 dxe4',
    'dxe4 Nc8',
    'Qh3 Nd6',
    'Ng5 h6',
    'Nxe6 Bd7',
    'Nxd8 Bxh3',
    'Nxf7 Rxf7',
    'e5 Bxg2',
    'Kxg2 Nf5',
    'Nc4 a4',
    'Be3 Nxe3+',
    'Rxe3 Rd8',
    'Nd6 Re7',
    'Rd3 Re6',
    'Ne4 Rxd3',
    'cxd3 Bf8',
    'Rc1 g5',
    'Nxc5 Bxc5',
    'Rxc5 gxf4',
    'Kf3 Rg6',
    'Rc4 a3',
    'Rxb4 axb2',
    'Rxb2 Rg1',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'O-O c6',
    'a4 a5',
    'Bf4 Na6',
    'Qd2 Nb4',
    'Rac1 Qb6',
    'Bh6 Bg4',
    'e5 dxe5',
    'dxe5 Nfd5',
    'Ne4 Rad8',
    'Qg5 Bxf3',
    'Bxf3 f6',
    'Qh4 Na6',
    'c4 Ndc7',
    'Rc3 Qd4',
    'exf6 exf6',
    'Be3 Qd7',
    'Rcc1 Qe7',
    'Rfe1 Qb4',
    'Rcd1 f5',
    'Ng5 h6',
    'Nh3 Rfe8',
    'Kf1 Rxd1',
    'Rxd1 Ne6',
    'Bxh6 Nd4',
    'Qg5 Qxc4+',
    'Kg1 Qd3',
    'Rc1 Nxf3+',
    'gxf3 Qxf3',
    'Qxg6 Qg4+',
    'Qxg4 fxg4',
    'Bxg7 Kxg7',
    'Ng5 Re2',
    'f4 gxf3',
    'Nxf3 Rxb2',
  ],
  [
    'e4 c6',
    'd4 d5',
    'exd5 cxd5',
    'Bd3 Nc6',
    'c3 Nf6',
    'Bf4 Bg4',
    'Qb3 Qd7',
    'Nd2 e6',
    'Ngf3 Bxf3',
    'Nxf3 Bd6',
    'Bxd6 Qxd6',
    'O-O O-O',
    'Rae1 Qc7',
    'Qc2 g6',
    'Qd2 Kg7',
    'Ne5 Nxe5',
    'dxe5 Nd7',
    'Qf4 Kg8',
    'Qg5 Qd8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'c3 Nf6',
    'Bd3 Bg4',
    'Bc2 Nc6',
    'd3 g6',
    'Nbd2 Bg7',
    'h3 Bxf3',
    'Nxf3 O-O',
    'O-O Re8',
    'Be3 Nd7',
    'd4 cxd4',
    'cxd4 e5',
    'Bb3 Nf6',
    'dxe5 Nxe5',
    'Nxe5 dxe5',
    'Qxd8 Rexd8',
    'f3 b6',
    'Rac1 h6',
    'Rfd1 Rxd1+',
    'Rxd1 Bf8',
    'Kf1 Kg7',
    'h4 Be7',
    'Ke2 Rd8',
    'g4 Nd7',
    'g5 hxg5',
    'hxg5 Bc5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'O-O Nc6',
    'Nc3 a6',
    'b3 Bg4',
    'Bb2 Bxf3',
    'Bxf3 Nd7',
    'd5 Na5',
    'Qd2 c5',
    'dxc6 Nxc6',
    'Nd5 e6',
    'Bxg7 Kxg7',
    'Ne3 Nf6',
    'Rfd1 Ne8',
    'Rac1 Qe7',
    'Qb2+ Nf6',
    'Ng4 Rfd8',
    'Bxc6 bxc6',
    'c5 dxc5',
    'Rxd8 Rxd8',
    'Rxc5 Rd1+',
  ],
  [
    'd4 Nf6',
    'Bg5 g6',
    'Bxf6 exf6',
    'c4 f5',
    'Nc3 d6',
    'g3 Nd7',
    'Bg2 Nf6',
    'e3 a5',
    'h4 Bg7',
    'Nh3 O-O',
    'Nd5 Ne4',
    'Ndf4 Re8',
    'O-O h6',
    'Kh2 Bd7',
    'Rc1 c5',
    'f3 Nf6',
    'Qd2 Bc6',
    'Nd3 Qb6',
    'd5 Bd7',
    'Nhf2 Re7',
    'Rfe1 Rae8',
    'b3 Qa6',
    'a4 Qb6',
    'Rb1 Qd8',
    'Re2 g5',
    'hxg5 hxg5',
    'Rbe1 Kh7',
    'Nh3 Bh6',
    'e4 fxe4',
    'fxe4 Bxh3',
    'Bxh3 g4',
    'Qb2 Bg7',
    'e5 dxe5',
    'Bg2 e4',
    'Nf2 Nxd5',
    'Bxe4+ Kh8',
    'Qd2 Nc3',
    'Qf4 Nxe2',
    'Rxe2 Qb6',
    'Nxg4 Qe6',
    'Qg5 Qd6',
    'Qh5+ Kg8',
    'Bh7+ Kf8',
    'Rf2 Qc7',
    'Nh6 Rd8',
    'Qg5 Bxh6',
  ],
  ['e4 c5', 'Nf3 d6', 'd4 cxd4', 'Nxd4 Nf6', 'Nc3 a6', 'Bg5 e6', 'f4 Qb6', 'Qd2 Qxb2', 'Rb1 Qa3', 'f5 Nc6', 'fxe6 fxe6', 'Nxc6 bxc6', 'Be2 Be7', 'O-O O-O', 'Rb3 Qc5+', 'Be3 Qe5'],
  [
    'e4 c6',
    'd4 d5',
    'e5 c5',
    'dxc5 Nc6',
    'Bb5 e6',
    'b4 a5',
    'c3 axb4',
    'cxb4 Bd7',
    'Qb3 Nxe5',
    'Bxd7+ Qxd7',
    'Bb2 Nc4',
    'Nf3 Nxb2',
    'Qxb2 Be7',
    'O-O Bf6',
    'Nc3 Ne7',
    'a4 O-O',
    'a5 e5',
    'Rad1 e4',
    'Nd4 Nc6',
    'Nce2 Rfe8',
    'Qb3 Rad8',
    'Nb5 Be5',
    'Nec3 Qe6',
    'Rxd5 Rxd5',
  ],
  [
    'c4 e5',
    'g3 Nc6',
    'Bg2 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd3 f5',
    'Nge2 Nf6',
    'O-O O-O',
    'f3 Be6',
    'Nd5 Ne7',
    'Bg5 c6',
    'Nxe7+ Qxe7',
    'Rc1 Qf7',
    'b3 h6',
    'Be3 d5',
    'cxd5 cxd5',
    'Qd2 Kh7',
    'd4 exd4',
    'Nxd4 dxe4',
    'Nxe6 Qxe6',
    'Rc7 Rad8',
    'Qc1 Rc8',
    'fxe4 Nxe4',
    'Bxa7 Rxc7',
    'Qxc7 Rf7',
    'Qc4 Qxc4',
    'bxc4 Nc3',
    'Bf2 Rc7',
    'Re1 Rxc4',
    'Bxb7 Rc7',
    'Bf3 Nxa2',
    'Rb1 Nc3',
    'Rb7 Rxb7',
    'Bxb7 Bd4',
    'Kf1 Bxf2',
    'Kxf2 Kg7',
    'Ke3 Kf6',
    'Kd3 Nb5',
    'Bc6 Nd6',
    'Kd4 Nf7',
    'Bf3 Ne5',
    'Bd1 Ke6',
    'Bb3+ Kd6',
    'Bd5 g5',
    'h3 Ng6',
    'Bf7 Nf8',
    'Be8 Ne6+',
    'Ke3 Ke5',
    'Bg6 Ng7',
    'Kf3 Ne6',
    'Ke3 Nc7',
    'Kf3 Nb5',
    'Ke3 Nd4',
    'Be8 Nc2+',
    'Kd2 Nb4',
    'Ke3 Nd5+',
    'Kf3 Nf6',
    'Bg6 h5',
    'Bf7 h4',
    'gxh4 gxh4',
    'Bg6 Ne4',
    'Kg2 Ng5',
    'Bh5 f4',
    'Bg4 Ke4',
    'Kf2 f3',
  ],
  ['e4 e6', 'd4 d5', 'Nc3 Bb4', 'e5 b6', 'Bd2 Bf8', 'Nf3 Ne7', 'Bd3 Ba6', 'Nb5 Nc8', 'Be2 c6', 'Nc3 Ne7', 'Bxa6 Nxa6', 'Ne2 Qd7', 'c4 c5', 'cxd5 Nxd5', 'O-O Be7', 'dxc5 Nxc5', 'Ned4 O-O'],
  [
    'Nf3 Nf6',
    'c4 c6',
    'd4 d5',
    'Nc3 dxc4',
    'a4 Bf5',
    'Ne5 e6',
    'f3 Bb4',
    'e4 Bxe4',
    'fxe4 Nxe4',
    'Bd2 Qxd4',
    'Nxe4 Qxe4+',
    'Qe2 Bxd2+',
    'Kxd2 Qd5+',
    'Kc2 Na6',
    'Nxc4 O-O-O',
    'Qe5 f6',
    'Qe3 Nb4+',
    'Kb3 c5',
    'Rc1 Nc6',
    'Ka3 Nd4',
    'Bd3 Kb8',
    'Be4 Qd7',
    'Rhd1 Qc7',
    'g4 g6',
    'Rd2 f5',
    'Bg2 e5',
    'Rf2 e4',
    'gxf5 gxf5',
    'Ka2 a6',
    'a5 Qc6',
    'Qa3 f4',
    'Rxf4 Ne2',
    'Rcf1 Nxf4',
    'Rxf4 Rd4',
    'Qg3 Ka7',
    'Nb6 Rb4',
    'Rf7 Qe6+',
  ],
  ['e4 g6', 'd4 d6', 'Nc3 c6', 'f4 d5', 'e5 h5', 'Nf3 Bg4', 'Be2 e6', 'Be3 Nh6'],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 Bb7',
    'Qd2 Nfd7',
    'O-O-O Nb6',
    'Nb3 b4',
    'Nb1 Nc6',
    'Qf2 Na4',
    'N1d2 Ne5',
    'Nc4 Nxc4',
    'Bxc4 Rc8',
    'Bd3 Be7',
    'Kb1 Qc7',
    'Qd2 Bf6',
    'Bd4 Bxd4',
    'Nxd4 Qb6',
    'Nb3 O-O',
    'Rhe1 Nc5',
    'Nxc5 Qxc5',
    'Qe3 Qxe3',
    'Rxe3 Rc5',
    'Be2 d5',
    'e5 Rfc8',
    'Rd2 d4',
    'Rxd4 Rxc2',
    'Rd8+ Rxd8',
    'Kxc2 g5',
    'a3 a5',
    'axb4 axb4',
    'Rd3 Rc8+',
    'Kd2 Bd5',
    'Bd1 Rc5',
    'Rd4 b3',
    'Rb4 Bc6',
    'Rxb3 Rxe5',
    'Be2 Ra5',
    'Rc3 Bd7',
    'b4 Ra2+',
    'Rc2 Ra8',
    'b5 Rb8',
    'Rc5 h6',
    'Kc3 Kf8',
    'Kb4 Ke7',
    'Rc2 Ra8',
    'Rb2 Bc8',
    'Kc5 Bb7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 g6',
    'c4 Bg7',
    'Nb3 a5',
    'a3 d6',
    'Nc3 Nf6',
    'Bd3 O-O',
    'O-O Nd7',
    'Be3 a4',
    'Nd2 Nc5',
    'Bb1 Nd4',
    'Ba2 c6',
    'Nf3 Nxf3+',
    'Qxf3 f5',
    'Rad1 Qe7',
    'exf5 Bxf5',
    'Qg3 Be5',
    'Bf4 Bxf4',
    'Qxf4 Bd3',
    'Qh6 Bxf1',
    'Rxf1 Rf6',
    'Qd2 Raf8',
    'h3 Qe5',
    'Qd1 Rf4',
    'Bb1 Kg7',
    'Ba2 Ne4',
    'f3 Nxc3',
    'bxc3 Qxc3',
    'Qxa4 Qe3+',
    'Rf2 Rd4',
    'Qc2 Qxa3',
    'Re2 Rf7',
    'Kh2 Rd3',
    'Bb1 Re3',
    'Rf2 Qc3',
    'Qd1 Re1',
    'Qd3 Qxd3',
    'Bxd3 Rfe7',
    'Rd2 Rc1',
    'Kg3 Kf6',
    'Kf2 Rd7',
    'Rb2 d5',
    'cxd5 Rxd5',
    'Be4 Rb5',
    'Rd2 Ke7',
    'g4 Ra5',
    'Re2 Kd7',
    'f4 Ra3',
    'h4 Rca1',
    'f5 R1a2',
    'fxg6 Rxe2+',
    'Kxe2 hxg6',
    'Bxg6 Rh3',
    'h5 Ke7',
    'Be4 Kf6',
    'Bg2 Rb3',
    'Be4 Kg5',
    'Bf5 Rc3',
    'Kd2 Rc5',
    'Be6 b5',
    'Bf7 b4',
    'Kd3 Rc3+',
    'Kd2 Kxg4',
    'h6 Kg5',
    'h7 Rh3',
    'Bg8 c5',
    'Ke2 Kg6',
    'Kd2 Rxh7',
    'Kd3 Rh3+',
    'Kc2 Kf6',
    'Bd5 Ke5',
    'Bg8 Kd4',
    'Be6 Rg3',
    'Bg8 Rxg8',
  ],
  [
    'd4 Nf6',
    'e3 c5',
    'Nd2 g6',
    'dxc5 Qa5',
    'a3 Qxc5',
    'b4 Qc7',
    'Bb2 Bg7',
    'Ne4 Qc6',
    'Ng3 d6',
    'Nf3 O-O',
    'h4 e5',
    'h5 a5',
    'b5 Qc7',
    'Ng5 Bg4',
    'f3 Qb6',
    'Qe2 Bd7',
    'hxg6 hxg6',
    'N3e4 Nxe4',
    'Nxe4 d5',
    'Nc3 Be6',
    'O-O-O d4',
    'exd4 exd4',
    'Qf2 Nd7',
    'Ne4 Rfc8',
    'Rxd4 Rc7',
    'Qh4 Kf8',
    'Rxd7 Qe3+',
    'Kb1 Bxb2',
    'Rd8+ Rxd8',
    'Qxd8+ Kg7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Na6',
    'dxe5 dxe5',
    'Qxd8 Rxd8',
    'Bg5 Re8',
    'Bxf6 Bxf6',
    'h3 c6',
    'Rab1 Nc5',
    'b4 Ne6',
    'b5 Nd4',
    'Rfd1 Nxe2+',
    'Nxe2 Be6',
    'bxc6 bxc6',
    'Rbc1 Red8',
    'g4 Rxd1+',
    'Rxd1 Bxc4',
    'Nc3 Be6',
    'g5 Bd8',
    'h4 Bc7',
    'Kg2 Rb8',
    'Ng1 Rb2',
    'Nge2 Kf8',
    'Kg3 Ke7',
    'a4 Ba5',
    'Rc1 Bc4',
    'Nd1 Rb3+',
    'Nec3 Be6',
    'f3 Kd7',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Be3 e5',
    'Nb3 Be6',
    'Qd2 d5',
    'exd5 Nxd5',
    'Nxd5 Qxd5',
    'Qxd5 Bxd5',
    'O-O-O O-O-O',
    'c4 Be6',
    'Rxd8+ Kxd8',
    'Nc5 Bxc5',
    'Bxc5 Kc7',
    'b3 Rd8',
    'Be3 Bf5',
    'Be2 Nd4',
    'Bd1 Be4',
    'f3 Bg6',
    'Re1 Nc6',
    'Bd2 f6',
    'Re2 b6',
    'Rf2 a5',
    'a3 Bd3',
    'a4 Nb4',
    'f4 Bg6',
    'Bxb4 axb4',
    'fxe5 fxe5',
    'h4 Kc6',
    'h5 Bd3',
    'Rf7 Rd7',
    'Rf8 Kc5',
    'Bg4 Rd6',
    'Rc8+ Kd4',
    'Kd2 e4',
    'Re8 Bf1',
    'g3 Bg2',
    'Be2 Bh3',
    'g4 Bg2',
    'Re7 h6',
    'Rxg7 e3+',
    'Ke1 Kc3',
    'g5 hxg5',
    'Rxg5 Bc6',
    'Rg6 Rxg6',
    'hxg6 Bd7',
  ],
  [
    'e4 c5',
    'c3 Nf6',
    'e5 Nd5',
    'Nf3 Nc6',
    'Bc4 Nb6',
    'Bb3 c4',
    'Bc2 Qc7',
    'Qe2 g5',
    'e6 dxe6',
    'Nxg5 Qe5',
    'd4 cxd3',
    'Bxd3 Qxe2+',
    'Bxe2 h6',
    'Ne4 e5',
    'Na3 f5',
    'Ng3 e6',
    'Nc2 Kd8',
    'f4 e4',
    'Bd2 Kc7',
    'O-O-O Rg8',
    'Rhg1 Nd5',
    'Rgf1 a5',
    'a4 Bc5',
    'Kb1 Bd7',
    'c4 Ndb4',
    'Na3 Nd4',
    'Bc3 Nxe2',
    'Be5+ Kc8',
    'Nxe2 Bxa4',
    'Rd2 e3',
  ],
  [
    'd4 Nf6',
    'Bg5 Ne4',
    'Bf4 d5',
    'Nd2 Bf5',
    'Nxe4 Bxe4',
    'e3 e6',
    'Ne2 Bd6',
    'Bxd6 Qxd6',
    'c4 dxc4',
    'Nc3 Bc6',
    'Qg4 O-O',
    'Bxc4 Qb4',
    'Bb3 a5',
    'Qe2 a4',
    'Bc2 a3',
    'Qd3 g6',
    'O-O axb2',
    'Rab1 Nd7',
    'Bb3 Rfd8',
    'Rxb2 Qd6',
    'Qe2 Nf6',
    'Rd1 Qb4',
    'Rc1 Qe7',
    'Rd1 Rd7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Bg5 Nc6',
    'Nge2 a6',
    'Qd2 Rb8',
    'Rc1 Re8',
    'd5 Na5',
    'Ng3 c5',
    'Bd3 Bd7',
    'O-O b5',
    'b3 bxc4',
    'bxc4 Rb4',
    'Nd1 Ba4',
    'Qe2 Nd7',
    'Bd2 Rb8',
    'Nc3 Nb6',
    'f4 e6',
    'Nd1 Nb7',
    'Ne3 Nd7',
    'dxe6 Rxe6',
    'e5 Bc6',
    'Ne4 Re8',
    'Rcd1 dxe5',
    'f5 Bxe4',
    'Bxe4 Nd6',
    'Ba5 Qe7',
    'Bc6 Nf6',
    'Bxe8 Rxe8',
    'Bc3 Nfe4',
    'Bb2 Qg5',
    'fxg6 hxg6',
    'Ng4 Qh5',
    'Qf3 Qf5',
    'Qe2 Qh5',
    'Qf3 Qf5',
    'Qe2 Qh5',
    'Qf3 Re6',
    'Ne3 Bh6',
    'Ng4 Bg7',
    'Ne3 Qxf3',
    'gxf3 Nf6',
    'Ba3 Bh6',
    'Bxc5 Bxe3+',
    'Bxe3 Nxc4',
    'Kf2 e4',
    'Rd8+ Ne8',
    'fxe4 Nd6',
    'Rb1 Kh7',
    'Kf3 Nxe4',
    'Rb6 Re5',
    'Rxa6 N8f6',
    'Bd4 Rf5+',
    'Ke2 g5',
    'a4 g4',
    'a5 g3',
    'hxg3 Nxg3+',
    'Kd3 Rf3+',
    'Kc4 Nge4',
    'Bxf6 Nxf6',
    'Rc6 Ng4',
    'Kc5 Ra3',
    'Kb5 Rb3+',
    'Ka6 Ra3',
    'Kb6 Ne3',
    'a6 Rb3+',
    'Kc7 Ra3',
    'Kb7 Rb3+',
    'Ka8 Ra3',
    'a7 f5',
  ],
  [
    'c4 c5',
    'Nf3 Nf6',
    'Nc3 b6',
    'e4 d6',
    'd4 cxd4',
    'Nxd4 Bb7',
    'f3 g6',
    'Be3 Bg7',
    'Be2 O-O',
    'O-O Nc6',
    'Nc2 Rc8',
    'Qd2 Ba6',
    'Rac1 Nd7',
    'b3 f5',
    'exf5 gxf5',
    'f4 Kh8',
    'Bf3 Na5',
    'Nb5 Bxb5',
    'cxb5 Nc5',
    'Na3 Ne4',
    'Bxe4 fxe4',
    'Qd5 Qd7',
    'Qxe4 d5',
    'Qd3 d4',
    'Rxc8 Rxc8',
    'Rd1 Qd6',
    'Bxd4 Qxa3',
    'Bxg7+ Kxg7',
    'Qd4+ Kh6',
    'b4 Nc4',
    'Re1 e5',
    'fxe5 Qxb4',
    'Qh4+ Kg7',
    'Qg4+ Kh6',
    'Rc1 Qc5+',
    'Kh1 Rf8',
    'h3 Qxe5',
    'Qxc4 Rf4',
    'Qd3 Rf6',
    'Qd8 Kg6',
    'Qd7 Kh6',
    'a4 Qf4',
    'Ra1 Qe5',
    'Rd1 Rg6',
    'Qd2+ Qg5',
    'Qxg5+ Rxg5',
    'Rd7 a6',
    'Rd6+ Kg7',
    'bxa6 Ra5',
    'Rxb6 Rxa4',
    'Kg1 Ra2',
    'Kh2 Ra3',
    'h4 h5',
    'Kg1 Ra2',
    'Kf1 Kh7',
    'g3 Ra3',
    'Kf2 Kg7',
    'Rb7+ Kf6',
    'a7 Kf5',
    'Rg7 Ke4',
    'Kg2 Ra2+',
    'Kh3 Ra1',
    'Rg5 Rxa7',
    'Rxh5 Ra1',
    'Rg5 Kf3',
    'Rf5+ Ke4',
    'Rf4+ Ke5',
    'Kg4 Rg1',
    'h5 Ke6',
    'Kh4 Ke5',
    'Rf8 Ke6',
    'g4 Ke7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'Qd2 Be7',
    'f3 Nbd7',
    'O-O-O Qc7',
    'g4 O-O',
    'h4 b5',
    'Bxf6 Nxf6',
    'g5 Nd7',
    'h5 b4',
    'Nb1 Nc5',
    'Qe3 Bb7',
    'Rd2 Qa5',
    'g6 Bg5',
    'f4 Bh6',
    'gxh7+ Kh8',
    'Bg2 Qxa2',
    'Rg1 Rac8',
    'e5 Bxg2',
    'Rdxg2 Bxf4',
    'Qxf4 Nd3+',
  ],
  [
    'c4 c6',
    'e4 d5',
    'exd5 cxd5',
    'd4 Nf6',
    'Nc3 Nc6',
    'Nf3 g6',
    'Bg5 Ne4',
    'cxd5 Nxc3',
    'bxc3 Qxd5',
    'Qb3 Qe4+',
    'Be3 Be6',
    'Qxb7 Rb8',
    'Qa6 Rb1+',
    'Rxb1 Qxb1+',
    'Ke2 Qc2+',
    'Bd2 Bd5',
    'Ne5 Bh6',
    'Qc8+ Nd8',
    'Qd7+ Kf8',
    'Qxd8+ Kg7',
    'Qxd5 Qxd2+',
    'Kf3 Qf4+',
    'Ke2 Qd2+',
    'Kf3 Qf4+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bf4 O-O',
    'Rc1 dxc4',
    'e4 Bg4',
    'Bxc4 Bxf3',
    'Qxf3 Nh5',
    'Bg5 Nc6',
    'd5 Ne5',
    'Qe2 Nxc4',
    'Qxc4 a6',
    'Qb4 Qd6',
    'Bxe7 Qxb4',
    'Bxb4 Rfe8',
    'f3 Nf4',
    'Rd1 Nxg2+',
    'Kf2 Nf4',
    'd6 cxd6',
    'Bxd6 Ne6',
    'e5 Rac8',
    'Kg3 h5',
    'Rhe1 Rc4',
    'h4 Nd4',
    'Re4 Nf5+',
    'Kh3 Rec8',
    'Rd3 R4c6',
    'Ba3 b5',
    'Bb4 Rc4',
    'a3 Rxe4',
    'fxe4 Nh6',
    'Rd6 Bxe5',
    'Nd5 a5',
    'Ne7+ Kg7',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Nf6',
    'Nb3 Nc6',
    'f4 b5',
    'O-O Bb7',
    'Qe2 Be7',
    'N1d2 d6',
    'Nf3 e5',
    'a4 O-O',
    'Be3 Ng4',
    'Bd2 exf4',
    'Bxf4 Bf6',
    'axb5 axb5',
    'Rad1 Bxb2',
    'Bxb5 Qb6+',
    'Kh1 Be5',
    'Nxe5 Ncxe5',
    'c4 Ra3',
    'Rb1 Rfa8',
    'c5 dxc5',
    'h3 Ng6',
    'Nd2 Nxf4',
    'Qxg4 Ne6',
    'Nc4 Qc7',
    'Nxa3 Rxa3',
    'Be8 Nd8',
    'Rbd1 Bc8',
    'Qf4 Qxf4',
    'Rxf4 g5',
    'Rxd8 gxf4',
    'Rxc8 f3',
    'gxf3 Rxf3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be2 e5',
    'Nb3 Be6',
    'O-O Be7',
    'Be3 Nbd7',
    'f4 Qc7',
    'Bf3 Nb6',
    'Nd2 exf4',
    'Bxf4 Nfd7',
    'Bg4 Nf6',
    'Bf5 O-O',
    'Kh1 Bxf5',
    'exf5 Nfd5',
    'Nxd5 Nxd5',
    'Nb3 Nxf4',
    'Rxf4 Bf6',
    'c3 Rfe8',
    'Qf3 Re5',
    'Re4 Rae8',
    'Rae1 Qc4',
    'Rxe5 Rxe5',
    'Rxe5 Bxe5',
    'g3 h6',
    'Kg2 b5',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'Nf3 Nf6',
    'd4 cxd4',
    'Nxd4 a6',
    'Be3 e5',
    'Nb3 Be6',
    'f3 Nbd7',
    'Qd2 Be7',
    'O-O-O b5',
    'g4 Nb6',
    'g5 Nfd7',
    'Nd5 Bxd5',
    'exd5 Nc4',
    'Bxc4 bxc4',
    'Na5 c3',
    'Qxc3 Bxg5',
    'f4 exf4',
    'Nc6 Qc7',
    'Bd4 f6',
    'Rhg1 Bh6',
    'Rge1+ Ne5',
    'Bxe5 fxe5',
    'Rxe5+ dxe5',
    'd6 Qd7',
    'Nxe5 Qb5',
    'd7+ Ke7',
    'Qa3+ Ke6',
    'Qd6+ Kf5',
    'Nf3 Kg4',
    'Nd4 Qc4',
    'Rg1+ Kh3',
    'Qe5 g6',
  ],
  [
    'e4 c5',
    'Nc3 e6',
    'Nf3 a6',
    'd4 cxd4',
    'Nxd4 Qc7',
    'Bd3 Nc6',
    'Nb3 Nf6',
    'O-O Be7',
    'f4 d6',
    'Qf3 O-O',
    'Bd2 b5',
    'Rae1 b4',
    'Nd1 Bb7',
    'Qh3 e5',
    'Ne3 g6',
    'f5 Nb8',
    'fxg6 fxg6',
    'Bxb4 Bc8',
    'Qg3 Be6',
    'Bd2 Nbd7',
    'Nf5 Bd8',
    'Ne3 Qa7',
    'Kh1 Bb6',
    'Nf5 Kh8',
    'Bh6 Nh5',
    'Qh4 Bd8',
    'Qh3 Rf7',
    'Qf3 gxf5',
    'Be3 Qb7',
    'Qxh5 Nf6',
    'Qh3 Nxe4',
    'Qh6 Rf6',
    'Qh5 Bf7',
    'Qh3 Bg6',
    'Bxe4 fxe4',
    'Rxf6 Bxf6',
    'Rf1 Qe7',
    'Na5 Rf8',
    'Nc6 Qe8',
    'Bh6 Bg7',
    'Bxg7+ Kxg7',
    'Rxf8 Kxf8',
    'Qc3 Qf7',
    'h3 Qf1+',
    'Kh2 Qb5',
    'a4 Qxa4',
    'Nb4 Bf5',
    'Qd2 Qd7',
    'Nd5 Bg6',
    'c4 Qf7',
    'Qa5 Qd7',
    'Qxa6 Bf7',
    'Nf6 Qf5',
    'Qxd6+ Kg7',
    'Ng4 Qf4+',
    'g3 Qc1',
    'Qxe5+ Kf8',
    'Qc5+ Kg8',
    'Qf2 Bxc4',
  ],
  [
    'g3 Nf6',
    'Bg2 d6',
    'd4 g6',
    'e4 Bg7',
    'Nc3 c6',
    'Nge2 O-O',
    'O-O e5',
    'h3 b5',
    'Be3 Nbd7',
    'dxe5 dxe5',
    'Qd6 Bb7',
    'a4 a6',
    'g4 Re8',
    'g5 Nh5',
    'axb5 cxb5',
    'Rfd1 Nf8',
    'Qb4 Qb8',
    'Nd5 Ne6',
    'Nb6 a5',
    'Rxa5 Rxa5',
    'Qxa5 Bc6',
    'Nd5 Nhf4',
    'Nec3 Bxd5',
    'exd5 Nd4',
    'Kf1 Qc8',
    'Ne4 Qxc2',
    'Re1 Nxg2',
    'Bxd4 Nxe1',
    'Qxe1 Qc4+',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Qc7',
    'Be2 a6',
    'O-O Nf6',
    'Kh1 Nxd4',
    'Qxd4 Bc5',
    'Qd3 b5',
    'f4 Bb7',
    'Bf3 h5',
    'e5 Ng4',
    'Bxb7 Qxb7',
    'Ne4 Rc8',
    'a4 b4',
    'c3 bxc3',
    'bxc3 Be7',
    'Ba3 Bxa3',
    'Rxa3 O-O',
    'h3 Nh6',
    'Nd6 Qb2',
    'Nxc8 Qxa3',
    'Nb6 h4',
    'Nxd7 Rc8',
    'Qxa6 Qxc3',
    'Rb1 Kh7',
    'Qe2 Nf5',
    'Qh5+ Kg8',
    'Qg4 Qc2',
    'Rd1 Ne3',
    'Nf6+ Kf8',
    'Nd7+ Kg8',
    'Nf6+ Kf8',
    'Nd7+ Ke8',
    'Nf6+ Ke7',
    'Nd5+ Nxd5',
    'Qxh4+ Kf8',
    'Rf1 Kg8',
    'a5 Ne3',
    'Rg1 Nf5',
    'Qe1 Rc4',
    'Rf1 Re4',
    'Qf2 Qxf2',
    'Rxf2 Ra4',
    'g4 Ne7',
    'Rf3 g5',
    'fxg5 Ng6',
    'Kh2 Rxa5',
    'Kg3 Nxe5',
    'Rc3 Kg7',
    'Re3 Nc4',
    'Re4 Ra3+',
    'Kg2 Ne3+',
    'Kf2 Nd5',
    'h4 Kg6',
    'Kg2 Rb3',
    'Ra4 Nb4',
    'Ra5 Nd5',
    'Ra4 Rb4',
    'Rxb4 Nxb4',
    'Kf3 Nd5',
    'Ke4 Nb6',
    'Kf4 Nd7',
    'h5+ Kg7',
    'Ke4 Nf8',
    'Ke5 Kg8',
    'Kd6 Kh7',
    'Ke5 Kg7',
    'Ke4 Nh7',
    'Kf4 Kg8',
    'g6 fxg6',
    'Ke5 gxh5',
    'gxh5 Nf8',
    'Kf6 Kh7',
    'Kg5 Nd7',
    'Kh4 Kh6',
    'Kg4 e5',
    'Kh4 Nf6',
    'Kg3 Kxh5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Be7',
    'Qf3 Qc7',
    'O-O-O Nbd7',
    'g4 b5',
    'Bxf6 Nxf6',
    'g5 Nd7',
    'f5 Bxg5+',
    'Kb1 Ne5',
    'Qh5 Qe7',
    'Nxe6 Bxe6',
    'fxe6 g6',
    'exf7+ Kxf7',
    'Qh3 Kg7',
    'Nd5 Qd8',
    'Qc3 Ra7',
    'Qa3 Qb8',
    'h4 Bh6',
    'h5 a5',
    'Be2 b4',
    'Qg3 Rf7',
    'hxg6 hxg6',
    'Bh5 Qf8',
    'Rhg1 Bf4',
    'Nxf4 Rxf4',
    'Bxg6 Rg4',
    'Qxe5+ dxe5',
    'Rxg4 Rh2',
    'Bf5+ Kh6',
    'Rg6+ Kh5',
    'Rc6 Qd8',
    'Rc1 Qh4',
    'Rc7 Kg5',
    'a4 Rh1',
    'Rxh1 Qxh1+',
    'Ka2 Kf4',
    'Rc4 Qd1',
    'Be6 Ke3',
    'Bd5 Kd2',
    'Rc8 Kc1',
    'Bb3 Qe2',
    'Rc4 Kd2',
    'Rc5 Qxe4',
    'Rxa5 Kc1',
    'Rd5 Qe2',
    'a5 e4',
    'Rd6 e3',
    'a6 Qg2',
    'Re6 Qg7',
    'c3 Qg2',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 e5',
    'Bc4 Be7',
    'd3 d6',
    'Nd2 Nf6',
    'Nf1 Bg4',
    'f3 Be6',
    'Ne3 O-O',
    'O-O Nd4',
    'a4 a6',
    'Ncd5 Nxd5',
    'Nxd5 Bg5',
    'Bxg5 Qxg5',
    'c3 Bxd5',
    'Bxd5 Nc6',
    'Qc1 Qxc1',
    'Raxc1 g6',
    'f4 Kg7',
    'f5 f6',
    'b4 Ne7',
    'bxc5 Nxd5',
    'exd5 dxc5',
    'Rb1 Rf7',
    'c4 gxf5',
    'Rxf5 Re8',
    'Rf2 f5',
    'g4 f4',
    'Re2 h5',
    'gxh5 Kh6',
    'Rb6+ Kxh5',
    'Re6 Rg8+',
    'Kf2 Kg4',
    'Kf1 Rh8',
    'Rg2+ Kf5',
    'Re2 f3',
    'R2xe5+ Kg4',
    'Rg6+ Kh3',
    'Rg3+ Kxh2',
    'Kf2 Rh3',
    'Rxh3+ Kxh3',
    'Rg5 Kh4',
    'Rg3 b5',
    'axb5 axb5',
    'Rxf3 Rb7',
    'Ke3 Kg5',
    'd4 cxd4+',
    'Kxd4 bxc4',
    'Kxc4 Kg6',
    'Kc5 Rc7+',
    'Kb6 Rc1',
    'd6 Rd1',
    'Kc7 Rc1+',
    'Kd8 Rd1',
    'd7 Kg5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'e4 Nxc3',
    'bxc3 Bg7',
    'Nf3 c5',
    'Rb1 O-O',
    'Be2 cxd4',
    'cxd4 Qa5+',
    'Bd2 Qxa2',
    'O-O Bg4',
    'Bg5 Qe6',
    'h3 Bxf3',
    'Bxf3 Qd7',
    'd5 Na6',
    'Qe2 Nc5',
    'e5 Rae8',
    'Rfd1 f6',
    'Be3 Rc8',
    'd6 b6',
    'Bxc5 Rxc5',
    'e6 Qc8',
    'dxe7 Re8',
    'Rd8 Rxd8',
    'exd8=Q+ Qxd8',
    'e7 Qe8',
    'Qe6+ Kh8',
    'Rd1 Rc8',
    'Qxc8 Qxc8',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nf6',
    'Nxc6 bxc6',
    'e5 Nd5',
    'c4 Nb6',
    'Nd2 Qe7',
    'Qe2 a5',
    'b3 a4',
    'Bb2 axb3',
    'axb3 Rxa1+',
    'Bxa1 Qa3',
    'Qd1 Bb4',
    'Bd3 Qa5',
    'Ke2 d6',
    'f4 dxe5',
    'fxe5 Bc3',
    'Bxc3 Qxc3',
    'Nf3 Bg4',
    'Qa1 Qxa1',
    'Rxa1 Ke7',
    'Ra7 Nd7',
    'Rxc7 Rb8',
    'Bc2 Rb6',
    'Kd2 Kd8',
    'Ra7 Bxf3',
    'gxf3 Nxe5',
    'f4 Nd7',
    'Bf5 Nc5',
    'Rxf7 Rxb3',
    'Rxg7 Rf3',
    'Rg4 Nb3+',
    'Ke1 h6',
    'Rh4 Nd4',
    'Bh3 Ke7',
    'Rxh6 Rxf4',
    'Kd2 Nb3+',
    'Kd3 Nc5+',
    'Kc3 Na4+',
    'Kb3 Nc5+',
    'Kb4 Nd3+',
    'Kc3 Nc5',
    'Bg2 Na4+',
    'Kd3 Nc5+',
    'Ke3 Rxc4',
    'Rxc6 Rc3+',
    'Kd4 Rc2',
    'Rg6 Ne6+',
    'Ke3 Rc3+',
    'Kd2 Rc5',
    'Bf3 Ng5',
    'Be2 Kf7',
    'Ra6 Re5',
    'Ra3 Kg6',
    'Ke1 Nh7',
    'Kf2 Nf6',
    'Bf3 Rc5',
    'Ra4 Rc3',
    'h4 Kg7',
    'Kg3 Rc5',
    'Ra6 Rb5',
    'Be2 Rc5',
    'Kf4 Nh5+',
    'Ke3 Re5+',
    'Kf2 Nf6',
    'Bd3 Rd5',
    'Ke3 Ng4+',
    'Ke2 Nf6',
    'Rc6 Rd4',
    'Rc7+ Kg8',
    'Bc4+ Kh8',
    'h5 Nxh5',
    'Ke3 Rh4',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'f4 g6',
    'Nf3 Bg7',
    'Bc4 Nc6',
    'O-O e6',
    'd3 Nge7',
    'Qe1 O-O',
    'f5 d5',
    'Bb3 dxe4',
    'dxe4 exf5',
    'Qh4 fxe4',
    'Ng5 h6',
    'Nxf7 Qd4+',
    'Kh1 c4',
    'Nxh6+ Bxh6',
    'Bxh6 Rxf1+',
    'Rxf1 Nf5',
    'Qg4 Ne5',
    'Qf4 cxb3',
    'axb3 Nf7',
    'Rd1 Qh8',
    'Bg5 Qh5',
    'Bf6 Be6',
    'Kg1 Re8',
    'Qxe4 N5d6',
    'Qd4 b6',
    'Qd2 Nb5',
    'Nxb5 Qxb5',
    'Rf1 Bf5',
    'Rf2 Bxc2',
    'Bc3 Bf5',
    'Qd4 Qe5',
    'Qh4 Qe7',
    'Qd4 Qe5',
    'Qh4 Qe7',
  ],
  [
    'e4 c5',
    'd4 cxd4',
    'Qxd4 Nc6',
    'Qd1 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'Bc4 O-O',
    'h4 h5',
    'Qd2 Ne5',
    'Bb3 d6',
    'Nf3 Neg4',
    'Bg5 b5',
    'Nh2 b4',
    'Nd5 Nxh2',
    'Rxh2 Nxd5',
    'Qxd5 Bxb2',
    'Rd1 Bc3+',
    'Kf1 Qc7',
    'Qd3 a5',
    'f4 Ba6',
  ],
  ['e4 c5', 'Nf3 d6', 'd4 cxd4', 'Nxd4 Nf6', 'Nc3 Nc6', 'Be3 Ng4', 'Bg5 Qb6', 'Bb5 Bd7', 'O-O h6', 'Be3 Nxe3', 'fxe3 Ne5', 'Bxd7+ Kxd7', 'Nd5 Qd8', 'Nf3 Nc6', 'Nd4 Ne5', 'Rxf7 g5'],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 a6',
    'Bd3 b5',
    'b3 Bg4',
    'Nbd2 Nbd7',
    'Bb2 e6',
    'Qc2 Bxf3',
    'Nxf3 Bb4+',
    'Ke2 O-O',
    'cxb5 cxb5',
    'Rhc1 Rc8',
    'Qxc8 Qxc8',
    'Rxc8 Rxc8',
    'a3 Bf8',
    'a4 bxa4',
    'Rxa4 Rb8',
    'Nd2 Bb4',
    'Rxa6 Bxd2',
    'Kxd2 Rxb3',
    'Ra8+ Rb8',
    'Rxb8+ Nxb8',
    'Bb5 Ne4+',
    'Ke2 Nd6',
    'Ba4 Nc4',
    'Bc3 Na6',
    'Kd3 Nc7',
    'Bb3 Nd6',
    'Bb4 Nce8',
    'Ba4 f5',
    'f3 Kf7',
    'Bc6 Nc8',
    'Be1 Nf6',
    'Kc3 Nd6',
    'Kb4 Nc4',
    'Bf2 g5',
    'Bb5 Nd6',
    'Bd3 Nd7',
    'Bg3 Ke7',
    'Ka5 h6',
    'Be1 Kd8',
    'Bg3 Ke7',
    'h3 Nf6',
    'Be5 Nf7',
    'Bh2 Nd6',
    'Kb4 Nd7',
    'g4 fxg4',
    'hxg4 Nb6',
    'Kc5 Nbc4',
    'Bg1 Nb7+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 Qd7',
    'a3 Bf8',
    'f4 b6',
    'Nf3 Ne7',
    'b4 h5',
    'Bd3 Ba6',
    'b5 Bb7',
    'a4 c5',
    'bxc6 Nbxc6',
    'Nb5 Nc8',
    'c3 Na5',
    'O-O g6',
    'Nh4 a6',
    'Na3 Ne7',
    'Nb1 Nc4',
    'Qb3 Qc7',
    'Nd2 Rc8',
    'Ndf3 Na5',
    'Qb1 Nf5',
    'Bd2 Qd8',
    'Nxf5 gxf5',
    'Ng5 Rc6',
    'Rf3 Be7',
    'Rh3 Nc4',
    'Bc1 Bxg5',
    'fxg5 Kd7',
    'Be2 h4',
    'Bf4 Rh7',
    'g4 fxg4',
    'Qxh7 gxh3',
    'g6 Rc8',
    'gxf7 Qe7',
    'Rf1 Rf8',
    'Bh6 b5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 Ng4',
    'Bg5 h6',
    'Bh4 g5',
    'Bg3 Bg7',
    'Be2 h5',
    'Nf5 Bxf5',
    'exf5 Qa5',
    'O-O Bxc3',
    'bxc3 Nf6',
    'Bf3 Kf8',
    'Rb1 Nbd7',
    'Rxb7 Rc8',
    'Bxh5 Qxc3',
    'Be2 Qxc2',
    'Bxa6 Qxa2',
    'Bb5 Ne5',
    'Re1 Qa8',
    'Rb6 Qa5',
    'Rb7 Qa8',
    'Rb6 Qa5',
    'Rxd6 exd6',
    'Qxd6+ Kg8',
    'Qxe5 Rc5',
    'Qxc5 Qxe1+',
    'Bf1 Qb1',
    'Qe3 Rh5',
    'h3 g4',
    'Be5 gxh3',
    'Qg3+ Kh7',
    'gxh3 Ne8',
    'Kg2 Qc1',
    'Bf4 Qe1',
    'Bd3 Kh8',
    'Be5+ Kh7',
    'Bf4 Kh8',
    'Be5+ Kh7',
    'Qg4 Qxe5',
    'Qxh5+ Kg8',
    'Qg5+ Qg7',
    'Qg3 Nf6',
    'Kf3 Kh7',
    'Bc4 Nd7',
    'Qh4+ Qh6',
    'Qxh6+ Kxh6',
    'Ke4 Nf6+',
    'Ke5 Kg7',
    'Bb5 Nh5',
    'Be8 Nf6',
    'Bc6 Nh5',
    'Bf3 Nf6',
    'h4 Nd7+',
    'Kd6 Nf6',
    'h5 Ng8',
    'Ke5 Kh6',
    'f6 Kg5',
    'Be2 Nh6',
    'Bd1 Kh4',
    'f4 Kg3',
    'Bb3 Kg4',
    'Bxf7 Nxf7+',
    'Ke6 Nd8+',
    'Kd7 Kf5',
    'Kxd8 Kxf6',
    'h6 Kf7',
    'f5 Kf8',
    'f6 Kg8',
    'Ke7 Kh7',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'd4 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'O-O Nc6',
    'd5 Na5',
    'Nfd2 c5',
    'Qc2 a6',
    'Nc3 Bd7',
    'b3 b5',
    'Bb2 Rb8',
    'Rae1 e5',
    'Nd1 Nh5',
    'f4 Qe7',
    'e4 bxc4',
    'bxc4 Rxb2',
    'Nxb2 exf4',
    'Nd3 fxg3',
    'h3 Bd4+',
    'Kh1 Bxh3',
    'Bxh3 g2+',
    'Kxg2 Qg5+',
    'Kh1 Qh4',
    'Rf3 Nf4',
    'Nxf4 Qxe1+',
    'Rf1 Qe3',
    'Qd1 Bc3',
    'Nb1 Qxe4+',
    'Qf3 Qxf3+',
    'Rxf3 Bg7',
    'Nd2 Bh6',
    'Bf1 Re8',
    'Nb3 Nb7',
    'Ng2 Re5',
    'Kg1 f5',
    'Bd3 Re7',
    'Kf2 Nd8',
    'Nf4 Bxf4',
    'Rxf4 Rb7',
    'Ke3 Nf7',
    'Na5 Re7+',
    'Kd2 Ne5',
    'Rf2 h5',
    'Bxf5 gxf5',
    'Rxf5 Rh7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Bc5',
    'Nxc6 Qf6',
    'Qd2 dxc6',
    'Nc3 Ne7',
    'Qf4 Qe6',
    'Bd3 O-O',
    'Qxc7 Bd6',
    'Qa5 b5',
    'O-O Qe5',
    'f4 Qd4+',
    'Kh1 Bb4',
    'Qc7 Bd6',
    'Qa5 Bb4',
    'Qc7 Bd6',
    'Qa5 Bb4',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f4 O-O',
    'Nf3 c5',
    'dxc5 Qa5',
    'Bd3 Qxc5',
    'Qe2 Nc6',
    'Be3 Qa5',
    'O-O Bg4',
    'Rac1 Nd7',
    'Qf2 Bxf3',
    'gxf3 Nc5',
    'Bb1 Na4',
    'Nxa4 Qxa4',
    'Kh1 e6',
    'h4 Ne7',
    'h5 Qb4',
    'Rc2 Rac8',
    'b3 f5',
    'Qh4 Kf7',
    'Re2 Bf6',
    'Qh2 b5',
    'cxb5 Qxb5',
    'Bxa7 Rc3',
    'Bg1 Rg8',
    'h6 Rgc8',
    'Ree1 Ng8',
    'a4 Qb4',
    'exf5 exf5',
    'Rd1 Ne7',
    'a5 d5',
    'a6 Rxb3',
    'a7 Rb2',
    'Qg3 d4',
    'Bd3 Qa3',
    'Rc1 Qxa7',
    'Rxc8 Nxc8',
    'Bc4+ Kf8',
    'Qe1 Qe7',
    'Qc1 Rb6',
    'Re1 Qc7',
    'Qa3+ Qd6',
    'Qa8 Rb8',
    'Qa4 Nb6',
    'Qa6 Nxc4',
    'Qxc4 Qxf4',
    'Qc5+ Kg8',
    'Qd5+ Kh8',
    'Bh2 Qxh6',
    'Qc6 Rf8',
    'Re6 Bg7',
    'Kg1 Qg5+',
    'Kf1 Qd8',
    'Bd6 Rg8',
    'Ke2 Qa8',
  ],
  [
    'e4 c5',
    'Nc3 e6',
    'f4 a6',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nxd4',
    'Qxd4 b5',
    'Be2 Bb7',
    'Be3 Rc8',
    'Qd3 Qc7',
    'O-O-O Nf6',
    'Bf3 Bb4',
    'Bd2 Bxc3',
    'Bxc3 Bxe4',
    'Bxe4 Qxf4+',
    'Kb1 Qxe4',
    'Qg3 Nd5',
    'Qxg7 Nxc3+',
    'bxc3 Rf8',
    'Rd6 b4',
    'Rd4 Qe3',
    'c4 Qc3',
    'Qg4 d5',
    'Rxd5 Rxc4',
    'Qe2 Qxc2+',
    'Qxc2 Rxc2',
    'Kxc2 exd5',
    'Kb3 Kd7',
    'Kxb4 Kd6',
    'Rf1 f5',
    'Kc3 Kc5',
    'g3 Rf6',
    'Kd3 Rh6',
    'Rf2 Rh3',
    'Ke3 Rh5',
    'h4 Rh6',
    'Rxf5 Rg6',
    'Rf3 Kc4',
    'Kd2 d4',
    'h5 Rg5',
    'Ra3 Rg4',
    'Rxa6 Rxg3',
    'Rc6+ Kd5',
    'Rc8 Rg2+',
    'Kd3 Rg3+',
    'Kd2 Rg2+',
    'Kd3 Rg3+',
    'Kd2 Ra3',
    'Rc7 Rxa2+',
    'Kd3 Ra3+',
    'Kd2 Ra2+',
    'Kd3 Ra3+',
    'Kd2 h6',
    'Rh7 Ra2+',
    'Kd3 Ra3+',
    'Kd2 Ra2+',
    'Kd3 Ra3+',
    'Kd2 Ra6',
    'Kd3 Ke5',
    'Re7+ Kf6',
    'Rd7 Ke5',
    'Re7+ Kf6',
    'Rd7 Ra5',
    'Rxd4 Rxh5',
    'Ke2 Rf5',
    'Rd3 Kg5',
    'Rf3 Rb5',
    'Kf2 Kg4',
    'Ra3 h5',
    'Kg1 Rb1+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 Ng4',
    'Bc1 Nf6',
    'h3 e6',
    'g4 b5',
    'Bg2 Bb7',
    'a3 Be7',
    'O-O Nfd7',
    'a4 bxa4',
    'Rxa4 Nb6',
    'Ra2 O-O',
    'Qe2 Qc7',
    'Nb3 Nc6',
    'Rd1 Rac8',
    'Bf4 Rfd8',
    'Nc5 Nd4',
    'Rxd4 Qxc5',
    'Rd3 Bg5',
    'b4 Qxb4',
    'Bxg5 f6',
    'Bc1 Rxc3',
    'Rb2 Qc4',
    'Rxb6 Rxc2',
    'Bd2 Bc6',
    'Qe3 d5',
    'exd5 exd5',
    'Rc3 Rxc3',
    'Qxc3 Qxc3',
    'Bxc3 Bb5',
    'Ba5 Rd7',
    'Rb8+ Kf7',
    'Rb7 Ke6',
    'Rxd7 Bxd7',
    'Kf1 Ke5',
    'Ke2 f5',
    'Bc7+ Kd4',
    'g5 h5',
    'f4 g6',
    'Bb6+ Kc4',
    'Ke3 d4+',
    'Bxd4 a5',
    'Bf1+ Kd5',
    'Bd3 h4',
    'Bb2 Bc6',
    'Ba3 Ba4',
    'Kd2 Bb3',
    'Kc3 Bd1',
    'Bc1 Bf3',
    'Be3 Bg2',
    'Bf2 Bxh3',
    'Bxh4 Bg4',
    'Bf2 Bf3',
    'Bd4 Kc6',
    'Bc4 Bd1',
    'Bf7 Bh5',
    'Kb3 Kb5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'a3 Bxc3+',
    'bxc3 Ne7',
    'Qg4 cxd4',
    'Qxg7 Rg8',
    'Qxh7 Qc7',
    'Ne2 dxc3',
    'f4 Bd7',
    'Qd3 Nf5',
    'Rg1 Qb6',
    'g4 Nc6',
    'Rg2 Nfd4',
    'Nxc3 Qa5',
    'Rb1 O-O-O',
    'Bd2 Qxa3',
    'Ne4 dxe4',
    'Qxa3 Nxc2+',
    'Kd1 Nxa3',
    'Ra1 Nd4',
    'Rxa3 Bc6',
    'h3 e3',
    'Rxe3 Ba4+',
    'Kc1 Nb3+',
    'Rxb3 Bxb3',
    'Rg3 Ba4',
    'Bg2 Bc6',
    'Bxc6 bxc6',
    'h4 Rd4',
    'h5 Rgd8',
    'Rg2 Rd3',
    'h6 Rh3',
    'g5 Kd7',
    'Rf2 Ke8',
    'Bb4 Rd4',
    'Bd6 Rh4',
    'f5 exf5',
    'Rxf5 Rdf4',
    'Rxf4 Rxf4',
    'e6 Rh4',
    'exf7+ Kxf7',
    'Kb2 Rh3',
    'Bb4 Kg6',
    'Bd2 a6',
    'Kc2 a5',
    'Bxa5 Kxg5',
    'Bd2+ Kf5',
    'Bc1 Ke4',
    'Bg5 Kd5',
    'Kd1 Ke4',
    'Kd2 Rh5',
    'Bf6 Rxh6',
    'Bg7 Rh3',
    'Kc2 Kd5',
    'Kd2 Kc4',
    'Bf8 Rd3+',
    'Kc2 Rf3',
    'Bd6 Rf2+',
    'Kc1 c5',
    'Be7 Kc3',
    'Kb1 c4',
    'Bh4 Rg2',
    'Be7 Kb3',
    'Bc5 Rh2',
    'Be3 Rh1+',
    'Bc1 Rf1',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Nc3 e6',
    'g4 Bg6',
    'Nge2 c5',
    'h4 h5',
    'Nf4 Nc6',
    'Nxg6 fxg6',
    'Ne2 Qb6',
    'Nf4 cxd4',
    'Bd3 Nxe5',
    'Nxg6 Nxg6',
    'Bxg6+ Kd7',
    'gxh5 Bb4+',
    'Kf1 Rf8',
    'Kg2 Ne7',
    'Qg4 Nxg6',
    'Qxg6 d3',
    'Be3 d4',
    'Qxd3 Bc5',
    'c3 Rxh5',
    'cxd4 Qc6+',
    'Kg1 Bd6',
    'Qg6 Rhf5',
    'Rc1 Qb5',
    'd5 Qxd5',
    'Qxg7+ R8f7',
    'Qc3 Rf8',
    'Qg7+ R8f7',
    'Qc3 Qf3',
    'Qc8+ Ke7',
    'Qg8 Bc5',
    'Rh3 Qxh3',
    'Bxc5+ Kf6',
    'Bd4+ e5',
    'Qd8+ Re7',
    'Qf8+ Ke6',
    'Qg8+ Kf6',
    'Qf8+ Ke6',
    'Qg8+ Kf6',
    'Qf8+ Ke6',
    'Qg8+ Kf6',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 a6',
    'Qc2 e6',
    'c5 e5',
    'dxe5 Nfd7',
    'f4 Nxc5',
    'Nf3 f6',
    'b4 Ncd7',
    'a3 a5',
    'Rb1 axb4',
    'axb4 Na6',
    'Bxa6 Rxa6',
    'e6 Nb6',
    'Nd4 Nc4',
    'O-O Rb6',
    'Qa4 f5',
    'Rd1 Be7',
    'b5 O-O',
    'bxc6 Rxb1',
    'Nxb1 Qe8',
    'Na3 Nd6',
    'Bb2 Bf6',
    'Qb4 Qe7',
    'Qc5 Bxd4',
    'Bxd4 bxc6',
    'Qxc6 Bxe6',
    'Bc5 Rd8',
    'Nb5 Qe8',
    'Qxe8+ Nxe8',
    'Nd4 Bd7',
    'Nf3 Rc8',
    'Be7 Be6',
    'Ra1 h6',
    'Nd4 Kf7',
    'Ra7 Rc7',
    'Rxc7 Nxc7',
    'Bd6 Na8',
  ],
  [
    'c4 e5',
    'Nc3 Nc6',
    'Nf3 Nf6',
    'd3 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'O-O d6',
    'Bg5 h6',
    'Bxf6 Bxf6',
    'Rb1 a5',
    'a3 Bg7',
    'b4 axb4',
    'axb4 f5',
    'Nd2 e4',
    'Nd5 exd3',
    'exd3 Nd4',
    'Nf3 c6',
    'Nf4 Kh7',
    'b5 Ra2',
    'Nxd4 Bxd4',
    'Ne2 Ba7',
    'bxc6 bxc6',
    'Bxc6 f4',
    'Nxf4 Rxf4',
    'gxf4 Bh3',
    'Rb7+ Kh8',
    'Bg2 Bc8',
    'Qb1 Bxb7',
    'Qxa2 Bxg2',
    'Kxg2 Qc8',
    'Qe2 Bd4',
    'Re1 Kh7',
    'Qe6 Qb8',
    'Qf7+ Bg7',
    'Re8 Qb2',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 c6',
    'f4 Bg4',
    'Be2 Bxe2',
    'Qxe2 e6',
    'Nf3 Be7',
    'O-O O-O',
    'e5 Nfd7',
    'Ne4 d5',
    'Ng3 c5',
    'c3 g6',
    'f5 gxf5',
    'Bh6 Re8',
    'Nh5 Nf8',
    'Ng7 Nc6',
    'Nxe8 Qxe8',
    'Be3 Ng6',
    'g3 Rc8',
    'h4 Kh8',
    'dxc5 Qg8',
    'Kh2 Qg7',
    'Bd4 Rg8',
    'Rg1 Nf8',
    'b4 Nd7',
    'Qe3 a6',
    'Raf1 Bd8',
    'Re1 Qg4',
    'Kh1 Qh3+',
    'Nh2 Bc7',
    'Qf4 Rg4',
    'Qh6 Nxd4',
    'cxd4 Rxd4',
    'g4 Rxg4',
    'Re3 Qxh4',
    'Qxh4 Rxh4',
    'Reg3 Rxh2+',
    'Kxh2 Bxe5',
    'Kg2 Bxg3',
    'Kxg3 Kg7',
    'Rc1 Ne5',
    'a4 Nd3',
    'Rb1 Kf6',
    'Kf3 e5',
    'Ke3 Nf4',
    'b5 axb5',
    'axb5 d4+',
    'Kd2 Ke6',
    'c6 Kd6',
    'Rc1 Kc7',
    'b6+ Kc8',
    'c7 Nd5',
    'Rc5 Nxb6',
    'Kd3 f6',
    'Rc1 Nd5',
    'Kc4 Nxc7',
    'Rh1 Kd7',
    'Rh6 Ke6',
    'Rxh7 Ne8',
    'Kc5 Nd6',
    'Rh1 f4',
    'Rb1 f3',
    'Rf1 f2',
    'Kb4 Ne4',
    'Kc4 f5',
    'Kd3 Kd5',
    'Rb1 f4',
    'Ke2 Nc3+',
  ],
  [
    'e4 c5',
    'c3 Nf6',
    'e5 Nd5',
    'd4 cxd4',
    'Nf3 Nc6',
    'Bc4 Nb6',
    'Bb3 d5',
    'exd6 Qxd6',
    'Na3 a6',
    'O-O Be6',
    'Bxe6 Qxe6',
    'Re1 Qd5',
    'Nxd4 Nxd4',
    'Be3 Nb5',
    'Bxb6 Qxd1',
    'Raxd1 Nxa3',
    'bxa3 e6',
    'a4 Be7',
    'Rd3 O-O',
    'Rd7 Bg5',
    'c4 Rfc8',
    'c5 Kf8',
    'Rxb7 Be7',
    'Rc1 Rc6',
    'Rc7 Rxc7',
    'Bxc7 Rc8',
    'Bb6 Ke8',
    'c6 Bb4',
    'Kf1 Ke7',
    'Rc4 Ba3',
    'Ba5 Kd6',
    'c7 Kd5',
    'Rc3 Bd6',
    'Ke2 f5',
    'h3 e5',
    'g4 g6',
    'f3 h5',
    'gxh5 gxh5',
    'Kd3 Bc5',
    'Rc4 Bd6',
    'Rc2 Ke6',
    'Rc6 Kd5',
    'Rxa6 Bxc7',
    'Bxc7 Rxc7',
    'Ra5+ Ke6',
    'Rb5 e4+',
    'fxe4 fxe4+',
    'Kd4 Rc2',
    'a3 Ra2',
    'Rb3 Kf5',
    'a5 Rd2+',
    'Ke3 Rd5',
    'a4 Ke6',
    'Rb6+ Kd7',
    'a6 Ra5',
    'Kxe4 Rxa4+',
    'Kf5 Ke7',
    'Kg6 h4',
    'Rf6 Ra2',
    'a7 Ra3',
    'Rf3 Ra5',
    'Rf4 Ke6',
    'Rf6+ Ke7',
    'Kg7 Rg5+',
    'Kh6 Ra5',
    'Kg6 Ra3',
    'Kg7 Rg3+',
    'Kh6 Ra3',
    'Kg6 Rg3+',
    'Kh5 Kxf6',
    'a8=Q Kg7',
    'Qe4 Kg8',
    'Kh6 Kf7',
    'Qf4+ Ke8',
    'Qxh4 Rg8',
    'Kh7 Rg1',
    'Qe4+ Kf7',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nxc6 bxc6',
    'e5 Nd5',
    'Ne4 Qc7',
    'f4 Qb6',
    'c4 Ne3',
    'Qd3 Nf5',
    'g4 Nd4',
    'Bg2 Rb8',
    'b3 a5',
    'Bd2 Bb7',
    'Bc3 c5',
    'O-O Be7',
    'Qd2 a4',
    'Rab1 axb3',
    'axb3 Bxe4',
    'Bxe4 d5',
    'cxd5 exd5',
    'Bxd5 O-O',
    'Bc4 Qh6',
    'Qg2 Rfd8',
    'Kh1 Ne6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 Bb7',
    'g5 Nfd7',
    'a3 Nb6',
    'Qd2 N8d7',
    'f4 Nc5',
    'Bd3 d5',
    'e5 Nc4',
    'Bxc4 dxc4',
    'Rf1 Qa5',
    'Nce2 b4',
    'Ng3 Ne4',
    'Nxe4 Bxe4',
    'Kf2 c3',
    'bxc3 bxc3',
    'Qe2 Be7',
    'Qc4 Bd5',
    'Qd3 Rc8',
    'Rfd1 Bc4',
    'Qe4 Bd5',
    'Qd3 g6',
    'h4 Bc4',
    'Qe4 Bd5',
    'Qd3 h6',
    'Kg3 Bc4',
    'Qe4 Bd5',
    'Qd3 hxg5',
    'hxg5 Bc4',
    'Qe4 Bd5',
    'Qd3 Rh5',
    'Nf3 Qa4',
    'Nd4 Kf8',
    'Rab1 Kg7',
    'Rb6 Rch8',
    'Rxa6 Rh2',
    'Rg1 R8h3+',
    'Kg4 Qe8',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'a3 Bxc3+',
    'bxc3 Ne7',
    'Qg4 O-O',
    'Bd3 Nbc6',
    'Qh5 Ng6',
    'Nf3 Qc7',
    'Be3 Nce7',
    'O-O c4',
    'Be2 Bd7',
    'a4 f6',
    'Rfe1 fxe5',
    'Nxe5 Nxe5',
    'Qxe5 Qxe5',
    'dxe5 Rfc8',
    'a5 Nf5',
    'Bd2 Be8',
    'Reb1 Rc7',
    'f4 Bg6',
    'Kf2 Ne7',
    'Ra2 Be4',
    'g4 Nc6',
    'h4 Rf8',
    'Kg3 h5',
    'Be3 hxg4',
    'Bxg4 Kf7',
    'Rab2 Rb8',
    'Bc5 Rbc8',
    'Rxb7 Nxa5',
    'Rxa7 Bxc2',
    'Rb8 Rxb8',
    'Rxc7+ Kg6',
    'Bxe6 Nb3',
    'Bxd5 Rb5',
    'Bf7+ Kf5',
    'Be3 Na5',
    'e6 Ba4',
    'e7 Rb3',
  ],
  [
    'd4 d5',
    'Nf3 Nf6',
    'c4 c6',
    'Nc3 dxc4',
    'a4 Bf5',
    'e3 e6',
    'Bxc4 Nbd7',
    'O-O Bb4',
    'Qe2 O-O',
    'Rd1 Qa5',
    'Bd2 e5',
    'Na2 Bxd2',
    'Qxd2 Qc7',
    'dxe5 Nxe5',
    'Nxe5 Qxe5',
    'Nc3 Ng4',
    'Qd6 Bc2',
    'Rd2 Qxd6',
    'Rxd6 Rad8',
    'Rxd8 Rxd8',
    'Be2 Ne5',
    'Kf1 a5',
    'f4 Nd7',
    'Rc1 Bb3',
    'Bd1 Bc4+',
    'Ke1 Re8',
    'Kf2 Nc5',
    'Bc2 Bd3',
    'Kf3 f5',
    'Bxd3 Nxd3',
    'Rb1 h6',
    'e4 fxe4+',
    'Nxe4 Rd8',
    'Rd1 Ne5+',
    'Ke2 Rxd1',
    'Kxd1 Nd3',
    'Nd6 b6',
    'Nc4 b5',
    'axb5 cxb5',
    'Nxa5 Nxb2+',
    'Kc2 Na4',
    'Nb7 Nb6',
    'Nd6 b4',
    'Kb3 Nd5',
    'g3 Kf8',
    'Kc4 Ne3+',
    'Kxb4 Nf1',
    'Kc4 Ke7',
    'Kd5 Nxh2',
    'Nf5+ Kf6',
    'Ne3 Kg6',
    'g4 Nxg4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 b5',
    'Be2 Bb7',
    'a4 b4',
    'Nd5 Nxe4',
    'Nb3 Nd7',
    'Bf3 f5',
    'a5 e6',
    'Nb6 Rb8',
    'Ra4 Be7',
    'Nd4 Ndc5',
    'Rxb4 O-O',
    'O-O Rf6',
    'Nb3 Bc6',
    'Nd4 Bb7',
    'Nb3 Nxb3',
    'Rxb3 f4',
    'Qd3 d5',
    'Bd4 Rf7',
    'c4 dxc4',
    'Nxc4 Nc5',
    'Bxc5 Qxd3',
    'Rxd3 Bxf3',
    'Rxf3 Bxc5',
    'Re1 Rd8',
    'Ne5 Rf6',
    'Rd3 Rxd3',
    'Nxd3 Bd6',
    'Kf1 Kf7',
    'Ne5+ Ke7',
    'Nc6+ Kd7',
    'Nd4 g5',
    'h3 h6',
    'f3 e5',
    'Nb3 Rf8',
    'Nd2 Rc8',
    'Ne4 Bb4',
    'Nc3 Ke6',
    'Ke2 Rc5',
    'Kd3 Bxc3',
    'bxc3 Rxa5',
    'Rb1 Ra2',
    'Rb6+ Kf5',
    'Rxh6 Rxg2',
    'Rh8 Rf2',
    'Rf8+ Ke6',
    'Re8+ Kf6',
    'Rf8+ Kg7',
    'Ra8 Rxf3+',
    'Ke4 Re3+',
    'Kf5 f3',
    'Ra7+ Kh6',
    'h4 gxh4',
    'Rxa6+ Kg7',
    'Ra7+ Kf8',
    'Ke6 e4',
    'c4 h3',
    'c5 h2',
    'Kf6 Ke8',
    'c6 Kd8',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 e5',
    'Nf3 Nbd7',
    'Bc4 Be7',
    'O-O O-O',
    'a4 c6',
    'a5 Qc7',
    'Qe2 h6',
    'dxe5 dxe5',
    'Nh4 Bb4',
    'Qf3 b5',
    'axb6 Nxb6',
    'Bd3 Kh7',
    'Nf5 Ng8',
    'Na4 Be6',
    'b3 Rfe8',
    'Bb2 Bf8',
    'Ne3 g6',
    'Qg3 Nd7',
    'Nc4 Bg7',
    'Ba3 Ngf6',
    'Qe3 Bf8',
    'Bb2 h5',
    'Rfd1 Bh6',
    'Qe1 Rac8',
    'Bf1 Bf8',
    'f3 Bxc4',
    'Bxc4 Nb6',
    'Bd3 Nxa4',
    'Rxa4 Bc5+',
    'Kh1 Rcd8',
    'Bc1 Kg7',
    'Qh4 Rd6',
    'Rf1 Qd7',
    'Rc4 Bb6',
    'Be2 Bd8',
    'Qg3 Rd4',
    'Bb2 h4',
    'Qf2 Rxc4',
    'Bxc4 Nh5',
    'Ba3 Ng3+',
    'hxg3 Bb6',
    'gxh4 Bxf2',
    'Rxf2 Rh8',
    'Bc5 Rxh4+',
    'Kg1 Rh8',
    'Bd3 Qd8',
    'Rf1 a5',
    'Ra1 Qg5',
    'Rxa5 Qc1+',
    'Bf1 Rd8',
    'Ba3 Qe3+',
    'Kh2 Rh8+',
    'Kg3 Qe1+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 a5',
    'bxa5 c5',
    'a4 Rxa5',
    'Ra3 Ne8',
    'Bd2 Ra6',
    'Qb3 h6',
    'Ne1 f5',
    'f3 f4',
    'Nd3 g5',
    'a5 h5',
    'Na4 g4',
    'Nb6 gxf3',
    'Bxf3 Bg4',
    'Nf2 Nf6',
    'Qd1 Bxf3',
    'gxf3 Nd7',
    'Nxd7 Qxd7',
    'Rb3 Ng6',
    'Kh1 Bf6',
    'Rg1 Kh7',
    'Qf1 Rf7',
    'Nd3 Ra8',
    'Nf2 Rg7',
    'Qh3 Qxh3',
    'Nxh3 Kh6',
    'Rg2 Bd8',
    'Rb5 Ra6',
    'Be1 b6',
    'axb6 Rxb6',
    'Rxb6 Bxb6',
    'Rb2 Bd8',
    'Rb8 Rd7',
    'Kg2 Be7',
    'Rb5 Kg7',
    'Ng1 Bd8',
    'Rb8 Nh8',
    'Ne2 Nf7',
    'Nc3 Ba5',
    'Ra8 Bxc3',
    'Bxc3 Rb7',
    'Ra1 Rb3',
    'Rc1 Ra3',
    'Rc2 Ra4',
    'Be1 Ng5',
    'Bh4 Kg6',
    'Kf2 Nf7',
    'Be7 Ra7',
    'Bh4 Kg7',
    'Ke2 Ra3',
    'Be7 Ra7',
    'Bh4 Kf8',
    'Be1 Ra3',
    'Bh4 Ra4',
    'Bf6 Ra1',
    'Kf2 Kg8',
    'Be7 Kh7',
    'Rb2 Ra7',
    'Bh4 Kg6',
    'Kg2 Ra4',
    'Rc2 Ng5',
    'Kf2 Ra3',
    'Bxg5 Kxg5',
    'Rb2 Ra6',
    'Rb8 Kh4',
    'Rh8 Ra2+',
    'Kg1 Rc2',
    'Rh6 Rxc4',
    'Rxd6 Rd4',
    'Rc6 c4',
    'Kg2 Rd2+',
    'Kg1 Rd3',
    'Kf2 c3',
    'Ke2 Re3+',
    'Kf2 Kh3',
    'd6 Rd3',
    'Ke2 Rd2+',
    'Ke1 Kxh2',
    'Rxc3 Rxd6',
    'Rc5 Re6',
    'Kf2 h4',
    'Ra5 h3',
    'Rb5 Ra6',
    'Rxe5 Ra2+',
  ],
  ['e4 c6', 'd4 d5', 'exd5 cxd5', 'Bd3 Nc6', 'c3 Nf6', 'Bf4 Bg4', 'Qb3 Qd7', 'Nd2 e6', 'Ngf3 Bxf3', 'Nxf3 Bd6', 'Bxd6 Qxd6', 'O-O Rb8', 'Rae1 O-O', 'Qc2 b5', 'b4 Rfc8', 'Qd2 Nd7'],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Qa4+ Bd7',
    'Qb3 dxc4',
    'Qxc4 O-O',
    'e4 Bg4',
    'Be3 Nfd7',
    'Qb3 Nb6',
    'Rd1 Nc6',
    'd5 Ne5',
    'Be2 Nxf3+',
    'gxf3 Bh5',
    'Rg1 Qd7',
    'Rg3 e6',
    'a4 Qe7',
    'a5 Nd7',
    'Qxb7 Be5',
    'dxe6 fxe6',
    'Qc6 Rad8',
    'f4 Bxe2',
    'Kxe2 Bxf4',
    'Bxf4 Rxf4',
    'Qxc7 Rf7',
    'Rgd3 a6',
    'R1d2 Qe8',
    'Qc6 Rc8',
    'Qxa6 Ra8',
    'Qc6 Rc8',
    'Qa4 Rc7',
    'a6 Qf8',
    'Rxd7 Rxf2+',
    'Kd1 Qf3+',
    'Kc2 Qf4',
  ],
  [
    'e4 c5',
    'c3 e6',
    'd4 d5',
    'e5 Nc6',
    'Nf3 cxd4',
    'cxd4 Nh6',
    'Bxh6 gxh6',
    'Be2 Bg7',
    'O-O O-O',
    'Na3 f6',
    'exf6 Qxf6',
    'Nc2 Bd7',
    'b4 Be8',
    'b5 Ne7',
    'Ne3 Ng6',
    'a4 Nf4',
    'Ne5 Qg5',
    'Bf3 Bxe5',
    'dxe5 Qxe5',
    'Re1 Qg7',
    'g3 Nh3+',
    'Kg2 Ng5',
    'Bg4 Bg6',
    'f4 Be4+',
    'Kg1 Rxf4',
    'gxf4 Nh3+',
    'Kf1 Nxf4',
    'Ra2 Rf8',
    'Rf2 h5',
    'Bf3 Nh3',
    'Rg2 Ng5',
    'Qa1 Rxf3+',
    'Kg1 Nh3+',
    'Kh1 Rf7',
    'Qc1 Nf4',
    'Rg1 Nxg2',
    'Nxg2 h6',
    'Qd1 Rf2',
    'h4 Rxg2',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'f3 e5',
    'Nb3 d5',
    'Bg5 Be6',
    'Bxf6 gxf6',
    'exd5 Qxd5',
    'Qxd5 Bxd5',
    'Nc3 Be6',
    'O-O-O Nd7',
    'Bb5 O-O-O',
    'Ne4 Be7',
    'Bxd7+ Rxd7',
    'Nbc5 Rxd1+',
    'Rxd1 Kc7',
    'Rd3 Bc8',
    'Rc3 Kb8',
    'Rd3 Kc7',
    'Rc3 f5',
    'Nf6 Kd6',
    'Nh5 Rg8',
    'f4 e4',
    'Nb3 Bd7',
    'Rg3 Rg6',
    'Nd4 Bh4',
    'Rxg6+ fxg6',
    'Ng3 Kd5',
    'Nde2 g5',
    'Kd2 gxf4',
    'Nxf4+ Ke5',
    'Nge2 Bf2',
    'g3 Be8',
    'Nc3 Bg1',
    'Ke2 h5',
    'h4 Bf7',
    'b3 Bc5',
    'Nd1 Be7',
    'Ng2 Bf8',
    'Nf4 Be7',
    'Ng2 Bf8',
    'Nf4 b5',
    'Nc3 a6',
    'a4 Kd4',
    'Nd1 bxa4',
    'bxa4 Bh6',
    'Ne3 Ke5',
    'Kf2 Bxf4',
    'gxf4+ Kxf4',
    'Ng2+ Ke5',
    'Ke3 a5',
    'Nf4 Be8',
    'c4 Bxa4',
    'Nxh5 Be8',
    'Nf4 a4',
    'Nd5 a3',
    'Nc3 f4+',
    'Kd2 Kd4',
    'h5 e3+',
    'Kc2 Kxc4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 O-O',
    'Qd2 Nc6',
    'O-O-O Nxd4',
    'Bxd4 Be6',
    'Kb1 Qc7',
    'g4 Rfc8',
    'g5 Nh5',
    'Bxg7 Nxg7',
    'Nd5 Qd7',
    'Be2 Rc5',
    'f4 Bxd5',
    'exd5 Qf5',
    'Rhg1 e6',
    'dxe6 Nxe6',
    'Rgf1 Rac8',
    'Bd3 Qg4',
    'Qe3 a5',
    'Be4 Rd8',
    'h3 Qh4',
    'Bd5 Re8',
    'Bxb7 Nc7',
    'Qf3 d5',
    'f5 Qe4',
    'Qf2 Qe3',
    'fxg6 Qxf2',
    'gxh7+ Kxh7',
    'Rxf2 Kg6',
    'Rf6+ Kxg5',
    'Rc6 Rxc6',
    'Bxc6 Rd8',
    'c4 d4',
    'Bd5 Nxd5',
    'Rxd4 Nc3+',
    'bxc3 Rh8',
    'h4+ Kf5',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 g6',
    'd4 cxd4',
    'Nxd4 Bg7',
    'Be3 Qa5',
    'Nb3 Qd8',
    'f4 d6',
    'Be2 Nf6',
    'g4 O-O',
    'g5 Ne8',
    'h4 e5',
    'h5 exf4',
    'Bxf4 Be6',
    'Qd2 a5',
    'Nb5 a4',
    'N3d4 a3',
    'b3 d5',
    'hxg6 hxg6',
    'O-O-O Nxd4',
    'Nxd4 Qb6',
    'Bf3 Qxd4',
    'Qxd4 Bxd4',
    'Rxd4 Kg7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nf6',
    'Nxc6 bxc6',
    'e5 Qe7',
    'Qe2 Nd5',
    'c4 Ba6',
    'b3 O-O-O',
    'Bb2 c5',
    'g3 Nb4',
    'Bg2 d5',
    'O-O dxc4',
    'bxc4 Qe6',
    'Nd2 Nd3',
    'Bc3 Nxe5',
    'Bxe5 f6',
    'Qh5 Qxe5',
    'Bh3+ Kb8',
    'Rab1+ Bb7',
    'Qf3 c6',
    'Qxc6 Qc7',
    'Rxb7+ Qxb7',
    'Rb1 Qxb1+',
    'Nxb1 Be7',
    'Qb5+ Kc7',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'd4 Bg7',
    'Qb3 dxc4',
    'Qxc4 O-O',
    'e4 Na6',
    'e5 Nd7',
    'a3 Nb6',
    'Qb3 Be6',
    'Qd1 c5',
    'Bxa6 bxa6',
    'dxc5 Nd7',
    'Be3 Qb8',
    'Qe2 Nxe5',
    'Nxe5 Bxe5',
    'Rc1 Rd8',
    'b4 Bf5',
    'Qxa6 Bd3',
    'Qa4 Bxh2',
    'b5 Be5',
    'Qh4 h5',
    'a4 Bf6',
    'Qh2 Qc8',
    'Qh3 Qc7',
    'g4 Bxc3+',
    'Rxc3 Qe5',
    'Rc1 Qb2',
    'Qf3 Rd4',
    'gxh5 Qb4+',
    'Bd2 Re4+',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'f4 g6',
    'd4 cxd4',
    'Qxd4 Nf6',
    'e5 Nc6',
    'Bb5 dxe5',
    'Qxd8+ Kxd8',
    'fxe5 Nxe5',
    'Bf4 Nc6',
    'O-O-O+ Bd7',
    'Bc4 Nh5',
    'Be3 Kc8',
    'Nf3 Bg7',
    'Bxf7 Rf8',
    'Ng5 Ne5',
    'Be6 h6',
    'Bxd7+ Nxd7',
    'Ne6 Rf6',
    'Nxg7 Nxg7',
    'Bxh6 Nf5',
    'Bg5 Re6',
    'g4 Nd6',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'f4 g6',
    'Nf3 Bg7',
    'Bc4 Nc6',
    'O-O e6',
    'd3 Nge7',
    'Qe1 O-O',
    'f5 d5',
    'Bb3 dxe4',
    'f6 exf3',
    'fxg7 Kxg7',
    'Rxf3 Nd4',
    'Rf2 Nef5',
    'Ne4 h5',
    'Nxc5 Qe7',
    'Ne4 a5',
    'c3 Nxb3',
    'axb3 f6',
    'b4 Rd8',
    'd4 b6',
    'bxa5 Rxa5',
    'Rxa5 bxa5',
    'Ng3 Nd6',
    'Ne4 Nf5',
    'Ng3 Nd6',
  ],
  [
    'c4 Nf6',
    'Nc3 e5',
    'g3 Nc6',
    'Bg2 g6',
    'e4 Bg7',
    'd3 d6',
    'Nge2 Be6',
    'O-O Qd7',
    'f4 O-O',
    'Nd5 exf4',
    'Ndxf4 Bg4',
    'h3 Bxe2',
    'Nxe2 Ne5',
    'Be3 c5',
    'Qd2 Rab8',
    'b3 b5',
    'Rad1 Ne8',
    'cxb5 Qxb5',
    'd4 cxd4',
    'Nxd4 Qb7',
    'Bh6 Qb4',
    'Qe3 Rc8',
    'Bxg7 Nxg7',
    'Qd2 Qxd2',
    'Rxd2 Rfd8',
    'Rfd1 Nh5',
    'Kf2 Rc3',
    'Ne2 Rc6',
    'Nd4 Rc3',
    'Nf3 Nxf3',
    'Bxf3 Ng7',
    'Rxd6 Rxd6',
    'Rxd6 Ne6',
    'Rd2 Kg7',
    'h4 h5',
    'e5 Nc5',
    'Ke2 a5',
    'Rb2 Nd7',
    'Kf2 Nxe5',
    'Be2 Nc6',
    'Rd2 Ne7',
    'Rd7 Kf6',
    'Rd6+ Kg7',
    'Ra6 Nf5',
    'Rxa5 Nxg3',
    'Bc4 Nf5',
    'Ra7 Nd6',
    'Bd5 Rd3',
    'Bf3 Nf5',
    'Be4 Rd2+',
    'Ke1 Rb2',
    'Bxf5 gxf5',
    'Ra6 f4',
    'Kf1 f6',
    'Kg1 Kg6',
    'Ra5 Kh6',
    'Ra4 Kg6',
    'Ra5 Kh6',
    'Kf1 Kg6',
    'Ke1 f3',
    'Kf1 Kh6',
    'a3 Rxb3',
    'Kf2 Kg6',
    'Kg3 Kh6',
    'Ra8 Kg6',
    'a4 Ra3',
    'a5 Kf5',
    'a6 f2+',
    'Kxf2 Kg4',
    'Ke2 Kxh4',
    'Kd2 Kh3',
    'Ke2 h4',
    'Kf2 Kh2',
    'a7 h3',
    'Kf1 Ra2',
    'Rf8 Rxa7',
    'Rxf6 Ra1+',
    'Kf2 Ra2+',
    'Kf1 Rg2',
    'Rf3 Rg7',
    'Kf2 Rg2+',
    'Kf1 Rg1+',
    'Kf2 Ra1',
    'Rb3 Ra2+',
    'Kf1 Ra1+',
    'Kf2 Ra6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bb5+ Nd7',
    'd4 cxd4',
    'Qxd4 Qc7',
    'Nc3 e6',
    'O-O Ne7',
    'Bxd7+ Bxd7',
    'Rd1 Ng6',
    'Be3 a6',
    'a4 Rc8',
    'a5 Ne5',
    'Nxe5 dxe5',
    'Qd3 Bc6',
    'Bb6 Qe7',
    'Rab1 Qb4',
    'Qh3 Bd6',
    'Qe3 Be7',
    'f3 O-O',
    'Kh1 Bb5',
    'Nxb5 Qxb5',
    'c3 Bc5',
    'Bxc5 Rxc5',
    'b4 Rc7',
    'h3 Rfc8',
    'Rd3 h6',
    'Rbd1 Kh7',
    'Qd2 Rc4',
    'Rd7 R8c7',
    'Qd6 Qa4',
    'Rxc7 Rxc7',
    'Qxc7 Qxd1+',
    'Kh2 Qd2',
    'Qxf7 Qxc3',
    'Qxb7 Qd2',
    'Qb6 Qf4+',
    'Kh1 Qc1+',
    'Qg1 Qb2',
    'Qc5 h5',
    'Kh2 h4',
    'Qd6 Qb3',
    'Qxa6 Qxb4',
    'Qb6 Qe1',
    'a6 Qg3+',
    'Kh1 Qe1+',
    'Qg1 Qa5',
    'a7 g5',
    'Kh2 Kg6',
    'Qf2 Kh6',
    'Qe3 Kh5',
    'Qg1 Kh6',
    'Qf2 Kg6',
    'Qe3 Kh5',
    'Qb3 Qxa7',
    'Qxe6 Qc5',
    'Qf7+ Kh6',
    'Qf6+ Kh5',
    'Qg7 Qd6',
    'g4+ hxg3+',
    'Kxg3 Qb6',
    'Qh7+ Qh6',
    'Qf7+ Qg6',
    'Qxg6+ Kxg6',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Bc5',
    'Be3 Qf6',
    'c3 Nge7',
    'Bc4 O-O',
    'O-O Bb6',
    'f4 d5',
    'Bxd5 Nxd4',
    'Bxd4 Bxd4+',
    'Qxd4 Qxd4+',
    'cxd4 Nxd5',
    'exd5 Re8',
    'Nc3 a6',
    'Rfe1 Kf8',
    'Kf2 Bd7',
    'h3 h5',
    'Re5 g6',
    'Ne4 Red8',
    'Rc1 Rac8',
    'Nc5 f6',
    'Re2 Bf5',
    'Nxb7 Rxd5',
    'Rd2 a5',
    'Rc5 Be6',
    'a4 Rxc5',
    'dxc5 Bb3',
    'Rd4 Bc2',
    'c6 Re8',
    'Nc5 Bf5',
    'b3 Ke7',
    'Nb7 Ra8',
    'Rd5 Ra6',
    'Nxa5 Be6',
    'Rc5 Rb6',
    'Rc3 Rb4',
    'Ke3 Bd5',
    'g3 g5',
    'fxg5 fxg5',
    'Kd2 Be6',
    'Rc5 Kf6',
    'Kc3 Re4',
    'Nc4 Bxh3',
    'a5 Bc8',
    'b4 Rg4',
    'b5 Rxg3+',
    'Kb4 h4',
    'Nd2 Rg4+',
    'Rc4 Rg1',
    'b6 cxb6',
    'axb6 h3',
    'b7 Bxb7',
    'cxb7 h2',
    'b8=Q h1=Q',
    'Qf8+ Kg6',
    'Qe8+ Kf5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be2 e5',
    'Nb3 Be6',
    'f4 Qc7',
    'O-O Be7',
    'Kh1 Nbd7',
    'f5 Bc4',
    'a4 O-O',
    'g4 h6',
    'g5 hxg5',
    'Bxg5 d5',
    'exd5 Bxd5+',
    'Bf3 Bxf3+',
    'Qxf3 Qc6',
    'Qxc6 bxc6',
    'Na5 Bb4',
    'Nxc6 Bxc3',
    'bxc3 Rfe8',
    'a5 Rac8',
    'Nb4 Ne4',
    'Be3 Rxc3',
    'Bg1 Rc4',
    'Rfb1 Nec5',
    'Nd3 Rxc2',
    'Nxc5 Nxc5',
    'Rb6 e4',
    'Rc6 Nb3',
    'Rxc2 Nxa1',
    'Rc6 e3',
    'Bxe3 Rxe3',
    'Rxa6 Ra3',
    'Ra8+ Kh7',
    'a6 Nc2',
    'f6 gxf6',
    'a7 Ne3',
    'Kg1 Ng4',
    'h3 Ne5',
    'Kg2 Nc6',
    'Rb8 Rxa7',
    'Rb6 Rc7',
    'Kg3 Kg6',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 Nf6',
    'Bb5 Nd4',
    'e5 Nxb5',
    'Nxb5 Nd5',
    'Ng5 f6',
    'Ne4 a6',
    'Nbc3 e6',
    'Qh5+ g6',
    'Qf3 fxe5',
    'Nxd5 exd5',
    'Nf6+ Kf7',
    'Nxd5+ Kg7',
    'h4 h5',
    'd4 Be7',
    'dxe5 Bxh4',
    'Qe4 Bg5',
    'Bf4 Bxf4',
    'Nxf4 Qg5',
    'O-O-O Rf8',
    'g3 a5',
    'Qe3 Qf5',
    'Rd6 Ra6',
    'Rhd1 Re8',
    'Nxg6 Rxd6',
    'Rxd6 Re6',
    'Ne7 Rxe7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bc4 Nc6',
    'O-O Nf6',
    'd3 g6',
    'h3 Bg7',
    'Bb3 O-O',
    'c3 b5',
    'Re1 d5',
    'e5 Ne8',
    'Bf4 Bf5',
    'Na3 Nc7',
    'Qd2 a5',
    'Bd1 d4',
    'c4 b4',
    'Nb1 a4',
    'Qe2 b3',
    'a3 f6',
    'Nbd2 Ra6',
    'Bh2 Nxe5',
    'Nxe5 fxe5',
    'Ne4 Rc6',
    'Qd2 Ne6',
    'Bf3 Ra6',
    'h4 Qa5',
    'Rad1 Qxd2',
    'Rxd2 Bh6',
    'Rde2 Bf4',
    'g3 Bh6',
    'Kg2 Bh3+',
    'Kxh3 Rxf3',
    'Kg4 Rxd3',
    'f4 Bg7',
    'h5 exf4',
    'gxf4 Re3',
    'f5 gxf5+',
    'Kxf5 Bh6',
    'Rg2+ Kf7',
    'Rf1 Ng7+',
    'Ke5+ Ke8',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 Bg4',
    'Be2 c6',
    'O-O Bxf3',
    'Bxf3 dxe5',
    'dxe5 e6',
    'Qe2 Nd7',
    'c4 Ne7',
    'Nc3 Qa5',
    'Re1 Ng6',
    'Be4 Bb4',
    'Bd2 Ndxe5',
    'h4 O-O-O',
    'h5 Nxc4',
    'Qxc4 Rxd2',
    'hxg6 hxg6',
    'Re3 Qe5',
    'g3 Bc5',
    'Re2 Rd4',
    'Bg2 Qh5',
    'Qb3 Rd3',
    'Kf1 Qh2',
    'Rxe6 Rxg3',
    'fxg3 Qg1+',
    'Ke2 Qf2+',
    'Kd3 Rd8+',
    'Bd5 fxe6',
    'Qc4 cxd5',
    'Qg4 Qf5+',
    'Qxf5 gxf5',
    'Re1 Kd7',
    'Rf1 Ke7',
    'a3 Kf7',
    'g4 Bd6',
    'Nb5 Bg3',
    'Nxa7 Be5',
    'Nb5 Rc8',
    'Re1 Kf6',
  ],
  [
    'd4 d6',
    'e4 Nf6',
    'Nc3 g6',
    'Bg5 Bg7',
    'Qd2 h6',
    'Be3 Ng4',
    'Bf4 e5',
    'dxe5 Nxe5',
    'Be3 Nbc6',
    'h3 g5',
    'g3 Be6',
    'Nd5 Ne7',
    'f4 gxf4',
    'gxf4 N5c6',
    'O-O-O f5',
    'Bc4 Bf7',
    'exf5 Nxd5',
    'Bxd5 Qd7',
    'Ne2 Qxf5',
    'Bxc6+ bxc6',
    'Bd4 Bxd4',
    'Nxd4 Qd5',
    'Qc3 Qc4',
    'Qxc4 Bxc4',
    'Nxc6 Kf7',
    'Nd4 Kf6',
    'b3 Bf7',
    'Rhg1 a6',
    'Rde1 Rhg8',
    'Kd2 c5',
    'Ne2 Be6',
    'c4 h5',
    'Ng3 Bxh3',
    'Nxh5+ Kf5',
    'Ke3 Rae8+',
    'Kf3 Rxe1',
    'Rxg8 Rf1+',
    'Ke3 Re1+',
    'Kf3 Rf1+',
    'Ke3 Re1+',
    'Kd2 Ra1',
    'a4 Ke4',
    'Rg6 d5',
    'cxd5 Kxd5',
    'Rxa6 c4',
    'Nf6+ Kc5',
    'Ra5+ Kb6',
    'b4 Be6',
    'Nd5+ Kc6',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 e6',
    'Nc3 f5',
    'g3 Nf6',
    'Bg2 Bd6',
    'O-O O-O',
    'b3 Bd7',
    'Ne5 Be8',
    'Nd3 Nbd7',
    'cxd5 exd5',
    'f3 Bf7',
    'Bh3 Bg6',
    'Bg5 Qe8',
    'Qd2 Nh5',
    'Bf4 Nxf4',
    'gxf4 Qe7',
    'e3 Qh4',
    'Bg2 Rae8',
    'Qf2 Qh5',
    'Ne2 Re6',
    'Ne5 Be8',
    'Ng3 Qh4',
    'Ne4 Qxf2+',
    'Nxf2 c5',
    'Rac1 cxd4',
    'exd4 Nb8',
    'Nfd3 Bb5',
    'Rfd1 Bxd3',
    'Rxd3 Nc6',
    'Kf2 Bc7',
    'Rdc3 Ba5',
    'Rc5 Bb6',
    'Rxd5 Bxd4+',
    'Kf1 Be3',
    'Rc4 g6',
    'Rd7 Rxe5',
    'fxe5 Nxe5',
    'Rxb7 Nxc4',
    'bxc4 Rf7',
    'Rxf7 Kxf7',
    'Ke2 Bf4',
    'h3 Ke6',
    'Kd3 Kd6',
    'Kd4 Be5+',
    'Ke3 Kc5',
    'f4 Bd4+',
    'Kd3 h6',
  ],
  [
    'e4 c5',
    'Nc3 g6',
    'Nge2 Bg7',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Be3 Nf6',
    'Bc4 O-O',
    'Bb3 a5',
    'O-O Nxd4',
    'Bxd4 d6',
    'f4 Bd7',
    'a4 Bc6',
    'Kh1 Nd7',
    'Bxg7 Kxg7',
    'Qd4+ f6',
    'Rae1 Qb6',
    'Qd2 Nc5',
    'Rf3 Nxb3',
    'cxb3 Qb4',
    'Qf2 Rf7',
    'Rg3 Rg8',
    'f5 Kh8',
    'fxg6 Rxg6',
    'Nd5 Bxd5',
    'exd5 Rxg3',
    'hxg3 Qxb3',
    'Rxe7 Qxd5',
    'Rxf7 Qxf7',
    'Qf5 b6',
    'Qc8+ Kg7',
    'Qd8 Qh5+',
    'Kg1 Qc5+',
    'Kh2 h5',
    'Qe7+ Kg6',
    'Qe8+ Kg5',
    'Qd7 Qe5',
    'b3 d5',
    'Qg7+ Kf5',
    'Qh7+ Ke6',
    'Qg8+ Kd7',
    'Qf7+ Kc6',
    'Qf8 d4',
    'Qc8+ Kd6',
    'Qa6 Kc7',
    'Qc4+ Kd7',
    'Kg1 Kd6',
    'Kf1 Qf5+',
    'Ke1 Qe6+',
    'Kd2 Qxc4',
    'bxc4 Ke6',
    'Ke2 Kf5',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 O-O',
    'a3 Bxc3+',
    'Qxc3 b6',
    'Bg5 Bb7',
    'e3 c5',
    'dxc5 bxc5',
    'f3 Nc6',
    'Bd3 h6',
    'Bh4 d5',
    'Ne2 d4',
    'exd4 cxd4',
    'Qd2 e5',
    'Ng3 Ne4',
    'Bxd8 Nxd2',
    'Kxd2 Raxd8',
    'b4 g6',
    'Rae1 Rfe8',
    'Ne4 Re7',
    'c5 Bc8',
    'Nd6 Be6',
    'Re2 Kg7',
    'Rhe1 f6',
    'Bb5 Rc7',
    'f4 Bg4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f3 e5',
    'Nb3 Be6',
    'Be3 Be7',
    'Qd2 O-O',
    'O-O-O Qc7',
    'g4 Rc8',
    'g5 Nh5',
    'Rg1 Nd7',
    'Nd5 Bxd5',
    'exd5 a5',
    'a4 Nf4',
    'Kb1 Nb6',
    'Bxf4 exf4',
    'Bb5 Nc4',
    'Bxc4 Qxc4',
    'Rde1 Bf8',
    'Re4 Qc7',
    'Rge1 Qd7',
    'Qxf4 b5',
    'Nd4 bxa4',
    'Nc6 Re8',
    'Qg4 Rxe4',
    'Rxe4 Qb7',
    'Rxa4 g6',
    'Ka2 Bg7',
    'c3 Qb6',
    'Qd7 Bxc3',
    'bxc3 Qf2+',
    'Kb3 Qxf3',
    'Qg4 Qxd5+',
    'Qc4 Qd1+',
    'Ka3 Qa1+',
    'Kb3 Qb1+',
    'Ka3 Qa1+',
    'Kb3 Qb1+',
  ],
  [
    'c4 Nf6',
    'Nc3 c5',
    'e4 Nc6',
    'Nf3 e5',
    'd3 d6',
    'a3 Be7',
    'Be2 Nd4',
    'b4 b6',
    'O-O O-O',
    'Bg5 Be6',
    'Rb1 h6',
    'Be3 Nxe2+',
    'Qxe2 Bg4',
    'h3 Bh5',
    'bxc5 bxc5',
    'Rb7 a6',
    'Rfb1 Re8',
    'g4 Bg6',
    'Bd2 Bf8',
    'Kg2 Re7',
    'R7b3 Re8',
    'Rb7 Re7',
    'R7b3 Re8',
    'Nh2 Ra7',
    'Nf1 Nh7',
    'Nd5 Nf6',
    'Nfe3 Nxd5',
    'cxd5 f6',
    'Nc4 Qd7',
    'Qd1 Kh7',
    'Rb6 Rc8',
    'f4 Be8',
    'Qf3 Kg8',
    'fxe5 fxe5',
    'Rb8 Qd8',
    'Rxc8 Qxc8',
    'Rb6 Rb7',
    'Rxd6 Bb5',
    'Re6 Bxc4',
    'dxc4 Rb2',
    'Qc3 Qb8',
    'd6 Rb3',
    'Qxe5 Rd3',
    'Be1 Rxd6',
    'Rxd6 Bxd6',
    'Qd5+ Kh7',
    'Bf2 Qc7',
    'Qe6 Bf8',
    'h4 Qd8',
    'Qd5 Qe8',
    'e5 Qg6',
    'Qf3 Kg8',
    'Qd5+ Kh8',
    'Qf3 Kg8',
    'Qf5 Qc6+',
    'Kg1 Be7',
    'e6 Qd6',
    'Kg2 Kh8',
    'g5 Qc6+',
    'Qd5 Qe8',
    'Qd7 Qf8',
    'gxh6 gxh6',
    'Bg3 Kg8',
    'a4 h5',
    'Qd5 Qe8',
    'Qf5 Qc6+',
    'Kh2 Qe8',
    'Kg2 Bf8',
    'Be5 Bh6',
    'Kh3 Bf8',
    'Qf6 Kh7',
    'Qh8+ Kg6',
    'Qg8+ Kf5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d5',
    'cxd5 Nxd5',
    'O-O Nb6',
    'Nc3 Nc6',
    'e3 e5',
    'd5 Na5',
    'e4 c6',
    'Bg5 f6',
    'Be3 cxd5',
    'Bxb6 Qxb6',
    'Nxd5 Qd8',
    'Rc1 Nc6',
    'Qb3 Rf7',
    'Rfd1 Be6',
    'h4 Bh6',
    'Rc3 Kh8',
    'Qa4 Qf8',
    'Kh2 g5',
    'a3 a6',
    'Ne3 gxh4',
    'Nxh4 Bxe3',
    'Rxe3 Nd4',
    'Bh3 Bxh3',
    'Kxh3 b5',
    'Qa5 Qc8+',
    'Kg2 Qg4',
    'Qd2 Rg8',
    'Rh1 Rc7',
    'Qd3 Ne6',
    'Rf3 Nf4+',
    'Rxf4 exf4',
    'Qd6 Rf7',
    'Qd5 Rfg7',
    'Nf5 f3+',
    'Kg1 Rc7',
    'Rh4 Qg5',
    'Kh2 Qc1',
    'Qd4 Rf8',
    'Rf4 Qc5',
    'Rxf3 Qxd4',
    'Nxd4 Re7',
    'Rf4 Kg8',
    'Kg2 Rd7',
    'Ne6 Rff7',
    'Nc5 Rd6',
    'b4 Re7',
    'Rf3 Ra7',
    'a4 Rd4',
    'axb5 axb5',
    'Rxf6 Rxb4',
    'Rb6 Rb2',
    'e5 Raa2',
    'Ne4 Ra7',
    'e6 Kg7',
    'Nd6 Re2',
    'e7 Raxe7',
    'Nf5+ Kf7',
    'Nxe7 Rxe7',
    'Rxb5 Ra7',
    'Rb6 Kg7',
    'Rc6 Rb7',
    'Ra6 Rc7',
    'Kh3 Rb7',
    'Rc6 Ra7',
    'Rd6 Rc7',
    'Re6 Ra7',
    'Rc6 Rb7',
    'Kg4 Ra7',
    'f4 Ra3',
    'Rc7+ Kh6',
    'Rc6+ Kg7',
    'Rd6 Rc3',
    'f5 Ra3',
    'Rd7+ Kg8',
    'Kf4 h5',
    'Re7 Rb3',
    'Re3 Rb8',
    'Kg5 Kf7',
    'Kxh5 Kf6',
    'Rf3 Rg8',
    'Kh4 Rh8+',
    'Kg4 Ra8',
    'Kh4 Rh8+',
    'Kg4 Ra8',
    'Rf4 Rg8+',
    'Kf3 Ra8',
    'g4 Kg5',
    'Re4 Ra3+',
    'Ke2 Rb3',
    'Kf2 Ra3',
    'Kg2 Rb3',
    'Kf2 Ra3',
    'Ke2 Rb3',
    'Rd4 Ra3',
    'Rb4 Rc3',
    'Re4 Ra3',
    'Kf2 Rb3',
    'Re3 Rb2+',
    'Kf3 Rb4',
    'Re8 Rf4+',
    'Ke3 Rxg4',
    'Rg8+ Kxf5',
    'Rxg4 Kxg4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 dxe4',
    'Nxe4 Nbd7',
    'Nxf6+ Nxf6',
    'Nf3 h6',
    'Be3 Nd5',
    'Bd3 Nxe3',
    'fxe3 Bd6',
    'e4 e5',
    'dxe5 Bc5',
    'Bc4 Qe7',
    'Qd2 O-O',
    'O-O-O c6',
    'Rhf1 b5',
    'Bb3 a5',
    'a3 a4',
    'Ba2 b4',
    'axb4 a3',
    'b3 Bxb4',
    'c3 Ba5',
    'b4 Bc7',
    'Nd4 Qxe5',
    'Nxc6 Qe8',
    'Nd4 Bg4',
    'Rde1 Be5',
    'h3 Be6',
    'Nxe6 fxe6',
    'Rf5 Bf6',
    'Kb1 Kh8',
    'Rf3 Be5',
    'Rd1 Qc6',
    'Rd3 Rac8',
    'c4 Qb6',
    'c5 Rxc5',
    'Rxa3 Rcc8',
    'Rd3 Kh7',
    'Rb3 Rf2',
    'Qd3 Rxg2',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'e4 Nxc3',
    'bxc3 Bg7',
    'd4 c5',
    'Be3 Qa5',
    'Qd2 Nc6',
    'Rb1 O-O',
    'Rb5 cxd4',
    'Rxa5 dxe3',
    'Qxe3 Nxa5',
    'h4 Be6',
    'Ng5 Bxa2',
    'h5 h6',
    'Nf3 g5',
    'Nxg5 Nc4',
    'Bxc4 Bxc4',
    'e5 a5',
    'Nf3 a4',
    'Nd2 a3',
    'Nxc4 a2',
    'O-O a1=Q',
    'Rxa1 Rxa1+',
    'Kh2 Ra4',
    'Qc5 Rb8',
    'Qc7 Rf8',
    'g3 b5',
    'Ne3 Re4',
    'Nf5 Rxe5',
    'Nxg7 Rg5',
    'f4 Rxg7',
    'Qxe7 Rc8',
    'Qe5 Rc4',
    'Qe8+ Kh7',
    'Qe3 Kg8',
    'Qe5 Kh7',
    'Qf5+ Kg8',
    'Qe5 Kh7',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 c5',
    'd5 exd5',
    'cxd5 d6',
    'e4 g6',
    'f4 Bg7',
    'e5 Nfd7',
    'Ne4 dxe5',
    'Nd6+ Ke7',
    'Nxc8+ Qxc8',
    'Nf3 Re8',
    'fxe5 Kf8',
    'e6 fxe6',
    'dxe6 Rxe6+',
    'Be2 Qe8',
    'O-O Rxe2',
    'Nd4+ Kg8',
    'Nxe2 Nc6',
    'Bd2 Nb6',
    'Bc3 Rd8',
    'Qe1 Ne5',
    'Qh4 Nd5',
    'Rad1 Rd7',
    'Bxe5 Qxe5',
    'Nc3 Nf6',
    'Rxd7 Nxd7',
    'Qd8+ Nf8',
    'Nd5 Qd4+',
    'Kh1 h5',
    'Nf6+ Kh8',
    'Nd7 Qc4',
    'Rxf8+ Kh7',
    'h4 Bxf8',
    'Nxf8+ Kg8',
    'Nd7+ Kh7',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'e4 Nxc3',
    'dxc3 Qxd1+',
    'Kxd1 f6',
    'Be3 e5',
    'Nd2 Nd7',
    'Bc4 Nc5',
    'b4 Ne6',
    'Kc2 h5',
    'Nb3 Bh6',
    'Bxh6 Rxh6',
    'Rhd1 Nf4',
    'Bf1 b6',
    'a4 Bb7',
    'f3 Rd8',
    'h4 g5',
    'g3 Ng6',
    'hxg5 fxg5',
    'Rxd8+ Kxd8',
    'Bh3 h4',
    'Rg1 Bc6',
    'b5 Bd7',
    'Bxd7 Kxd7',
    'Nd2 Ke6',
    'Nf1 Ne7',
    'Kd3 Nc8',
    'Ne3 Nd6',
    'Rh1 Rh7',
    'Rh3 Nb7',
    'Kc4 Nd6+',
    'Kd3 Rd7',
    'Ke2 hxg3',
    'Rxg3 Rg7',
    'Rh3 Nf7',
    'Rh1 Rg8',
    'Nd5 g4',
    'fxg4 Rxg4',
    'Kf3 Rg6',
    'Nxc7+ Kd6',
    'Na6 Ng5+',
    'Ke3 Ne6',
    'Kd3 Rg4',
    'Rh6 Rg3+',
    'Kc4 Rg4',
    'Nc7 Kxc7',
    'Rxe6 Rxe4+',
    'Kd5 Rxa4',
    'Re7+ Kd8',
    'Rxe5 Rh4',
    'Kc6 Rh7',
    'c4 Rc7+',
    'Kd5 Kc8',
    'Re8+ Kb7',
    'Kd4 Rh7',
    'Rf8 Kc7',
    'Rf6 Kb7',
    'Rg6 a5',
    'bxa6+ Ka7',
    'Kc3 Rh1',
    'Kb3 Rb1+',
    'Kc2 Ra1',
    'Kb3 Rc1',
    'Rf6 Rb1+',
    'Kc2 Rh1',
    'Kd3 Rh5',
    'Kc3 Kxa6',
    'Kb4 Rh4',
    'Rf7 Rh1',
    'Rf5 Rb1+',
    'Kc3 Kb7',
    'Rf6 Rh1',
    'Kb4 Rh5',
    'Rg6 Ka6',
    'Rf6 Rg5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 a6',
    'Bg5 Ne4',
    'Bf4 Nxc3',
    'bxc3 dxc4',
    'e4 b5',
    'Ne5 Qa5',
    'Bd2 Nd7',
    'Nxc6 Qc7',
    'd5 Nc5',
    'Qf3 e5',
    'Nb4 Bd6',
    'Nc2 f5',
    'exf5 O-O',
    'g4 e4',
    'Qh3 Bb7',
    'Ne3 Bf4',
    'Be2 Bxd5',
    'O-O Bb7',
    'Rfd1 Nd3',
    'Nc2 Bxd2',
    'Rxd2 Qa5',
    'Nb4 Qa3',
    'Qe3 Qxc3',
    'Rb1 a5',
    'Nc2 b4',
    'Nd4 Rfd8',
    'Ne6 Rd7',
    'f3 exf3',
    'Bxf3 Bxf3',
    'Qxf3 Rc8',
    'Qe3 a4',
    'Kf1 Re8',
    'Rbd1 b3',
    'axb3 axb3',
    'Qb6 Rde7',
    'Kg2 b2',
    'h4 h6',
    'Qb5 Rxe6',
    'fxe6 Rxe6',
    'Qf5 Re8',
    'Qd5+ Kh8',
    'Qc6 Nf4+',
    'Kh1 Qh3+',
    'Rh2 Qe3',
    'Rxb2 Rf8',
    'g5 Nd3',
    'gxh6 Qh3+',
    'Kg1 Qe3+',
    'Kh1 Nxb2',
    'hxg7+ Kxg7',
    'Qb7+ Kh8',
    'Qxb2+ c3',
    'Qg2 Rg8',
    'Qf1 Qe4+',
    'Kh2 Qe5+',
    'Kh1 c2',
    'Rc1 Qe4+',
    'Kh2 Qe5+',
    'Kh1 Qd5+',
    'Kh2 Qd6+',
    'Kh1 Qc6+',
    'Kh2 Qc7+',
    'Kh1 Qb7+',
    'Kh2 Qb8+',
    'Kh1 Qa8+',
    'Kh2 Qb8+',
    'Kh1 Qb7+',
    'Kh2 Qc7+',
    'Kh1 Qc6+',
    'Kh2 Qd6+',
    'Kh1 Qd5+',
    'Kh2 Qe5+',
    'Kh1 Qe4+',
    'Kh2 Rg4',
    'Qf8+ Kh7',
    'Qf7+ Kh8',
    'Qf8+ Kh7',
    'Qf7+ Rg7',
    'Qf2 Rc7',
    'h5 Qd5',
    'Qe2 Qd6+',
    'Kh1 Qd5+',
    'Kh2 Qf5',
    'Qg2 Qf4+',
    'Qg3 Qd2+',
    'Qg2 Qxc1',
    'Qg6+ Kh8',
    'Qe8+ Kg7',
    'Qg6+ Kf8',
    'Qf6+ Ke8',
    'Qe6+ Kd8',
    'Qd5+ Rd7',
    'Qa5+ Ke8',
    'Qe5+ Kf7',
    'Qf5+ Kg8',
    'Qe6+ Rf7',
    'Qg6+ Kh8',
    'Qxf7 Qd2+',
    'Kh3 Qd3+',
    'Kg4 Qd1+',
    'Kh3 Qh1+',
    'Kg3 Qg1+',
    'Kh3 Qg8',
    'Qf6+ Qg7',
    'Qd8+ Kh7',
    'Qd3+ Kg8',
    'Qd8+ Qf8',
    'Qd5+ Qf7',
    'Qd8+ Kg7',
  ],
  [
    'Nf3 Nf6',
    'c4 c6',
    'd4 d5',
    'e3 a6',
    'Bd3 dxc4',
    'Bxc4 e6',
    'O-O c5',
    'dxc5 Bxc5',
    'Qxd8+ Kxd8',
    'b3 Ke7',
    'Bb2 Nbd7',
    'Nbd2 b6',
    'a3 Bb7',
    'Be2 Rac8',
    'b4 Bd6',
    'Nc4 Bc7',
    'a4 Rhd8',
    'b5 a5',
    'Ba3+ Ke8',
    'Rac1 Ne4',
    'Nd4 Ne5',
    'Nxe5 Bxe5',
    'Nc6 Nc3',
    'Bc4 Bxc6',
    'bxc6 Rd2',
    'f4 Bf6',
    'Rfe1 Kd8',
    'Bb5 Kc7',
    'e4 Bd4+',
    'Kh1 Bf2',
    'Rf1 Nxe4',
    'Rc4 Rd4',
    'Rc2 Be3',
    'Bb2 Rd5',
    'Bxg7 Ng3+',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 d5',
    'cxd5 Qxd5',
    'Nf3 Qf5',
    'Qb3 Nc6',
    'e3 O-O',
    'Be2 e5',
    'd5 Bxc3+',
    'bxc3 Na5',
    'Qa4 b6',
    'c4 Bd7',
    'Qd1 c6',
    'Bb2 Rfe8',
    'h3 cxd5',
    'cxd5 Rad8',
    'O-O e4',
    'Ne5 Rxe5',
    'g4 Qg5',
    'h4 Qg6',
    'Bxe5 Bxg4',
    'Bg3 Nc4',
    'Bxg4 Nxg4',
    'Qe2 Nce5',
    'Kg2 h5',
    'Rad1 Rd6',
    'Rg1 Kh7',
    'Kf1 Qf5',
    'Bf4 Rg6',
    'Bxe5 Qxe5',
    'Rg3 f5',
    'Kg2 Qe7',
    'Qd2 f4',
    'exf4 e3',
    'Qe2 Nxf2',
    'Qxh5+ Rh6',
    'Qf5+ g6',
    'Qe6 Qg7',
    'Rf1 Qb2',
    'Qe7+ Kg8',
    'Rxe3 Ne4+',
    'Kh3 Qb5',
    'Rc1 Rh8',
  ],
  [
    'c4 Nf6',
    'Nc3 e6',
    'e4 d5',
    'e5 d4',
    'exf6 dxc3',
    'bxc3 Qxf6',
    'd4 e5',
    'Nf3 exd4',
    'Bg5 Qe6+',
    'Be2 Be7',
    'Bxe7 Qxe7',
    'cxd4 O-O',
    'O-O Rd8',
    'Qb3 Nc6',
    'Qe3 Bg4',
    'h3 Bxf3',
    'Bxf3 Qf6',
    'Rab1 Nxd4',
    'Bxb7 Rab8',
    'Be4 c5',
    'Bd5 g6',
    'Rbe1 Rd7',
    'Qh6 Re7',
    'Rb1 Rbe8',
    'Kh1 Ne2',
    'Rb3 Nd4',
    'Rbb1 Ne2',
    'Rb3 Nd4',
    'Rbb1 Ne2',
  ],
  [
    'c4 e6',
    'd4 d5',
    'Nc3 c6',
    'Nf3 Nf6',
    'Bg5 dxc4',
    'e4 b5',
    'a4 Qb6',
    'Bxf6 gxf6',
    'Be2 Bb7',
    'O-O a6',
    'b3 cxb3',
    'Qxb3 Nd7',
    'd5 Nc5',
    'Qb1 cxd5',
    'exd5 Qa5',
    'axb5 Qxc3',
    'bxa6 Bxd5',
    'Qb5+ Nd7',
    'Rfc1 Qb4',
    'Rc7 Qd6',
    'Rac1 Bh6',
    'R1c6 Bxc6',
    'Rxc6 Qd5',
    'Rc7 Rb8',
    'Qxd5 exd5',
    'Nd4 Bf4',
    'Ra7 Rb1+',
    'Bf1 Bb8',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 b6',
    'Ne2 Ne4',
    'Qc2 Bb7',
    'Nf4 Nxc3',
    'bxc3 Be7',
    'f3 d6',
    'Bd3 g6',
    'O-O Nd7',
    'e4 Bg5',
    'Ne2 Bxc1',
    'Qxc1 Qe7',
    'Qe3 e5',
    'Bc2 Ba6',
    'Qd3 O-O',
    'Ba4 Qe6',
    'Bb5 Bxb5',
    'cxb5 d5',
    'exd5 Qxd5',
    'c4 Qd6',
    'Rad1 Rad8',
    'dxe5 Qxd3',
    'Rxd3 Nxe5',
    'Rc3 Rd2',
    'Nf4 Rd4',
    'Nd5 Kg7',
    'Re1 Nxc4',
    'Nxc7 Nd6',
    'Ra3 Rc8',
    'Rxa7 Kf8',
    'Ra8 Rxa8',
    'Nxa8 Nc4',
    'a4 h5',
    'Rb1 h4',
    'Nc7 Rd2',
    'Re1 Ra2',
    'Re4 Ra1+',
    'Kf2 Ra2+',
    'Ke1 Rxa4',
    'Rxh4 Ra1+',
    'Ke2 Ra2+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 e6',
    'Bb3 b5',
    'Bg5 Be7',
    'Qf3 Qb6',
    'O-O-O Nbd7',
    'Rhe1 h6',
    'e5 Bb7',
    'Qh3 dxe5',
    'Nxe6 fxe6',
    'Bxe6 Rd8',
    'Rxd7 Rxd7',
    'Bxf6 Rd6',
    'Qh5+ Kd8',
    'Bxe7+ Kxe7',
    'Qxe5 Kd8',
    'Nd5 Bxd5',
    'Bxd5 Qc7',
    'f4 Rd7',
    'Qf5 Qc5',
    'Be6 Qf2',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 O-O',
    'Bd3 c5',
    'Ne2 d5',
    'cxd5 cxd4',
    'exd4 Nxd5',
    'O-O Nc6',
    'Bc2 Re8',
    'Qd3 g6',
    'Rd1 Bf8',
    'Qf3 b6',
    'Be4 Nce7',
    'Bg5 Bb7',
    'Nxd5 Bxd5',
    'Nc3 Qd7',
    'Bxe7 Bxe4',
    'Nxe4 Bxe7',
    'd5 exd5',
    'Rxd5 Qe6',
    'h3 Rad8',
    'Nc3 Bf6',
    'Rad1 h5',
    'Rxd8 Bxd8',
    'Nd5 Kg7',
    'a4 Qe5',
    'b3 h4',
    'Qc3 Qxc3',
    'Nxc3 Bf6',
    'Nb5 Re7',
    'Kf1 a6',
    'Nd6 Bc3',
    'Nc4 b5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Qc2 dxc4',
    'e4 b5',
    'b3 cxb3',
    'axb3 g6',
    'Bb2 Bg7',
    'Bd3 O-O',
    'O-O Bb7',
    'Nc3 a5',
    'e5 Nd5',
    'Nxd5 Qxd5',
    'Be4 Qd8',
    'Ne1 Nd7',
    'Nd3 Qb8',
    'h4 Rc8',
    'Rfe1 c5',
    'dxc5 Bxe4',
    'Rxe4 Qb7',
    'Rf4 e6',
    'b4 axb4',
    'Rxa8 Qxa8',
    'Rxb4 Qc6',
    'Qb3 Rb8',
    'Bd4 Bf8',
    'Qc3 Rc8',
    'Qb3 Bxc5',
    'Nxc5 Nxc5',
    'Bxc5 Qxc5',
    'Rxb5 Qc1+',
    'Kh2 Qa1',
    'Qb4 Qf1',
    'Rb8 Rxb8',
    'Qxb8+ Kg7',
    'Qb2 Qc4',
    'g3 h6',
    'f3 Qf1',
    'Qg2 Qe1',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Bf4 Bg7',
    'e3 c5',
    'dxc5 Qa5',
    'Rc1 Ne4',
    'cxd5 Nxc3',
    'Qd2 Qxa2',
    'Rxc3 O-O',
    'Bc4 Qa1+',
    'Rc1 Qxb2',
    'e4 a5',
    'Ne2 a4',
    'Rc2 Qa1+',
    'Rc1 Qb2',
    'Rc2 Qa1+',
    'Nc1 Na6',
    'Be3 Qb1',
    'Ba2 Qb4',
    'f3 Qxd2+',
    'Kxd2 Nb4',
    'Rc4 Nxa2',
    'Nxa2 e6',
    'd6 Bb2',
    'Rb1 a3',
    'Rb4 e5',
    'R4xb2 axb2',
    'Rxb2 Bd7',
    'Nb4 Bc6',
    'Nxc6 bxc6',
    'Rb6 Rac8',
    'Bh6 Rfd8',
    'Bg5 Rd7',
    'Bf6 Kf8',
    'Bxe5 Ke8',
    'Ke3 Ra7',
    'Kf4 Kd7',
    'Kg5 Ke6',
    'Bd4 h6+',
    'Kf4 Ra4',
    'Ke3 Ra3+',
    'Ke2 Ra2+',
    'Kd3 Ra3+',
    'Bc3 Ra7',
    'g4 f5',
    'gxf5+ gxf5',
    'Kd4 Ra4+',
    'Bb4 fxe4',
    'Kxe4 Rf8',
    'f4 h5',
    'h4 Re8',
    'Kd4 Rf8',
    'Ke4 Re8',
    'Kd4 Rg8',
    'Kc4 Rc8',
    'Rb7 Ra2',
    'Re7+ Kf5',
    'Kb3 Ra1',
    'Kb2 Ra4',
    'Kb3 Ra1',
    'Kb2 Ra4',
    'Kb3 Ra1',
  ],
  [
    'c4 Nf6',
    'Nc3 c6',
    'e4 e5',
    'Nf3 Qa5',
    'd3 d5',
    'cxd5 cxd5',
    'Nxe5 Bd6',
    'Bf4 O-O',
    'Nc4 dxc4',
    'Bxd6 Re8',
    'f3 cxd3',
    'Bxd3 Qb6',
    'Bb5 Rd8',
    'e5 a6',
    'Be2 Ne8',
    'Nd5 Qa5+',
    'b4 Qa3',
    'Bxb8 Kh8',
    'Bd6 Nxd6',
    'exd6 Rxd6',
    'Qd2 Be6',
    'Qe3 Qa4',
    'Nc3 Qa3',
    'O-O Rc8',
    'Nd5 Qxe3+',
    'Nxe3 Rd2',
    'Rfe1 Bxa2',
    'Bc4 Bxc4',
    'Nxc4 Rd4',
    'Nb6 Rcd8',
    'Rab1 R4d6',
    'Nc4 Rd4',
    'Na5 R4d7',
    'Re2 Kg8',
    'Kf2 f6',
    'b5 Kf7',
    'bxa6 bxa6',
    'Rb6 Ra8',
    'Ra2 Rd5',
    'Nc4 Rd7',
    'Rbxa6 Rxa6',
    'Rxa6 Ke7',
    'g4 Kf7',
    'Rb6 Ke7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Bf4 Bg7',
    'e3 c5',
    'dxc5 Qa5',
    'Rc1 dxc4',
    'Bxc4 O-O',
    'Ne2 Qxc5',
    'Qb3 Qh5',
    'Nb5 Na6',
    'Ng3 Qh4',
    'Nd4 Nd7',
    'Bxa6 bxa6',
    'Nge2 e5',
    'Bg3 Qf6',
    'Nc6 Re8',
    'O-O Nb6',
    'Rfd1 Bd7',
    'Qc3 Rac8',
    'Bxe5 Rxe5',
    'Qxe5 Qxe5',
    'Nxe5 Rxc1',
    'Rxc1 Bxe5',
    'b3 Kf8',
    'f3 Ke7',
    'Kf2 Kd6',
    'e4 f6',
    'Ke3 a5',
    'f4 Bb2',
    'Rc2 Ba3',
    'Kd4 a4',
    'e5+ fxe5+',
    'fxe5+ Ke7',
    'Nf4 axb3',
    'axb3 a5',
    'g3 a4',
    'bxa4 Bxa4',
    'Rc7+ Bd7',
    'Rb7 g5',
    'Rxb6 gxf4',
    'gxf4 Bf5',
    'Kd5 Bc1',
    'Rb7+ Ke8',
    'e6 h5',
    'Ke5 Bg4',
  ],
  [
    'e4 d6',
    'd4 g6',
    'Nc3 Bg7',
    'Bg5 a6',
    'Qd2 b5',
    'f3 Bb7',
    'h4 h5',
    'O-O-O Nd7',
    'Nge2 Ngf6',
    'Qe3 b4',
    'Nb1 c5',
    'dxc5 Nxc5',
    'Nd2 Qc7',
    'Nf4 Rc8',
    'Bc4 Nfd7',
    'Nd3 Nb6',
    'Nxc5 Nxc4',
    'Nxc4 Qxc5',
    'Qxc5 Rxc5',
    'b3 Bh6',
    'Bxh6 Rxh6',
    'Rd4 Rh8',
    'Ne3 a5',
    'a3 bxa3',
    'Kd2 f5',
    'exf5 gxf5',
    'Re1 Kd7',
    'Ra4 Rg8',
    'Re2 Rf8',
    'Rxa3 f4',
    'Nd1 e5',
    'c4 Bc6',
    'Nf2 a4',
    'Nd3 Ra5',
    'b4 Raa8',
    'b5 Bb7',
    'c5 Bd5',
    'cxd6 e4',
    'fxe4 Bb3',
    'Nc5+ Kxd6',
    'Nxb3 axb3',
    'Rxb3 Kc5',
    'Ke1 Rab8',
    'Rc2+ Kd6',
    'Kf2 Rg8',
    'b6 Rb7',
    'Rb5 Rbg7',
  ],
  [
    'Nf3 Nf6',
    'c4 c6',
    'd4 d5',
    'e3 a6',
    'Bd3 Bg4',
    'Qb3 Ra7',
    'Ne5 Bh5',
    'O-O e6',
    'Nc3 Nbd7',
    'cxd5 Nxe5',
    'dxe5 Nd7',
    'e4 Nc5',
    'Qc2 cxd5',
    'exd5 exd5',
    'Ne2 Nxd3',
    'Qxd3 Bg6',
    'Qd4 b6',
    'Nf4 Be4',
    'e6 f6',
    'Be3 Bc5',
    'Qa4+ b5',
    'Qb3 d4',
    'Rac1 dxe3',
    'Rxc5 exf2+',
    'Rxf2 Re7',
    'Qe3 f5',
    'Rd2 Qb8',
    'Rd7 Rxd7',
    'exd7+ Kxd7',
    'Qd4+ Ke7',
    'Qxg7+ Kd6',
    'Qd4+ Ke7',
    'Re5+ Kf7',
    'Qd7+ Kf6',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 Nh5',
    'Re1 a5',
    'bxa5 f5',
    'Nd2 Nf4',
    'Bf1 fxe4',
    'Nb3 Nd3',
    'Bxd3 exd3',
    'Ne4 c5',
    'Qxd3 h6',
    'Bd2 Nf5',
    'Rab1 g5',
    'Nc1 Ra6',
    'f3 Nh4',
    'Ne2 Rf7',
    'N2g3 Bf8',
    'Rb5 Be7',
    'Reb1 Qf8',
    'Rb6 Ra8',
    'R1b3 Qd8',
    'Qb1 Bf8',
    'Be1 Ra7',
    'Ra3 Ra8',
    'Nf1 Nf5',
    'Ne3 Nd4',
    'Nxd6 Bxd6',
    'Qg6+ Rg7',
    'Qxd6 Qe8',
    'Qxh6 e4',
    'fxe4 Qxe4',
    'Qh5 Bf5',
    'h3 Rf8',
    'Bg3 g4',
    'Rh6 Bh7',
    'Nxg4 Nf5',
    'Rf3 Rxg4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f3 e5',
    'Nb3 Be6',
    'Be3 h5',
    'Qd2 Nbd7',
    'a4 Rc8',
    'Be2 Be7',
    'O-O Nb6',
    'a5 Nc4',
    'Bxc4 Bxc4',
    'Rfd1 Rc6',
    'Nc1 d5',
    'exd5 Rd6',
    'Nd3 Nxd5',
    'Ne4 Re6',
    'Ndc5 Rc6',
    'Bf2 f5',
    'Ra4 fxe4',
    'Rxc4 e3',
    'Qxd5 exf2+',
    'Kxf2 Rd6',
    'Qe4 Rd2+',
    'Rxd2 Qxd2+',
    'Qe2 Qxa5',
    'Qxe5 Qd2+',
    'Qe2 Qxe2+',
    'Kxe2 Rh6',
    'Nd3 Bd6',
    'f4 Kd7',
    'Kf3 Rf6',
    'Nc5+ Bxc5',
    'Rxc5 g6',
    'Ke4 Rb6',
    'b3 Re6+',
    'Re5 Rc6',
    'c4 Rb6',
    'Rd5+ Ke7',
    'Rd3 Re6+',
    'Kf3 Rc6',
    'g4 hxg4+',
    'Kxg4 Kf6',
    'h4 a5',
    'Rd5 a4',
    'bxa4 Rxc4',
    'a5 Rc7',
    'Rb5 Rd7',
    'h5 gxh5+',
    'Rxh5 Rg7+',
    'Rg5 Rc7',
    'Rf5+ Ke6',
    'Rb5 Rg7+',
    'Rg5 Rc7',
    'Rg6+ Kf7',
    'Rb6 Kg7',
    'f5 Kf8',
    'Rb5 Kg8',
    'Kg5 Kg7',
    'Rb6 Rd7',
    'Rg6+ Kf8',
    'Rf6+ Kg8',
    'Re6 Kf7',
    'Re5 Rd1',
    'Rb5 Rd7',
    'Kf4 Re7',
    'Re5 Rc7',
    'Ke4 Rc4+',
    'Kd5 Rb4',
    'Kc5 Rb1',
    'Re4 Ra1',
    'Kb5 Kf6',
    'Ra4 Rd1',
    'Rb4 Kxf5',
    'Kb6 Rd7',
    'Rc4 Ke5',
    'Rc7 Rd1',
    'Rc5+ Kd6',
    'Rb5 Rh1',
    'Kxb7 Rh7+',
    'Kb6 Rh8',
    'Rg5 Rb8+',
    'Ka7 Rb1',
    'a6 Kd7',
    'Rg7+ Kc8',
    'Rb7 Rc1',
  ],
  [
    'd4 Nf6',
    'c4 b6',
    'Nf3 Bb7',
    'g3 Bxf3',
    'exf3 e6',
    'Bg2 c6',
    'O-O d5',
    'Qe2 Be7',
    'b3 O-O',
    'Rd1 a5',
    'Nc3 Na6',
    'Be3 Nb4',
    'Rac1 a4',
    'a3 Na6',
    'b4 dxc4',
    'Qxc4 Nb8',
    'b5 Bxa3',
    'Ra1 cxb5',
    'Qxb5 Ra5',
    'Qb1 Be7',
    'f4 a3',
    'd5 Qc8',
    'd6 Bd8',
    'Nb5 Nd5',
    'Bxd5 exd5',
    'Rxa3 Rxa3',
    'Nxa3 Qc6',
    'Nb5 Nd7',
    'Rc1 Qb7',
    'Bd4 Nf6',
    'Qf5 g6',
    'Qc8 Qd7',
    'Bxf6 Qxc8',
    'Rxc8 Bxf6',
    'd7 Rd8',
    'Nd6 Kg7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 Na6',
    'O-O e5',
    'Be3 Ng4',
    'Bg5 Qe8',
    'h3 h6',
    'Bh4 exd4',
    'Nxd4 Nf6',
    'Bf3 Nh7',
    'Re1 Ng5',
    'Bxg5 hxg5',
    'Qd2 c6',
    'Rad1 Qe7',
    'Bg4 Bxg4',
    'hxg4 Be5',
    'Nf3 Bf4',
    'Qc2 Nc5',
    'Ne2 Be5',
    'b4 Ne6',
    'c5 a5',
    'a3 axb4',
    'axb4 Qc7',
    'Ned4 Nxd4',
    'Nxd4 Rfd8',
    'Nf3 Qe7',
    'g3 Qe6',
    'Kg2 Ra2',
    'Qc1 Bf4',
    'Nxg5 Bxc1',
    'Nxe6 fxe6',
    'Rxc1 Rf8',
    'Rf1 d5',
    'exd5 exd5',
    'Rcd1 Rb2',
    'Rd4 g5',
    'Kg1 Rf3',
    'Kg2 Rf7',
    'Kg1 Rf3',
    'Kg2 Rc3',
    'Rdd1 Rcc2',
    'Rde1 Rxb4',
    'Re5 Rxg4',
    'Rb1 Kf7',
    'Rxb7+ Kf6',
    'Rbe7 Re4',
    'R7e6+ Kf7',
    'Re7+ Kf6',
    'R7e6+ Kf7',
    'Rxe4 dxe4',
    'Rxe4 Rxc5',
    'Kf3 Rf5+',
    'Ke3 Kf6',
    'f4 gxf4+',
  ],
  [
    'c4 Nf6',
    'Nc3 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'd4 d6',
    'Nf3 Nc6',
    'O-O a6',
    'b3 Rb8',
    'Nd5 Nh5',
    'Bb2 e6',
    'Nf4 Nxf4',
    'gxf4 b5',
    'Bc3 Bb7',
    'Rc1 Ne7',
    'e3 Be4',
    'Qe2 bxc4',
    'Qxc4 Bc6',
    'Ba5 Bb5',
    'Qxc7 Bxf1',
    'Rxf1 Nc6',
    'Qxd8 Nxd8',
    'Bc7 Rc8',
    'Bxd6 Re8',
    'Bc5 Nc6',
    'Nd2 Bf8',
    'Ne4 Be7',
    'Nd6 Bxd6',
    'Bxd6 Nb8',
    'b4 Rc2',
    'a4 Rd8',
    'Be7 Re8',
    'Bc5 Rc8',
    'Ra1 Kg7',
    'Be4 Rd2',
    'b5 axb5',
    'a5 b4',
    'Bxb4 Rb2',
    'Bd6 Na6',
    'd5 Rd2',
    'Be5+ f6',
    'Bd4 exd5',
    'Bxd5 Rdc2',
    'Ra3 Rd8',
    'Bb7 Nc5',
    'Bf3 Na6',
    'Bb7 Rd6',
    'Kg2 Rc7',
    'Bf3 Nb4',
    'Bb6 Rc8',
    'Bb7 Nc2',
    'Ra4 Rb8',
    'Bc7 Nxe3+',
    'Kf3 Rxb7',
    'Bxd6 Nf5',
    'Bc5 Rc7',
    'Be3 Nxe3',
    'fxe3 Kf7',
    'a6 Ra7',
    'f5 gxf5',
    'Kf4 Ke6',
    'h3 Ke7',
    'Kxf5 Kf7',
    'Ra5 Ke7',
    'Ra1 Kf7',
    'Ra4 Ke7',
    'h4 Kf7',
    'h5 Ke7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'a3 Bxc3+',
    'bxc3 Qc7',
    'Qg4 f5',
    'Qg3 Ne7',
    'Qxg7 Rg8',
    'Qxh7 cxd4',
    'Kd1 Bd7',
    'Qh5+ Kd8',
    'Nf3 Qxc3',
    'Ra2 Nbc6',
    'Rb2 Kc7',
    'Rb5 a6',
    'Bb2 axb5',
    'Bxc3 dxc3',
    'Bxb5 Rxa3',
    'Ke2 Rxg2',
    'Rb1 Ra2',
    'Kf1 Rg4',
    'Bd3 Rb2',
    'Rc1 Nb4',
    'Qh6 Nxd3',
    'cxd3 d4',
    'h3 Ng8',
    'Qf8 Rf4',
    'Qd6+ Kd8',
    'Kg2 Ne7',
    'Ra1 Nc8',
    'Qf8+ Kc7',
    'Kg3 Rxf3+',
    'Kxf3 Rd2',
    'Ra8 Rxd3+',
    'Kf4 Rxh3',
    'Rxc8+ Bxc8',
  ],
  [
    'g3 Nf6',
    'Bg2 d5',
    'c4 c6',
    'Qb3 e6',
    'Nf3 Nbd7',
    'd4 Bd6',
    'Bf4 Bxf4',
    'gxf4 O-O',
    'Nbd2 b6',
    'Ne5 Bb7',
    'Rc1 c5',
    'cxd5 Bxd5',
    'Bxd5 Nxd5',
    'Nxd7 Qxd7',
    'dxc5 Nxf4',
    'c6 Qc7',
    'e3 Ng6',
    'Nf3 Rfd8',
    'Qc3 Rd5',
    'b4 Rad8',
    'Ke2 R8d6',
    'a4 a6',
    'Rhg1 e5',
    'h4 Rf6',
    'Qc4 Ne7',
    'b5 axb5',
    'axb5 e4',
    'Qxe4 Rdf5',
    'Qxe7 Qxe7',
    'c7 Rxf3',
    'c8=Q+ Qf8',
    'Rg2 g6',
    'h5 Qxc8',
    'Rxc8+ Kg7',
    'hxg6 hxg6',
    'Rc2 R3f5',
    'Rb2 Rc5',
    'Rg4 Rff5',
    'Rgb4 Rh5',
    'Kd3 Rhd5+',
    'Ke2 Kf8',
    'Kf3 Ke7',
    'Ke2 Ke6',
    'Re4+ Re5',
    'Reb4 g5',
    'Kf3 Kf5',
    'e4+ Ke6',
    'Kg4 Kf6',
    'Rd2 Ke7',
    'Rdb2 Ke6',
    'f3 f5+',
    'exf5+ Rxf5',
    'Rh2 Rxb5',
    'Rh6+ Kf7',
  ],
  [
    'd4 e6',
    'e4 d5',
    'e5 c5',
    'c3 Qb6',
    'Nf3 Bd7',
    'Be2 cxd4',
    'cxd4 Bb5',
    'Nc3 Bxe2',
    'Nxe2 Ne7',
    'O-O Nd7',
    'Nf4 h5',
    'Qd3 g6',
    'Bd2 Nf5',
    'Rfc1 Be7',
    'Rc2 Rd8',
    'Ne2 Nb8',
    'Ng3 Qa6',
    'Qb3 Nxg3',
    'fxg3 Nc6',
    'Rf1 Rd7',
    'Be3 Qb6',
    'Qd3 Nb4',
    'Rc8+ Rd8',
    'Rxd8+ Bxd8',
    'Qd2 Nc6',
    'h3 Rh7',
    'b3 Qa5',
    'Qf2 Qc3',
    'Rd1 Rh8',
    'Ne1 Be7',
    'Rc1 Qa5',
    'Nd3 Qb5',
    'Ne1 Rf8',
    'Nf3 Qd3',
    'Bh6 Rh8',
    'Bg5 Bxg5',
    'Nxg5 Rf8',
    'Kh2 Qf5',
    'Qd2 Rh8',
    'Kg1 f6',
    'exf6 Qxf6',
    'Nxe6 Kd7',
    'Nc5+ Kc8',
    'b4 b5',
    'a4 Kd8',
    'Kh2 Re8',
    'axb5 Ne7',
    'Ra1 Nf5',
    'Qf4 Qb6',
    'Ra6 Qxb5',
    'Rxa7 Re7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'Qf3 Nbd7',
    'Be2 Qc7',
    'O-O-O b5',
    'a3 Bb7',
    'Bg5 Rc8',
    'Bd3 Be7',
    'Qg3 Qd8',
    'Bd2 Ne5',
    'Kb1 O-O',
    'h4 Nfd7',
    'Bg5 Rxc3',
    'bxc3 Nb6',
    'Bc1 Na4',
    'Ne2 Qc7',
    'f4 Nd7',
    'Qe3 Bf6',
    'Bd2 Rc8',
    'g4 d5',
    'e5 Be7',
    'Bc1 d4',
    'cxd4 Bxh1',
    'Rxh1 b4',
    'Qe4 g6',
    'Ka2 bxa3',
    'f5 Rb8',
    'c4 Ndc5',
    'dxc5 Nxc5',
    'Qf3 Qxe5',
    'Bxa3 Nxd3',
    'Qxd3 Bxa3',
    'Nc3 Qa5',
    'Qc2 Rb2+',
    'Qxb2 Bxb2+',
    'Kxb2 Qb4+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Qb6',
    'Be2 cxd4',
    'cxd4 Nge7',
    'Na3 Nf5',
    'Nc2 Be7',
    'O-O O-O',
    'Bd3 Kh8',
    'g4 Nfxd4',
    'Nfxd4 Nxd4',
    'Be3 Bc5',
    'b4 Bxb4',
    'Nxd4 Bc5',
    'Rb1 Qc7',
    'Qc2 b6',
    'Nb3 Bb7',
    'Nxc5 bxc5',
    'Qxc5 Qd7',
  ],
  [
    'e4 c5',
    'Ne2 d6',
    'c4 g6',
    'd4 Bg7',
    'Nbc3 Nf6',
    'dxc5 dxc5',
    'Qxd8+ Kxd8',
    'Be3 Nbd7',
    'O-O-O b6',
    'f3 Ne8',
    'g3 e6',
    'Bh3 Ke7',
    'Rhf1 Bb7',
    'f4 Nd6',
    'f5 Nxc4',
    'fxe6 Nde5',
    'Rd7+ Nxd7',
    'Rxf7+ Kd6',
    'Rxd7+ Kc6',
    'Rxg7 Nxe3',
    'Nd5 Ba6',
    'Nec3 Nxd5',
    'exd5+ Kd6',
    'Rd7+ Ke5',
    'Kd2 b5',
    'Ke3 b4',
    'Ne4 Bb5',
    'Rc7 Bc4',
    'Ng5 Rhf8',
    'Rxc5 Rfc8',
    'Nf7+ Kf6',
    'Rxc8 Rxc8',
    'Nd6 Rd8',
    'Nxc4 Rxd5',
    'Ke4 Rh5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 Nh5',
    'Re1 f5',
    'Ng5 Nf6',
    'f3 Nh5',
    'c5 Kh8',
    'Bc4 Nf4',
    'Ne6 Bxe6',
    'dxe6 fxe4',
    'fxe4 Nc6',
    'Be3 Nd4',
    'g3 Nfxe6',
    'Bxe6 Nxe6',
    'cxd6 cxd6',
    'Nb5 a6',
    'Nxd6 Nd4',
    'Bxd4 exd4',
    'Nxb7 Qb6',
    'Nc5 d3',
    'e5 Qxb4',
    'Nxd3 Qd4+',
    'Kh1 Qd5+',
    'Kg1 Qd4+',
    'Kh1 Qd5+',
    'Kg1 Rad8',
    'Nf2 Qa5',
    'Qg4 Bxe5',
    'Rad1 Qc5',
    'Rf1 Rxd1',
    'Qxd1 Bd4',
    'Qd2 Be3',
    'Qe2 h5',
    'h4 Kh7',
    'Kg2 Qd5+',
    'Kh2 Bc5',
    'Rd1 Rxf2+',
    'Qxf2 Qxd1',
    'Qxc5 Qe2+',
    'Kg1 Qxa2',
    'Qe7+ Kg8',
    'Qe8+ Kg7',
    'Qe5+ Kf7',
    'Qf4+ Kg8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 b5',
    'Nd5 Nxd5',
    'exd5 g6',
    'a4 b4',
    'Qd2 Bb7',
    'Bc4 Nd7',
    'Qxb4 Rb8',
    'Nc6 Bxc6',
    'Qc3 Bxd5',
    'Bxd5 Rg8',
    'O-O Bg7',
    'Bd4 Bxd4',
    'Qxd4 Qb6',
    'Qf4 e6',
    'Rfe1 Ke7',
    'Bb3 g5',
    'Qg3 Rg6',
    'Re3 h6',
    'Rd1 Ne5',
    'Re4 Qc5',
    'Red4 Rd8',
    'h3 f5',
    'Qe3 Nc6',
    'Rc4 Qxe3',
    'fxe3 Kd7',
    'a5 Ne5',
    'Rb4 Kc7',
    'Rb6 f4',
    'Rxa6 fxe3',
    'Re1 g4',
    'h4 Nf3+',
    'gxf3 gxf3+',
    'Kf1 e2+',
    'Rxe2 fxe2+',
    'Kxe2 Kb7',
    'Rb6+ Ka7',
    'Bc4 Rg4',
    'Bd3 e5',
    'b4 d5',
    'Kd2 e4',
    'Be2 Rg2',
    'Re6 Rf8',
    'b5 Rff2',
    'b6+ Kb8',
    'a6 Rxe2+',
    'Kd1 Rd2+',
    'Ke1 Rge2+',
    'Kf1 Rf2+',
    'Ke1 Rfe2+',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 c5',
    'Bd3 Nc6',
    'Nf3 Bxc3+',
    'bxc3 d6',
    'O-O O-O',
    'd5 Ne7',
    'e4 e5',
    'Ne1 Ne8',
    'g4 Ng6',
    'Ng2 h6',
    'Qf3 Nh4',
    'Qg3 Nxg2',
    'Kxg2 g5',
    'Rh1 Ng7',
    'h4 f6',
    'hxg5 hxg5',
    'Qf3 Kf7',
    'Bxg5 Bxg4',
    'Qxg4 fxg5',
    'Rh6 Qe7',
    'Rah1 Ke8',
    'f3 Kd8',
    'Rg6 Ne8',
    'Qxg5 Qxg5+',
    'Rxg5 Rc8',
    'Kf2 Rc7',
    'Bf1 Rcf7',
    'Rg3 Nf6',
    'Ke3 Nd7',
    'Bg2 Nb6',
    'Kd3 Na4',
    'Kc2 Nb6',
    'Kd3 Na4',
    'Rb1 Kc7',
    'Rb3 a6',
    'Ke3 Nb6',
    'Bf1 Rh8',
    'Rb2 Na4',
    'Rc2 Rh1',
    'Kf2 Rh2+',
    'Rg2 Rh3',
    'Be2 Rh1',
    'Ke3 Rb1',
    'Rf2 Re1',
    'Rf1 Rxf1',
    'Bxf1 Rh7',
    'Kd3 Rh1',
    'Rf2 Rg1',
    'Kc2 Nb6',
    'f4 exf4',
    'Rxf4 Rg7',
    'e5 dxe5',
    'Rf6 Nc8',
    'Re6 Rg5',
    'Bh3 Rh5',
    'Bg4 Rg5',
    'Bh3 Rh5',
    'Bg4 Rg5',
    'Be2 Nd6',
    'Re7+ Kd8',
    'Rh7 Rg2',
    'Kd1 Rg1+',
    'Kc2 Rg2',
    'Kd1 Rg1+',
  ],
  [
    'd4 Nc6',
    'Nf3 Nf6',
    'c4 d6',
    'Nc3 g6',
    'e4 Bg4',
    'd5 Ne5',
    'Be2 Bxf3',
    'gxf3 c6',
    'Be3 Bg7',
    'O-O O-O',
    'Rc1 Qa5',
    'a3 Rfc8',
    'b4 Qd8',
    'c5 cxd5',
    'exd5 Qd7',
    'f4 Neg4',
    'c6 bxc6',
    'dxc6 Qf5',
    'Nd5 Qxd5',
    'Bxg4 Qxd1',
    'Bxd1 Nd5',
    'Bd2 Bb2',
    'Rc2 Bxa3',
    'Bf3 a5',
    'Bxd5 axb4',
    'Be3 e6',
    'Bb3 Ra6',
    'c7 Kf8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Nc3 a6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Bc4 b5',
    'Bb3 Bb7',
    'f3 e6',
    'Be3 Nbd7',
    'O-O Nc5',
    'Qe1 Be7',
    'Rd1 Nfd7',
    'a4 bxa4',
    'Nxa4 Qc7',
    'Nxc5 Nxc5',
    'Ba2 O-O',
    'Ne2 Bf6',
    'c3 a5',
    'Bd4 Be5',
    'Nc1 Rfd8',
    'Rf2 Bxd4',
    'Rxd4 Qb6',
    'Rd1 Na4',
    'Rd2 Bc6',
    'Qd1 Rab8',
    'Bb3 Nc5',
    'Bc2 Bxe4',
    'fxe4 Nxe4',
    'Bb1 Nxf2',
    'Rxf2 f5',
    'Ba2 d5',
    'Nd3 Qd6',
    'Qe2 Rb6',
    'Qe3 d4',
    'cxd4 Qxd4',
    'Bxe6+ Kh8',
    'Qxd4 Rxd4',
    'Rxf5 g6',
    'Rd5 Rxd3',
    'Rxd3 Rxe6',
    'Rd5 Ra6',
    'Rd7 Kg8',
    'Rb7 h6',
    'h4 Kf8',
    'Kh2 a4',
    'Kh3 Ke8',
    'Kg4 Kd8',
    'g3 Kc8',
    'Rh7 a3',
    'bxa3 h5+',
    'Kg5 Rxa3',
    'Kxg6 Rxg3+',
    'Kxh5 Kd8',
    'Rf7 Ke8',
    'Rf5 Rg8',
  ],
  [
    'd4 Nf6',
    'c4 c5',
    'd5 d6',
    'Nc3 g6',
    'e4 Bg7',
    'f3 O-O',
    'Bg5 h6',
    'Be3 e6',
    'Qd2 exd5',
    'cxd5 Re8',
    'Nge2 h5',
    'Nc1 Na6',
    'Be2 Nc7',
    'O-O a6',
    'a4 Rb8',
    'a5 b5',
    'axb6 Rxb6',
    'Kh1 Bd7',
    'Nd3 Bb5',
    'Nxb5 Rxb5',
    'Ra4 Ncxd5',
    'exd5 Qe7',
    'Nxc5 Qxe3',
    'Qxe3 Rxe3',
    'Bxb5 dxc5',
    'Bc4 Nd7',
    'b3 Nb8',
    'Rb1 Bc3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Be7',
    'Qf3 Qc7',
    'O-O-O Nbd7',
    'g4 b5',
    'Bxf6 Nxf6',
    'g5 Nd7',
    'f5 Bxg5+',
    'Kb1 Ne5',
    'Qh5 Qe7',
    'Rg1 Bf6',
    'fxe6 g6',
    'exf7+ Nxf7',
    'Qd5 Bb7',
    'Qb3 Bxd4',
    'Rxd4 O-O',
    'Bh3 Kg7',
    'Rgd1 Ng5',
    'Bg2 Rad8',
    'Qb4 Rf2',
    'R4d2 Rxd2',
    'Rxd2 Qe5',
    'a4 Bc6',
    'axb5 axb5',
    'Qd4 Qxd4',
    'Rxd4 Ne6',
    'Rb4 Rf8',
    'Nxb5 Rf2',
    'Na7 Be8',
    'Bh3 Nc5',
    'Nc8 Rxh2',
    'Nxd6 Bf7',
    'Nxf7 Kxf7',
    'Bc8 Re2',
    'Rc4 Nxe4',
    'Rc7+ Kg8',
    'b4 Nd6',
    'Ba6 Re4',
    'c3 g5',
    'Bd3 Re1+',
    'Kc2 g4',
    'Rxh7 Nf7',
    'Rh2 Ne5',
    'Rg2 Ra1',
    'Be2 Ra7',
    'Kb3 Rg7',
    'Rg3 Kf7',
    'b5 Ke6',
    'c4 Kf5',
    'c5 Kf4',
    'Rg1 g3',
    'Kb4 Ke3',
    'Rg2 Kd4',
    'c6 Kd5',
    'Ka5 Nxc6+',
    'bxc6 Kxc6',
    'Bf3+ Kc5',
    'Ka4 Kc4',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 a6',
    'a4 e6',
    'Bg5 Nbd7',
    'cxd5 exd5',
    'e3 a5',
    'Bd3 Be7',
    'O-O O-O',
    'Qc2 Re8',
    'Ne5 Nf8',
    'f4 N6d7',
    'Bxe7 Qxe7',
    'Kh1 f6',
    'Nf3 Qb4',
    'Rfe1 b6',
    'e4 dxe4',
    'Nxe4 Bb7',
    'Qc4+ Kh8',
    'Qxb4 axb4',
    'Nd6 Rxe1+',
    'Rxe1 Ba6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Be7',
    'Qf3 Qc7',
    'O-O-O Nbd7',
    'g4 b5',
    'Bxf6 Nxf6',
    'g5 Nd7',
    'f5 Bxg5+',
    'Kb1 Ne5',
    'Qh5 Qe7',
    'Nxe6 g6',
    'Qxg5 fxe6',
    'f6 Qd8',
    'Bh3 O-O',
    'Rhf1 Ra7',
    'Rf2 Raf7',
    'Rdf1 Qb6',
    'Nd1 h6',
    'Qd2 Kh7',
    'Ne3 h5',
    'Ng2 Nd7',
    'Bxe6 Rxf6',
    'Nf4 Ne5',
    'Bxc8 Nc4',
    'Qe1 Rxc8',
    'Nd5 Rxf2',
    'Rxf2 Qd4',
    'c3 Qd3+',
    'Ka1 Ne5',
    'a3 Kg7',
    'Nf4 Qc4',
    'Rg2 Kh7',
    'Qh4 Rf8',
    'Rxg6 Nxg6',
    'Qxh5+ Kg7',
    'Qxg6+ Kh8',
    'Qh6+ Kg8',
    'Qg6+ Kh8',
    'Qh6+ Kg8',
    'Qg5+ Kf7',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 a5',
    'Ba3 axb4',
    'Bxb4 Nd7',
    'Bd3 h6',
    'a4 f5',
    'Nd2 f4',
    'Nb3 f3',
    'g3 Nf6',
    'Nd2 Qd7',
    'Nxf3 Qh3',
    'Re1 Ng4',
    'Bf1 Qh5',
    'h3 c5',
    'Ba3 g5',
    'Nh2 Rxf2',
    'Bg2 Ne3',
    'Rxe3 Rxg2+',
    'Kxg2 Qxh3+',
    'Kg1 g4',
    'Bxc5 dxc5',
    'd6 Ng6',
    'd7 Bxd7',
    'Qxd7 Nh4',
    'Qd2 Rf8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bb5+ Nd7',
    'c3 Nf6',
    'Qe2 a6',
    'Ba4 g6',
    'O-O Bg7',
    'd4 b5',
    'Bc2 O-O',
    'e5 Ne8',
    'Bg5 dxe5',
    'dxe5 Nc7',
    'Qe4 Rb8',
    'Qh4 Re8',
    'Nbd2 Ne6',
    'Rfe1 Nxg5',
    'Nxg5 Nf8',
    'Ndf3 h6',
    'e6 Nxe6',
    'Nxe6 Bxe6',
    'Bxg6 Bd5',
    'Be4 e6',
    'Qg3 b4',
    'c4 Bxc4',
    'Ne5 Bb5',
    'Nxf7 Kxf7',
    'Qg6+ Kf8',
    'Re3 Bd4',
    'Rf3+ Ke7',
    'Rf7+ Kd6',
    'Qxh6 Re7',
    'Qf4+ Kd7',
    'Rxe7+ Kxe7',
    'Re1 Qd6',
    'Qh4+ Kd7',
    'Qh7+ Qe7',
    'Qh5 Rh8',
    'Qg4 Qh4',
    'Bc6+ Bxc6',
  ],
  ['d4 Nf6', 'c4 e6', 'Nf3 b6', 'a3 Bb7', 'Nc3 d5', 'cxd5 exd5', 'Bg5 Be7', 'e3 O-O', 'Bd3 Nbd7', 'O-O c5', 'Rc1 h6', 'Bf4 Ne4', 'Qc2 Nxc3'],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 a6',
    'e3 b5',
    'b3 Bg4',
    'h3 Bxf3',
    'gxf3 e6',
    'Bd2 Nbd7',
    'a4 dxc4',
    'axb5 axb5',
    'bxc4 b4',
    'Rxa8 Qxa8',
    'Qa4 Qb7',
    'Ne4 Nxe4',
    'fxe4 c5',
    'Rg1 Be7',
    'Rxg7 Bf6',
    'Rg3 b3',
    'Bd3 b2',
    'Qb5 Qxb5',
    'cxb5 cxd4',
    'exd4 Bxd4',
    'Bb1 Ke7',
    'Bc3 Bxc3+',
    'Rxc3 Rg8',
    'Kf1 Nb6',
    'Rc2 Na4',
    'Rc4 Ra8',
    'e5 Nb6',
    'Re4 Ra1',
    'Re1 h6',
    'Ke2 Nc4',
    'Kd3 Na3',
    'Kc3 Rxb1',
    'Rxb1 Nxb1+',
    'Kxb2 Nd2',
    'f4 Nc4+',
    'Kc3 Nb6',
    'Kd4 Kd7',
    'h4 h5',
    'Ke4 Kc7',
    'f5 Nd5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 a5',
    'Ba3 b6',
    'bxa5 Nh5',
    'Re1 f5',
    'Nd2 Nf6',
    'Bd3 Bh6',
    'Nb3 Kh8',
    'f3 bxa5',
    'Nd2 c5',
    'Nb5 a4',
    'Bb2 f4',
    'Nf1 g5',
    'g4 Ng6',
    'Nd2 Rf7',
    'Kf2 Bf8',
    'h3 h5',
    'Rh1 Rh7',
    'Be2 Be7',
    'Qc2 Kg7',
    'Rhg1 hxg4',
    'hxg4 Rh2+',
    'Ke1 Qh8',
    'Qd3 Qh4+',
    'Kd1 Qf2',
    'Rf1 Qg2',
    'Re1 Nh4',
    'Na3 Bd7',
    'Rb1 Rh8',
    'Bc3 Bc8',
    'Rb8 Qf2',
    'Rf1 Qg3',
    'Kc1 Nxg4',
    'fxg4 Rxe2',
    'Qxe2 Bxg4',
    'Nf3 Rxb8',
    'Be1 Bxf3',
    'Qd3 Be2',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 b6',
    'a3 Ba6',
    'Qc2 Bb7',
    'Nc3 c5',
    'e4 cxd4',
    'Nxd4 Bc5',
    'Nb3 Nc6',
    'Bf4 d6',
    'O-O-O e5',
    'Nb5 exf4',
    'Nxc5 bxc5',
    'Nxd6+ Kf8',
    'e5 Nd4',
    'Rxd4 cxd4',
    'Nxb7 Qe7',
    'exf6 Qxb7',
    'Qd2 Qb6',
    'Qxf4 Rb8',
    'b4 Re8',
    'Bd3 a5',
    'c5 Qe6',
    'Qxd4 gxf6',
    'Bc4 Qf5',
    'Bd3 Qe6',
    'Bc4 Qf5',
    'Bd3 Qe6',
  ],
  [
    'e4 c5',
    'd3 Nc6',
    'g3 g6',
    'f4 Bg7',
    'c3 d5',
    'Bg2 e6',
    'Nf3 Nge7',
    'O-O O-O',
    'e5 b5',
    'Be3 c4',
    'd4 b4',
    'Nbd2 Bd7',
    'g4 Qa5',
    'Qc1 Rfb8',
    'Bf2 Nc8',
    'Bh4 bxc3',
    'bxc3 Nb6',
    'Rb1 Na4',
    'Rxb8+ Rxb8',
    'Nb1 Nb2',
    'Bf6 Nd3',
    'Qe3 Qxa2',
    'Nbd2 Bh6',
    'Ng5 Rb2',
    'Rd1 Qa5',
    'Qh3 Qxc3',
    'Ndf3 Rxg2+',
    'Qxg2 Nxd4',
    'Rb1 Nxf4',
    'Rb8+ Bf8',
    'Nxh7 Nxf3+',
    'Qxf3 Qe1+',
    'Qf1 Qe3+',
    'Kh1 Qe4+',
    'Kg1 Qe3+',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 dxc4',
    'a4 Bf5',
    'Ne5 Na6',
    'e3 e6',
    'Bxc4 Nb4',
    'O-O a6',
    'Bd2 Be7',
    'Qe2 Bg6',
    'Rfd1 c5',
    'dxc5 Qc7',
    'Nf3 Bxc5',
    'h3 O-O',
    'e4 Rfd8',
    'Bg5 Rxd1+',
    'Rxd1 Nd7',
    'Bh4 Nc6',
    'Bg3 Qxg3',
    'Rxd7 Rb8',
    'Kf1 Qf4',
    'g3 Qf6',
    'e5 Qf5',
    'Kg2 Nb4',
    'Nh4 Qh5',
    'Nxg6 Qxe2',
    'Bxe2 hxg6',
    'Ne4 Bf8',
    'Ng5 Rc8',
    'Rd6 Bxd6',
    'exd6 Rd8',
    'Ne4 f5',
    'Nc5 Rxd6',
    'Nxb7 Rd2',
    'Bc4 Kf7',
    'Nc5 Nd5',
    'Bxa6 Rxb2',
    'Bc8 Nc7',
    'a5 Rb5',
    'Nb7 Ke7',
    'h4 Nd5',
    'a6 Rb2',
    'Nc5 Kd6',
    'Nxe6 Ra2',
    'Nxg7 Ke5',
    'Bb7 Nc7',
    'h5 gxh5',
    'Nxh5 Nxa6',
    'Nf4 Nc5',
    'Bd5 Rd2',
    'Bf3 Nd3',
    'Nxd3+ Rxd3',
    'Be2 Rd2',
    'Kf3 Rb2',
    'Ke3 Rb3+',
    'Bd3 Ra3',
    'f4+ Kd5',
    'Ke2 Kd4',
    'Bxf5 Rxg3',
    'Kf2 Ra3',
    'Bh7 Ra7',
    'Bg6 Ra6',
    'f5 Rf6',
    'Kg3 Rxf5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Nf6',
    'O-O Qc7',
    'Qe2 d6',
    'c4 g6',
    'Nc3 Bg7',
    'Nf3 O-O',
    'Rd1 Nbd7',
    'Bf4 Ng4',
    'Rac1 Nge5',
    'Nxe5 Nxe5',
    'Qd2 Rd8',
    'Bg5 f6',
    'Be3 Ng4',
    'Bf4 e5',
    'Nd5 Qf7',
    'Bg3 Bh6',
    'Qa5 Be6',
    'Rc3 f5',
    'exf5 gxf5',
    'Be2 Bxd5',
    'Qxd5 Nf6',
    'Qxf7+ Kxf7',
    'f3 Ke6',
    'Rb3 b6',
    'Bf2 Nd7',
    'c5 d5',
    'c6 Nf6',
    'c7 Rdc8',
    'Rxb6+ Kf7',
    'Rc6 a5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 Na6',
    'O-O e5',
    'Bg5 h6',
    'Bh4 g5',
    'dxe5 Nh5',
    'Bg3 Nxg3',
    'hxg3 dxe5',
    'Qxd8 Rxd8',
    'Rfd1 Be6',
    'Nh2 Nb4',
    'Nd5 Nc2',
    'Rac1 Nd4',
    'Kf1 Rd7',
    'Bg4 Rad8',
    'Bxe6 Nxe6',
    'Ng4 Kh8',
    'f3 h5',
    'Nf2 Kh7',
    'Ne3 Rd4',
    'Ke2 h4',
    'g4 Nf4+',
    'Kf1 c6',
    'Nh3 Bf6',
    'b3 Rd3',
    'Rxd3 Rxd3',
    'Re1 Rd2',
    'Nxf4 exf4',
    'Nf5 Bc3',
    'Re2 Rd1+',
    'Kf2 Rh1',
    'a3 f6',
    'c5 Kh8',
    'b4 Kg8',
    'Rc2 h3',
    'Rxc3 h2',
    'Rd3 Ra1',
    'Rd8+ Kf7',
    'Nd6+ Ke7',
    'Rh8 h1=Q',
    'Rxh1 Rxh1',
    'Nxb7 Ra1',
    'Na5 Kd7',
    'Nc4 Rc1',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 c5',
    'a3 Bxc3+',
    'bxc3 Ne7',
    'Qg4 Qc7',
    'Bd3 g6',
    'Bd2 Nbc6',
    'Nf3 Bd7',
    'O-O c4',
    'Be2 h6',
    'Rfb1 O-O-O',
    'a4 Rdf8',
    'h4 f5',
    'exf6 e5',
    'Qg3 Nf5',
    'Qh2 g5',
    'Nxe5 Nxh4',
    'Nxd7 Qxd7',
    'g3 Nf5',
    'Qh3 Rh7',
    'Bg4 Rxf6',
    'Re1 h5',
    'Re8+ Kc7',
    'Bxf5 Qxf5',
    'Qxf5 Rxf5',
    'Rg8 g4',
    'Bf4+ Kb6',
    'Rc8 Rhf7',
    'Kf1 Re7',
    'Rh8 Ka6',
    'Rh6 Ref7',
    'Re6 R7f6',
    'Ree1 Rf7',
    'Ke2 h4',
    'Rh1 hxg3',
    'fxg3 Re7+',
    'Kd2 Rf6',
    'Rh5 Rfe6',
    'Rxd5 Re2+',
    'Kc1 Rg2',
    'Kb2 Ree2',
    'Rc1 Na5',
    'Rb5 Nb3',
    'Rxb3 cxb3',
    'Kxb3 Kb6',
    'c4 Kc6',
    'Kc3 Rxg3+',
    'Bxg3 Re3+',
    'Kb4 Rxg3',
    'Rh1 Rh3',
    'Rg1 g3',
    'Rg2 Kd6',
    'Kb5 Kc7',
    'c5 Rh4',
    'Kc4 Rg4',
    'Kd5 b6',
    'cxb6+ axb6',
    'c3 Rg5+',
    'Ke4 Ra5',
    'Rxg3 Rxa4',
  ],
  [
    'Nc3 c5',
    'Nf3 e6',
    'e4 a6',
    'g3 Nc6',
    'Bg2 g6',
    'd4 cxd4',
    'Nxd4 Bg7',
    'Be3 Nge7',
    'a4 d6',
    'O-O O-O',
    'Nb3 b6',
    'Qe2 Bb7',
    'Rad1 Qc7',
    'f4 Nb4',
    'Qf2 b5',
    'axb5 axb5',
    'Bb6 Qb8',
    'Nxb5 Ba6',
    'c4 Bxb5',
    'cxb5 Rc8',
    'Rd2 Ra2',
    'Rfd1 Bf8',
    'Nc1 Ra1',
    'Ne2 Rxd1+',
    'Rxd1 Rc2',
    'Ba7 Qxb5',
    'Nd4 Rxf2',
    'Nxb5 Rxb2',
    'Nxd6 Nec6',
    'Bc5 e5',
    'Bf1 exf4',
    'gxf4 Rc2',
    'Be3 g5',
    'Ne8 gxf4',
    'Nf6+ Kh8',
    'Bxf4 Bc5+',
    'Kh1 Rf2',
    'Nh5 Nc2',
    'Bb5 N6d4',
    'Be5+ Kg8',
    'Nf6+ Kf8',
    'Nd7+ Ke7',
    'Nxc5 Nxb5',
    'Rd7+ Ke8',
    'Rb7 f6',
    'Bg3 Rf1+',
    'Kg2 Ne3+',
    'Kh3 Nd4',
    'Rxh7 Kf8',
    'Rd7 Nf3',
    'Bd6+ Kg8',
    'Ne6 Rg1',
    'Bg3 Ra1',
    'Rd3 Ng1+',
    'Kh4 Ng2+',
    'Kg4 Ra4',
    'Kf5 Ra7',
    'Kxf6 Nf3',
    'Rd8+ Kh7',
    'Ng5+ Nxg5',
    'Kxg5 Ne3',
    'Be5 Rg7+',
    'Bxg7 Kxg7',
    'Rd7+ Kf8',
    'h4 Nc4',
    'h5 Ne5',
    'Ra7 Nc6',
    'Rc7 Nd4',
    'Kf6 Kg8',
    'h6 Nb5',
    'Rd7 Nc3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bc4 e6',
    'O-O Nf6',
    'Re1 a6',
    'Bb3 Nc6',
    'c3 d5',
    'exd5 Nxd5',
    'd4 cxd4',
    'Nxd4 Nde7',
    'Be3 Nxd4',
    'Qxd4 Qxd4',
    'Bxd4 b5',
    'Nd2 Nc6',
    'Be3 Bb7',
    'Ne4 Na5',
    'Bc2 Nc4',
    'Bc1 Rc8',
    'Bd3 h6',
    'Bf1 Be7',
    'a3 O-O',
    'Ng3 Nb6',
    'Bd3 Rfd8',
    'Bc2 a5',
    'Nf1 Rd7',
    'Ne3 Rcd8',
    'h3 Bc6',
    'Bd1 e5',
    'Bg4 Rb7',
    'Nf5 Bf6',
    'Ng3 Na4',
    'Nh5 Be7',
    'Rxe5 g6',
    'Ng3 h5',
    'Be2 Bf6',
    'Bg5 Bxe5',
    'Bxd8 f6',
    'Bxa5 Nxb2',
    'Rb1 Na4',
    'c4 h4',
    'Nf1 Bc3',
    'Bb4 bxc4',
    'Bxc4+ Kg7',
    'Ne3 Be4',
    'Rb3 Bd4',
    'Bd5 Bxd5',
    'Nxd5 Rd7',
    'Ne3 Rc7',
    'Rb1 Nb2',
    'Kf1 f5',
    'Bd6 Rc6',
    'Bb4 f4',
    'Ng4 Rc2',
    'Be7 Kf7',
    'Bxh4 Nc4',
    'Rd1 Bc5',
    'Bg5 Bd6',
    'Rd4 Ke6',
    'Bxf4 Bxa3',
    'Ne3 Rc1+',
    'Ke2 Nb6',
    'Rd1 Rc8',
    'h4 Bc5',
    'Rc1 Nd7',
    'g3 Ra8',
    'Rc2 Kf7',
    'Ng4 Re8+',
    'Kf3 Re6',
    'Rd2 Ke8',
    'Rd5 Be7',
    'h5 gxh5',
    'Rxh5 Ra6',
    'Rh8+ Bf8',
    'Bh6 Kf7',
    'Bxf8 Nxf8',
    'Rh5 Ra3+',
    'Kg2 Ra4',
    'Kh3 Ne6',
    'Rf5+ Kg7',
    'f3 Ra2',
    'Ne5 Ra7',
    'g4 Nd4',
    'Rf4 Ne6',
    'Rc4 Ra5',
    'Nc6 Ra3',
    'Kg3 Ng5',
    'Rf4 Ra1',
    'Rf5 Ne6',
    'Ra5 Rg1+',
    'Kf2 Rc1',
    'Ne5 Kf6',
    'Nd3 Rc3',
    'Rf5+ Kg7',
    'Ne5 Rc7',
    'Nd3 Rc3',
    'Ke3 Ra3',
    'g5 Ra4',
    'f4 Ra3',
    'Rd5 Kf7',
    'Ke4 Ra7',
    'Nc5 Nf8',
    'f5 Ke7',
    'Ke5 Ra1',
    'Rd4 Ra5',
    'Rd5 Ra1',
    'Ne4 Re1',
    'Kf4 Rf1+',
    'Ke3 Re1+',
    'Kf3 Rf1+',
    'Ke2 Ra1',
    'Re5+ Kf7',
    'Nd6+ Kg8',
    'Kf3 Nh7',
    'Re8+ Kg7',
    'Re7+ Kg8',
    'g6 Nf6',
    'Rf7 Nd5',
    'Ne4 Ra3+',
    'Kf2 Ra2+',
    'Ke1 Ra4',
    'Nf6+ Nxf6',
    'Rxf6 Ra7',
    'Kf2 Kg7',
    'Rb6 Kh6',
    'Rb8 Kg7',
    'Kf3 Kh6',
    'Rh8+ Kg5',
    'Rf8 Kh6',
    'Kf4 Ra4+',
    'Ke5 Ra5+',
    'Ke6 Ra6+',
    'Kf7 Ra7+',
    'Kg8 Rg7+',
    'Kh8 Ra7',
  ],
  [
    'e4 c5',
    'c3 Nf6',
    'e5 Nd5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Bc4 Nb6',
    'Bb3 d5',
    'exd6 Qxd6',
    'O-O Be6',
    'Bxe6 Qxe6',
    'Nxd4 Nxd4',
    'Qxd4 Rd8',
    'Qb4 Qc6',
    'a4 Rd5',
    'a5 e6',
    'Qf4 Bd6',
    'axb6 Bxf4',
    'bxa7 Ke7',
    'Bxf4 Ra8',
    'Bb8 Rd8',
    'Na3 Raxb8',
    'axb8=Q Rxb8',
    'Nc2 Rd8',
    'Nd4 Qb6',
    'b4 Rc8',
    'Ra3 Rc4',
    'Rd1 Qc7',
    'Ne2 h5',
    'Rd4 Qe5',
    'Rd2 Re4',
    'Raa2 Qxc3',
  ],
  [
    'd4 Nf6',
    'c4 Nc6',
    'Nf3 e6',
    'a3 d6',
    'Nc3 g6',
    'd5 Ne7',
    'g3 Bg7',
    'Bg2 O-O',
    'O-O a6',
    'Ne1 Rb8',
    'e4 c5',
    'f4 b5',
    'e5 Ne8',
    'Be3 Nf5',
    'Bf2 bxc4',
    'Qe2 Nd4',
    'Qxc4 Rxb2',
    'Bxd4 cxd4',
    'Qxd4 dxe5',
    'fxe5 exd5',
    'Nxd5 Re2',
    'Nf3 Bb7',
    'Rfd1 Nc7',
    'Nf4 Qxd4+',
    'Nxd4 Rb2',
    'Bxb7 Rxb7',
    'Nc6 Ne6',
    'Nd5 Kh8',
    'Rab1 Rd7',
    'Nf6 Rc7',
    'Rb6 Rfc8',
    'Nd4 Nxd4',
    'Rxd4 Bf8',
    'Rbd6 Rc1+',
    'Kg2 Bxd6',
    'exd6 R1c2+',
    'Kh3 Kg7',
    'Nd5 Rd8',
    'Nb4 Rc3',
    'Nxa6 Rxa3',
    'Nc5 Ra5',
    'Ne4 Re5',
    'g4 f5',
    'gxf5 gxf5',
    'Nd2 Kf6',
    'Kg3 Ke6',
    'Rh4 Rxd6',
    'Rh6+ Kd7',
    'Rxh7+ Re7',
    'Rxe7+ Kxe7',
    'Nf3 Kf6',
    'h3 Ra6',
    'Ne1 Ke5',
    'Nd3+ Ke4',
    'Nf2+ Ke3',
    'Nd1+ Kd2',
    'Nb2 Kc2',
    'Kf4 Kxb2',
    'Kxf5 Rh6',
    'Kg4 Kc3',
    'h4 Kd4',
    'Kg5 Rh8',
    'h5 Ke5',
    'Kg6 Ke6',
    'h6 Rg8+',
    'Kh7 Rg1',
    'Kh8 Kf7',
    'h7 Kg6',
    'Kg8 Kh6+',
    'Kh8 Ra1',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c4 Bg7',
    'Nc3 O-O',
    'e4 d6',
    'Be2 Na6',
    'O-O e5',
    'Be3 Ng4',
    'Bg5 Qe8',
    'd5 f5',
    'Nd2 Nf6',
    'f3 h6',
    'Be3 f4',
    'Bf2 g5',
    'c5 Nxc5',
    'b4 Na6',
    'Bxa6 bxa6',
    'Qe2 Qg6',
    'Rfc1 Rf7',
    'b5 axb5',
    'Nxb5 Ba6',
    'Rc6 Bxb5',
    'Qxb5 g4',
    'Kh1 Bf8',
    'Qb7 Re8',
    'Bxa7 Rg7',
    'Qb3 h5',
    'a4 Ra8',
    'Bg1 h4',
    'fxg4 h3',
    'Qf3 hxg2+',
    'Qxg2 Nxg4',
    'a5 Qh6',
    'a6 Nf6',
    'Qf3 Nh5',
    'Bf2 Ng3+',
    'Bxg3 fxg3',
    'Qg2 Ra7',
    'Rac1 Qh4',
    'h3 Qg5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 e5',
    'Be3 Na6',
    'O-O Ng4',
    'Bg5 Qe8',
    'dxe5 dxe5',
    'h3 f6',
    'Bd2 Nh6',
    'Rb1 c6',
    'b4 Nf7',
    'Be3 Qe7',
    'Qb3 Re8',
    'b5 Nc5',
    'Qb4 Bf8',
    'Nd2 f5',
    'bxc6 bxc6',
    'exf5 gxf5',
    'Bxc5 Qxc5',
    'Qxc5 Bxc5',
    'Na4 Bd6',
    'Nb3 Bc7',
    'Rfc1 Rb8',
    'Bf3 e4',
    'Bh5 Rd8',
    'Be2 Ne5',
    'Nac5 Kf7',
    'Rd1 Nd7',
    'Nxd7 Rxd7',
    'Rxd7+ Bxd7',
    'Rd1 Bc8',
    'Kf1 Ke7',
    'g4 a5',
    'Nd4 Kf6',
    'Nxc6 Rb2',
    'Na7 Be6',
    'gxf5 Bxf5',
    'Bg4 Bxg4',
    'hxg4 Bb6',
    'Nb5 Ke5',
    'Rd5+ Kf4',
    'Rf5+ Kxg4',
    'Rf7 h5',
    'Nc3 e3',
    'fxe3 Bxe3',
    'Nd1 Rb1',
    'Ke2 Bc5',
    'Nc3 Rb2+',
    'Kd3 Bb4',
    'Rg7+ Kf5',
    'Ne4 h4',
    'Rh7 Be1',
    'c5 Rb1',
    'c6 Rc1',
    'c7 Kg6',
    'Re7 Bb4',
    'Nd2 Rc5',
    'Ne4 Rc1',
    'Nd2 Rc3+',
    'Kd4 Rc5',
    'Nc4 h3',
  ],
  [
    'c4 e5',
    'g3 g6',
    'Bg2 Bg7',
    'Nc3 f5',
    'd3 Nf6',
    'Nf3 Nc6',
    'O-O O-O',
    'Rb1 a5',
    'a3 d6',
    'b4 axb4',
    'axb4 h6',
    'b5 Ne7',
    'Ne1 g5',
    'Nc2 Rb8',
    'Ba3 b6',
    'Nb4 Bb7',
    'Bxb7 Rxb7',
    'Ncd5 f4',
    'Nc6 Nxc6',
    'bxc6 Ra7',
    'Bb2 Qe8',
    'Ra1 Rxa1',
    'Qxa1 Qh5',
    'Nxf6+ Rxf6',
    'f3 Qh3',
    'Qe1 Rg6',
    'Qf2 Qe6',
    'e4 Qe8',
    'gxf4 gxf4+',
    'Kh1 Qxc6',
    'Rg1 Rxg1+',
    'Kxg1 Qa4',
    'Qd2 Kf8',
    'Kg2 Ke7',
    'Kh3 Bf6',
    'Bc3 c6',
    'Kg4 Qa8',
    'Kh3 Qc8+',
    'Kg2 Qg8+',
    'Kf1 Qa8',
    'Qb2 Qa6',
    'Kg2 Kd7',
    'Kh3 Kc7',
    'Kg2 Qa4',
    'd4 Qxc4',
    'dxe5 Be7',
    'exd6+ Bxd6',
    'Be5 Qd3',
    'Bxd6+ Qxd6',
    'Qg7+ Kc8',
    'e5 Qd2+',
    'Kg1 Qe3+',
    'Kg2 b5',
    'Qf8+ Kb7',
    'Qd6 Qe2+',
    'Kg1 Qxf3',
    'Qd7+ Kb6',
    'Qd8+ Kc5',
    'Qd6+ Kc4',
    'e6 Qe3+',
    'Kf1 c5',
    'Qa6 Qf3+',
    'Kg1 Qe2',
    'Qa3 f3',
    'Qc1+ Kd5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 Nfd7',
    'Qd2 Nb6',
    'O-O-O N8d7',
    'Ndxb5 axb5',
    'Nxb5 Ba6',
    'Nxd6+ Bxd6',
    'Qxd6 Nc4',
    'Bxc4 Bxc4',
    'Rd4 Be2',
    'a4 e5',
    'Rb4 Qe7',
    'Qxe7+ Kxe7',
    'f4 exf4',
    'Bxf4 Bxg4',
    'Rg1 h5',
    'b3 Rhc8',
    'Kb2 g6',
    'Ra1 Ra5',
    'Rd4 Rca8',
    'Bd2 R5a6',
    'a5 Ne5',
    'Rd5 f6',
    'Bc3 Be6',
    'Rb5 Bd7',
    'Rc5 Rc8',
    'Rxc8 Bxc8',
    'b4 Bd7',
    'Bxe5 fxe5',
    'c4 Be6',
    'b5 Ra8',
    'Kb3 Rc8',
    'Rc1 g5',
    'Kb4 g4',
    'c5 h4',
    'b6 Bd7',
    'b7 Rb8',
    'a6 Bc6',
    'Ka5 Bxb7',
    'axb7 Rxb7',
  ],
  [
    'f4 Nf6',
    'b3 g6',
    'Bb2 Bg7',
    'e3 O-O',
    'Be2 c5',
    'Nf3 Nc6',
    'O-O d5',
    'Ne5 Nxe5',
    'Bxe5 Ne8',
    'Bxg7 Nxg7',
    'd4 Nf5',
    'Qd2 Qb6',
    'g4 cxd4',
    'gxf5 dxe3',
    'Qxd5 Bxf5',
    'Na3 Rfd8',
    'Qf3 Qc5',
    'Nc4 Rd4',
    'c3 Re4',
    'Rad1 Rc8',
    'b4 Qc7',
    'Rd4 Re6',
    'Rfd1 Rc6',
    'b5 Rc5',
    'Qxe3 b6',
    'Qd2 Be6',
    'Rd8+ Rxd8',
    'Qxd8+ Qxd8',
    'Rxd8+ Kg7',
    'Ne5 Rxc3',
    'Rd2 Ra3',
    'Nc6 Kf6',
    'Kf2 Rh3',
    'Kg2 Ra3',
    'Kf2 h5',
    'h4 Ra4',
    'Ke3 Ra3+',
    'Bd3 Bxa2',
    'Ne5 Bb1',
    'Kd4 Bxd3',
    'Rxd3 Ra4+',
    'Ke3 Rb4',
    'Rd5 Ke6',
    'Nc6 Kxd5',
    'Nxb4+ Kc5',
    'Nc6 a5',
    'Nxe7 a4',
    'Kd3 Kxb5',
    'f5 gxf5',
    'Nxf5 Kc5',
    'Ng3 Kd5',
    'Nxh5 Ke5',
    'Ng3 a3',
    'Kc3 Kf4',
    'Kb3 Kg4',
    'h5 Kg5',
    'Kxa3 f5',
    'Nxf5 Kxf5',
    'Kb4 b5',
    'Kxb5 Kg5',
    'Kc5 Kxh5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Be7',
    'Qf3 Qc7',
    'O-O-O Nbd7',
    'g4 h6',
    'Bxf6 Bxf6',
    'h4 g5',
    'hxg5 hxg5',
    'Rxh8+ Bxh8',
    'f5 Bxd4',
    'Rxd4 Qc5',
    'Qd1 Ke7',
    'Qd2 Nf6',
    'Na4 Qe5',
    'Nb6 Rb8',
    'Nc4 Nxe4',
    'Qe3 Qf4',
    'Qxf4 gxf4',
    'Rxe4 d5',
    'f6+ Kxf6',
    'Rxf4+ Kg5',
    'Rxf7 dxc4',
    'Bxc4 b5',
    'Bb3 Rb6',
    'Rc7 Bb7',
    'Bxe6 Rxe6',
    'Rxb7 Kxg4',
    'Rf7 Kg5',
    'b3 Kg6',
    'Rf2 b4',
    'Kb2 a5',
    'c3 Re8',
    'a4 bxc3+',
    'Kxc3 Rc8+',
    'Kd4 Rb8',
    'Rf3 Kg5',
    'Kc4 Rb4+',
    'Kc3 Rb8',
    'Rf7 Rc8+',
    'Kb2 Kg6',
    'Rb7 Kf6',
    'Rb5 Ra8',
    'Kc3 Ke6',
    'Kc4 Kd6',
    'Rb6+ Kc7',
    'Kb5 Ra7',
    'Ra6 Rb7+',
    'Kc4 Rb4+',
    'Kc3 Rh4',
    'Rxa5 Kb6',
    'Rg5 Ka6',
    'Kb2 Rf4',
    'Ka3 Rh4',
    'a5 Rf4',
    'b4 Rf1',
    'Rg6+ Kb5',
    'Rb6+ Kc4',
    'a6 Ra1+',
    'Kb2 Ra4',
    'b5 Rb4+',
    'Kc2 Ra4',
    'Kd2 Kd4',
    'Ke2 Ke4',
    'Kf2 Ra2+',
    'Kg3 Ra3+',
    'Kg4 Ra1',
    'Kg5 Ke5',
    'Kg6 Rg1+',
    'Kf7 Rh1',
    'Rb7 Kf5',
    'Ke7 Ke5',
    'Kd7 Kd5',
    'Kc7 Kc5',
    'Kb8 Rh8+',
    'Ka7 Rh6',
    'b6 Kb5',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Bf4 Bg7',
    'e3 O-O',
    'Be2 d6',
    'h3 Nbd7',
    'O-O Qe8',
    'Bh2 e5',
    'c4 Qe7',
    'Nc3 e4',
    'Nd2 b6',
    'b4 Bb7',
    'a4 c5',
    'bxc5 dxc5',
    'd5 Ne8',
    'Qc2 f5',
    'a5 Nd6',
    'Rab1 Ba6',
    'Nb5 Bxb5',
    'cxb5 Be5',
    'Nc4 Bxh2+',
    'Kxh2 Ne5',
    'Nxe5 Qxe5+',
    'g3 g5',
    'Qb2 Qxb2',
    'Rxb2 c4',
    'Rc1 Rfc8',
    'Rbc2 a6',
    'Bxc4 axb5',
    'Bxb5 Rxc2',
    'Rxc2 Nxb5',
    'axb6 Nd6',
    'Rc6 Rd8',
    'g4 fxg4',
    'hxg4 Kf8',
    'Kg2 Ke7',
    'f3 exf3+',
    'Kxf3 Kf6',
    'e4 Ke5',
    'Rc7 Kd4',
  ],
  [
    'd4 Nf6',
    'c4 c5',
    'd5 e6',
    'Nc3 exd5',
    'cxd5 d6',
    'e4 g6',
    'f3 Bg7',
    'Bg5 O-O',
    'Qd2 a6',
    'a4 Nbd7',
    'Nh3 Qa5',
    'Ra3 Re8',
    'Nf2 Rb8',
    'Be2 Qc7',
    'O-O b6',
    'f4 c4',
    'b4 cxb3',
    'Rxb3 Nc5',
    'Rb4 Rb7',
    'Bf3 Qb8',
    'Re1 Nfd7',
    'Ng4 f6',
    'Bh4 Rc7',
    'Bg3 a5',
    'Rbb1 Ba6',
    'f5 Rcc8',
    'Be2 Qa8',
    'Bxd6 Bxe2',
    'Rxe2 Qa6',
    'Bxc5 Nxc5',
    'd6 gxf5',
    'exf5 Rxe2',
    'Nxe2 Qc4',
    'Nf4 h5',
    'Nf2 Rd8',
    'Nxh5 Nxa4',
    'Rc1 Rxd6',
    'Rxc4 Rxd2',
    'Rxa4 Kf7',
    'Rc4 Bh6',
    'Kf1 Be3',
    'Rc7+ Kf8',
    'Ne4 Rd5',
    'g4 a4',
    'Nexf6 Rd2',
    'Nh7+ Kg8',
    'N5f6+ Kh8',
  ],
  [
    'd4 Nf6',
    'Bg5 c5',
    'Bxf6 gxf6',
    'd5 Qb6',
    'Qc1 f5',
    'g3 Bg7',
    'c3 e6',
    'Bg2 Na6',
    'Na3 Nc7',
    'Nc4 Qa6',
    'Qf4 O-O',
    'Nh3 d6',
    'O-O e5',
    'Qh4 f4',
    'b3 b5',
    'Nd2 f5',
    'a4 bxa4',
    'Nc4 Nb5',
    'Rxa4 Qb7',
    'Na5 Qf7',
    'Rc1 fxg3',
    'Qxg3 f4',
    'Qd3 Qh5',
    'Nxf4 exf4',
    'Qxb5 f3',
    'exf3 Bh3',
    'Qf1 Rxf3',
    'Bxh3 Rxh3',
    'Qg2 Kh8',
    'Rg4 Be5',
    'Nc6 Bxh2+',
    'Kf1 Bf4',
    'Ne7 Rh1+',
    'Ke2 Rxc1',
    'Kd3 Qf7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Bb4+',
    'c3 Bc5',
    'Be3 Bb6',
    'Qg4 Qf6',
    'Qg3 Qg6',
    'Nf5 d6',
    'Bxb6 axb6',
    'Ne3 Nf6',
    'f3 Qh6',
    'Qf2 d5',
    'Na3 dxe4',
    'Nb5 O-O',
    'Nxc7 Ra5',
    'b4 Rh5',
    'f4 Ng4',
    'Qg3 Nxh2',
    'O-O-O Nxf1',
    'Rhxf1 Nxb4',
    'cxb4 Qc6+',
    'Kb2 Qxc7',
    'Rd4 Be6',
    'Rc1 Qe7',
    'Rxe4 Qf6+',
    'Re5 Rxe5',
    'fxe5 Qh6',
    'Rd1 Qh5',
    'Rd2 h6',
    'Qf4 Rc8',
    'Qg3 Ra8',
    'a3 Rc8',
    'Qf4 b5',
    'Qg3 g5',
    'Nc2 Qh1',
    'Qf3 Qh2',
    'Re2 Rd8',
    'Qxb7 Rd3',
    'Qe4 Rb3+',
    'Kc1 Rc3',
    'Kb2 Rb3+',
    'Kc1 Qg3',
    'Qd4 Rd3',
    'Qe4 Bg4',
    'Re1 Rc3',
    'Rf1 Be6',
    'Rd1 Bb3',
    'e6 Rxc2+',
    'Kb1 Rc8',
    'e7 Bxd1',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 d6',
    'f4 Nh6',
    'Nf3 O-O',
    'O-O f5',
    'h3 Nf7',
    'Be3 Nd4',
    'Kh2 Bd7',
    'Bg1 Bc6',
    'Nxd4 cxd4',
    'Ne2 e5',
    'c3 dxc3',
    'Nxc3 exf4',
    'gxf4 fxe4',
    'dxe4 g5',
    'Bd4 Bxd4',
    'Qxd4 gxf4',
    'Rxf4 Ne5',
    'Rxf8+ Qxf8',
    'Qe3 Qe7',
    'Rf1 b6',
    'Qf4 Re8',
    'Qf6 Nc4',
    'Qxe7 Rxe7',
    'Rd1 Re6',
    'b3 Ne3',
    'Rd3 Nxg2',
    'Kxg2 Rxe4',
    'Kf2 Re6',
    'a4 Rf6+',
    'Kg3 Kf7',
    'Ne2 Ke7',
    'Nd4 Be4',
    'Re3 d5',
    'Nb5 Ke6',
    'Nxa7 Ke5',
    'Nb5 d4',
    'Re1 Rf3+',
    'Kg4 Rxb3',
  ],
  [
    'd4 d5',
    'c4 c6',
    'e3 Nf6',
    'Nc3 a6',
    'c5 g6',
    'Bd3 Bg7',
    'Nge2 O-O',
    'O-O e5',
    'f3 Nbd7',
    'Bd2 Re8',
    'b4 Nf8',
    'Qe1 N6d7',
    'Qf2 Ne6',
    'Na4 Bh6',
    'f4 exd4',
    'exd4 f5',
    'h3 Nf6',
    'Nb6 Rb8',
    'Qf3 Bg7',
    'Kh1 Ne4',
    'Be3 Nf8',
    'Nxc8 Rxc8',
    'Rfd1 Ne6',
    'Bg1 Ra8',
    'a4 Qd7',
    'Rab1 Nc7',
    'Rb3 Rab8',
    'Bc2 b5',
    'a5 Re7',
    'Bd3 Rf8',
    'Be3 Kf7',
    'Bc2 Ke8',
    'Qf1 Kd8',
    'Qe1 Ne6',
    'Qg1 Kc8',
    'Nc1 Rfe8',
    'g3 Qd8',
    'Ne2 Bf6',
    'Rbd3 Ng7',
    'Rf1 Kb7',
    'Rf3 h6',
    'Bd1 Re6',
    'Qg2 R8e7',
    'Bg1 Qe8',
    'Kh2 Rf7',
    'Rf1 Bd8',
    'Kh1 Bc7',
    'Bh2 Ref6',
    'Ng1 g5',
    'Nf3 Ne6',
    'Ne5 Rg7',
    'Qf3 Qg8',
    'Qe3 gxf4',
    'gxf4 Bxe5',
    'dxe5 Rfg6',
    'Qe1 Rg2',
    'Bf3 Rb2',
    'Bxe4 dxe4',
    'Rd2 Rg2',
    'Rxb2 Rxb2',
    'Rf2 Rxf2',
    'Qxf2 Qd8',
    'Qe2 Qd3',
    'Qh5 Qf1+',
    'Bg1 e3',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nc3 c6',
    'Nf3 Nf6',
    'Bg5 h6',
    'Bh4 dxc4',
    'e4 g5',
    'Bg3 b5',
    'Be2 Bb7',
    'h4 g4',
    'Ne5 h5',
    'O-O Nbd7',
    'Qc2 Nxe5',
    'Bxe5 Rg8',
    'Rad1 Nd7',
    'Bg3 e5',
    'dxe5 Qe7',
    'a4 a6',
    'axb5 axb5',
    'Ra1 Rxa1',
    'Rxa1 Bg7',
    'Ra7 Nc5',
    'Bf4 Bxe5',
    'Be3 Ne6',
    'g3 Qc7',
    'Qd2 Ke7',
    'Nd5+ cxd5',
    'exd5 Ra8',
    'Qb4+ Bd6',
    'Qxb5 Rxa7',
    'Bxa7 Bxd5',
    'Qxd5 Qxa7',
    'Kg2 Qc5',
    'Qb7+ Bc7',
    'Qc8 Qe5',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Nc3 Nf6',
    'd4 exd4',
    'Nxd4 d6',
    'Nde2 Be7',
    'h3 O-O',
    'g4 Rb8',
    'Bg2 b5',
    'a3 a6',
    'O-O Re8',
    'Be3 Bf8',
    'Ng3 b4',
    'axb4 Nxb4',
    'Qd2 Bb7',
    'Rad1 a5',
    'b3 Qc8',
    'f4 c5',
    'g5 Nd7',
    'f5 c4',
    'Na4 d5',
    'Bf4 d4',
    'Bxb8 Nxb8',
    'Nh5 Bxe4',
    'Nb6 Qc5',
    'Nxc4 d3+',
    'Kh1 dxc2',
    'Ra1 Bxg2+',
    'Qxg2 N8c6',
    'g6 Qd5',
    'gxf7+ Kxf7',
    'Qxd5+ Nxd5',
    'Nxa5 Ne3',
    'Rfc1 Nxa5',
    'Rxa5 Rd8',
    'Ra7+ Be7',
    'Rg1 Rd1',
    'Rc7 Kf8',
    'Rc8+ Kf7',
    'Rc7 Kf8',
    'Nf4 Bf6',
    'b4 Be5',
    'Rc8+ Ke7',
    'Ne2 Rd2',
    'Re1 Nxf5',
    'Nd4 Kd7',
    'Rxe5 Kxc8',
    'Rc5+ Kd7',
    'Nxc2 Nh4',
    'Rc3 g5',
    'b5 h5',
    'Nb4 Rb2',
    'Nd5 Kd6',
    'Rd3 Ke5',
    'Nc3 g4',
    'hxg4 hxg4',
    'Kg1 Nf3+',
    'Kf1 Kf4',
    'Ne2+ Ke5',
    'Nc3 Kf5',
    'Ne2 Kg5',
    'Kf2 Rxb5',
    'Rd8 Ne5',
    'Rg8+ Kf6',
    'Rf8+ Ke7',
    'Ra8 Rb2',
    'Ke3 Rb4',
    'Kf2 Rb3',
    'Ra7+ Kf6',
    'Ra6+ Kg5',
    'Ra5 Rf3+',
    'Kg2 Re3',
    'Kf2 Rf3+',
    'Kg2 Kf6',
    'Ra6+ Ke7',
    'Nd4 Rd3',
  ],
  [
    'd4 Nf6',
    'Nc3 d5',
    'Bg5 e6',
    'e4 Bb4',
    'Ne2 dxe4',
    'a3 Be7',
    'Bxf6 gxf6',
    'Nxe4 f5',
    'N4c3 c6',
    'g3 b6',
    'Bg2 Bb7',
    'Qd3 Nd7',
    'Nd1 Qc7',
    'c4 O-O-O',
    'Ne3 Kb8',
    'Qc2 Bd6',
    'f4 h5',
    'h4 Nf6',
    'O-O-O c5',
    'd5 Rhe8',
    'Nc3 a6',
    'Rhe1 Rg8',
    'Nf1 Rxg3',
    'Nxg3 Bxf4+',
    'Kb1 Bxg3',
    'Re2 Bc8',
    'Rd3 Be5',
    'Na4 Ka7',
    'Rb3 Nd7',
    'Bf3 Rg8',
    'Bxh5 exd5',
    'cxd5 b5',
    'Nc3 c4',
    'Rxb5 Bxc3',
    'd6 Qxd6',
    'Rxf5 Rg1+',
    'Ka2 Qxa3+',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 c5',
    'dxc5 O-O',
    'a3 Bxc5',
    'Nf3 Qc7',
    'Bg5 Be7',
    'e4 d6',
    'Be2 a6',
    'O-O Nbd7',
    'Rfd1 b6',
    'b4 Bb7',
    'Bf4 Ng4',
    'Nd2 Nge5',
    'Rac1 Rac8',
    'Be3 Rfe8',
    'Qb1 Qb8',
    'Na4 Ba8',
    'f4 Ng6',
    'Nb3 Qb7',
    'Nd2 Bd8',
    'Qd3 Bc7',
    'g3 Nf6',
    'Bf3 Qb8',
    'Bf2 Red8',
    'Qe2 Nd7',
    'Nc3 Bb7',
    'h4 h6',
    'h5 Ngf8',
    'Nb3 Nh7',
    'Rd3 Ndf6',
    'Rcd1 Re8',
    'c5 bxc5',
    'bxc5 d5',
    'exd5 Nxd5',
    'Nxd5 exd5',
    'Re3 Nf6',
    'Nd4 Qa8',
    'Qb2 Rxe3',
    'Bxe3 Bc6',
    'Nf5 Bd8',
    'Bd4 Qa7',
    'Re1 Kh7',
    'Qc2 Kg8',
    'Qc3 Kh7',
    'Nd6 Rc7',
    'Kh2 Qa8',
    'Be5 d4',
    'Qd3+ Kg8',
  ],
  ['d4 Nf6', 'Nf3 g6', 'g3 Bg7', 'Bg2 O-O', 'c4 d6', 'O-O Nc6', 'Nc3 Bg4', 'h3 Bxf3', 'Bxf3 Nd7', 'e3 e5'],
  [
    'e4 e6',
    'd4 d5',
    'Nd2 c5',
    'exd5 Qxd5',
    'Ngf3 cxd4',
    'Bc4 Qd6',
    'O-O Nf6',
    'Re1 Nc6',
    'Ne4 Qd8',
    'Bb5 Bd7',
    'Bg5 Be7',
    'Bxf6 Bxf6',
    'Nd6+ Ke7',
    'Ne4 Qb6',
    'Nxf6 gxf6',
    'Bxc6 Bxc6',
    'Nxd4 Rad8',
    'c3 Rhg8',
    'g3 Qxb2',
    'Nf5+ Ke8',
    'Qh5 Be4',
    'Qxh7 Bxf5',
    'Qxg8+ Ke7',
    'Qg7 Rd2',
    'Rf1 Bd3',
    'Qh8 Rxf2',
    'Rxf2 Qxa1+',
    'Kg2 Be4+',
    'Kh3 Bf5+',
    'Rxf5 exf5',
    'Qb8 Qf1+',
    'Kh4 b6',
    'Qc7+ Kf8',
    'Qxa7 Qc4+',
    'Kh3 Qf1+',
    'Kh4 Qg2',
    'Qb8+ Kg7',
    'h3 Qxa2',
    'Qxb6 Qd2',
    'g4 Qe1+',
    'Kh5 Qg3',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 Na6',
    'O-O e5',
    'Be3 Ng4',
    'Bg5 Qe8',
    'dxe5 dxe5',
    'h3 f6',
    'Bd2 Nh6',
    'c5 Nxc5',
    'Qc1 Nf7',
    'Nd5 Ne6',
    'Nxc7 Nxc7',
    'Qxc7 Nd8',
    'Be3 Be6',
    'Bc4 Rf7',
    'Bxe6 Qxe6',
    'Qc2 Rc8',
    'Qa4 Nc6',
    'Rfd1 Bf8',
    'Rac1 Rfc7',
    'a3 h6',
    'b4 a6',
    'Bb6 Rf7',
    'Nd2 f5',
    'Qb3 Re8',
    'Qxe6 Rxe6',
    'exf5 gxf5',
    'Nc4 h5',
    'Rd5 Rg6',
    'f3 Bh6',
    'Re1 Bf4',
    'Rd6 Rxd6',
    'Nxd6 Rf6',
    'Nc4 Re6',
    'Kf1 e4',
    'Bf2 Ne7',
    'g3 b5',
    'Ne3 Bxe3',
    'Bxe3 Nd5',
    'Bg5 Nb6',
    'fxe4 fxe4',
    'Rc1 Kf7',
    'Ke2 Kg6',
    'Be3 Nc4',
    'a4 Rd6',
    'Bf4 Rd3',
    'axb5 axb5',
    'Rd1 Rb3',
    'Rd5 Rxb4',
    'Rg5+ Kf7',
    'Rxh5 Rb2+',
    'Ke1 e3',
    'Rf5+ Ke6',
    'Rc5 Rb1+',
    'Ke2 Rb2+',
    'Ke1 Rh2',
    'Rxb5 Na3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 O-O',
    'f3 d6',
    'Be3 c5',
    'Nge2 Nc6',
    'dxc5 dxc5',
    'Qxd8 Rxd8',
    'Bxc5 Nd7',
    'Be3 Nde5',
    'Nc1 Nb4',
    'Kf2 Nc2',
    'Rb1 Nxe3',
    'Kxe3 Bh6+',
    'f4 Nc6',
    'Be2 g5',
    'g3 gxf4+',
    'gxf4 e5',
    'Nd3 Be6',
    'Nd5 exf4+',
    'N3xf4 Bxd5',
    'cxd5 Ne5',
    'Rhg1+ Ng6',
    'Rbf1 Kf8',
    'Rxg6 hxg6',
    'Kd4 Bg7+',
    'e5 Re8',
    'Ne6+ Kg8',
    'Nxg7 Kxg7',
    'd6 Rh8',
    'Bc4 Rh4+',
    'Kd3 Rf8',
    'Rf2 f5',
    'e6 Rd8',
    'd7 Kf6',
    'Bd5 b6',
    'Rc2 g5',
    'a3 Ke7',
    'b4 Rh3+',
    'Kd4 Rxa3',
    'Ke5 f4',
    'Rg2 Re3+',
    'Kd4 Kf6',
    'h4 gxh4',
    'Rg4 Rxe6',
    'Rxf4+ Kg5',
    'Bxe6 Kxf4',
    'Kd5 a5',
    'b5 a4',
    'Kc6 a3',
    'Kc7 Rxd7+',
    'Kxd7 h3',
  ],
  [
    'e4 c5',
    'Nf3 g6',
    'd4 Bg7',
    'Nc3 cxd4',
    'Nxd4 Nc6',
    'Be3 Nf6',
    'Bc4 O-O',
    'Bb3 a5',
    'O-O Nxd4',
    'Bxd4 d6',
    'Qe2 Bd7',
    'Rad1 a4',
    'Bc4 Bc6',
    'f4 Nd7',
    'e5 dxe5',
    'fxe5 e6',
    'Bc5 Qa5',
    'Bxf8 Rxf8',
    'Bb5 Nxe5',
    'Bxc6 bxc6',
    'Ne4 Qb6+',
    'Qf2 Qxb2',
    'c3 Qa3',
    'h3 h6',
    'Kh1 f5',
    'Nc5 Re8',
    'Na6 Qxc3',
    'Nc7 Re7',
    'Qa7 Kh7',
    'Qb8 Nf7',
    'Qc8 Qg3',
    'Nxe6 Be5',
    'Kg1 Qh2+',
    'Kf2 Qg3+',
    'Kg1 Qh2+',
    'Kf2 Bg3+',
    'Kf3 Ne5+',
    'Ke3 Qxg2',
    'Rd8 Qe4+',
    'Kd2 Nc4+',
    'Kc3 Be5+',
    'Nd4 Nb6',
    'Qa6 Nd5+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 O-O',
    'Bg5 d6',
    'Qd2 c5',
    'd5 a6',
    'a4 e6',
    'Bd3 exd5',
    'exd5 Nbd7',
    'f4 Qa5',
    'Ra3 Nb6',
    'Nge2 Bf5',
    'O-O Qb4',
    'Bxf6 Bxf6',
    'a5 Nxc4',
    'Bxc4 Qxc4',
    'Ra4 Qd3',
    'Qc1 Rfe8',
    'Rf3 Qc2',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'c4 a6',
    'Nc3 Qc7',
    'Be2 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'O-O Bb4',
    'Nxc6 dxc6',
    'Qd3 O-O',
    'Rd1 e5',
    'Bg5 Be7',
    'Qg3 Re8',
    'Rac1 b6',
    'h4 Kh8',
    'Be3 Bb7',
    'f3 Nd7',
    'Qf2 Bc5',
    'Bxc5 Nxc5',
    'b4 Ne6',
    'Nd5 cxd5',
    'cxd5 Qe7',
    'dxe6 Qxe6',
    'Rc7 Bc6',
    'Qxb6 Qxa2',
    'Qf2 Rec8',
    'Rxc8+ Rxc8',
    'Bf1 Qxf2+',
    'Kxf2 Bb5',
    'Bxb5 axb5',
    'Rd5 f6',
    'Rxb5 Rc2+',
    'Kg3 h5',
    'Rb7 Rb2',
    'b5 Kh7',
    'b6 Kg6',
    'Rb8 Kf7',
    'Kh2 Rb1',
    'b7 Kg6',
    'g4 hxg4',
    'fxg4 Rb2+',
    'Kg3 Rb3+',
    'Kf2 Rb2+',
    'Ke3 Rb3+',
    'Kd2 Kh7',
    'Kc2 Rb6',
    'h5 Rb4',
    'Kc3 Rb6',
    'Kc4 Rb1',
    'Kd5 Rd1+',
    'Ke6 Rb1',
    'Kf7 Rb6',
    'Ke7 Rb4',
    'Kd6 Rb1',
    'Ke6 Rb2',
    'g5 fxg5',
    'Kf5 Rb3',
    'Kxg5 Rb4',
    'Kf5 Rb5',
    'Ke6 Rb1',
    'Kxe5 Rb5+',
    'Ke6 g6',
    'hxg6+ Kg7',
    'e5 Rb1',
    'Ke7 Rb5',
    'e6 Rb1',
    'Kd7 Rd1+',
  ],
  [
    'd4 g6',
    'e4 Bg7',
    'Nc3 c6',
    'f4 d5',
    'e5 h5',
    'Nf3 Nh6',
    'Be3 Qb6',
    'a3 Bg4',
    'Na4 Qa5+',
    'Qd2 Qxd2+',
    'Nxd2 Nd7',
    'c4 O-O',
    'cxd5 cxd5',
    'Nc3 Be6',
    'Bd3 Rac8',
    'Ke2 Nb6',
    'h3 Bf5',
    'Rhc1 f6',
    'Nf3 Bxd3+',
    'Kxd3 Nf5',
    'Bd2 h4',
    'b3 Rc6',
    'a4 Rd8',
    'a5 Nd7',
    'Nb5 Rxc1',
    'Rxc1 a6',
    'Nc3 Nf8',
    'Na4 Ne6',
    'Nc5 Kf7',
    'Nxe6 Kxe6',
    'Rc7 Rd7',
    'Rxd7 Kxd7',
    'Be1 Bh6',
    'Bxh4 Bxf4',
    'exf6 exf6',
    'Bxf6 Bc7',
    'b4 Bd6',
    'Kc3 Ng3',
    'Be5 Ne4+',
    'Kb3 Be7',
    'Bb8 Ke6',
    'Ne5 g5',
    'Nd3 Kf5',
    'g4+ Ke6',
    'Be5 Nd2+',
    'Kc3 Nf3',
    'Bg3 Ng1',
    'Kd2 Nxh3',
    'Ke3 Kd7',
    'Kf3 Kc6',
    'Kg2 Nf4+',
    'Bxf4 gxf4',
    'Kf3 Kb5',
    'Kxf4 Kc4',
    'Nc5 Kxd4',
    'g5 Kc4',
    'Kf5 Bxg5',
    'Kxg5 Kxb4',
    'Nxb7 Kb5',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c3 Bg7',
    'Nbd2 O-O',
    'e4 d6',
    'Bd3 Nbd7',
    'O-O e5',
    'Re1 b6',
    'Nb3 Bb7',
    'Bg5 h6',
    'Bxf6 Bxf6',
    'Qc2 Qe7',
    'Nbd2 Rfe8',
    'Rad1 Nf8',
    'd5 c6',
    'c4 Rec8',
    'Qb1 a6',
    'b3 b5',
    'Nf1 cxd5',
    'exd5 bxc4',
    'Bxc4 Nd7',
    'Ng3 Bg7',
    'Nd4 Nb6',
    'Qd3 Qd7',
    'Nf3 Nxc4',
    'bxc4 a5',
    'Rc1 f5',
    'Nd2 e4',
    'Qe2 Bd4',
    'Ngxe4 fxe4',
    'Qxe4 Qg7',
    'Qe6+ Qf7',
    'Nf3 Bc5',
    'Qg4 Re8',
    'Nd4 Rxe1+',
    'Rxe1 Rf8',
    'Rf1 Bc8',
    'Qd1 Qf4',
    'Nc6 Qxc4',
    'Ne7+ Kg7',
    'Nxc8 Rxc8',
    'Kh1 Re8',
    'Re1 Qxd5',
    'Qb1 Qxa2',
    'Qb7+ Qf7',
    'Qb2+ Kg8',
    'Rxe8+ Qxe8',
    'h3 Qe1+',
    'Kh2 Qe5+',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 d6',
    'c4 Nc6',
    'Nc3 Be7',
    'Be3 Nf6',
    'Be2 Nxd4',
    'Qxd4 O-O',
    'O-O Bd7',
    'Rfd1 Bc6',
    'f3 Nd7',
    'Rac1 f5',
    'b4 fxe4',
    'Nxe4 d5',
    'b5 e5',
    'Qd2 dxe4',
    'bxc6 Nc5',
    'Qd5+ Qxd5',
    'cxd5 exf3',
    'Bxf3 e4',
    'Bxc5 Bxc5+',
    'Rxc5 exf3',
    'c7 Rae8',
    'd6 b6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e5',
    'Nf3 Be7',
    'Bc4 O-O',
    'O-O Be6',
    'Bb3 h6',
    'Qe2 Nbd7',
    'Nh4 Rc8',
    'Ng6 Re8',
    'Nxe7+ Qxe7',
    'f3 Nc5',
    'Bxc5 dxc5',
    'Bxe6 Qxe6',
    'Rfd1 Red8',
    'Rxd8+ Rxd8',
    'Rd1 Rd4',
    'b3 c4',
    'Kf1 b5',
    'b4 Qd6',
    'a3 Ne8',
    'Nd5 Nc7',
    'Rxd4 exd4',
    'Qd2 Nxd5',
    'Qxd4 Ne3+',
    'Qxe3 Qd1+',
    'Kf2 Qxc2+',
    'Kg3 Qb3',
    'Qc5 c3',
    'e5 Qc4',
    'Qc8+ Kh7',
  ],
  [
    'd4 Nf6',
    'c4 d6',
    'Nc3 Nbd7',
    'Nf3 e5',
    'g3 Be7',
    'Bg2 O-O',
    'O-O c6',
    'b4 Re8',
    'Qb3 Bf8',
    'Rd1 Qe7',
    'a4 exd4',
    'Nxd4 d5',
    'cxd5 Qxb4',
    'dxc6 Qxb3',
    'Nxb3 bxc6',
    'Bxc6 Rb8',
    'Nd4 Bb7',
    'Bxb7 Rxb7',
    'Ba3 Nc5',
    'e3 Nb3',
    'Rab1 Bxa3',
    'Rxb3 Rxb3',
    'Nxb3 a6',
    'Nd5 Nxd5',
    'Rxd5 h6',
    'Nd4 Rc8',
    'Kg2 Bb4',
    'Rd7 Rc3',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 O-O',
    'a3 Bxc3+',
    'Qxc3 d6',
    'Bg5 Nbd7',
    'e3 b6',
    'Ne2 Bb7',
    'Qd3 a5',
    'Nc3 a4',
    'Qc2 Ra5',
    'Bxf6 Nxf6',
    'Nxa4 Be4',
    'Qd1 Qa8',
    'Nc3 Bxg2',
    'Rg1 Bxf1',
    'Kxf1 Rf5',
    'Rg2 g6',
    'Kg1 Ne4',
    'Nxe4 Qxe4',
    'Rc1 c5',
    'Rg4 Qc6',
    'b4 Ra8',
    'b5 Qb7',
    'a4 h5',
    'Rg3 Qe4',
    'Rc3 cxd4',
    'f3 Rxf3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bg5 Ne4',
    'cxd5 Nxg5',
    'Nxg5 e6',
    'Nf3 exd5',
    'e3 O-O',
    'Be2 a5',
    'O-O c6',
    'a3 b5',
    'Ne1 Nd7',
    'Nd3 Ba6',
    'b4 axb4',
    'axb4 Re8',
    'Qc2 Bf8',
    'Nb1 Nb6',
    'Nd2 Nc4',
    'Nb3 Re7',
    'Ndc5 Rea7',
    'Na5 Qb6',
    'Rfe1 Bc8',
    'Bxc4 bxc4',
    'Qc3 Rb8',
    'Ra4 Qb5',
    'Rea1 Bf5',
    'h3 Re8',
    'R4a2 Rae7',
    'Rb2 Ra7',
    'Rba2 h5',
    'Re2 Bd6',
    'Rae1 Bxc5',
    'dxc5 Bd3',
    'Rb2 Rb8',
    'Ra1 Re7',
    'Rd1 Re6',
    'Rdd2 Be4',
    'Rd1 Rbe8',
    'Rd4 Re5',
    'Rbd2 Kh7',
    'f3 Bd3',
    'e4 Kg8',
    'exd5 cxd5',
    'Kh2 Rf5',
    'f4 Be4',
    'Rf2 h4',
    'Qd2 Rb8',
    'Nxc4 dxc4',
    'Rxe4 Qxb4',
    'Re8+ Kh7',
    'Qxb4 Rxb4',
    'Re5 c3',
    'Rxf5 gxf5',
    'Rc2 Rc4',
    'Kg1 Kg6',
    'c6 Kf6',
    'g3 hxg3',
    'Kg2 Ke6',
    'Kxg3 Kd5',
    'h4 Rxc6',
    'h5 Kc4',
    'Kh4 Kb3',
    'Rc1 Kb2',
    'Rh1 c2',
    'Kg5 c1=Q',
    'Rxc1 Kxc1',
    'Kxf5 Kd2',
    'Kg5 Ke3',
    'f5 Ke4',
    'h6 Rxh6',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 e6',
    'e3 Nbd7',
    'Bd3 dxc4',
    'Bxc4 b5',
    'Bd3 Bd6',
    'O-O O-O',
    'Ne2 Re8',
    'Ng3 g6',
    'a4 bxa4',
    'Rxa4 Bb7',
    'e4 Be7',
    'Bd2 c5',
    'Ba5 Qb8',
    'e5 Bxf3',
    'Qxf3 Nd5',
    'Be4 Qb5',
    'Bxd5 exd5',
    'Rfa1 cxd4',
    'Rxd4 Nxe5',
    'Qxd5 a6',
    'Bc3 Nc6',
    'Rdd1 Qxd5',
    'Rxd5 Bb4',
  ],
  [
    'c4 Nf6',
    'Nc3 g6',
    'e4 d6',
    'd4 Bg7',
    'Nf3 O-O',
    'Be2 Na6',
    'O-O e5',
    'Re1 Bg4',
    'd5 Nc5',
    'Qc2 a5',
    'Bg5 h6',
    'Bh4 Qc8',
    'Nd2 Bd7',
    'b3 Nh7',
    'f3 h5',
    'Bf2 Bh6',
    'Rab1 f5',
    'a3 Nf6',
    'Bf1 fxe4',
    'Bxc5 Bxd2',
    'Qxd2 dxc5',
    'Qg5 Qe8',
    'Rb2 Bf5',
    'fxe4 Bd7',
    'Rf2 Kg7',
    'Be2 Nh7',
    'Rxf8 Nxg5',
    'Rxe8 Rxe8',
    'Rb1 Ra8',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 a6',
    'Nf3 b5',
    'b3 Bg4',
    'h3 Bh5',
    'g4 Bg6',
    'Ne5 e6',
    'h4 Ne4',
    'Nxe4 Bxe4',
    'f3 f6',
    'Nxc6 Nxc6',
    'fxe4 dxe4',
    'Qc2 Bb4+',
    'Ke2 f5',
    'a3 Bd6',
    'Bb2 O-O',
    'Bg2 Rc8',
    'gxf5 exf5',
    'Bh3 bxc4',
    'bxc4 Qe8',
    'Bc3 Rd8',
    'Rag1 Qh5+',
    'Ke1 Qh6',
    'Ke2 Qh5+',
    'Kd2 Rf7',
    'Rf1 Bxa3',
    'Qd1 Qh6',
    'Qa1 Bb4',
    'Ke2 Qh5+',
    'Kd2 Nxd4',
    'exd4 Rxd4+',
    'Ke1 Qxh4+',
    'Ke2 Qh5+',
    'Ke1 Rfd7',
    'Bxb4 Rd1+',
    'Kf2 Qf3+',
    'Kg1 Qe3+',
    'Kh2 Rxa1',
    'Rxa1 Rd3',
    'Bg2 f4',
    'Be1 Rd6',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Bb5 e6',
    'Bxc6 bxc6',
    'b3 d5',
    'Qe2 Ne7',
    'Ba3 Ng6',
    'O-O Qa5',
    'Bb2 Bd6',
    'Bxg7 Nf4',
    'Qe3 Rg8',
    'e5 Nxg2',
    'Qh6 Ne1',
    'exd6 Nxf3+',
    'Kh1 Ba6',
    'd3 Qd8',
    'Nd2 Nxd2',
    'Rg1 Qxd6',
    'Qxd2 O-O-O',
    'Qh6 Bb7',
    'Bf6 Rxg1+',
    'Rxg1 Re8',
    'Qxh7 Qf4',
    'Rg8 Qxf6',
    'Rxe8+ Kd7',
    'Rg8 Qxf2',
    'Qh3 c4',
    'Qg2 Qf4',
    'Rg3 Qc1+',
    'Qg1 Qxc2',
    'Qxa7 Qd1+',
    'Kg2 Qe2+',
    'Qf2 cxd3',
    'Qxe2 dxe2',
    'Kf2 d4',
    'h4 c5',
    'h5 Be4',
    'Kxe2 e5',
    'h6 Kc6',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 Ne7',
    'a3 Bxc3+',
    'bxc3 b6',
    'Qg4 Ng6',
    'a4 Ba6',
    'Bxa6 Nxa6',
    'Qe2 Nb8',
    'Nf3 Nc6',
    'h4 h5',
    'Ng5 Nge7',
    'Nh3 Ng6',
    'Bg5 Qd7',
    'O-O Nce7',
    'c4 Nf5',
    'Rfd1 Nfxh4',
    'cxd5 exd5',
    'c4 Nf5',
    'cxd5 Qxd5',
    'Qc2 Qd7',
    'Rac1 O-O',
    'Qxc7 Qxa4',
    'd5 Rac8',
    'Qxc8 Rxc8',
    'Rxc8+ Kh7',
    'Rcc1 Nxe5',
    'd6 f6',
    'Bf4 Nd7',
    'Ra1 Qc6',
    'Rdc1 Nc5',
    'Rxa7 Nxd6',
    'Be3 Nf5',
    'Bxc5 bxc5',
    'Ra5 Nh4',
    'Nf4 Qe4',
    'Raxc5 Qxf4',
    'R1c4 Nf3+',
    'gxf3 Qxf3',
    'Rc3 Qd1+',
    'Kg2 Qg4+',
    'Kh2 g5',
    'Re3 Kg6',
    'Rc6 Qf4+',
    'Kg1 g4',
    'Rc8 h4',
    'Rec3 Qd4',
    'R8c4 Qd1+',
    'Kh2 Kg5',
    'Rc5+ f5',
    'Rc1 Qf3',
    'R5c2 Qd3',
    'Rc3 Qd4',
    'R3c2 Qe5+',
    'Kh1 Qe4+',
    'Kg1 h3',
    'f4+ Qxf4',
    'Rf1 Qe3+',
    'Kh2 Qe5+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d5',
    'O-O c5',
    'Na3 Na6',
    'dxc5 Nxc5',
    'Be3 Nce4',
    'cxd5 Nxd5',
    'Bd4 Ndf6',
    'Qa4 Nd6',
    'Rfd1 Bd7',
    'Qb4 Bc6',
    'Rac1 Nd7',
    'Bxg7 Kxg7',
    'e4 Qb6',
    'Qc3+ Kg8',
    'Ne5 Rac8',
    'Nec4 Nxc4',
    'Nxc4 Qc5',
    'Na5 Qxc3',
    'Rxc3 Nb8',
    'Nxc6 Rxc6',
    'Rxc6 Nxc6',
    'Rd7 Rb8',
    'f4 Kf8',
    'e5 Na5',
    'b4 Nc6',
    'a3 a5',
    'b5 Nd8',
    'b6 Ke8',
    'Rc7 Ra8',
    'a4 Ra6',
    'Bf1 Rxb6',
    'Bb5+ Nc6',
    'Rc8+ Kd7',
    'Ra8 Kc7',
    'Kf2 Nd4',
    'Rxa5 Kb8',
    'Bd7 e6',
    'Rc5 Rb2+',
    'Ke3 Nf5+',
    'Kd3 Rxh2',
    'Rc8+ Ka7',
    'Rf8 Nh6',
    'Rh8 Ng4',
    'Be8 f6',
    'Bxg6 Rg2',
    'Bh5 Rxg3+',
    'Kd4 fxe5+',
    'fxe5 Nxe5',
    'Rxh7 Nc6+',
    'Kc5 Rd3',
    'Be8 Ka6',
    'Rh6 Rd5+',
    'Kc4 Re5',
    'Bd7 Nd8',
    'Rh8 Nf7',
    'Rf8 Nd6+',
    'Kb4 Ne4',
    'Re8 Nc5',
    'Bb5+ Kb6',
    'a5+ Kc7',
    'Kc4 Ne4',
    'Re7+ Kd8',
    'Re8+ Kc7',
    'Re7+ Kb8',
    'Re8+ Ka7',
    'Rd8 Rc5+',
    'Kb4 Rc1',
    'Bc4 Rb1+',
    'Ka3 Nc5',
    'Re8 Re1',
    'Kb4 Re5',
    'Kc3 Re4',
    'Rc8 Na6',
    'Kd3 Re5',
    'Kd4 Re1',
    'Re8 Nc7',
    'Re7 Kb8',
    'Kc5 Kc8',
    'Rh7 Kb8',
    'Rh8+ Ka7',
    'Rc8 Na8',
    'Kd6 e5',
    'Bd5 Ra1',
    'Rc5 Rb1',
    'Kxe5 Kb8',
    'Kd6 Rg1',
    'Rb5 Rg6+',
    'Kc5 b6+',
    'axb6 Nxb6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Qb6',
    'Qd2 Nc6',
    'O-O-O Qxd4',
    'Qxd4 Nxd4',
    'Rxd4 h6',
    'Bh4 Be7',
    'Be2 b5',
    'Bf3 Rb8',
    'Bg3 Nd7',
    'Rhd1 O-O',
    'Kb1 Re8',
    'a4 b4',
    'Na2 a5',
    'Nc1 Nb6',
    'b3 Bb7',
    'Ne2 Rbc8',
    'R4d2 d5',
    'e5 Bc5',
    'Nd4 Ba6',
    'Bf2 Nd7',
    'Re1 Rc7',
    'Kb2 g6',
    'h4 Bf8',
    'h5 Nc5',
    'hxg6 fxg6',
    'g3 h5',
    'Be3 Rh7',
    'Rh1 Ne4',
    'Rg2 Bc5',
    'Re1 Bxd4+',
    'Bxd4 Rc8',
    'Bxe4 dxe4',
    'Bb6 Rb7',
    'Bxa5 Rbb8',
    'Rd2 Rc3',
    'Rd6 Ra8',
    'Rg1 Bd3',
    'cxd3 Rxa5',
    'dxe4 Rac5',
    'Rg2 Re3',
    'Rd4 Rcc3',
    'Rxb4 Rxg3',
    'Rxg3 Rxg3',
    'a5 Kg7',
    'Ka3 Rg1',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'c3 Nf6',
    'Be2 Bd7',
    'Qc2 Bc6',
    'd3 Nbd7',
    'O-O e6',
    'c4 a6',
    'Nc3 e5',
    'a3 Be7',
    'b4 b6',
    'Be3 O-O',
    'Nd2 Ne8',
    'Bg4 Nc7',
    'Nd5 Ne6',
    'Rab1 g6',
    'Bh6 Re8',
    'Bxe6 fxe6',
    'Nxe7+ Qxe7',
    'f4 Qh4',
    'Bg5 Qh5',
    'Rf3 exf4',
    'Bxf4 e5',
    'Be3 Rf8',
    'Rbf1 Rxf3',
    'Nxf3 h6',
    'b5 Bb7',
    'bxa6 Rxa6',
    'Qd2 g5',
    'h4 gxh4',
    'Bxh6 Rxa3',
    'Bg5 h3',
    'Nh4 Nf8',
    'Qf2 Ne6',
    'Qf6 Bc8',
    'Ng6 Ra7',
    'g3 h2+',
    'Kh1 Qh3',
    'Bh4 Nf4',
    'Qh8+ Kf7',
    'Qf6+ Kg8',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 a6',
    'Qc2 Bg4',
    'Ne5 Bh5',
    'cxd5 cxd5',
    'Qb3 Ra7',
    'Bd2 e6',
    'Nc3 Be7',
    'Bd3 O-O',
    'O-O Nbd7',
    'Nxd7 Nxd7',
    'Rfc1 Bd6',
    'Bf1 Qe7',
    'e4 b5',
    'e5 Bc7',
    'Ne2 Bxe2',
    'Bxe2 Rb8',
    'Qd3 Bb6',
    'Be3 Qe8',
    'Rc2 Rc8',
    'Rac1 Rxc2',
    'Rxc2 Nb8',
    'Qb3 Rc7',
    'Rxc7 Bxc7',
    'a4 bxa4',
    'Qb7 Ba5',
    'g3 h6',
    'h4 Qf8',
    'Bd1 Qb4',
    'Qc8+ Qf8',
    'Qb7 Qb4',
    'Qxb4 Bxb4',
    'Bxa4 Kf8',
    'h5 a5',
    'Bb5 Ke7',
    'g4 Nd7',
    'b3 Nf8',
    'Bd3 Bc3',
    'f4 f6',
    'Kf2 Kf7',
    'Ke2 Ke7',
    'Ba6 Nd7',
    'Kd3 Bb4',
    'Bb5 f5',
    'Bf2 Nf8',
    'Bh4+ Kf7',
    'gxf5 exf5',
    'Bc6 Ke6',
    'Bb7 g6',
    'Bc8+ Kf7',
    'e6+ Ke8',
    'Bd7+ Nxd7',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Nc3 Qc7',
    'Nb3 Nf6',
    'Bd3 a6',
    'O-O d6',
    'f4 b5',
    'Qf3 Bb7',
    'Bd2 Be7',
    'Rae1 b4',
    'Na4 O-O',
    'Be3 Rae8',
    'Qh3 e5',
    'Nb6 Nb8',
    'Nd2 Bd8',
    'Nbc4 Nbd7',
    'b3 Nc5',
    'fxe5 dxe5',
    'Bf2 Bc8',
    'Qf3 Be7',
    'h3 Ne6',
    'Bg3 Bc5+',
    'Kh1 Nd7',
    'Ne3 Bxe3',
    'Qxe3 Ndc5',
    'Bc4 f6',
    'Bd5 Kh8',
    'Nc4 Rd8',
    'a3 bxa3',
    'b4 Na4',
    'Qxa3 Nb6',
    'Ne3 Nf4',
    'c4 Nfxd5',
    'exd5 f5',
    'Bh4 Rde8',
    'c5 Nd7',
    'Nxf5 Nxc5',
    'Nxg7 Qxg7',
    'bxc5 e4',
    'Rxf8+ Qxf8',
    'd6 Qh6',
    'Qc3+ Kg8',
  ],
  [
    'd4 e6',
    'e4 d5',
    'Nc3 Nf6',
    'Bg5 dxe4',
    'Nxe4 Be7',
    'Bxf6 Bxf6',
    'Nf3 Nd7',
    'Qd2 b6',
    'O-O-O Bb7',
    'Bd3 O-O',
    'Qf4 c5',
    'Ne5 Qc7',
    'Nxf6+ Nxf6',
    'Rhe1 cxd4',
    'Qxd4 Rfd8',
    'Qh4 Rac8',
    'c3 h6',
    'Qg3 b5',
    'Bc2 Rxd1+',
    'Rxd1 Bd5',
    'a3 a5',
    'Rd4 Rb8',
    'Rh4 h5',
    'Nd7 Qxg3',
    'Nxf6+ gxf6',
    'hxg3 Bxg2',
    'Rxh5 f5',
    'Rh4 Rd8',
    'a4 Bc6',
    'g4 fxg4',
    'Rxg4+ Kf8',
    'Rg5 Rd5',
    'Rxd5 exd5',
    'axb5 Bxb5',
    'Kd2 Ke7',
    'Ke3 Kd6',
    'Kd4 Bc6',
    'Bd1 Bd7',
    'Be2 f6',
    'Bd1 Be6',
    'Bb3 Bf7',
    'f4 Be6',
    'Ba4 Bc8',
    'Bb5 Bb7',
    'Be8 Ba6',
    'b3 Be2',
    'b4 axb4',
    'cxb4 Bc4',
    'Bg6 Ba6',
    'Bd3 Bc8',
    'b5 Bg4',
    'Bg6 Bd7',
    'Bd3 Bg4',
    'Bf1 Bf5',
    'Bg2 Bd7',
    'b6 Kc6',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Na3 b5',
    'Nd5 Be7',
    'Bxf6 Bxf6',
    'c3 Ne7',
    'Nxf6+ gxf6',
    'Bd3 d5',
    'Qe2 Bb7',
    'O-O-O Qb6',
    'exd5 Nxd5',
    'Be4 Nf4',
    'Qf3 Bxe4',
    'Qxe4 O-O',
    'Nc2 Qe6',
    'Ne3 Qxa2',
    'g3 b4',
    'gxf4 bxc3',
    'Qb1 cxb2+',
    'Kd2 Rad8+',
    'Ke2 Qxb1',
    'Rxb1 exf4',
    'Rhg1+ Kh8',
    'Nd1 Rd4',
    'Nxb2 f3+',
    'Kxf3 Rb8',
    'Rbe1 Rb3+',
    'Re3 Rxe3+',
    'fxe3 Rd2',
    'Nc4 Rxh2',
    'Nd6 h5',
    'Ne8 Kh7',
    'Rg7+ Kh6',
    'Rxf7 Ra2',
    'Rxf6+ Kg5',
    'Rc6 Ra1',
    'Nd6 Rf1+',
    'Kg2 Ra1',
    'Ne4+ Kg4',
    'Rc5 Ra2+',
    'Nf2+ Kh4',
    'Kf3 Ra1',
    'Kf4 a5',
    'Rc2 a4',
    'e4 Rb1',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 O-O',
    'Ne2 d5',
    'a3 Bd6',
    'c5 Be7',
    'b4 b6',
    'Nf4 bxc5',
    'bxc5 c6',
    'Be2 Qc7',
    'Nd3 Ba6',
    'O-O Nbd7',
    'Rb1 Bc4',
    'Bd2 Rfb8',
    'Rxb8+ Rxb8',
    'Nb4 Bxe2',
    'Qxe2 Rb7',
    'f3 a5',
    'Nd3 Rb3',
    'Qd1 Qb7',
    'Qc2 Bd8',
    'a4 Nb8',
    'e4 Bc7',
    'e5 Nfd7',
    'f4 g6',
    'Rc1 Qa6',
    'Nb5 Rxb5',
    'axb5 cxb5',
    'c6 Nb6',
    'f5 Nc4',
    'fxe6 fxe6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Bd7',
    'Qe2 Nc6',
    'O-O-O Nxd4',
    'Rxd4 Qb6',
    'Qd2 Bc6',
    'Bc4 Be7',
    'e5 dxe5',
    'fxe5 Bc5',
    'Rd3 Ne4',
    'Nxe4 Bxe4',
    'Rb3 Qc6',
    'Rd1 O-O',
    'Bd3 Bg6',
    'Bxg6 fxg6',
    'Be3 Rac8',
    'a3 Be7',
    'Rd3 Qe4',
    'Rd7 Rfd8',
    'Kb1 Qxe5',
    'Bb6 Bf6',
    'c3 Re8',
    'Bd4 Qe4+',
    'Qd3 Qxd3+',
    'Rxd3 e5',
    'Be3 Rcd8',
    'Kc2 b5',
    'Rxd8 Rxd8',
    'Rxd8+ Bxd8',
    'Kd3 Kf7',
    'c4 bxc4+',
    'Kxc4 Ke6',
    'b4 Bc7',
    'Kc5 e4',
    'h3 Be5',
    'g4 h6',
    'a4 g5',
    'b5 axb5',
    'axb5 g6',
    'Kc6 Bd6',
    'b6 Be5',
    'b7 Bd6',
    'Ba7 Bf4',
    'Be3 Bd6',
    'Bd2 Bg3',
    'Ba5 Bb8',
    'Bb6 h5',
    'Bd4 hxg4',
    'hxg4 Bh2',
    'Be3 Bd6',
    'Bxg5 Be5',
    'Be3 Bd6',
    'Bb6 Bb8',
    'Bc7 Ba7',
    'Bf4 Ke7',
    'Kb5 e3',
    'Bxe3 Bb8',
    'Kc6 Ke6',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 e6',
    'e3 Nbd7',
    'Qc2 Bd6',
    'g4 Bb4',
    'Bd2 Qe7',
    'Rg1 Bxc3',
    'Bxc3 Ne4',
    'O-O-O O-O',
    'Bd3 Nxc3',
    'Qxc3 dxc4',
    'Qxc4 e5',
    'Bf5 exd4',
    'Qxd4 Qc5+',
    'Kb1 Qxd4',
    'Rxd4 Nb6',
    'Bc2 g6',
    'h3 f5',
    'Rg3 Kg7',
    'a4 c5',
    'Rf4 Nd5',
    'Rc4 fxg4',
    'hxg4 b6',
    'Re4 Bb7',
    'Re5 Rae8',
    'Rxe8 Rxe8',
    'Nd2 Nb4',
    'f4 Rd8',
    'Kc1 a6',
    'Bb1 Bc6',
    'b3 Nd5',
    'Ne4 Re8',
    'f5 Re5',
    'Kd2 h6',
    'a5 Nb4',
    'Nc3 bxa5',
    'e4 Kf6',
    'Rf3 Be8',
    'Ne2 Kg5',
    'Rf1 Bf7',
    'Nf4 gxf5',
    'gxf5 c4',
    'Ng6 Rc5',
    'Rg1+ Kf6',
    'e5+ Rxe5',
    'Nxe5 Kxe5',
    'bxc4 Bxc4',
    'Rh1 Bd5',
    'Rxh6 Nc6',
    'Rh5 Ne7',
    'Kc3 Bf3',
    'Rh3 Nd5+',
    'Kc4 Nb6+',
    'Kb3 Bd1+',
    'Kb2 Nd5',
    'Rh1 Bg4',
    'Re1+ Kf4',
    'Re4+ Kg5',
    'Re6 Kf4',
    'Re4+ Kg5',
    'Re5 Nb6',
    'Kc3 a4',
    'Re6 Nd5+',
    'Kd4 Nf4',
    'Re5 Ne2+',
    'Kc5 a3',
    'Kd6 Nd4',
    'Ra5 Kf6',
    'Rxa6 Bxf5',
    'Ba2 Bd3',
    'Ra7 Nf5+',
    'Kd5 Ne7+',
    'Kd4 Bf5',
    'Ra6+ Kg5',
    'Bc4 Ng6',
    'Rxa3 Kf4',
    'Bd5 Ne7',
    'Bb7 Kg5',
    'Rg3+ Kf6',
    'Rf3 Ke6',
    'Rf1 Kf6',
    'Kc5 Kg5',
    'Kd6 Ng6',
    'Bc6 Kg4',
    'Rg1+ Kf4',
    'Rf1+ Kg5',
    'Rg1+ Kf4',
    'Be8 Nh4',
    'Bh5 Nf3',
    'Rg8 Be4',
    'Rb8 Ke3',
    'Rb4 Nd4',
    'Ra4 Nf3',
    'Kc5 Ne5',
    'Ra1 Nf3',
    'Kc4 Kf4',
    'Kc3 Nh4',
    'Kd2 Nf5',
    'Bg6 Ke5',
    'Ra4 Nd6',
    'Bh5 Nf5',
    'Bg4 Kf4',
    'Bh3 Ke5',
    'Ke2 Ne7',
    'Ra6 Nd5',
    'Kd2 Kd4',
    'Ra5 Ke5',
    'Bf1 Kd4',
    'Ra4+ Ke5',
    'Be2 Nf4',
    'Bc4 Nd5',
    'Ba2 Ne7',
    'Ra6 Nd5',
    'Rh6 Kd4',
    'Bb3 Ke5',
    'Rh4 Ne7',
    'Bc4 Nf5',
    'Rg4 Ne7',
    'Bd3 Bxd3',
    'Kxd3 Nf5',
    'Ra4 Ng3',
    'Ra8 Nf5',
    'Ra4 Ng3',
    'Rg4 Nf5',
    'Re4+ Kd5',
    'Re1 Nd6',
    'Ra1 Ke5',
    'Ra5+ Kf4',
    'Rc5 Nf5',
    'Rd5 Ng3',
    'Ra5 Nf5',
    'Kc4 Ke4',
    'Ra4 Ke5',
    'Kc5 Ng3',
    'Ra5 Ne4+',
    'Kc6+ Kd4',
    'Ra4+ Ke5',
    'Ra5+ Kd4',
    'Rh5 Nf6',
    'Rh1 Ne4',
    'Kd7 Ke5',
    'Rh5+ Kd4',
    'Ra5 Nc5+',
    'Kc7 Ne4',
    'Kc6 Nf6',
    'Rf5 Ne4',
    'Rd5+ Kc4',
    'Rd8 Nc3',
    'Ra8 Kd4',
    'Kd6 Nb5+',
    'Kd7 Nc3',
    'Ke6 Kc4',
    'Rc8+ Kd4',
    'Rd8+ Ke4',
    'Rh8 Kd4',
    'Rh4+ Kc5',
    'Rg4 Nb5',
    'Rg8 Nd4+',
    'Ke5 Nc6+',
    'Ke4 Kd6',
    'Rg5 Nb4',
    'Rh5 Nc6',
    'Rb5 Ne7',
    'Ra5 Nc6',
    'Rd5+ Ke6',
    'Rd1 Ne7',
    'Kd4 Nf5+',
    'Kc5 Ke5',
    'Re1+ Kf4',
    'Re8 Ne3',
    'Kd4 Nf5+',
    'Kd3 Nd6',
    'Re6 Nf5',
    'Re4+ Kf3',
    'Ra4 Ng3',
    'Kd4 Kf4',
    'Kd5+ Kf3',
    'Ke5 Ke2',
    'Kf4 Nf1',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Nf6',
    'O-O Qc7',
    'Qe2 d6',
    'c4 g6',
    'Nc3 Bg7',
    'Rd1 O-O',
    'Nf3 Nc6',
    'h3 Nd7',
    'Be3 Bxc3',
    'bxc3 b6',
    'Qd2 Nc5',
    'Bc2 Na5',
    'Qxd6 Qxd6',
    'Rxd6 Nxc4',
    'Rxb6 Nxe3',
    'fxe3 Bb7',
    'Nd2 Rac8',
    'Kf2 Rfd8',
    'Ke2 Rc7',
    'Rb4 a5',
    'Rd4 Rdc8',
    'Rb1 Ba6+',
    'c4 Nd7',
    'Ba4 Nc5',
    'Bc2 Kf8',
    'Rb6 Ke7',
    'Bd3 Bb7',
    'Nf3 Nd7',
    'Rb5 f6',
    'Bc2 Ba6',
    'Rxa5 Bb7',
    'Bb3 e5',
    'Rd1 Bxe4',
    'g3 h5',
    'h4 Bf5',
    'Kf2 Nc5',
    'Ne1 Ne4+',
    'Kg2 Nd6',
    'Rc1 Be4+',
    'Kf2 Bb7',
    'Kg1 Ne4',
    'Kh2 Rd8',
    'Rd1 Nd2',
    'Ra7 Nf1+',
    'Kg1 Nxe3',
    'Rxd8 Kxd8',
    'c5 Nf5',
    'Kf2 Kc8',
    'Ra5 Kb8',
    'Rb5 Ka7',
    'Ra5+ Kb8',
    'Rb5 Ne7',
    'Rb6 Rxc5',
    'Rxf6 Rc3',
    'Re6 Nf5',
    'Rxe5 Nxg3',
    'Bc2 Nh1+',
  ],
  [
    'd4 Nf6',
    'Bg5 c5',
    'd5 Qb6',
    'Nc3 Qxb2',
    'Bd2 Qb6',
    'e4 e5',
    'f4 d6',
    'Nf3 Nbd7',
    'fxe5 dxe5',
    'Bc4 Qd8',
    'Rb1 Bd6',
    'O-O O-O',
    'Bg5 h6',
    'Bh4 a6',
    'a4 Qa5',
    'Be1 Qd8',
    'Nh4 Ne8',
    'Nf5 Ndf6',
    'Ne3 Be7',
    'Bg3 Nd6',
    'Qd3 Nd7',
    'Nf5 Kh7',
    'Rf3 g6',
    'Nxd6 Bxd6',
    'Bf2 Nf6',
    'h3 Nh5',
    'Be3 Rb8',
    'Rbf1 f6',
    'Qd2 g5',
    'h4 g4',
    'R3f2 f5',
    'exf5 g3',
    'Rf3 Qxh4',
    'Re1 Qxc4',
    'Bxh6 Bxf5',
    'Bxf8 Rxf8',
    'Qg5 Bg6',
    'Re4 Rf5',
    'Qxf5 Bxf5',
    'Rxc4 Nf4',
    'Ne4 Bf8',
    'Nxg3 Bd7',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'O-O d5',
    'c4 dxc4',
    'Na3 c3',
    'bxc3 c5',
    'e3 Nc6',
    'Qe2 Bf5',
    'Rd1 cxd4',
    'cxd4 Rc8',
    'Bb2 Nb4',
    'Ne1 Bg4',
    'Bf3 Bxf3',
    'Nxf3 Qd5',
    'Ne5 Nxa2',
    'Nac4 b5',
    'Rxa2 bxc4',
    'Rda1 c3',
    'Bc1 Ne4',
    'Ra5 Qe6',
    'Nd3 Nd6',
    'Rxa7 Nb5',
    'Nf4 Qc6',
    'R7a5 Rb8',
    'Nd3 Rfc8',
    'Qc2 Qb6',
    'Nc5 Qd8',
    'Qa4 Nc7',
    'Nd7 Ra8',
    'Nb6 Rxa5',
    'Qxa5 Rb8',
    'Nc4 Nd5',
    'Qxd8+ Rxd8',
    'Kf1 c2',
    'Bd2 Rc8',
    'Na3 e5',
    'dxe5 Bf8',
    'Bc1 Bb4',
    'Ra2 Ra8',
    'Rxc2 Bxa3',
    'Ra2 Bxc1',
    'Rxa8+ Kg7',
    'Ra5 Nc3',
    'Ra7 Kg8',
    'Ke1 Bb2',
    'Ra5 Ne4',
    'Ke2 Ng5',
    'f4 Ne4',
    'Ra8+ Kg7',
    'Kd3 Nf2+',
    'Kc2 Bxe5',
    'fxe5 Ng4',
    'Ra5 Nxe3+',
    'Kd3 Ng4',
    'h4 h5',
    'Ke4 Nh6',
    'Ra7 Nf5',
    'e6 Kf6',
    'Rxf7+ Kxe6',
    'Rf8 Nd6+',
    'Kf4 Nf7',
    'Ra8 Kf6',
    'Ra6+ Kg7',
    'Ke4 Nh6',
    'Ke5 Ng4+',
    'Ke6 Kg8',
    'Ra7 Kf8',
    'Kd5 Kg8',
    'Ke4 Nf6+',
    'Ke5 Nh7',
    'Ke6 g5',
  ],
  [
    'c4 e6',
    'Nc3 d5',
    'd4 Nf6',
    'Nf3 Be7',
    'Bf4 O-O',
    'e3 Nbd7',
    'c5 c6',
    'Bd3 b6',
    'b4 a5',
    'a3 Ba6',
    'O-O Qc8',
    'h3 Qb7',
    'Qc2 Bxd3',
    'Qxd3 Ra7',
    'Nd2 Rfa8',
    'Rab1 axb4',
    'axb4 Ra3',
    'Qc2 Bd8',
    'Nb3 Bc7',
    'Bxc7 Qxc7',
    'b5 Rc8',
    'Rfc1 Raa8',
    'Qe2 Qd8',
    'bxc6 Rxc6',
    'Qb5 Rcc8',
    'c6 Nb8',
    'Na4 Ra6',
    'Nd2 Qc7',
    'Nxb6 Rd8',
    'Nf3 Ne4',
    'Ne5 Nd6',
    'Qb3 f6',
    'Nd3 Ne4',
    'Nf4 Nd2',
    'Nxe6 Nxb3',
    'Nxc7 Rxb6',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Bb5 Qc7',
    'Nf3 Nf6',
    'O-O Nd4',
    'Re1 a6',
    'Bf1 Ng4',
    'g3 Nxf3+',
    'Qxf3 Ne5',
    'Qe2 e6',
    'Bg2 d6',
    'b3 Be7',
    'Bb2 Nc6',
    'Nd1 Nd4',
    'Qd3 Bf6',
    'c3 Nc6',
    'Ne3 O-O',
    'Ng4 Be7',
    'Qe2 Bd7',
    'Red1 a5',
    'd4 a4',
    'Rab1 axb3',
    'axb3 Ra2',
    'Ne3 cxd4',
    'cxd4 Bf6',
    'e5 dxe5',
    'dxe5 Be7',
    'Nc4 Nb4',
    'Nd6 Bc6',
    'Qc4 Nd5',
    'Bd4 Bxd6',
    'exd6 Qd7',
    'Bc5 Rc8',
    'Qd4 Nf6',
    'Bxc6 Rxc6',
    'Ra1 Rxa1',
    'Rxa1 Rc8',
    'Ra7 Qc6',
    'Ba3 h5',
    'Qb4 b5',
    'Qd4 Rd8',
    'Rc7 Qf3',
    'd7 e5',
    'Qxe5 Qd1+',
    'Kg2 Rxd7',
    'Rc5 h4',
    'Qf5 Rd8',
    'Bb2 Ng4',
    'Rc8 Rxc8',
    'Qxc8+ Kh7',
    'Qf5+ Kg8',
    'h3 Qxb3',
    'Bxg7 Qxg3+',
    'fxg3 Ne3+',
    'Kf3 Nxf5',
    'Bc3 hxg3',
    'Bb4 Kg7',
    'Bc5 Kg6',
    'Kg4 g2',
    'Kf3 b4',
    'Kxg2 b3',
    'Ba3 Ne3+',
    'Kf3 Nc4',
    'Bc1 Kf5',
    'h4 b2',
    'Bxb2 Nxb2',
    'h5 Kg5',
    'Ke4 Kxh5',
    'Kf5 Nc4',
  ],
  [
    'c4 Nf6',
    'Nc3 e5',
    'g3 Bb4',
    'Qb3 Nc6',
    'Nd5 Bc5',
    'Bg2 Nxd5',
    'cxd5 Qf6',
    'e3 Ne7',
    'Ne2 c6',
    'O-O O-O',
    'f4 Bb6',
    'fxe5 Qxe5',
    'd4 Qh5',
    'Nf4 Qh6',
    'a4 d6',
    'a5 Bd8',
    'Bd2 g5',
    'dxc6 bxc6',
    'Nd3 Bh3',
    'Nf2 Bxg2',
    'Kxg2 Qe6',
    'Qxe6 fxe6',
    'Ne4 Nf5',
    'Rac1 Rc8',
    'h4 Bxa5',
    'Bxa5 Nxe3+',
    'Kg1 Rxf1+',
    'Rxf1 Nxf1',
    'Kxf1 gxh4',
    'Nxd6 Rb8',
    'Bc3 hxg3',
    'Kg2 Kf8',
    'Kxg3 Ke7',
    'Ne4 Rg8+',
    'Kf3 Rg1',
    'Bb4+ Kf7',
    'Bc5 a5',
    'd5 Rf1+',
    'Kg2 Rb1',
    'Nd6+ Ke7',
    'Nc4+ Kd7',
    'd6 e5',
    'Ba3 Ke6',
    'Nxa5 c5',
    'Nb7 h5',
    'Kg3 Kd7',
    'Kh4 Kc6',
    'Nxc5 Rh1+',
    'Kg5 Kxd6',
    'Ne4+ Kd5',
    'Nf6+ Kd4',
    'Nxh5 Kd3',
    'Bc5 e4',
    'Nf4+ Kc4',
    'b4 e3',
    'Kg4 Rf1',
    'Kg3 Rxf4',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Bb5 Nf6',
    'e5 Nd5',
    'O-O g6',
    'Nc3 Nc7',
    'Bxc6 dxc6',
    'd3 Bg7',
    'h3 O-O',
    'Ne4 Ne6',
    'Re1 b6',
    'a4 a5',
    'Be3 f5',
    'exf6 exf6',
    'Qc1 Ra7',
    'Bh6 Re7',
    'Bxg7 Kxg7',
    'Qd2 Rfe8',
    'b3 Nd4',
    'Nxd4 cxd4',
    'Kf1 c5',
    'Qf4 Re5',
    'Nd2 Bb7',
    'Rxe5 Rxe5',
    'Nc4 Re6',
    'f3 Bd5',
    'Re1 Bxc4',
    'dxc4 Rd6',
    'Kg1 d3',
    'cxd3 Rxd3',
    'Re3 Rd1+',
    'Kh2 g5',
    'Qg3 Kf7',
    'f4 Qd6',
    'fxg5 fxg5',
    'Qxd6 Rxd6',
    'g4 Re6',
    'Rd3 Re2+',
    'Kg3 Ke6',
    'Kf3 Rb2',
    'Re3+ Kd6',
    'Kg3 Rb1',
    'Kg2 Rb2+',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Bc4 Nf6',
    'e5 Ne4',
    'Qe2 Nc5',
    'c3 dxc3',
    'Nxc3 Be7',
    'Be3 O-O',
    'O-O-O Ne6',
    'Ne4 Qe8',
    'h4 d6',
    'exd6 cxd6',
    'Nxd6 Bxd6',
    'Rxd6 Na5',
    'Bd3 Qa4',
    'Kb1 Nf4',
    'Qc2 Qxc2+',
    'Bxc2 Ne6',
    'b3 b6',
    'Ng5 h6',
    'Nxe6 Bxe6',
    'Be4 Rac8',
    'f3 Rfe8',
    'Bf4 Re7',
    'Rc1 Rce8',
    'b4 Nb7',
    'Rdc6 f5',
    'Bd3 Kf7',
    'Rc7 a5',
    'Bb5 Rd8',
    'Rxe7+ Kxe7',
    'Rc7+ Kf6',
    'Rxb7 Rd4',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Nc3 Qc7',
    'Be3 Nf6',
    'Qd2 Bb4',
    'f3 d5',
    'exd5 Nxd5',
    'a3 Bxc3',
    'bxc3 O-O',
    'c4 Nxe3',
    'Qxe3 Qc5',
    'Kf2 Nd7',
    'Nb3 Qc7',
    'Bd3 Ne5',
    'Rae1 f6',
    'h4 Bd7',
    'Nc5 Rad8',
    'h5 Bc8',
    'Rh4 Rfe8',
    'h6 g6',
    'Ne4 Qe7',
    'Rf4 Nd7',
    'c5 Rf8',
    'Bc4 Kh8',
    'Bb3 e5',
    'Nd6 Nxc5',
    'Nf7+ Rxf7',
    'Bxf7 Rf8',
    'Bb3 b6',
    'Rc4 Qd6',
    'Rc3 Bb7',
    'Bc4 Rd8',
    'Re2 g5',
    'Bd3 Kg8',
    'Rd2 Kf8',
    'Ke2 Ne6',
    'Bxh7 Nd4+',
    'Kf1 Bc8',
    'Be4 Bd7',
    'Rcd3 Bb5',
    'Kg1 Bxd3',
    'Rxd3 Qc7',
    'c3 Ne6',
    'Rxd8+ Qxd8',
    'Bd3 Nc5',
    'Bc4 Qd6',
    'a4 a5',
    'Bb5 Kg8',
    'Qe2 Kh7',
    'Qc4 Qe7',
    'Qd5 Kxh6',
    'Qc6 Qd8',
    'Kh2 Kg7',
    'Bc4 Kf8',
    'Qb5 Qd6',
    'Qb1 e4+',
    'Kg1 f5',
    'fxe4 Nxe4',
    'Bb5 Qc5+',
    'Kh2 Qe5+',
    'Kg1 Nxc3',
    'Qd3 Nd5',
    'Kh1 g4',
    'Qc4 Kg7',
    'Qc8 Qe1+',
    'Kh2 g3+',
    'Kh3 Nf4+',
    'Kh4 Nxg2+',
    'Kg5 Qe3+',
    'Kxf5 Qf4+',
    'Ke6 Qg4+',
  ],
  [
    'c4 c5',
    'Nc3 Nf6',
    'g3 g6',
    'Bg2 Bg7',
    'Nf3 O-O',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Nc2 d6',
    'O-O Bd7',
    'b3 a6',
    'Bb2 Rb8',
    'Ne3 b5',
    'Rc1 bxc4',
    'Nxc4 Na5',
    'Ne3 Bc6',
    'Qd2 Bxg2',
    'Kxg2 Nc6',
    'Ncd5 Nxd5',
    'Nxd5 Bxb2',
    'Rxc6 Bg7',
    'Rxa6 Kh8',
    'e4 Qd7',
    'Rc1 e6',
    'Ne3 Qb7',
    'Rac6 Ra8',
    'Kg1 h5',
    'h4 Kg8',
    'Qc2 Bd4',
    'Rc4 Bxe3',
    'fxe3 Qa7',
    'a4 Qxe3+',
    'Kg2 Rfb8',
    'Rc3 Qd4',
    'Qe2 Qe5',
    'Qf3 d5',
    'exd5 exd5',
    'Rd1 Ra5',
    'Rf1 Ra7',
    'Qf4 Qxf4',
    'Rxf4 Rab7',
    'Rff3 d4',
    'Rcd3 Rb4',
    'Kf2 Rc8',
    'Ke2 Rc2+',
    'Kd1 Rc7',
    'Kd2 Kg7',
    'Rf4 Rd7',
    'Kc2 f6',
    'Kb2 g5',
    'hxg5 fxg5',
    'Re4 Kf6',
    'Ka3 Kf5',
    'Re1 Rb8',
    'b4 h4',
    'gxh4 gxh4',
    'b5 Rh8',
    'Rh1 Ke4',
    'Rhh3 Rc7',
    'b6 Rc1',
    'Rb3 Kd5',
    'a5 Kc6',
    'Rhf3 Rc3',
    'Rf6+ Kb7',
    'Rf7+ Kb8',
    'a6 Rxb3+',
    'Kxb3 h3',
    'Rb7+ Ka8',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Bg5 c5',
    'd5 e6',
    'Qd2 exd5',
    'cxd5 h6',
    'Be3 a6',
    'a4 h5',
    'Nge2 Nbd7',
    'Nd1 Nh7',
    'Nec3 f5',
    'Be2 Be5',
    'exf5 gxf5',
    'Bf4 Bxf4',
    'Qxf4 Qf6',
    'Nf2 Ne5',
    'O-O Bd7',
    'Ra3 Rae8',
    'Rb3 Bc8',
    'Rb6 Qd8',
    'a5 Rf6',
    'Rc1 Qc7',
    'b4 Nd7',
    'bxc5 Nxb6',
    'axb6 Qd8',
    'c6 Qxb6',
    'Rb1 Rxe2',
    'Qg3+ Kh8',
    'h4 Rb2',
    'Re1 f4',
    'Re8+ Nf8',
  ],
  [
    'd4 Nf6',
    'Bg5 e6',
    'e4 h6',
    'Bxf6 Qxf6',
    'Nc3 Bb4',
    'Qd2 b6',
    'Nge2 Bb7',
    'a3 Be7',
    'g3 Qf3',
    'Rg1 Bg5',
    'Nf4 Bxf4',
    'gxf4 Rg8',
    'Rg3 Qh5',
    'Rh3 Qg6',
    'O-O-O d6',
    'd5 Nd7',
    'Nb5 Kd8',
    'Qc3 Nc5',
    'b4 Qxe4',
    'bxc5 Qxf4+',
    'Re3 Bxd5',
    'cxb6 c6',
    'Rxd5 exd5',
    'b7 Kd7',
    'bxa8=Q Rxa8',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 dxc4',
    'a4 Bf5',
    'Ne5 Nbd7',
    'Nxc4 Nb6',
    'Ne5 a5',
    'f3 Nfd7',
    'Nxd7 Nxd7',
    'e4 Bg6',
    'Be3 e5',
    'dxe5 Nxe5',
    'f4 Qxd1+',
    'Rxd1 Nd7',
    'Be2 f6',
    'Bg4 Rd8',
    'O-O Be7',
    'Rd2 h5',
    'Bh3 Bc5',
    'Re1 Rf8',
    'Bf5 Bxf5',
    'exf5 Be7',
    'Ne4 Rf7',
    'Red1 Bb4',
    'Rd4 Re7',
    'Bd2 Bxd2',
    'R1xd2 Rb8',
    'b4 axb4',
    'Rxb4 Kf8',
    'a5 c5',
    'Rc4 b5',
    'Nxc5 bxc4',
    'Nxd7+ Rxd7',
    'Rxd7 c3',
    'Rc7 Ra8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f3 e5',
    'Nb3 Be6',
    'Be3 Nbd7',
    'Qd2 h5',
    'a4 Be7',
    'Be2 g6',
    'a5 h4',
    'O-O Kf8',
    'Nd5 Bxd5',
    'exd5 Rc8',
    'c4 Kg7',
    'Rac1 Nh5',
    'Rfd1 Nf4',
    'Bf1 Bg5',
    'Na1 Nf6',
    'b4 N6h5',
    'c5 Ng3',
    'c6 h3',
    'hxg3 h2+',
    'Kh1 Nh5',
    'Qe1 Bxe3',
    'g4 Bxc1',
    'gxh5 Bb2',
    'Nb3 b5',
    'Qd2 Bd4',
    'Nxd4 exd4',
    'hxg6 fxg6',
    'Qxd4+ Kf7',
    'Bd3 Re8',
    'Be4 Qf6',
    'Qb6 Rb8',
    'Qa7+ Qe7',
    'Qe3 Qf6',
    'Rc1 Re7',
    'Qh6 Qg7',
    'Qh3 Rh8',
    'Qg4 Qh6',
    'Rc2 Qg7',
    'Rc1 Qh6',
    'Re1 g5',
    'Rc1 Rc7',
    'Bf5 Qh4',
    'Bd7 Qxg4',
    'Bxg4 Ke7',
    'Re1+ Kd8',
    'Re6 Rf7',
    'Rxd6+ Kc7',
    'Rg6 Rf4',
    'Rg7+ Kd6',
    'Rd7+ Ke5',
    'c7 Rc4',
    'Bh3 Rh6',
    'Kxh2 g4',
    'fxg4 Rd6',
    'Rf7 Kxd5',
    'g5 Rdc6',
  ],
  [
    'c4 Nf6',
    'Nf3 e6',
    'Nc3 Bb4',
    'g4 Bxc3',
    'bxc3 d6',
    'g5 Nfd7',
    'Bg2 e5',
    'd3 Nc6',
    'h4 O-O',
    'h5 Ne7',
    'Rb1 Rb8',
    'd4 b6',
    'g6 fxg6',
    'hxg6 Nxg6',
    'Ng5 Nf6',
    'Qd3 e4',
    'Bxe4 Qe8',
    'Bf5 Qc6',
    'd5 Bxf5',
    'Qxf5 Qd7',
    'Qxd7 Nxd7',
    'Nxh7 Rfe8',
    'Ng5 Nde5',
    'Ne6 Re7',
    'f4 Nxc4',
    'Rb4 b5',
    'Nd4 Re4',
    'Rxb5 Rxb5',
    'Nxb5 Nxf4',
    'Bxf4 Rxf4',
    'Nxc7 Nb6',
    'Rh5 Ra4',
    'Ne8 Nc8',
    'Rg5 Rxa2',
    'Nxg7 Kf7',
    'Ne6 Nb6',
    'Rf5+ Ke7',
    'Nd4 a5',
    'Rh5 a4',
    'Nf5+ Kd7',
    'Rh7+ Kd8',
    'Rh6 a3',
    'Rxd6+ Kc7',
    'Rc6+ Kb7',
    'Nd6+ Ka7',
    'Rc5 Ka6',
    'Nc4 Nxc4',
    'Rxc4 Kb5',
    'Rc8 Rc2',
    'Ra8 a2',
    'Kf2 Kc5',
    'Ke3 Kxd5',
    'Kd3 Rb2',
    'Ra5+ Kc6',
    'e3 Kb6',
    'Ra8 Kc5',
    'Ra5+ Kb6',
    'Ra3 Kc5',
    'Ke4 Kc4',
    'Ke5 Kd3',
    'e4 Kc2',
    'c4 Kb1',
    'c5 a1=Q',
    'Rxa1+ Kxa1',
    'c6 Rc2',
    'Kd6 Rd2+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f3 e5',
    'Nb3 Be6',
    'Be3 Be7',
    'Qd2 O-O',
    'O-O-O Qc7',
    'g4 Rc8',
    'g5 Nh5',
    'Kb1 Nd7',
    'Qf2 b5',
    'h4 b4',
    'Nd5 Bxd5',
    'Rxd5 Rcb8',
    'Ra5 Qb7',
    'Bc4 Nb6',
    'Bd5 Nxd5',
    'Rxd5 f5',
    'Na5 Qd7',
    'exf5 Rb5',
    'Qd2 Rf8',
    'f6 gxf6',
    'Rg1 Kh8',
    'gxf6 Nxf6',
    'Rxb5 Qxb5',
    'Bg5 Rg8',
    'Nb3 Nh5',
    'Rh1 Bxg5',
    'hxg5 Ng3',
    'Rxh7+ Kxh7',
    'Qh2+ Kg7',
    'Qh6+ Kf7',
    'Qf6+ Ke8',
    'Qe6+ Kf8',
    'Qxd6+ Ke8',
    'Qe6+ Kf8',
  ],
  [
    'd4 Nf6',
    'Bg5 d5',
    'Bxf6 exf6',
    'e3 Bd6',
    'c4 dxc4',
    'Bxc4 O-O',
    'Nc3 f5',
    'Qc2 Nd7',
    'O-O-O Nf6',
    'h3 g6',
    'f3 a6',
    'e4 b5',
    'Bd3 fxe4',
    'fxe4 Bf4+',
    'Kb1 Qxd4',
    'Bxb5 Qc5',
    'Bd3 Rb8',
    'Nf3 Be6',
    'Ne2 Qxc2+',
    'Bxc2 Bh6',
    'Ned4 Rfe8',
    'Nxe6 Rxe6',
    'e5 Reb6',
    'b3 Nh5',
    'Rd7 Nf4',
    'Rxc7 Nxg2',
    'Rd1 Nf4',
    'Rdd7 Ne6',
    'Ra7 Rf8',
    'Rd6 Rxd6',
    'exd6 Rd8',
    'Ne5 Rxd6',
    'Nxf7 Be3',
    'Rb7 Rb6',
    'Nh6+ Kh8',
    'Nf7+ Kg7',
    'Rd7 Nc5',
    'Re7 Bd4',
    'Nd8+ Kf8',
    'Rxh7 Rf6',
    'Kc1 Rf1+',
    'Kd2 Rf2+',
    'Kd1 Rf1+',
    'Kd2 Rf2+',
    'Kc1 Rf1+',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'Bb5+ Nd7',
    'a4 Nf6',
    'f4 a6',
    'Bc4 b6',
    'Nf3 e6',
    'Qe2 Bb7',
    'd3 Be7',
    'O-O O-O',
    'Ba2 Bc6',
    'd4 d5',
    'exd5 exd5',
    'Kh1 Bd6',
    'Ne5 Bb7',
    'Qf3 cxd4',
    'Nxd7 Qxd7',
    'Nxd5 Bc5',
    'Nxf6+ gxf6',
    'Qh5 Kh8',
    'Bd2 Rg8',
    'Rg1 Qc6',
    'Qh3 d3',
    'Bxf7 Rg7',
    'Bb3 Bxg1',
    'Rxg1 dxc2',
    'Qc3 Qxc3',
  ],
  [
    'd4 e6',
    'c4 f5',
    'g3 Nf6',
    'Bg2 c6',
    'Nf3 d5',
    'O-O Bd6',
    'b3 Qe7',
    'Bb2 O-O',
    'Qc1 Bd7',
    'Ba3 Be8',
    'Bxd6 Qxd6',
    'Na3 Bh5',
    'Nc2 Nbd7',
    'Rd1 Bxf3',
    'c5 Qc7',
    'exf3 f4',
    'Ne1 Rae8',
    'Nd3 fxg3',
    'hxg3 e5',
    'f4 e4',
    'Ne5 g5',
    'Nxd7 Qxd7',
    'fxg5 Ng4',
    'Rf1 Qf7',
    'g6 Qxg6',
    'Bh3 Re6',
    'Kg2 Ref6',
    'Bxg4 Qxg4',
    'Qd2 Rf5',
    'Qe3 R8f6',
    'Rae1 Rf3',
    'Qc1 h5',
    'Re3 h4',
    'Rxf3 Rxf3',
    'Qe1 e3',
    'Kh2 Rxf2+',
    'Rxf2 hxg3+',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Na3 b5',
    'Nd5 Be7',
    'Bxf6 Bxf6',
    'c3 Ne7',
    'Nxe7 Bxe7',
    'Nc2 Bb7',
    'Bd3 O-O',
    'Ne3 Bg5',
    'Nd5 Bxd5',
    'exd5 e4',
    'Bc2 e3',
    'O-O exf2+',
    'Rxf2 Be3',
    'Bxh7+ Kh8',
    'Qh5 Bxf2+',
    'Kxf2 Qf6+',
    'Bf5+ Qh6',
    'Qxh6+ gxh6',
    'Ke3 Rfe8+',
    'Kd3 Re5',
    'Be4 Rae8',
    'Bf3 Re1',
    'Rxe1 Rxe1',
    'Kc2 Kg7',
    'Kb3 Kf6',
    'a4 bxa4+',
    'Kxa4 Ra1+',
    'Kb4 Ke5',
    'b3 f5',
    'Kc4 a5',
    'Kb5 Ra3',
    'Kc4 Kf4',
    'Bh5 Ra2',
    'Bf3 Rb2',
  ],
  [
    'c4 c5',
    'Nf3 Nf6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'g3 Nc6',
    'Bg2 e6',
    'O-O Be7',
    'd4 Nxc3',
    'bxc3 O-O',
    'Rb1 Qc7',
    'Qa4 Bd7',
    'Bf4 Qc8',
    'Qd1 Rd8',
    'e4 b6',
    'Qe2 Be8',
    'Rfd1 cxd4',
    'cxd4 Bd6',
    'Be3 Qd7',
    'e5 Be7',
    'Ng5 h6',
    'Ne4 Rac8',
    'Bxh6 gxh6',
  ],
  ['e4 c5', 'Nf3 d6', 'Bb5+ Nd7', 'O-O Nf6', 'Re1 a6', 'Bf1 g6', 'c3 Bg7', 'd4 O-O', 'h3 b6', 'e5 Ne8', 'Bg5 dxe5', 'dxe5 Nc7'],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Bg5 Nbd7',
    'Nh3 a6',
    'Qd2 c6',
    'd5 Ne5',
    'Nf2 b5',
    'cxb5 cxd5',
    'exd5 axb5',
    'Bxb5 Ba6',
    'Bxa6 Rxa6',
    'O-O Qa8',
    'b3 Rb8',
    'Rac1 e6',
    'dxe6 fxe6',
    'Rfe1 Nd5',
    'Nxd5 Qxd5',
    'Qxd5 exd5',
    'Re2 Rba8',
    'Rd1 Rxa2',
    'Rxa2 Rxa2',
    'Rxd5 Rb2',
    'Ne4 Rxb3',
    'Rxd6 Nf7',
    'Rd1 Nxg5',
    'Nxg5 Bf6',
    'Ne4 Be7',
    'Rd7 Kf7',
    'Ra7 Ke6',
    'h3 h5',
    'Ra6+ Kf7',
    'Nd2 Rc3',
    'f4 h4',
    'Nf3 Bf6',
    'Ra7+ Kg8',
    'Ra4 Rc1+',
    'Kh2 Rd1',
    'Ra5 Rb1',
    'Ng5 Rf1',
    'Ra4 Kg7',
    'Nf3 Rd1',
    'Re4 Kf7',
    'Rb4 Kf8',
    'Rb5 Rf1',
    'Rb7 Kg8',
    'Rd7 Ra1',
    'g4 hxg3+',
    'Kxg3 Ra5',
    'Rc7 Rb5',
    'Rc4 Rd5',
    'Ra4 Kg7',
    'h4 Rb5',
    'Ra7+ Kg8',
    'Nd2 Rb4',
    'Rc7 Rd4',
    'Nf3 Rd5',
    'Rc2 Kf7',
    'Nd2 Rd4',
    'Nf3 Rd5',
    'Re2 Kg7',
    'Nd2 Rh5',
    'Rh2 Rd5',
    'Ne4 Rd4',
    'Nc5 Kf7',
    'Ra2 Rd1',
    'Ra7+ Ke8',
    'Ne4 Rg1+',
    'Kh3 Be7',
    'Ng3 Re1',
    'Ra5 Kf7',
    'Kg4 Re6',
    'Ra7 Ke8',
    'h5 gxh5+',
    'Nxh5 Kf8',
    'f5 Re4+',
    'Kf3 Re5',
    'Kf4 Rb5',
    'Ra6 Kf7',
    'Ng3 Rb4+',
    'Ne4 Rb1',
    'Ra7 Kf8',
    'f6 Bxf6',
    'Nxf6 Rf1+',
  ],
  ['Nf3 Nf6', 'c4 g6', 'd4 Bg7', 'g3 d5', 'cxd5 Nxd5', 'Bg2 O-O', 'O-O c5', 'dxc5 Na6', 'Ng5 e6', 'Ne4 Bd7', 'Nbc3 Bc6', 'Nxd5 exd5', 'Nc3 d4', 'Bxc6 bxc6', 'Na4 Qd5', 'Bf4 Nxc5'],
  [
    'Nf3 Nf6',
    'b3 d6',
    'd4 g6',
    'Bb2 Bg7',
    'e3 O-O',
    'Be2 c6',
    'c4 Qc7',
    'O-O Nbd7',
    'Nc3 Re8',
    'b4 e5',
    'dxe5 dxe5',
    'c5 b6',
    'cxb6 axb6',
    'Rc1 Qb8',
    'Ng5 b5',
    'Qb3 Re7',
    'a4 h6',
    'Nf3 bxa4',
    'Nxa4 Ba6',
    'Bxa6 Rxa6',
    'h3 Nd5',
    'Rc4 Re6',
    'Rfc1 Bf8',
    'Bc3 Qb5',
    'Nb2 c5',
    'Rxc5 Nxc5',
    'Qxd5 Qb7',
    'Qxb7 Nxb7',
    'Nxe5 Nd6',
    'Nbd3 Ra3',
    'Bb2 Rb3',
    'f4 Nf5',
    'Rc8 Rd6',
    'Nc5 Rxb2',
    'Ncd7 Rd1+',
    'Kh2 Nd6',
    'Rxf8+ Kg7',
    'f5 gxf5',
    'Rd8 Rdd2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Nf3 O-O',
    'Be2 e5',
    'O-O Na6',
    'Be3 Ng4',
    'Bg5 Qe8',
    'dxe5 dxe5',
    'h3 f6',
    'Bd2 Nh6',
    'c5 c6',
    'Bxa6 bxa6',
    'Qa4 Rb8',
    'Rad1 Nf7',
    'Bc1 Nd8',
    'Rd6 Rf7',
    'Rfd1 Be6',
    'Be3 a5',
    'R6d3 Nb7',
    'Qc2 Bf8',
    'Na4 g5',
    'Nd2 Rg7',
    'Nf1 Rd8',
    'Ng3 Rgd7',
    'b3 Rxd3',
    'Rxd3 Rxd3',
    'Qxd3 Bc8',
    'Kh2 h5',
    'Nf5 Qd7',
    'Qc4+ Qe6',
    'Qc3 h4',
    'Bc1 Qd7',
    'Ba3 Qh7',
    'Qc4+ Qf7',
    'Nd6 Bxd6',
    'cxd6 Qxc4',
    'bxc4 c5',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 d5',
    'cxd5 Qxd5',
    'Nf3 Qf5',
    'Qb3 c5',
    'a3 Ba5',
    'Qc4 O-O',
    'b4 cxb4',
    'axb4 Bb6',
    'e3 Nc6',
    'Bd3 Qh5',
    'b5 Ne7',
    'Be2 Ned5',
    'Ne5 Qh4',
    'O-O Nxc3',
    'Qxc3 Rd8',
    'Bf3 Nd5',
    'Qb3 Bc7',
    'g3 Qe7',
    'Nc4 Bd7',
    'Ba3 Qe8',
    'Rfb1 Rac8',
    'e4 Nb6',
    'Na5 Rb8',
    'e5 Nd5',
    'Nc4 Rdc8',
    'Bc5 b6',
    'Bd6 Nc3',
    'Bxc7 Rxc7',
    'Rc1 Nxb5',
    'Nd6 Nxd6',
    'exd6 Rcc8',
    'Rc7 b5',
    'Rcxa7 Qd8',
    'Bb7 Rxb7',
    'Rxb7 Bc6',
    'Rc7 Rxc7',
    'dxc7 Qxc7',
    'Qc3 Qb7',
    'Rc1 Be8',
    'Qc8 Qxc8',
    'Rxc8 Kf8',
    'Rc7 b4',
    'Rb7 Bc6',
    'Rxb4 g5',
    'h3 Kg7',
    'Kh2 Kf6',
    'g4 Kg6',
    'Kg3 h6',
    'Rb8 Bd5',
    'h4 gxh4+',
    'Kxh4 Bc4',
    'Rg8+ Kh7',
    'Rc8 Bd3',
    'Kg3 Kg6',
    'Rh8 Kg7',
    'Rd8 Bc4',
    'Kf4 f6',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nc3 Be7',
    'Nf3 Nf6',
    'Bf4 c5',
    'dxc5 Na6',
    'e3 Nxc5',
    'Be2 O-O',
    'O-O a6',
    'cxd5 exd5',
    'Rc1 Be6',
    'Be5 Rc8',
    'Bd4 Nfe4',
    'Na4 Nd7',
    'Nc3 Ndc5',
    'Nxe4 dxe4',
    'Nd2 Bxa2',
    'b3 Nd3',
    'Rxc8 Qxc8',
    'Nxe4 Nb4',
    'Nc3 f6',
    'Bc4+ Kh8',
    'Nd5 Nxd5',
    'Bxd5 Rd8',
    'e4 Qc7',
    'Qd2 Rxd5',
    'exd5 Bxb3',
    'Rc1 Qd7',
    'Bc5 Bd8',
    'Re1 h6',
    'Qe3 Qxd5',
    'Be7 Bxe7',
    'Qxe7 Ba4',
    'h3 Bc6',
    'f3 Qd4+',
    'Kh1 Kh7',
    'Qf7 Qd5',
    'Qf8 Qd3',
    'Re7 Qd1+',
    'Kh2 Qd6+',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 a6',
    'e3 b5',
    'b3 Bg4',
    'h3 Bxf3',
    'gxf3 Nbd7',
    'f4 g6',
    'Bd2 Bg7',
    'c5 O-O',
    'Qc2 Ne8',
    'O-O-O f6',
    'f5 e5',
    'f4 Qe7',
    'h4 Nc7',
    'Bh3 gxf5',
    'Bxf5 Kh8',
    'Rdg1 Bh6',
    'Qd1 Ne6',
    'Ne2 Rg8',
    'Kb2 Rxg1',
    'Rxg1 Rg8',
    'Rxg8+ Kxg8',
    'Qg1+ Kh8',
    'Qg4 Ndf8',
    'Bd3 e4',
    'Bc2 Qg7',
    'Qh3 Qd7',
    'f5 Ng7',
    'Ng3 Ng6',
    'Bd1 Ne7',
    'Bg4 a5',
    'Qf1 Qc7',
    'Qe1 b4',
    'a3 bxa3+',
    'Kxa3 Qb7',
    'Qf2 Qc7',
    'Nh5 Nxh5',
    'Bxh5 Qd7',
    'Bg4 Qc7',
    'Qe1 Qa7',
    'Ka4 Qb8',
    'Qe2 Qg3',
    'h5 Qg1',
    'Kxa5 Qa1+',
    'Kb4 Nc8',
    'Qd1 Qa2',
    'Kc3 Na7',
    'Be2 Bf8',
    'Qc1 Nb5+',
    'Bxb5 Qa5+',
    'Kb2 Qxb5',
    'Qe1 Qd3',
    'Bc1 Bh6',
    'Qd2 Qb5',
    'Qh2 Bg5',
    'h6 Kg8',
    'Bd2 Qb7',
    'Qd6 Bxh6',
    'Qxf6 Bg7',
    'Qe6+ Qf7',
    'b4 Qxe6',
    'fxe6 Kf8',
  ],
  ['d4 d5', 'c4 c6', 'Nf3 Nf6', 'Nc3 dxc4', 'a4 Bf5', 'e3 e6', 'Bxc4 Nbd7', 'O-O Bb4', 'Nh4 Bg4', 'f3 Bh5', 'g4 Nd5', 'Ng2 Bg6'],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Qxd4 a6',
    'Be3 Nc6',
    'Qb6 Qxb6',
    'Bxb6 Nf6',
    'Nc3 e6',
    'O-O-O Be7',
    'h3 O-O',
    'Bc4 Bd7',
    'Rhe1 Rfc8',
    'Bb3 Be8',
    'Na4 Nb4',
    'Kb1 d5',
    'Bd4 Bxa4',
    'Bxa4 Nxe4',
    'c3 Nc6',
    'Bc2 Nf6',
    'Bxf6 Bxf6',
    'g4 g6',
    'Bb3 b5',
    'Nd4 Bxd4',
    'cxd4 g5',
    'Rd3 h6',
    'Rc1 Ne7',
    'Rdc3 Rxc3',
    'Rxc3 Rc8',
    'Rc5 Kf8',
    'Kc2 Ke8',
    'Kc3 Kd7',
    'Bc2 Nc6',
    'b4 Nd8',
    'Bd3 Nb7',
    'Rxc8 Kxc8',
    'f3 Kc7',
    'Kd2 f6',
    'Ke3 Nd8',
    'a3 Nc6',
    'Bb1 a5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 e6',
    'e3 a6',
    'Qc2 c5',
    'dxc5 Bxc5',
    'Be2 dxc4',
    'O-O Nbd7',
    'a4 b6',
    'Bxc4 Bb7',
    'Qe2 Qc7',
    'e4 Ng4',
    'h3 h5',
    'Ba2 Qg3',
    'Nd1 Nde5',
    'Kh1 Bxf2',
    'Rxf2 Rd8',
    'Be3 Bxe4',
    'Kg1 Nxf3+',
    'Rxf3 Bxf3',
  ],
  ['d4 Nf6', 'Nf3 g6', 'c4 Bg7', 'Nc3 d5', 'Bf4 c5', 'dxc5 Qa5', 'cxd5 Nxd5', 'Qxd5 Bxc3+', 'Bd2 Be6', 'Bxc3 Qxc3+', 'Qd2 Qxd2+', 'Nxd2 Na6', 'e3 Nxc5'],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Qb3 dxc4',
    'Qxc4 O-O',
    'e4 Na6',
    'Be2 c5',
    'd5 e6',
    'O-O exd5',
    'exd5 Bf5',
    'Be3 Qb6',
    'b3 Rfe8',
    'Rad1 Ng4',
    'Bd2 Rad8',
    'Rfe1 Bd4',
    'Nxd4 cxd4',
    'Na4 Qc7',
    'Bxg4 Qxc4',
    'bxc4 Bxg4',
    'f3 Bd7',
    'Nb2 b5',
    'Ba5 Rxe1+',
    'Bxe1 bxc4',
    'Nxc4 Bb5',
    'Rxd4 Bxc4',
    'Rxc4 Rxd5',
    'Bc3 Rd8',
    'Bf6 Re8',
    'Bd4 Nb8',
    'Bxa7 Nd7',
    'Bd4 Ra8',
    'Rc7 Nf8',
    'Ra7 Rd8',
    'Be3 Kg7',
    'a4 Ne6',
    'a5 Rd3',
    'Kf2 Ra3',
    'Bb6 Rb3',
    'Ke2 Kf8',
    'Kd2 Ke8',
    'Kc2 Rb4',
    'Kc3 Rb1',
    'Be3 Ra1',
    'Kb2 Ra4',
    'a6 Kd8',
    'Ra8+ Kd7',
    'a7 h5',
    'Rb8 Nc7',
    'Kb3 Ra1',
    'h4 Ra6',
    'Bf2 Ra1',
    'Bb6 Kc6',
    'Bxc7 Rxa7',
    'Bf4 Kd7',
    'Rb6 Ra1',
    'Kc3 Rg1',
    'Rb2 f6',
    'Kd3 Rh1',
    'g3 Ke6',
    'Rb6+ Kf5',
    'Bd2 Rd1',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Qb6',
    'Nf3 Bd7',
    'Bd3 cxd4',
    'Nxd4 Ne7',
    'Nd2 Nbc6',
    'Nxc6 Nxc6',
    'Nf3 h6',
    'h4 d4',
    'O-O dxc3',
    'bxc3 Bc5',
    'Qe2 Qc7',
    'Rb1 O-O-O',
    'Be3 Bxe3',
    'Qxe3 Kb8',
    'Be4 Na5',
    'Nd4 Nc4',
    'Qe1 Nb6',
    'f4 Rc8',
    'Rf3 f5',
    'Bc2 Nd5',
    'Ne2 g5',
    'hxg5 hxg5',
    'fxg5 Rh5',
    'g6 Rch8',
    'g7 Rg8',
    'Bb3 Qxe5',
    'Bxd5 Qxd5',
    'Qg3+ e5',
    'Re3 f4',
    'Nxf4 exf4',
    'Qxf4+ Kc8',
    'Qf8+ Kc7',
    'Qf4+ Kc6',
    'Qf6+ Kc7',
    'Qf4+ Kc6',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Qb6',
    'Nf3 Bd7',
    'Bd3 cxd4',
    'Nxd4 Ne7',
    'Nd2 Nbc6',
    'Nxc6 Nxc6',
    'Qe2 Qc7',
    'Nf3 h6',
    'O-O g5',
    'h3 Bg7',
    'Re1 O-O-O',
    'Be3 Kb8',
    'Bd4 Nxd4',
    'Nxd4 h5',
    'Rac1 g4',
    'h4 f6',
    'exf6 Bxf6',
    'Nxe6 Bxe6',
    'Qxe6 Bxh4',
    'Qe5 Qxe5',
    'Rxe5 g3',
    'f4 Bf6',
    'Re6 Rhf8',
    'Rce1 h4',
    'f5 b5',
    'Rc6 d4',
    'c4 bxc4',
    'Rxc4 Rd6',
    'Rb4+ Kc7',
    'Rc4+ Kb8',
    'Kf1 Bd8',
    'a4 a5',
    'b4 Rg8',
    'Re5 Ka7',
    'Rc8 Kb7',
    'Rc4 Ka7',
    'Be4 Rg4',
    'Rc8 Rxe4',
    'Rxe4 d3',
    'Rc1 Bg5',
    'Rd1 Rd5',
    'Rg4 Rxf5+',
    'Ke1 Re5+',
    'Kf1 Rf5+',
    'Ke1 d2+',
    'Ke2 axb4',
    'Rxb4 Rf2+',
    'Kd3 Rxg2',
    'Rg4 Bd8',
    'Rxd2 Rg1',
    'Ke4 Bb6',
    'a5 Bc5',
    'Rg6 Re1+',
    'Kf3 Re7',
    'Rd5 Rf7+',
    'Kg2 Bb4',
    'Rg4 Rf2+',
    'Kg1 Rb2',
    'Rxh4 Rb1+',
    'Kg2 Rb2+',
    'Kf3 Rb3+',
    'Ke4 Rc3',
    'Rh7+ Ka6',
    'Rh6+ Ka7',
    'Rd7+ Kb8',
    'a6 Rc4+',
    'Kd3 Rc3+',
    'Kd4 Rf3',
    'Rhh7 Rf4+',
    'Ke5 g2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 e5',
    'Be3 Na6',
    'd5 Ng4',
    'Bg5 f6',
    'Bh4 h5',
    'Nd2 Bd7',
    'h3 Nh6',
    'g4 hxg4',
    'hxg4 Qe7',
    'Nf1 Nf7',
    'Ne3 Bh6',
    'Bd3 Kg7',
    'Qe2 Rh8',
    'O-O-O c5',
    'a3 Nc7',
    'Kb1 a6',
    'f3 b5',
    'Bf2 Rab8',
    'cxb5 axb5',
    'b4 Na6',
    'Qb2 Rhc8',
    'Be2 Rb7',
    'Rc1 Bf4',
    'Rc2 Qe8',
    'Na2 Rbc7',
    'Ng2 Bh6',
    'Be3 Bxe3',
    'Nxe3 Qe7',
    'Bd1 Rh8',
    'Rch2 Rcc8',
    'Rxh8 Rxh8',
    'Rxh8 Kxh8',
    'Nc3 cxb4',
    'axb4 Qd8',
    'Qh2+ Kg7',
    'Nc2 Qb6',
    'Kb2 Ng5',
    'Kb3 Nc7',
    'Be2 Qa7',
    'Qg2 Kf7',
    'Qf1 Qb6',
    'Qa1 Ke7',
    'Qa5 Qb7',
    'Na3 f5',
    'gxf5 gxf5',
    'Nc2 fxe4',
    'fxe4 Nxe4',
    'Nxe4 Qxd5+',
    'Kb2 Qxe4',
    'Bd1 Ne6',
    'Qa3 Nd4',
    'Qg3 Kd8',
    'Ne3 Kc7',
    'Qe1 Nc6',
    'Bb3 Qd4+',
    'Ka3 Nxb4',
    'Qc1+ Bc6',
    'Qg1 Nd3',
    'Qg7+ Kb6',
    'Nd1 Qa1+',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 e5',
    'Be3 Na6',
    'O-O Ng4',
    'Bg5 Qe8',
    'dxe5 dxe5',
    'h3 f6',
    'Bd2 Nh6',
    'c5 Nxc5',
    'Qc1 Nf7',
    'Nd5 Ne6',
    'Nxc7 Nxc7',
    'Qxc7 Nd8',
    'Rfc1 Be6',
    'Be3 Rf7',
    'Qc3 Nc6',
    'b4 Rd7',
    'b5 Nd8',
    'a4 Nf7',
    'Nd2 f5',
    'f3 Nd6',
    'Nc4 Rc8',
    'Qb4 Bf8',
    'Nxe5 Rdd8',
    'Qe1 fxe4',
    'f4 b6',
    'Nc6 Rd7',
    'Bg4 Bg7',
    'Rab1 h5',
    'Bxe6+ Qxe6',
    'Rd1 Rf7',
    'a5 Kh7',
    'Kh1 Bf6',
    'axb6 axb6',
    'Ne5 Bxe5',
    'fxe5 Qxe5',
    'Bd4 Qg5',
    'Be3 Qe5',
    'Bd4 Qe8',
    'Bxb6 Nxb5',
    'Rb3 Qc6',
    'Bg1 Re8',
    'Rdb1 Nc7',
    'Rb6 Qd5',
    'Qg3 Re6',
    'Rb7 Qe5',
    'Qb3 Ref6',
    'Re1 Qd5',
    'Qb4 Na6',
    'Rxf7+ Rxf7',
    'Qa4 Nc7',
    'Rxe4 Ne6',
    'Qb4 Ng7',
    'Rd4 Qg5',
    'Qc4 Qf6',
    'Rd6 Qxd6',
    'Qxf7 Qe6',
    'Qa7 Qe1',
    'Qc7 Kg8',
    'Qc8+ Kf7',
  ],
  [
    'c4 e6',
    'Nc3 Nf6',
    'e4 d5',
    'e5 d4',
    'exf6 dxc3',
    'bxc3 Qxf6',
    'Nf3 b6',
    'd4 Bb7',
    'Bd3 h6',
    'O-O Nd7',
    'Nd2 Bd6',
    'Qg4 Qg5',
    'Qh3 Qe7',
    'Ne4 Ba3',
    'Qg3 O-O-O',
    'c5 Bxc1',
    'cxb6 axb6',
    'Rfxc1 e5',
    'a4 f5',
    'Nd2 e4',
    'Bb5 g5',
    'Qe3 Nf6',
    'a5 f4',
    'Qe2 e3',
    'fxe3 Qxe3+',
    'Qxe3 fxe3',
    'Nc4 Nd5',
    'a6 Ba8',
    'Ne5 Rhf8',
    'Be2 c5',
    'dxc5 Kc7',
    'Nc4 bxc5',
    'Ra5 Kc6',
    'Re1 Nxc3',
    'Bf3+ Rxf3',
    'gxf3 e2',
    'Kf2 Rd4',
    'Ne5+ Kb6',
    'Ra3 Nb5',
    'Re3 Bd5',
    'R3xe2 Kxa6',
    'Rb2 Rb4',
    'Rd2 Rd4',
    'Rc2 c4',
    'Ke3 Rh4',
    'Rb1 c3',
    'Nd3 Ka5',
    'Ra1+ Kb6',
    'Rf2 Nd4',
    'Rb1+ Kc7',
    'Nb4 Bxf3',
    'Rc1 Ne2',
    'Rc2 Bh5',
    'Nd5+ Kd6',
    'Nxc3 Rh3+',
    'Kd2 Nd4',
    'Rb2 Nf3+',
    'Kc1 Ne5',
    'Rf6+ Kc5',
    'Ne4+ Kd4',
    'Nf2 Rc3+',
    'Rc2 Rxc2+',
    'Kxc2 Bg6+',
    'Kd2 h5',
    'Ke2 g4',
    'Kf1 Bc2',
    'Kg2 h4',
    'Rh6 h3+',
    'Kg3 Be4',
    'Rd6+ Ke3',
    'Nd1+ Ke2',
    'Kf4 Bf3',
    'Nc3+ Kf1',
    'Rd2 Ng6+',
    'Kg3 Ne7',
    'Nb5 Ke1',
    'Ra2 Nc6',
    'Rb2 Kf1',
    'Rd2 Ke1',
    'Rd6 Kf1',
    'Re6 Nd8',
    'Re3 Nc6',
    'Re8 Be2',
    'Nd6 Nd4',
    'Ne4 Kg1',
    'Nc3 Nf5+',
    'Kf4 Bf3',
    'Kxf5 Kxh2',
    'Kf4 Kg2',
    'Ne2 h2',
    'Ng3 Kh3',
    'Rh8+ Kg2',
    'Rb8 Kh3',
    'Rb5 Be2',
    'Rb3 Bd1',
    'Ra3 Bf3',
    'Nh1 Kh4',
    'Nf2 Be2',
    'Re3 Bd1',
    'Re1 g3',
    'Rxd1 g2',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 e6',
    'Nf3 Nbd7',
    'Qc2 Bd6',
    'g4 dxc4',
    'Bxc4 e5',
    'g5 Nd5',
    'Ne4 Bb4+',
    'Bd2 Bxd2+',
    'Qxd2 Qe7',
    'a3 N7b6',
    'Ba2 Bh3',
    'Rg1 exd4',
    'Qxd4 O-O-O',
    'Ng3 f5',
    'gxf6 Nxf6',
    'Qb4 Qd7',
    'Ne5 Qc7',
    'Nf7 Nbd5',
    'Qh4 Bg4',
    'Nxd8 Qa5+',
    'b4 Qxa3',
    'Kf1 Qd3+',
    'Kg2 Nxe3+',
    'fxe3 Qc2+',
    'Kf1 Qd3+',
  ],
  [
    'c4 c6',
    'd4 d5',
    'Nc3 Nf6',
    'e3 e6',
    'Nf3 Nbd7',
    'Bd3 dxc4',
    'Bxc4 b5',
    'Bd3 Bb7',
    'O-O a6',
    'e4 c5',
    'd5 Qc7',
    'dxe6 fxe6',
    'Bc2 c4',
    'Nd4 Nc5',
    'Be3 O-O-O',
    'Qe2 e5',
    'Nf3 Nd3',
    'a4 b4',
    'Nd5 Nxd5',
    'exd5 e4',
    'Nd2 b3',
    'Bxd3 cxd3',
    'Qg4+ Qd7',
    'Qf4 Bxd5',
    'Rfc1+ Kb7',
    'Qe5 Be7',
    'Nxe4 Rhe8',
    'Qd4 Ka8',
    'Nd6 Bxd6',
    'Qxd5+ Qb7',
    'Qxb7+ Kxb7',
    'Rc3 Bb4',
    'Rxb3 a5',
    'Kf1 d2',
    'Rd1 Kc6',
    'Ke2 Kd5',
    'Rd3+ Kc6',
    'b3 Rb8',
    'Rd4 Re5',
    'Kd3 Bc5',
    'Rc4 Rxb3+',
    'Kxd2 Rb2+',
    'Kc3 Rb4',
    'Rdd4 h5',
    'g3 g5',
    'h4 g4',
    'Rf4 Rxc4+',
    'Rxc4 Kb6',
    'Rxc5 Rxc5+',
    'Kd3 Kc6',
    'Bxc5 Kxc5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 dxc4',
    'e4 b5',
    'e5 Nd5',
    'a4 e6',
    'Ng5 Bb7',
    'Qh5 g6',
    'Qf3 Qc7',
    'Be2 h6',
    'Qh3 Nd7',
    'O-O Be7',
    'Bg4 Nf8',
    'Re1 Qb6',
    'a5 Qxd4',
    'Nf3 Qc5',
    'Ne4 Qb4',
    'a6 Bc8',
    'Bg5 Bd7',
    'Bxe7 Kxe7',
    'Nfg5 Nh7',
    'Qxh6 Nxg5',
    'Qxg5+ Kf8',
    'Nf6 Qe7',
    'Bf3 Kg7',
    'Rad1 Rad8',
    'Bxd5 exd5',
    'Rd4 Be6',
    'Rf4 Qb4',
    'Rf1 Rdf8',
    'h4 Rh6',
    'h5 Rfh8',
    'g4 Qxb2',
    'Nd7 Qa3',
    'Qf6+ Kg8',
    'Qd8+ Kg7',
    'Rxf7+ Bxf7',
    'Qf6+ Kg8',
    'e6 Be8',
    'e7 gxh5',
    'Qf8+ Kh7',
    'Qf5+ Kg7',
    'Qe5+ Kf7',
    'Qf5+ Kg7',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 b6',
    'a3 Ba6',
    'e3 d5',
    'Nbd2 Be7',
    'b3 c5',
    'Bb2 O-O',
    'Bd3 cxd4',
    'exd4 Nbd7',
    'O-O Rc8',
    'Qe2 g6',
    'Rac1 Rc7',
    'Rc2 Qb8',
    'Rfc1 Rfc8',
    'Nf1 Bf8',
    'Ne3 Bb7',
    'a4 a5',
    'cxd5 Nxd5',
    'Bb5 Nf4',
    'Qd1 Rxc2',
    'Rxc2 Rxc2',
    'Qxc2 Bxf3',
    'Bxd7 Bb7',
    'Bc6 Bg7',
    'd5 Nxd5',
    'Bxd5 Bxd5',
    'Ng4 Qd8',
    'Qd2 h5',
    'Bxg7 Kxg7',
    'Qc3+ f6',
    'Ne3 Qd6',
    'h3 Qc6',
    'Qd3 Qc1+',
    'Kh2 Qc7+',
    'Kg1 Bb7',
    'Qd2 Bc6',
    'Qc3 Qd6',
    'Nc4 Qc5',
    'Qd3 Bd5',
    'Ne3 Kf7',
    'Kh2 f5',
    'f3 Ke7',
    'Kh1 Bb7',
    'Qd2 Ba6',
    'Nc2 e5',
    'b4 axb4',
    'Nxb4 Bc4',
    'Qe1 Kf7',
    'Kh2 e4',
    'fxe4 fxe4',
    'Nc2 Bb3',
    'Ne3 Bxa4',
    'Qg3 Bd7',
    'Qf4+ Kg7',
    'Qxe4 Qd6+',
    'Kh1 Bc6',
    'Qc2 b5',
    'Qc3+ Kh7',
    'Nc2 Be4',
    'Nd4 b4',
    'Qe3 Bd5',
    'Qd3 b3',
    'Qb5 Qe5',
    'Qb4 Qe4',
    'Qd2 Qxg2+',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 Nc6',
    'c4 Nb6',
    'exd6 exd6',
    'h3 Be7',
    'Be2 O-O',
    'O-O Bf6',
    'Nc3 Re8',
    'b3 Be6',
    'Ne4 d5',
    'Nxf6+ Qxf6',
    'c5 Nd7',
    'Be3 Bf5',
    'Qd2 h6',
    'Rfe1 Nf8',
    'Rad1 g5',
    'Ne5 Ng6',
    'Nxg6 Qxg6',
    'Bb5 Re7',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c4 Bg7',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'O-O Nc6',
    'e3 O-O',
    'Nc3 Re8',
    'd5 Na5',
    'Nd4 Bd7',
    'e4 c6',
    'Bf4 Bc8',
    'Nde2 e5',
    'dxe6 Bxe6',
    'b3 c5',
    'Qc1 Qe7',
    'Rd1 Rad8',
    'Qa3 Nc6',
    'Rac1 c4',
    'Bd6 Qg5',
    'h4 Qa5',
    'Qxa5 Nxa5',
    'bxc4 Naxc4',
    'Bc5 Rc8',
    'Bd4 Nb2',
    'Rd2 N6c4',
    'Rdc2 Nd3',
    'Rd1 Nb4',
    'Bxg7 Nxc2',
    'Bf6 Bg4',
    'Rb1 Rc6',
    'e5 Rb6',
    'Rxb6 Nxb6',
    'Bxb7 Nd7',
    'Nf4 Nxe5',
    'Be4 Nf3+',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 e6',
    'Nc3 Nbd7',
    'Bd3 dxc4',
    'Bxc4 b5',
    'Bd3 Bb7',
    'O-O a6',
    'e4 c5',
    'd5 Qc7',
    'dxe6 fxe6',
    'Bc2 Bd6',
    'Ng5 Nf8',
    'f4 h6',
    'Nh3 O-O-O',
    'Qe2 e5',
    'f5 N8d7',
    'Be3 Nb8',
    'a4 b4',
    'Nd5 Nxd5',
    'exd5 Bxd5',
    'Bd3 Kb7',
    'Nf2 Qc6',
    'Qg4 h5',
    'Qg5 Nd7',
    'Rfc1 h4',
    'h3 Nf6',
    'Be2 Rd7',
    'Bc4 Be7',
    'Nd3 Rh5',
    'Qxg7 Rxf5',
    'Bxd5 Qxd5',
    'Rd1 Bd6',
    'Qh6 Qc6',
    'Rac1 c4',
    'Qg6 Nd5',
    'Qxf5 Nxe3',
    'Qxd7+ Qxd7',
    'Nc5+ Bxc5',
    'Rxd7+ Kc6',
    'Rd2 Bd4',
    'Kf2 Kc5',
    'Kf3 b3',
    'g4 hxg3',
    'Kxg3 Nd5',
    'Kf3 Kb4',
    'Ke4 c3',
    'bxc3+ Nxc3+',
    'Kd3 Nxa4',
    'Rc4+ Ka3',
    'Rxd4 exd4',
    'Kxd4 b2',
  ],
  ['d4 d5', 'c4 e5', 'dxe5 d4', 'Nf3 Nc6', 'Nbd2 Nge7', 'g3 Ng6', 'Bg2 Ncxe5', 'Nxe5 Nxe5', 'O-O Be7', 'Nf3 Nxf3+'],
  [
    'c4 Nf6',
    'Nc3 c5',
    'Nf3 b6',
    'e4 d6',
    'd4 cxd4',
    'Nxd4 Bb7',
    'f3 e6',
    'Be3 Be7',
    'Be2 O-O',
    'O-O a6',
    'Qd2 Re8',
    'Rfd1 Nbd7',
    'Rac1 Rc8',
    'Bf1 Bf8',
    'Qf2 Qc7',
    'b4 Qb8',
    'a3 Be7',
    'Nb3 Bd8',
    'h3 Bc7',
    'Kh1 h6',
    'a4 d5',
    'cxd5 Bg3',
    'Qd2 exd5',
    'Nxd5 Nxd5',
    'exd5 Rcd8',
    'd6 Nf6',
    'Bxh6 Ne4',
    'fxe4 Rxd6',
    'Bf4 Bxf4',
    'Qxf4 Rxd1',
    'Qxb8 Rxf1+',
    'Rxf1 Rxb8',
    'Rf4 Rc8',
    'a5 Rc4',
    'axb6 Rxb4',
    'Nc5 Rxb6',
    'Rf2 Bc6',
    'Ra2 Bb5',
    'Kg1 Rc6',
    'Nb3 Bd3',
    'Ra5 Rb6',
    'Ra3 Bb5',
    'Nd4 Rd6',
    'Nf3 Rd3',
    'Ra1 Re3',
    'e5 Rd3',
    'Rc1 Rd8',
    'Rc7 Ra8',
    'Nd4 Be8',
    'e6 a5',
    'exf7+ Bxf7',
    'Nc2 a4',
    'Na3 Rd8',
    'Ra7 Be8',
    'Ra5 Rd3',
    'Ra8 Kf7',
    'Nc4 Rd4',
    'Ne5+ Ke7',
    'Kf2 Rd5',
    'Nc4 Rc5',
    'Ra7+ Ke6',
    'Ra6+ Ke7',
    'Ra7+ Ke6',
    'Ra6+ Kd5',
    'Nb6+ Kd4',
    'Nxa4 Rc2+',
    'Ke1 Rxg2',
    'Nb6 Rg6',
    'Ra8 Re6+',
    'Kf2 Kc5',
    'Na4+ Kb4',
    'Nb2 Bb5',
    'Nd1 Rf6+',
    'Kg3 Be2',
    'Rb8+ Kc5',
    'Nf2 Rf3+',
    'Kg2 Re3',
    'Rf8 Kd6',
    'Rf4 Ke7',
    'h4 Bb5',
    'Rg4 Bc6+',
    'Kh2 Rf3',
    'Kg1 Kf6',
    'Rb4 Kf5',
    'Rg4 Kf6',
    'Rg5 Bd7',
    'Ne4+ Kf7',
    'Rd5 Ke7',
    'Re5+ Kf8',
    'Nc5 Bc8',
    'Rd5 Ke7',
    'Rg5 Rf4',
    'Re5+ Kd6',
    'Rh5 Rf5',
    'Ne4+ Ke5',
    'Ng5 Kf4',
    'Rh8 Rc5',
    'Rd8 Bf5',
    'Kf2 Rc2+',
  ],
  [
    'c4 Nf6',
    'Nc3 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'Nf3 d6',
    'O-O Nc6',
    'd4 a6',
    'd5 Na5',
    'Qd3 c5',
    'e4 b5',
    'cxb5 axb5',
    'Nxb5 Ba6',
    'a4 Qb6',
    'b4 cxb4',
    'Be3 Qb7',
    'Rab1 b3',
    'Nd2 Bxb5',
    'axb5 Rfb8',
    'b6 Qa6',
    'Qxa6 Rxa6',
    'Bf3 b2',
    'Be2 Raxb6',
    'Bxb6 Rxb6',
    'Rfd1 Rb4',
    'f3 Nd7',
    'Nf1 Nc5',
    'Kf2 Na4',
    'Rd2 Nc3',
    'Rbxb2 Nxe4+',
    'fxe4 Bxb2',
    'Kf3 Nb3',
    'Rd1 Nd4+',
    'Kf2 Nxe2',
    'Kxe2 Rxe4+',
    'Kf3 Ra4',
    'Ne3 Bd4',
    'Nc2 Bc5',
    'h4 h5',
    'Rd3 Kg7',
    'Ne1 Ra2',
    'g4 hxg4+',
    'Kxg4 Ra4+',
    'Kh3 e5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Bd3 O-O',
    'Nge2 Nc6',
    'O-O e5',
    'd5 Nd4',
    'Nb5 c5',
    'Nbxd4 exd4',
    'f3 Nd7',
    'f4 Nf6',
    'h3 b5',
    'cxb5 a6',
    'bxa6 Bxa6',
    'Bxa6 Rxa6',
    'Qd3 Qa8',
    'a3 Re8',
    'Ng3 Rb6',
    'b4 Qa6',
    'Qxa6 Rxa6',
    'bxc5 dxc5',
    'd6 Nd7',
    'e5 f6',
    'Ne4 fxe5',
    'f5 Nf6',
    'Nxc5 Rxd6',
    'fxg6 hxg6',
    'Bg5 e4',
    'Bxf6 Bxf6',
    'Rae1 e3',
    'Re2 Bg7',
    'Rd1 Rd5',
    'Nd3 Ra5',
    'Ne1 Rxa3',
    'g3 Ra4',
    'Kg2 Kf7',
    'Nd3 Rc8',
    'Kf3 Ra5',
    'Rb2 Rc7',
    'Ke2 Rh5',
    'h4 g5',
    'Rf1+ Kg8',
    'Rf5 Rf7',
    'Rb8+ Bf8',
    'Rd5 Kg7',
    'Rxd4 gxh4',
    'Rg4+ Kh7',
    'Rxh4 Rxh4',
    'gxh4 Bh6',
    'h5 Ra7',
    'Rb2 Rg7',
    'Ne1 Rf7',
    'Kd3 Rd7+',
    'Ke2 Rd5',
    'Ng2 Rxh5',
    'Rb7+ Kg6',
  ],
  [
    'd4 Nf6',
    'Nc3 d5',
    'Bg5 e6',
    'e4 Bb4',
    'Ne2 dxe4',
    'a3 Be7',
    'Bxf6 gxf6',
    'Nxe4 f5',
    'N4c3 b6',
    'Qd2 Bb7',
    'O-O-O Nd7',
    'd5 Nf6',
    'Nf4 exd5',
    'Nfxd5 Nxd5',
    'Nxd5 Bg5',
    'f4 Qxd5',
    'Qxd5 Bxf4+',
    'Kb1 Bxd5',
    'Rxd5 O-O',
    'Rxf5 Bd6',
    'Bc4 Rae8',
    'Rhf1 Re7',
    'Rg5+ Kh8',
    'Rgf5 Kg7',
    'Rg5+ Kh6',
  ],
  [
    'c4 Nf6',
    'Nc3 e6',
    'e4 d5',
    'e5 Nfd7',
    'd4 dxc4',
    'Nf3 Nb6',
    'Be3 Be7',
    'Nd2 f5',
    'Nxc4 Nd5',
    'Be2 O-O',
    'O-O b6',
    'Nxd5 exd5',
    'Na3 Bb7',
    'Nb5 Qd7',
    'Rc1 Nc6',
    'Nc3 Nd8',
    'f4 Ne6',
    'a3 a6',
    'b4 c6',
    'g4 g6',
    'gxf5 gxf5',
    'Rf3 Kh8',
    'Bd3 Rf7',
    'Rh3 Rg8+',
    'Kh1 c5',
    'Qh5 Nd8',
    'Ne2 c4',
    'Bb1 Bc8',
    'Ng3 Bf8',
    'Re1 h6',
    'Qf3 Qe6',
    'Rh5 Qg6',
    'Rg1 Qh7',
    'Qf1 Be6',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 Nf6',
    'Bb5 Qc7',
    'O-O Nd4',
    'Re1 a6',
    'e5 Nxb5',
    'exf6 gxf6',
    'Ne4 Bg7',
    'd4 f5',
    'Nxc5 Nxd4',
    'Nxd4 Qxc5',
    'Be3 Bf6',
    'b4 Qxb4',
    'Nxf5 d5',
    'Rb1 Qxe1+',
    'Qxe1 Bxf5',
    'Rxb7 O-O',
    'Bc5 Rab8',
    'Qb4 a5',
    'Qb3 Rxb7',
    'Qxb7 Bxc2',
    'Qxd5 Rc8',
    'Be3 a4',
    'h4 e6',
    'Qf3 Be5',
    'Qg4+ Kf8',
    'Bh6+ Ke7',
    'Qg5+ Kd6',
    'Qe3 Bf6',
    'Qb6+ Kd7',
    'Bf4 Be7',
    'Qb7+ Kd8',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 Nf6',
    'Bb5 Qc7',
    'O-O Nd4',
    'Nxd4 cxd4',
    'Nd5 Nxd5',
    'exd5 a6',
    'Ba4 g6',
    'd3 b5',
    'Bb3 Bg7',
    'd6 Qc6',
    'dxe7 d5',
    'Qf3 Be6',
    'Bg5 h6',
    'Bf6 Rg8',
    'Bxg7 Rxg7',
    'Qf6 Rg8',
    'Rfe1 Ra7',
    'a4 b4',
    'a5 Bd7',
    'Qxd4 Rc7',
    'Bxd5 Qc5',
    'Qf4 Bf5',
    'Bb3 Rxe7',
    'd4 Qc8',
    'Qd6 Qd8',
    'Ba4+ Bd7',
    'Bxd7+ Qxd7',
    'Qb8+ Qd8',
    'Rxe7+ Kxe7',
    'Re1+ Kd7',
    'Qb7+ Kd6',
    'Qxb4+ Kc6',
    'Qc5+ Kd7',
    'Qa7+ Kd6',
    'Qxa6+ Kd7',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'Qc2 O-O',
    'a3 Bxc3+',
    'Qxc3 d6',
    'Bg5 Nbd7',
    'e3 b6',
    'Ne2 Bb7',
    'Qd3 Be4',
    'Qd2 Bg6',
    'Nc3 e5',
    'Be2 Re8',
    'O-O h6',
    'Bh4 Ne4',
    'Bxd8 Nxd2',
    'Bxc7 exd4',
    'exd4 Rxe2',
    'Nxe2 Nxf1',
    'Kxf1 Rc8',
    'Bxd6 Rxc4',
    'Rc1 Rxc1+',
    'Nxc1 f6',
    'Na2 Kf7',
    'Nc3 Bd3+',
    'Ke1 f5',
    'Be5 Bc4',
    'Kd2 g5',
    'g3 a6',
    'Ke3 b5',
    'f4 Nb6',
    'Nb1 Nd5+',
    'Kf2 Bd3',
    'Nd2 Bc2',
    'fxg5 hxg5',
    'Nf3 g4',
    'Ng5+ Ke7',
    'Ke2 Bb3',
    'h4 gxh3',
    'Nxh3 Bc4+',
    'Kf3 Bf1',
    'Nf2 Ke6',
    'g4 fxg4+',
    'Nxg4 Bd3',
    'Bf4 Nf6',
    'Nf2 Bc2',
    'Ke2 Ne4',
    'Nh3 Nf6',
    'Bd2 Kd5',
    'Be3 Bf5',
    'Ng5 Kc4',
    'Kd1 Nd5',
    'Bf2 Kb3',
    'Kc1 Nf4',
    'Bh4 Nd3+',
    'Kd2 Nxb2',
    'd5 Nc4+',
    'Kc1 Kxa3',
    'Nf3 b4',
    'Be7 a5',
    'Nd4 Bd7',
    'Nc2+ Kb3',
    'Bf8 Kc3',
    'Bg7+ Kd3',
    'Nd4 Ke4',
    'd6 Nxd6',
    'Nb3 Nb7',
    'Bf8 a4',
    'Nc5+ Nxc5',
    'Bxc5 b3',
  ],
  [
    'Nf3 Nf6',
    'd4 g6',
    'Nc3 d5',
    'Bf4 Bg7',
    'e3 O-O',
    'Be2 b6',
    'Ne5 c5',
    'O-O Bb7',
    'a4 a6',
    'a5 cxd4',
    'exd4 b5',
    'Re1 e6',
    'Nd3 Nfd7',
    'Bd6 Re8',
    'Ne5 Nxe5',
    'Bxe5 Bf8',
    'Bf1 Nc6',
    'Qf3 Nxe5',
    'dxe5 Rc8',
    'Qf4 Bg7',
    'Bd3 f6',
    'exf6 Qxf6',
    'Qxf6 Bxf6',
    'Nd1 e5',
    'c3 e4',
    'Bc2 Red8',
    'Bb3 Kg7',
    'Kf1 b4',
    'Re3 bxc3',
    'bxc3 d4',
    'Rh3 dxc3',
    'Rc1 Rd2',
    'Nxc3 Bd4',
    'Ke1 e3',
  ],
  [
    'c4 f5',
    'g3 Nf6',
    'Bg2 e6',
    'd4 Be7',
    'Nf3 O-O',
    'O-O Qe8',
    'Nc3 Bd8',
    'b3 Qh5',
    'Qc2 Nc6',
    'Bb2 d5',
    'e3 a5',
    'a3 Ne7',
    'Ne5 Ng4',
    'Nxg4 fxg4',
    'e4 dxc4',
    'bxc4 e5',
    'd5 Ra6',
    'Bc1 Ng6',
    'Ne2 Bg5',
    'Bxg5 Qxg5',
    'Qc1 Qh5',
    'Qe3 Raf6',
    'Rac1 Nh8',
    'Bh1 g5',
    'Rc2 Rh6',
    'h4 gxh4',
    'f4 h3',
    'Kh2 Rhf6',
    'f5 b6',
    'Nc1 Nf7',
    'Nd3 Ba6',
    'Rb1 Re8',
    'Nf2 Kh8',
    'c5 Qg5',
    'Qxg5 Nxg5',
    'Nxg4 Rff8',
    'Nf2 bxc5',
    'Rxc5 Rb8',
    'Rcc1 Rfc8',
    'Nxh3 Rxb1',
    'Rxb1 Nf7',
    'Bf3 h6',
    'Nf2 Kg7',
    'Rc1 Bb7',
    'Nd3 c6',
    'd6 Rd8',
    'Nc5 Bc8',
    'd7 Bxd7',
    'Rb1 Bc8',
    'Rb6 Ng5',
    'Bg2 Rd2',
    'Kg1 Rd6',
    'Kf2 Kf6',
    'Ke3 Ke7',
    'Nb3 Kd8',
    'Nxa5 Kc7',
    'Nc4 Rd1',
  ],
  [
    'd4 Nf6',
    'Bg5 d5',
    'e3 e6',
    'c4 Be7',
    'cxd5 exd5',
    'Nc3 O-O',
    'Bd3 h6',
    'Bh4 b6',
    'Nge2 Bb7',
    'O-O Ne4',
    'Bxe7 Qxe7',
    'Nf4 Nxc3',
    'bxc3 Nd7',
    'Qb3 Nf6',
    'c4 Rfd8',
    'c5 Rab8',
    'Rfc1 bxc5',
    'Rxc5 g5',
    'Ne2 Ba6',
    'Qc2 Bxd3',
    'Qxd3 Ne4',
    'Rc2 Nd6',
    'Rc5 Ne4',
    'Rc2 Nd6',
    'Qa6 Rb6',
    'Qxa7 Nc4',
    'Nc3 Qd6',
    'Rac1 Rdb8',
    'h3 c6',
    'Na4 R8b7',
    'Qa8+ Rb8',
  ],
  [
    'd4 g6',
    'e4 d6',
    'Nc3 c6',
    'a4 Nd7',
    'f4 e6',
    'Nf3 d5',
    'e5 a6',
    'Bd3 Bb4',
    'O-O c5',
    'Na2 Ba5',
    'c3 c4',
    'Bc2 Ne7',
    'b3 b5',
    'Ba3 Nf5',
    'Qe2 h5',
    'Rab1 Qc7',
    'Qe1 Qa7',
    'bxc4 bxc4',
    'Bxf5 gxf5',
    'Qg3 Bb7',
    'Qg5 f6',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Bb5 Nf6',
    'Qe2 g6',
    'Bxc6 dxc6',
    'h3 Bg7',
    'd3 Nd7',
    'Nbd2 O-O',
    'a4 a5',
    'O-O Re8',
    'e5 Nf8',
    'Ne4 Ne6',
    'Re1 b6',
    'b3 f5',
    'Neg5 Nf8',
    'Nd2 h6',
    'Ngf3 Be6',
    'Bb2 Bd5',
    'Rad1 Qc7',
    'Nh4 Qc8',
    'Nc4 Rb8',
    'Bc1 g5',
    'Nf3 f4',
    'Bb2 Rd8',
    'Bc3 Ne6',
    'Ba1 Nf8',
    'Ncd2 Qf5',
    'Nh2 Ng6',
    'Qg4 Qxg4',
    'hxg4 Be6',
    'Nc4 Nf8',
    'f3 Nd7',
    'Nf1 Kf7',
    'Nfd2 Rh8',
    'Kf2 Nf8',
    'Rh1 Ng6',
    'Rde1 Rhd8',
    'Bc3 Rd7',
    'g3 fxg3+',
    'Kxg3 Nf4',
    'Bb2 Kg6',
    'Bc1 b5',
    'Na3 Rd5',
    'axb5 cxb5',
    'Bb2 Rdd8',
    'Ne4 c4',
    'd4 cxb3',
    'cxb3 Nd3',
    'Nc5 Nxc5',
    'dxc5 Rd3',
    'Nc2 Bxb3',
    'Nd4 Bd5',
    'Rhf1 e6',
    'c6 Rc8',
    'Nxb5 Rxc6',
    'Nd6 Rc2',
    'Bc1 Rxc1',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Bb5 Nf6',
    'Bxc6 dxc6',
    'd3 g6',
    'Bd2 Bg7',
    'Bc3 O-O',
    'Nbd2 Re8',
    'O-O b6',
    'Re1 Nd7',
    'Bxg7 Kxg7',
    'Nc4 e5',
    'Nd6 Re7',
    'Nxc8 Rxc8',
    'Qd2 Nf8',
    'a3 f6',
    'b4 Ne6',
    'bxc5 Nxc5',
    'a4 Ne6',
    'Qc3 Qd6',
    'Nd2 Qc5',
    'Qxc5 Nxc5',
    'f3 Ne6',
    'Kf2 Nd4',
    'Ra2 Rd7',
    'Nc4 Re8',
    'Rb2 Rde7',
    'Nd6 Rd8',
    'Nc4 Rde8',
    'Nd6 Rd8',
    'Nc4 Rf8',
    'Nd2 Rd8',
    'Nb3 Ne6',
    'g3 Rf7',
    'Nd2 Nc5',
    'Ra2 Ne6',
    'Nb3 Rdf8',
    'Kg2 Re8',
    'Raa1 Rd8',
    'Kf2 Rdf8',
  ],
  [
    'c4 e5',
    'Nc3 Nc6',
    'Nf3 f5',
    'd4 e4',
    'Ng5 Nf6',
    'Nh3 Bb4',
    'Bg5 h6',
    'Bxf6 Qxf6',
    'e3 Bxc3+',
    'bxc3 g5',
    'Qh5+ Qf7',
    'Qxf7+ Kxf7',
    'c5 Na5',
    'f3 b6',
    'fxe4 fxe4',
    'Nf2 Bb7',
    'Bb5 d6',
    'O-O Kg7',
    'Rad1 Bd5',
    'Ng4 Rhf8',
    'a3 Bc4',
    'Bxc4 Nxc4',
    'cxd6 cxd6',
    'd5 Rxf1+',
    'Kxf1 Nxa3',
    'Rd4 h5',
    'Nf2 Nb5',
    'Rc4 a5',
    'Nxe4 a4',
    'Rb4 a3',
    'Rb1 a2',
    'Ra1 Re8',
    'Nxg5 Nxc3',
    'Ke1 b5',
    'Kd2 b4',
    'Ne6+ Kf6',
    'Nd4 Ra8',
    'Kc2 Nxd5',
    'Kb2 Nxe3',
    'g3 Ra3',
    'Rxa2 Nd1+',
    'Ka1 Rd3',
    'Nc6 b3',
    'Re2 b2+',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Qc2 Nbd7',
    'cxd5 cxd5',
    'Bf4 e6',
    'a3 Qa5+',
    'Nbd2 Nb6',
    'Ne5 Bd7',
    'Nxd7 Nfxd7',
    'e3 Rc8',
    'Qb3 Be7',
    'Bd3 O-O',
    'Ke2 Qa4',
    'Rhc1 Qxb3',
    'Nxb3 Na4',
    'Rxc8 Rxc8',
    'Rb1 Ndb6',
    'Na5 Bxa3',
    'bxa3 Nc3+',
    'Kd2 Nxb1+',
    'Bxb1 Nc4+',
    'Nxc4 Rxc4',
    'Bd3 Rc8',
    'Bd6 f6',
    'Bc5 a6',
    'a4 Kf7',
    'a5 g6',
    'e4 f5',
    'f3 Kf6',
    'Bc2 Kf7',
    'Bb3 fxe4',
    'fxe4 dxe4',
    'Ke3 Kf6',
    'Kxe4 Rd8',
    'Bd1 Rd7',
    'Bf3 h6',
    'h4 Rf7',
    'Bd6 Rd7',
    'Bf8 Rh7',
    'Bd6 Rd7',
    'Be5+ Kf7',
    'Kd3 Ke8',
    'Kc4 Kd8',
    'Be4 g5',
    'h5 Kc8',
    'Kc5 Kd8',
    'Kb6 Kc8',
    'd5 Re7',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Nf3 g6',
    'd4 cxd4',
    'Nxd4 Bg7',
    'Be3 Nf6',
    'Bc4 O-O',
    'Bb3 d6',
    'h3 Na5',
    'O-O b6',
    'Re1 Nxb3',
    'axb3 Bb7',
    'Bg5 h6',
    'Bh4 Re8',
    'Qd2 a6',
    'Rad1 Kh7',
    'f4 Qc7',
    'Qf2 e6',
    'Nf3 b5',
    'b4 Rac8',
    'Rd2 Kg8',
    'e5 dxe5',
    'fxe5 Nd7',
    'Red1 Bc6',
    'Rxd7 Bxd7',
    'Nh2 h5',
    'Ne4 Bxe5',
    'Nf3 Bg7',
    'Nf6+ Bxf6',
    'Bxf6 e5',
    'Qe3 Re6',
    'Bxe5 Rxe5',
    'Qxe5 Qxe5',
    'Nxe5 Bf5',
    'c3 f6',
    'Nf3 Kf7',
    'Rd6 Ra8',
    'Nd4 a5',
    'Nxf5 gxf5',
    'bxa5 Rxa5',
    'Rb6 f4',
    'Kf2 Kg6',
    'Kf3 Kf5',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 Nf6',
    'd4 cxd4',
    'Nxd4 e5',
    'Ndb5 d6',
    'Bg5 a6',
    'Na3 b5',
    'Nd5 Be7',
    'Bxf6 Bxf6',
    'c3 Bb7',
    'Nc2 Nb8',
    'Nce3 Nd7',
    'Qg4 h5',
    'Qf3 g6',
    'a4 bxa4',
    'Rxa4 Bg5',
    'Bc4 O-O',
    'h4 Bxe3',
    'Qxe3 Kg7',
    'b4 a5',
    'Rxa5 Rxa5',
    'bxa5 Qxa5',
    'O-O Rc8',
    'Rb1 Bxd5',
    'Bxd5 Rxc3',
    'Qd2 Nf6',
    'Qa2 Qc5',
  ],
  ['e4 c5', 'Nf3 d6', 'd4 cxd4', 'Nxd4 Nf6', 'Nc3 Nc6', 'Be3 e5', 'Nb3 Be6', 'Qe2 Ng4', 'Bd2 a5', 'a4 Be7', 'h3 Nf6', 'Be3 Rc8', 'Qb5 O-O'],
  ['d4 Nf6', 'Bg5 c5', 'Bxf6 gxf6', 'd5 Qb6', 'Qc1 Bg7', 'c4 f5', 'Nc3 e6', 'e3 Na6', 'Bd3 d6', 'a3 Bd7', 'Nge2 O-O-O', 'Qd2 Kb8', 'O-O Rdg8', 'Rab1 Bf6', 'e4 fxe4', 'Bxe4 Rg4', 'Qd3 Be7', 'f4 Nc7'],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 Nf6',
    'd4 cxd4',
    'Nxd4 d6',
    'Be3 Ng4',
    'Bg5 h6',
    'Bh4 g5',
    'Bg3 Bg7',
    'Nb3 Be6',
    'Qd2 a5',
    'a4 Rc8',
    'f3 Nge5',
    'Nd5 Nb4',
    'c3 Nxd5',
    'Bb5+ Kf8',
    'exd5 Bf5',
    'O-O Ng6',
    'f4 g4',
    'Bf2 h5',
    'Bd4 Ra8',
    'Rae1 h4',
    'Bxg7+ Kxg7',
    'Nd4 Rh5',
    'Qd1 Bd7',
    'f5 Ne5',
    'Rxe5 dxe5',
    'Qxg4+ Kh6',
  ],
  [
    'Nf3 d5',
    'c4 d4',
    'd3 Nc6',
    'g3 e5',
    'Bg2 Bb4+',
    'Bd2 a5',
    'O-O Nf6',
    'Na3 O-O',
    'Nb5 Re8',
    'e3 Bf5',
    'e4 Bg4',
    'h3 Bh5',
    'Bc1 Nd7',
    'Na3 Bd6',
    'Nc2 Nc5',
    'Qd2 Nb4',
    'Nfe1 f6',
    'f3 Qd7',
    'b3 b5',
    'cxb5 Nxc2',
    'Nxc2 Qxb5',
    'Rd1 Bf7',
    'Bf1 a4',
    'Na3 Qd7',
    'b4 Na6',
    'b5 Bb4',
    'Qf2 Bxa3',
    'Bxa3 Qxb5',
    'Qd2 c5',
    'Rab1 Qc6',
    'Rdc1 Reb8',
    'f4 Qd6',
    'Rxb8+ Rxb8',
    'Qa5 exf4',
    'gxf4 f5',
    'e5 Qg6+',
    'Kh2 Bd5',
    'Rc2 Qc6',
    'Rb2 Rxb2+',
    'Bxb2 Nc7',
    'a3 Ne6',
    'Bc1 h6',
    'h4 Qe8',
    'Qe1 Qb5',
    'Qd2 Bb7',
    'Qf2 Qc6',
    'Bd2 c4',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Be3 e6',
    'Nd2 Ne7',
    'c3 Bg6',
    'f4 h5',
    'Ngf3 Nf5',
    'Bf2 Qc7',
    'b4 a5',
    'a3 Nd7',
    'Be2 Be7',
    'O-O b5',
    'a4 axb4',
    'axb5 O-O',
    'Qb3 Ra3',
    'Rxa3 bxa3',
    'c4 dxc4',
    'Nxc4 cxb5',
    'Nxa3 b4',
    'Nc4 Ra8',
    'Nfd2 Nb6',
    'Nxb6 Qxb6',
    'Qb2 Qa5',
    'Bc4 Qa3',
    'Qb3 Rd8',
    'Nf3 Qxb3',
    'Bxb3 Rc8',
    'Ra1 Rc3',
    'Bd1 b3',
    'Be1 Rd3',
    'Ra8+ Kh7',
    'Be2 Rxd4',
    'Nxd4 Nxd4',
    'Bc4 b2',
    'Ba2 b1=Q',
    'Bxb1 Bxb1',
    'Kf2 Nf5',
    'Rc8 Be4',
    'g3 Kg6',
    'Bb4 Bxb4',
    'Rc4 Bd5',
    'Rxb4 Nh6',
    'Rb8 Kf5',
    'Rh8 Kg4',
    'Rc8 Kh3',
    'Kg1 Ng4',
    'Rc2 h4',
    'gxh4 Kxh4',
    'Rc3 Nh6',
    'Rg3 Nf5',
    'Rg5 Bf3',
    'Kf2 Bg4',
    'Kg2 g6',
    'h3 Bxh3+',
    'Kf3 Nd4+',
    'Ke3 Nc2+',
    'Kf3 Ne1+',
    'Ke2 Bg4+',
    'Kxe1 Kg3',
    'f5 exf5',
    'e6 fxe6',
    'Rxg6 f4',
    'Rg5 f3',
    'Rg8 e5',
    'Rf8 Kg2',
    'Kd2 f2',
    'Ke3 f1=Q',
    'Rxf1 Kxf1',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'O-O Be7',
    'Re1 b5',
    'Bb3 O-O',
    'c3 d5',
    'exd5 Nxd5',
    'Nxe5 Nxe5',
    'Rxe5 c6',
    'd3 Bd6',
    'Re1 Bf5',
    'Qf3 Qh4',
    'g3 Qh3',
    'Nd2 Rae8',
    'Ne4 Bg4',
    'Qg2 f5',
    'Qxh3 Bxh3',
    'Bd2 fxe4',
    'dxe4 Bc5',
    'Be3 Rxe4',
    'Bxc5 Rfe8',
    'Rxe4 Rxe4',
    'Be3 Kf8',
    'Re1 Nxe3',
    'fxe3 h6',
    'Kf2 Bd7',
    'h4 Re8',
    'e4 Ke7',
    'Ke3 Rf8',
    'a4 Rb8',
    'Re2 b4',
    'Bc4 bxc3',
    'bxc3 Rb1',
    'Kd4 Ra1',
    'Ra2 Rg1',
    'Bxa6 Rxg3',
    'c4 Rb3',
    'c5 Rb4+',
    'Ke5 Be6',
    'Ra1 Bb3',
    'Rb1 g5',
    'hxg5 hxg5',
    'Be2 g4',
    'Bd1 g3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 Ng4',
    'Bc1 Nf6',
    'Be3 Ng4',
    'Bg5 h6',
    'Bh4 g5',
    'Bg3 Bg7',
    'Qd2 Nc6',
    'Nb3 Be6',
    'f3 Nge5',
    'Nd5 b5',
    'Bf2 Rb8',
    'c3 Nc4',
    'Qc2 Qd7',
    'Be2 f5',
    'O-O fxe4',
    'fxe4 O-O',
    'a4 Qb7',
    'axb5 axb5',
    'h4 g4',
    'Nd2 N4e5',
    'b4 Ng6',
    'g3 Nge5',
    'Be3 Rbc8',
    'Qa2 Nf3+',
    'Nxf3 gxf3',
    'Bxf3 Ne5',
    'Bh5 Rxf1+',
    'Rxf1 Rxc3',
    'Qd2 Nc4',
    'Qf2 Nxe3',
    'Bf7+ Kh8',
    'Bxe6 Nxd5',
    'Bxd5 Qc8',
    'Qe1 Bd4+',
    'Kg2 Rc2+',
    'Kf3 h5',
  ],
  [
    'Nf3 d5',
    'c4 d4',
    'a3 a5',
    'e3 Nc6',
    'Nxd4 Nxd4',
    'exd4 Qxd4',
    'Nc3 Nf6',
    'Be2 Bf5',
    'd3 e5',
    'Qb3 Be7',
    'Qxb7 O-O',
    'O-O Qd7',
    'Qf3 Rab8',
    'Rd1 Rfd8',
    'Qg3 Qe6',
    'Re1 Bd6',
    'Nb5 Nd7',
    'Be3 c6',
    'Nxd6 Qxd6',
    'Rab1 Nf8',
    'Red1 Rb3',
    'c5 Qc7',
    'Bg5 Rdb8',
    'Qf3 Bg6',
    'h4 e4',
    'Qg4 exd3',
    'Bf3 h5',
    'Qa4 Ne6',
    'Be3 d2',
    'Ra1 Rxb2',
    'Qxc6 Qxc6',
    'Bxc6 Rd8',
    'Bf3 Nd4',
    'c6 Nb3',
    'c7 Rc8',
    'Bf4 Bc2',
  ],
  [
    'e4 c5',
    'Nf3 g6',
    'd4 Bg7',
    'dxc5 Qa5+',
    'c3 Qxc5',
    'Na3 d6',
    'Nb5 a6',
    'Be3 Qc6',
    'Nbd4 Qc7',
    'Be2 Nf6',
    'Nd2 O-O',
    'O-O Nc6',
    'a4 d5',
    'Nxc6 bxc6',
    'exd5 cxd5',
    'f4 h5',
    'Bd4 Bg4',
    'h3 Bxe2',
    'Qxe2 e6',
    'b4 Nd7',
    'Qe3 Bxd4',
    'Qxd4 Rfc8',
    'Rf3 a5',
    'Nb3 axb4',
    'cxb4 Qd6',
    'Nd2 e5',
    'fxe5 Nxe5',
    'Rb3 Nc6',
    'Qb2 Rab8',
    'b5 Qc5+',
    'Kh1 Nd4',
    'Rd3 Nf5',
    'Qe5 Rd8',
    'Ne4 Qe7',
    'Nf6+ Kf8',
    'Nd7+ Qxd7',
    'Qh8+ Ke7',
    'Re1+ Qe6',
    'Rxe6+ Kxe6',
    'Qb2 h4',
    'b6 d4',
    'a5 Rd6',
    'b7 Kd5',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'Bg5 Bg7',
    'c3 O-O',
    'Nbd2 d5',
    'e3 b6',
    'Bd3 c5',
    'O-O Bb7',
    'Qe2 Nbd7',
    'Rad1 Re8',
    'Bf4 Ne4',
    'Bc2 Qc8',
    'Ba4 Rd8',
    'Rfe1 Ndf6',
    'h3 e6',
    'Nxe4 Nxe4',
    'Bb5 Nd6',
    'Bd3 a5',
    'Ne5 f6',
    'Nf3 Nf7',
    'e4 e5',
    'dxe5 fxe5',
    'Bg3 c4',
    'Bc2 d4',
    'cxd4 exd4',
    'Ba4 Qc5',
    'e5 b5',
    'e6 Nh6',
    'e7 Re8',
    'Qe6+ Nf7',
    'Bxb5 Qxb5',
    'Nxd4 Qc5',
    'Qd7 Bxd4',
    'Qxd4 Qxd4',
    'Rxd4 Rac8',
    'Rd7 Bc6',
    'Ra7 Ra8',
    'Rc7 Rac8',
    'Ra7 a4',
    'Rc1 Bd5',
    'Rd1 Bc6',
    'Rd4 a3',
    'Rxa3 Rxe7',
    'Rxc4 Re6',
    'Rac3 Ng5',
    'f3 Rd8',
    'Rxc6 Rd1+',
    'Kh2 Nxf3+',
    'gxf3 Re2+',
    'Bf2 Rxf2+',
    'Kg3 Rxb2',
    'Ra6 Rg1+',
    'Kf4 Rb4+',
    'Ke3 Re1+',
    'Kf2 Re7',
    'Rca3 Rb2+',
    'Kg3 Ree2',
    'Ra7 Rb5',
    'h4 Rbb2',
    'Rc3 Rg2+',
    'Kf4 Rb4+',
    'Ke3 Rb8',
    'Rcc7 Re8+',
    'Kd3 Rd8+',
    'Kc3 Rgd2',
    'Rg7+ Kf8',
    'Rxh7 R2d3+',
    'Kc4 R3d4+',
    'Kc5 R4d5+',
    'Kc6 R5d6+',
    'Kc7 Kg8',
    'Re7 Kf8',
    'Rh7 Kg8',
  ],
  [
    'Nf3 d5',
    'd4 Nf6',
    'c4 c6',
    'Qc2 g6',
    'Bf4 Bg7',
    'e3 O-O',
    'Nc3 c5',
    'dxc5 Qa5',
    'Rd1 Be6',
    'Nd4 Qxc5',
    'Nxe6 fxe6',
    'Be2 Nc6',
    'O-O Rad8',
    'a3 d4',
    'b4 Qf5',
    'Bd3 Qh5',
    'Be2 Qf5',
    'Bd3 Qh5',
    'Be2 Qf5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'Nc3 a6',
    'g3 b5',
    'Bg2 Bb7',
    'd3 d6',
    'O-O Nd7',
    'Qe2 Qc7',
    'Ng5 Ngf6',
    'f4 b4',
    'Nb1 h6',
    'Nh3 Be7',
    'Nd2 O-O',
    'g4 Nh7',
    'Nf3 c4',
    'dxc4 Rac8',
    'b3 d5',
    'exd5 Bc5+',
    'Kh1 exd5',
    'cxd5 Ndf6',
    'g5 Rfe8',
    'Qd3 Nxd5',
    'gxh6 Rcd8',
    'Nhg5 Ndf6',
    'Qc4 Nxg5',
    'fxg5 Re4',
    'gxf6 Rxc4',
    'bxc4 g6',
    'Bb2 Bf8',
    'Rae1 Bxf3',
    'Bxf3 Qxc4',
    'Be4 Re8',
    'h7+ Kxh7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'O-O Nc6',
    'Nc3 a6',
    'b3 Rb8',
    'Bb2 b5',
    'cxb5 axb5',
    'Qc2 e6',
    'e4 Bd7',
    'e5 Nb4',
    'Qd2 Nfd5',
    'Ne4 Bc6',
    'Ba3 Na6',
    'Rfe1 b4',
    'Bb2 Rb6',
    'h4 Ne7',
    'exd6 cxd6',
    'd5 Nxd5',
    'Bxg7 Kxg7',
    'h5 Nf6',
    'h6+ Kg8',
    'Nxf6+ Qxf6',
    'Qxd6 Rd8',
    'Qe5 Qxe5',
    'Nxe5 Bxg2',
    'Kxg2 Nc7',
    'Rac1 Nd5',
    'Rc4 f6',
    'Nc6 Re8',
    'Ree4 e5',
    'Nxb4 Ne7',
    'Rc7 Nf5',
    'Nc6 Nxh6',
    'Ra4 Nf7',
    'Raa7 Ng5',
    'Ne7+ Kf8',
    'Nd5 Rd6',
    'Ne3 f5',
    'Nc4 Rd3',
    'Rd7 Rc3',
    'Ne3 f4',
    'gxf4 exf4',
    'Nd5 f3+',
    'Kh2 Rc2',
    'Nf6 Rxf2+',
    'Kg3 Rg2+',
    'Kf4 Nh3+',
    'Kxf3 Rf2+',
    'Kg4 Rxf6',
    'Kxh3 Re3+',
    'Kg2 Re2+',
    'Kg3 Re3+',
    'Kg2 Re2+',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Qc7',
    'Nc3 Nf6',
    'O-O Nc6',
    'Nb3 b5',
    'f4 d6',
    'Qf3 Rb8',
    'Bd2 Be7',
    'Rae1 O-O',
    'Kh1 b4',
    'Nd1 a5',
    'Ne3 a4',
    'Nc1 Nd4',
    'Qf2 Bb7',
    'c3 bxc3',
    'Bxc3 Nc6',
    'Qg3 g6',
    'Ne2 Rfe8',
    'Rc1 Ba8',
    'f5 Nh5',
    'Qg4 Qd7',
    'e5 Nxe5',
    'Bxe5 dxe5',
    'fxg6 fxg6',
    'Bxg6 Ng7',
    'Bxh7+ Kxh7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'O-O b5',
    'Bb3 Bb7',
    'c3 Nxe4',
    'd4 Na5',
    'Bc2 exd4',
    'Nxd4 c5',
    'Nf5 d5',
    'a4 Qd7',
    'f3 Nd6',
    'Re1+ Kd8',
    'Ne3 Ndc4',
    'axb5 axb5',
    'Bf5 Nxe3',
    'Bxd7 Nxd1',
    'Bxb5 Bd6',
    'Rxd1 Kc7',
    'Nd2 Bc6',
    'Bd3 Rhe8',
    'c4 dxc4',
    'Nxc4 Nxc4',
    'Rxa8 Rxa8',
    'Bxc4 f6',
    'Be3 Rb8',
  ],
  [
    'd4 Nf6',
    'Bg5 c5',
    'Bxf6 gxf6',
    'd5 Qb6',
    'Qc1 Bg7',
    'g3 d6',
    'Bg2 f5',
    'c3 Nd7',
    'Nd2 Nf6',
    'Nh3 h5',
    'Qc2 Bd7',
    'a4 h4',
    'Nf4 hxg3',
    'hxg3 Rxh1+',
    'Bxh1 O-O-O',
    'Nc4 Qa6',
    'Qd3 Rh8',
    'Bg2 Kc7',
    'Ra3 Ng4',
    'b4 cxb4',
    'cxb4 Bxa4',
    'Nxd6 Qb6',
    'Qc4+ Kxd6',
    'Qc5+ Qxc5',
    'bxc5+ Kxc5',
    'Rxa4 Bc3+',
    'Kd1 a5',
    'Nd3+ Kd6',
    'Rc4 Bg7',
    'Bf3 Ne5',
    'Nxe5 Bxe5',
    'Kc2 Rh2',
    'Kb3 Rxf2',
    'Ka4 Bxg3',
    'Kxa5 Rf1',
    'Rc8 Rb1',
    'Ka4 Ke5',
    'Rf8 Kd4',
    'Ka3 Kc3',
    'Rc8+ Kd2',
    'Rf8 Be5',
    'Rc8 b5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Ba7',
    'O-O Nc6',
    'Nc3 Qh4',
    'Qe2 Nf6',
    'h3 d6',
    'Be3 O-O',
    'Bxa7 Rxa7',
    'Qe3 Ra8',
    'Rad1 Rd8',
    'f4 Nd7',
    'Rd2 b6',
    'e5 dxe5',
    'Be4 Bb7',
    'fxe5 Ndxe5',
    'Qxb6 Rxd2',
    'Nxd2 Qd8',
    'Qe3 Qc7',
    'Nf3 Nxf3+',
    'Bxf3 Ne7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Bc4 Bf5',
    'Bd2 e6',
    'Qe2 Bb4',
    'O-O-O Nf6',
    'a3 Bxc3',
    'Bxc3 Qc7',
    'd5 cxd5',
    'Bxd5 Nxd5',
    'Rxd5 O-O',
    'Rd2 Nc6',
    'Nf3 Rfd8',
    'Rhd1 Bg4',
    'h3 Rxd2',
    'Rxd2 Bxf3',
    'Qxf3 f6',
    'Qe4 Re8',
    'f4 Qe7',
    'Qc4 Qf7',
    'Re2 h6',
    'b3 a6',
    'a4 e5',
    'Qe4 f5',
    'Qe3 e4',
    'g4 Ne7',
    'Be5 Nd5',
    'Qg3 Rxe5',
    'fxe5 f4',
    'Qe1 e3',
    'c4 Qc7',
    'Qh1 Nc3',
    'Re1 Qd7',
    'Qh2 Qd4',
    'e6 Ne4',
    'e7 Qd2+',
    'Kb1 Nc3+',
    'Ka1 Qxh2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Bf4 Bg7',
    'e3 c5',
    'dxc5 Qa5',
    'Rc1 Ne4',
    'cxd5 Nxc3',
    'Qd2 Qxa2',
    'Rxc3 O-O',
    'Bc4 Qa1+',
    'Rc1 Qxb2',
    'e4 Qa3',
    'Nf3 Qxc5',
    'd6 Qa3',
    'Bxf7+ Kh8',
    'Bg5 Nc6',
    'dxe7 Nxe7',
    'Rc7 Qa1+',
    'Rc1 Qa3',
    'Rc7 Qa1+',
    'Ke2 Qxh1',
    'Bxe7 Bg4',
    'Bxf8 Rxf8',
    'h3 Bxf3+',
    'gxf3 Qxh3',
    'Rxb7 Qc8',
    'Bd5 Qc5',
    'e5 Bxe5',
    'Qh6 Bc7',
    'Kf1 Rb8',
    'Qh4 Qc1+',
    'Kg2 Qf4',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 Bf5',
    'Nc3 a6',
    'Bd3 Bxd3',
    'Qxd3 b5',
    'cxd5 cxd5',
    'O-O e6',
    'Bd2 Bd6',
    'Rfc1 O-O',
    'Ne2 Qb6',
    'Rc2 Nc6',
    'Rac1 Rac8',
    'Be1 Rc7',
    'Ng3 Rfc8',
    'Bd2 h6',
    'Be1 g6',
    'Bd2 Kg7',
    'Be1 a5',
    'Bd2 a4',
    'Be1 Ra8',
    'Bd2 Rac8',
    'Be1 Qa6',
    'Bd2 a3',
    'bxa3 Bxa3',
    'Rb1 Qa4',
    'Ne1 Nb4',
    'Rxc7 Rxc7',
    'Qb3 Rc4',
    'Bxb4 Bxb4',
    'Nd3 Bd6',
    'Qxb5 Qxa2',
    'Qb2 Qa5',
    'Qb6 Qa3',
    'Qb3 Qa8',
    'Qd1 h5',
    'Ra1 Qc8',
    'Nf1 Rc3',
    'h3 Ne4',
    'Ne1 Qd8',
    'Nf3 Qc7',
    'Ne1 Be7',
    'Nd2 Nd6',
    'Nef3 Bf6',
    'Rb1 Rc2',
    'Ne1 Rc3',
    'Nef3 Nf5',
    'Nb3 Be7',
    'Rc1 Ba3',
    'Rxc3 Qxc3',
    'Nbd2 Bd6',
    'Nf1 Bc7',
    'Qa4 Nd6',
    'Qd1 Ne4',
    'N3d2 Qd3',
    'Qb1 Qxb1',
    'Nxb1 Ba5',
    'f3 Nd6',
    'Kf2 f6',
    'Nbd2 e5',
    'dxe5 fxe5',
    'Ke2 h4',
    'Nb3 Bb6',
    'Nc1 Kf6',
    'Nd3 Ke6',
    'Nf2 Nb5',
    'Kd3 Nd6',
    'Nh2 Nf5',
    'Nf1 Ng7',
    'Ke2 Nh5',
    'Nd3 Ba5',
    'Nc5+ Ke7',
    'Nd3 Kd6',
    'Nf2 g5',
    'Nd3 e4',
    'fxe4 dxe4',
    'Nf2 Kd5',
    'Ng4 Bc3',
    'Nf2 Ng3+',
    'Nxg3 hxg3',
    'Nxe4 Kxe4',
    'Kf1 Be5',
    'Kg1 Kd3',
    'e4 Ke3',
    'Kf1 Kd4',
    'Kg1 Kc4',
    'Kh1 Kd3',
    'Kg1 Ke2',
    'Kh1 Bd4',
    'e5 Bxe5',
    'Kg1 Ke1',
    'Kh1 Kf2',
    'h4 gxh4',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'e4 Nxc3',
    'bxc3 Bg7',
    'Bc4 c5',
    'Ne2 Nc6',
    'Be3 O-O',
    'O-O Bd7',
    'Rb1 a6',
    'dxc5 Na5',
    'Bd3 Qc7',
    'Rb6 Rfd8',
    'Qb1 e6',
    'Bg5 Re8',
    'Qb4 Nc6',
    'Qa3 Ne5',
    'Bf4 Bf8',
    'Rbb1 Bxc5',
    'Qb3 Rab8',
    'Kh1 Red8',
    'Nd4 Bd6',
    'Be2 Rdc8',
    'Rbd1 Nc4',
    'Bc1 Bf8',
    'f4 Nb6',
    'Rd3 Na4',
    'Ba3 Bxa3',
    'Qxa3 Qc5',
    'Qb3 Qa5',
    'Qb4 Qxb4',
    'cxb4 Nc3',
    'a3 Nxe4',
    'Bf3 Nf6',
    'g4 Ba4',
    'g5 Ne8',
    'Bd1 Bxd1',
    'Rfxd1 Rc4',
    'Ne2 Re4',
    'R1d2 Nc7',
    'Nc3 Rxf4',
    'Rd7 Nb5',
    'Ne2 Rf1+',
    'Kg2 Rf5',
    'h4 Nxa3',
    'Ng3 Rd5',
    'R2xd5 exd5',
    'Rxd5 Nc2',
    'Rd7 Nxb4',
    'Ne4 a5',
    'Nd6 a4',
    'Nxf7 a3',
    'Nh6+ Kf8',
    'Rxh7 Nc6',
    'h5 a2',
    'Rf7+ Ke8',
    'Rf1 Ra8',
    'Ra1 Nd4',
    'Re1+ Kf8',
    'Rf1+ Nf5',
    'hxg6 a1=Q',
    'Rxa1 Nh4+',
    'Kg3 Rxa1',
    'Kxh4 Rf1',
    'Kg4 b5',
    'Nf5 b4',
    'Ne3 Rc1',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 Nc6',
    'Be3 e5',
    'Nb3 Be6',
    'Be2 Be7',
    'O-O O-O',
    'f4 exf4',
    'Bxf4 d5',
    'exd5 Nxd5',
    'Nxd5 Qxd5',
    'Qxd5 Bxd5',
    'Rad1 Be6',
    'Bf3 Rac8',
    'c3 Rfd8',
    'Be3 Rxd1',
    'Rxd1 b6',
    'Bf4 Kf8',
    'h3 a5',
    'Kh1 a4',
    'Nd2 Na5',
    'a3 b5',
    'Be2 Nc4',
    'Nxc4 Bxc4',
    'Bxc4 Rxc4',
    'Bd6 Bxd6',
    'Rxd6 Rc5',
    'Rd7 Re5',
    'Rb7 g5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Nge7',
    'Na3 cxd4',
    'cxd4 Nf5',
    'Nc2 Qb6',
    'Be2 Bb4+',
    'Kf1 Be7',
    'g3 Bd7',
    'Bd2 O-O',
    'h4 f6',
    'g4 Nfxd4',
    'Ncxd4 fxe5',
    'Nxc6 bxc6',
    'Bc3 Bd6',
    'Rh3 Rab8',
    'Bd3 Qc7',
    'Ng5 h6',
    'Bh7+ Kh8',
    'Qc2 Rf4',
    'Qg6 Rf6',
    'Nf7+ Rxf7',
    'Qxf7 Kxh7',
    'g5 Be8',
    'g6+ Kh8',
    'Qxc7 Bxc7',
    'h5 d4',
    'Rf3 Kg8',
    'Be1 c5',
    'Rc1 Bd6',
    'Rb3 Rxb3',
    'axb3 Bb5+',
    'Kg2 Kf8',
    'f3 Bd3',
    'Bg3 Ke7',
    'b4 cxb4',
    'Rc8 e4',
    'Rg8 Bxg3',
    'Rxg7+ Kf8',
    'Rf7+ Kg8',
    'Kxg3 e3',
    'Rd7 e5',
    'f4 exf4+',
    'Kxf4 e2',
    'Re7 Kf8',
    'Re5 a5',
    'Kf3 a4',
    'Kf2 a3',
    'bxa3 b3',
    'g7+ Kxg7',
    'Re7+ Kf6',
    'Rb7 Bc4',
    'Rb4 Bf7',
    'a4 d3',
    'a5 Bxh5',
    'Ke1 Ke5',
    'a6 Bf3',
    'a7 h5',
    'Rxb3 Kd4',
    'Rb8 h4',
    'Rd8+ Kc3',
    'Rc8+ Kd4',
    'Kd2 h3',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 d6',
    'Nh3 Nh6',
    'O-O O-O',
    'Kh1 Kh8',
    'Ng1 f5',
    'Bg5 Nf7',
    'Be3 Bd7',
    'Qd2 Rb8',
    'Rae1 b5',
    'a3 a5',
    'exf5 gxf5',
    'Nh3 b4',
    'Nd1 e6',
    'Nf4 Re8',
    'Nh5 Bf8',
    'c3 bxa3',
    'bxa3 Nce5',
    'f4 Ng4',
    'Bg1 Nf6',
    'Nxf6 Qxf6',
    'd4 Bb5',
    'Rf2 cxd4',
    'cxd4 d5',
    'Rf3 a4',
    'Nf2 Nd6',
    'g4 Bg7',
    'gxf5 exf5',
    'Re5 Nc4',
    'Qe1 Nxe5',
    'fxe5 Qe7',
    'Nh3 Bc4',
    'Qa5 Qd7',
    'Ng5 Rb3',
    'e6 Qe7',
    'Rxf5 Rb1',
    'Qd2 Qxa3',
    'Nf7+ Kg8',
    'Nh6+ Kh8',
    'Nf7+ Kg8',
    'Nh6+ Bxh6',
    'Qxh6 Rxg1+',
    'Kxg1 Qa1+',
    'Bf1 Qxd4+',
    'Rf2 Kh8',
    'Bxc4 Rg8+',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Ba7',
    'O-O Nc6',
    'Nc3 d6',
    'Qe2 Nge7',
    'Be3 b5',
    'Bxa7 Rxa7',
    'Qe3 O-O',
    'a4 b4',
    'Ne2 Rd7',
    'a5 Bb7',
    'Rfc1 Qa8',
    'Ra4 e5',
    'Nd2 f5',
    'Nc4 Qe8',
    'Nb6 Rd8',
    'f3 Kh8',
    'Bc4 Qg6',
    'Nd5 Nxd5',
    'Bxd5 fxe4',
    'Qxe4 Qe8',
    'c4 Nd4',
    'Rxb4 Bxd5',
    'cxd5 Rc8',
    'Rc3 Rb8',
    'Rxb8 Qxb8',
    'Nxd4 exd4',
    'Qxd4 Qxb2',
    'Rd3 Qb5',
    'Qc3 h6',
    'Rd4 Qb1+',
    'Kf2 Re8',
    'Re4 Rb8',
    'Kg3 Qb7',
    'Qc6 Qxc6',
    'dxc6 Rc8',
    'Rb4 Kg8',
    'Rb6 Kf7',
    'Rxa6 Ke6',
    'Rb6 Kd5',
    'a6 Kc5',
    'Rb1 Rc7',
    'Kf4 d5',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Nxd4 Nf6',
    'Nc3 Bb4',
    'Nxc6 bxc6',
    'Bd3 d5',
    'exd5 O-O',
    'O-O cxd5',
    'Bg5 c6',
    'Na4 h6',
    'Bh4 Re8',
    'c4 Bd6',
    'Rc1 Rb8',
    'b3 Be6',
    'cxd5 Bxd5',
    'Bc4 Bf4',
    'Rc3 Be5',
    'Rc2 Qd6',
    'Bxf6 Bxf6',
    'Bxd5 cxd5',
    'Nc5 Be5',
    'g3 Rbc8',
    'Nd3 Bc3',
    'Nf4 d4',
    'Qd3 Ba5',
    'Rc4 Rxc4',
    'bxc4 Qe5',
    'Rb1 Bc3',
    'Rb7 Re7',
    'Rb5 Qd6',
    'Nd5 Re6',
    'Rb7 Re8',
    'Qf3 Rf8',
    'Rxa7 Bb4',
    'Qf5 Bc5',
    'Rc7 Ba3',
    'c5 Bxc5',
    'Rxc5 g6',
    'Nf6+ Kg7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Nf3 Nf6',
    'Bc4 Bf5',
    'Ne5 e6',
    'g4 Bg6',
    'h4 Bd6',
    'h5 Be4',
    'O-O Bd5',
    'Bd3 Nbd7',
    'Re1 g6',
    'Qe2 Bxe5',
    'dxe5 Ng8',
    'Bd2 Qc7',
    'Nxd5 cxd5',
    'c4 d4',
    'Bb4 Nc5',
    'Bxc5 Qxc5',
    'Qf3 Qc7',
    'Be4 O-O-O',
    'Rad1 gxh5',
    'g5 h6',
    'Rd3 hxg5',
    'Rb3 b6',
    'c5 Qxc5',
    'Qxf7 Ne7',
    'Qxe6+ Kb8',
    'a4 Nd5',
    'Rb5 Nc7',
    'Qf7 Rhf8',
    'Rxc5 Rxf7',
    'Rc6 Re7',
    'Rd6 Red7',
    'Rg6 g4',
    'f3 d3',
    'fxg4 d2',
    'Rd1 Rd4',
    'Bf3 hxg4',
    'Bxg4 Nd5',
    'Kf2 Rf8+',
    'Bf3 Rd3',
    'Rg3 Ne3',
    'Rxd2 Rxd2+',
    'Kxe3 Rxb2',
    'Bd5 Re8',
    'e6 Kc7',
    'Rg7+ Kd6',
    'Rd7+ Ke5',
    'a5 Rb5',
    'Bc4 Rxa5',
    'Kd3 Rxe6',
    'Bxe6 Kxe6',
    'Rh7 Kd6',
    'Kc4 Kc6',
    'Rh6+ Kb7',
    'Rh7+ Ka6',
    'Kb4 Rg5',
    'Rh4 Rg7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Qxd4 Nc6',
    'Bb5 Bd7',
    'Bxc6 Bxc6',
    'Nc3 Nf6',
    'Bg5 e6',
    'O-O-O Be7',
    'Qd3 Qa5',
    'Kb1 O-O',
    'h4 h6',
    'Bxf6 Bxf6',
    'Nd4 d5',
    'exd5 Bxd5',
    'Nxd5 Qxd5',
    'Qf3 Qc4',
    'Nb3 Qb5',
    'c3 Rfd8',
    'g3 a5',
    'Nc1 Rac8',
    'Ka1 Qc5',
    'Nb3 Qa7',
    'Nd2 b5',
    'Ne4 Be7',
    'g4 Rxd1+',
    'Rxd1 Bxh4',
    'Rh1 Qe7',
    'Qd3 b4',
    'c4 Rd8',
    'Qe3 a4',
    'Kb1 Bf6',
    'Qe2 Bd4',
    'Rd1 b3',
    'axb3 axb3',
    'Rd3 Qb7',
    'f3 Ra8',
    'Kc1 Qb4',
    'Nc3 Bxc3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 Nfd7',
    'Qd2 Nb6',
    'O-O-O N8d7',
    'Bg5 Qc7',
    'Ndxb5 axb5',
    'Nxb5 Qc6',
    'Nxd6+ Bxd6',
    'Qxd6 Qxd6',
    'Rxd6 O-O',
    'Be3 Na4',
    'b3 Nac5',
    'a4 Na6',
    'Be2 Ne5',
    'Rhd1 Nb8',
    'Bc5 Ba6',
    'c4 Re8',
    'Be3 Bb7',
    'Rb6 Bc6',
    'Bf4 f6',
    'Rd6 Rc8',
    'Bxe5 fxe5',
    'Rxe6 Kf7',
    'Rd6 Ke7',
    'c5 Ra5',
    'b4 Rxa4',
    'Bc4 Ra7',
    'Kb2 Rac7',
    'Re6+ Kf8',
    'Rxe5 Nd7',
    'Rf5+ Ke8',
    'Ra6 g6',
    'Rf7 Bb5',
    'Bxb5 Kxf7',
    'Bc4+ Kg7',
    'f4 Rb8',
    'Kc3 Nf6',
    'Bd3 Nxg4',
    'c6 g5',
    'Be2 Nf6',
    'Bf3 gxf4',
    'e5 Nd7',
    'Ra5 Rb6',
    'Kc4 Rbxc6+',
    'Bxc6 Rxc6+',
    'Kd5 Rc7',
    'Kd6 Rb7',
    'Kc6 Rxb4',
    'Kxd7 Rb7+',
    'Kd6 f3',
    'Ra2 Rb3',
    'Kd5 Re3',
    'Rf2 Kf7',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'f4 g6',
    'd4 cxd4',
    'Qxd4 Nf6',
    'e5 Nc6',
    'Bb5 Nd7',
    'Bxc6 bxc6',
    'e6 Nf6',
    'exf7+ Kxf7',
    'Nf3 h6',
    'Ne5+ Kg7',
    'Nxc6 Qc7',
    'Nb4 e5',
    'Qf2 Qc4',
    'a3 a5',
    'Nba2 Bf5',
    'b3 Qe6',
    'O-O d5',
    'h3 Bd6',
    'Re1 Rhf8',
    'fxe5 Bxe5',
    'Bxh6+ Kxh6',
    'Qe3+ Kg7',
    'Qxe5 Qxe5',
    'Rxe5 Bxc2',
    'Nxd5 Nxd5',
    'Rxd5 Bxb3',
    'Rd3 Bxa2',
    'Rxa2 Rad8',
    'Rg3 Rd1+',
    'Kh2 Rfd8',
    'Rc2 R1d3',
    'Rg4 R8d4',
    'Rg5 Rd5',
    'Rxd5 Rxd5',
    'Rc4 Kf6',
    'Ra4 g5',
    'Kg3 Kf5',
    'Kf3 Rb5',
    'Re4 Rb3+',
    'Re3 a4',
    'g4+ Kf6',
    'Ke4 Rxe3+',
    'Kxe3 Ke5',
    'Kf2 Kf6',
    'Kg3 Ke6',
    'h4 gxh4+',
    'Kxh4 Kf6',
    'Kh5 Kg7',
    'Kg5 Kh7',
    'Kf6 Kh6',
    'g5+ Kh7',
    'Kf7 Kh8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 b4',
    'Nce2 h6',
    'c4 Nbd7',
    'Qd2 Qc7',
    'Rc1 Nc5',
    'Bg2 g6',
    'Ng3 e5',
    'Nde2 Be6',
    'b3 a5',
    'h4 a4',
    'Rb1 Nfd7',
    'O-O Rb8',
    'Rfc1 Be7',
    'h5 g5',
    'Bf2 axb3',
    'axb3 O-O',
    'Nf1 Ra8',
    'Ne3 Rfc8',
    'Ng3 Bf8',
    'Bf1 Rcb8',
    'Rc2 Ra3',
    'Rcb2 Qa5',
    'Ngf5 Bxf5',
    'Nxf5 Ne6',
    'Nxd6 Ndc5',
    'Nb5 Rd8',
    'Qc2 Ra1',
    'Qc1 Rxb1',
    'Qxb1 Rd7',
    'Be3 Qd8',
    'Rf2 Nf4',
    'Qc2 Rd1',
    'Rd2 Rxd2',
    'Qxd2 Qxd2',
    'Bxd2 Nxb3',
    'Be3 Bc5',
    'Kf2 Kf8',
    'Bxc5+ Nxc5',
    'Ke3 Nfe6',
    'Be2 Ke7',
    'Bd1 Kd7',
    'Bc2 Nc7',
    'Kd2 Kc6',
    'Na7+ Kb7',
    'Nb5 Nxb5',
    'cxb5 Kb6',
    'Bd1 Kxb5',
    'Be2+ Ka4',
    'Bc4 f6',
    'Kc2 Ka3',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 d6',
    'c3 Bd7',
    'd4 Nge7',
    'O-O Ng6',
    'd5 Nb8',
    'Bxd7+ Nxd7',
    'c4 Be7',
    'Nc3 h6',
    'Be3 Bg5',
    'b4 Bxe3',
    'fxe3 O-O',
    'Nd2 Nh4',
    'Qg4 Qg5',
    'Qxg5 hxg5',
    'Nb3 g4',
    'c5 g6',
    'c6 bxc6',
    'dxc6 Nb6',
    'a4 f5',
    'a5 Nc8',
    'Nd5 Rf7',
    'Nd2 Na7',
    'Rac1 Nb5',
    'g3 Nf3+',
    'Nxf3 gxf3',
    'exf5 gxf5',
    'Rxf3 Kg7',
    'g4 Raf8',
    'gxf5 Rxf5',
    'Rxf5 Rxf5',
    'Kg2 Rg5+',
    'Kf2 Kf7',
    'h3 Ke6',
    'e4 Rg7',
    'Rg1 Rh7',
    'Rg8 Rh4',
    'Rb8 Rxe4',
    'Rxb5 axb5',
    'Nxc7+ Ke7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qd6',
    'd4 Nf6',
    'Nf3 a6',
    'Bc4 b5',
    'Bb3 Nbd7',
    'O-O Bb7',
    'Ng5 e6',
    'Re1 Nd5',
    'a4 Be7',
    'axb5 Nxc3',
    'bxc3 Bxg5',
    'Bxg5 axb5',
    'Rxa8+ Bxa8',
    'Qg4 h5',
    'Qg3 Qxg3',
    'hxg3 Nb6',
    'Bc1 Bd5',
    'Ba3 Bc4',
    'Bxc4 bxc4',
    'Kf1 Nd5',
    'Bb4 Kd7',
    'Ke2 Rb8',
    'Kd2 Rb5',
    'Bf8 g6',
    'Ra1 Nf6',
    'f3 Rg5',
    'Ke2 Nd5',
    'Bh6 Rxg3',
    'Kf2 h4',
    'Bd2 g5',
    'Rh1 Ke7',
    'Ra1 Kf6',
    'Re1 Ke7',
    'Ra1 Kf6',
    'Re1 Kg7',
    'Re5 Kg6',
    'Re1 Ne7',
    'Rh1 Nf5',
    'Bc1 Kh5',
    'Bd2 Kg6',
    'Bc1 Ne7',
    'Bd2 Kf5',
    'Ra1 Nd5',
    'Ra8 Nf4',
    'Ra5+ Nd5',
    'Ra8 f6',
    'Ra7 g4',
    'Ra8 gxf3',
    'gxf3 Nf4',
    'Rh8 Rg2+',
    'Ke1 Re2+',
    'Kd1 Rh2',
    'Ke1 h3',
    'Rh6 Re2+',
    'Kd1 Rh2',
    'Ke1 Ng2+',
    'Kf1 Rh1+',
    'Ke2 h2',
    'Rh8 Nf4+',
    'Ke3 Nd5+',
    'Ke2 e5',
    'dxe5 fxe5',
    'Rh5+ Kg6',
    'Rh6+ Kg7',
    'Rh5 Kg6',
    'Rh6+ Kf7',
    'Rh7+ Ke6',
    'Rh6+ Nf6',
    'Bg5 Kf5',
    'Bd2 e4',
    'Rh4 exf3+',
    'Ke3 f2',
    'Kxf2 Rd1',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 b6',
    'Ne2 Ne4',
    'Qc2 Bb7',
    'Nf4 O-O',
    'Bd3 f5',
    'O-O Bxc3',
    'bxc3 c5',
    'f3 Nd6',
    'e4 Nc6',
    'e5 Nf7',
    'Qf2 Rc8',
    'Be3 Qe7',
    'Rac1 Kh8',
    'Rfe1 Na5',
    'Qe2 Ba6',
    'Bf2 Qg5',
    'Nh3 Qh5',
    'Be3 Qh4',
    'Bf2 Qh5',
    'Be3 Qh4',
    'Red1 Rc7',
    'f4 cxd4',
    'cxd4 Rfc8',
    'c5 Bxd3',
    'Qxd3 bxc5',
    'Qb5 Nc6',
    'Rxc5 Ne7',
    'Rxc7 Rxc7',
    'd5 Nxd5',
    'Ng5 g6',
    'Nxf7+ Kg7',
    'Nd6 Nxe3',
    'Ne8+ Kh6',
    'Qb4 d6',
    'Nxc7 Nxd1',
    'Qd2 dxe5',
    'Nxe6 exf4',
    'Nxf4 Nb2',
    'Nd3+ g5',
    'Nxb2 Qe4',
    'Nd1 f4',
    'h3 f3',
    'g4 Qe2',
    'Qd6+ Kg7',
    'Qd4+ Kg8',
    'Nf2 Qxa2',
    'Qd8+ Kf7',
    'Qd7+ Kf8',
    'Qf5+ Ke8',
    'Qe4+ Kd8',
    'Qxf3 a5',
    'Nd3 a4',
    'Qf8+ Kc7',
    'Qg7+ Kc8',
    'Qh8+ Kc7',
    'Qxh7+ Kc8',
    'Qh8+ Kc7',
    'Qe5+ Kc8',
    'Qc5+ Kb7',
    'Qb4+ Kc7',
    'Qc3+ Kd8',
    'Nc1 Qe6',
    'Kf2 Qb6+',
    'Ke2 Qg1',
    'Kd2 Qg2+',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Nf3 Nf6',
    'Bc4 Bf5',
    'Bd2 e6',
    'Nd5 Qd8',
    'Nxf6+ gxf6',
    'Bb3 Nd7',
    'Qe2 Qc7',
    'Nh4 Bg6',
    'O-O-O O-O-O',
    'g3 Nb6',
    'Ba5 Bd6',
    'Kb1 Kb8',
    'Nxg6 hxg6',
    'h4 Rh5',
    'Bd2 Nd7',
    'Be3 f5',
    'Bg5 Rc8',
    'c4 Ka8',
    'c5 Bf8',
    'f3 Rh7',
    'g4 Bh6',
    'Be7 Bf8',
    'Bg5 Bh6',
    'Be7 Bf4',
    'h5 gxh5',
    'gxf5 exf5',
    'Bh4 Bg3',
    'Bc2 Bxh4',
    'Rxh4 Rhh8',
    'Qe7 Qd8',
    'Qxd8 Rcxd8',
    'Re1 Rhe8',
    'Rxe8 Rxe8',
    'Bxf5 Nf6',
    'Be4 Rh8',
    'Kc2 Kb8',
    'b4 Rh6',
    'Kd3 Kc7',
    'a4 Ng8',
    'd5 cxd5',
    'Bxd5 Rh7',
    'Be4 Rh6',
    'Ke3 Ne7',
    'Kf4 f6',
    'b5 Rh8',
    'a5 a6',
    'b6+ Kb8',
    'Ke3 f5',
    'Bc2 Rc8',
    'Rxh5 Rxc5',
    'Rh7 Nc8',
    'Bd3 Rxa5',
    'Rh8 f4+',
    'Kxf4 Rc5',
    'Bf5 a5',
    'Ke4 a4',
    'Be6 Rc6',
    'Re8 a3',
    'Kd3 Rxe6',
    'Rxe6 Nxb6',
    'Kc3 Na4+',
    'Kb4 a2',
    'Re1 Nb6',
    'Kb3 Kc7',
    'Kxa2 Nd5',
    'Kb3 b5',
    'Kc2 Kd6',
    'Kd3 Nf4+',
    'Ke4 Ne6',
    'Rd1+ Kc5',
    'Ke5 Nf8',
    'f4 b4',
    'f5 b3',
    'Kf6 Kc6',
    'Ke7 Nh7',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 a6',
    'Nf3 b5',
    'c5 Nbd7',
    'Qb3 Rb8',
    'Be2 e5',
    'O-O Be7',
    'Ne1 O-O',
    'Nd3 Qc7',
    'f3 Re8',
    'Bd2 Bd8',
    'Qd1 a5',
    'b4 e4',
    'Ne1 axb4',
    'Nb1 exf3',
    'gxf3 Nf8',
    'Nc2 Nh5',
    'Kh1 Ng3+',
    'hxg3 Qxg3',
    'Rf2 Bc7',
    'Qg1 Qh3+',
    'Rh2 Bxh2',
    'Qxh2 Qxh2+',
    'Kxh2 Re6',
    'Nxb4 Rh6+',
    'Kg1 Rg6+',
    'Kf2 Bh3',
    'Bf1 Bxf1',
    'Kxf1 Re8',
    'Nc3 Rh6',
    'Ke2 f5',
    'Kd3 Ree6',
    'a4 bxa4',
    'Rxa4 Rh3',
    'Ra6 Rxf3',
    'Rxc6 g5',
    'Ncxd5 h5',
    'Rc8 f4',
    'c6 fxe3',
    'Bxe3 g4',
    'c7 g3',
    'Rd8 g2',
    'c8=Q Rexe3+',
    'Nxe3 g1=Q',
    'Qe6+ Rf7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'Nge2 Nf6',
    'g3 c6',
    'Bg2 Bf5',
    'b4 Qc7',
    'd4 e6',
    'Bf4 Bd6',
    'O-O O-O',
    'Bxd6 Qxd6',
    'b5 a6',
    'bxa6 Nxa6',
    'a4 Nb4',
    'Rc1 Rfd8',
    'Qd2 h6',
    'Rfd1 e5',
    'h3 exd4',
    'Qxd4 Qe7',
    'Qb6 Rxd1+',
    'Rxd1 Nfd5',
    'Nxd5 Nxd5',
    'Rxd5 cxd5',
    'Nd4 Be4',
    'Bxe4 Qxe4',
    'a5 Qe1+',
    'Kg2 Rxa5',
    'Nf3 Qa1',
    'Qxb7 Qc3',
    'Qd7 Qxc2',
    'Qd8+ Kh7',
    'Qxa5 Qe4',
    'Qd2 Qe6',
    'Qd3+ Kg8',
    'g4 Qd6',
    'Qd4 Qd7',
    'Ne5 Qb5',
    'Qa7 Qe8',
    'Qd4 Qb5',
    'Kg3 Qb3+',
    'Nf3 Qb7',
    'Qe5 Qb5',
    'Qe7 Qb8+',
    'Kg2 Qa8',
    'Qd7 d4',
    'Qxd4 Qb7',
    'Qd8+ Kh7',
    'Qd3+ Kg8',
    'Qd8+ Kh7',
    'Qd6 Qb3',
    'Qf4 Qb7',
    'h4 Qc6',
    'Kh3 Qc7',
    'Kg3 Qb7',
    'Kg2 Kg8',
    'Qf5 Qa8',
    'Qf4 Qb7',
    'Qe5 Kh7',
    'Kg3 Qb3',
    'Qe8 g6',
    'Qe5 Qd1',
    'Qf6 Qb3',
    'Kg2 Qd5',
    'Kh2 Kg8',
    'Kg3 Kh7',
    'Ne5 Kg8',
    'Nc6 Qd3+',
    'Kh2 Qe4',
    'Ne5 Qb7',
    'Nxg6 fxg6',
    'Qxg6+ Kh8',
    'Qxh6+ Kg8',
    'Qf4 Kg7',
    'h5 Kg8',
    'Qg5+ Kh7',
    'Qg6+ Kh8',
    'Qf6+ Kg8',
  ],
  [
    'e4 c5',
    'Nf3 g6',
    'd4 Bg7',
    'dxc5 Qa5+',
    'c3 Qxc5',
    'Na3 d6',
    'Nb5 a6',
    'Be3 Qc6',
    'Na7 Qc7',
    'Nxc8 Qxc8',
    'Qb3 Nd7',
    'Ng5 e6',
    'Rd1 Qc7',
    'Bf4 Be5',
    'Be3 Nc5',
    'Bxc5 dxc5',
    'Qa4+ Ke7',
    'Nxf7 b5',
    'Bxb5 axb5',
    'Qxa8 Kxf7',
    'Rd8 Bf6',
    'Rb8 Qe5',
    'Rf8+ Kg7',
    'O-O Qd6',
    'Rc8 b4',
    'Qa7+ Ne7',
    'Rxc5 Ra8',
    'Qxa8 Qxc5',
    'cxb4 Qxb4',
    'b3 Bd4',
    'Qd8 e5',
    'g3 Ng8',
    'Qd7+ Kh8',
    'Qf7 Qd2',
    'a4 Bc3',
    'Qf3 Qc2',
    'Kg2 Qd2',
    'Qf7 Qb2',
    'Qc4 Qd2',
    'Qc5 Bd4',
    'Qd5 Kg7',
    'Qd7+ Kh8',
    'Qd6 Qc2',
    'Qd5 Kg7',
    'Qb7+ Kh6',
    'Qf7 Qxe4+',
    'Qf3 Qc2',
    'Qf8+ Kg5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Bd3 O-O',
    'Nge2 Na6',
    'O-O c6',
    'h3 e5',
    'd5 Nc5',
    'Bc2 a5',
    'Be3 cxd5',
    'cxd5 Bd7',
    'a3 a4',
    'Qd2 Qa5',
    'Bh6 b5',
    'Bxg7 Kxg7',
    'f4 h6',
    'fxe5 dxe5',
    'Rxf6 Kxf6',
    'Qxh6 Qb6',
    'd6 Bc6',
    'Kh2 Ke6',
    'Qg5 Rae8',
    'Rd1 Kd7',
    'Qg4+ Kd8',
    'Nd5 Bxd5',
    'Rxd5 Re6',
    'Qg5+ Kd7',
    'Qh6 Rfe8',
    'Qg7 Rxd6',
    'Qxf7+ Re7',
    'Qxg6 Kc7',
    'Qg8 Rxd5',
    'Qxd5 Ne6',
    'Nc3 Nf4',
    'Qxb5 Qf2',
    'Nd5+ Nxd5',
    'Qc4+ Kd8',
    'Qxd5+ Rd7',
    'Qg8+ Ke7',
    'Bxa4 Qf4+',
    'Qg3 Rd2',
    'b4 Ra2',
    'Bc6 Rxa3',
    'Qxf4 exf4',
    'b5 Rb3',
    'h4 Kf6',
    'h5 Rb2',
    'Kg1 Kg5',
    'e5 Re2',
    'b6 Rxe5',
    'b7 Re1+',
    'Kf2 Rb1',
    'Bf3 Rb2+',
    'Ke1 Kf5',
    'Kd1 Rb6',
    'Kc2 Ke5',
    'h6 Kf6',
    'h7 Kg7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Nf3 Nf6',
    'Ne5 Bf5',
    'Bd3 Bxd3',
    'Qxd3 Nbd7',
    'Bf4 e6',
    'O-O Nb6',
    'a3 Nbd5',
    'Nxd5 cxd5',
    'b4 Qa4',
    'c4 dxc4',
    'Nxc4 Qd7',
    'Be5 Be7',
    'f4 O-O',
    'f5 Ng4',
    'Rad1 Rac8',
    'h3 Rxc4',
    'hxg4 Rfc8',
    'Qe3 Rc3',
    'Rd3 Rxd3',
    'Qxd3 exf5',
    'gxf5 f6',
    'Bf4 Rd8',
    'Be3 Qd5',
    'Rc1 Kf8',
    'Bf2 Bd6',
    'Qh3 Kg8',
    'Re1 Rc8',
    'Qg4 b6',
    'Bh4 Kf8',
    'Bf2 a5',
    'bxa5 bxa5',
    'Qh3 Kg8',
    'a4 Bb4',
    'Rd1 Kh8',
    'Qd3 h6',
    'Qb5 Rd8',
    'Rc1 Qe4',
    'Qb6 Rd5',
    'Qe6 Qxf5',
    'Rc8+ Kh7',
    'Qg8+ Kg6',
    'Qe8+ Kh7',
    'Qg8+ Kg6',
    'Qe8+ Kg5',
    'Qe3+ Kh5',
    'Rg8 Qb1+',
    'Kh2 Bd6+',
    'g3 Rg5',
    'Qe2+ Kg6',
    'Qe8+ Kf5',
    'Qc8+ Kg6',
    'Qe8+ Kf5',
    'Qd7+ Ke4',
    'Qc6+ Kf5',
    'Qd7+ Ke4',
    'Re8+ Kf3',
    'Qc6+ Kg4',
    'Kg2 Rf5',
    'Re4+ Kg5',
    'Qe8 Rxf2+',
    'Kxf2 Qb2+',
    'Kf3 Qc3+',
    'Re3 Qxd4',
    'Qe4 Qxe4+',
    'Rxe4 Kf5',
  ],
  [
    'd4 Nf6',
    'Bg5 e6',
    'e4 h6',
    'Bxf6 Qxf6',
    'Nc3 d6',
    'Qd2 g5',
    'Bc4 Bg7',
    'Nge2 Nc6',
    'Rd1 a6',
    'a4 Bd7',
    'O-O Qe7',
    'Qe3 Na5',
    'Bd3 c5',
    'dxc5 dxc5',
    'e5 Nc6',
    'Be4 Nxe5',
    'Bxb7 Rb8',
    'Ne4 O-O',
    'Bxa6 Rxb2',
    'Qxc5 Qxc5',
    'Nxc5 Bc8',
    'Bb5 Rxc2',
    'Rc1 Rxc1',
    'Rxc1 Rd8',
    'a5 Bf8',
    'Ng3 Rd5',
    'Nge4 Be7',
    'a6 Bxa6',
    'Bxa6 f5',
    'Bb7 fxe4',
    'Bxd5 exd5',
    'Nb3 Kf7',
    'Rc7 Kf6',
    'Kf1 Bd6',
    'Ra7 Nc4',
    'Rh7 Bf8',
    'Rd7 Bd6',
    'Nd4 Ke5',
    'Nc6+ Kf6',
    'Ke2 h5',
    'h3 Kf5',
    'Rf7+ Kg6',
    'Rb7 Kf5',
    'Rb5 Ke6',
    'Nd8+ Ke5',
    'Nf7+ Kd4',
    'Nxg5 Na3',
    'Rb7 Kc3',
    'Rd7 Nc4',
  ],
  [
    'd4 d5',
    'Nf3 Nc6',
    'Bf4 Bg4',
    'Ne5 Nxe5',
    'Bxe5 Nf6',
    'c4 dxc4',
    'Qa4+ Nd7',
    'Bg3 Be6',
    'Nc3 c6',
    'e4 Nb6',
    'Qa5 g6',
    'O-O-O Bg7',
    'f4 f5',
    'd5 cxd5',
    'exd5 Bf7',
    'Be2 O-O',
    'Bf3 Nc8',
    'Qxd8 Rxd8',
    'Bh4 Kf8',
    'Rhe1 Rd7',
    'Nb5 a6',
    'd6 exd6',
    'Nxd6 Nxd6',
    'Rxd6 Rxd6',
    'Be7+ Kg8',
    'Bxd6 Rd8',
    'Be5 Rd7',
    'a4 b6',
    'Kc2 Bf8',
    'Bc6 Rd3',
    'Bc3 Rd6',
    'Bf3 b5',
    'axb5 axb5',
    'Re5 Rb6',
    'Bd4 Rd6',
    'Bc3 Rb6',
    'Bd4 Rd6',
  ],
  [
    'c4 Nf6',
    'Nc3 c5',
    'g3 g6',
    'Bg2 Bg7',
    'a3 Nc6',
    'Rb1 a5',
    'e3 e6',
    'Nge2 d5',
    'b3 d4',
    'Na4 Nd7',
    'exd4 cxd4',
    'd3 e5',
    'O-O O-O',
    'b4 axb4',
    'axb4 Ne7',
    'b5 Nb6',
    'Nxb6 Qxb6',
    'Bd2 Bf5',
    'Bb4 Rfe8',
    'Nc1 Qc7',
    'Re1 Nc8',
    'Nb3 Nb6',
    'Ba5 Bf8',
    'c5 Rxa5',
    'cxb6 Qxb6',
    'Nxa5 Qxa5',
    'Bxb7 Bb4',
    'Bc6 Re7',
    'Re2 Bg4',
    'f3 Bc8',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'O-O Be7',
    'Re1 b5',
    'Bb3 O-O',
    'c3 d5',
    'exd5 Nxd5',
    'Nxe5 Nxe5',
    'Rxe5 c6',
    'd3 Bd6',
    'Re1 Bf5',
    'Qf3 Qh4',
    'g3 Qh3',
    'Nd2 Rae8',
    'Ne4 Bg4',
    'Qg2 Qxg2+',
    'Kxg2 f5',
    'h3 Bh5',
    'Bf4 Bxf4',
    'gxf4 fxe4',
    'dxe4 Bf3+',
    'Kxf3 Rxf4+',
    'Kg3 Rfxe4',
    'Rxe4 Rxe4',
    'f3 Re2',
    'c4 bxc4',
    'Bxc4 Rxb2',
    'Bxa6 g5',
    'a4 Kg7',
    'a5 Nf4',
    'Bf1 Kg6',
    'h4 Kf5',
    'a6 Ke5',
  ],
  [
    'd4 d5',
    'c4 e5',
    'dxe5 d4',
    'Nf3 Nc6',
    'g3 Nge7',
    'Bg2 Ng6',
    'Bg5 Qd7',
    'e6 fxe6',
    'a3 a5',
    'Qa4 h6',
    'Bc1 e5',
    'Nbd2 Be7',
    'O-O O-O',
    'b4 Nd8',
    'Qxd7 Bxd7',
    'b5 a4',
    'Ne1 c6',
    'Rb1 cxb5',
    'cxb5 Ra5',
    'Be4 Nh8',
    'Nd3 Nhf7',
    'Nc4 Rxb5',
    'Rxb5 Bxb5',
    'Ncxe5 Nxe5',
    'Nxe5 Bd6',
    'Bd5+ Kh7',
    'Be4+ Kg8',
    'Bd5+ Kh7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 b5',
    'Bb3 Bb7',
    'O-O Nbd7',
    'Re1 Rc8',
    'a4 b4',
    'Nd5 g6',
    'Nxb4 Bg7',
    'Bd5 Qc7',
    'c3 O-O',
    'Bg5 e6',
    'Bxb7 Qxb7',
    'f3 Ne5',
    'a5 Rc5',
    'Bxf6 Bxf6',
    'Nb3 Rb5',
    'Nd4 Qa7',
    'Kh1 Rb7',
    'Ndc6 Nxc6',
    'Nxc6 Qc5',
    'Nb4 Ra8',
    'f4 Rb5',
    'Qf3 Bg7',
    'e5 d5',
    'Qe3 Qc7',
    'Nd3 Rxa5',
    'Rxa5 Qxa5',
    'b4 Qb5',
    'Ra1 Bf8',
    'h3 Rc8',
    'Ra5 Qc4',
    'Nc5 Bxc5',
    'bxc5 Rb8',
    'Ra1 Rb3',
    'f5 exf5',
    'Qg5 Qxc5',
    'Qd8+ Qf8',
    'Qxd5 Rb5',
    'Qd4 Qc8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 Nfd7',
    'Qd2 Nb6',
    'O-O-O N8d7',
    'Bg5 Qc7',
    'Ndxb5 axb5',
    'Nxb5 Qc6',
    'Nxd6+ Bxd6',
    'Qxd6 Qxd6',
    'Rxd6 Rxa2',
    'Kb1 Ra8',
    'Bb5 Rb8',
    'Rhd1 h6',
    'Be3 Ke7',
    'c4 Nd5',
    'Rxd7+ Bxd7',
    'Bc5+ Kd8',
    'exd5 Bxb5',
  ],
  [
    'g3 c5',
    'Bg2 g6',
    'e4 Bg7',
    'd3 Nc6',
    'f4 d6',
    'c3 Nh6',
    'Qe2 f5',
    'Nd2 O-O',
    'Ngf3 Nf7',
    'exf5 Bxf5',
    'Nh4 Bd7',
    'O-O e6',
    'Ne4 Qe7',
    'Be3 b6',
    'Rae1 Rae8',
    'Bd2 Ncd8',
    'Nf3 Bc6',
    'Bh3 Qb7',
    'Neg5 Bd5',
    'a3 b5',
    'Rf2 a5',
    'Bc1 b4',
    'axb4 axb4',
    'c4 Bxf3',
    'Nxf3 Nh6',
    'Ng5 Bd4',
    'Be3 Nf5',
    'Bxd4 Nxd4',
    'Qf1 Kg7',
    'g4 Nf7',
    'Nf3 Nxf3+',
    'Rxf3 g5',
    'Bg2 gxf4',
    'Rxf4 Qe7',
    'Qe2 Ne5',
    'Re4 Qf6',
    'Qe3 Ng6',
    'd4 cxd4',
    'Rxd4 e5',
    'Rd2 Nf4',
    'Be4 Rd8',
    'Kh1 Ne6',
    'Bd5 Nd4',
    'Kg2 Kh8',
    'h3 Qg7',
    'Rf1 Nf5',
    'Qd3 Qg5',
    'Kg1 Ne3',
    'Rxf8+ Rxf8',
    'Rf2 Rxf2',
    'Kxf2 Qf4+',
    'Ke2 Nxd5',
    'Qxd5 Qh2+',
    'Ke1 Qg3+',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Bc4 Bf5',
    'Nf3 Nf6',
    'Ne5 e6',
    'O-O Nbd7',
    'Re1 Nxe5',
    'Rxe5 Qc7',
    'h3 O-O-O',
    'Bg5 h6',
    'Bh4 Bd6',
    'Re3 Bf4',
    'Bg3 g5',
    'Ne2 Rhg8',
    'Ra3 Kb8',
    'Nxf4 gxf4',
    'Bh4 Rg6',
    'Bf1 e5',
    'c3 e4',
    'Bxf6 Rxf6',
    'c4 Rg6',
    'Qh5 Bc8',
    'd5 c5',
    'Re1 Re8',
    'Kh1 Qd8',
    'Rc3 Qf6',
    'a3 Re5',
    'Qe2 Ka8',
    'b4 Reg5',
    'Qd2 e3',
    'fxe3 f3',
    'g4 f2',
    'Re2 Qf3+',
    'Bg2 f1=Q+',
  ],
  [
    'Nc3 c5',
    'd4 cxd4',
    'Qxd4 e6',
    'e4 Nc6',
    'Qa4 a6',
    'Nf3 Qc7',
    'Bg5 Rb8',
    'Qc4 b5',
    'Qe2 Bb4',
    'Bd2 Nge7',
    'g3 O-O',
    'Bg2 f5',
    'O-O Bxc3',
    'Bxc3 b4',
    'Bd2 fxe4',
    'Qxe4 d5',
    'Qe2 e5',
    'c4 Bg4',
    'cxd5 Bxf3',
    'Qc4 Bxg2',
    'Kxg2 Kh8',
    'Rac1 Qd6',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Qb6',
    'Nf3 Nc6',
    'a3 c4',
    'g3 f6',
    'exf6 Nxf6',
    'Bg2 Bd6',
    'O-O O-O',
    'Nbd2 Bd7',
    'Qe2 Rae8',
    'Ne5 Bc8',
    'Kh1 Bxe5',
    'dxe5 Nd7',
    'f4 Nc5',
    'Nf3 Nd3',
    'Rb1 Nxc1',
    'Rbxc1 Bd7',
    'Qc2 h6',
    'Rce1 Qc7',
    'Qd2 Qb6',
    'g4 Ne7',
    'Nd4 Nc6',
    'Re3 Nxd4',
    'cxd4 Bb5',
    'Rf2 Rf7',
    'f5 Ref8',
    'Ref3 h5',
    'f6 hxg4',
    'Rg3 c3',
    'bxc3 Bc4',
    'h3 Qb1+',
    'Kh2 gxh3',
    'Bxh3 Qh7',
    'Rfg2 g5',
    'Rxg5+ Kh8',
    'Rg7 Qh5',
    'R2g6 Qxe5+',
  ],
  [
    'c4 e5',
    'Nc3 Nf6',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'd3 Be7',
    'Be3 O-O',
    'Rc1 Kh8',
    'Nf3 Nc6',
    'O-O f5',
    'Na4 Nxa4',
    'Qxa4 Bd7',
    'Qc4 Bf6',
    'Nd2 Re8',
    'Nb3 Nd4',
    'Bxd4 exd4',
    'Bxb7 Rb8',
    'Bf3 Be6',
    'Qa4 Rb6',
    'Qxa7 Bxb3',
    'Rxc7 Qb8',
    'axb3 Rxb3',
    'Qxb8 Rexb8',
    'Rb1 g6',
  ],
  [
    'c4 e6',
    'Nc3 d5',
    'd4 Nf6',
    'cxd5 exd5',
    'Bg5 c6',
    'e3 Bd6',
    'Bd3 O-O',
    'Nf3 Re8',
    'Qc2 h6',
    'Bh4 Bg4',
    'O-O-O Nbd7',
    'h3 Bxf3',
    'gxf3 Qa5',
    'Kb1 Be7',
    'Rhg1 Rad8',
    'Rg2 Kh8',
    'Rdg1 Rg8',
    'e4 dxe4',
    'fxe4 Nf8',
    'Bc4 g5',
    'f4 Ng6',
    'Be1 Nxf4',
    'Nd5 Qxe1+',
    'Rxe1 cxd5',
    'exd5 Bb4',
    'Rf1 Rge8',
    'a3 Bd6',
    'Qf5 N6h5',
    'Rgg1 Kg7',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 Nc6',
    'Nh3 Nh6',
    'f3 Rb8',
    'Be3 f6',
    'Qd2 Nf7',
    'Nf4 b5',
    'h4 b4',
    'Nd1 f5',
    'h5 g5',
    'h6 Bxh6',
    'Nh5 fxe4',
    'f4 gxf4',
    'Bxf4 Bxf4',
    'Qxf4 Nfe5',
    'Bxe4 Qa5',
    'Bxc6+ Nxc6',
    'O-O Kd8',
    'Qh6 Bh3',
    'Ne3 Bxf1',
    'Nc4 Qc7',
    'Rxf1 Qd7',
    'Nf4 Qg4',
    'Nxd6 exd6',
    'Qxd6+ Qd7',
    'Qf6+ Kc7',
    'Ne6+ Kb6',
    'd4 cxd4',
    'a4 Qd6',
    'Rf5 a6',
  ],
  [
    'e4 g6',
    'd4 Bg7',
    'Nc3 d6',
    'f4 Nf6',
    'Nf3 O-O',
    'e5 Nfd7',
    'h4 c5',
    'h5 cxd4',
    'hxg6 dxc3',
    'gxf7+ Rxf7',
    'Bc4 Nf8',
    'Ng5 e6',
    'Nxf7 cxb2',
    'Bxb2 Qa5+',
    'Kf1 Kxf7',
    'Qh5+ Kg8',
    'Bd3 Qb4',
    'Rb1 Bd7',
    'c4 Qd2',
    'Bxh7+ Nxh7',
    'Qxh7+ Kf8',
  ],
  [
    'd4 Nf6',
    'c4 Nc6',
    'Nf3 e6',
    'e3 g6',
    'Nc3 d6',
    'b4 Bg7',
    'b5 Ne7',
    'Bb2 O-O',
    'Be2 b6',
    'O-O Bb7',
    'a4 c5',
    'bxc6 Nxc6',
    'Ba3 Re8',
    'Rb1 Rc8',
    'Nb5 Bf8',
    'd5 exd5',
    'cxd5 Ne5',
    'Nxa7 Nxd5',
    'Nb5 Nxf3+',
    'Bxf3 Nc3',
    'Nxc3 Bxf3',
    'Qxf3 Rxc3',
    'Bb4 Rc4',
    'Qd5 Qc7',
    'Rfd1 Qc6',
    'Qxc6 Rxc6',
    'Rd4 Ra8',
    'Ba3 d5',
  ],
  [
    'c4 e5',
    'Nc3 Nf6',
    'g3 c6',
    'd4 exd4',
    'Qxd4 d5',
    'Bg2 dxc4',
    'Qxc4 Be6',
    'Qa4 Nbd7',
    'Nf3 Nc5',
    'Qc2 Qc8',
    'O-O Be7',
    'e4 O-O',
    'Be3 Re8',
    'Rfe1 h6',
    'Rad1 Bg4',
    'Rd2 Bf8',
    'Nd4 Bh3',
    'f3 Bxg2',
    'Kxg2 g6',
    'Bf2 Qc7',
    'Red1 a5',
    'Nde2 Ne6',
    'a3 h5',
    'Na4 h4',
    'Be3 Bg7',
    'Nf4 Qe5',
    'Nb6 Rad8',
    'Rxd8 Rxd8',
    'Nc4 Nxf4+',
    'Bxf4 h3+',
    'Kf2 Qe7',
    'Rxd8+ Qxd8',
    'Qd2 Qxd2+',
    'Bxd2 a4',
    'e5 Nd5',
    'f4 Bf8',
    'Na5 Bc5+',
    'Kf3 Bd4',
    'Nxb7 Bxb2',
    'Nc5 Bxa3',
    'Nxa4 f5',
    'exf6 Nxf6',
    'Be3 Kf7',
    'g4 Ke6',
    'Nb6 Bd6',
    'Nc4 Bc7',
    'Nb2 Nd5',
    'Nd3 g5',
    'f5+ Kf6',
    'Bd4+ Kf7',
    'Be5 Nb4',
    'Bxc7 Nxd3',
    'Bd8 c5',
    'Bxg5 c4',
    'Be3 c3',
    'Ke4 Nb4',
    'g5 c2',
    'g6+ Kg7',
    'Bd4+ Kh6',
    'Bb2 Na2',
    'Ke5 c1=Q',
    'Bxc1+ Nxc1',
    'Kf6 Nd3',
    'g7 Kh7',
    'Kf7 Ne5+',
    'Ke6 Ng4',
    'f6 Kg8',
    'Kf5 Nh6+',
    'Kg5 Nf7+',
    'Kh4 Nh6',
    'Kh5 Nf7',
    'Kg6 Ne5+',
    'Kg5 Nf7+',
    'Kh4 Ne5',
    'Kxh3 Kf7',
    'Kh4 Nd7',
    'Kg5 Nxf6',
    'Kh6 Ng8+',
    'Kh7 Nf6+',
    'Kh8 Kg6',
    'h4 Kh5',
    'g8=Q Nxg8',
    'Kxg8 Kxh4',
  ],
  [
    'Nf3 Nf6',
    'b3 g6',
    'Bb2 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'd4 Nbd7',
    'O-O e5',
    'dxe5 Ng4',
    'Qc1 Ngxe5',
    'c4 Re8',
    'Nc3 Rb8',
    'Qd2 a6',
    'Nxe5 Nxe5',
    'Rfd1 Be6',
    'Rac1 h5',
    'Nd5 Bxd5',
    'cxd5 Re7',
    'Rc2 Qd7',
    'Rdc1 Rc8',
    'h3 Qd8',
    'Bd4 Nd7',
    'Bxg7 Kxg7',
    'Qb4 b6',
    'Qd4+ Kg8',
    'b4 b5',
    'f4 Re8',
    'Qa7 Ra8',
    'Qxc7 Qe7',
    'e4 h4',
    'gxh4 Rad8',
    'Rc6 Qxh4',
    'Rxd6 Qxf4',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c4 Bg7',
    'Nc3 O-O',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'O-O Nc6',
    'e3 Bd7',
    'Qe2 Re8',
    'Rd1 Qc8',
    'b3 e5',
    'dxe5 Bg4',
    'Bb2 Nxe5',
    'Rd2 c5',
    'Nb5 Qf5',
    'Bxe5 Bxe5',
    'Rad1 a6',
    'Nd6 Bxd6',
    'Rxd6 Re6',
    'h3 Bxf3',
    'Qxf3 Rb8',
    'Qxf5 gxf5',
    'Rxe6 fxe6',
    'Rd6 c4',
    'bxc4 Nxc4',
    'Rxe6 Kg7',
    'Re7+ Kg6',
    'Bxb7 a5',
    'Bd5 Nd2',
    'Kg2 Rb5',
    'Rd7 Rb2',
    'a4 h6',
    'Ra7 Nb1',
    'Rxa5 Nc3',
    'Ra6+ Kg7',
    'Bf3 Ra2',
    'a5 Nb5',
  ],
  [
    'c4 c6',
    'd4 d5',
    'Nf3 Nf6',
    'Qc2 dxc4',
    'e4 b5',
    'b3 cxb3',
    'axb3 e6',
    'Bd2 a5',
    'Bd3 Bb7',
    'O-O Be7',
    'Bc3 h6',
    'Nbd2 Nbd7',
    'Ra2 O-O',
    'Rfa1 b4',
    'Bb2 c5',
    'Nc4 a4',
    'Rxa4 Rxa4',
    'Rxa4 cxd4',
    'Bxd4 Bc5',
    'Ncd2 Bxd4',
    'Nxd4 Qb6',
    'Qc4 Nc5',
    'Rxb4 Qa5',
    'Bf1 Ncxe4',
    'Nxe4 Bxe4',
    'Nxe6 fxe6',
    'Qxe6+ Kh8',
    'Rxe4 Nxe4',
    'Qxe4 Qd2',
    'Qe2 Qxe2',
    'Bxe2 Rb8',
    'Bc4 Rd8',
    'f4 Rd2',
    'f5 h5',
    'h4 Kh7',
    'Kh2 g6',
    'f6 Rf2',
    'f7 Kg7',
    'Kg3 Rf6',
    'Kh3 Rf4',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'd4 c6',
    'Bc4 Bf5',
    'Bd2 e6',
    'g4 Bg6',
    'Qe2 Bb4',
    'f4 Ne7',
    'O-O-O Nd7',
    'a3 Bxc3',
    'Bxc3 Qc7',
    'Nh3 Nb6',
    'd5 exd5',
    'Bxg7 Rg8',
    'Be5 Qd7',
    'Bd3 Bxd3',
    'Qxd3 Rxg4',
    'Ng5 Qf5',
    'Nxh7 Nd7',
    'h4 Qxd3',
    'Rxd3 Rg6',
    'h5 Rh6',
    'Ng5 f6',
    'Bc3 Nf5',
    'Bb4 Nb6',
    'Re1+ Kd7',
    'Ne6 Rg8',
    'b3 Rxh5',
    'a4 Nc8',
    'Nf8+ Kc7',
    'c4 Rg3',
    'Ne6+ Kb8',
    'Rxg3 Nxg3',
    'Nc5 Rf5',
    'Re8 b6',
    'Ne6 dxc4',
    'bxc4 c5',
    'Bd2 Rh5',
    'f5 Nxf5',
    'Rd8 Kb7',
    'a5 Nfe7',
    'Bf4 bxa5',
    'Rd7+ Kb6',
    'Rc7 Rh3',
    'Rxc5 a4',
    'Bc7+ Ka6',
    'Bd8 Re3',
    'Rc6+ Kb7',
    'Rc7+ Kb8',
    'Nc5 a3',
    'Kb1 f5',
    'Na6+ Ka8',
    'c5 Nd5',
    'c6 Nd6',
    'Rd7 Rb3+',
    'Kc2 Rc3+',
    'Kb1 Rxc6',
    'Nc7+ Nxc7',
    'Bxc7 Nb5',
    'Be5 Re6',
    'Rd5 a6',
    'Rc5 Kb7',
    'Bf4 Re1+',
    'Ka2 Re2+',
    'Ka1 Kb6',
    'Rc8 Nd4',
    'Rc3 Kb5',
    'Bd6 Nc2+',
    'Kb1 Re1+',
    'Kxc2 a2',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Qb6',
    'Nf3 Nc6',
    'a3 c4',
    'g3 Bd7',
    'h4 Na5',
    'Nbd2 O-O-O',
    'Bh3 f5',
    'exf6 gxf6',
    'O-O h5',
    'Re1 Nh6',
    'Nh2 Nf5',
    'Bxf5 exf5',
    'f4 Bd6',
    'Kf2 Rde8',
    'Rxe8+ Rxe8',
    'Nhf1 Nb3',
    'Nxb3 Qxb3',
    'Qxb3 cxb3',
    'a4 b5',
    'a5 b4',
    'Bd2 bxc3',
    'Bxc3 a6',
    'Rc1 Kb7',
    'Ne3 Be6',
    'Ke2 Rc8',
    'Kd3 Rc6',
    'Re1 Bf8',
    'Nf1 Bd7',
    'Nd2 Re6',
    'Rxe6 Bxe6',
    'Nxb3 Kc6',
    'Kc2 Kb5',
    'Nd2 Ka4',
    'Nf1 Bd6',
    'Ne3 Bc7',
    'Nd1 Bxa5',
    'Bxa5 Kxa5',
    'Kb3 Kb6',
    'Nc3 a5',
    'Ka4 Bd7+',
    'Ka3 Bc6',
    'Na4+ Kb5',
    'Kb3 Bb7',
    'Nc3+ Kb6',
    'Ka3 Bc6',
    'b4 Ka6',
    'Kb3 Kb6',
    'Na4+ Kb5',
    'bxa5 Kxa5',
    'Nc5 Be8',
    'Ne6 Bf7',
    'Nd8 Be8',
    'Nb7+ Kb6',
    'Nd6 Bd7',
    'Kb4 Kc6',
    'Nb5 Kb6',
    'Nc3 Bc6',
    'Nb1 Bd7',
    'Nd2 Bb5',
    'Nb3 Bd7',
    'Nc5 Be8',
    'Ne6 Bf7',
    'Nf8 Kc6',
    'Nh7 Kd6',
    'Nxf6 Bg6',
    'Ng8 Bf7',
    'Nf6 Bg6',
    'Ng8 Bf7',
    'Nh6 Be6',
    'Kb5 Bd7+',
    'Ka5 Be6',
    'Kb6 Kd7',
    'Kc5 Kc7',
    'Nxf5 Bxf5',
    'Kxd5 Kd7',
    'Ke5 Bg4',
    'Kf6 Kd6',
    'f5 Kd7',
    'Kg6 Bf3',
    'd5 Bxd5',
    'Kxh5 Ke7',
    'Kg5 Kf7',
    'g4 Bf3',
    'h5 Kg7',
    'Kh4 Bd1',
    'g5 Bc2',
    'h6+ Kh7',
    'Kg4 Bb1',
    'Kf4 Bc2',
    'Ke5 Bd3',
    'Ke6 Bc4+',
    'Ke7 Bb3',
    'f6 Kg6',
    'f7 Bxf7',
    'h7 Kxh7',
    'Kxf7 Kh8',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Nf6',
    'O-O Qc7',
    'Qe2 d6',
    'c4 g6',
    'Nc3 Bg7',
    'Nf3 Nc6',
    'h3 O-O',
    'Bf4 Nd7',
    'Rac1 Nde5',
    'Rfd1 b6',
    'Qe3 Rb8',
    'Be2 Qe7',
    'Bg5 f6',
    'Bh6 Nxf3+',
    'Bxf3 e5',
    'Bxg7 Kxg7',
    'Nd5 Qd8',
    'Bg4 Bb7',
    'Qxb6 h5',
    'Qxd8 Rfxd8',
    'Be2 Nd4',
    'Rd2 Rdc8',
    'Rc3 a5',
    'f3 Bxd5',
    'exd5 h4',
    'Kf2 g5',
    'Ra3 Rc5',
    'Bd3 f5',
    'Ke3 Kf6',
    'Rc3 Ke7',
    'b3 Rbc8',
    'a3 Rxd5',
    'b4 axb4',
    'axb4 Rb5',
    'Rb2 Rb6',
    'Ra3 Kf6',
    'b5 Ne6',
    'Ra7 d5',
    'cxd5 Nf4',
    'Bf1 Nxd5+',
    'Kf2 e4',
    'fxe4 fxe4',
    'g3 Ke5',
    'gxh4 Rf6+',
    'Kg1 Rc1',
    'Rf2 Rxf2',
    'Kxf2 e3+',
    'Ke2 gxh4',
    'b6 Nf4+',
    'Kxe3 Rxf1',
    'Re7+ Kf6',
    'Re4 Kf5',
    'Rb4 Re1+',
    'Kf3 Rf1+',
    'Ke3 Nd5+',
    'Ke2 Nxb4',
    'Kxf1 Kf4',
    'b7 Nc6',
    'Kf2 Nb8',
    'Kg2 Ke3',
    'Kg1 Kf3',
    'Kh2 Kf2',
    'Kh1 Kg3',
    'Kg1 Kxh3',
    'Kh1 Kg3',
    'Kg1 Nc6',
    'Kh1 h3',
    'Kg1 h2+',
    'Kh1 Kh3',
    'b8=Q Nxb8',
  ],
  [
    'c4 e6',
    'Nc3 d5',
    'd4 Be7',
    'cxd5 exd5',
    'Nf3 Nf6',
    'Bg5 O-O',
    'e3 Nbd7',
    'Bd3 Re8',
    'Qc2 Nf8',
    'O-O c6',
    'a3 a5',
    'Rab1 Bd6',
    'Bh4 Bg4',
    'Nd2 Bh5',
    'Bg3 Bg6',
    'b4 Re7',
    'Ne2 Bxg3',
    'Nxg3 Qd6',
    'Rfc1 Ne6',
    'Nf3 axb4',
    'axb4 Nc7',
    'Ne5 Bxd3',
    'Nxd3 g6',
    'Ra1 Rb8',
    'Ra2 Kg7',
    'Rca1 h5',
    'Nf1 Ne4',
    'Nd2 h4',
    'h3 g5',
    'Nf3 f6',
    'Nc5 Qd8',
    'Nd2 Nd6',
    'Re1 Qe8',
    'Qd3 Qh5',
    'f3 Rbe8',
    'Nf1 Qg6',
    'Qxg6+ Kxg6',
    'Kf2 Ncb5',
    'Rea1 Nc3',
    'Ra3 Ncb5',
    'Ra8 b6',
    'Rxe8 Rxe8',
    'Nb3 Nc4',
    'Ke2 f5',
    'Kd3 Nb2+',
    'Kc2 Nc4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'a4 e5',
    'Nf3 Qc7',
    'Bg5 Nbd7',
    'Nd2 h6',
    'Bh4 g5',
    'Bg3 Nc5',
    'a5 Be6',
    'h4 Bg7',
    'Ra3 Rd8',
    'Qe2 d5',
    'f3 d4',
    'Nd1 Nh5',
    'Qf2 Bf6',
    'hxg5 Nxg3',
    'Qxg3 Bxg5',
    'Qh2 Ke7',
    'Nc4 d3',
    'cxd3 Bxc4',
    'dxc4 Bd2+',
    'Ke2 Ne6',
    'g3 Qxc4+',
    'Kf2 Qc5+',
    'Ne3 Bxe3+',
    'Rxe3 Rd2+',
    'Be2 Rxb2',
    'Rd1 Rb3',
    'Qh4+ f6',
    'Rd3 Rxd3',
    'Bxd3 Qxa5',
  ],
  [
    'e4 e5',
    'Qh5 Nc6',
    'Bc4 g6',
    'Qf3 Nf6',
    'Ne2 Bg7',
    'Nbc3 d6',
    'd3 Bg4',
    'Qg3 Qd7',
    'f3 Be6',
    'Bg5 Nh5',
    'Qh4 h6',
    'Be3 Na5',
    'Bb3 Nxb3',
    'axb3 a6',
    'd4 Qe7',
    'Qf2 exd4',
    'Bxd4 Nf6',
    'O-O-O O-O-O',
    'Nf4 Rhg8',
    'Rhe1 Kb8',
    'Kb1 g5',
    'Nfe2 Rge8',
    'g4 Qf8',
    'Ng3 Nd7',
    'Be3 Qh8',
    'Nge2 Be5',
    'h4 Qg7',
    'Rh1 Nf6',
    'Bd4 Nd7',
    'Qe3 Qf6',
    'hxg5 hxg5',
    'Bxe5 Qxe5',
    'Rh5 Rg8',
    'Nd5 Rde8',
    'Qc1 Qg7',
    'Ne3 Nf6',
    'Rh2 Rh8',
    'Rg2 Nd7',
    'Nd4 Rh3',
    'c4 Qf6',
    'Rf2 Reh8',
    'b4 Qe5',
    'c5 dxc5',
    'bxc5 Nxc5',
    'Qc3 f6',
    'Rc2 Na4',
    'Qb4 Bd7',
    'Nb3 Rh1',
    'Rxh1 Rxh1+',
    'Ka2 Nb6',
    'Qf8+ Qe8',
    'Qxe8+ Bxe8',
    'Nc5 Nd7',
    'Nxd7+ Bxd7',
    'Kb3 Re1',
    'Rc3 Be6+',
    'Kc2 Re2+',
    'Kc1 a5',
    'Nc2 Rf2',
    'Nd4 Bd7',
    'Rc5 b6',
    'Rd5 Kc8',
    'e5 fxe5',
    'Rxe5 c5',
    'Nb3 Rf1+',
    'Kd2 a4',
    'Nxc5 bxc5',
    'Rxc5+ Kb7',
    'Rxg5 Rxf3',
    'Rd5 Be6',
    'Rd3 Rf1',
    'Rg3 Rf2+',
    'Kc3 Kb6',
    'Kb4 Rf4+',
    'Ka3 Kb5',
    'Re3 Bd5',
    'Rd3 Bc4',
    'Re3 Rd4',
    'g5 Rd1',
    'b3 axb3',
    'Re8 Ra1+',
    'Kb2 Ra2+',
    'Kc3 Rc2+',
    'Kd4 b2',
    'Rb8+ Ka4',
    'g6 Bb5',
    'g7 b1=Q',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Qb6',
    'a3 c4',
    'Nbd2 Bd7',
    'Be2 Na5',
    'Rb1 Ne7',
    'O-O h6',
    'Re1 O-O-O',
    'Qc2 Kb8',
    'Nf1 Qb3',
    'Bd1 Qxc2',
    'Bxc2 Nc8',
    'a4 Nb6',
    'Ra1 Rc8',
    'N3d2 Be7',
    'f4 g6',
    'Ne3 h5',
    'g3 Rhg8',
    'h3 Rc6',
    'g4 Rh8',
    'Nf3 Rcc8',
    'Ra2 hxg4',
    'hxg4 Rh3',
    'Kg2 Rch8',
    'Nf1 Nb3',
    'a5 Nxc1',
    'Rxc1 Nc8',
    'Ng3 a6',
    'Raa1 Na7',
    'Rh1 Rxh1',
    'Nxh1 Nc6',
    'Nf2 Kc8',
    'Nh3 Kd8',
    'Nhg5 Ke8',
    'Kg3 Bd8',
    'Ba4 Nb8',
    'b3 cxb3',
    'Bxb3 Bc6',
    'f5 gxf5',
    'gxf5 exf5',
    'c4 dxc4',
    'Bxc4 Bxf3',
    'Nxf7 Rh1',
    'Rxh1 Bxh1',
    'Nxd8 Kxd8',
    'd5 Nd7',
    'Kf4 Nc5',
    'Kxf5 Ke7',
    'Kf4 Bg2',
    'Ke3 Ne4',
    'Bb3 Ng5',
    'Ba2 Nf7',
    'Kd4 Bf3',
    'Bb1 Bg2',
    'Bf5 Kd8',
    'Bd3 Bxd5',
    'Kxd5 Nxe5',
    'Kxe5 Kc7',
    'Kd5 b6',
    'axb6+ Kxb6',
    'Bxa6 Kxa6',
  ],
  [
    'd4 Nf6',
    'c4 Nc6',
    'Nf3 e6',
    'Bf4 d6',
    'Nc3 g6',
    'e4 Bg7',
    'Be2 O-O',
    'O-O Nd7',
    'Bg5 f6',
    'Be3 Ne7',
    'Qb3 Kh8',
    'c5 f5',
    'Ng5 Nb8',
    'f4 fxe4',
    'Ncxe4 Nbc6',
    'Rad1 Nd5',
    'cxd6 cxd6',
    'g3 Nxe3',
    'Qxe3 Qb6',
    'Nf3 Ne7',
    'Rd2 Bd7',
    'Re1 Rae8',
    'Qb3 Qc7',
    'Bf1 Nf5',
    'Nc3 Bc6',
    'd5 exd5',
    'Rxe8 Rxe8',
    'Nxd5 Qa5',
    'Qc4 Ne3',
    'Nxe3 Rxe3',
    'Ng5 h6',
    'Nf7+ Kh7',
    'Rxd6 Qb6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'f4 e5',
    'Nf3 Nbd7',
    'a4 Be7',
    'Bd3 O-O',
    'O-O exf4',
    'Bxf4 Qb6+',
    'Kh1 Qxb2',
    'Qe1 Qb6',
    'Be3 Qc7',
    'Bd4 Re8',
    'Qg3 Nh5',
    'Qf2 Ndf6',
    'Ng5 Rf8',
    'Rae1 h6',
    'Nh3 Be6',
    'Qh4 Rae8',
    'e5 dxe5',
    'Rxe5 Rd8',
  ],
  [
    'c4 e6',
    'Nc3 Nf6',
    'e4 d5',
    'e5 d4',
    'exf6 dxc3',
    'bxc3 Qxf6',
    'Nf3 e5',
    'Bd3 Bg4',
    'Be4 Nc6',
    'O-O O-O-O',
    'Qa4 Bd7',
    'Rb1 Bf5',
    'Bxf5+ Qxf5',
    'Qb5 Bb4',
    'Rxb4 Nxb4',
    'cxb4 f6',
    'd4 a6',
    'Qa5 Qc2',
    'Qc5 e4',
    'Bf4 Rd7',
    'Qa7 Re7',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'Nf3 Nf6',
    'd4 c6',
    'Bd2 Bf5',
    'Ne4 Qc7',
    'Nxf6+ gxf6',
    'g3 e6',
    'Bg2 Nd7',
    'O-O O-O-O',
    'Re1 Bd6',
    'c4 e5',
    'Nh4 Bg6',
    'c5 Bf8',
    'b4 exd4',
    'Qb3 Kb8',
    'Bf4 Ne5',
    'b5 Bxc5',
    'bxc6 b6',
    'Rac1 Bd6',
    'Red1 d3',
    'Nxg6 hxg6',
    'Rc3 Rhe8',
    'a4 g5',
    'Bxe5 fxe5',
    'Rcxd3 e4',
    'Rd5 Qxc6',
    'Rxg5 Bc7',
    'Rgd5 Rxd5',
    'Rxd5 Qe6',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Ba7',
    'O-O Ne7',
    'Nc3 Nbc6',
    'Qe2 O-O',
    'Be3 b5',
    'Bxa7 Rxa7',
    'Qe3 d6',
    'a4 b4',
    'Ne2 e5',
    'a5 Ra8',
    'Bc4 Kh8',
    'Rfd1 f5',
    'f3 Rf6',
    'Rd2 Rb8',
    'Rad1 fxe4',
    'fxe4 Ng6',
    'h3 Qc7',
    'Kh1 Nce7',
    'Qd3 Qa7',
    'c3 Nc6',
    'Bd5 bxc3',
    'bxc3 Nce7',
    'Bc4 Bb7',
    'Rb2 Rbf8',
    'Nd2 Nc6',
    'Ra1 Bc8',
    'Bd5 Nce7',
    'Rb6 Nh4',
    'Qg3 Nhf5',
    'Qd3 Nh4',
    'Rg1 Qd7',
    'Ng3 Neg6',
    'Nh5 Rf2',
    'Rb8 Qe7',
  ],
  [
    'Nf3 Nf6',
    'c4 Nc6',
    'd4 e6',
    'a3 d6',
    'Nc3 g6',
    'Bg5 Bg7',
    'e3 O-O',
    'Ne4 Nb8',
    'Be2 Nbd7',
    'Nc3 b6',
    'Qc2 Bb7',
    'Rd1 Qe7',
    'O-O h6',
    'Bh4 g5',
    'Bg3 Nh5',
    'd5 Nxg3',
    'hxg3 Ne5',
    'Nxe5 Bxe5',
    'Bf3 Rab8',
    'Nb5 a6',
    'Nd4 Bxd4',
    'exd4 Kg7',
    'Rfe1 Rbe8',
    'Re3 Qd8',
    'Rde1 exd5',
    'Bxd5 Rxe3',
    'Rxe3 Bc8',
    'Bc6 Qf6',
    'Qd2 Bg4',
    'b3 Rh8',
    'Bf3 Bd7',
    'Bd5 Rf8',
    'b4 c6',
    'Bf3 Rc8',
    'Be2 b5',
    'Rf3 Qe7',
    'Re3 Qd8',
    'Bd3 Qf6',
    'Rf3 Qe7',
    'Re3 Qd8',
    'Qc3 d5',
    'c5 a5',
    'bxa5 Ra8',
    'Bc2 g4',
  ],
  [
    'c4 c6',
    'd4 d5',
    'Nc3 Nf6',
    'e3 e6',
    'Nf3 Nbd7',
    'Qc2 Bd6',
    'g4 dxc4',
    'Bxc4 Nd5',
    'Bd2 b5',
    'Be2 Bb7',
    'Ne4 Be7',
    'Nc5 Nxc5',
    'dxc5 Qc7',
    'a4 O-O',
    'h4 a5',
    'Rc1 bxa4',
    'Bd3 h6',
    'Qxa4 Ba6',
    'Bxa6 Rxa6',
    'b4 Qa7',
    'bxa5 Bd8',
    'O-O Bxa5',
    'Bxa5 Rxa5',
    'Qxc6 Ra6',
    'Qb5 Rb8',
    'Qe2 Ra2',
    'Rc2 Nc3',
    'Qd3 Rxc2',
    'Qxc2 Qxc5',
    'Rc1 Rc8',
    'Kg2 Qc4',
    'Nd4 e5',
    'Nf5 Qxg4+',
    'Kh2 Kh8',
    'Rg1 Qh5',
    'Rxg7 e4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 dxe4',
    'Nxe4 Bd7',
    'Nf3 Bc6',
    'Bd3 Nd7',
    'Qe2 Be7',
    'O-O Ngf6',
    'Ng3 O-O',
    'Rd1 Bxf3',
    'Qxf3 c6',
    'b3 Qa5',
    'Bb2 Ba3',
    'Bc3 Bb4',
    'Bb2 Ba3',
    'Bc3 Bb4',
    'Bb2 Rad8',
    'a3 Bd6',
    'c4 Rfe8',
    'Re1 Qc7',
    'Bc2 Bf8',
    'Re2 g6',
    'Rae1 Bg7',
    'h3 b5',
    'Bc3 a6',
    'Nf1 Nh5',
    'Re4 Nhf6',
    'R4e2 Rc8',
    'g4 Red8',
    'Ng3 Ne8',
    'Ne4 Nf8',
    'Rd1 Qa7',
    'Red2 a5',
    'a4 bxc4',
    'bxc4 Nd6',
    'Nxd6 Rxd6',
    'Be4 Qa6',
    'c5 Rdd8',
    'Bd3 Qa8',
    'Be4 Nd7',
    'g5 Re8',
    'h4 e5',
    'dxe5 Nxc5',
    'Rd7 Rf8',
    'R7d6 Qb7',
    'Bxc6 Qb3',
    'Bd5 Qxa4',
    'Rd4 Qe8',
    'Rf4 Ne6',
    'Rc4 Rxc4',
    'Bxc4 Qa4',
    'Qg4 Qa3',
    'Rd3 Re8',
    'Rf3 a4',
    'Qe4 Qe7',
    'Qc6 Rd8',
    'Qxa4 Qb7',
    'Be2 Qc7',
    'Qc4 Qb8',
    'Qe4 Qc7',
  ],
  [
    'e4 e5',
    'Qh5 Nc6',
    'Bc4 g6',
    'Qf3 Nf6',
    'Ne2 d6',
    'h3 Bg7',
    'd3 Be6',
    'Bb3 h6',
    'Be3 Bxb3',
    'axb3 d5',
    'O-O O-O',
    'Na3 Kh7',
    'b4 a6',
    'c3 Qd6',
    'Ng3 Rad8',
    'Nc2 Qe6',
    'Qe2 Rfe8',
    'Rad1 Rd7',
    'Bc1 Red8',
    'Rfe1 Ng8',
    'f4 exf4',
    'Bxf4 Nf6',
    'e5 Ng8',
    'd4 f6',
    'exf6 Qxe2',
    'Rxe2 Nxf6',
    'Ne1 a5',
    'bxa5 Nxa5',
    'Nd3 Rf7',
    'Rde1 Ng8',
    'Nh1 Nc4',
    'Nhf2 Rdf8',
    'Bh2 c6',
    'Nc5 Ra8',
    'Nfd3 Ra2',
    'Rb1 Nf6',
    'Nb4 Ra8',
    'Rbe1 Ng8',
    'b3 Nb6',
    'Nbd3 Ra3',
    'Rb1 Nc8',
    'Na4 Nf6',
    'Kh1 Ne4',
    'Rc2 g5',
    'Nac5 Ncd6',
    'Ne6 Nb5',
    'Nxg7 Rxg7',
    'Be5 Rf7',
    'c4 dxc4',
    'bxc4 Nxd4',
    'Bxd4 Rxd3',
    'Be5 Re7',
    'Bh2 Kg6',
    'Rcb2 Rc3',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 dxe4',
    'Nxe4 Bd7',
    'Nf3 Bc6',
    'Bd3 Nd7',
    'Qe2 Ngf6',
    'Ng3 Bxf3',
    'Qxf3 c6',
    'O-O Qc7',
    'c4 O-O-O',
    'Re1 c5',
    'd5 Bd6',
    'dxe6 Ne5',
    'Qf5 fxe6',
    'Qxe6+ Kb8',
    'Be2 Rhe8',
    'Qf5 Nxc4',
    'Qc2 Nb6',
    'Bd2 h5',
    'Bg5 Rh8',
    'Bf3 h4',
    'Nf5 Bxh2+',
    'Kh1 Rde8',
    'Nxg7 Qxg7',
    'Qf5 Nh7',
    'Bd2 Bc7',
    'Bc3 Qg5',
    'Qf7 Ref8',
    'Qe7 Qxe7',
    'Rxe7 Nf6',
    'Rae1 Nfd5',
  ],
  [
    'c4 e5',
    'Nc3 Bb4',
    'g3 Bxc3',
    'bxc3 Nc6',
    'Bg2 d6',
    'd3 Nge7',
    'Nh3 Bd7',
    'f3 O-O',
    'O-O Ng6',
    'e3 Qc8',
    'Nf2 Nce7',
    'Kh1 f5',
    'f4 Bc6',
    'Bxc6 Nxc6',
    'Qf3 Qd7',
    'Rb1 b6',
    'h4 Rae8',
    'h5 Nge7',
    'h6 g6',
    'e4 exf4',
    'Bxf4 Ne5',
    'Qg2 Ng4',
    'Nxg4 fxg4',
    'Bg5 Nc6',
    'd4 Na5',
    'c5 dxc5',
    'dxc5 Nc4',
    'cxb6 cxb6',
    'Bf4 Qe6',
    'Rbd1 Ne5',
    'Bxe5 Qxe5',
    'Rxf8+ Rxf8',
    'Qe2 Qh5+',
    'Kg1 Qxh6',
    'e5 Qg5',
    'Qc4+ Kh8',
    'Qd4 Kg8',
    'e6 Qe7',
    'Qxg4 Rf5',
    'Qc4 b5',
    'Rd8+ Rf8',
    'Rxf8+ Kxf8',
    'Qf4+ Kg7',
    'Qe5+ Qf6',
    'Qc7+ Kf8',
    'Qc5+ Qe7',
    'Qe5 Qd8',
    'Qh8+ Ke7',
    'Qxh7+ Kxe6',
    'Qxg6+ Ke5',
    'Qh5+ Ke6',
    'Qxb5 Qd2',
    'Qc4+ Kf5',
    'Qf4+ Qxf4',
    'gxf4 Kxf4',
    'Kf2 Ke4',
    'Ke2 Kd5',
    'Kd3 Kc5',
    'c4 Kc6',
    'Kd4 Kd6',
    'c5+ Kc7',
    'Kd5 Kd7',
    'c6+ Kc7',
    'Kc5 Kc8',
    'Kd6 Kd8',
    'c7+ Kc8',
    'Kc6 a6',
    'Kd6 a5',
  ],
  [
    'e4 c5',
    'Nc3 d6',
    'g3 g6',
    'Bg2 Bg7',
    'd3 Nc6',
    'f4 Nh6',
    'Nf3 O-O',
    'O-O f5',
    'h3 Nf7',
    'Be3 Nd4',
    'a3 Bd7',
    'Qd2 Rc8',
    'Rab1 Bc6',
    'Nd5 e6',
    'Bxd4 cxd4',
    'Nb4 Ba4',
    'exf5 gxf5',
    'Rfe1 a5',
    'Na2 Bd7',
    'Re2 Kh8',
    'Rbe1 e5',
    'h4 Rg8',
    'Kh2 Bh6',
  ],
  [
    'e4 c5',
    'Nc3 Nc6',
    'Bb5 Nd4',
    'Bc4 e6',
    'Nge2 Ne7',
    'Nxd4 cxd4',
    'Ne2 Nc6',
    'O-O g6',
    'b4 Bg7',
    'Rb1 O-O',
    'b5 Na5',
    'Bd3 d5',
    'f4 Nc4',
    'e5 Qb6',
    'Kh1 Qc5',
    'Bxc4 dxc4',
    'd3 Rd8',
    'Bd2 b6',
    'Ng3 Bb7',
    'Qe2 Rac8',
    'Bb4 cxd3',
    'cxd3 Qc2',
    'Rb2 Qxe2',
    'Nxe2 Bf8',
    'Bxf8 Kxf8',
    'Kg1 Rc5',
    'a4 g5',
    'g3 g4',
    'Kf2 h5',
    'Rfb1 Bf3',
    'h4 Ke7',
    'Ng1 Bb7',
    'Ke2 a5',
    'Kd2 f6',
    'exf6+ Kxf6',
    'Ne2 Bf3',
    'Nc1 e5',
    'Nb3 Rcd5',
    'fxe5+ Rxe5',
    'Re1 Rxe1',
    'Kxe1 Ke5',
    'Nd2 Bd5',
    'Rc2 Rf8',
    'Rc7 Kd6',
    'Rh7 Re8+',
    'Kf2 Re5',
    'Rh6+ Kc5',
    'Nc4 Bxc4',
    'Rc6+ Kd5',
    'dxc4+ Ke4',
    'Rxb6 Kd3',
    'Rc6 Re2+',
    'Kf1 Rb2',
    'b6 Rb1+',
    'Kf2 Rb2+',
    'Kf1 Rb1+',
    'Kf2 Rb2+',
  ],
  [
    'e4 c5',
    'Qh5 d6',
    'f4 Nc6',
    'c3 Nf6',
    'Qf3 e5',
    'f5 d5',
    'd3 b6',
    'g4 Bb7',
    'g5 dxe4',
    'dxe4 Nd7',
    'Be3 Qc7',
    'Nd2 Nd4',
    'cxd4 cxd4',
    'Bf2 Qc2',
    'Bd3 Qxb2',
    'Rb1 Qxa2',
    'Qe2 a5',
    'Ngf3 Bb4',
    'O-O O-O',
    'Bc4 d3',
    'Qxd3 Nc5',
    'Qe3 Qa4',
    'g6 Rad8',
    'Bxf7+ Kh8',
    'f6 Bxd2',
    'Nxd2 Nxe4',
    'Nxe4 Qxe4',
    'Qxe4 Bxe4',
    'Rxb6 hxg6',
  ],
  [
    'd4 Nf6',
    'c4 Nc6',
    'Nf3 e6',
    'Nc3 Bb4',
    'Qc2 d6',
    'a3 Bxc3+',
    'Qxc3 a5',
    'b3 h6',
    'g3 O-O',
    'Bg2 Re8',
    'O-O e5',
    'dxe5 dxe5',
    'Bb2 Bg4',
    'h3 Bh5',
    'Qe3 Qe7',
    'Rad1 Nd7',
    'Nh4 Rad8',
    'Nf5 Qf8',
    'g4 Bg6',
    'Ng3 Nd4',
    'Bxb7 Nc5',
    'Bd5 c6',
    'Bg2 Bc2',
    'Rc1 Bxb3',
    'Bc3 Nc2',
    'Qf3 e4',
    'Qf5 Nd4',
    'Qf4 Nce6',
    'Qxe4 Qxa3',
    'Ra1 Ba2',
    'Qb1 Qxc3',
    'Qxa2 Nf4',
    'e3 Nxg2',
    'Kxg2 Nc2',
    'Qxa5 Qd2',
    'Qxd2 Rxd2',
    'Kf3 Nxa1',
    'Rxa1 Rc2',
    'Ra4 Re6',
    'Ne4 c5',
    'Ra5 Rxc4',
    'Nxc5 Rf6+',
    'Kg3 Rd6',
    'Kf3 Rd5',
    'Nb3 Rxa5',
    'Nxa5 Rc3',
    'Nb7 Kf8',
    'Nd6 Ke7',
    'Nf5+ Kf6',
    'Ng3 Rc2',
    'h4 g6',
    'g5+ hxg5',
    'Ne4+ Kf5',
    'Nxg5 Rc7',
    'Kg3 Ra7',
    'Kf3 Kf6',
    'Ne4+ Kg7',
    'Ng5 Kh6',
    'Kg3 Kg7',
    'Kh3 Ra4',
    'Kg3 Ra7',
    'Kg4 Rb7',
    'Kf4 Kg8',
    'Kg4 Kf8',
    'Kf4 Ke7',
    'Kg4 Rb4+',
    'Kg3 Kf6',
    'Kh3 Rb7',
    'Kg4 Ke5',
    'Kf3 Kd5',
    'Kg4 Ke5',
    'Kf3 Ra7',
    'Kg3 Kd5',
    'Kg4 f5+',
    'Kg3 Ra4',
    'f3 Ke5',
    'Nh3 Ra3',
    'Nf4 Kf6',
    'Kf2 Kg7',
    'Nd5 Kh6',
    'Nf4 Ra6',
    'Kg3 Ra2',
    'Kh3 Ra3',
    'Ng2 Kg7',
    'Kg3 Kf6',
    'Kf4 Rb3',
    'Kg3 Kg7',
    'Kh3 Kf7',
    'Kg3 Ra3',
    'Kf4 Ra2',
    'Kg3 Re2',
    'Kh3 Ke8',
    'Kg3 Kd7',
    'Kh3 Kd6',
    'Kg3 Ke5',
    'Kh3 Kf6',
    'Kg3 Kf7',
    'Kh3 Rf2',
    'Kg3 Rf1',
    'Nf4 Rh1',
    'Ng2 Ke7',
    'Nf4 Kf7',
    'Ng2 Ra1',
    'Nf4 Ra6',
    'Ng2 Ke6',
    'Kf4 Ra1',
    'Kg3 Kd5',
    'Nf4+ Kc4',
    'Nxg6 Rg1+',
    'Kf4 Rxg6',
    'h5 Rh6',
    'Kg5 Rh8',
    'h6 Kd3',
    'Kxf5 Rxh6',
    'f4 Ra6',
  ],
  [
    'c4 e5',
    'Nc3 Nc6',
    'g3 f5',
    'Bg2 Nf6',
    'd3 Bb4',
    'Bd2 O-O',
    'Nh3 d6',
    'f4 Ng4',
    'O-O Bxc3',
    'bxc3 Qe8',
    'fxe5 Ncxe5',
    'Qc2 Qh5',
    'Qb3 Kh8',
    'd4 Ng6',
    'e3 Rb8',
    'Rae1 Bd7',
    'Qc2 Bc6',
    'd5 Bd7',
    'c5 dxc5',
    'c4 Rbe8',
    'Bc1 Rf7',
    'e4 Rfe7',
    'Bg5 Rxe4',
    'Bxe4 fxe4',
    'Bh4 N4e5',
    'Ng5 h6',
    'Ne6 Nxh4',
    'Rxe4 Nhf3+',
    'Kh1 Bxe6',
    'dxe6 Rxe6',
    'Rf4 Rf6',
    'Qe2 Qf7',
    'Qe3 Rxf4',
    'Qxf4 Qg6',
    'Rxf3 Nxf3',
    'Qxf3 Qb1+',
    'Kg2 Qxa2+',
    'Kh3 Qxc4',
    'Qxb7 Qe6+',
    'Kg2 Qe2+',
    'Kg1 Qd3',
    'Qxa7 c4',
    'Qb8+ Kh7',
    'Qxc7 c3',
    'Qf4 c2',
    'Kf2 Qd5',
    'Qe3 Qc6',
    'Qc1 Qb6+',
    'Ke2 Qb1',
    'Kd2 Qxc1+',
    'Kxc1 Kg6',
    'Kxc2 Kf5',
  ],
  [
    'd4 Nf6',
    'Bg5 c5',
    'd5 d6',
    'Nc3 g6',
    'Qd2 a6',
    'a4 Bg7',
    'e4 O-O',
    'Nf3 Qa5',
    'Ra3 Qb4',
    'e5 dxe5',
    'Rb3 Qa5',
    'Nxe5 b5',
    'axb5 Qa1+',
    'Qc1 axb5',
    'Bxb5 e6',
    'dxe6 Bxe6',
    'O-O Qxc1',
    'Rxc1 Bxb3',
    'cxb3 Ne4',
    'Nxe4 Bxe5',
    'Nc3 Ra5',
    'Bc4 Nd7',
    'Na4 Bd4',
    'Bf4 Re8',
    'Kf1 Kg7',
    'g3 h6',
    'Rd1 Ne5',
    'Re1 g5',
    'Bxe5+ Rxe5',
    'Rd1 Rf5',
    'Rd2 g4',
    'Bd3 Rf6',
    'Bc2 Rb5',
    'Kg2 Rb8',
    'Nc3 h5',
    'Nd5 Rd6',
    'Ne3 Rdd8',
    'Nf5+ Kf6',
    'h3 Bxb2',
    'Re2 Bc1',
    'hxg4 hxg4',
    'f4 Re8',
    'Rxe8 Rxe8',
    'Kf1 Rh8',
    'Nd6 Bd2',
    'Kg2 Ke6',
    'Nc4 Ra8',
    'Bd1 Ra2',
    'Bxg4+ f5',
    'Bh3 Be1+',
    'Kf1 Bxg3',
    'Ne3 Rf2+',
    'Kg1 Rxf4',
    'Nd1 Rb4',
    'Bf1 Be5',
    'Bc4+ Rxc4',
    'bxc4 Bd4+',
    'Kg2 f4',
    'Kf3 Kf5',
  ],
  [
    'd4 Nf6',
    'c4 Nc6',
    'Nf3 e6',
    'g3 d5',
    'Bg2 dxc4',
    'Qa4 Bb4+',
    'Bd2 Nd5',
    'Bxb4 Nxb4',
    'O-O Rb8',
    'Na3 O-O',
    'Qb5 b6',
    'Qxc4 Ba6',
    'Nb5 Qd5',
    'Qxd5 exd5',
    'Nc3 Rfe8',
    'Rfd1 Ne7',
    'Rac1 Rbd8',
    'a3 Nbc6',
    'e3 Nb8',
    'b4 c6',
    'a4 Bb7',
    'Ne5 Nd7',
    'Nxd7 Rxd7',
    'a5 b5',
    'f3 f5',
    'Kf2 Red8',
    'Ne2 Nc8',
    'Nf4 Re7',
    'Nd3 Nd6',
    'Ne5 Nc4',
    'f4 Rd6',
    'h4 g6',
    'Bf3 Bc8',
    'Rh1 h6',
    'Rh2 Rg7',
    'Rhh1 Kf8',
    'Rh2 Rf6',
    'Rhh1 Ke7',
    'Rh2 Kd8',
    'Rhh1 Kc7',
    'Rh2 Be6',
    'Ra1 Rff7',
    'Rc1 Rf8',
    'Rc2 Re8',
    'Rc1 Rd8',
    'Rhh1 Bd7',
    'Rhd1 Bc8',
    'Rd3 a6',
    'Rdd1 Bb7',
    'Rd3 Rd6',
    'Ra1 Re6',
    'Rdd1 Re8',
    'Rh1 Bc8',
    'Rad1 Bb7',
    'Ra1 Reg8',
    'Rh2 Bc8',
    'Rd1 Bd7',
    'Ra1 Be8',
    'Rah1 Bd7',
    'Rd1 Bc8',
    'Rdh1 Bb7',
    'Rd1 Kb8',
    'Rhh1 Re8',
    'Rd3 Ka7',
    'Rdd1 c5',
    'dxc5 Nxe5',
    'fxe5 Rxe5',
    'Rd3 Kb8',
    'Rhd1 Rd7',
    'Rd4 Kc7',
    'Rc1 Bc6',
    'Rcd1 Kd8',
    'Bg2 Ke7',
    'Bf3 Kf6',
    'Bg2 Rde7',
    'R1d3 Ke6',
    'Rd1 Rd7',
    'R1d2 Ke7',
    'Rd1 Kd8',
    'R1d2 Kc7',
    'Rd1 Bb7',
    'Bf3 Rd8',
    'Bg2 Kc6',
    'Bf3 Bc8',
    'R4d3 Be6',
    'Rd4 Bf7',
    'R4d3 Rf8',
    'Rd4 Be6',
    'Bg2 g5',
    'hxg5 hxg5',
    'Rh1 Rf7',
    'Rh6 Re7',
    'Rg6 g4',
    'Rh6 Kc7',
    'Rd3 Kb8',
    'Rh8+ Kc7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'O-O Be7',
    'Re1 b5',
    'Bb3 O-O',
    'd4 d6',
    'c3 Bg4',
    'Be3 exd4',
    'cxd4 Na5',
    'Bc2 c5',
    'dxc5 dxc5',
    'h3 Qxd1',
    'Bxd1 Be6',
    'Nbd2 Nc6',
    'Ng5 c4',
    'a3 Rad8',
    'Bc2 Bc8',
    'f4 Nd7',
    'Ngf3 Nc5',
    'e5 Nd3',
    'Bxd3 Rxd3',
    'Ne4 Bf5',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'O-O Nxe4',
    'd4 b5',
    'Bb3 d5',
    'dxe5 Be6',
    'Nbd2 Nc5',
    'c3 d4',
    'Bxe6 Nxe6',
    'cxd4 Ncxd4',
    'a4 Bb4',
    'axb5 Nxb5',
    'Qb3 a5',
    'Qc4 Nbd4',
    'Nxd4 Qxd4',
    'Qc6+ Ke7',
    'Nf3 Qd7',
    'Qc2 h6',
    'Rd1 Qb5',
    'Nd4 Nxd4',
    'Rxd4 Rhd8',
    'Qxc7+ Rd7',
    'Rxd7+ Qxd7',
    'Qc2 Rd8',
    'Be3 Qd3',
    'Qxd3 Rxd3',
    'Kf1 Rb3',
    'Bd4 Ke6',
    'Ke2 g5',
    'Rd1 Kd5',
    'Bc3+ Ke6',
    'Bd4 Kd5',
    'Rd3 Rxd3',
    'Kxd3 a4',
    'f3 h5',
    'Be3 Be7',
    'Bd4 Bb4',
    'Be3 Be7',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'f3 e5',
    'Nb3 Be6',
    'Be3 Be7',
    'Qe2 a5',
    'Qb5 O-O',
    'O-O-O Qb8',
    'Na4 Ra6',
    'Kb1 Ne8',
    'Nb6 a4',
    'Nd2 Bd8',
    'Nd5 Ra5',
    'Qe2 Bxd5',
    'exd5 Rxd5',
    'Qc4 Ra5',
    'Ne4 Be7',
    'Be2 a3',
    'b3 Nf6',
    'Bb6 Ra6',
    'Bf2 Qc7',
    'Nxf6+ Bxf6',
    'Rd5 Ra5',
    'Rhd1 Rxd5',
    'Rxd5 Rc8',
    'Bd3 g6',
    'Be4 Kg7',
    'Be3 Qe7',
    'Bc1 Ra8',
    'Rb5 Ra6',
    'Be3 Qd7',
    'Qd3 Qc7',
    'c3 Ra8',
    'Qc4 Rc8',
    'Bc1 Qd7',
    'Bxa3 Nd8',
    'Qd5 Rxc3',
    'Qxd6 Qe8',
    'Rb6 Ne6',
    'Rxb7 h5',
    'Rb8 Rc8',
    'Rxc8 Qxc8',
    'Bb2 Nd4',
    'Qb4 Qa6',
    'Qe1 h4',
    'Qd1 Qb5',
    'f4 Qb6',
    'fxe5 Bxe5',
    'a4 f6',
    'Ka2 Ne6',
    'Qd7+ Kh6',
    'Bc1+ Bf4',
    'Bxf4+ Nxf4',
    'Qd2 g5',
    'g3 Qe6',
    'Bf3 Ng6',
    'a5 Ne5',
    'Bb7 hxg3',
    'hxg3 Qe7',
    'Be4 Qc5',
    'a6 f5',
    'Bxf5 Nc6',
    'Qh2+ Kg7',
    'Qh7+ Kf6',
    'Qg6+ Ke7',
    'Qe6+ Kf8',
    'Qf6+ Ke8',
    'Bg6+ Kd7',
    'Qf5+ Qxf5',
    'Bxf5+ Kc7',
    'Ka3 Kb6',
    'Bd3 Ne5',
    'Be2 Nc6',
    'g4 Nd4',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 dxc4',
    'a4 Bf5',
    'Ne5 Nbd7',
    'Nxc4 Nb6',
    'Ne5 a5',
    'Bg5 h6',
    'Bh4 e6',
    'e4 Bh7',
    'f3 Be7',
    'Bf2 Nfd7',
    'Nd3 O-O',
    'Be2 Rc8',
    'O-O c5',
    'dxc5 Nxc5',
    'Nxc5 Bxc5',
    'Bxc5 Rxc5',
    'Qxd8 Rxd8',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Qb6',
    'a3 c4',
    'Nbd2 Na5',
    'Be2 Bd7',
    'O-O Ne7',
    'Rb1 Qc7',
    'Re1 Nc8',
    'Nf1 Nb6',
    'Bf4 Ba4',
    'Qc1 Nb3',
    'Qe3 h6',
    'h4 O-O-O',
    'h5 Na5',
    'N3d2 Bc2',
    'Rbc1 Bh7',
    'Bd1 Kb8',
    'Bc2 Bxc2',
    'Rxc2 Be7',
    'Bh2 Ka8',
    'Qh3 Qd7',
    'f4 g6',
    'Ne3 Rdg8',
    'Nf3 Nc6',
    'Kh1 Bd8',
    'g4 Qe8',
    'Rg1 Nd7',
    'Re2 gxh5',
    'Qxh5 Qf8',
    'f5 Bg5',
    'Rf1 Qg7',
    'Ng2 Qh7',
    'Ref2 Nd8',
    'Ng1 Nb8',
    'Ne2 Na6',
    'Ng3 Re8',
    'Qh3 Re7',
    'Nh5 Rc7',
    'Bf4 Nb8',
    'Bxg5 hxg5',
    'Ne3 Nbc6',
    'Rh2 Ne7',
    'Rf3 Rc6',
    'Nf6 Qxh3',
    'Rfxh3 Rxh3',
    'Rxh3 b5',
    'Nh7 exf5',
    'gxf5 g4',
  ],
  [
    'Nf3 d5',
    'd4 Nf6',
    'c4 c6',
    'Nc3 dxc4',
    'a4 Bf5',
    'Ne5 Nbd7',
    'Nxc4 Nb6',
    'Ne5 a5',
    'Bg5 h6',
    'Bh4 Bh7',
    'f3 Nfd7',
    'Nxd7 Nxd7',
    'Qb3 Qc7',
    'e3 e6',
    'Be2 Bd6',
    'Bf2 O-O',
    'Rc1 e5',
    'e4 exd4',
    'Nb5 Qb8',
    'Nxd6 Qxd6',
    'Bg3 Qb4+',
    'Qxb4 axb4',
    'Kf2 f5',
    'exf5 Bxf5',
    'Rhd1 c5',
    'Bd6 Rfc8',
    'Bc4+ Kh7',
    'g4 Bg6',
    'Bb5 Be8',
    'Bxd7 Bxd7',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Nc3 a6',
    'Nxc6 bxc6',
    'Bd3 d5',
    'O-O Nf6',
    'Re1 Be7',
    'Qf3 O-O',
    'Bf4 g6',
    'Na4 Bb7',
    'Rad1 Qa5',
    'b3 dxe4',
    'Bxe4 Nxe4',
    'Qxe4 Rad8',
    'h3 Qh5',
    'Rxd8 Rxd8',
    'c4 Rd7',
    'Be5 c5',
    'Qe3 Qf5',
    'Nb2 Bg5',
    'f4 Bh4',
    'Re2 h5',
    'Kh2 Be7',
    'Rf2 h4',
    'Bc3 Qe4',
    'Re2 Qxe3',
    'Rxe3 g5',
    'fxg5 Bxg5',
    'Rd3 Rxd3',
    'Nxd3 Be3',
    'Ne5 f6',
    'Nf3 Kf7',
    'a4 Be4',
    'b4 cxb4',
    'Bxb4 Bf2',
    'a5 e5',
    'Nd2 Bc6',
    'Nb3 Bb7',
    'Nc5 Bxc5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 e6',
    'Nf3 Nbd7',
    'Bd3 dxc4',
    'Bxc4 b5',
    'Bb3 b4',
    'Ne2 Bd6',
    'Ng3 Bb7',
    'e4 Bxg3',
    'hxg3 Nxe4',
    'Qe2 c5',
    'Rh4 Nef6',
    'Ne5 Nd5',
    'dxc5 Nxe5',
    'Qxe5 Qd7',
    'Qxg7 O-O-O',
    'Qe5 Ba6',
    'Bxd5 exd5',
    'Rxb4 Bb5',
    'c6 Qxc6',
    'Be3 Rhe8',
    'Qf4 d4',
    'Qf5+ Kb7',
    'Qxb5+ Qxb5',
    'Rxb5+ Ka6',
    'Rb3 dxe3',
    'Rxe3 Rxe3+',
    'fxe3 Rb8',
    'b3 Rg8',
    'Kf2 Rg6',
    'Rh1 h6',
    'Rh4 Kb6',
    'Rf4 f6',
    'Rf5 Kc7',
    'Kf3 Kd8',
    'Kf4 Ke8',
    'b4 Kf8',
    'a4 Ke8',
  ],
  [
    'd4 Nf6',
    'Nf3 g6',
    'c4 Bg7',
    'Nc3 O-O',
    'e4 d6',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'b4 Nh5',
    'Re1 f5',
    'Ng5 Nf6',
    'Bf3 c6',
    'b5 h6',
    'Ne6 Bxe6',
    'dxe6 Qa5',
    'Bb2 fxe4',
    'Nxe4 Nxe4',
    'Rxe4 d5',
    'cxd5 cxd5',
    'Rxe5 Bxe5',
    'Bxe5 Qxb5',
    'Rb1 Qc6',
    'Ba1 Rf4',
    'g3 Rc4',
    'Qd2 Qc5',
    'Bf6 g5',
    'Rxb7 Rc1+',
    'Kg2 Qc4',
    'Qe2 Qxe2',
    'Bxe2 Nf5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bg5 e6',
    'f4 Nbd7',
    'Qf3 Qc7',
    'O-O-O b5',
    'Bd3 Bb7',
    'Rhe1 Qb6',
    'Nd5 Qxd4',
    'Bxf6 gxf6',
    'Bxb5 Qc5',
    'Nxf6+ Kd8',
    'Nxd7 Qxb5',
    'Nxf8 Rxf8',
    'Qa3 Rc8',
    'Qxd6+ Ke8',
    'c3 Qc6',
    'Qb4 a5',
    'Qxa5 Ra8',
    'Qg5 f6',
    'Qd5 Qxc3+',
    'bxc3 exd5',
    'exd5+ Kd7',
    'Kb1 Ra4',
    'g3 Rfa8',
    'Rd2 R8a5',
    'd6 Be4+',
    'Ka1 h5',
    'h3 Bd5',
    'g4 Rxa2+',
    'Rxa2 Rxa2+',
    'Kb1 Rh2',
    'Re3 h4',
    'Kc1 Kxd6',
    'f5 Rf2',
    'Kd1 Rf3',
  ],
  [
    'c4 Nf6',
    'Nc3 c5',
    'g3 Nc6',
    'Bg2 g6',
    'Nf3 e5',
    'O-O Bg7',
    'e3 O-O',
    'd4 cxd4',
    'exd4 d6',
    'd5 Na5',
    'Nd2 b6',
    'b4 Nb7',
    'Bb2 h5',
    'Ne2 h4',
    'Qb3 Nh5',
    'f4 Bg4',
    'Bf3 Qd7',
    'Rae1 Rae8',
    'Ne4 hxg3',
    'hxg3 exf4',
    'Bxg7 Kxg7',
    'Qc3+ f6',
    'Nxf4 Re5',
    'Bxg4 Qxg4',
    'Ne6+ Kf7',
    'Nxf6 Qxg3+',
    'Qxg3 Nxg3',
  ],
  [
    'c4 Nf6',
    'Nc3 d5',
    'cxd5 Nxd5',
    'd4 g6',
    'e4 Nxc3',
    'bxc3 Bg7',
    'Nf3 c5',
    'Be3 Qa5',
    'Qd2 O-O',
    'Rb1 a6',
    'Rc1 e6',
    'dxc5 Nd7',
    'c4 Qa3',
    'Be2 Nxc5',
    'Qd6 Qa5+',
    'Bd2 Rd8',
    'Bxa5 Rxd6',
    'e5 Nd3+',
    'Bxd3 Rxd3',
    'Bb4 b5',
    'Ke2 bxc4',
    'Rxc4 Rd5',
    'Rd1 a5',
    'Rxd5 exd5',
    'Rxc8+ Rxc8',
    'Bxa5 Ra8',
    'Bb6 Rxa2+',
    'Kd3 h6',
    'h4 Kf8',
    'Ke3 Ke7',
    'Bc7 f6',
    'exf6+ Bxf6',
    'Bb6 Ra3+',
    'Ke2 Ra4',
    'g3 Ke6',
    'Be3 Bg7',
    'Ne1 Ra2+',
    'Kf3 d4',
    'Bf4 Kd5',
    'Nd3 Kc4',
    'Ne5+ Bxe5',
    'Bxe5 d3',
    'g4 h5',
    'gxh5 gxh5',
    'Bf6 Ra6',
  ],
  [
    'c4 Nf6',
    'Nc3 e5',
    'e3 d5',
    'cxd5 Nxd5',
    'Nf3 Nxc3',
    'bxc3 Bd6',
    'd4 Nd7',
    'Be2 O-O',
    'O-O Qe7',
    'Nd2 Ba3',
    'Bxa3 Qxa3',
    'Nc4 Qe7',
    'Qc2 b6',
    'Nxe5 Nxe5',
    'Qe4 Bd7',
    'Qxe5 Qxe5',
    'dxe5 Rae8',
    'f4 f6',
    'Rad1 Bc8',
    'Bc4+ Kh8',
    'exf6 Rxe3',
    'Rfe1 Rxe1+',
    'Rxe1 gxf6',
    'Re7 Bf5',
    'h3 c6',
    'g4 b5',
    'Bb3 Bd3',
    'Rxa7 c5',
    'Be6 f5',
    'Rd7 c4',
    'g5 Re8',
    'Bd5 Be4',
    'Bf7 Ra8',
    'h4 b4',
    'Rc7 bxc3',
    'Bxc4 c2',
    'Bb3 Rd8',
    'Kf2 Rd2+',
  ],
  [
    'c4 Nf6',
    'Nc3 e5',
    'e3 d5',
    'cxd5 Nxd5',
    'Nf3 Nxc3',
    'bxc3 Bd6',
    'd4 Nd7',
    'Be2 O-O',
    'O-O c5',
    'Bb2 Qe7',
    'Nd2 Bc7',
    'Bf3 Rb8',
    'Ba3 b6',
    'Qa4 a6',
    'Rab1 Rd8',
    'Rfd1 Nf6',
    'Qc2 g6',
    'dxc5 bxc5',
    'e4 Be6',
    'Be2 Rxb1',
    'Rxb1 Qd7',
    'Nf3 h6',
    'Bxc5 Ra8',
    'Be3 Qc6',
    'Nd2 Kh7',
    'c4 Ng4',
    'Bxg4 Bxg4',
    'f3 Be6',
    'c5 Rd8',
    'Rc1 Qb5',
    'Nc4 a5',
    'h3 a4',
    'Qc3 a3',
    'Nxa3 Qe2',
    'Qe1 Qa6',
    'Nb1 Qxa2',
    'Nc3 Qb3',
    'Rb1 Qc4',
    'Nb5 Bb8',
    'Qh4 Qd3',
    'Re1 h5',
    'Nd6 Bc7',
    'Qg5 Ba5',
    'Ra1 Ra8',
    'Kh2 Kg8',
    'Nb7 Qc3',
    'Rxa5 Rxa5',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nf3 Bg7',
    'g3 O-O',
    'Bg2 d5',
    'cxd5 Nxd5',
    'O-O c5',
    'e4 Nf6',
    'e5 Nd5',
    'dxc5 Na6',
    'a3 Nxc5',
    'b4 Ne6',
    'Qb3 Nec7',
    'Rd1 Bg4',
    'Nc3 e6',
    'Bb2 Qb8',
    'Ne4 Rd8',
    'Nf6+ Bxf6',
    'exf6 Bxf3',
    'Bxf3 Rd7',
    'Rd3 b6',
    'Be5 Qb7',
    'Rc1 Rad8',
    'b5 Ne8',
    'Qc4 a6',
    'bxa6 Qa7',
    'Bxd5 exd5',
    'Qb5 Nd6',
    'Qxd5 Qxa6',
    'Bxd6 Rxd6',
    'Qxd6 Rxd6',
    'Rxd6 h5',
    'Rd8+ Kh7',
    'Rd7 g5',
    'Rxf7+ Kg6',
    'Rfc7 Kxf6',
    'R7c6+ Ke7',
    'Rh6 Qxa3',
    'Re1+ Kf7',
    'Rxh5 Kg6',
    'Rh8 Qc3',
    'Rhe8 b5',
    'R8e3 Qb2',
    'Re6+ Kf7',
    'Re7+ Kf8',
    'R7e2 Qb3',
    'Re5 Qd3',
    'h3 b4',
    'Re8+ Kf7',
    'R8e7+ Kf8',
    'Rb7 Qc3',
    'Rb8+ Kf7',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'e3 a6',
    'Qc2 Bg4',
    'Bd3 e6',
    'Nge2 Bh5',
    'Bd2 Nbd7',
    'Nf4 Bg6',
    'Nxg6 hxg6',
    'h3 dxc4',
    'Bxc4 b5',
    'Be2 c5',
    'Bf3 cxd4',
    'exd4 Rc8',
    'Qb3 Rc7',
    'O-O Bd6',
    'Ne4 Nxe4',
    'Bxe4 Nf6',
    'Bf3 Qb8',
    'Rfc1 O-O',
    'Rxc7 Bxc7',
    'Qa3 Qa7',
    'Rc1 Qxd4',
    'Be3 Qe5',
    'Qxa6 Qh2+',
    'Kf1 Bf4',
    'Rc8 Bxe3',
    'Rxf8+ Kxf8',
    'Qa3+ Kg8',
    'Qxe3 Qd6',
    'a3 b4',
    'g3 bxa3',
    'bxa3 Nd7',
    'Kg2 Ne5',
    'a4 Qc7',
    'Qb3 Qc5',
    'Qb8+ Kh7',
    'Qb5 Qc3',
    'Be2 Nc6',
    'Qc4 Qxc4',
    'Bxc4 Kg8',
    'Bb5 Nd4',
    'Bd3 Nb3',
    'Kf3 Kf8',
    'Ke3 Ke7',
    'Bb5 g5',
    'Bc4 Na5',
    'Be2 Kd6',
    'Kf3 f5',
    'Ke3 e5',
    'Kd3 Kc5',
    'Kc3 Nc6',
    'Bc4 e4',
    'Bf7 Ne5',
    'Be6 f4',
    'g4 Nd3',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 dxe4',
    'Nxe4 Bd7',
    'Nf3 Bc6',
    'Bd3 Nd7',
    'O-O Ngf6',
    'Ng3 Be7',
    'Re1 Bxf3',
    'Qxf3 c6',
    'c3 O-O',
    'Bf4 Re8',
    'Rad1 Qb6',
    'Bc1 c5',
    'dxc5 Nxc5',
    'Bc2 Rad8',
    'Rxd8 Bxd8',
    'Nh5 Nxh5',
    'Bxh7+ Kxh7',
    'Qxf7 Rf8',
    'Qxf8 Nd3',
    'Qf3 Nhf4',
    'Bxf4 Nxe1',
    'Qe4+ Kh8',
    'b3 Qa6',
    'Qxe1 Qxa2',
    'Qd1 Bb6',
    'Bg3 Qb2',
    'c4 Qc3',
    'Kh1 e5',
    'h3 Bd4',
    'Kh2 Kg8',
    'Qb1 a6',
    'Bh4 Qd2',
    'Qg6 Qf4+',
    'Bg3 Qf7',
    'Qg5 Qe6',
    'Qd8+ Kh7',
    'Qh4+ Kg8',
    'Qe4 b5',
    'f4 bxc4',
    'fxe5 Bc5',
    'bxc4 a5',
    'Qa8+ Kh7',
    'Qxa5 Qxc4',
    'e6 Kg6',
    'Qc7 Qd4',
    'h4 Qg1+',
    'Kh3 Qh1+',
    'Bh2 Qc1',
    'Qe5 Qa3+',
    'Bg3 Kh7',
    'h5 Be7',
    'Qf5+ Kh8',
    'Qg6 Bf8',
    'Kh2 Qc5',
    'h6 gxh6',
    'Bh4 Qd6+',
    'Kh3 Bg7',
    'Qe4 Kg8',
    'Qf5 Qa3+',
    'Bg3 Qe7',
    'Bh4 Qa3+',
    'Qf3 Qc1',
    'e7 Qc8+',
    'Kh2 Qe6',
    'Qh5 Qe5+',
    'Qxe5 Bxe5+',
    'Bg3 Bxg3+',
    'Kxg3 Kf7',
    'Kg4 Kxe7',
    'Kh5 Kf6',
    'Kxh6 Kf5',
    'Kh5 Kf6',
    'g4 Kg7',
    'Kg5 Kh7',
    'Kf6 Kh6',
    'g5+ Kh7',
    'Kf7 Kh8',
  ],
  [
    'e4 c5',
    'Qh5 Nf6',
    'Qh4 Nc6',
    'Be2 e5',
    'd3 Be7',
    'Qg3 d5',
    'Nd2 O-O',
    'c3 b5',
    'Nh3 d4',
    'c4 Ne8',
    'cxb5 Bh4',
    'Qf3 Nb4',
    'Bd1 f5',
    'a3 Nd6',
    'axb4 fxe4',
    'Qh5 Bxh3',
    'g3 Qf6',
    'Bb3+ Kh8',
    'f3 exf3',
    'Kf2 Bg5',
    'Nxf3 g6',
    'Bxg5 Qf5',
    'Qxh3 Qxf3+',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Be3 e6',
    'Nd2 Nd7',
    'f4 Qb6',
    'Nb3 a5',
    'a4 Bb4+',
    'c3 Be7',
    'Nf3 Nh6',
    'Be2 O-O',
    'O-O Ng4',
    'Bc1 Nh6',
    'h3 Be4',
    'g4 c5',
    'Bb5 Rad8',
    'Be3 cxd4',
    'cxd4 f6',
    'exf6 Nxf6',
    'Bd2 Bb4',
    'Bxb4 axb4',
    'g5 Nf5',
    'gxf6 Ne3',
    'Qe2 Nxf1',
    'Rxf1 Rxf6',
    'Ne5 Rh6',
    'Qe3 Bf5',
    'Rf3 Qc7',
    'Bd3 b6',
    'Bxf5 exf5',
    'Qd3 Rf6',
    'Rf1 Rc8',
    'Rc1 Qd8',
    'Rxc8 Qxc8',
    'Qb5 h6',
    'Qxd5+ Kh7',
    'Qg2 Qe8',
    'a5 Qa4',
    'a6 Qxb3',
    'a7 Qe3+',
    'Kh1 Qc1+',
    'Qg1 Qc8',
    'Qg2 Qc1+',
    'Qg1 Qc8',
  ],
  [
    'd4 d5',
    'c4 c6',
    'e3 Nf6',
    'Qc2 g6',
    'Bd3 Bg7',
    'Nf3 O-O',
    'O-O Bg4',
    'Nbd2 Nbd7',
    'h3 Bxf3',
    'Nxf3 c5',
    'b3 Rc8',
    'Bb2 b5',
    'Qe2 cxd4',
    'exd4 bxc4',
    'bxc4 dxc4',
    'Bxc4 Nb6',
    'Bb3 e6',
    'Ne5 Nfd5',
    'Rac1 Qg5',
    'g3 Qf5',
    'Kh2 h5',
    'h4 Rfd8',
    'Kg2 Bh6',
    'Rc5 Bf8',
    'Rxc8 Rxc8',
    'Rc1 Rxc1',
    'Bxc1 Nc3',
    'Qf3 Qxf3+',
    'Kxf3 Nb5',
    'Nc6 a6',
    'Ke4 Bd6',
    'Bd2 Kf8',
    'Ba5 Bc7',
    'Bb4+ Ke8',
    'a4 Nd6+',
    'Kd3 Nb7',
    'Ke4 Nd8',
    'Ne5 Nd7',
    'Bc4 f5+',
    'Ke3 Nxe5',
    'dxe5 Bb6+',
    'Ke2 Nc6',
    'Bc3 a5',
    'Bb5 Kd7',
    'f4 Kc7',
    'Bc4 Kd7',
    'Be1 Nd4+',
    'Kd3 Nc6',
    'Bb3 Ne7',
    'Kc4 Kc6',
    'Bd1 Nd5',
    'Bf3 Bd8',
    'Bc3 Bb6',
    'Bd4 Bd8',
    'Bc5 Bc7',
    'Kd4 Bd8',
  ],
  [
    'c4 c5',
    'Nc3 g6',
    'g3 Bg7',
    'Bg2 Nc6',
    'a3 Nf6',
    'Rb1 a5',
    'd3 O-O',
    'Nf3 d6',
    'Bg5 h6',
    'Bd2 Rb8',
    'O-O Bd7',
    'h3 Ne8',
    'Nb5 Nc7',
    'Bc3 Nxb5',
    'Bxg7 Kxg7',
    'cxb5 Ne5',
    'Nxe5 dxe5',
    'Qa4 Qb6',
    'b4 axb4',
    'axb4 Rfc8',
    'Rfc1 cxb4',
    'Qxb4 Rxc1+',
    'Rxc1 Qxb5',
    'Qxe7 Rc8',
    'Rxc8 Bxc8',
    'e3 Be6',
    'd4 exd4',
    'exd4 Qb1+',
    'Kh2 Qf5',
    'Qxb7 Qxf2',
    'd5 Bf5',
    'Qe7 Qd4',
    'd6 h5',
    'h4 Be6',
    'Qc7 Kf6',
    'Qd8+ Kg7',
    'Qc7 Kf6',
    'Qd8+ Kg7',
  ],
  [
    'c4 e5',
    'Nc3 Nf6',
    'Nf3 Nc6',
    'a3 g6',
    'g3 Bg7',
    'd3 d6',
    'Bg2 O-O',
    'Bg5 h6',
    'Bxf6 Bxf6',
    'O-O Ne7',
    'Nd2 c6',
    'Rc1 Bg7',
    'b4 a6',
    'a4 a5',
    'b5 Be6',
    'Nb3 f5',
    'e3 Kh8',
    'Qe2 Bg8',
    'd4 exd4',
    'Nxd4 Qb6',
    'Rfd1 Qc5',
    'Qd2 g5',
    'Nce2 Rfd8',
    'Rb1 Ra7',
    'Rdc1 Rc8',
    'Qd3 Qe5',
    'Qd2 Bf7',
    'Nb3 Rca8',
    'Ned4 Be8',
    'b6 Ra6',
    'c5 Rb8',
    'Nf3 Qf6',
    'cxd6 Nd5',
    'd7 Bf7',
    'Nc5 Rxb6',
    'Rxb6 Nxb6',
    'Nxb7 Nxd7',
    'Qxd7 Qb2',
    'Qxf7 Qxc1+',
    'Bf1 Qd1',
    'Qc7 Rxb7',
    'Qc8+ Kh7',
    'Qxf5+ Kg8',
    'Qc8+ Bf8',
    'Qxb7 Qxf3',
    'Qb3+ Kh7',
    'Bd3+ Kg7',
  ],
  [
    'd4 d5',
    'Nf3 Nf6',
    'c4 c6',
    'Nc3 Qb6',
    'e3 Bf5',
    'Ne5 Nbd7',
    'Nxd7 Bxd7',
    'Bd3 e6',
    'O-O Be7',
    'c5 Qc7',
    'b4 b6',
    'Bd2 O-O',
    'f4 Rfb8',
    'Qc2 g6',
    'Kh1 Qc8',
    'Ne2 Qf8',
    'a4 bxc5',
    'bxc5 Bd8',
    'Ng1 Ng4',
    'Nf3 f5',
    'a5 Bc7',
    'a6 Qc8',
    'h3 Nh6',
    'Rg1 Nf7',
    'g4 Kh8',
    'g5 Qf8',
    'h4 h5',
    'Bc3 Kg8',
    'Rgc1 Nd8',
    'Kg2 Be8',
    'Nd2 Qe7',
    'Nb3 Bd7',
    'Rab1 Bc8',
    'Rb2 Kf8',
    'Rcb1 Kf7',
    'Qd2 Kf8',
    'Bb4 Kf7',
    'Qc3 Ke8',
    'Nd2 Nf7',
    'Qa3 Kf8',
    'Nf3 Ke8',
    'Qa4 Qd7',
    'Kf2 Kf8',
    'Qa3 Kg8',
    'Qc3 Qd8',
    'Rb3 Kf8',
    'Ke2 Kg8',
    'Kd1 Kf8',
    'Kc2 Qe7',
    'Kb2 Qd8',
    'Ka2 Qe7',
    'R3b2 Qd8',
    'Qa3 Kg8',
    'Qa4 Qd7',
    'Nd2 Kg7',
    'Nb3 Nd8',
    'Na5 Kf7',
    'Be2 Kg8',
    'Bd3 Kf7',
    'Bc3 Rxb2+',
    'Rxb2 Rb8',
    'Rxb8 Bxb8',
    'Nb3 Bc7',
    'Nd2 Kf8',
    'Nf1 Qf7',
    'Ka3 Qe7',
    'Ng3 Bd7',
    'Be2 Be8',
    'Nf1 Bd7',
    'Ba5 Be8',
    'Nd2 Nf7',
    'Nb3 Qd8',
    'Qb4 Bxa5',
    'Nxa5 Qc7',
    'Bd1 Nd8',
    'Ba4 Ke7',
    'Nb7 Bd7',
    'Nd6 Bc8',
    'Bd1 Bxa6',
    'Qa4 Bf1',
    'Qb4 Nf7',
    'Qb7 Qxb7',
    'Nxb7 Ba6',
    'Na5 Kd7',
    'Kb4 Nd8',
    'Ba4 Be2',
    'Kc3 Bb5',
    'Bb3 Be2',
    'Ba4 Kc7',
    'Kb4 a6',
    'Kc3 Bb5',
    'Bd1 Kd7',
    'Bc2 Ke7',
  ],
  [
    'Nf3 d5',
    'd4 e6',
    'c4 dxc4',
    'e3 c5',
    'Bxc4 a6',
    'O-O Nf6',
    'dxc5 Qxd1',
    'Rxd1 Bxc5',
    'b3 Nbd7',
    'Bb2 b6',
    'Nbd2 Bb7',
    'Be2 O-O',
    'Ne1 Rfd8',
    'Nd3 Be7',
    'Nc4 Rac8',
    'Rac1 Kf8',
    'f3 Rc6',
    'Rd2 Rc7',
    'Rcd1 b5',
    'Na5 Ba8',
    'Kf2 Rdc8',
    'a3 Nd5',
    'Nb4 Nxb4',
    'Rxd7 Nc6',
    'Nxc6 Bxc6',
    'Rxc7 Rxc7',
    'Rc1 Bd6',
    'e4 f6',
    'b4 e5',
    'Rc3 Bd7',
    'g3 Ke7',
    'Ke3 Rxc3+',
    'Bxc3 Bc7',
    'Bf1 Bb6+',
    'Ke2 Kd6',
    'g4 g6',
    'h3 f5',
    'Kd1 fxe4',
    'fxe4 Be6',
    'Kc2 Be3',
    'Bd2 Bf4',
    'Be1 Be3',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 e6',
    'Bg5 h6',
    'Bh4 dxc4',
    'e4 g5',
    'Bg3 b5',
    'Be2 Bb7',
    'h4 g4',
    'Ne5 Nbd7',
    'Nxg4 Rg8',
    'O-O b4',
    'Na4 Nxe4',
    'Bxc4 Qa5',
    'Rc1 O-O-O',
    'Bh2 Bd6',
    'Bd3 Bxh2+',
    'Nxh2 Ndf6',
    'Bxe4 Nxe4',
    'Nc5 Nxc5',
    'Rxc5 Qxa2',
    'b3 Qb2',
    'Nf3 Rg4',
    'Rc2 Rgxd4',
    'Nxd4 Qxd4',
    'Qh5 Qd3',
    'Rc4 Qxb3',
    'Rfc1 Kb8',
    'Qxh6 Qb2',
    'Qf4+ e5',
    'Rxb4 exf4',
    'Rxb2 Kc7',
    'Rc4 f3',
    'Rf4 Rd1+',
    'Kh2 fxg2',
    'Rxf7+ Kd6',
    'Kxg2 Ba6',
    'Rxa7 Bc4',
    'f3 Ke5',
    'h5 Rd8',
    'h6 Bd3',
    'h7 Rh8',
    'Rbb7 c5',
    'Kf2 Kd4',
    'Rd7+ Kc4',
    'Ke3 Bf5',
    'Rf7 Bb1',
    'Kf4 Kd5',
    'Kg5 c4',
    'f4 Be4',
    'Rfd7+ Kc5',
    'f5 c3',
  ],
  ['d4 Nf6', 'Nf3 e6', 'c4 b6', 'g3 Ba6', 'b3 Bb4+', 'Bd2 Be7', 'Bg2 c6', 'Bc3 d5', 'Ne5 Nfd7', 'Nxd7 Nxd7', 'Nd2 O-O', 'O-O Rc8', 'e4 b5', 'Re1 dxe4', 'Nxe4 bxc4', 'Bf1 Nb6'],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Bc4 Nf6',
    'e5 d5',
    'Bb5 Ne4',
    'Nxd4 Bd7',
    'Bxc6 bxc6',
    'O-O Bc5',
    'f3 Ng5',
    'f4 Ne4',
    'Be3 Qb8',
    'Qc1 Bxd4',
    'Bxd4 c5',
    'Bf2 Bb5',
    'Rd1 Nxf2',
    'Kxf2 Bc6',
    'Nd2 Qb6',
    'c4 d4',
    'Qc2 O-O',
    'f5 Rad8',
    'Rf1 Ba8',
    'Kg1 d3',
    'Qc3 Rd4',
    'Rf2 Re8',
    'Re1 Qh6',
    'Qa3 Qg5',
    'g3 h5',
    'Qxc5 h4',
    'Qxd4 hxg3',
    'Kf1 gxf2',
    'Qxf2 Qg4',
    'f6 gxf6',
    'Re3 Qd1+',
    'Qe1 Qxe1+',
    'Kxe1 fxe5',
    'Rxd3 f5',
    'Rd7 e4',
    'Nb3 f4',
    'Nd4 e3',
    'Nf5 Kh8',
    'Nh4 Kg8',
    'Ng6 f3',
    'Re7 Rxe7',
    'Nxe7+ Kf7',
    'Nf5 e2',
    'Nd4 Kf6',
    'Nb5 Ke5',
    'Nxc7 Be4',
    'Nb5 Bd3',
    'b3 Ke4',
    'Nc7 Bxc4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be2 e5',
    'Nb3 Be7',
    'Bg5 Nbd7',
    'a4 b6',
    'Bc4 Bb7',
    'Qe2 O-O',
    'O-O Qc7',
    'Rfd1 Rfc8',
    'Nd2 h6',
    'Bxf6 Nxf6',
    'Bb3 Bc6',
    'Nf1 Qb7',
    'Nd5 b5',
    'axb5 Bxb5',
    'Qf3 Bxf1',
    'Kxf1 a5',
    'Qe2 Ne8',
    'Rd3 Bd8',
    'Rf3 a4',
    'Rxa4 Rxa4',
    'Bxa4 Qxb2',
    'Qd1 Nf6',
    'Bb3 Qd4',
    'Rd3 Qxe4',
    'Nxf6+ Bxf6',
    'Rxd6 Be7',
    'Rd7 Kf8',
    'Rd3 Bf6',
    'Rd7 Be7',
    'Qh5 Qf4',
    'g3 Qf6',
    'Rd3 Rc7',
    'Kg1 e4',
    'Rd5 e3',
    'fxe3 Qb6',
    'Kf2 Bg5',
    'Qf3 g6',
    'h4 Bf6',
    'h5 g5',
    'Kg2 Kg7',
    'Rd3 Re7',
    'e4 Be5',
    'Qf5 Qf6',
    'Qc8 Rc7',
    'Qe8 Qe7',
    'Qb5 Bf6',
    'Qf5 Qe5',
    'Rd5 Qxf5',
    'Rxf5 Bc3',
    'Kf3 Kf8',
    'Kg4 Kg7',
    'Kf3 Kf8',
    'Ke2 Re7',
    'Kd3 Be5',
    'g4 f6',
    'Rf1 Rb7',
    'Bd5 Rb8',
    'c3 Rc8',
    'c4 Rb8',
    'c5 Ke7',
    'Kc4 Rb2',
    'Rf3 Rb1',
    'Rd3 Rg1',
    'Bc6 Rc1+',
    'Kb5 Rb1+',
    'Ka6 Rc1',
    'Rd7+ Ke6',
    'Rd5 Ra1+',
    'Kb6 Rb1+',
    'Bb5 Bg3',
    'Kc6 Rb4',
    'Rd8 Ke7',
    'Rd7+ Ke6',
    'Ba6 Ke5',
    'Re7+ Kf4',
    'Bc8 Bf2',
    'Rf7 Rxe4',
    'Rxf6+ Kg3',
    'Rxf2 Kxf2',
    'Bf5 Rc4',
    'Kd5 Rc1',
    'c6 Ke3',
    'Kd6 Rd1+',
    'Kc5 Rc1+',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'Bb5 a6',
    'Ba4 Nf6',
    'd3 b5',
    'Bb3 Bc5',
    'a4 Rb8',
    'axb5 axb5',
    'Nc3 h6',
    'Nd5 d6',
    'c3 Bg4',
    'h3 Bh5',
    'Be3 Nxd5',
    'Bxd5 Ne7',
    'Bb3 Bb6',
    'd4 exd4',
    'cxd4 c5',
    'dxc5 Bxc5',
    'O-O O-O',
    'e5 Rc8',
    'e6 Qb6',
    'exf7+ Bxf7',
    'Re1 Ng6',
    'Bxf7+ Rxf7',
    'Qd5 Qb7',
    'Qxb7 Rxb7',
    'Ra6 Ne5',
    'Nxe5 dxe5',
    'Rc1 Rbc7',
    'Bxc5 Rxc5',
    'Rxc5 Rxc5',
    'Rb6 Rc1+',
    'Kh2 Rc2',
    'Rxb5 Rxf2',
    'Kg3 Rc2',
    'Rxe5 Rxb2',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 O-O',
    'a3 Bxc3+',
    'bxc3 c5',
    'Bd3 Nc6',
    'Ne2 b6',
    'e4 Ne8',
    'O-O Ba6',
    'f4 f5',
    'exf5 exf5',
    'dxc5 bxc5',
    'Ng3 g6',
    'Be3 d6',
    'Bxf5 gxf5',
    'Qd5+ Rf7',
    'Qxc6 Bb7',
    'Qa4 Qf6',
    'Rab1 Ng7',
    'Qd1 Re8',
    'Re1 Qg6',
    'Bf2 Rxe1+',
    'Qxe1 h5',
    'h4 Rd7',
    'Qd1 Kh7',
    'Qa4 Qf7',
    'Rd1 Ne6',
    'Be3 Qg7',
    'Kf2 Kg6',
    'Rd2 Qe7',
    'Qd1 Qxh4',
    'Rxd6 Re7',
    'Rd7 Qh2',
    'Rxe7 Qxg2+',
    'Ke1 Qxg3+',
    'Kd2 Kf6',
    'Rh7 Nxf4',
    'Qf1 Ng2',
    'Bxc5 Be4',
    'Bd4+ Ke6',
    'c5 Qg5+',
    'Kd1 Qg4+',
    'Kc1 Qg5+',
    'Kd1 Qg4+',
    'Qe2 Nf4',
    'Rh6+ Kd7',
    'Qxg4 hxg4',
    'Rh7+ Kc6',
    'Rxa7 g3',
    'Rg7 g2',
    'Kd2 Ne6',
    'Rg8 f4',
    'Bf2 f3',
    'a4 Nf4',
    'Ke3 Bd5',
    'Rg4 Ne2',
    'c4 Bf7',
    'a5 Nc3',
    'Rf4 Bh5',
    'Kd2 Nd1',
    'Rf6+ Kc7',
    'Ke1 Nb2',
    'Bg3+ Kd7',
    'Kf2 Nd1+',
    'Kg1 Nc3',
    'Bf2 Ne4',
    'c6+ Kd8',
    'Re6 Nxf2',
    'Kxf2 Bf7',
    'a6 Bxe6',
  ],
  [
    'Nf3 Nf6',
    'g3 d5',
    'Bg2 c5',
    'O-O Nc6',
    'd3 Bg4',
    'c4 d4',
    'e3 e6',
    'a3 a5',
    'h3 Bh5',
    'exd4 cxd4',
    'Qa4 Nd7',
    'Qb5 Bg6',
    'Qxb7 Qc8',
    'Qxc8+ Rxc8',
    'Rd1 Nc5',
    'Bf1 h6',
    'Nbd2 a4',
    'Ne1 e5',
    'Be2 Bd6',
    'Rb1 Na5',
    'f3 Be7',
    'Ne4 Ncb3',
    'g4 O-O',
    'Nc2 f5',
    'gxf5 Bxf5',
    'Nb4 Bxh3',
    'Nd5 Bd8',
    'Bd2 Nxd2',
    'Rxd2 Nb3',
    'Rc2 Rc6',
    'Kh2 Bf5',
    'Rg1 Nc5',
    'Nb4 Bxe4',
    'dxe4 Rb6',
    'Bd1 Bg5',
    'Rcg2 Kh7',
    'Be2 Rfb8',
    'Rd1 Rxb4',
    'axb4 Rxb4',
    'Bf1 Rb3',
    'Rf2 Bh4',
    'Rc2 Rxf3',
    'Bh3 Bg5',
    'Kg2 Rb3',
    'Rf2 Bf4',
    'Bc8 g6',
    'Kf1 Kg7',
    'Rg2 Kf6',
    'Bg4 h5',
    'Bc8 Rb4',
    'Rc2 g5',
    'Bf5 g4',
    'Ra1 d3',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 Bb4+',
    'Nc3 b6',
    'Bg5 h6',
    'Bh4 Bb7',
    'e3 Bxc3+',
    'bxc3 d6',
    'Nd2 Nbd7',
    'f3 Qe7',
    'e4 g5',
    'Bf2 O-O-O',
    'Qa4 Kb8',
    'Rb1 c5',
    'Be2 Rhg8',
    'g4 h5',
    'h3 Rh8',
    'Rg1 hxg4',
    'hxg4 Nh7',
    'Nb3 e5',
    'd5 Nhf8',
    'Kd2 Ng6',
    'Rh1 f6',
    'Na1 Rxh1',
    'Rxh1 Rh8',
    'Rxh8+ Nxh8',
    'Nc2 Bc8',
    'Ne3 Nf8',
    'Nf5 Bxf5',
    'gxf5 Nf7',
    'Qd1 Kc7',
    'Qh1 Kd8',
    'Bd1 Qb7',
    'Bb3 Ke7',
    'Qd1 Qa6',
    'Kd3 Nd7',
    'Ba4 Qc8',
    'Qb3 Nb8',
    'Be3 Qh8',
    'Qb5 Kd8',
    'Bd1 Kc7',
    'Qa4 a6',
    'Qc2 Qh3',
    'Be2 Qh1',
    'Kd2 Nd8',
    'Qd1 Qxd1+',
    'Kxd1 Nf7',
    'Ke1 Kd8',
    'Bd1 Ke7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'e5 Nfd7',
    'f4 c5',
    'Nf3 Nc6',
    'Be3 cxd4',
    'Nxd4 Bc5',
    'Qd2 O-O',
    'g3 Nxd4',
    'Bxd4 Bxd4',
    'Qxd4 Nb8',
    'O-O-O Nc6',
    'Qc5 Bd7',
    'Kb1 Rc8',
    'Qd6 Na5',
    'Bd3 Rc6',
    'Qa3 a6',
    'Ne2 b5',
    'Nd4 Rc8',
    'Rhe1 Re8',
    'Nf3 Qb6',
    'Qb4 Nb7',
    'g4 Nc5',
    'Qd2 b4',
    'f5 Nxd3',
    'f6 Qc5',
    'cxd3 Qf8',
    'Nd4 a5',
    'Rc1 h6',
    'g5 g6',
    'h4 h5',
    'Rxc8 Rxc8',
    'Rc1 Ba4',
    'Qe3 Qd8',
    'b3 Bd7',
    'Rxc8 Qxc8',
    'Nc2 Bb5',
    'Kc1 Qc3',
    'Qd4 Qc7',
    'Kd2 Bd7',
    'Kc1 Kf8',
    'Kb2 Ke8',
    'a3 bxa3+',
    'Nxa3 Kd8',
    'Nc2 Kc8',
    'Qc3 Kb7',
    'Qxc7+ Kxc7',
    'Kc3 Kb6',
    'b4 a4',
    'Nd4 Ka6',
    'Kb2 Kb6',
    'Ka3 Be8',
    'Ne2 Bb5',
    'Nc3 d4',
    'Ne2 Bxd3',
    'Nxd4 Bc4',
    'Kxa4 Bd5',
    'Ne2 Bc6+',
    'Kb3 Bb5',
    'Nf4 Be8',
    'Kc4 Bb5+',
    'Kd4 Be8',
    'Ne2 Bc6',
    'Nc3 Bg2',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 g6',
    'a3 Bg7',
    'Rb1 Nf6',
    'b4 d6',
    'bxc5 dxc5',
    'Bb5 Bd7',
    'O-O O-O',
    'Re1 Ne8',
    'Nd5 Nd6',
    'Bf1 e6',
    'Ne3 Nd4',
    'd3 Ba4',
    'Bb2 Qa5',
    'Nd2 Rfd8',
    'Qc1 Rac8',
    'c3 b5',
    'Ba1 Nc6',
    'f4 Rd7',
    'e5 Nf5',
    'Ne4 Qb6',
    'Ng4 Kh8',
    'Kh1 Rcd8',
    'Qb2 Rxd3',
    'Bxd3 Rxd3',
    'Ngf2 Rd7',
    'Qe2 c4',
    'Qf3 Bc2',
    'Rb2 Bxe4',
    'Qxe4 Qc5',
    'Qb1 a6',
    'Ne4 Qa7',
    'a4 h6',
    'axb5 axb5',
    'Rxb5 g5',
    'fxg5 Bxe5',
    'Rc5 Qc7',
    'Qb5 Nfe7',
    'h3 Bg3',
    'Rf1 hxg5',
    'Rxg5 Be5',
    'Qxc4 Ng6',
    'Qe2 Bf4',
  ],
  [
    'e4 c5',
    'Ne2 d6',
    'g3 h5',
    'h3 e5',
    'Bg2 Nc6',
    'c3 g6',
    'd4 Bg7',
    'Be3 b6',
    'Na3 Nge7',
    'dxc5 dxc5',
    'Qxd8+ Nxd8',
    'Nb5 O-O',
    'Nc7 Rb8',
    'O-O-O Bb7',
    'g4 Rc8',
    'Rd7 Bc6',
    'Rxe7 Bf6',
    'Nd5 Bxd5',
    'Rxa7 Bc4',
    'Ng3 Nc6',
    'Rb7 h4',
    'Nf1 Bd8',
    'Nd2 Be6',
    'Bf1 Ra8',
    'Bb5 Na5',
    'Rd7 Bxd7',
    'Bxd7 Kg7',
    'a4 Be7',
    'Kc2 Rab8',
    'Bb5 Rfc8',
    'Nc4 Nxc4',
    'Bxc4 Ra8',
    'b3 Rd8',
    'Rg1 f6',
    'f3 g5',
    'Bd5 Ra5',
    'c4 Kf8',
    'Bd2 Ra7',
    'Be3 Ra5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'e5 Nfd7',
    'f4 c5',
    'Nf3 Nc6',
    'Be3 Rb8',
    'Qd2 Qa5',
    'dxc5 Bxc5',
    'Bxc5 Qxc5',
    'Nb5 O-O',
    'O-O-O Qb6',
    'Kb1 Nc5',
    'Qe3 Bd7',
    'Nd6 f6',
    'Bd3 fxe5',
    'fxe5 h6',
    'Rhe1 Be8',
    'Nxe8 Rbxe8',
    'Bf1 Rf7',
    'c3 Ref8',
    'Bd3 Na4',
    'Qxb6 Nxb6',
    'b3 a6',
    'Kc1 Rf4',
    'Re3 Nd7',
    'Rd2 g5',
    'h3 Kg7',
    'Kd1 b5',
    'Be2 Nc5',
    'Ke1 Rc8',
    'Nd4 Kf7',
    'Bf3 Nxd4',
    'cxd4 Ne4',
    'Bxe4 dxe4',
    'Ke2 Rc1',
    'Rd1 Rc2+',
    'Rd2 Rc1',
    'Rd1 Rc8',
    'Rd2 Rc1',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 e6',
    'Bg5 dxc4',
    'e4 b5',
    'e5 h6',
    'Bh4 g5',
    'Nxg5 hxg5',
    'Bxg5 Nbd7',
    'g3 Qa5',
    'exf6 Ba6',
    'a3 O-O-O',
    'Bg2 Nc5',
    'O-O Nb3',
    'Qf3 Bb7',
    'Rad1 b4',
    'Ne4 bxa3',
    'bxa3 Rd5',
    'Be3 Bxa3',
    'h4 c3',
    'Qe2 Bb2',
    'Qc2 Na1',
    'Rxa1 Bxa1',
    'Nc5 Rxc5',
    'dxc5 Bb2',
    'Rd1 Rd8',
    'Be4 Rxd1+',
    'Qxd1 c2',
    'Bxc2 Bxf6',
    'Qh5 Qc7',
    'Qh7 Ba6',
    'Qg8+ Kb7',
    'h5 Bc4',
    'h6 Bd5',
    'h7 Qe5',
    'Qxf7+ Kc8',
    'Qe8+ Kb7',
    'Qd7+ Qc7',
    'Qxc7+ Kxc7',
    'g4 Kd7',
    'g5 Bh8',
    'g6 Ke7',
  ],
  [
    'e4 g6',
    'd4 d6',
    'Nf3 Bg7',
    'Be2 e6',
    'O-O Ne7',
    'c4 O-O',
    'Nc3 Nd7',
    'Be3 e5',
    'Qd2 f5',
    'Bh6 fxe4',
    'Nxe4 Bxh6',
    'Qxh6 Rf4',
    'dxe5 dxe5',
    'Rad1 Qf8',
    'Qxf8+ Nxf8',
    'Nc3 Bg4',
    'Rfe1 Nc6',
    'Nd5 Rf7',
    'Ng5 Bxe2',
    'Rxe2 Rf5',
    'h4 h5',
    'Nxc7 Rc8',
    'Nd5 Kg7',
    'b3 Nh7',
    'Ne3 Nf6',
    'Nxf5+ gxf5',
    'Rd6 a6',
    'Red2 b5',
    'cxb5 Nd4',
    'f3 Nxb5',
    'Rxa6 e4',
    'Rb6 Nc3',
    'Rdd6 Ncd5',
    'Rbc6 Re8',
    'Re6 Rf8',
    'fxe4 fxe4',
    'Nxe4 Ng4',
    'Rg6+ Kh8',
    'Rc5 Nf4',
    'Rxh5+ Nxh5',
    'Rxg4 Ra8',
    'a4 Rb8',
  ],
  [
    'Nf3 d5',
    'c4 c6',
    'e3 e6',
    'Qc2 Bd6',
    'Nc3 f5',
    'h3 Nf6',
    'g4 O-O',
    'gxf5 exf5',
    'b3 Qe7',
    'Bb2 Na6',
    'Rg1 Bd7',
    'cxd5 Nb4',
    'Qb1 cxd5',
    'a3 Nc6',
    'Nxd5 Nxd5',
    'Bc4 Be6',
    'Rxg7+ Qxg7',
    'Bxg7 Kxg7',
    'Qb2+ Rf6',
    'O-O-O h6',
    'd3 Kf7',
    'e4 fxe4',
    'dxe4 Rxf3',
    'exd5 Bf4+',
    'Kb1 Bf5+',
    'Ka2 Ne5',
    'd6+ Kg6',
    'Be2 Rxh3',
    'Qd4 Rf8',
    'Bb5 Be6',
    'Qe4+ Kh5',
    'Be2+ Kh4',
    'd7 Bxd7',
    'Rxd7 Nxd7',
    'Qe7+ Bg5',
    'Qxd7 Rf4',
    'f3 Rh2',
    'Qd3 Kg3',
    'b4 Rf2',
    'Kb1 Kg2',
    'Bd1 Bh4',
    'Qg6+ Bg3',
    'Bc2 h5',
    'Qxh5 Rc4',
    'Be4 Rd4',
    'f4+ Rxe4',
    'Qd5 Re2',
    'Qxb7 Kg1',
  ],
  [
    'e4 c5',
    'Nf3 g6',
    'd4 cxd4',
    'Nxd4 Bg7',
    'c4 Nc6',
    'Be3 Nf6',
    'Nc3 Ng4',
    'Qxg4 Nxd4',
    'Qd1 Ne6',
    'Qd2 Qa5',
    'Rc1 g5',
    'Be2 b6',
    'Qd5 Rb8',
    'Qxa5 bxa5',
    'b3 Bb7',
    'f3 Ra8',
    'h4 h6',
    'Nd5 Rg8',
    'hxg5 hxg5',
    'Kf2 d6',
    'g3 Kf8',
    'Rcd1 Bb2',
    'Bf1 Bc6',
    'Bh3 Bd7',
    'Nc7 Nxc7',
    'Bxd7 Na6',
    'Bc6 Rd8',
    'Bb5 Nc7',
    'Bc6 Ba3',
    'Bxa7 Ne6',
    'Rd5 Nc5',
    'Bb6 Rc8',
    'Bb5 Bb4',
    'Ke3 Rb8',
    'Bxc5 Bxc5+',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nc3 c5',
    'cxd5 cxd4',
    'Qa4+ b5',
    'Nxb5 Bd7',
    'd6 Bxb5',
    'Qxb5+ Nd7',
    'Nf3 Bxd6',
    'g3 Nf6',
    'Bg2 Rb8',
    'Qa4 e5',
    'O-O O-O',
    'Qxa7 Ra8',
    'Qb7 Nc5',
    'Qb5 Qc7',
    'Nd2 Rfb8',
    'Qc4 Ra4',
    'Qc2 Qe7',
    'b3 Ra6',
    'Bb2 Qa7',
    'a3 h5',
    'Rac1 h4',
    'b4 Na4',
    'Ba1 Nb6',
    'Bb2 Na4',
    'Ba1 Qd7',
    'Ne4 Nxe4',
    'Qxe4 h3',
    'Bf3 Nb6',
    'Bg4 Qe7',
    'Bb2 Na4',
    'Ba1 Nb6',
    'e3 Rxa3',
    'exd4 Ra4',
    'dxe5 Bxb4',
    'e6 Bc3',
    'exf7+ Qxf7',
    'Qe6 Bxa1',
    'Rxa1 Rxa1',
    'Rxa1 Nd5',
    'Bxh3 Nf6',
    'Ra7 Qxe6',
    'Bxe6+ Kh8',
    'h4 Rb6',
    'Bf5 Rb8',
    'Kg2 Rf8',
    'Bg6 Ng4',
    'f4 Kg8',
    'h5 Kh8',
    'Kh3 Nf6',
    'Kh4 Rd8',
    'g4 Rf8',
    'Kg5 Ng8',
    'Bf5 Nh6',
    'Be6 Kh7',
    'f5 Kh8',
    'Kf4 Re8',
    'g5 Ng8',
    'Ke5 Ne7',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Nf6',
    'O-O Qc7',
    'Qe2 d6',
    'c4 Be7',
    'b3 O-O',
    'Bb2 Re8',
    'Nd2 Nc6',
    'Nxc6 Qxc6',
    'f4 Qc5+',
    'Kh1 Qh5',
    'Nf3 Bd7',
    'Rae1 b5',
    'e5 bxc4',
    'bxc4 dxe5',
    'fxe5 Ng4',
    'h3 Nh6',
    'Qc2 Nf5',
    'Kh2 Nh4',
    'Nxh4 Bxh4',
    'Re4 Bg5',
    'Rg4 Rab8',
    'Bc3 g6',
    'Qf2 Rf8',
    'Qa7 Ra8',
    'Qxd7 Rad8',
    'Qb7 Rxd3',
    'Bb4 Bh6',
    'Bxf8 Qxe5+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Qb6',
    'a3 Nh6',
    'b4 cxd4',
    'cxd4 Nf5',
    'Bb2 Bd7',
    'g4 Nfe7',
    'Nc3 Na5',
    'Nd2 Rc8',
    'Qc2 Qxd4',
    'Nb5 Qb6',
    'bxa5 Qxb5',
    'Qxc8+ Nxc8',
    'Bxb5 Bxb5',
    'Rc1 Ne7',
    'Nb3 Bc4',
    'Nd2 Ba6',
    'f4 Ng6',
    'Bd4 Nxf4',
    'Rc7 Be7',
    'Nb3 Bd8',
    'Rc2 h5',
    'Kf2 Nd3+',
    'Kg2 hxg4',
    'Nc5 Nf4+',
    'Kf2 Rh3',
    'Nxa6 Bh4+',
    'Kg1 bxa6',
    'Bf2 Rxa3',
    'Bxh4 Ra1+',
    'Kf2 Rxh1',
    'Bg3 Rc1',
    'Ra2 g5',
    'Ra3 Kf8',
    'Ke3 Rc4',
    'Rb3 Re4+',
    'Kd2 Rxe5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Nc3 Qc7',
    'Bd3 Bd6',
    'Qd2 Be7',
    'f4 d6',
    'O-O Nf6',
    'Kh1 b5',
    'a4 b4',
    'Nce2 Bb7',
    'Qe3 Nbd7',
    'Nb3 g6',
    'Bd2 Nc5',
    'Nxc5 Qxc5',
    'Qf3 d5',
    'e5 Ne4',
    'Be3 Qc7',
    'a5 Bc5',
    'Rfc1 Bxe3',
    'Qxe3 O-O',
    'Qd4 Qc5',
    'Ra4 Rac8',
    'Qxc5 Rxc5',
    'Rxb4 Ba8',
    'Ra1 Nf2+',
    'Kg1 Nxd3',
    'cxd3 d4',
    'Nxd4 g5',
    'g3 Rfc8',
    'Kf2 Kg7',
    'Rb6 gxf4',
    'gxf4 Rc1',
    'Rxc1 Rxc1',
    'Rxa6 Bd5',
    'f5 Rh1',
    'fxe6 fxe6',
    'Nxe6+ Bxe6',
    'Rxe6 Rxh2+',
    'Kg3 Rxb2',
    'a6 Rb3',
    'Rd6 Kf7',
    'Kg4 Ke7',
    'a7 Ra3',
    'Rh6 Ra1',
    'd4 Ra4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'e5 h6',
    'Bd2 Bxc3',
    'bxc3 Ne4',
    'Qg4 g6',
    'Bd3 Nxd2',
    'Kxd2 c5',
    'Nf3 c4',
    'Be2 Nd7',
    'h4 Qe7',
    'a3 b6',
    'Kc1 Bb7',
    'Nd2 O-O-O',
    'f4 f5',
    'exf6 Nxf6',
    'Qh3 h5',
    'Qe3 Rhf8',
    'g3 Bc6',
    'Re1 b5',
    'Bf1 Rd6',
    'Nf3 Bd7',
    'Ne5 Rg8',
    'Bg2 Ra6',
    'Kb2 Ne8',
    'Nf3 Nc7',
    'Ng5 Ra4',
    'Bh3 Re8',
    'Qe5 a5',
    'Qe3 Kb7',
    'Nf3 Ra8',
    'Reb1 Kc8',
    'Qe1 Rb8',
    'Ne5 Be8',
    'Nf3 Rb6',
    'Ne5 b4',
    'axb4 axb4',
    'Rxa4 Bxa4',
    'Kc1 b3',
    'cxb3 Qa3+',
    'Kd2 Qa2+',
    'Ke3 Rxb3',
    'Rc1 Qh2',
    'Bf1 Nb5',
    'Nxc4 dxc4',
    'Bxc4 Rb2',
    'Bxe6+ Kd8',
    'f5 gxf5',
    'Bxf5 Nd6',
    'Bd3 Rg2',
    'd5 Rxg3+',
    'Kd4 Qxh4+',
    'Kc5 Qf4',
    'Qe6 Nb7+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 Nc6',
    'Bc4 Qb6',
    'Bb5 Qc7',
    'Nd5 Nxd5',
    'exd5 a6',
    'Nxc6 Bxb2',
    'Ba4 O-O',
    'Rb1 Bc3+',
    'Kf2 bxc6',
    'Bb6 Qb8',
    'Bxc6 Ra7',
    'Qd3 Bf6',
    'Qe3 Rb7',
    'Bxb7 Qxb7',
    'c4 Bf5',
    'Rb3 Qd7',
    'Bd4 Qa4',
    'Bxf6 exf6',
    'Rc1 Qxa2+',
    'Kg3 h5',
    'h4 Kg7',
    'Rb6 Rd8',
    'Qd4 Qe2',
    'c5 Qe7',
    'c6 a5',
    'Qc3 Re8',
    'c7 a4',
    'Rb8 Bc8',
    'Ra8 Qd7',
    'Re1 Re5',
    'Rxe5 fxe5',
    'Qc4 f5',
    'Qxa4 f4+',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 c5',
    'd5 exd5',
    'cxd5 d6',
    'Nc3 g6',
    'h3 Bg7',
    'e4 O-O',
    'Bd3 a6',
    'a4 Nbd7',
    'O-O Re8',
    'Re1 Qc7',
    'Bf4 b6',
    'Qd2 Bb7',
    'Bc4 Nh5',
    'Bh2 h6',
    'g4 Nhf6',
    'Rad1 h5',
    'g5 Nh7',
    'Bg3 Re7',
    'Re2 Rae8',
    'Rde1 Bc8',
    'Kh2 b5',
    'e5 h4',
    'Bf4 Nxe5',
    'Bxe5 Qd7',
    'Bxg7 Qxh3+',
    'Kg1 Qg4+',
    'Kh2 Qh3+',
    'Kg1 Bg4',
    'Nh2 Bxe2',
    'Bxe2 Kxg7',
    'axb5 Re5',
    'f4 Re3',
    'Kh1 axb5',
    'Nxb5 Rg3',
    'Bf1 Rxe1',
    'Qxe1 Qf5',
    'Qd2 Qe4+',
    'Bg2 Qb1+',
    'Bf1 Qe4+',
    'Bg2 Qe7',
    'Nc3 Re3',
    'Ng4 Re1+',
    'Kh2 f6',
    'gxf6+ Nxf6',
    'Nxf6 Kxf6',
    'b4 cxb4',
    'Qd4+ Kf7',
    'Ne4 b3',
    'Qb4 Re3',
    'Ng5+ Kg8',
    'Ne6 Rg3',
    'Qb8+ Kh7',
    'Ng5+ Kg7',
    'Ne6+ Kh7',
    'Ng5+ Rxg5',
    'fxg5 Qe5+',
    'Kh1 Qe1+',
    'Kh2 Qe5+',
    'Kh1 b2',
    'Qb7+ Kg8',
    'Qb8+ Kf7',
    'Qb7+ Ke8',
    'Qb8+ Ke7',
  ],
  [
    'Nf3 Nf6',
    'b3 g6',
    'Bb2 Bg7',
    'g3 O-O',
    'Bg2 d6',
    'O-O c5',
    'c4 Nc6',
    'Nc3 Bg4',
    'Nd5 Nxd5',
    'Bxg7 Kxg7',
    'cxd5 Nd4',
    'Nxd4 cxd4',
    'h3 Bd7',
    'Rc1 Qb6',
    'Rc4 Bb5',
    'Rc1 Rac8',
    'd3 Rc5',
    'Qd2 Rfc8',
    'Rxc5 Qxc5',
    'Qg5 Rc7',
    'h4 Qc2',
    'a4 Ba6',
    'Qf4 Qxe2',
    'Qxd4+ f6',
    'Be4 b6',
    'b4 Bc8',
    'Qa1 Rc2',
    'Qd4 Qd2',
    'a5 Qc3',
    'Qe3 Qxb4',
    'axb6 axb6',
    'h5 Rc7',
    'hxg6 hxg6',
    'Kg2 Qc5',
    'Qe1 Bb7',
    'Rh1 Bxd5',
    'Qe2 Bxe4+',
    'dxe4 Qe5',
    'Rh4 b5',
    'Qa2 Rc8',
    'Qb1 Rh8',
    'Rxh8 Kxh8',
    'Qd3 Kg7',
    'f4 Qc5',
    'Kh3 Qc4',
    'Qe3 b4',
    'f5 b3',
    'Qa7 Qxe4',
    'fxg6 b2',
    'Qa2 b1=Q',
    'Qf7+ Kh6',
    'Qf8+ Kg5',
    'Qh6+ Kxh6',
    'g7 Qbh1+',
  ],
  [
    'd4 Nf6',
    'Bg5 Ne4',
    'Bf4 c5',
    'f3 Qa5+',
    'c3 Nf6',
    'd5 d6',
    'e4 g6',
    'Na3 Bg7',
    'Nc4 Qd8',
    'a4 O-O',
    'Ne2 Nh5',
    'Be3 b6',
    'g4 Nf6',
    'Ng3 e6',
    'dxe6 Bxe6',
    'Qxd6 Qc8',
    'g5 Nfd7',
    'Qf4 Nc6',
    'Rd1 Nde5',
    'Nxe5 Nxe5',
    'Be2 f6',
    'O-O fxg5',
    'Qxg5 Bf6',
    'Qh6 Nf7',
    'Qf4 Bg5',
    'Qxg5 Nxg5',
    'Bxg5 Qc7',
    'f4 c4',
    'f5 gxf5',
    'exf5 Qc5+',
    'Rd4 Bxf5',
    'Bxc4+ Kh8',
    'b4 Qe5',
    'Bh6 Bh3',
    'Rfd1 Rae8',
    'Be2 Rg8',
    'Kh1 Rxg3',
    'hxg3 Qxe2',
    'R1d2 Qf1+',
    'Kh2 Bf5',
    'Rd8 Qh3+',
    'Kg1 Qxg3+',
    'Rg2 Qxg2+',
    'Kxg2 Rxd8',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e5',
    'Nb3 Be6',
    'f3 h5',
    'Qd2 Be7',
    'O-O-O Nbd7',
    'Kb1 Rc8',
    'Nd5 Nxd5',
    'exd5 Bf5',
    'Bd3 Bxd3',
    'Qxd3 Bg5',
    'Bf2 O-O',
    'h4 e4',
    'Qxe4 Bf6',
    'Bd4 Ne5',
    'g4 Re8',
    'g5 Be7',
    'Bxe5 dxe5',
    'g6 Bf6',
    'gxf7+ Kxf7',
    'd6 Qd7',
    'Qd5+ Kf8',
    'Nc5 Qb5',
    'd7 Rxc5',
    'dxe8=Q+ Kxe8',
    'Qg8+ Ke7',
    'Rhe1 Qc6',
    'f4 e4',
    'Re2 Rb5',
    'c4 Rc5',
    'b3 b5',
    'Rd5 Rxd5',
    'cxd5 Qc3',
    'Rxe4+ Kd7',
    'Qe6+ Kc7',
    'Qc6+ Qxc6',
    'dxc6 Kxc6',
    'f5 Kd5',
    'Re6 a5',
    'Kc2 Bxh4',
    'Kd3 Bf6',
    'Ke3 Bd4+',
    'Kf4 Bf6',
    'Ra6 Bc3',
    'Kg5 Ke4',
    'Rb6 b4',
    'Rb5 Kd3',
    'Kxh5 a4',
    'bxa4 Kc2',
    'a5 Kb2',
    'a6 Kxa2',
    'a7 Kb3',
    'a8=Q Kc4',
  ],
  [
    'Nf3 Nf6',
    'c4 c6',
    'Nc3 d5',
    'cxd5 cxd5',
    'd4 a6',
    'Bf4 Nc6',
    'e3 Bg4',
    'Be2 Bxf3',
    'Bxf3 e6',
    'O-O Bd6',
    'Bg5 h6',
    'Bh4 g5',
    'Bg3 h5',
    'Bxd6 Qxd6',
    'e4 g4',
    'e5 Qb4',
    'Be2 Nd7',
    'f3 gxf3',
    'Bxf3 Qxd4+',
    'Qxd4 Nxd4',
    'Rae1 Nc6',
    'Bxd5 exd5',
    'e6 fxe6',
    'Rxe6+ Kd8',
    'Nxd5 Rc8',
    'Rd6 Ne5',
    'Nf4 Rc6',
    'Rd5 Kc8',
    'h3 Nc4',
    'Rf2 Ne3',
    'Rd3 Rc1+',
    'Kh2 Re8',
    'Kg3 Nf5+',
    'Kh2 Ne3',
    'Kg3 Nc5',
    'Rxe3 Rxe3+',
    'Kh4 Ne4',
  ],
  [
    'c4 e5',
    'Nc3 Nf6',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'Nf3 Nc6',
    'a3 Be7',
    'd3 Be6',
    'O-O O-O',
    'Bd2 Qd7',
    'Rc1 Bh3',
    'Na4 Rad8',
    'Nc5 Bxc5',
    'Rxc5 f6',
    'Qc2 Bxg2',
    'Kxg2 Rfe8',
    'Be3 Kh8',
    'b4 Nd5',
    'Rc1 Nce7',
    'Bd2 c6',
    'Qb3 a6',
    'a4 Nc7',
    'R5c4 Nf5',
    'Nh4 Nd4',
    'Qd1 Qd5+',
    'Kg1 e4',
    'Be3 Nf5',
    'Rc5 Nxe3',
    'fxe3 Qd7',
    'd4 Nd5',
    'Qd2 Qg4',
    'b5 axb5',
    'axb5 cxb5',
    'Rxb5 b6',
    'Ng2 Qd7',
    'Qb2 Rc8',
    'Rxc8 Rxc8',
    'Qb3 Rd8',
    'Kf2 g5',
    'Ke1 Kg7',
    'Kd2 Qc6',
    'Ne1 h6',
    'g4 Qd7',
    'Nc2 Qc6',
    'Ke1 Qd7',
    'h3 Qc6',
    'Kd2 Rd6',
    'Na3 Rd8',
    'Ke1 Rd6',
    'Nc4 Re6',
    'Kf2 Qb7',
    'Na5 Qc7',
    'Qxd5 Qh2+',
    'Ke1 Re7',
    'Rxb6 Rc7',
    'Rc6 Ra7',
    'Qc5 Qg1+',
    'Kd2 Rf7',
    'Rc7 Qa1',
    'Rxf7+ Kxf7',
    'Qc3 Qa2+',
    'Kc1 Qxe2',
    'Qc4+ Qxc4+',
    'Nxc4 f5',
    'Nd6+ Ke6',
  ],
  [
    'c4 e5',
    'Nc3 Nf6',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'd3 Be7',
    'Be3 O-O',
    'Rc1 Kh8',
    'Nf3 Nc6',
    'O-O f5',
    'Na4 Bd6',
    'a3 f4',
    'Bc5 Bg4',
    'b4 Qd7',
    'Nc3 Bh3',
    'Ne4 Bxg2',
    'Kxg2 Nd5',
    'Bxd6 cxd6',
    'Qb3 Nce7',
    'd4 fxg3',
    'fxg3 exd4',
    'Nxd4 Qg4',
    'Qd3 Nf4+',
    'Rxf4 Rxf4',
    'Nf2 Rxf2+',
    'Kxf2 Rf8+',
    'Kg2 Ng6',
    'Rf1 Rxf1',
    'Kxf1 Ne5',
    'Qc2 h6',
    'Nb5 d5',
    'Nd6 Nc4',
    'Nxc4 dxc4',
    'Kf2 b5',
    'Ke3 Qg5+',
    'Kd4 Qf6+',
    'Kc5 Qe5+',
    'Kc6 a6',
    'Kb7 Qd6',
    'Qe4 c3',
    'Qc2 Qd2',
    'Qe4 c2',
    'Qe8+ Kh7',
    'Qe4+ Kh8',
    'Qe8+ Kh7',
    'Qe4+ Kh8',
    'Kxa6 Qd6+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be2 b5',
    'a4 b4',
    'Nd5 Bb7',
    'Nxf6+ gxf6',
    'O-O e6',
    'Bh5 Qd7',
    'Qg4 Nc6',
    'Qxe6+ Qxe6',
    'Nxe6 Rc8',
    'Nxf8 Rxf8',
    'Bh6 Rg8',
    'Rad1 Ne5',
    'Rxd6 Bxe4',
    'g3 Ke7',
    'Rxa6 Rxc2',
    'Re1 Bd5',
    'Bf4 Kf8',
    'Rxf6 Nd3',
    'Bh6+ Rg7',
    'Rd1 b3',
    'Rxd3 Ke7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'e5 h6',
    'Be3 Ne4',
    'Qg4 g5',
    'h4 h5',
    'hxg5 hxg4',
    'Rxh8+ Bf8',
    'Nxe4 dxe4',
    'Ne2 Kd7',
    'O-O-O c6',
    'Ng3 Qe7',
    'Nxe4 Kc7',
    'Rh7 Nd7',
    'g6 Qe8',
    'Rxf7 Be7',
    'Nf6 Bxf6',
    'exf6 Qg8',
    'Bf4+ Kb6',
    'Bd3 Nxf6',
  ],
  [
    'd4 e6',
    'c4 b6',
    'Nc3 Bb4',
    'e3 Bxc3+',
    'bxc3 Ne7',
    'Qf3 d5',
    'cxd5 exd5',
    'Bd3 O-O',
    'Ne2 Ba6',
    'Bc2 Ng6',
    'h4 Re8',
    'h5 Nf8',
    'Ba3 c5',
    'O-O cxd4',
    'cxd4 Nbd7',
    'Rfc1 Bxe2',
    'Qxe2 Qg5',
    'Ba4 Re4',
    'Rc7 Nc5',
    'Qf3 Nce6',
    'Rxf7 g6',
    'Bc6 Rc8',
  ],
  [
    'e4 e5',
    'Nf3 Nf6',
    'Nxe5 d6',
    'Nf3 Nxe4',
    'Bd3 d5',
    'O-O Be7',
    'Re1 Nd6',
    'Nc3 c6',
    'Qe2 Nd7',
    'b4 Nf8',
    'Nd4 Ne6',
    'Nxe6 Bxe6',
    'Qh5 Qd7',
    'Bb2 Bf6',
    'Rab1 Kf8',
    'h3 Nf5',
    'Na4 g6',
    'Nc5 gxh5',
    'Nxd7+ Bxd7',
    'Bxf6 Rg8',
    'Re5 Nd4',
    'Rxh5 Nf3+',
    'Kh1 Nxd2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bg5 Ne4',
    'cxd5 Nxg5',
    'Nxg5 c6',
    'Nf3 cxd5',
    'e3 O-O',
    'Bd3 Nc6',
    'O-O e5',
    'dxe5 Nxe5',
    'Be2 Be6',
    'Nd4 Nc6',
    'Bf3 Nxd4',
    'exd4 Qd7',
    'Qb3 Rfd8',
    'Rfd1 Rac8',
    'Be2 Bh6',
    'Na4 b6',
    'Ba6 Rc7',
    'Nc3 Qe7',
    'Nb5 Rc6',
    'Re1 Rc4',
    'a3 Qd7',
    'Rad1 Bg7',
    'Re3 h5',
    'Rc3 h4',
    'Qc2 h3',
    'b3 Bf5',
    'Qd2 Rxc3',
    'Qxc3 hxg2',
    'Qg3 Qe7',
    'Rc1 Bh6',
    'Rc7 Qe1+',
    'Kxg2 Be4+',
    'f3 Qe2+',
    'Kh3 Bf5+',
    'Kh4 g5+',
    'Kh5 Qe6',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Ba7',
    'Nc3 Nc6',
    'Qg4 Nf6',
    'Qg3 d6',
    'a4 Qe7',
    'O-O Bd7',
    'Kh1 Nd4',
    'Na5 Bc6',
    'b4 Bb6',
    'f4 Bxa5',
    'bxa5 Rg8',
    'e5 O-O-O',
    'Qe1 dxe5',
    'fxe5 Nd7',
    'Ne2 Nxe2',
    'Qxe2 Qc5',
    'Bf4 Qxa5',
    'Bg3 Nc5',
    'Be1 Qc7',
    'Bb4 Nxd3',
    'cxd3 f6',
    'Bd6 Rxd6',
    'exd6 Qxd6',
    'd4 Rd8',
    'Rfd1 Rd7',
    'a5 Kd8',
    'Rd2 Qd5',
    'Qe3 Ke7',
    'h3 Kf7',
    'Qd3 Qe4',
    'Qxe4 Bxe4',
    'Rc1 Bc6',
    'Rc5 h6',
    'Kg1 Ke7',
    'Kf2 Kd6',
    'g4 Re7',
    'Rc1 Rf7',
    'Rc5 f5',
    'gxf5 Bd5',
    'Rd3 Rxf5+',
    'Ke1 Rf4',
    'Rc8 g5',
    'Rh8 Rh4',
    'Rc3 g4',
    'hxg4 Rxg4',
    'Rxh6 Rxd4',
    'Rhh3 Rb4',
    'Kd2 Rb5',
    'Ra3 Kc5',
    'Kc3 Rb4',
    'Rh2 Rc4+',
    'Kb2 Kb5',
    'Rh5 Ra4',
    'Re3 Kb4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'Ne2 h6',
    'Bxf6 Qxf6',
    'a3 Ba5',
    'Qd3 dxe4',
    'Qxe4 O-O',
    'f4 Bd7',
    'O-O-O Nc6',
    'g4 Ne7',
    'Rg1 Bc6',
    'Qe3 Nd5',
    'Nxd5 exd5',
    'Ng3 Rfe8',
    'Qf3 g6',
    'Bd3 Bb6',
    'c3 Ba4',
    'Bc2 Bxc2',
    'Kxc2 c6',
    'h4 Bc7',
    'Rdf1 Qxh4',
    'Rh1 Qe7',
    'Rxh6 Qe3',
    'Qh1 Qxg3',
    'Rh8+ Kg7',
    'Qh6+ Kf6',
    'Rh7 Rf8',
    'g5+ Ke7',
    'Qxg6 Qg2+',
    'Kb3 Qxf1',
    'Qf6+ Ke8',
    'g6 Qc4+',
    'Kc2 Qe2+',
    'Kb3 Qe7',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nc6',
    'Nc3 Qc7',
    'Be3 a6',
    'Qd2 Nf6',
    'O-O-O Be7',
    'f3 O-O',
    'g4 Rd8',
    'Qf2 b5',
    'g5 Ne8',
    'Nb3 Rb8',
    'f4 Nd6',
    'Bd3 Nb4',
    'Kb1 Nc4',
    'Bc1 d5',
    'e5 Bb7',
    'Ne2 d4',
    'Rhe1 Ne3',
    'Rd2 Nexc2',
    'Bxc2 d3',
    'Bd1 dxe2',
    'Rexe2 Rbc8',
    'Qe3 Bd5',
    'a3 Bxb3',
    'Rxd8+ Rxd8',
    'Bxb3 Rd3',
    'Qe4 Rxb3',
    'axb4 Rxb4',
    'Qa8+ Bf8',
    'Qxa6 Qc4',
    'Rc2 Qd3',
    'Qc6 Rc4',
    'Qg2 h6',
    'h4 h5',
    'Qe2 Qf5',
    'Be3 Bc5',
    'Bc1 Rxc2',
    'Qxc2 Bf2',
    'Qxf5 exf5',
    'Kc2 Bxh4',
    'Kd3 Bf2',
    'Ke2 Bd4',
    'b3 Kf8',
    'Bd2 Ke8',
    'Be1 Kd7',
    'Kd3 Bg1',
    'Bg3 Kc6',
    'g6 fxg6',
    'Bh4 Kd5',
    'Bg5 Bc5',
    'Ke2 Ke6',
  ],
  [
    'd4 e6',
    'c4 c5',
    'Nf3 cxd4',
    'Nxd4 Nf6',
    'g3 Qa5+',
    'Nd2 Nc6',
    'Nxc6 dxc6',
    'Bg2 Be7',
    'O-O O-O',
    'h3 e5',
    'Qc2 Be6',
    'a3 Qc7',
    'b3 Rad8',
    'Bb2 Qd7',
    'Rad1 Bxh3',
    'Nf3 Qf5',
    'e4 Qh5',
    'Nxe5 Bg4',
    'Rxd8 Rxd8',
    'b4 Be6',
    'c5 Ng4',
    'Nxg4 Qxg4',
    'Be5 h5',
    'Qc3 h4',
    'gxh4 Qxh4',
    'Bc7 Rd7',
    'Bb8 Bf6',
    'Qc2 Bd4',
    'Bd6 Bh3',
    'Qe2 b6',
    'Qf3 Bxg2',
    'Kxg2 Qg5+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'e5 Nfd7',
    'f4 c5',
    'Nf3 Nc6',
    'Be3 Rb8',
    'Qd2 Qa5',
    'dxc5 Nxc5',
    'Bd3 Be7',
    'O-O O-O',
    'a3 Qd8',
    'Ne2 Nxd3',
    'cxd3 f6',
    'Rac1 Bd7',
    'b4 a6',
    'Ned4 a5',
    'exf6 gxf6',
    'Nxc6 bxc6',
    'Bc5 axb4',
    'axb4 Bxc5+',
    'bxc5 Qe7',
    'Rfe1 Rfe8',
    'Re3 Rb5',
    'Qc3 Reb8',
    'Nd4 Rb1',
    'Rxb1 Rxb1+',
    'Kf2 e5',
    'fxe5 fxe5',
    'Nf3 e4',
    'dxe4 dxe4',
    'Qc4+ Qe6',
    'Rxe4 Rb2+',
    'Kg3 Qxc4',
    'Rxc4 Be6',
    'Re4 Bd5',
    'Rg4+ Kf7',
    'Rh4 Kg7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'e5 Nfd7',
    'f4 c5',
    'Nf3 Nc6',
    'Be3 a6',
    'Qd2 b5',
    'a3 g5',
    'Nxg5 cxd4',
    'Bxd4 Nxd4',
    'Qxd4 Bc5',
    'Qd2 Qb6',
    'Nd1 f6',
    'exf6 Nxf6',
    'Bd3 O-O',
    'Rf1 Ra7',
    'Nf3 Ng4',
    'g3 e5',
    'fxe5 Ne3',
    'Nxe3 Bxe3',
    'Qg2 Raf7',
    'g4 Kh8',
    'Rd1 d4',
    'Nd2 Bf2+',
    'Rxf2 Rxf2',
    'Qxf2 Rxf2',
    'Kxf2 Qh6',
    'Nf1 Qh4+',
    'Ng3 Bxg4',
    'Rh1 Qg5',
    'Nf1 Qf4+',
    'Ke1 Qxe5+',
    'Kf2 Qf4+',
    'Ke1 Qc1+',
    'Kf2 Bd1',
    'Ng3 Qd2+',
    'Ne2 Bxc2',
    'Bxc2 Qxc2',
    'Rc1 Qf5+',
  ],
  [
    'd4 d5',
    'Nf3 Nf6',
    'c4 c6',
    'Nc3 a6',
    'c5 Nbd7',
    'Bf4 Nh5',
    'Bd2 Nhf6',
    'Rc1 g6',
    'h3 Qc7',
    'g3 Bg7',
    'Bg2 O-O',
    'Bf4 Qd8',
    'O-O Nh5',
    'Bd2 f5',
    'Na4 e5',
    'dxe5 Nxe5',
    'Nb6 Rb8',
    'Bc3 Nf7',
    'e3 Be6',
    'b4 Qe8',
    'a4 Nf6',
    'Qe2 g5',
    'Qb2 Qe7',
    'b5 g4',
    'Nd4 gxh3',
    'Bxh3 Ng5',
    'Bg2 Bd7',
    'Nf3 Nge4',
    'Bd4 Be6',
    'Be5 Rbe8',
    'bxc6 bxc6',
    'Nd4 Ng4',
    'Nxc6 Qf7',
    'Bxe4 Qh5',
    'f4 Nxe5',
    'Nxe5 fxe4',
    'c6 Bh3',
    'Qh2 Qf5',
    'g4 Bxg4',
    'c7 Bh3',
    'c8=Q Rxc8',
    'Nxc8 Rxc8',
    'Rxc8+ Qxc8',
    'Rb1 Bxe5',
    'Qg3+ Bg7',
    'f5 Qxf5',
    'Rb8+ Kf7',
    'Rb7+ Ke6',
    'Qxg7 Qf1+',
    'Kh2 Qf2+',
    'Kxh3 Qxe3+',
    'Kh4 Qf2+',
    'Qg3 Qf6+',
    'Kh5 Qf5+',
    'Kh6 Qg6+',
    'Qxg6+ hxg6',
    'Kxg6 e3',
    'Kg5 d4',
    'Kf4 e2',
    'Rb1 Kd5',
    'Kf3 d3',
    'Ke3 Kc4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 Nf6',
    'Nc3 cxd4',
    'Nxd4 a6',
    'g3 e5',
    'Nb3 Nbd7',
    'a4 b6',
    'Bg2 Be7',
    'O-O Bb7',
    'Nd2 Rc8',
    'Re1 O-O',
    'Nf1 Nc5',
    'Bg5 Ne6',
    'Bxf6 Bxf6',
    'Ne3 g6',
    'Ncd5 Bg5',
    'c3 Kh8',
    'Qb3 Bxe3',
    'Rxe3 Bxd5',
    'Qxd5 Qe7',
    'Rd1 Rfd8',
    'Bh3 Rc5',
    'Qb3 Rc6',
    'Qd5 Rc5',
    'Qd3 Nc7',
    'Qc2 Ne8',
    'Qe2 Qb7',
    'b4 Rc7',
    'Bf1 Ra8',
    'Qa2 Qc8',
    'Qb3 Kg7',
    'Be2 Qe6',
    'Qa3 h5',
    'h4 Rd7',
    'Kg2 Nf6',
    'c4 a5',
    'bxa5 bxa5',
    'Rb1 Rdd8',
    'Rb5 Nd7',
    'Rd3 Nc5',
    'Rd5 Nxe4',
    'Rxa5 Rxa5',
    'Rxa5 Qf5',
    'Qf3 Rc8',
    'Qxf5 gxf5',
    'Ra7 Kg6',
    'Ra6 f6',
    'a5 d5',
    'cxd5 Rc2',
    'Kf1 Rc1+',
    'Kg2 Rc2',
    'Bb5 Rxf2+',
    'Kg1 Rd2',
    'Be8+ Kh6',
    'Rb6 Rxd5',
    'a6 Rd1+',
    'Kg2 Rd2+',
    'Kg1 Rd1+',
    'Kg2 Rd2+',
    'Kf1 Ra2',
    'Ke1 Ra1+',
    'Ke2 Nxg3+',
    'Kd3 Ne4',
  ],
  [
    'Nf3 d5',
    'g3 Nc6',
    'd4 Bf5',
    'c3 Qd7',
    'Nbd2 f6',
    'Nh4 Bg4',
    'h3 Bh5',
    'Nb3 e5',
    'Be3 O-O-O',
    'g4 Bf7',
    'Bg2 e4',
    'f4 g6',
    'Qc2 b6',
    'Bf2 Bd6',
    'e3 Nge7',
    'a3 g5',
    'fxg5 fxg5',
    'Nf5 Nxf5',
    'gxf5 Rde8',
    'Nd2 Bh5',
    'Qa4 Rhf8',
    'O-O g4',
    'h4 g3',
    'Be1 Na5',
    'Qxd7+ Kxd7',
    'Bh3 Rf6',
    'b4 Nc4',
    'Nxc4 dxc4',
    'Ra2 Kc6',
    'Kg2 a5',
    'a4 axb4',
    'cxb4 Ra8',
    'a5 Kb5',
    'a6 c6',
    'Bc3 Rf7',
    'f6 Bf3+',
    'Kg1 Rxa6',
    'Rxa6 Kxa6',
    'Bc8+ Ka7',
    'd5 Kb8',
    'Be6 Ra7',
    'Ra1 Rxa1+',
    'Bxa1 cxd5',
    'f7 Bf8',
    'Be5+ Kb7',
    'Bxd5+ Kc8',
    'Bxc4 Kd7',
    'Bxg3 Bxb4',
    'Be5 Bf8',
    'Kf2 b5',
    'Bxb5+ Ke6',
    'Bf4 Kxf7',
    'Bc4+ Kg6',
    'Bb5 h5',
    'Bd7 Bb4',
    'Bc8 Be7',
    'Kg3 Bb4',
    'Kf2 Bg4',
    'Bb7 Bf5',
    'Bc6 Kf6',
    'Be8 Bg4',
    'Bc6 Bf3',
    'Bd7 Kf7',
  ],
  [
    'd4 Nf6',
    'Bg5 e6',
    'e4 h6',
    'Bxf6 Qxf6',
    'c3 d6',
    'Bd3 g6',
    'Ne2 Bg7',
    'f4 Qh4+',
    'g3 Qe7',
    'e5 Bd7',
    'Nd2 Bc6',
    'Be4 Bxe4',
    'Nxe4 Nd7',
    'Qa4 d5',
    'Nc5 b6',
    'Na6 Qd8',
    'Qc6 Rc8',
    'c4 dxc4',
    'd5 exd5',
    'O-O-O O-O',
    'Qxd5 Nb8',
    'Qxc4 Qe8',
    'Nxb8 Rxb8',
    'Kb1 c5',
    'Rd6 Rd8',
    'Rhd1 Qe7',
    'Nc3 Rxd6',
    'Rxd6 Rd8',
    'Rd5 g5',
    'a3 Rxd5',
    'Qxd5 gxf4',
    'gxf4 Qh4',
    'Qa8+ Kh7',
    'Qe4+ Kh8',
    'Nd5 Qxh2',
    'Ne7 Bf8',
    'Qa8 Kg7',
    'Nf5+ Kg8',
    'Ne7+ Kg7',
    'Nf5+ Kg8',
    'Qf3 Qg1+',
    'Ka2 Qg6',
    'Ne3 Qd3',
    'Qg2+ Kh8',
    'Qa8 Kg7',
    'Qg2+ Kh8',
    'Qa8 Kg7',
    'Qg2+ Kh8',
    'Nd5 c4',
    'Nc3 Qf5',
    'Qa8 Kg7',
    'Qxa7 Qxf4',
    'Qxb6 Qxe5',
    'a4 h5',
    'a5 h4',
    'a6 Bc5',
    'Qb5 Qd4',
    'Na4 Ba7',
    'Qg5+ Kf8',
    'Qh6+ Ke8',
    'Qc6+ Kf8',
    'Nc3 Qc5',
    'Qh6+ Ke8',
    'Qh8+ Kd7',
    'Qf6 Ke8',
    'Qh8+ Kd7',
    'Qh7 Ke8',
    'Qe4+ Kf8',
    'Qxh4 Qa5+',
    'Kb1 Qxa6',
    'Qh8+ Ke7',
    'Qe5+ Kd7',
    'Nd5 Qd6',
    'Nf6+ Kc6',
    'Qe4+ Kb5',
    'Qb7+ Bb6',
    'Nd5 Qc6',
    'Qxf7 Bd4',
    'Qf5 Qc5',
    'Qe4 Ka6',
    'Kc2 Ka7',
    'Qh7+ Ka6',
    'Qd7 Bxb2',
    'Qe6+ Kb5',
    'Kxb2 Qf2+',
    'Kc3 Qg3+',
    'Ne3 Qe1+',
    'Kd4 Qd2+',
    'Ke4 Qd3+',
    'Kf4 Kb4',
    'Qb6+ Kc3',
    'Kf3 Qd7',
    'Qc5 Qh3+',
    'Kf4 Qh2+',
    'Kf3 Qh3+',
    'Kf2 Qh4+',
    'Kg2 Qe4+',
    'Kg3 Kd3',
    'Ng4 Kd2',
    'Qg5+ Kc2',
    'Ne3+ Kd3',
    'Nd1 Kc2',
    'Nf2 Qd4',
    'Kf3 c3',
    'Qf5+ Kb3',
    'Nd3 c2',
    'Qg6 Qc3',
    'Qe6+ Ka3',
    'Qd6+ Kb3',
    'Qe6+ Ka3',
    'Qe3 c1=Q',
    'Qxc1+ Qxc1',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'Bb5+ Nc6',
    'O-O Bd7',
    'Re1 Nf6',
    'c3 a6',
    'Ba4 c4',
    'Na3 b5',
    'Bc2 e5',
    'b3 Na5',
    'd4 cxd3',
    'Bxd3 Be7',
    'c4 b4',
    'Nc2 Nc6',
    'a3 a5',
    'axb4 axb4',
    'Bd2 Rb8',
    'Ne3 Be6',
    'Ra6 Na5',
    'Nd5 Bxd5',
    'cxd5 Nd7',
    'Be3 O-O',
    'Qc2 Nb7',
    'Rc6 Na5',
    'Ra6 Nb7',
    'Rc6 Ra8',
    'Rc4 Nbc5',
    'Rxb4 Ra3',
    'Rb1 Qa8',
    'Bf1 Ra2',
    'Qd1 f5',
    'exf5 Rxf5',
    'Bb5 Nf6',
    'Bc6 Qf8',
    'Ra1 Rxa1',
    'Qxa1 e4',
    'Nd4 Re5',
    'Qa8 Qxa8',
    'Bxa8 Nxd5',
    'Rb8+ Bf8',
    'Nc6 Rh5',
    'g4 Rh4',
    'Bxc5 dxc5',
    'Ne5 Nc7',
    'Bxe4 g5',
    'Bf5 Kg7',
    'Nf3 Rh6',
    'Nxg5 Bd6',
    'Rb7 Kg8',
    'h3 Bf4',
    'Ne4 Rxh3',
    'Nxc5 Rc3',
    'Ne4 Rc1+',
    'Kg2 h6',
    'b4 Kf8',
    'Nc5 Bd6',
    'Rb6 Bxc5',
    'bxc5 h5',
    'Rc6 Nd5',
    'Rc8+ Kf7',
    'gxh5 Nf4+',
    'Kf3 Nxh5',
    'c6 Nf6',
    'Rc7+ Ke8',
    'Rc8+ Ke7',
    'Be4 Rc4',
    'Bf5 Nd5',
    'Bd7 Nf6',
    'Ke3 Rc3+',
    'Kd2 Rc5',
    'Rc7 Kd6',
    'Ra7 Re5',
    'f3 Rh5',
    'Ke3 Re5+',
    'Kf2 Re7',
    'Ra3 Nxd7',
    'cxd7 Kxd7',
    'Ra7+ Ke8',
    'Rxe7+ Kxe7',
    'Kg3 Kf7',
    'Kg4 Kg6',
    'Kf4 Kf6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'g4 h6',
    'h3 b5',
    'a3 Bb7',
    'Bg2 Nbd7',
    'Qe2 Rc8',
    'O-O Nc5',
    'Bd2 e5',
    'Nb3 Be7',
    'f4 Ne6',
    'f5 Nd4',
    'Qd3 O-O',
    'Rfe1 Nd7',
    'Be3 Nc5',
    'Nxc5 dxc5',
    'Nd5 Bh4',
    'Rf1 c4',
    'Qd1 Bxd5',
    'exd5 Bg5',
    'Bf2 c3',
    'b3 Bd2',
    'Be4 Re8',
    'f6 gxf6',
    'Bh4 Bg5',
    'Bg3 Rc5',
    'h4 Be3+',
    'Kh2 Bd2',
    'g5 f5',
    'Qh5 Qd6',
    'Bxf5 Nxf5',
    'Rxf5 Qg6',
    'Qg4 h5',
    'Qe4 Rxd5',
    'Raf1 Rd4',
    'Qb7 Bf4',
    'Rf6 Qxc2+',
    'Kh3 Rf8',
    'g6 fxg6',
    'Bxf4 exf4',
    'Rxf8+ Kxf8',
    'Qb8+ Ke7',
    'Qe5+ Kf7',
    'Qxd4 Qf5+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'Bg5 Bb4',
    'e5 h6',
    'Bd2 Bxc3',
    'bxc3 Ne4',
    'Qg4 g6',
    'Be3 Nxc3',
    'Bd3 Nc6',
    'Nf3 Bd7',
    'Qh3 Qe7',
    'Bg5 Qa3',
    'O-O Ne4',
    'Bc1 Qc3',
    'Rb1 O-O-O',
    'Rb3 Qa5',
    'c3 a6',
    'Ra3 Qb6',
    'Be3 Qb2',
    'Bc1 Qb6',
    'Be3 f5',
    'exf6 e5',
    'dxe5 Qb2',
    'Bc1 Qxa3',
    'Bxa3 Bxh3',
    'gxh3 Kd7',
    'c4 Ng5',
    'Nxg5 hxg5',
    'Re1 Ke6',
    'cxd5+ Rxd5',
    'Bc4 Rd8',
    'h4 g4',
    'h3 gxh3',
    'f4 b5',
    'Bxd5+ Rxd5',
    'Rc1 Nd4',
    'Rxc7 Nf3+',
    'Kf2 h2',
    'Rc6+ Kf5',
    'f7 h1=Q',
    'f8=Q+ Ke4',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 e5',
    'Bc4 Be7',
    'd3 d6',
    'Nd2 Nf6',
    'Nf1 Bg4',
    'f3 Be6',
    'Ne3 O-O',
    'a3 Rb8',
    'O-O Nd4',
    'Ncd5 Nxd5',
    'Nxd5 b5',
    'Ba2 Bg5',
    'f4 exf4',
    'c3 Bxd5',
    'Bxd5 Ne6',
    'Qg4 Qe7',
    'Bd2 a5',
    'Rae1 b4',
    'axb4 axb4',
    'cxb4 cxb4',
    'b3 g6',
    'Kh1 Kg7',
    'Bc1 Rbc8',
    'Bc4 Ra8',
    'd4 h5',
    'Qf3 Nxd4',
    'Bb2 Bf6',
    'e5 Qxe5',
    'Qf2 Qc5',
    'Qxf4 Nf5',
    'Bxf6+ Kxf6',
    'Bd3 Rg8',
    'Qh4+ Kg7',
    'Bxf5 gxf5',
    'Qxh5 Rae8',
    'Rc1 Qe3',
    'Qxf5 Qe6',
    'Qf4 Rh8',
    'Rc7 Re7',
    'Rxe7 Qxe7',
    'Qxb4 Rh4',
    'Qd2 Qe5',
    'h3 Re4',
    'Qf2 Qe6',
    'Qg3+ Kf8',
    'Qc3 Re3',
    'Qh8+ Ke7',
    'b4 Kd7',
    'Qh4 Re4',
    'Rxf7+ Qxf7',
    'Qxe4 d5',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 g6',
    'Be2 Bg7',
    'O-O O-O',
    'exd6 cxd6',
    'c4 Nb6',
    'Nc3 Bg4',
    'h3 Bxf3',
    'Bxf3 Nc6',
    'c5 dxc5',
    'dxc5 Nd7',
    'Be3 Qc8',
    'Rc1 Rd8',
    'Qb3 Nde5',
    'Be4 Qe6',
    'Qxb7 Rab8',
    'Qa6 Rxb2',
    'Nd5 Rxd5',
    'Bxd5 Qxd5',
    'Qc8+ Bf8',
    'Rfd1 Qe6',
    'Qxe6 fxe6',
    'Bd4 Nxd4',
    'Rxd4 Nc6',
    'Rd7 Rxa2',
    'Rc7 Ra6',
    'Rc8 Kf7',
    'Rc3 Bg7',
    'Rc4 Bf6',
    'g3 e5',
    'Rc1 Ke6',
    'Rd1 Nd4',
    'Rc7 h5',
    'h4 e4',
    'Kg2 e3',
    'fxe3 Ra2+',
    'Kf1 Nf5',
    'Rd8 Nxg3+',
    'Kg1 Kf5',
    'Ra8 Rc2',
    'Rcxa7 Ne4',
    'Ra2 Rxc5',
    'Kg2 Bxh4',
    'R8a5 Rxa5',
    'Rxa5+ e5',
    'Kf3 Bf6',
    'Ra4 g5',
    'Rb4 g4+',
    'Kg2 h4',
    'Kh2 Bg5',
    'Rb3 g3+',
    'Kh3 Nf2+',
    'Kg2 Kg4',
  ],
  [
    'd4 Nf6',
    'Bg5 Ne4',
    'Bf4 d5',
    'e3 c6',
    'Bd3 Qb6',
    'Qc1 Nd6',
    'Nd2 c5',
    'c3 cxd4',
    'exd4 Bf5',
    'Qc2 e6',
    'O-O-O Nc6',
    'Bxf5 Nxf5',
    'Re1 Be7',
    'g4 Nh4',
    'Bg3 h5',
    'gxh5 Nf5',
    'Ne2 Rc8',
    'Nf3 Qa6',
    'a3 Rxh5',
    'Kb1 Rh8',
    'Be5 Kd7',
    'Nf4 Qa5',
    'Rhg1 Rh7',
    'c4 Nxe5',
    'Nxe5+ Ke8',
    'Nxd5 Rxh2',
    'Nxe7 Kxe7',
    'Qxf5 exf5',
    'Nc6+ Kf6',
    'Nxa5 b6',
    'Nb7 Rxc4',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 g6',
    'Bc4 Nb6',
    'Bb3 Bg7',
    'Ng5 d5',
    'Be3 f6',
    'Nf3 Nc6',
    'exf6 exf6',
    'O-O O-O',
    'Re1 Re8',
    'Nbd2 Na5',
    'c3 Nxb3',
    'Qxb3 Be6',
    'Qc2 Bf5',
    'Qc1 c6',
    'b3 g5',
    'Nf1 Qd7',
    'Ng3 Bg6',
    'h4 h6',
    'hxg5 hxg5',
    'Nh2 Bh6',
    'Bd2 Rxe1+',
    'Qxe1 Nc8',
    'Qe2 Nd6',
    'Ng4 Bg7',
    'Qf3 Qf7',
    'Re1 a5',
    'Re3 a4',
    'Qe2 axb3',
    'axb3 Ra1+',
    'Kh2 Bf8',
    'Nh6+ Bxh6',
    'Re7 Bd3',
    'Rxf7 Bxe2',
    'Rxf6 Bf8',
    'c4 dxc4',
    'Nxe2 Ne4',
    'Rxf8+ Kxf8',
    'Bb4+ Kf7',
    'bxc4 Ra4',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'c4 Nb6',
    'd4 d6',
    'f4 dxe5',
    'fxe5 Nc6',
    'Be3 Bf5',
    'Nc3 e6',
    'Nf3 Bg4',
    'Qd2 Bxf3',
    'gxf3 Qh4+',
    'Bf2 Qh5',
    'c5 Qxf3',
    'Rg1 Nd5',
    'Bg2 Qf4',
    'Nxd5 Qxd2+',
    'Kxd2 exd5',
    'Bxd5 Rd8',
    'Bxc6+ bxc6',
    'Kc3 f5',
    'b4 Kd7',
    'a4 Rb8',
    'Kc4 a6',
    'Rge1 Ke6',
    'Rab1 Be7',
    'b5 axb5+',
    'axb5 cxb5+',
    'Rxb5 c6',
    'Rb6 Rxb6',
    'cxb6 Rb8',
    'Rb1 g5',
    'b7 Kd7',
    'd5 cxd5+',
    'Kxd5 Kc7',
    'Bb6+ Kxb7',
    'Bc5+ Kc7',
  ],
  [
    'd4 Nf6',
    'c4 c5',
    'd5 b5',
    'Nf3 g6',
    'cxb5 a6',
    'b6 d6',
    'Nc3 Nbd7',
    'e4 Bg7',
    'a4 Qxb6',
    'Be2 O-O',
    'O-O Rb8',
    'Nd2 Ne8',
    'a5 Qa7',
    'Nc4 Nc7',
    'Na4 Nb5',
    'Nab6 Nxb6',
    'Nxb6 f5',
    'Nxc8 Rbxc8',
    'exf5 gxf5',
    'Bc4 Kh8',
    'Re1 Bf6',
    'Qh5 Nd4',
    'Ra3 Nc2',
    'Rh3 e5',
    'Rf1 Nd4',
    'Bxa6 Rc7',
    'Bd3 e4',
    'Bh6 Rg8',
    'Rg3 Rcc8',
    'Bc4 Be5',
    'a6 f4',
    'Rxg8+ Rxg8',
    'Kh1 Qd7',
    'g3 Nf3',
    'Be2 Nd4',
    'gxf4 Bg7',
    'Bg4 Qe7',
    'Bxg7+ Qxg7',
    'h3 Ne2',
    'Qf5 e3',
    'Qe4 h5',
    'Qxe3 hxg4',
    'Qxe2 Qh6',
    'f3 gxh3',
    'Rg1 Rxg1+',
    'Kxg1 Qxf4',
    'a7 Qd4+',
    'Kh1 Qa4',
    'Qe7 Qd1+',
    'Kh2 Qc2+',
    'Kxh3 Qf5+',
    'Kg3 Qg6+',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Ba7',
    'Qg4 Nf6',
    'Qg3 d6',
    'Nc3 Nc6',
    'O-O Ne5',
    'Kh1 O-O',
    'Bg5 Ng6',
    'Rad1 Nh5',
    'Qg4 Nf6',
    'Qe2 h6',
    'Bxf6 Qxf6',
    'f4 b5',
    'a3 Rb8',
    'Na5 Bb7',
    'f5 Ne5',
    'fxe6 Qxe6',
    'Nxb7 Rxb7',
    'Nd5 Re8',
    'Rde1 Qg4',
    'Qd2 Qg5',
    'Qe2 Qg4',
    'b4 Qxe2',
    'Rxe2 Rc8',
    'h3 Bd4',
    'Rb1 Kf8',
    'Rd2 Nc4',
    'Bxc4 Rxc4',
    'Re2 Rb8',
    'Rb3 Re8',
    'g4 Ba7',
    'Rc3 Rd4',
    'Nc7 Rexe4',
    'Rxe4 Rxe4',
    'Nxa6 Bd4',
    'Rd3 Be5',
    'Nc7 Re1+',
    'Kg2 Re2+',
    'Kf3 Rxc2',
    'Nxb5 Ke7',
    'Ke4 Ke6',
    'Rxd6+ Bxd6',
    'Nd4+ Kd7',
    'Nxc2 g6',
    'Nd4 Bg3',
    'Kd5 h5',
    'gxh5 gxh5',
    'a4 Kc7',
    'Nb5+ Kd7',
  ],
  [
    'd4 g6',
    'c4 Bg7',
    'Nf3 Nf6',
    'Nc3 d5',
    'Bf4 O-O',
    'cxd5 Nxd5',
    'Be5 Bh6',
    'e3 Nxc3',
    'bxc3 Nd7',
    'Bg3 c5',
    'Bd3 Qa5',
    'Qd2 Nf6',
    'O-O b6',
    'Bh4 Bb7',
    'Bxf6 exf6',
    'Qb2 Rac8',
    'Rfc1 Rfd8',
    'a4 cxd4',
    'cxd4 Bf8',
    'Qb5 Bb4',
    'Rab1 Bc3',
    'h3 Kg7',
    'Rc2 Bc6',
    'Qxa5 Bxa5',
    'Rbc1 Bd7',
    'Bb5 Rxc2',
    'Rxc2 Rc8',
    'Rxc8 Bxc8',
    'Kf1 a6',
    'Bc4 Kf8',
    'Ke2 Ke7',
    'Kd3 Bd7',
    'Bxa6 Bxa4',
    'e4 Bd7',
    'Bc4 b5',
    'Bb3 Bc7',
    'Nd2 h5',
    'Nb1 b4',
    'Kc4 Bb6',
    'Nd2 Bc8',
    'Kd3 g5',
    'Nf1 f5',
    'Ne3 fxe4+',
    'Kxe4 Ba5',
    'g4 hxg4',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Be3 e6',
    'Nd2 Nd7',
    'f4 Ne7',
    'Ngf3 c5',
    'dxc5 Nc6',
    'Nb3 Nxc5',
    'Nxc5 Qb6',
    'Bd3 Bxd3',
    'Nxd3 Qxe3+',
    'Qe2 Bc5',
    'Qxe3 Bxe3',
    'Ke2 Bb6',
    'Rac1 Ke7',
    'c3 Rac8',
    'Rhe1 a6',
    'Kf1 g6',
    'g3 Na5',
    'b3 Rc7',
    'Ke2 Rhc8',
    'Kd2 Nc6',
    'Rf1 Nb8',
    'g4 Nd7',
    'Nd4 Nc5',
    'Nxc5 Bxc5',
    'Ne2 f6',
    'Rf3 Rf8',
    'Rh3 Rf7',
    'exf6+ Kxf6',
    'Rf1 Ke7',
    'f5 gxf5',
    'gxf5 e5',
    'c4 dxc4',
    'bxc4 Bb4+',
    'Kd3 Kf6',
    'Rh6+ Kg5',
    'Rb6 Bc5',
    'Re6 Rxf5',
    'Re1 Bf2',
    'Rb1 Rf3+',
    'Kd2 Kf5',
    'Re8 Be3+',
    'Ke1 Bf4',
    'Rb8 Rxc4',
    'R8xb7 Rh3',
    'Nxf4 Kxf4',
    'Rf7+ Ke4',
    'Rf2 Rhc3',
    'Re2+ Re3',
    'Rxe3+ Kxe3',
    'Rb3+ Kd4',
    'Ra3 Rc2',
    'Rxa6 Rxh2',
    'a4 Ke3',
    'Kf1 e4',
    'a5 Rf2+',
    'Kg1 Ra2',
    'Ra8 Ke2',
    'a6 e3',
    'a7 Ra6',
    'Kg2 Rg6+',
    'Kh3 Rg7',
    'Kh4 Rd7',
    'Kg3 Kd2',
    'Kf3 e2',
    'Rb8 Rf7+',
    'Kg2 Rxa7',
    'Rd8+ Ke1',
    'Rb8 Rg7+',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'dxe5 dxe5',
    'Qxd8 Rxd8',
    'Bg5 Nbd7',
    'O-O-O Rf8',
    'Nd5 c6',
    'Ne7+ Kh8',
    'Be3 Re8',
    'Nxc8 Raxc8',
    'g4 Nf8',
    'Nd2 Ne6',
    'g5 Nd7',
    'Nb3 Rc7',
    'Bg4 Bf8',
    'Bxe6 Rxe6',
    'Rd3 c5',
    'Nd2 f5',
    'f3 f4',
    'Bf2 h6',
    'gxh6 g5',
    'Nb1 Rxh6',
    'Nc3 b6',
    'Kd2 Rb7',
    'Ke2 Nf6',
    'Rd8 Kg7',
    'Nd5 Be7',
    'Rc8 Rh3',
    'Rg1 Nxd5',
    'exd5 Rxh2',
    'd6 Bf6',
    'Rc7+ Rxc7',
    'dxc7 Rh8',
    'Kd3 Rc8',
    'Ke4 Rxc7',
    'Be1 Kg6',
    'Rh1 Rd7',
    'Bc3 g4',
    'fxg4 Kg5',
    'a4 a5',
    'Rh5+ Kxg4',
    'Rf5 Bg7',
  ],
  [
    'd4 g6',
    'e4 Bg7',
    'Nc3 d6',
    'Bg5 c6',
    'Qd2 Qa5',
    'f4 b5',
    'Nf3 Bg4',
    'Be2 b4',
    'Nd1 d5',
    'e5 e6',
    'a3 Bf8',
    'O-O h6',
    'Bh4 c5',
    'Ne3 Bxf3',
    'axb4 Qxb4',
    'Qxb4 cxb4',
    'Bb5+ Nd7',
    'Rxf3 a5',
    'Be1 Rd8',
  ],
  [
    'd4 Nf6',
    'c4 a6',
    'Nc3 c5',
    'd5 b5',
    'e3 bxc4',
    'Bxc4 g6',
    'e4 d6',
    'Nge2 Bg7',
    'O-O Nbd7',
    'f4 O-O',
    'Ng3 Rb8',
    'Re1 Ne8',
    'Qc2 Nc7',
    'Be3 Nb5',
    'Nxb5 axb5',
    'Be2 Nb6',
    'Bf2 Qc7',
    'Rad1 Nc4',
    'Bxc4 bxc4',
    'e5 dxe5',
    'fxe5 Bxe5',
    'Qxc4 Bd6',
    'Qc3 Bd7',
    'h4 f5',
    'h5 Qb6',
    'Rc1 Qxb2',
    'Bxc5 Qxc3',
    'Rxc3 Rbc8',
    'Bxd6 Rxc3',
    'Rxe7 Rf7',
    'Re2 Rd3',
    'hxg6 hxg6',
    'Rb2 g5',
    'Rb8+ Kh7',
    'Be5 Rxd5',
    'Bb2 Rb5',
    'Rh8+ Kg6',
    'Rg8+ Kh6',
    'Bd4 Rd5',
    'Bb2 Rd1+',
    'Kh2 f4',
    'Ne4 g4',
  ],
  [
    'd4 d5',
    'c4 dxc4',
    'Nf3 Nf6',
    'Qa4+ Nc6',
    'Nc3 Nd5',
    'Ne5 Nb6',
    'Nxc6 Qd6',
    'Qc2 Qxc6',
    'e4 e5',
    'dxe5 Bc5',
    'Be2 O-O',
    'O-O Re8',
    'Bf4 Bd4',
    'Rad1 Qc5',
    'Kh1 Bxe5',
    'Be3 Qa5',
    'f4 Bxc3',
    'bxc3 Qa4',
    'Qb1 Bd7',
    'Bf3 Bc6',
    'Bd4 Qb5',
    'Qc2 Qa4',
    'Qf2 Bxe4',
    'Qg3 g6',
    'Bxe4 Rxe4',
    'f5 c5',
    'fxg6 hxg6',
    'Qf3 f5',
    'Bxc5 Rae8',
    'Bd4 R8e7',
    'Qh3 Rh7',
    'Qg3 Kf7',
    'Qg5 Qc6',
    'Rxf5+ gxf5',
    'Qxf5+ Kg8',
    'Qg5+ Kf8',
    'Rf1+ Ke8',
    'Qg8+ Kd7',
    'Qxh7+ Re7',
    'Rf7 Nd5',
    'h4 Qe6',
    'Rxe7+ Nxe7',
    'Kh2 Kc6',
    'h5 Nf5',
    'Qh8 Nh6',
    'Qf8 b6',
    'Bg7 Nf5',
    'Qf6 Nxg7',
    'Qxg7 Kb5',
    'h6 Qd6+',
    'Kh3 Qe6+',
    'Kh4 Qe1+',
    'Kg4 Qe4+',
    'Kh5 Qe8+',
    'Kg5 Qe3+',
    'Kf5 Ka6',
    'h7 Qf2+',
    'Ke6 Qe2+',
    'Kd6 Qd3+',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nd2 dxe4',
    'Nxe4 Nf6',
    'Nxf6+ gxf6',
    'Nf3 b6',
    'Bb5+ c6',
    'Bc4 Bb7',
    'Bf4 Bd6',
    'Bg3 Qe7',
    'O-O Nd7',
    'Re1 O-O-O',
    'a4 a5',
    'c3 Nf8',
    'Qb3 Bc7',
    'Bf1 h5',
    'Nh4 Rg8',
    'Nf5 Qd7',
    'Ne3 Kb8',
    'Rad1 f5',
    'Nc4 Ka7',
    'Bh4 Rc8',
    'Ne5 Qe8',
    'c4 c5',
    'f3 cxd4',
    'Bf2 f6',
    'Nd3 Qc6',
    'Bxd4 Qd6',
    'Qc3 Bxf3',
  ],
  [
    'd4 f5',
    'g3 g6',
    'Bg2 Nf6',
    'c4 Bg7',
    'Nf3 O-O',
    'O-O d6',
    'b3 h6',
    'Bb2 Qe8',
    'Nbd2 g5',
    'e3 c6',
    'Re1 Nh7',
    'e4 f4',
    'e5 d5',
    'Rc1 Be6',
    'Ba3 Na6',
    'Bf1 Rf7',
    'Bd3 Nf8',
    'Kg2 Qd7',
    'cxd5 Bxd5',
    'Be4 fxg3',
    'fxg3 Ne6',
    'h3 Raf8',
    'Qe2 Nac7',
    'Rf1 h5',
    'Bb2 h4',
    'Nh2 hxg3',
    'Kxg3 Nf4',
    'Qg4 Qxg4+',
    'Kxg4 Bxe4',
    'Nxe4 Nd3',
    'Rxf7 Rxf7',
    'Nxg5 Rf2',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 g6',
    'Bc4 Nb6',
    'Bb3 Bg7',
    'O-O O-O',
    'h3 Nc6',
    'Qe2 Na5',
    'Bg5 d5',
    'c3 f6',
    'Bf4 g5',
    'Bg3 Nxb3',
    'axb3 Bf5',
    'Nh2 c6',
    'Nd2 Qe8',
    'exf6 exf6',
    'Qxe8 Rfxe8',
    'f4 Bd3',
    'Rf2 h6',
    'fxg5 hxg5',
    'h4 gxh4',
    'Bxh4 Re6',
    'Ng4 Nd7',
    'Rf3 Be2',
    'Rg3 Kf7',
    'Nf3 Rh8',
    'Bg5 a6',
    'Kf2 c5',
    'Be3 Bxf3',
    'Rxf3 Re4',
    'Kg3 Bf8',
    'Bf4 cxd4',
    'cxd4 Rxd4',
    'Ne5+ Ke6',
    'Nxd7 Kxd7',
    'Rc1 Rxf4',
    'Rxf4 Bd6',
    'Kf3 Bxf4',
    'Kxf4 Rc8',
    'Rd1 Rc2',
    'Rxd5+ Kc6',
    'Rf5 Rxg2',
    'Ke4 Rxb2',
    'Rf3 Kc5',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 d5',
    'g3 Bb4+',
    'Nbd2 O-O',
    'Bg2 b6',
    'a3 Be7',
    'b4 Bb7',
    'c5 a5',
    'Bb2 axb4',
    'axb4 Rxa1',
    'Bxa1 Ba6',
    'O-O Bb5',
    'Nb1 Qc8',
    'Nc3 Qa6',
    'Ne5 Nc6',
    'Nxb5 Qxb5',
    'Nxc6 Qxc6',
    'Qd3 b5',
    'f3 Ra8',
    'e4 Ra4',
    'Bc3 Qa6',
    'e5 Nd7',
    'f4 f5',
    'g4 g6',
    'gxf5 gxf5',
    'Kh1 Nf8',
    'Bf3 Ng6',
    'Rg1 Ra2',
    'Bh5 Qa4',
    'Bxg6 hxg6',
    'Rxg6+ Kf7',
    'Rg2 Qb3',
    'Rxa2 Qxa2',
    'Bd2 Qa1+',
    'Kg2 Qd1',
    'h3 Bh4',
    'Qe3 c6',
    'Bc3 Qc2+',
    'Kf1 Qd1+',
  ],
  [
    'Nf3 f5',
    'c4 Nf6',
    'g3 d6',
    'Bg2 g6',
    'O-O Bg7',
    'Nc3 Nc6',
    'Rb1 a5',
    'a3 e5',
    'd3 O-O',
    'b4 axb4',
    'axb4 h6',
    'b5 Ne7',
    'c5 Be6',
    'b6 dxc5',
    'bxc7 Qxc7',
    'Nb5 Qd8',
    'Nxe5 Ba2',
    'Ra1 Nfd5',
    'Ba3 Ra5',
    'Rxa2 Rxb5',
    'd4 Nc3',
    'Qd3 Qxd4',
    'Qxd4 cxd4',
    'Bxe7 Re8',
    'Rd2 Rxe5',
    'Bd6 R5e6',
    'Bb4 Nxe2+',
    'Kh1 Rb6',
    'Ba3 Rb5',
    'h4 g5',
    'Kh2 gxh4',
    'Re1 hxg3+',
    'fxg3 Rbe5',
    'Bd6 Re3',
    'Bxb7 Nxg3',
    'Rg1 Ne2',
    'Rg2 Kh7',
    'Ba6 Nc3',
    'Bf4 Rf3',
    'Bg3 Ne4',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Ne7',
    'O-O Nbc6',
    'Nxc6 Nxc6',
    'f4 d6',
    'Nd2 g6',
    'Nf3 Bg7',
    'c3 O-O',
    'Qe2 Re8',
    'Qf2 Qc7',
    'Nh4 b5',
    'Be3 Bb7',
    'Rad1 Qe7',
    'Bb6 Bf6',
    'Nf3 e5',
    'g3 Bg7',
    'Bb1 Nd8',
    'f5 Bf8',
    'Nh4 Bc6',
    'a3 Qb7',
    'Ba2 g5',
    'Ng6 Bxe4',
    'Nxf8 d5',
    'Qe3 h6',
  ],
  ['Nf3 d5', 'd4 Nf6', 'c4 c6', 'e3 a6', 'Bd3 Bg4'],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bg5 Ne4',
    'Bf4 Nxc3',
    'bxc3 dxc4',
    'e3 Be6',
    'Qb1 Qc8',
    'Ng5 Bd7',
    'Bxc4 e6',
    'h4 h6',
    'Ne4 Bc6',
    'O-O Nd7',
    'Bb5 Bxe4',
    'Qxe4 c6',
    'Be2 O-O',
    'Bd6 Re8',
    'Qf3 Qd8',
    'Rab1 Nb6',
    'Bg3 e5',
    'h5 g5',
    'Bd3 Qd5',
    'Be4 Qe6',
    'Qf5 Qxf5',
    'Bxf5 Re7',
    'Rb4 Rd8',
    'Rfb1 Rd5',
    'c4 Rd8',
    'c5 Nd5',
    'Rxb7 Rxb7',
    'Rxb7 exd4',
    'exd4 Bxd4',
    'Bd6 Re8',
    'Kf1 Bc3',
    'Rb8 Rxb8',
    'Bxb8 a5',
    'Bd7 Nb4',
    'a3 Nd3',
    'Bxc6 Bd4',
    'Bb5 Nxc5',
    'Bc7 Bc3',
    'Bb6 Ne6',
    'g3 Kg7',
    'Bd7 Nd4',
    'Bd8 Bb2',
    'a4 Bc3',
    'Kg2 Bb4',
    'Bb6 Bc3',
    'f4 gxf4',
    'gxf4 Nb3',
    'Kf3 Bb4',
    'Bb5 Nc5',
    'Ke3 Kf6',
    'Kd4 Ne6+',
    'Ke4 Nc5+',
    'Kd5 Ne6',
    'Be3 Be1',
    'Bd3 Bg3',
    'Ke4 Be1',
    'f5 Nf8',
    'Bxh6 Nd7',
    'Be3 Bc3',
    'h6 Ne5',
    'Be2 Nd7',
    'Bd4+ Bxd4',
    'Kxd4 Nf8',
    'Kc5 Kg5',
    'Kb5 Kxh6',
    'Kxa5 Kg5',
    'Bd3 Kf6',
    'Kb5 Ke5',
  ],
  [
    'e4 e5',
    'Nf3 Nf6',
    'Nc3 Nc6',
    'd4 exd4',
    'Nxd4 Bb4',
    'Nxc6 bxc6',
    'Bd3 d5',
    'exd5 cxd5',
    'O-O O-O',
    'Bg5 c6',
    'Na4 h6',
    'Bh4 Bd6',
    'c3 Bf4',
    'Bc2 Qd6',
    'g3 Bg5',
    'Bxg5 hxg5',
    'f4 g4',
    'Qd4 Ba6',
    'Rfe1 Rfe8',
    'Nc5 Be2',
    'Kf2 Bf3',
    'Re5 Nd7',
    'Nxd7 Qxd7',
    'Rae1 Rxe5',
    'Qxe5 Qd8',
    'Kf1 Qb8',
    'Qe3 Be4',
    'Bxe4 dxe4',
    'Re2 f5',
    'Qc5 Qb5',
    'Qxb5 cxb5',
    'Rd2 g6',
    'Rd6 Kg7',
    'Ra6 Rh8',
    'Rxa7+ Kf6',
    'Rb7 Rxh2',
    'Rxb5 e3',
    'a4 Rf2+',
    'Ke1 Rg2',
    'a5 Rxg3',
    'a6 Rg1+',
    'Ke2 Ra1',
    'Rb6+ Kg7',
    'Kxe3 g5',
    'fxg5 g3',
    'Kf3 f4',
    'a7 Rxa7',
    'Rb4 Rf7',
    'Rd4 Rf5',
    'b4 Rxg5',
    'Rd2 Kf6',
    'c4 Ke7',
    'c5 Rg6',
    'Rg2 Rf6',
    'Rd2 Rg6',
    'Re2+ Kd8',
    'Rg2 Rf6',
    'Rd2+ Kc8',
    'Ra2 Rg6',
    'Rd2 Rg5',
    'Rg2 Rd5',
    'Ke4 Rd1',
    'b5 Re1+',
    'Kd5 f3',
    'Rxg3 f2',
    'Rg8+ Kb7',
    'Rg7+ Kc8',
    'Rg8+ Kb7',
    'c6+ Kb6',
    'Rb8+ Kc7',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 e5',
    'Bc4 Be7',
    'd3 Nf6',
    'O-O O-O',
    'Ng5 h6',
    'f4 exf4',
    'Nh3 Ne5',
    'Nxf4 Nxc4',
    'dxc4 d6',
    'b3 Ng4',
    'Nfd5 Bg5',
    'Qe1 Bxc1',
    'Rxc1 Be6',
    'Qg3 Qg5',
    'Rcd1 Rad8',
    'h3 Ne5',
    'Qxg5 hxg5',
    'Nb5 Nc6',
    'Ndc7 Bc8',
    'c3 g4',
    'hxg4 Bxg4',
    'Rxd6 a6',
    'Rxd8 Rxd8',
    'Na3 Rd2',
    'Rc1 Rxa2',
    'Nc2 Ne5',
    'Ne3 Be2',
    'Ncd5 Bd3',
    'Rd1 Bxe4',
    'Ne7+ Kh7',
    'Kh2 g6',
    'Kg3 Nd3',
    'N7d5 Kg7',
    'Nf4 Nxf4',
    'Kxf4 Bxg2',
    'Nxg2 Rxg2',
    'Rd6 Rf2+',
    'Ke4 Rf6',
    'Rd1 Rb6',
    'Rb1 Rd6',
    'Ke5 Rd3',
    'b4 cxb4',
    'cxb4 f6+',
    'Ke6 Re3+',
    'Kd6 Kf7',
    'Rh1 Re6+',
    'Kc7 Rc6+',
    'Kxb7 Rxc4',
    'Rb1 f5',
    'Kxa6 f4',
    'b5 Ra4+',
    'Kb7 Ke6',
    'b6 g5',
    'Kb8 f3',
    'b7 Rf4',
    'Re1+ Kf5',
    'Rb1 Rc4',
    'Ka8 Ra4+',
    'Kb8 Rc4',
    'Ka8 Ra4+',
    'Kb8 Re4',
    'Kc7 Re8',
    'Rd1 g4',
    'Rd8 Rxd8',
    'Kxd8 f2',
    'b8=Q f1=Q',
    'Qc8+ Kf4',
    'Qc7+ Kf3',
    'Qf7+ Kg2',
    'Qd5+ Qf3',
    'Qd2+ Kf1',
    'Qc1+ Ke2',
    'Qc2+ Ke1',
    'Kc7 g3',
    'Qc1+ Kf2',
    'Qd2+ Qe2',
    'Qf4+ Kg2',
    'Kd6 Qa6+',
    'Kc5 Qa5+',
    'Kc6 Qc3+',
    'Kd6 Kg1',
    'Ke6 g2',
    'Kf5 Qh3+',
    'Kg5 Kh1',
    'Qe4 Qg3+',
    'Kf5 Kg1',
    'Qb1+ Kh2',
    'Qb2 Qh3+',
    'Kf4 Qg3+',
    'Kf5 Qd3+',
    'Kf4 Qd6+',
    'Kg4 Qd7+',
    'Kf4 Qc7+',
    'Kg4 Qc8+',
    'Kf3 Qa8+',
    'Kf4 Kh1',
    'Qb5 g1=Q',
    'Qd5+ Qg2',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'c4 Nb6',
    'exd6 cxd6',
    'Nc3 g6',
    'Be3 Bg7',
    'Be2 Nc6',
    'Rc1 O-O',
    'b3 Bf5',
    'Nf3 d5',
    'O-O Bg4',
    'c5 Nc8',
    'b4 a6',
    'Rb1 e6',
    'a4 Bxf3',
    'Bxf3 N8e7',
    'b5 Na5',
    'Bg5 h6',
    'Bxe7 Qxe7',
    'Qd3 Rfd8',
    'bxa6 Rxa6',
    'Nb5 Nc6',
    'Nd6 Ra7',
    'Rb6 Bxd4',
    'Rxc6 Rxa4',
    'Nb5 bxc6',
    'Nxd4 Rc8',
    'g3 e5',
    'Nb3 Qf6',
    'Bg2 Ra3',
    'Qc2 Rb8',
    'Nd2 e4',
    'Qc1 Ra2',
    'Bxe4 Qd4',
    'Bxd5 Qxd5',
    'Nc4 Qxc5',
    'Qc3 Rb4',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nf3 c6',
    'e3 f5',
    'Bd3 Nf6',
    'O-O Bd6',
    'b3 Qe7',
    'Bb2 O-O',
    'Ne5 Nfd7',
    'Qc1 Nxe5',
    'dxe5 Bc5',
    'Nd2 Nd7',
    'Kh1 b6',
    'a3 a5',
    'Bc3 Bb7',
    'Qb2 Qh4',
    'b4 axb4',
    'axb4 Be7',
    'cxd5 cxd5',
    'Nf3 Qh5',
    'Be2 Qf7',
    'Bb5 Nb8',
    'Rxa8 Bxa8',
    'Nd4 Rc8',
    'Rc1 Rc4',
    'Qa1 Bc6',
    'Bxc4 dxc4',
    'Nxc6 Nxc6',
    'Qa8+ Nd8',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 Nb6',
    'Be2 g6',
    'exd6 cxd6',
    'O-O Bg7',
    'c3 O-O',
    'Na3 d5',
    'h3 Nc6',
    'Nc2 f6',
    'a4 a5',
    'Be3 Be6',
    'Na3 Bf7',
    'Qb3 Re8',
    'Rad1 Rc8',
    'Rfe1 h6',
    'h4 Qc7',
    'Qc2 Nd7',
    'Nb5 Qb8',
    'Qd2 Kh7',
    'Bf4 e5',
    'Bg3 Re7',
    'h5 g5',
    'dxe5 fxe5',
    'Nfd4 Nf6',
    'Nf5 Rd7',
    'Bd3 Kh8',
    'Nxg7 Kxg7',
    'Bf5 Re7',
    'Bxc8 Qxc8',
    'Nd6 Qe6',
    'Nxf7 Qxf7',
    'b4 e4',
    'b5 Ne5',
    'Bxe5 Rxe5',
    'Qd4 Rf5',
    'c4 Qxh5',
    'cxd5 Kg6',
    'd6 Ng4',
    'Qxe4 Qh2+',
    'Kf1 Nf6',
    'Qxb7 Qh1+',
    'Ke2 Qh4',
    'f4 Rxf4',
    'Rf1 Qg4+',
    'Kd2 Rd4+',
    'Ke3 Re4+',
    'Kd3 Qe2+',
  ],
  [
    'c4 Nf6',
    'Nc3 e6',
    'e4 d5',
    'e5 d4',
    'exf6 dxc3',
    'bxc3 Qxf6',
    'Nf3 c5',
    'Bd3 Bd6',
    'Bc2 e5',
    'O-O Nc6',
    'Re1 Bf5',
    'd4 O-O',
    'dxe5 Bxe5',
    'Bg5 Bxc2',
    'Qxc2 Qd6',
    'Rad1 Qc7',
    'Rd5 Rae8',
    'g3 f6',
    'Be3 b6',
    'Red1 Qf7',
    'Nh4 Ne7',
    'Rd7 Qxc4',
    'Rxa7 Nd5',
    'Bh6 Re7',
    'Ra4 Nb4',
    'Qb1 Bxc3',
    'Nf5 Rc7',
    'Bxg7 Rb8',
    'Rc1 b5',
    'Bxf6 Bxf6',
    'Rxc4 bxc4',
    'a3 Nc6',
    'Qe4 c3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'g3 Nc6',
    'Nde2 b6',
    'Bg2 Ba6',
    'O-O Bg7',
    'Re1 O-O',
    'a4 Rc8',
    'h3 Nd7',
    'Nd5 Nc5',
    'Ra3 e6',
    'Ne3 Qe7',
    'Qd2 Nd7',
    'b3 Rfd8',
    'c4 Bb7',
    'Bb2 Bxb2',
    'Qxb2 Nb4',
    'Rd1 Nc5',
    'Nc3 h5',
    'h4 Kh7',
    'Raa1 g5',
    'Qe2 Kh6',
    'Ncd5 Bxd5',
    'exd5 Rd7',
    'dxe6 fxe6',
    'Rd4 Kg6',
    'Be4+ Kh6',
    'Bg2 Kg6',
    'Be4+ Kh6',
    'Bf3 Rg8',
    'Bxh5 gxh4',
    'Rg4 Kh7',
    'Kg2 Rxg4',
    'Qxg4 Qf6',
    'Rh1 Rg7',
    'Qxh4 Qxh4',
    'Rxh4 Kg8',
    'Bd1 a5',
    'Rd4 Rd7',
    'f4 Kf7',
    'f5 exf5',
    'Nxf5 Ke6',
    'g4 Nbd3',
    'Kf3 Nc1',
    'Rd2 N5xb3',
    'Bxb3 Nxb3',
    'Re2+ Kf6',
    'Rb2 Nc5',
    'Rxb6 Ke6',
    'Nd4+ Ke5',
    'Nc6+ Ke6',
    'Nd4+ Ke5',
    'Nc6+ Ke6',
    'Rb5 Nxa4',
    'Nd4+ Kf7',
    'Rf5+ Kg8',
    'Nb3 Nb6',
    'Nxa5 Rc7',
    'Rf6 Nxc4',
    'Nxc4 Rxc4',
    'Rxd6 Kg7',
    'Kg3 Rc5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 a6',
    'Bd3 Bc5',
    'Nb3 Be7',
    'Be3 d5',
    'exd5 Qxd5',
    'Nc3 Qxg2',
    'Be4 Qh3',
    'Qd4 Nf6',
    'O-O-O Nbd7',
    'Rhg1 g6',
    'Rg3 Qh5',
    'Bg5 h6',
    'Bf3 Qxh2',
    'Be3 e5',
    'Qa4 e4',
    'Nxe4 Nxe4',
    'Bxe4 Qh4',
    'Nc5 b5',
    'Qd4 Bf6',
    'Qd5 Nxc5',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 a6',
    'Qd2 Nbd7',
    'Be2 b6',
    'Nd5 Nxd5',
    'exd5 Bb7',
    'c4 O-O',
    'O-O Re8',
    'Rac1 e5',
    'dxe6 fxe6',
    'Rfd1 Ne5',
    'Nb3 Nf7',
    'Bd4 Bh6',
    'Be3 Bg7',
    'Rc2 Rb8',
    'Kh1 Ba8',
    'Nd4 e5',
    'Nb3 a5',
    'Bd3 a4',
    'Nc1 e4',
    'Bxe4 Bxe4',
    'fxe4 Rxe4',
    'Bg1 Qc7',
    'b3 Rbe8',
    'Qd3 axb3',
    'axb3 Nh6',
    'Re2 Qc6',
    'Rxe4 Rxe4',
    'Bxb6 Nf5',
    'Bg1 Be5',
    'Qf3 Nh4',
    'Qh3 Nf5',
    'Bf2 Qe8',
    'Qf3 Bd4',
    'Nd3 Bxf2',
    'Qxf2 Re3',
    'Re1 Qe4',
    'Rxe3 Nxe3',
    'Qe2 Qd4',
    'h3 h5',
    'g3 Qe4+',
    'Kg1 Qd4',
    'Kh2 Qe4',
    'Kg1 Qd4',
    'Kh2 Qe4',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 Bxc3+',
    'bxc3 d6',
    'Bd3 e5',
    'Ne2 e4',
    'Bc2 Be6',
    'Ng3 Bxc4',
    'Nxe4 Nxe4',
    'Bxe4 d5',
    'Bc2 O-O',
    'Qh5 g6',
    'Qh6 Qf6',
    'h4 Qg7',
    'Qxg7+ Kxg7',
    'Bb3 Bxb3',
    'axb3 Nd7',
    'c4 f5',
    'Ke2 Nf6',
    'Kd3 c6',
    'f3 Rfe8',
    'Bd2 h5',
    'Be1 Kf7',
    'Bg3 a6',
    'Bf4 Rec8',
    'Rhc1 Ke6',
    'Rc2 Rd8',
    'Ra5 Rd7',
    'Bg5 Kf7',
    'Rc1 Re8',
    'Bf4 Rdd8',
    'Be5 Rd7',
    'Ke2 Red8',
    'Rf1 Re7',
    'Kf2 Rde8',
    'Ke2 Nd7',
    'Bf4 Nf6',
    'Kd3 Re6',
    'Bg5 Rd6',
    'cxd5 Rxd5',
    'Rxd5 Nxd5',
    'e4 Nc7',
    'Bf4 Ne6',
    'Be3 Ke7',
    'Kc4 Kd6',
    'Rd1 fxe4',
    'fxe4 Rf8',
    'Kb4 Kc7',
    'Rc1 Re8',
    'Kc4 Kd7',
    'Rf1 Rf8',
    'Rxf8 Nxf8',
    'd5 cxd5+',
    'Kxd5 Ne6',
    'Ke5 Ke7',
    'g3 Nc7',
    'Bg5+ Kf7',
    'Kd6 Ne6',
    'Be3 Kf6',
    'b4 Kf7',
    'e5 b5',
    'Kc6 Ke7',
    'Kb6 Kd7',
    'Kxa6 Kc6',
    'Ka7 Nc7',
    'Kb8 Na6+',
    'Kc8 Nxb4',
    'e6 Nd5',
    'Bf4 b4',
    'Kd8 Nf6',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 Nb6',
    'Be2 g6',
    'O-O Bg7',
    'b3 Nc6',
    'Bb2 Bg4',
    'Nbd2 O-O',
    'h3 Bf5',
    'a4 a5',
    'Re1 Nb4',
    'Rc1 d5',
    'Nf1 f6',
    'Ne3 Bc8',
    'c3 Nc6',
    'c4 Nb4',
    'exf6 exf6',
    'Qd2 Re8',
    'Bf1 c6',
    'Ba3 Be6',
    'Bxb4 axb4',
    'Qxb4 Qc7',
    'a5 Nd7',
    'cxd5 Bf7',
    'Bc4 Bf8',
    'Qd2 cxd5',
    'Bxd5 Qxa5',
    'Bxf7+ Kxf7',
    'Qxa5 Rxa5',
    'Nc4 Rxe1+',
    'Rxe1 Rb5',
    'Re3 Nb8',
    'Kf1 Nc6',
    'Ke2 Rd5',
    'Rd3 Be7',
    'Ne3 Rd8',
    'd5 Nb4',
    'Rd4 Bc5',
    'Rc4 b6',
    'Nd4 Nxd5',
    'Nxd5 Rxd5',
    'Nc2 Re5+',
    'Ne3 b5',
    'Rc3 g5',
    'g4 Ke6',
    'Kf3 Kd6',
    'Nf5+ Kd5',
    'Rd3+ Kc6',
    'Rc3 Kb6',
    'Rd3 Ka5',
    'Rd7 Kb4',
    'Rd3 Re6',
    'Ne3 Bxe3',
    'fxe3 Ka3',
    'e4 b4',
    'Ke3 h6',
    'Kf3 Kb2',
    'Ke3 Kc2',
    'Rd8 Kxb3',
    'Kd3 Kb2',
    'Rb8 b3',
    'Rb7 Ka2',
    'Kc3 Rc6+',
    'Kd2 b2',
    'Ra7+ Kb1',
    'Ra4 Rd6+',
    'Kc3 Kc1',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 g6',
    'Be2 Bg7',
    'O-O O-O',
    'b3 c5',
    'dxc5 dxe5',
    'Bb2 Nc6',
    'Bc4 Ndb4',
    'Nbd2 Bg4',
    'a3 Bxf3',
    'gxf3 Na6',
    'b4 Nc7',
    'Re1 a6',
    'Ne4 Qc8',
    'Qd2 Ne6',
    'Kh1 Nf4',
    'Rg1 h6',
    'Rg4 Qf5',
    'Rag1 Rad8',
    'Qe3 Nd4',
    'c3 Nc2',
    'Qc1 Nd3',
    'Qxc2 Qxf3+',
    'R1g2 Nf4',
    'Nd2 Qc6',
    'Rxf4 exf4',
    'h3 Kh7',
    'Be2 Rxd2',
    'Qxd2 f3',
    'Bxf3 Qxf3',
    'h4 Qh3+',
    'Kg1 Qxh4',
    'Qd7 Qe4',
    'Rg3 Qb1+',
    'Kg2 Qxb2',
    'Qxb7 Qe2',
    'Qf3 Qxf3+',
    'Rxf3 e5',
    'c4 e4',
    'Rb3 Bd4',
    'c6 a5',
    'b5 Rc8',
    'a4 f5',
    'Rb1 Kg7',
    'Rd1 Bb6',
    'Rd7+ Kf6',
    'Rb7 Bc5',
    'Rd7 Ke6',
    'Rg7 g5',
    'Rh7 Ke5',
    'Rd7 Ke6',
    'Rh7 f4',
    'Rxh6+ Kf5',
    'Rh7 e3',
    'fxe3 Bxe3',
    'Rd7 g4',
    'Rd5+ Ke4',
    'Rd7 g3',
    'Re7+ Kd4',
    'Kf3 Kxc4',
    'Rxe3 fxe3',
    'b6 Rxc6',
    'b7 Rb6',
    'Kxg3 Rxb7',
    'Kf3 Kd4',
    'Ke2 Rb2+',
    'Kf3 Ra2',
    'Kf4 Rxa4',
    'Kf5 Rc4',
    'Ke6 a4',
    'Kd6 a3',
    'Ke6 a2',
    'Kf5 a1=N',
    'Kf4 e2',
    'Kf3 e1=B',
    'Ke2 Ba5',
    'Kd1 Ke4',
    'Ke2 Rc1',
    'Kf2 Rc3',
    'Ke2 Rd3',
    'Kf2 Rf3+',
    'Ke2 Nb3',
    'Kd1 Nd4',
    'Kc1 Rf2',
    'Kb1 Kd3',
    'Ka1 Rb2',
    'Kxb2 Bb4',
    'Kc1 Bc3',
    'Kd1 Nc2',
    'Kc1 Ne3',
    'Kb1 Bb4',
    'Kc1 Kc3',
    'Kb1 Kb3',
    'Kc1 Ba5',
    'Kb1 Bd2',
    'Ka1 Nc2+',
    'Kb1 Na3+',
  ],
  ['d4 d6', 'e4 Nf6', 'Nc3 e5', 'Nf3 Nbd7', 'Bc4 Be7'],
  [
    'Nf3 f5',
    'c4 g6',
    'g3 Bg7',
    'Bg2 e5',
    'O-O d6',
    'Nc3 Nf6',
    'd3 Nc6',
    'Bg5 h6',
    'Bxf6 Bxf6',
    'Rb1 a5',
    'Nd2 O-O',
    'a3 Be6',
    'b4 axb4',
    'axb4 Rb8',
    'Nd5 Bg7',
    'b5 Ne7',
    'Nxe7+ Qxe7',
    'Nb3 b6',
    'Ra1 h5',
    'Ra7 h4',
    'Nd2 Rf7',
    'Nb1 e4',
    'dxe4 Bxc4',
    'Qc2 Qe6',
    'Nd2 Bxe2',
    'Re1 Bxb5',
    'Rxc7 hxg3',
    'hxg3 Rbf8',
    'Rxf7 Rxf7',
    'Nf3 Qc4',
    'Qb1 Qd3',
    'Qxd3 Bxd3',
    'exf5 Rxf5',
    'Re8+ Rf8',
    'Re6 Rd8',
    'Ng5 Bc4',
    'Rxg6 b5',
    'Bh3 Re8',
    'Be6+ Kf8',
    'Nh7+ Ke7',
    'Bxc4 bxc4',
    'Rxg7+ Ke6',
    'Rc7 d5',
    'Kf1 Kd6',
    'Ra7 c3',
    'Nf6 Re5',
    'Rd7+ Ke6',
    'Rc7 d4',
    'Ng4 Ra5',
    'Ke2 Ra2+',
    'Kd3 Rd2+',
    'Ke4 Kd6',
    'Rc4 c2',
    'Ne5 Rxf2',
    'Rxd4+ Ke6',
    'Nd3 Rd2',
    'Rc4 Kd6',
    'Ke3 Rg2',
    'g4 Kd5',
    'Rc5+ Kd6',
    'Rc3 Kd5',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qa5',
    'b4 Qxb4',
    'Rb1 Qd6',
    'Bc4 Nf6',
    'Nf3 a6',
    'd4 b5',
    'Bb3 Bb7',
    'O-O e6',
    'Re1 Be7',
    'Ng5 Nd5',
    'Nce4 Qd7',
    'Qh5 Bxg5',
    'Nc5 Nf6',
    'Qxg5 Qc6',
    'Bxe6 O-O',
    'Bb3 Nbd7',
    'Nxb7 Qxb7',
    'Qg3 Qc6',
    'Bb2 Nd5',
    'Qf3 N7b6',
    'Ba3 Rfd8',
    'Bc5 Nc4',
    'Bxc4 bxc4',
    'Re5 Qd7',
    'h3 h6',
    'Rbe1 c6',
    'Qe2 c3',
    'Qh5 Rab8',
    'Ba3 Nf4',
    'Qf3 Nd5',
    'Qh5 Nf4',
    'Qf3 Nd5',
    'g3 Qxh3',
    'Qd3 Qd7',
    'Kg2 Re8',
    'Qxa6 Rxe5',
    'Rxe5 Qg4',
    'Bc1 Qxd4',
    'Re2 Qg4',
    'Qd3 Ra8',
    'a3 h5',
    'Re4 Qg6',
    'Qd4 h4',
    'Rg4 Qf6',
    'Qxf6 Nxf6',
    'Rxh4 Nd5',
    'Rc4 Ra6',
    'Kf3 f6',
    'Ke4 Kf7',
    'Kd4 Ke6',
    'Kc5 Kd7',
    'Kd4 Kd6',
    'Kd3 Ra8',
    'Rg4 g5',
    'a4 c5',
    'Ba3 Kc6',
    'Rc4 Ra5',
    'g4 Nb6',
    'Rxc3 Nxa4',
    'Rb3 Nb6',
    'c4 Ra4',
    'Bb2 Rxc4',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'g3 Bg7',
    'Bg2 O-O',
    'O-O d5',
    'cxd5 Nxd5',
    'Nc3 c5',
    'Ng5 Nc7',
    'd3 h6',
    'Nge4 Nd7',
    'Be3 b6',
    'Rc1 e5',
    'Nd6 Rb8',
    'Qa4 a6',
    'Nxc8 Qxc8',
    'b4 Ne6',
    'Nd5 Qd8',
    'Qxa6 Ra8',
    'Qxa8 Qxa8',
    'Ne7+ Kh7',
    'Bxa8 Rxa8',
    'Rc2 cxb4',
    'Nd5 Nd4',
    'Bxd4 exd4',
    'Rb1 Bf8',
    'Nxb4 Bc5',
    'Nd5 Ra5',
    'Kg2 Kg7',
    'Ne7 Bxe7',
    'Rc7 Rd5',
    'a4 Bd8',
    'Rb7 Nc5',
    'Ra7 Re5',
    'Rb5 Rxe2',
    'a5 Nxd3',
    'axb6 Rxf2+',
    'Kg1 Rc2',
    'b7 Bc7',
    'Ra8 Ne5',
    'Rc8 Nd7',
    'Rd5 Nb8',
    'Rxd4 Bb6',
    'Rxc2 Bxd4+',
    'Kg2 Kf6',
    'Rc8 Be5',
    'Kf3 Ke6',
    'h4 h5',
    'g4 Nd7',
    'gxh5 gxh5',
    'Re8+ Kf6',
    'Rh8 Kg6',
    'Rd8 Nb8',
    'Rg8+ Kf6',
    'Rg5 Nd7',
    'Rxh5 Bb8',
    'Rd5 Ne5+',
    'Ke4 Ke6',
    'Rd8 f5+',
    'Ke3 Nd7',
    'h5 f4+',
    'Kf3 Ne5+',
    'Ke4 f3',
    'Rxb8 f2',
    'Re8+ Kf6',
    'b8=Q Nf7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'g4 h6',
    'f3 b5',
    'Qd2 Bb7',
    'O-O-O Nbd7',
    'h4 b4',
    'Na4 Qa5',
    'b3 Nc5',
    'a3 Nxa4',
    'axb4 Qc7',
    'bxa4 d5',
    'e5 Nd7',
    'f4 Nb6',
    'Rh3 Nxa4',
    'c4 dxc4',
    'Qc2 Nb6',
    'Nxe6 fxe6',
    'Bxb6 Qxb6',
    'Qg6+ Ke7',
    'f5 Bd5',
  ],
  [
    'e4 d5',
    'exd5 Qxd5',
    'Nc3 Qd8',
    'd4 Nf6',
    'Bc4 c6',
    'Nf3 Bf5',
    'Qe2 e6',
    'Bd2 Nbd7',
    'O-O-O Nb6',
    'Bb3 a5',
    'a3 Nbd5',
    'Ne5 a4',
    'Ba2 Nxc3',
    'Bxc3 Ne4',
    'Be1 Qg5+',
    'f4 Qxf4+',
    'Kb1 h5',
    'Rf1 Qg5',
    'Nxf7 Kxf7',
    'Qxe4 Kg6',
    'Qe2 Qg4',
    'Rf3 Bd6',
    'Bg3 Be7',
    'h3 Qe4',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'e4 dxe4',
    'Bg5 Bg7',
    'Nge2 O-O',
    'Ng3 h6',
    'Be3 c5',
    'd5 e6',
    'Bxc5 Re8',
    'Be2 exd5',
    'cxd5 Nbd7',
    'Be3 h5',
    'O-O h4',
    'Nh1 Nb6',
    'd6 Bd7',
    'Bg5 h3',
    'Ng3 hxg2',
    'Re1 Bc6',
    'a4 Re6',
    'a5 Rxd6',
    'Qb3 Nbd5',
    'Ncxe4 Rd7',
    'Bc4 Qf8',
    'Rad1 Re8',
    'Bxf6 Nxf6',
    'Ng5 Rxe1+',
    'Rxe1 Nh7',
    'Nxf7 Rxf7',
    'Ne4 Qe7',
    'a6 b6',
    'Bxf7+ Qxf7',
    'Qxf7+ Kxf7',
    'Nd6+ Kf6',
    'Rc1 Bd5',
    'h4 Bf8',
    'Nb5 g5',
    'Rc7 Be7',
    'Nc3 Bf3',
    'h5 g4',
    'Rd7 Ng5',
    'Ne2 Nh3+',
    'Kh2 Bxe2',
    'Kxg2 Nf4+',
    'Kg3 Nxh5+',
    'Kh4 Nf4',
    'Rxa7 Bd6',
    'Rd7 Ng6+',
    'Kh5 g3+',
    'Kh6 gxf2',
    'Rxd6+ Ke5',
    'a7 f1=Q',
    'a8=Q Qh3+',
    'Kxg6 Kxd6',
    'Qd8+ Kc5',
    'Qe7+ Kc6',
    'Qxe2 Qg3+',
    'Kf5 Qd6',
    'Qa6 Qd5+',
    'Kf6 Qd4+',
    'Ke7 Qxb2',
    'Qc4+ Kb7',
    'Qd5+ Ka6',
    'Qa8+ Kb5',
    'Qd5+ Ka4',
    'Kd6 Qb5',
    'Qa2+ Kb4',
    'Qb2+ Ka5',
    'Qa1+ Qa4',
    'Qe5+ b5',
    'Kc6 Qc4+',
    'Kb7 Ka4',
    'Qa1+ Kb4',
    'Qe1+ Qc3',
    'Qb1+ Ka4',
    'Qa2+ Qa3',
    'Qc2+ Qb3',
    'Qc6 Ka3',
    'Qa6+ Qa4',
    'Qf6 b4',
    'Qa1+ Kb3',
    'Qd1+ Ka3',
  ],
  [
    'd4 d6',
    'c4 e5',
    'Nf3 e4',
    'Ng5 f5',
    'Nc3 Be7',
    'Nh3 c6',
    'g3 Nf6',
    'Bg2 O-O',
    'O-O Na6',
    'b3 Nc7',
    'Bb2 Kh8',
    'd5 cxd5',
    'cxd5 Nd7',
    'f3 exf3',
    'exf3 Bf6',
    'Qd2 Ne5',
    'Kh1 Bd7',
    'Rad1 Qe8',
    'Ne2 Qf7',
    'Nef4 Rfe8',
    'Ne6 Nxe6',
    'dxe6 Bxe6',
    'Ng5 Bxg5',
    'Qxg5 Rad8',
    'Bxe5 h6',
    'Qxh6+ Kg8',
    'Qg5 d5',
    'Bd4 b6',
    'Rfe1 Bc8',
    'f4 Bb7',
    'Re5 Rxe5',
    'Bxe5 Re8',
    'h3 Kh7',
    'Kh2 d4',
    'Bxb7 Qxb7',
  ],
  [
    'd4 f5',
    'g3 Nf6',
    'Bg2 g6',
    'Nh3 Bg7',
    'Nf4 O-O',
    'c3 d6',
    'Qb3+ Kh8',
    'Bxb7 Bxb7',
    'Qxb7 Nbd7',
    'Ne6 Qc8',
    'Qxc8 Rfxc8',
    'Na3 c5',
    'dxc5 Nxc5',
    'Nxg7 Kxg7',
    'Nc2 Na4',
    'Nd4 Rab8',
    'Nb3 Nd7',
    'Be3 Nxb2',
    'Bxa7 Ra8',
    'Be3 e5',
    'O-O Nc4',
    'Rfd1 Kf7',
    'Bh6 Ke6',
    'f4 Ndb6',
    'fxe5 dxe5',
    'Rd3 Na4',
    'Nd2 Ncb2',
    'Re3 e4',
    'c4 Nxc4',
    'Nxc4 Rxc4',
    'Rb3 Rc2',
    'Ra3 Rxe2',
    'Rb1 Rc2',
    'Rb4 Rd8',
    'Rb1 Nc3',
    'Ra6+ Kd5',
    'Rb7 e3',
    'Bxe3 Ke4',
    'Bf2 Kf3',
  ],
  [
    'e4 c5',
    'Nf3 Nc6',
    'Nc3 Nf6',
    'Bb5 e5',
    'O-O d6',
    'Bxc6+ bxc6',
    'h3 Be7',
    'd3 O-O',
    'b3 h6',
    'Ne2 Be6',
    'Ng3 Qd7',
    'Nh2 Nh7',
    'f4 exf4',
    'Bxf4 Bf6',
    'Rb1 Bd4+',
    'Kh1 Rae8',
    'Qd2 f6',
    'Nf3 Be5',
    'Kh2 Ng5',
    'Nxe5 dxe5',
    'Be3 Kh8',
    'Qe2 Bxh3',
    'Bxg5 Bg4',
    'Qe3 hxg5',
    'Qxc5 Kg8',
    'Kg1 Kf7',
    'Qe3 Rh8',
    'b4 Rh4',
    'Rb3 Be6',
    'Ra3 Re7',
    'Ra5 Rf4',
    'Ne2 Rxf1+',
    'Kxf1 Qc7',
    'Qc5 Qb6',
    'a3 Qxc5',
    'Rxc5 Bd7',
    'Ra5 Bc8',
    'Rc5 Bd7',
    'a4 Ke8',
    'Ra5 Bc8',
    'b5 Rc7',
    'c4 Kd7',
    'bxc6+ Kxc6',
    'Nc3 a6',
    'Rd5 Rb7',
    'a5 Be6',
    'Rd8 Rb2',
    'Ra8 Kc5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'exd5 exd5',
    'Nf3 c6',
    'Bd3 Bd6',
    'h3 Ne7',
    'O-O h6',
    'Re1 Be6',
    'Nbd2 Nd7',
    'Nf1 Qc7',
    'c3 O-O-O',
    'b4 g5',
    'N3h2 Rdg8',
    'a4 h5',
    'b5 g4',
    'h4 g3',
    'fxg3 Nf5',
    'Bg5 Nxg3',
    'bxc6 Qxc6',
    'Nxg3 Bxg3',
    'Re3 Qc7',
    'Nf1 Bf4',
    'Bxf4 Qxf4',
    'Qf3 Qxh4',
    'a5 Rh6',
    'a6 b6',
    'Qf2 Qxf2+',
    'Kxf2 Nf6',
    'Nh2 Kd7',
    'Rg1 Kd6',
    'Rf3 Rc8',
    'Rc1 Ne4+',
    'Bxe4 dxe4',
    'Rf4 f5',
    'Nf1 Ke7',
    'Ne3 Kf6',
    'g3 Kg5',
    'c4 Bd7',
    'Rh1 b5',
    'cxb5 Rb8',
    'Rb1 Rxa6',
    'd5 Ra2+',
    'Kg1 Ra3',
    'Kf2 Rc8',
    'Rb2 Rcc3',
    'Nf1 Rcb3',
    'Rc2 Rxb5',
    'Rc7 Rxd5',
    'Ne3 Rd2+',
    'Ke1 Rdd3',
    'Rxd7 Rxe3+',
    'Kf2 Rxg3',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nf3 c5',
    'cxd5 exd5',
    'g3 Nc6',
    'Bg2 Nf6',
    'O-O Be7',
    'Nc3 O-O',
    'h3 Re8',
    'b3 Ne4',
    'Bb2 Bf6',
    'e3 cxd4',
    'exd4 Bf5',
    'Kh2 Nxc3',
    'Bxc3 Be4',
    'Qd2 Rc8',
    'Rfd1 h6',
    'Ne1 Bg5',
    'Qb2 h5',
    'h4 Bh6',
    'Bxe4 Rxe4',
    'Ng2 Qf6',
    'Re1 Rce8',
    'Rxe4 Rxe4',
    'Re1 Qf3',
    'Rxe4 Qxe4',
    'Ne1 g6',
    'Qc2 Qxc2',
    'Nxc2 Bg7',
    'Kg2 Kf8',
    'Kf3 Ke7',
    'Ke3 Ke6',
    'Kd3 Ne7',
    'Bd2 Nf5',
    'Be3 Nd6',
    'Ne1 Kf5',
    'Nc2 Bf8',
    'a4 Ne4',
    'Ke2 Ke6',
    'Bc1 a6',
    'Bf4 Kd7',
    'Ne3 Kc6',
    'Be5 f6',
    'Bb8 Bh6',
    'Ng2 b5',
    'Bf4 g5',
    'axb5+ Kxb5',
    'Bb8 gxh4',
    'gxh4 Kb4',
    'Bf4 Bf8',
    'f3 Nc3+',
    'Kd3 Kxb3',
    'Bd2 Bb4',
    'Nf4 a5',
    'Bxc3 Bxc3',
    'Nxd5 Be1',
    'Nb6 a4',
    'Nxa4 Kxa4',
    'Ke4 Bxh4',
    'Kf5 Bf2',
    'd5 Bb6',
    'Kf4 Kb5',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'Nf3 g6',
    'Bc4 Nb6',
    'Bb3 Bg7',
    'exd6 cxd6',
    'O-O O-O',
    'h3 d5',
    'Re1 Nc6',
    'c3 f6',
    'a4 a5',
    'Na3 Kh8',
    'Bf4 Bd7',
    'Nb5 Rc8',
    'Qd2 Be8',
    'h4 Bf7',
    'Rad1 Qd7',
    'Bg3 Rfe8',
    'Re2 Bg8',
    'Rde1 Nc4',
    'Qc1 Bf7',
    'Bc2 Nb6',
    'b3 Nd8',
    'Qd2 Nc6',
    'Bd3 Nd8',
    'Bb1 Nc6',
    'h5 Qg4',
    'h6 Bf8',
    'Nc7 Red8',
    'Ne6 Bxe6',
    'Rxe6 Qh5',
    'Bf4 Na8',
    'Nh2 Nc7',
    'R6e3 g5',
    'Rh3 Qf7',
    'Bg3 e6',
    'Ng4 Bd6',
    'Bxd6 Rxd6',
    'Rf3 f5',
    'Qxg5 Rg8',
    'Qf4 Ne8',
    'Ne5 Qf6',
    'Rfe3 Rf8',
    'g3 Ne7',
    'Nd7 Rxd7',
    'Rxe6 Qf7',
    'Qe5+ Nf6',
    'Rxe7 Rxe7',
    'Qxe7 Qxe7',
    'Rxe7 Ng4',
    'Bxf5 Nxh6',
    'Rxh7+ Kg8',
    'Rxh6 Rxf5',
    'Kg2 Rf7',
    'Rd6 Rc7',
    'Rxd5 Rxc3',
    'Rb5 Rd3',
    'Rxb7 Rxd4',
    'Rb5 Kf7',
  ],
  [
    'c4 e6',
    'Nf3 d5',
    'g3 Nf6',
    'Bg2 Be7',
    'd4 O-O',
    'O-O dxc4',
    'Qc2 a6',
    'a4 Bd7',
    'Rd1 Bc6',
    'Nc3 Bxf3',
    'Bxf3 Nc6',
    'Bxc6 bxc6',
    'Bg5 Rb8',
    'e3 Nd7',
    'Bxe7 Qxe7',
    'Ne4 c5',
    'Qxc4 Rxb2',
    'Qxa6 cxd4',
    'exd4 Nf6',
    'Nc5 Nd5',
    'Rab1 Rfb8',
    'Rxb2 Rxb2',
    'Qc8+ Qf8',
    'Qxf8+ Kxf8',
    'Ra1 Ke7',
    'a5 Rb8',
    'a6 Kd6',
    'Kf1 f6',
    'Ne4+ Ke7',
    'Ke2 Ra8',
    'f4 Nb4',
    'Nc5 Kd6',
    'Ra4 Nc6',
    'Kd3 e5',
    'Ne4+ Ke7',
    'd5 Rxa6',
    'Rc4 Ra3+',
    'Kd2 Ra2+',
    'Kc1 Nd4',
    'fxe5 fxe5',
    'Rxc7+ Kd8',
    'Rxg7 Rxh2',
    'd6 h5',
    'Nf6 Nb3+',
    'Kd1 Rd2+',
    'Ke1 Rxd6',
    'Nxh5 Nd4',
    'Kf2 Ke8',
    'g4 Kf8',
    'Rb7 Ne6',
    'Ke3 Nf4',
    'Ng7 Rg6',
    'Nf5 Nd5+',
    'Kf3 Nf6',
    'Ne3 Rg7',
    'Rb8+ Kf7',
    'Rb5 Rg5',
    'Rb7+ Kg6',
    'Rb6 Kf7',
    'Kg3 Ne4+',
    'Kh4 Rg8',
    'Rh6 Kg7',
    'Nf5+ Kf7',
    'Rh7+ Kg6',
    'Rh5 Kf7',
    'Nh6+ Kg7',
    'Nxg8 Kxg8',
    'Rxe5 Nf6',
    'Re7 Nd5',
    'Ra7 Nf4',
    'g5 Ng6+',
    'Kh5 Ne5',
    'g6 Kf8',
    'Kg5 Kg8',
    'Kf5 Nc6',
    'Rc7 Nb4',
    'Rc5 Nd5',
    'Rxd5 Kg7',
    'Rd7+ Kh6',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 e6',
    'Nc3 Nbd7',
    'Ne5 Bb4',
    'cxd5 exd5',
    'Nd3 Bd6',
    'g3 O-O',
    'Bg2 Qe7',
    'O-O Ne4',
    'f3 Nxc3',
    'bxc3 f5',
    'Qb3 Kh8',
    'Re1 c5',
    'c4 dxc4',
    'Qxc4 Rb8',
    'a4 a6',
    'a5 b5',
    'axb6 Nxb6',
    'Qc2 cxd4',
    'exd4 Qf6',
    'f4 Bd7',
    'Be3 Rbc8',
    'Nc5 Bb5',
    'Bf2 Nd7',
    'Rac1 Bxc5',
    'dxc5 Bc6',
    'Bxc6 Qxc6',
    'Re7 Nf6',
    'Qxf5 Rcd8',
    'Qe6 Qf3',
    'Qe2 Qd5',
    'Qxa6 Ng4',
    'Qe2 Nxf2',
    'Qxf2 Rf6',
    'Qe3 h6',
    'c6 Kh7',
    'c7 Rc8',
  ],
  [
    'd4 f5',
    'g3 Nf6',
    'Bg2 g6',
    'c4 d6',
    'Nf3 Bg7',
    'O-O O-O',
    'Nc3 Nc6',
    'd5 Ne5',
    'Nxe5 dxe5',
    'Qb3 h6',
    'c5 Kh8',
    'Rd1 g5',
    'Qc4 e4',
    'f3 exf3',
    'exf3 e5',
    'dxe6 Qe7',
    'f4 Bxe6',
    'Qb4 a5',
    'Qb5 c6',
    'Qe2 Rad8',
    'Rd6 Rxd6',
    'cxd6 Qxd6',
    'fxg5 Ng4',
    'Bf4 Qc5+',
    'Kh1 Bc4',
    'Qd2 Nf2+',
    'Kg1 Ng4+',
    'Kh1 hxg5',
    'Bxg5 f4',
    'Ne4 Qb6',
    'Bxf4 Rd8',
    'Qc2 Bd3',
    'Qc5 Qxb2',
    'Qh5+ Kg8',
    'Rf1 Bxf1',
    'Bxf1 Qb1',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'e3 e6',
    'Qc2 Nbd7',
    'Nc3 Bd6',
    'g4 h6',
    'Rg1 Qe7',
    'h4 dxc4',
    'e4 e5',
    'g5 Nh5',
    'Bxc4 exd4',
    'Nxd4 Nb6',
    'Be2 Nf4',
    'Be3 h5',
    'O-O-O g6',
    'Kb1 Bd7',
    'Qd2 Nxe2',
    'Ndxe2 Be5',
    'f4 Nc4',
    'Qd3 Nxe3',
    'Qxe3 Bc7',
    'Qd4 Rf8',
    'Rge1 Bb6',
    'Qc4 Qe6',
    'Qxe6+ Bxe6',
    'f5 Bd7',
    'Nf4 Bc7',
    'Nce2 Rg8',
    'Nd3 b6',
    'Nef4 O-O-O',
    'b4 Kb7',
    'a3 Bc8',
    'Kc2 gxf5',
    'e5 Rd4',
    'Re3 Rgd8',
    'Rh1 a5',
    'bxa5 b5',
    'Nxh5 f4',
    'Ndxf4 Bf5+',
    'Kb3 c5',
    'Rc1 c4+',
    'Kb2 Bxa5',
    'Ne2 Rd2+',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'd4 d6',
    'c4 Nb6',
    'exd6 cxd6',
    'd5 N8d7',
    'Be3 g6',
    'Qd2 Nf6',
    'Nc3 Bd7',
    'Bd3 Bg7',
    'Bd4 O-O',
    'Nge2 e5',
    'dxe6 fxe6',
    'O-O e5',
    'Be3 Bc6',
    'Ng3 Kh8',
    'Rad1 Qe8',
    'c5 dxc5',
    'Bxc5 Rf7',
    'Nge4 Rd8',
    'Nxf6 Bxf6',
    'Qe2 Nd5',
    'Nxd5 Rxd5',
    'Bxa7 Rfd7',
    'Bc2 Qa8',
    'Rxd5 Bxd5',
    'Be3 Qxa2',
    'Qb5 Bc6',
    'Qb4 Kg7',
    'Be4 Bxe4',
    'Qxe4 Qxb2',
    'g4 Qc3',
    'g5 Be7',
    'Rc1 Qa5',
    'Bc5 Qc7',
    'Ba3 Qa5',
    'Bc5 Qc7',
    'Ba3 Qa5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Bb4',
    'e5 b6',
    'a3 Bf8',
    'Bb5+ c6',
    'Ba4 Ba6',
    'Nce2 Ne7',
    'h4 h5',
    'Bg5 Qc7',
    'c3 Nd7',
    'Nf4 g6',
    'Nge2 Nf5',
    'Bc2 Be7',
    'Bxf5 exf5',
    'e6 Nf8',
    'Nxd5 cxd5',
    'Qa4+ Kd8',
    'exf7 Ne6',
    'Bxe7+ Kxe7',
    'Qxa6 Kxf7',
    'O-O Rae8',
    'Rae1 Re7',
    'Nc1 Qf4',
    'g3 Qg4',
    'Kh2 f4',
    'Qe2 g5',
    'Nd3 Qf5',
    'hxg5 Ree8',
    'Qe5 Qxd3',
    'Qf6+ Kg8',
    'Rxe6 Rf8',
    'Qg6+ Qxg6',
    'Rxg6+ Kf7',
    'Rf6+ Kg7',
  ],
  [
    'Nf3 f5',
    'b3 d6',
    'd4 g6',
    'Bb2 Bg7',
    'e3 Nf6',
    'Bc4 e6',
    'O-O O-O',
    'Nbd2 Kh8',
    'Qe2 Nc6',
    'Rad1 Qe7',
    'Rfe1 Bd7',
    'a3 Rae8',
    'b4 Nh5',
    'Bb3 g5',
    'Ne5 Nxe5',
    'dxe5 Qf7',
    'Nc4 d5',
    'Nd2 b5',
    'Rf1 g4',
    'Bd4 Rg8',
    'g3 a5',
    'c3 a4',
    'Bc2 Qg6',
    'Bd3 Rb8',
    'Kg2 Bf8',
    'Rh1 Ng7',
    'h3 gxh3+',
    'Rxh3 h5',
    'Rdh1 Be8',
    'Kf1 Be7',
    'Nf3 Qg4',
    'Bc5 Bxc5',
    'bxc5 Rf8',
    'Rh4 Qg6',
    'g4 Qh6',
    'g5 Qg6',
    'Rg1 Kg8',
    'Nd4 Kf7',
    'c6 Rb6',
    'Bxb5 Kg8',
    'f4 Bf7',
    'c4 dxc4',
    'Qxc4 Rd8',
    'Qxa4 Be8',
    'Kf2 Qf7',
    'Rc1 Qe7',
    'Rc3 Rd5',
    'Qc4 Qd8',
    'a4 Rb8',
    'Qb4 Kh7',
    'Kg3 Bg6',
    'Rh2 Be8',
    'Kh4 Kg6',
    'Rd2 Bf7',
    'Rc4 Ra8',
    'Nf3 Rb8',
    'Rcd4 Qe8',
    'Rxd5 exd5',
    'Nd4 Be6',
    'Qc5 Ra8',
    'Ra2 Bf7',
    'Bd3 Qf8',
    'Qxf8 Rxf8',
    'a5 Ra8',
    'a6 Be6',
    'Rb2 Bc8',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 Nf6',
    'e5 Nfd7',
    'f4 c5',
    'Nf3 a6',
    'Be3 Qb6',
    'Na4 Qa5+',
    'c3 cxd4',
    'b4 Qc7',
    'Qxd4 a5',
    'b5 b6',
    'Qd2 Nc5',
    'Nxc5 Bxc5',
    'Bd4 Nd7',
    'Bd3 g6',
    'a4 Bb7',
    'h4 h5',
    'O-O Be7',
    'Qe3 Nc5',
    'g3 Rc8',
    'Nd2 Nxd3',
    'Qxd3 Bc5',
    'Rfc1 Bxd4+',
    'Qxd4 O-O',
    'Nf1 Qd8',
    'Ne3 Rc5',
    'Kf2 Qe7',
    'Ke2 Rfc8',
    'Kd2 Qc7',
    'Rc2 Kf8',
    'Ra3 Qd8',
    'Kc1 Rc4',
    'Nxc4 Rxc4',
    'Qe3 Re4',
    'Qf2 d4',
    'cxd4 Qe7',
    'Rac3 Qb4',
    'd5 Bxd5',
    'Qxb6 Kg7',
    'Qd8 Re1+',
    'Kd2 Rg1',
    'Qf6+ Kg8',
    'Qd8+ Kh7',
    'Qb6 Qb1',
    'Rc1 Qb2+',
    'R1c2 Rg2+',
    'Kd3 Rxg3+',
    'Kd4 Qb4+',
    'Rc4 Bxc4',
    'Rxc4 Qa3',
  ],
  [
    'c4 c5',
    'Nf3 Nc6',
    'Nc3 e5',
    'g3 g6',
    'Bg2 Bg7',
    'a3 a5',
    'O-O Nge7',
    'd3 d6',
    'Ne1 Be6',
    'Nc2 d5',
    'cxd5 Nxd5',
    'Bd2 O-O',
    'Ne3 Nxe3',
    'fxe3 c4',
    'Be1 Ne7',
    'Bf2 Nf5',
    'dxc4 Bxc4',
    'Qa4 b5',
    'Nxb5 Bxe2',
    'Bxa8 Bxf1',
    'Bh1 Bxb5',
    'Qxb5 e4',
    'Re1 Qd2',
    'b4 axb4',
    'Qxb4 Bc3',
    'Qxe4 Qa2',
    'Rd1 Qxa3',
    'g4 Ng7',
    'Qd3 Bb2',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 Nc6',
    'Qd2 O-O',
    'O-O-O Bd7',
    'g4 Rc8',
    'Kb1 Ne5',
    'h3 b5',
    'f4 b4',
    'Nd5 Nxd5',
    'exd5 Nc4',
    'Bxc4 Rxc4',
    'f5 Qa8',
    'b3 Rc5',
    'Bh6 Bxh6',
    'Qxh6 Rxd5',
    'Rhe1 Re5',
    'Rf1 Qg2',
    'Rf4 Rc8',
    'g5 Rxc2',
    'Nxc2 Bxf5',
    'Rc4 d5',
    'Rc7 Re3',
    'Ka1 Qh2',
  ],
  [
    'e4 Nf6',
    'e5 Nd5',
    'c4 Nb6',
    'd4 d6',
    'exd6 cxd6',
    'Be3 g6',
    'd5 Bg7',
    'Bd4 Bxd4',
    'Qxd4 O-O',
    'h4 e5',
    'Qd2 f5',
    'Nf3 Qf6',
    'Nc3 Na6',
    'Qh6 Qg7',
    'Qd2 h6',
    'Nb5 Rf6',
    'Qa5 Nd7',
    'Qa3 Qe7',
    'Be2 e4',
    'Nd2 Ne5',
    'O-O-O Nc5',
    'Kb1 Ncd3',
    'Bxd3 Nxd3',
    'Rhf1 Bd7',
    'Nd4 Qe5',
    'Qc3 b5',
    'cxb5 Rf7',
    'f3 Rc8',
    'Nc4 Qxd5',
    'fxe4 Qxc4',
    'Qxd3 Qxd3+',
    'Rxd3 fxe4',
    'Rxf7 exd3',
    'Rf1 Rc4',
    'Nc6 d2',
    'Ne7+ Kg7',
    'Rd1 Kf7',
    'b3 Rc1+',
  ],
  [
    'e4 e5',
    'Nc3 Nf6',
    'f4 d5',
    'fxe5 Nxe4',
    'Qf3 Nc6',
    'Bb5 Nxc3',
    'dxc3 Qh4+',
    'g3 Qe4+',
    'Qxe4 dxe4',
    'Bf4 h6',
    'Ne2 g5',
    'Be3 Bg7',
    'Nd4 Bd7',
    'e6 fxe6',
    'Nxc6 a6',
    'Bc4 Bxc6',
    'Bxe6 Rd8',
    'O-O Bd5',
    'Bf5 O-O',
    'a3 b6',
    'g4 Rfe8',
    'h3 Bc4',
    'Rfd1 Rxd1+',
    'Rxd1 Be6',
    'Bxe6+ Rxe6',
    'a4 Kf7',
    'a5 b5',
    'Rd8 Be5',
    'Ra8 Rc6',
    'Kf1 b4',
    'cxb4 Rxc2',
    'b5 Bf4',
    'Bg1 e3',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 Nc6',
    'Qd2 O-O',
    'O-O-O d5',
    'exd5 Nxd5',
    'Nxc6 bxc6',
    'Bd4 e5',
    'Bc5 Be6',
    'Ne4 Re8',
    'h4 h6',
    'g4 Qc7',
    'g5 h5',
    'Bc4 Rad8',
    'Qf2 Rd7',
    'Rd3 f5',
    'gxf6 Nxf6',
    'Bxe6+ Rxe6',
    'Rxd7 Qxd7',
    'Rd1 Nxe4',
    'fxe4 Bh6+',
    'Be3 Bxe3+',
    'Qxe3 Qe7',
    'a4 a5',
    'Qb6 Qxh4',
    'Qxa5 Kh7',
    'Qc7+ Kh6',
    'Qc8 Qf4+',
    'Kb1 Rd6',
    'Rxd6 Qf1+',
    'Ka2 Qc4+',
    'Kb1 Qf1+',
    'Ka2 Qc4+',
    'Ka3 Qc5+',
    'Kb3 Qxd6',
    'a5 c5',
    'Qb7 Qd4',
    'a6 c4+',
    'Ka2 Qc5',
    'c3 Qa5+',
    'Kb1 Qd8',
    'Qd5 Qc7',
    'Qb7 Qd8',
    'Qd5 Qc7',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'd4 exd4',
    'Bc4 Nf6',
    'e5 d5',
    'Bb5 Ne4',
    'Nxd4 Bd7',
    'Bxc6 bxc6',
    'O-O c5',
    'Ne2 Bc6',
    'f3 Ng5',
    'f4 Ne4',
    'Nbc3 f5',
    'Nxe4 fxe4',
    'f5 Qe7',
    'Bf4 O-O-O',
    'Qd2 d4',
    'Qa5 c4',
    'Qa6+ Bb7',
    'Qxc4 Qc5',
    'Qxc5 Bxc5',
    'Kh1 Rhe8',
    'Rad1 e3',
    'Ng3 Ba6',
    'Rfe1 Bb4',
    'Rg1 e2',
    'Rc1 Rd5',
    'a3 g5',
    'fxg6 e1=Q',
    'Rgxe1 Bxe1',
    'Rxe1 hxg6',
    'h4 Rc5',
    'Re4 Rxc2',
    'Rxd4 Bb7',
    'Rd2 Rxd2',
    'Bxd2 Rxe5',
    'Bc3 Rc5',
    'Kg1 Rc4',
    'h5 gxh5',
    'Nxh5 Rg4',
    'Kf2 Rxg2+',
    'Ke3 Kd7',
    'Nf6+ Ke7',
    'Ne4 Bxe4',
    'Kxe4 Kd6',
    'Kd4 Rg4+',
    'Kd3 Kd5',
    'Kc2 Kc4',
    'b3+ Kd5',
    'Bf6 Rg6',
    'Bh8 c5',
    'Kc3 Rg3+',
    'Kb2 Rg2+',
    'Kc3 Rg3+',
    'Kb2 c4',
    'bxc4+ Kxc4',
    'Be5 Rb3+',
    'Ka2 Re3',
    'Bd6 Re2+',
    'Kb1 Kb3',
    'Kc1 a5',
    'Kd1 Re8',
    'Bc5 Rc8',
    'Be7 Rc7',
    'Bf8 Kb2',
    'Kd2 Rd7+',
    'Ke3 Kc3',
    'Ke4 Rd8',
    'Bc5 Kc4',
    'Bb6 Re8+',
    'Kf3 a4',
    'Kf2 Kb3',
    'Bc5 Kb2',
    'Kf1 Rc8',
    'Bd6 Rd8',
  ],
  [
    'd4 d5',
    'c4 e5',
    'dxe5 d4',
    'Nf3 Nc6',
    'g3 Nge7',
    'Bg2 Ng6',
    'Qa4 Bd7',
    'O-O Bc5',
    'Bg5 f6',
    'Qb5 Bb6',
    'exf6 gxf6',
    'c5 Nce5',
    'Qb3 Bxc5',
    'Nxe5 Nxe5',
    'Qxb7 Rc8',
    'Qd5 Bb6',
    'e3 Ng4',
    'Bf4 dxe3',
    'Qh5+ Kf8',
    'fxe3 Qe8',
    'Bh6+ Ke7',
    'Qh4 Qg6',
    'Bf4 Be6',
    'Nc3 Rcd8',
    'Kh1 Rd2',
    'e4 Nf2+',
    'Rxf2 Rxf2',
    'e5 Kf7',
    'Rd1 Ba5',
    'Be4 f5',
    'Nd5 Re8',
    'Be3 fxe4',
    'Bxf2 Qf5',
    'Qf6+ Qxf6',
    'Nxf6 Rb8',
    'b3 Rb5',
    'Bd4 e3',
    'Bxe3 Rxe5',
    'Bd4 Re2',
    'Nxh7 Bd5+',
    'Kg1 Rg2+',
    'Kf1 Rxh2',
    'Ng5+ Kg6',
    'Be3 c6',
    'a3 Bc3',
    'b4 Bf6',
    'Rxd5 cxd5',
    'Nf3 Ra2',
    'Bxa7 Rxa3',
  ],
  [
    'e4 e5',
    'Nf3 Nc6',
    'c3 f5',
    'd4 fxe4',
    'Nxe5 Nf6',
    'Bb5 Bd6',
    'Nc4 Be7',
    'Ba4 d5',
    'Ne5 O-O',
    'Bxc6 bxc6',
    'Nxc6 Qe8',
    'Nxe7+ Qxe7',
    'O-O Ng4',
    'h3 e3',
    'Bxe3 Nxe3',
    'fxe3 Bxh3',
    'Rf3 Bg4',
    'Rxf8+ Rxf8',
    'Qe1 Rf6',
    'Nd2 Rg6',
    'Qg3 Qe6',
    'Qf4 Bh3',
    'g3 h5',
    'e4 Rg4',
    'Qxc7 h4',
    'Kh2 hxg3+',
    'Kxh3 Rxe4+',
    'Kg2 Re2+',
    'Kxg3 Qe3+',
    'Kh4 Qh6+',
    'Kg3 Qg5+',
    'Kh3 Rxd2',
    'Qc8+ Kh7',
  ],
  ['e4 e6', 'd4 d5', 'Nc3 dxe4', 'Nxe4 Nd7', 'Nf3 Ndf6', 'Nxf6+ Nxf6', 'c3 c5', 'Ne5 a6', 'Bg5 Qc7', 'Qa4+ Nd7', 'Bf4 Bd6', 'Nxd7 Bxd7', 'Bxd6 Qxd6', 'Qa3 Rc8', 'Be2 Ke7', 'O-O-O Rhd8'],
  [
    'e4 c5',
    'Nf3 d6',
    'c3 Nf6',
    'Be2 g6',
    'O-O Bg7',
    'Bb5+ Nbd7',
    'Re1 a6',
    'Bf1 O-O',
    'd4 b6',
    'Bf4 Bb7',
    'Nbd2 Nh5',
    'Be3 Qc7',
    'a4 e5',
    'dxe5 dxe5',
    'Qb3 h6',
    'Nh4 Kh7',
    'Be2 Nf4',
    'Bc4 Nf6',
    'Bxf4 exf4',
    'e5 Ng4',
    'e6 Ne5',
    'Nhf3 Nxc4',
    'Nxc4 b5',
    'axb5 axb5',
    'e7 Rfe8',
    'Rxa8 Bxa8',
    'Nb6 Bxf3',
    'Qxf7 Bc6',
    'Re6 Qxb6',
    'Rxg6 Rg8',
    'Rxg7+ Rxg7',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 e6',
    'Bb3 Nc6',
    'Be3 Be7',
    'f4 Nxd4',
    'Bxd4 b5',
    'e5 dxe5',
    'fxe5 Nd7',
    'Qg4 O-O',
    'O-O-O Qc7',
    'Ne4 Nxe5',
    'Qg3 f6',
    'Rhf1 Kh8',
    'Ng5 Bd6',
    'Bxe5 Bxe5',
    'Qxe5 Qxe5',
    'Nf7+ Kg8',
    'Nxe5 fxe5',
    'Rfe1 Ra7',
    'Rxe5 Re7',
    'Rde1 Rf2',
    'Rc5 Bd7',
    'Rc7 Kf7',
    'c4 bxc4',
    'Bxc4 Bb5',
    'Bxe6+ Kf6',
    'Rxe7 Kxe7',
    'Bd5+ Kd6',
    'Bf3 Bc4',
    'b3 Bf1',
    'Rd1+ Kc5',
    'Rd2 Rxd2',
    'Kxd2 Kb4',
    'Bd5 Ka3',
    'Bc4 Bxg2',
    'Bxa6 Kxa2',
    'Kc3 g5',
    'Be2 Ka3',
    'b4 Bh3',
    'Bb5 h5',
    'Bc6 Bc8',
    'b5 Ka4',
    'Kc4 Ka5',
    'Kc5 g4',
    'Bg2 h4',
    'Kc6 Bd7+',
    'Kxd7 Kxb5',
    'Kd6 g3',
    'h3 Kc4',
    'Bc6 Kb4',
    'Ke6 Kc5',
    'Bg2 Kd4',
    'Ba8 Kc5',
    'Kd7 Kd4',
    'Kd6 Kc4',
    'Bb7 Kd4',
    'Bd5 Kd3',
    'Ke5 Kc3',
    'Kf6 Kd4',
    'Ba8 Kc5',
    'Ke5 Kc4',
    'Kf6 Kc5',
    'Ke5 Kc4',
    'Bg2 Kc5',
    'Bd5 Kb6',
  ],
  ['e4 e5', 'Nf3 Nc6', 'd4 exd4', 'Nxd4 Nf6', 'Nc3 Bb4', 'Nxc6 bxc6', 'Bd3 O-O', 'O-O d5', 'exd5 cxd5', 'Bg5 c6', 'Na4 h6', 'Bh4 Be6', 'c3 Bd6', 'Bc2 Re8', 'Qd4 a5', 'Rae1 Bb8', 'b3 Ba7', 'Qd1 g5'],
  [
    'e4 c5',
    'c3 Nf6',
    'e5 Nd5',
    'Nf3 Nc6',
    'd4 cxd4',
    'cxd4 d6',
    'Bc4 Nb6',
    'Bb5 d5',
    'h3 Bf5',
    'Nc3 Rc8',
    'O-O e6',
    'Bg5 Be7',
    'Bxe7 Qxe7',
    'Nd2 O-O',
    'Nb3 Nc4',
    'Qe2 Nb4',
    'Bxc4 dxc4',
    'Nc5 Nc2',
    'Rad1 Nxd4',
    'Rxd4 Qxc5',
    'Rfd1 b5',
    'f4 b4',
    'Ne4 Bxe4',
    'Qxe4 Rfd8',
    'Kf1 Rxd4',
    'Qxd4 Qxd4',
    'Rxd4 c3',
    'bxc3 bxc3',
    'Rd1 g5',
    'fxg5 Kg7',
    'Ke2 Kg6',
    'Rc1 Kxg5',
    'Kd3 c2',
    'Kd2 Kh4',
  ],
  [
    'd4 d5',
    'c4 e6',
    'Nf3 Nf6',
    'Nc3 Be7',
    'Bf4 O-O',
    'e3 Nh5',
    'Be5 f6',
    'Bg3 Nxg3',
    'hxg3 c6',
    'Bd3 g6',
    'Qc2 Rf7',
    'O-O-O Nd7',
    'Ne2 Nf8',
    'e4 Bd7',
    'Kb1 Rc8',
    'Nf4 Bd6',
    'e5 fxe5',
    'Nxe5 Bxe5',
    'dxe5 b6',
    'Nh3 c5',
    'f4 Bc6',
    'Ng5 Rd7',
    'cxd5 Bxd5',
    'Bb5 Re7',
    'Ne4 Bxe4',
    'Qxe4 Qc7',
    'Rd6 Qb8',
    'Rhd1 c4',
    'Bxc4 b5',
    'Bb3 a5',
    'a3 Kg7',
    'g4 Rc5',
    'Rd8 Qb7',
    'Qe3 Qc7',
  ],
  [
    'c4 Nf6',
    'Nc3 g6',
    'g3 d5',
    'cxd5 Nxd5',
    'Bg2 Nb6',
    'h4 h6',
    'd3 Bg7',
    'Be3 N8d7',
    'Qd2 Nf6',
    'Rc1 c6',
    'Nf3 Ng4',
    'Bc5 O-O',
    'Nh2 Nxh2',
    'Rxh2 f5',
    'Be3 Kh7',
    'f4 h5',
    'Bf3 Nd7',
    'd4 Nb6',
    'b3 Be6',
    'Bg1 Nd5',
    'Nxd5 Bxd5',
    'Bxd5 Qxd5',
    'Rc5 Qe6',
    'Rf2 Rad8',
    'Rf3 Rd6',
    'Re3 Qd7',
    'Rd3 Rd8',
    'Rc4 Qe6',
    'Kf1 Qd5',
    'Qe3 Re6',
    'Qf3 Re4',
    'Bf2 b6',
    'a4 Rd6',
    'Rc2 Qe6',
    'Kg2 Qd7',
    'Rdc3 Rde6',
    'Rc4 Qd5',
    'Kf1 Qd7',
    'Kg2 Rd6',
    'Bg1 a6',
    'Bf2 b5',
    'Rc5 Bxd4',
    'Bxd4 Rdxd4',
    'Rxc6 Rd2',
    'axb5 axb5',
    'R6c5 Qd4',
    'Rxd2 Qxd2',
    'Re5 Rxe5',
    'fxe5 Qd4',
    'Qf4 Qd3',
    'Kf2 Qxb3',
    'Qd4 Qc4',
    'Qa7 Qb4',
    'Qb6 f4',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nd2 Nf6',
    'e5 Nfd7',
    'c3 c5',
    'f4 Nc6',
    'Ndf3 cxd4',
    'cxd4 Bb4+',
    'Bd2 Qb6',
    'Bxb4 Qxb4+',
    'Qd2 Nb6',
    'Qxb4 Nxb4',
    'Kd2 Bd7',
    'a3 Nc6',
    'b3 Rc8',
    'Bd3 Na5',
    'Rb1 Ke7',
    'Ne2 a6',
    'a4 g6',
    'h3 Nc6',
    'Rbf1 Nb4',
    'Bb1 Na8',
    'g4 b5',
    'axb5 Bxb5',
    'f5 Bxe2',
    'Kxe2 Nc7',
    'Ng5 gxf5',
    'gxf5 h6',
    'fxe6 hxg5',
    'Rxf7+ Kxe6',
    'Rhf1 Ne8',
    'Rb7 Nc2',
    'Rb6+ Ke7',
  ],
  [
    'e4 c5',
    'Nf3 a6',
    'Nc3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Be2 e5',
    'Nb3 Be7',
    'O-O O-O',
    'Be3 Bd7',
    'a4 Bc6',
    'Nd5 Nbd7',
    'f3 Bxd5',
    'exd5 Nh5',
    'Qd2 g6',
    'Rfc1 Ng7',
    'Bd3 a5',
    'Qe1 b6',
    'Nd2 Nf6',
    'c4 Nd7',
    'Bc2 Bh4',
    'g3 Bg5',
    'Bxg5 Qxg5',
    'b4 axb4',
    'Ne4 Qe7',
    'Qxb4 Nf5',
    'a5 bxa5',
    'Qb7 Rfd8',
    'Ba4 Rab8',
    'Qa7 Kf8',
    'Bxd7 Qxd7',
    'Qxd7 Rxd7',
    'c5 dxc5',
    'Rxc5 Nd4',
    'Rcxa5 Nxf3+',
    'Kg2 Nd4',
    'Nf6 Rdb7',
    'Rf1 Kg7',
    'Ng4 e4',
    'Ra4 Nf3',
    'Rxf3 exf3+',
    'Kxf3 Rb3+',
    'Ne3 Re8',
    'Re4 Kf8',
    'h4 h5',
    'Kf4 Rxe4+',
    'Kxe4 Ke7',
    'Kd4 Rb1',
    'Ke5 Re1',
    'Kf4 Kd6',
    'Ke4 f6',
    'Kf4 Ra1',
    'Ke4 Ra4+',
    'Kd3 Kc5',
  ],
  [
    'd4 d5',
    'c4 c6',
    'e3 Bf5',
    'Nf3 e6',
    'Nc3 Nd7',
    'g3 h6',
    'b3 Ngf6',
    'Bb2 Ne4',
    'a3 Be7',
    'Bg2 b5',
    'cxd5 Nxc3',
    'Bxc3 cxd5',
    'O-O O-O',
    'Qd2 a5',
    'Rfc1 Be4',
    'Qe2 Qb6',
    'Bb2 Rfc8',
    'Bf1 Bxf3',
    'Qxf3 b4',
    'a4 Qb7',
    'Bd3 Rxc1+',
    'Rxc1 Rc8',
    'Re1 f5',
    'g4 fxg4',
    'Qxg4 Qb6',
    'Kh1 Bf6',
    'Rg1 Nf8',
    'f4 Rc7',
    'Qh5 Kh8',
    'Qe8 Kg8',
    'Rf1 Qb7',
    'Qh5 Qb6',
    'Bc1 Rc3',
    'Qd1 Qd6',
    'Bb5 Ng6',
    'Bd2 Rc8',
    'Bd3 Ne7',
    'Qh5 Qd7',
    'Rg1 Kf8',
    'Rf1 Kg8',
    'Qh3 Rc7',
    'Kg1 Nf5',
    'Kf2 g6',
    'Rg1 Kh7',
    'Qg4 Qf7',
    'Ke2 Rc6',
    'Kd1 Rc7',
    'Qe2 Bd8',
    'Bc2 Qf6',
    'Qa6 Qe7',
    'Kc1 Ra7',
    'Qe2 Rc7',
    'Kb1 Qf7',
    'Bd3 Qf6',
    'Bc1 Qf7',
    'Bb2 Bf6',
    'Ka2 Qd7',
    'Bb1 Qc6',
    'Qg2 Qe8',
    'Bd3 Qf7',
    'Qe2 Qd7',
    'Rd1 Qg7',
    'Bb1 Rd7',
    'Qa6 Nxe3',
    'Re1 Nf5',
    'Rxe6 Bxd4',
    'Bxf5 gxf5',
    'Bxd4 Qxd4',
    'Rxh6+ Kg7',
    'Qg6+ Kf8',
    'Qxf5+ Ke8',
    'Re6+ Kd8',
    'Qf8+ Kc7',
    'Re2 Kb7',
    'Qe8 Qg7',
    'Rc2 Ka7',
    'Qc8 Qe7',
    'f5 Rb7',
    'Qc6 Qd8',
    'Qc5+ Ka6',
    'Re2 Rb6',
    'Re7 Qb8',
    'Qf2 Rb7',
  ],
  [
    'd4 f5',
    'Nf3 Nf6',
    'c4 d6',
    'g3 g6',
    'Bg2 Bg7',
    'O-O O-O',
    'Nc3 Nc6',
    'd5 Ne5',
    'Nxe5 dxe5',
    'e4 f4',
    'gxf4 exf4',
    'Bxf4 Nxe4',
    'Qc1 e5',
    'Be3 Nd6',
    'Bg5 Qe8',
    'Nb5 Rf7',
    'Nxd6 cxd6',
    'c5 dxc5',
    'Qxc5 b6',
    'Qc4 Bb7',
    'Bh3 Kh8',
    'Be6 Rf5',
    'Qh4 Qb5',
    'a4 Qxb2',
    'Rac1 Qb3',
    'Rc7 Bxd5',
    'Rxg7 Rxg5+',
    'Qxg5 Bxe6',
  ],
  [
    'e4 d6',
    'd4 Nf6',
    'Nc3 g6',
    'Bg5 Bg7',
    'Qd2 c6',
    'f4 b5',
    'Bd3 O-O',
    'Nf3 Bg4',
    'O-O-O Nbd7',
    'h3 Bxf3',
    'gxf3 b4',
    'Ne2 Qa5',
    'Kb1 Rab8',
    'Nc1 c5',
    'e5 Nd5',
    'Nb3 Nc3+',
    'Qxc3 bxc3',
    'Nxa5 Rxb2+',
    'Ka1 f6',
    'exf6 exf6',
    'Bh4 d5',
    'dxc5 Nxc5',
    'Bf2 Nxd3',
    'Rxd3 Rxc2',
    'Bd4 Rc8',
    'Nb3 Bf8',
    'Rc1 Rxc1+',
    'Nxc1 Ba3',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 Nbd7',
    'O-O e5',
    'Re1 c6',
    'Bf1 a5',
    'Rb1 Re8',
    'd5 Nc5',
    'b3 Bd7',
    'a3 a4',
    'b4 Nb3',
    'dxc6 bxc6',
    'Qxd6 Nd4',
    'Nxe5 Nxe4',
    'Rxe4 Bf5',
    'Rxd4 Qxd6',
    'Rxd6 Bxe5',
    'Rxc6 Bxc3',
    'Bb2 Bxb1',
    'Bxc3 Rad8',
    'f3 Rd1',
    'Kf2 Bd3',
    'Bxd3 Rxd3',
    'Bf6 Rxa3',
    'Ra6 Ra2+',
    'Kg3 Rc2',
    'c5 Rc4',
    'Rxa4 Rc8',
    'Be7 Rb8',
    'Bd6 Rcxb4',
    'Ra6 Rc8',
    'c6 Rb5',
    'h4 f6',
    'c7 Kf7',
    'Kh3 Rb3',
    'Rc6 h5',
    'Bf4 Rb7',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nc3 dxe4',
    'Nxe4 Bd7',
    'Nf3 Bc6',
    'Bd3 Nd7',
    'O-O Ngf6',
    'Ng3 Be7',
    'b3 O-O',
    'Bb2 Bxf3',
    'Qxf3 c6',
    'c4 Re8',
    'a3 Bf8',
    'Rfe1 g6',
    'Rad1 Bg7',
    'Bc2 Qc7',
    'Qe2 Rad8',
    'b4 a5',
    'Bb3 Ra8',
    'Nf1 axb4',
    'axb4 Qb6',
    'Bc3 Bf8',
    'Nd2 Bxb4',
    'c5 Qa5',
    'Ra1 Bxc3',
    'Rxa5 Bxa5',
    'Rb1 Bxd2',
    'Qxd2 b5',
    'Qf4 e5',
    'dxe5 Rxe5',
    'h3 Rxc5',
    'Rd1 Rf5',
    'Qc7 c5',
    'Rxd7 Nxd7',
    'Qxd7 c4',
    'Bc2 Re5',
    'Qb7 Rd8',
    'Be4 b4',
    'Kh2 b3',
    'Qc7 Rde8',
    'f3 Rxe4',
    'fxe4 b2',
    'Qb7 c3',
    'e5 h6',
  ],
  [
    'e4 e6',
    'd4 d5',
    'e5 c5',
    'c3 Nc6',
    'Nf3 Qb6',
    'Be2 Nh6',
    'Bd3 Bd7',
    'Bc2 Rc8',
    'O-O cxd4',
    'cxd4 Nb4',
    'Ba4 Bxa4',
    'Qxa4+ Qc6',
    'Qxc6+ Rxc6',
    'Nc3 Nf5',
    'Rd1 Nc2',
    'Rb1 Rc4',
    'Ne2 h5',
    'b3 Rc6',
    'Bb2 Be7',
    'Rbc1 g5',
    'Bc3 g4',
    'Ne1 Rxc3',
    'Nxc2 Rc6',
    'Ne3 Nh4',
    'Rxc6 bxc6',
    'Nf4 Kd7',
    'Nd3 f6',
    'Nc2 Ng6',
    'exf6 Bxf6',
    'Rc1 Kd6',
    'g3 Ne7',
    'Nc5 Nf5',
    'Nb7+ Ke7',
    'Nb4 Bxd4',
    'Nxc6+ Kf6',
    'b4 h4',
    'Nc5 Rh7',
    'Kg2 e5',
    'Nd3 Rd7',
    'b5 h3+',
    'Kf1 e4',
    'Ndb4 Be5',
    'Rc5 Ke6',
    'a4 Bg7',
    'Nb8 Rf7',
    'N8c6 Rf8',
    'Ke1 e3',
    'Nd3 Kd6',
    'Rc2 Re8',
    'Re2 Re4',
    'Ra2 Bd4',
    'Kf1 Bc3',
    'fxe3 Rxe3',
    'Ncb4 Nd4',
    'Kf2 Re4',
    'Ra3 Re2+',
    'Kf1 Bxb4',
    'Nxb4 Rb2',
    'Nd3 Rb1+',
  ],
  [
    'e4 e5',
    'd4 exd4',
    'c3 dxc3',
    'Bc4 Nc6',
    'Nf3 d6',
    'Qb3 Qd7',
    'O-O Na5',
    'Qxc3 Nxc4',
    'Qxc4 Ne7',
    'Nc3 Ng6',
    'Be3 c6',
    'Nd4 Ne5',
    'Qe2 Qg4',
    'f3 Qh5',
    'Ncb5 cxb5',
    'Nxb5 Kd8',
    'Rfd1 Bd7',
    'a4 Be7',
    'Bxa7 Rxa7',
    'Nxa7 g5',
    'h3 g4',
    'f4 Nf3+',
    'Kh1 Rg8',
    'e5 gxh3',
    'g3 Rxg3',
    'exd6 Bf6',
    'Qc2 Rg2',
  ],
  [
    'd4 Nf6',
    'Nf3 c5',
    'd5 b5',
    'Bg5 Qa5+',
    'c3 Ne4',
    'Bh4 b4',
    'c4 b3+',
    'Nbd2 bxa2',
    'Qb3 Na6',
    'Rxa2 Qb4',
    'Qc2 Nxd2',
    'Nxd2 d6',
    'Ra4 Qb6',
    'Qc3 f6',
    'e4 g6',
    'f4 Bg7',
    'Be2 O-O',
    'O-O f5',
    'e5 Re8',
    'Bf3 Rb8',
    'b3 Nb4',
    'Re1 e6',
    'Raa1 Bb7',
    'dxe6 dxe5',
    'fxe5 Rxe6',
    'Bxb7 Qxb7',
    'Nf3 Nc6',
    'Bf2 Qe7',
    'Rad1 Nxe5',
    'Nxe5 Rxe5',
    'Rxe5 Bxe5',
    'Qe3 Bd6',
    'Qd3 Rd8',
    'Qd5+ Kg7',
    'Qf3 a6',
    'Rd5 Qc7',
    'Qc3+ Kf7',
    'g3 Re8',
    'Qa1 Qc6',
    'Qa5 Rc8',
    'Qd2 Be7',
    'Rd7 Ke8',
    'Rd5 Kf7',
    'Rd7 Rb8',
    'Qd3 Rb7',
    'Rd5 a5',
    'Qf3 Qb6',
    'g4 Qxb3',
    'Rd3 Qxc4',
    'gxf5 Rb1+',
    'Kg2 g5',
    'f6 Bxf6',
    'Qf5 Rb2',
    'Rd7+ Kg8',
    'Qxh7+ Kf8',
    'Qf5 Rxf2+',
    'Kxf2 Qf4+',
    'Qxf4 gxf4',
    'Kf3 Bc3',
    'Kxf4 Bb4',
    'Ke4 c4',
    'Rc7 c3',
    'Kd3 Kg8',
    'h4 Kh8',
    'h5 Bd6',
    'Ra7 a4',
    'Rxa4 Kh7',
    'Ra7+ Kg8',
    'Kxc3 Bf8',
    'Kd3 Bg7',
    'Ke4 Kh7',
    'Kf5 Kh6',
    'Rb7 Bc3',
    'Rb6+ Kh7',
    'Rd6 Bb2',
    'Rc6 Bd4',
    'Ke4 Bg7',
    'Kf5 Bd4',
  ],
  [
    'd4 Nf6',
    'Bg5 d5',
    'e3 Ne4',
    'Bf4 c5',
    'Bd3 e6',
    'Bxe4 dxe4',
    'Nc3 cxd4',
    'Qxd4 Qxd4',
    'exd4 Bb4',
    'Ne2 f5',
    'O-O-O O-O',
    'd5 Na6',
    'dxe6 Bxe6',
    'Bd6 Rfd8',
    'Bxb4 Nxb4',
    'Nd4 Kf7',
    'Rhe1 Rac8',
    'g4 g6',
    'gxf5 Bxf5',
    'Ndb5 e3',
    'Nd6+ Rxd6',
    'Rxd6 Nxa2+',
    'Nxa2 Rxc2+',
    'Kd1 Rxb2',
    'Rxe3 Rxa2',
    'Rc3 Ke7',
    'Rd4 h5',
    'Rc7+ Kf6',
    'Rxb7 Rxf2',
    'h4 Ra2',
    'Rc4 Ke5',
    'Rb5+ Kd6',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 g6',
    'Be3 Bg7',
    'f3 O-O',
    'Qd2 Nc6',
    'g4 Bd7',
    'O-O-O Rc8',
    'h4 Ne5',
    'h5 Qa5',
    'Kb1 Nxf3',
    'Nxf3 Rxc3',
    'Qxc3 Qxc3',
    'bxc3 Bxg4',
    'h6 Bh8',
    'Bg2 Nxe4',
    'Rd3 Nxc3+',
    'Rxc3 Bxc3',
    'Rh4 Bd7',
    'Rc4 Rc8',
    'Rxc8+ Bxc8',
    'Ng5 b6',
    'Kc1 e6',
    'Bd2 Be5',
    'Nf3 Bg3',
    'Bg5 Bb7',
    'Kd1 Bd5',
    'a3 f5',
    'Ne1 Bxg2',
    'Nxg2 d5',
    'a4 Kf7',
    'Ke2 Bd6',
    'Ne1 e5',
    'Nf3 e4',
    'Nd4 f4',
    'c3 Be5',
    'Kf2 Bxd4+',
    'cxd4 f3',
    'Bd2 a6',
    'Kg3 Ke6',
    'Be1 b5',
    'axb5 axb5',
    'Bd2 Kf5',
    'Be1 Kg5',
    'Bd2+ Kh5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nd2 dxe4',
    'Nxe4 Nd7',
    'Nf3 Ngf6',
    'Nxf6+ Nxf6',
    'Bg5 h6',
    'Be3 Nd5',
    'Qd2 Bd6',
    'O-O-O Qe7',
    'Bd3 Bd7',
    'Be4 Nf6',
    'Bd3 Nd5',
    'c4 Nxe3',
    'Qxe3 c5',
    'Be4 O-O-O',
    'Qb3 b6',
    'Kb1 f5',
    'Bc2 cxd4',
    'Nxd4 Bc5',
    'Qf3 Kb8',
    'Qg3+ Kb7',
    'Nf3 Bc6',
    'Ne5 Bd6',
    'Nxc6 Kxc6',
    'Qf3+ Kc7',
    'a3 Be5',
    'Ba4 Bf6',
    'Bb5 Qc5',
    'Rhe1 Rxd1+',
    'Rxd1 Rd8',
    'Rxd8 Kxd8',
    'Qa8+ Qc8',
    'Qxa7 Qc7',
    'Qa8+ Qc8',
    'Qf3 Ke7',
    'Qe3 Qc7',
    'h3 Qd6',
    'Kc2 Bd4',
    'Qe2 Qf4',
    'f3 h5',
    'b4 h4',
    'Qd2 Qxd2+',
    'Kxd2 Kd6',
    'Ba6 Kc7',
    'Bb5 e5',
    'Be8 e4',
    'fxe4 fxe4',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 e6',
    'Bb3 b5',
    'Bg5 Be7',
    'Qf3 Qb6',
    'Rd1 O-O',
    'O-O Nbd7',
    'Qg3 Nh5',
    'Qh4 Bxg5',
    'Qxg5 Nhf6',
    'Rfe1 Bb7',
    'Re3 Ne5',
    'Rg3 Ng6',
    'Nf5 b4',
    'Rxd6 Qc7',
    'e5 exf5',
    'h4 bxc3',
    'Rxf6 Qxe5',
    'Rxf5 Qe1+',
    'Kh2 cxb2',
  ],
  [
    'e4 e6',
    'd4 d5',
    'Nd2 c5',
    'exd5 exd5',
    'Ngf3 Nc6',
    'dxc5 Bxc5',
    'Bd3 Qe7+',
    'Qe2 Nf6',
    'Qxe7+ Kxe7',
    'O-O h6',
    'a3 Rd8',
    'b4 Bb6',
    'Bb2 Kf8',
    'Rfe1 a6',
    'Nb3 Bd7',
    'a4 Re8',
    'b5 Rxe1+',
    'Rxe1 axb5',
    'axb5 Nb4',
    'Bd4 Nxd3',
    'cxd3 Bd8',
    'Bc5+ Kg8',
    'Nbd4 Rc8',
    'Bb4 Bb6',
    'h3 Ra8',
    'Rc1 Nh5',
    'Bd6 g5',
    'Re1 Re8',
    'Rxe8+ Bxe8',
    'Nf5 Kh7',
    'g4 Ng7',
    'Nxg7 Kxg7',
    'Be5+ f6',
    'Bd4 Ba5',
    'b6 Bb5',
    'Bc5 Bxd3',
    'Nd4 Kf7',
    'Kg2 Bc3',
    'Kg3 Bd2',
    'Bd6 Ke8',
    'Nb3 Kd7',
    'Bf8 Bf4+',
    'Kf3 Bc2',
    'Nc5+ Kc6',
    'Ne6 Bd1+',
    'Kg2 Be5',
    'Bxh6 Kxb6',
    'Bg7 d4',
    'Nxg5 Bb3',
    'Kf1 Bd5',
    'Ke2 Kc5',
    'Kd3 Bc4+',
    'Ke4 Bd5+',
    'Kd3 Bc4+',
    'Ke4 Bd5+',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Be3 e6',
    'f3 b5',
    'g4 h6',
    'Qd2 Nbd7',
    'O-O-O Bb7',
    'h4 Ne5',
    'Qg2 Rc8',
    'g5 Nfd7',
    'gxh6 g6',
    'h5 g5',
    'Bxg5 Qxg5+',
    'Qxg5 Bxh6',
    'Qxh6 Rxh6',
    'Ndxb5 axb5',
    'Nxb5 Ke7',
    'Nxd6 Rc7',
    'Nxb7 Rxb7',
    'Be2 Nf6',
    'f4 Ned7',
    'e5 Nd5',
    'Rd4 Ra7',
    'a3 Rc7',
    'Rh3 f6',
    'exf6+ Rxf6',
    'h6 Rc8',
    'h7 Rh8',
    'Rh4 Nf8',
    'Bd3 Ng6',
    'Bxg6 Rxg6',
  ],
  [
    'd4 d5',
    'c4 dxc4',
    'e4 Nf6',
    'e5 Nd5',
    'Bxc4 Nb6',
    'Bd3 Nc6',
    'Ne2 Be6',
    'Nbc3 Qd7',
    'Ne4 Bd5',
    'O-O e6',
    'Be3 Rd8',
    'a3 Bxe4',
    'Bxe4 Nd5',
    'Qc2 Nce7',
    'Bg5 h6',
    'Bxd5 exd5',
    'Bxe7 Bxe7',
    'Ng3 O-O',
    'Nf5 Rfe8',
    'f4 Bf8',
    'Kh1 b6',
    'Rf3 c5',
    'Rg3 Kh8',
    'Rd1 a5',
    'Qd3 Re6',
    'Nh4 cxd4',
    'Nf3 Rc6',
    'Nxd4 Rc4',
    'b3 Rc7',
    'f5 Rdc8',
    'Qf1 Qe7',
    'Nb5 Rc2',
    'Nd6 R8c7',
    'f6 gxf6',
    'Ne8 R7c3',
    'Nxf6 Rxg3',
    'hxg3 Qxe5',
    'Qd3 Qxf6',
    'Qxc2 Qe5',
    'Qd2 Qh5+',
    'Kg1 Bc5+',
    'Kf1 Qh1+',
    'Ke2 Qxg2+',
    'Kd3 Qe4+',
    'Kc3 Bf8',
    'Kb2 Bg7+',
    'Ka2 a4',
    'Rc1 d4',
    'Re1 axb3+',
    'Kxb3 Qd5+',
    'Kc2 Qc4+',
    'Kd1 d3',
    'Qc1 Qxc1+',
    'Kxc1 Bc3',
    'Re3 d2+',
    'Kd1 Ba5',
  ],
  [
    'e4 e6',
    'd4 d5',
    'exd5 Qxd5',
    'Nf3 Nf6',
    'Be3 Be7',
    'Be2 O-O',
    'O-O Qd8',
    'c4 b6',
    'Ne5 Bb7',
    'Bf3 Qc8',
    'Nc3 Nbd7',
    'Nc6 Re8',
    'b4 Bf8',
    'c5 a5',
    'a3 bxc5',
    'dxc5 e5',
    'Nxa5 Bxf3',
    'Qxf3 Nxc5',
    'Bxc5 Bxc5',
    'Ne4 Nxe4',
    'Qxe4 Qe6',
    'Rac1 Bb6',
    'Rc6 Qd7',
    'Nc4 Bd4',
    'b5 h5',
    'h3 Qe7',
    'Rb1 Ra7',
    'Rb3 Ra4',
    'Rg3 Qd7',
    'Rh6 f5',
    'Qc2 Raa8',
    'Rxh5 e4',
    'Qd2 f4',
    'Qxf4 Bxf2+',
    'Qxf2 Qd1+',
    'Kh2 Qxh5',
    'Qf6 Qf7',
    'Qc6 Rad8',
    'Ne3 Rd6',
    'Qc5 Rd3',
    'a4 Qf4',
    'a5 Re6',
    'Qc4 Qe5',
    'Kh1 Kh7',
    'Nf1 Rf6',
    'Ne3 Qxg3',
    'Qxe4+ Qg6',
    'Qh4+ Kg8',
    'Qc4+ Rf7',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Nf3 Bg7',
    'Bg5 Ne4',
    'cxd5 Nxg5',
    'Nxg5 e6',
    'Nf3 exd5',
    'e3 O-O',
    'Be2 c6',
    'O-O Qe7',
    'a3 Be6',
    'b4 Nd7',
    'Ne1 a5',
    'Nd3 b5',
    'Qd2 a4',
    'Nc5 Nb6',
    'Rae1 Rae8',
    'Bd3 Bc8',
    'Kh1 Kh8',
    'Qc1 Qd6',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 d5',
    'g3 dxc4',
    'Bg2 Bb4+',
    'Bd2 Bxd2+',
    'Nbxd2 b5',
    'a4 c6',
    'O-O Bb7',
    'Qc2 O-O',
    'b3 cxb3',
    'Nxb3 Nbd7',
    'Ne5 Nxe5',
    'dxe5 Nd5',
    'Nc5 Qe7',
    'Rfc1 a5',
    'Bxd5 exd5',
    'Nxb7 Qxb7',
    'Qxc6 Qxc6',
    'Rxc6 b4',
    'Kf1 Rfc8',
    'Rd6 Rc5',
    'Rb6 Rac8',
    'Rd1 Kf8',
    'Ke1 Ke7',
    'f4 g6',
    'Rd3 h5',
    'Kd2 R8c7',
    'Ke3 Rd7',
    'h3 Rdc7',
    'g4 hxg4',
    'hxg4 Rc3',
    'Rb5 R7c5',
    'Rxc3 Rxc3+',
    'Kd4 Rc4+',
    'Kxd5 Rxf4',
    'Rxa5 b3',
    'e4 Rf1',
    'Ra7+ Kf8',
    'Rb7 Rd1+',
    'Kc5 Rc1+',
    'Kd4 Ra1',
    'e6 Rxa4+',
    'Ke5 fxe6',
    'Rxb3 Kg7',
    'Rb7+ Kh6',
    'Rb5 Kg5',
    'Kxe6+ Kxg4',
    'e5 g5',
    'Kf6 Rf4+',
    'Kg6 Re4',
    'Kf7 Kf3',
    'Rb3+ Kf4',
    'e6 g4',
    'e7 Rxe7+',
    'Kxe7 g3',
    'Kf6 g2',
    'Rb1 Kf3',
    'Kf5 Kf2',
    'Kf4 g1=Q',
    'Rb2+ Ke1',
    'Rb1+ Kf2',
    'Rb2+ Kf1',
    'Rb1+ Kg2',
    'Rxg1+ Kxg1',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'f3 O-O',
    'Be3 e5',
    'Nge2 Nc6',
    'Qd2 a6',
    'd5 Na5',
    'Nc1 c5',
    'dxc6 Nxc6',
    'Nb3 Be6',
    'Rd1 Qc8',
    'Nd5 Nd7',
    'Be2 a5',
    'a4 Bxd5',
    'cxd5 Nb4',
    'Rc1 Qd8',
    'O-O b6',
    'f4 exf4',
    'Bxf4 Nc5',
    'Nxc5 bxc5',
    'Bb5 Qe7',
    'Rce1 Be5',
    'Bh6 Rfc8',
    'Rf3 c4',
    'Ref1 Qh4',
    'Bf4 Nd3',
    'Bxe5 Nxe5',
    'Rf4 Qe7',
    'Rf6 Kg7',
    'Qf2 Rc5',
    'h3 Ra7',
    'Kh2 Qd8',
    'Qh4 Ra8',
    'R1f4 c3',
    'bxc3 Rxc3',
    'Bc6 Rc8',
    'Qf2 Kg8',
    'Qa7 Qc7',
    'Qxc7 Rxc7',
    'Bb5 Rc2',
    'Rxd6 Ra2',
    'Rd8+ Kg7',
    'Re8 Rcc2',
  ],
  [
    'e4 c5',
    'Nf3 d6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 a6',
    'Bc4 e6',
    'Bb3 b5',
    'O-O b4',
    'Na4 Bd7',
    'f4 Nc6',
    'Be3 Rb8',
    'c3 Be7',
    'Rc1 Nxe4',
    'cxb4 Nxd4',
    'Qxd4 Nf6',
    'Nb6 O-O',
    'Kh1 e5',
    'fxe5 dxe5',
    'Qxe5 Ng4',
    'Qe4 Nxe3',
    'Nxd7 Qxd7',
    'Qxe3 Bxb4',
    'Qe4 Rb6',
    'Rcd1 Qc7',
    'Qg4 Rh6',
    'h3 Bd6',
    'Rc1 Qb7',
    'Rf3 Rg6',
    'Qd4 Be7',
    'Bd5 Qb6',
    'Qc4 Rf6',
    'Qe2 Rxf3',
    'Bxf3 Bf6',
    'Rc6 Qxb2',
    'Qxb2 Bxb2',
    'Rxa6 Rc8',
    'Kg1 g6',
    'Kf2 Kg7',
    'Bd5 Rc2+',
    'Ke3 Bc1+',
    'Kd3 Rd2+',
    'Kc4 Rc2+',
    'Kb3 Rd2',
    'Ra5 f5',
    'a4 Kf6',
    'Rc5 Rb2+',
    'Kc3 Ke5',
    'a5 Kd6',
    'a6 Kxc5',
    'a7 Kxd5',
    'a8=Q+ Ke5',
    'Qd8 Rd2',
    'Qe7+ Kf4',
    'Qh4+ Ke5',
    'Qe1+ Kf6',
    'Qxc1 Rxg2',
    'Qh6 Ke5',
    'Qxh7 Kf4',
    'Qc7+ Kg5',
    'Kd3 f4',
    'Qe5+ Kh4',
    'Qxf4+ Kxh3',
    'Qh6+ Kg3',
    'Qxg6+ Kf2',
    'Qb6+ Kf1',
    'Ke4 Rf2',
    'Ke3 Kg2',
    'Qg6+ Kf1',
    'Qg4 Rf8',
    'Qd7 Rf2',
    'Qh3+ Kg1',
    'Qg3+ Rg2',
    'Qe1+ Kh2',
    'Kf3 Rg8',
    'Qd2+ Kg1',
    'Qc1+ Kh2',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Bg5 Ne4',
    'Bh4 Nxc3',
    'bxc3 dxc4',
    'e3 Be6',
    'Qb1 Qd5',
    'a4 Nc6',
    'Nf3 Bh6',
    'Be2 g5',
    'Bg3 g4',
    'Nh4 f5',
    'O-O O-O-O',
    'Bd1 Qa5',
    'Bc2 Rhf8',
    'Qb2 Bg5',
    'Rab1 b6',
    'Rbe1 Bxh4',
    'Bxh4 Bd5',
    'Bg3 h5',
    'f3 gxf3',
    'gxf3 f4',
    'Bxf4 Nxd4',
    'cxd4 Rg8+',
    'Kf2 Qd2+',
    'Re2 Rg2+',
    'Kxg2 Qxe2+',
    'Rf2 Rg8+',
    'Bg3 Bxf3+',
    'Kh3 Bg4+',
    'Kh4 Qxe3',
    'Qb5 Qxd4',
    'Qe5 Qd8',
    'Rd2 Bd7',
    'Rd4 e6+',
    'Kxh5 Be8+',
    'Kh6 Qe7',
    'Rf4 Bf7',
    'Qf6 Qf8+',
    'Kh7 Kb8',
    'Rf2 e5',
  ],
  [
    'Nf3 Nf6',
    'c4 e6',
    'g3 d5',
    'Bg2 Be7',
    'O-O O-O',
    'b3 a5',
    'Nc3 c6',
    'd4 Nbd7',
    'Qc2 b6',
    'e4 Ba6',
    'Nd2 c5',
    'exd5 cxd4',
    'Nb5 exd5',
    'Nxd4 Rc8',
    'Re1 b5',
    'Bb2 Re8',
    'Qd1 bxc4',
    'bxc4 Qb6',
    'Rb1 dxc4',
    'Nc6 Rxc6',
    'Bxf6 Qxf2+',
    'Kxf2 Bc5+',
    'Kf3 Rxf6+',
    'Kg4 Ne5+',
    'Kg5 Rg6+',
    'Kh5 f6',
    'Rxe5 Rxe5+',
    'Kh4 Bc8',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 c5',
    'd5 d6',
    'Nc3 exd5',
    'cxd5 g6',
    'Nd2 Nbd7',
    'e4 Bg7',
    'Nc4 Nb6',
    'Ne3 O-O',
    'Bd3 Re8',
    'O-O Nbd7',
    'a4 Ne5',
    'Be2 g5',
    'Rb1 h6',
    'b4 b6',
    'Bb5 Rf8',
    'bxc5 dxc5',
    'Qc2 Ne8',
    'Bxe8 Rxe8',
    'Ne2 Ng4',
    'Ng3 Qd6',
    'Nc4 Qg6',
    'Bb2 Bxb2',
    'Rxb2 Ba6',
    'Rfb1 Bxc4',
    'Qxc4 Qf6',
    'Qe2 Ne5',
    'Nf5 c4',
    'Rc2 Rac8',
    'h3 Rc5',
    'Rb5 Rec8',
    'Rxc5 Rxc5',
    'd6 Kh7',
    'Qd1 Nd7',
    'Ne3 c3',
    'Qd3 Kg8',
    'Ng4 Qe6',
    'Ne3 Qf6',
    'Nc4 Qe6',
    'Rxc3 Rc6',
    'Qd4 a6',
    'e5 b5',
    'axb5 axb5',
    'Na3 Rxc3',
    'Qxc3 Qxe5',
    'Qxe5 Nxe5',
    'Nxb5 Kf8',
    'Nd4 Ke8',
    'Nf5 Kd7',
    'Nxh6 Kxd6',
    'Kh2 Ke6',
    'Kg3 f5',
    'f4 gxf4+',
    'Kxf4 Nd3+',
    'Kg5 Ne1',
    'g3 Nf3+',
    'Kg6 Ne5+',
    'Kg7 Nd3',
    'Nf7 f4',
    'g4 Nf2',
    'Ng5+ Ke7',
    'h4 Nxg4',
    'h5 Kd7',
    'Ne4 f3',
    'Kf7 Kc6',
    'Ke6 Nh6',
    'Nf2 Kc5',
    'Ke5 Ng8',
    'Ng4 Nh6',
    'Nf2 Ng8',
    'Ne4+ Kc6',
    'Ke6 Nh6',
    'Ke5 Ng8',
    'Nf2 Kc5',
    'Kf4 Kd5',
    'Kxf3 Ke5',
    'Ng4+ Kf5',
    'h6 Nxh6',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'Nd2 a5',
    'a3 c5',
    'Rb1 Ne8',
    'b4 axb4',
    'axb4 b6',
    'bxc5 bxc5',
    'Nb3 f5',
    'f3 f4',
    'Bd2 Bd7',
    'Ra1 g5',
    'Nb5 Rxa1',
    'Qxa1 Nc8',
    'g4 fxg3',
    'hxg3 Qf6',
    'Qc1 Qg6',
    'Nc3 h6',
    'Na1 Nf6',
    'Nc2 g4',
    'Qe1 gxf3',
    'Bxf3 Nb6',
    'Ne3 Bh3',
    'Bg2 Bxg2',
    'Nf5 Rf7',
    'Kxg2 Nxc4',
    'Qe2 Nxd2',
    'Qxd2 Nxe4',
    'Nxe4 Rxf5',
    'Rxf5 Qxf5',
    'Nxd6 Qg6',
    'Nb5 Qe4+',
    'Kh2 Qb4',
    'Qe2 c4',
    'Nc7 c3',
    'Qa6 c2',
    'Qc8+ Qf8',
    'Ne8 c1=Q',
    'Qxc1 Qxe8',
    'd6 Qe6',
    'Qc7 Bf6',
    'd7 Qd5',
    'Qc8+ Kg7',
    'Qe8 e4',
    'Kh3 Bg5',
    'Kg4 e3',
    'Kh3 Qh1+',
    'Kg4 Qd1+',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'd4 O-O',
    'Be2 e5',
    'O-O Nc6',
    'd5 Ne7',
    'Nd2 a5',
    'a3 c5',
    'Rb1 Ne8',
    'b4 axb4',
    'axb4 b6',
    'bxc5 bxc5',
    'Nb3 f5',
    'f3 f4',
    'Bd2 Bd7',
    'Ra1 g5',
    'Nb5 Rxa1',
    'Qxa1 Nc8',
    'g4 fxg3',
    'hxg3 Qf6',
    'Qc1 Qg6',
    'Nc3 h6',
    'Na1 Nf6',
    'Nc2 g4',
    'Qe1 gxf3',
    'Bxf3 Nb6',
    'Ne3 Bh3',
    'Bg2 Bxg2',
    'Nf5 Rf7',
    'Kxg2 Nxc4',
    'Qe2 Nxd2',
    'Qxd2 Nxe4',
    'Nxe4 Rxf5',
    'Rxf5 Qxf5',
    'Nxd6 Qg6',
    'Nb5 Qe4+',
    'Kh2 Qb4',
    'Qe2 c4',
    'Nc7 c3',
    'Qa6 c2',
    'Qc8+ Qf8',
    'Ne8 Qxe8',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 e6',
    'e3 Nbd7',
    'b3 Bb4',
    'Bd2 O-O',
    'Bd3 c5',
    'cxd5 cxd4',
    'Nxd4 Ne5',
    'Bc2 Nxd5',
    'Nxd5 Bxd2+',
    'Qxd2 Qxd5',
    'f4 Ng6',
    'Nf3 Qxd2+',
    'Kxd2 Rd8+',
    'Ke2 b6',
    'Be4 Ba6+',
    'Kf2 Rac8',
    'Rad1 Ne7',
    'Nd4 Nd5',
    'Bf3 h6',
    'a3 Rc3',
    'Rc1 Rdc8',
    'Rxc3 Rxc3',
    'f5 Nxe3',
    'fxe6 fxe6',
    'Re1 Nf5',
    'Nxf5 exf5',
    'Re7 Bd3',
    'Rxa7 Be4',
    'Bxe4 fxe4',
    'b4 b5',
    'Ra5 Kf7',
    'Rxb5 e3+',
    'Ke2 Rxa3',
    'h4 Ra2+',
    'Kxe3 Rxg2',
    'h5 Rg4',
    'Kf3 Rc4',
  ],
  [
    'd4 d5',
    'c4 dxc4',
    'e4 e5',
    'Nf3 exd4',
    'Bxc4 Bb4+',
    'Bd2 Bxd2+',
    'Nbxd2 Qf6',
    'O-O Ne7',
    'Nb3 Nbc6',
    'Nfxd4 O-O',
    'Nxc6 Nxc6',
    'Qc2 a5',
    'a4 Qe7',
    'f3 Rd8',
    'Rfd1 Bd7',
    'Bd5 Qb4',
    'Qc5 Be8',
    'Qxb4 axb4',
    'a5 Kf8',
    'Rdc1 Ra6',
    'Rc4 Rda8',
    'Kf2 R8a7',
    'Ke3 Nxa5',
    'Rxa5 Rxa5',
    'Nxa5 c6',
    'Nxc6 bxc6',
    'Bxc6 Bxc6',
    'Rxc6 Ra2',
    'Rc2 b3',
    'Re2 Ra1',
    'Kd2 Ra6',
    'Kc3 Rb6',
    'Kc4 Ke7',
    'Re3 Rh6',
    'h3 Rg6',
    'Re2 Rb6',
    'f4 h5',
    'g3 h4',
    'g4 Rd6',
    'Re3 Rd2',
    'Kxb3 Rf2',
    'f5 f6',
    'e5 fxe5',
    'Rxe5+ Kf6',
    'Re3 Kg5',
    'Kc3 Kf4',
    'Rd3 Ke4',
    'b4 Rf1',
    'Kc4 Rb1',
  ],
  [
    'd4 Nf6',
    'c4 g6',
    'Nc3 Bg7',
    'e4 d6',
    'Be2 O-O',
    'Nf3 e5',
    'O-O Nc6',
    'd5 Ne7',
    'Nd2 a5',
    'Rb1 Nd7',
    'a3 f5',
    'b4 Kh8',
    'f3 axb4',
    'axb4 c6',
    'Kh1 Nf6',
    'Nb3 cxd5',
    'cxd5 f4',
    'Nb5 Nh5',
    'Bd2 Ng8',
    'Be1 Bf6',
    'Qc2 Bh4',
    'Bxh4 Qxh4',
    'Kg1 Ng3',
    'Rfe1 Rf6',
    'Qc7 Nxe4',
    'fxe4 f3',
    'Bxf3 Rxf3',
    'Rf1 Qxe4',
    'gxf3 Qe3+',
    'Kh1 Bh3',
    'Nxd6 Bxf1',
    'Nf7+ Kg7',
    'Rxf1 Qxb3',
    'Ng5+ Kh6',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'g3 d5',
    'Nf3 Be7',
    'Bg2 O-O',
    'O-O dxc4',
    'Qc2 a6',
    'Qxc4 b5',
    'Qc2 Bb7',
    'Bd2 Be4',
    'Qc1 Nbd7',
    'Ba5 Ra7',
    'Nbd2 Bd5',
    'Re1 Ne4',
    'Nxe4 Bxe4',
    'Qf4 Nf6',
    'Ne5 Bxg2',
    'Kxg2 Bd6',
    'Rac1 Nd5',
    'Qd2 Ne7',
    'Nc6 Nxc6',
    'Rxc6 Bb4',
    'Bxb4 Qd5+',
    'f3 Qxc6',
    'Rc1 Qd7',
    'Bxf8 Kxf8',
    'Rc5 Kg8',
    'Qc3 h6',
    'b4 Qd6',
    'a3 a5',
    'bxa5 Qa6',
    'Rxc7 Rxc7',
    'Qxc7 b4',
    'Kf2 bxa3',
    'Qb8+ Kh7',
    'Qb4 a2',
    'Qa4 Qd6',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nc3 Bb4',
    'e3 b6',
    'Bd3 Bb7',
    'Nf3 O-O',
    'O-O Bxc3',
    'bxc3 Be4',
    'Be2 c5',
    'Nd2 Bb7',
    'f3 d5',
    'Nb3 dxc4',
    'Bxc4 Qc7',
    'Qe2 Nbd7',
    'Bb2 Rac8',
    'Nd2 Ne8',
    'Bd3 Nd6',
    'c4 Rfe8',
    'Rfe1 f5',
    'Qf2 e5',
    'd5 Re7',
    'Qh4 Rce8',
    'Bc2 Nf8',
    'Rac1 Ng6',
    'Qf2 a6',
    'Bd1 Bc8',
    'Be2 Bd7',
    'Bf1 Bc8',
    'Rc3 f4',
    'Bd3 Bf5',
    'Bxf5 Nxf5',
    'exf4 exf4',
    'Rxe7 Ngxe7',
    'Ne4 Nc8',
    'Rc1 Ncd6',
    'Ng5 Re3',
    'Ne6 Qe7',
    'Ba1 h5',
    'g3 Ra3',
    'Re1 Nxc4',
    'Nxf4 Re3',
    'Ne6 Rxe1+',
    'Qxe1 Qd7',
    'Qe4 Qa4',
    'Qe2 Qb5',
    'Qd3 Nce3',
    'Qxb5 axb5',
    'Nf4 Kf7',
    'Kf2 g5',
    'Nxh5 Nxd5',
    'g4 Kg6',
    'f4 gxf4',
    'Kf3 Kg5',
    'Ng7 Nh4+',
    'Kf2 Kxg4',
    'Ne8 Nb4',
    'Nf6+ Kh3',
    'Nd7 Nd3+',
    'Kf1 f3',
    'Ne5 Nb4',
    'a3 Nc2',
    'Bc3 Ne3+',
    'Kg1 f2+',
    'Kxf2 Nd1+',
    'Ke2 Nxc3+',
    'Kd3 Na4',
    'Ke4 Ng2',
    'Kd5 Ne3+',
    'Kc6 Nc4',
    'Kxb5 Nxe5',
    'Kxa4 c4',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Qa4+ Bd7',
    'Qb3 dxc4',
    'Qxc4 Nc6',
    'd4 Bg7',
    'e4 O-O',
    'e5 Be6',
    'exf6 Bxc4',
    'fxg7 Kxg7',
    'Bxc4 Nxd4',
    'Nxd4 Qxd4',
    'Be2 Qb4',
    'O-O Rfd8',
    'Rb1 Rd7',
    'b3 Kg8',
    'Na4 Rad8',
    'a3 Qe4',
    'Be3 b6',
    'Nc3 Qe5',
    'Rbc1 Rd2',
    'Rfe1 c5',
    'Bc4 R2d3',
    'Bh6 Qh5',
    'Bxd3 Qxh6',
    'Bc4 e6',
    'a4 Rd2',
    'Ne4 Rd4',
    'Rc3 Qf4',
    'Rf3 Qb8',
    'Ng5 f5',
    'Nxe6 Rxc4',
    'bxc4 Qd6',
    'h4 Qd2',
    'Rfe3 Qc2',
    'Ng5 Qxc4',
    'Re8+ Kg7',
    'R1e7+ Kf6',
  ],
  [
    'Nf3 Nf6',
    'c4 g6',
    'Nc3 d5',
    'Qa4+ Bd7',
    'Qb3 dxc4',
    'Qxc4 Nc6',
    'e4 e5',
    'Qb3 Bg7',
    'd3 O-O',
    'Be3 Qe7',
    'Be2 b6',
    'O-O h6',
    'Qc2 Kh7',
    'a3 Nd8',
    'd4 exd4',
    'Bxd4 c5',
    'Bxf6 Qxf6',
    'Rad1 Be6',
    'e5 Qe7',
    'Qe4 Rc8',
    'Nd5 Bxd5',
    'Rxd5 Ne6',
    'h4 h5',
    'Rd6 Nd4',
    'Re1 Rcd8',
    'Ng5+ Kg8',
    'Bc4 Rxd6',
    'exd6 Qxd6',
    'Nxf7 Qf6',
    'Ng5+ Kh8',
    'Rf1 Qc6',
    'Bd5 Ne2+',
    'Kh1 Ng3+',
    'fxg3 Rxf1+',
    'Kh2 Qf6',
    'Qe8+ Bf8',
    'Bf7 Qe7',
    'Bxg6 Qxe8',
    'Bxe8 Rb1',
    'Bxh5 Rxb2',
    'Bf7 Bd6',
    'Ne4 Be5',
    'Kh3 Re2',
    'Bd5 c4',
    'Ng5 c3',
    'Bb3 Kg7',
    'Nf3 Bf6',
    'g4 c2',
    'Bxc2 Rxc2',
    'g5 Bb2',
    'h5 Rc4',
    'g4 Rc3',
    'Kg2 Rxf3',
    'Kxf3 Bxa3',
    'Ke4 Bc1',
    'Kf5 a5',
    'g6 a4',
    'g5 Bb2',
  ],
  [
    'd4 Nf6',
    'c4 c5',
    'd5 b5',
    'cxb5 a6',
    'b6 d6',
    'Nc3 Nbd7',
    'Nf3 g6',
    'e4 Bg7',
    'Be2 O-O',
    'O-O Rb8',
    'Nd2 Nxb6',
    'a4 Nfd7',
    'Kh1 Kh8',
    'a5 Na8',
    'Nc4 Nc7',
    'Na4 Nb5',
    'Nab6 Nd4',
    'Bd3 Bb7',
    'Bg5 Nf6',
    'Rb1 e6',
    'Ne3 h6',
    'Bh4 g5',
    'Bg3 Nxe4',
    'Bxe4 f5',
    'Bd3 f4',
    'Qh5 Qe8',
    'Bg6 Qe7',
    'Ng4 fxg3',
    'Nxh6 Bxh6',
    'Qxh6+ Kg8',
    'dxe6 Nxe6',
    'fxg3 Nd4',
    'Bd3 d5',
    'Rfe1 Qg7',
    'Qxg7+ Kxg7',
    'Nd7 c4',
    'Re7+ Kh8',
    'Nxb8 cxd3',
    'Rxb7 d2',
    'Re7 Rc8',
    'Rf1 Rc1',
    'Re8+ Kg7',
    'Re7+ Kg6',
    'Rf7 d1=Q',
    'Rf6+ Kh5',
    'g4+ Kxg4',
    'h3+ Kg3',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'g3 Bb4+',
    'Bd2 Be7',
    'Bg2 d5',
    'Nf3 O-O',
    'O-O c6',
    'Qc2 b6',
    'Bf4 Bb7',
    'Nc3 Nbd7',
    'Rfd1 Rc8',
    'b3 c5',
    'cxd5 cxd4',
    'd6 Rxc3',
    'dxe7 Qxe7',
    'Bd6 Rxc2',
    'Bxe7 Bxf3',
    'Bxf3 Re8',
    'Bxf6 Nxf6',
    'Rxd4 e5',
    'Ra4 e4',
    'Bg2 Re7',
    'Rd1 g6',
    'Kf1 e3',
    'fxe3 Kg7',
    'Rd3 h5',
    'Bf3 Rec7',
    'h4 Nd7',
    'Rad4 Ne5',
    'Rd2 R2c3',
    'Kf2 Rc2',
    'Rxc2 Rxc2',
    'Ra4 a5',
    'Be4 Rc1',
    'Bf3 Rc2',
    'Kf1 Kf8',
    'Ke1 Rc1+',
    'Kf2 Ke7',
    'b4 Rc4',
    'a3 Ng4+',
    'Ke1 Kd6',
    'Kd2 f5',
    'Bb7 Nf6',
    'Kd3 Rg4',
    'Bf3 Rxg3',
    'bxa5 bxa5',
    'Rxa5 Nd7',
    'Kd4 Rh3',
    'Ra6+ Ke7',
    'Rxg6 Rxh4+',
    'Kd5 Ra4',
    'Rg7+ Kd8',
    'Kd6 Nf6',
    'Bc6 Ra6',
    'Rb7 Ne4+',
    'Kd5 Rxa3',
    'Kd4 Ng3',
    'Rg7 Nxe2+',
    'Ke5 Rd3',
    'Bd5 Nc3',
    'Be6 Rxe3+',
    'Kf6 Ne4+',
    'Kxf5 Nd6+',
    'Kg5 Rxe6',
    'Kxh5 Re1',
    'Ra7 Ne4',
    'Kg4 Rf1',
    'Kh5 Ke8',
    'Rb7 Nd6',
    'Ra7 Rg1',
    'Kh4 Kf8',
    'Kh3 Nf7',
    'Ra3 Ke7',
    'Rg3 Re1',
    'Kg4 Ke6',
    'Ra3 Ne5+',
    'Kg5 Rg1+',
    'Kh4 Kf5',
    'Ra4 Ng6+',
    'Kh3 Nf4+',
    'Kh2 Rg2+',
    'Kh1 Rg4',
    'Kh2 Rg2+',
    'Kh1 Rb2',
    'Kg1 Kg4',
    'Kf1 Rh2',
    'Ke1 Kf3',
    'Ra3+ Ke4',
    'Kf1 Nd3',
    'Ra8 Rf2+',
    'Kg1 Rb2',
    'Kf1 Rf2+',
    'Kg1 Rf7',
    'Kg2 Kf4',
    'Ra4+ Ke3',
    'Kg3 Rf8',
    'Kg4 Nf2+',
    'Kh5 Rg8',
    'Ra6 Nh3',
    'Ra3+ Kf4',
    'Ra4+ Kg3',
    'Ra3+ Kg2',
    'Ra2+ Nf2',
    'Ra3 Ne4',
    'Kh6 Nd6',
    'Kh5 Nc4',
    'Rc3 Ne5',
    'Ra3 Nf3',
    'Kh6 Kg3',
    'Ra4 Ne5',
    'Kh5 Nf7',
    'Rg4+ Rxg4',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 e6',
    'Bg5 h6',
    'Bh4 dxc4',
    'e4 b5',
    'e5 g5',
    'Bg3 Nd5',
    'Nd2 Nd7',
    'Be2 Bb7',
    'a4 Qb6',
    'Nde4 a5',
    'O-O Rd8',
    'Bh5 b4',
    'Qg4 Nc7',
    'Nd6+ Bxd6',
    'exd6 Nf6',
    'Qf3 Nxh5',
    'dxc7 Rxd4',
    'Qxh5 bxc3',
    'bxc3 Rd3',
    'Rab1 Qa6',
    'Be5 Rh7',
    'f4 c5',
    'fxg5 Rd2',
    'Rxb7 Qxb7',
    'Qf3 Rd5',
  ],
  [
    'e4 c5',
    'Nf3 e6',
    'd4 cxd4',
    'Nxd4 Nf6',
    'Nc3 d6',
    'Bc4 Nc6',
    'Be3 Be7',
    'Bb3 O-O',
    'Qe2 a6',
    'O-O-O Qc7',
    'g4 Na5',
    'g5 Nd7',
    'h4 b5',
    'g6 Nc5',
    'gxf7+ Rxf7',
    'Kb1 Naxb3',
    'Nxb3 b4',
    'Nxc5 bxc3',
    'Nb3 a5',
    'Nd4 Qb7',
    'Bc1 cxb2',
    'Bg5 Bf8',
    'Rhg1 a4',
    'f4 Ra5',
    'h5 a3',
    'e5 dxe5',
    'fxe5 Qa6',
    'Qe3 Qc4',
    'Bf6 Rd5',
    'Rg4 Rfd7',
    'c3 Ba6',
    'Rg2 Rb7',
    'Rdg1 Qd3+',
    'Qxd3 Bxd3+',
    'Nc2 Be4',
    'Re2 Bf5',
    'c4 Rc5',
    'Rf2 Rxc4',
    'Rxf5 exf5',
    'e6 Rg4',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 e6',
    'e3 Nbd7',
    'Bd3 dxc4',
    'Bxc4 b5',
    'Bd3 Bd6',
    'O-O Bb7',
    'e4 e5',
    'Ne2 O-O',
    'Qc2 Re8',
    'Bg5 h6',
    'Bh4 Qb6',
    'Rad1 c5',
    'dxe5 Bxe5',
    'Nxe5 Rxe5',
    'Nc3 c4',
    'Be2 Rae8',
    'Bf3 a6',
    'Bg3 R5e6',
    'Nd5 Bxd5',
    'exd5 R6e7',
    'b3 Rc8',
    'd6 Ree8',
    'Rfe1 Rxe1+',
    'Rxe1 c3',
    'b4 a5',
    'a3 axb4',
    'axb4 Qd4',
    'Bb7 Rb8',
    'Bc6 Qxb4',
    'Re7 Qb2',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nf3 Nf6',
    'Nc3 e6',
    'Bg5 h6',
    'Bh4 dxc4',
    'e4 g5',
    'Bg3 b5',
    'Be2 Nbd7',
    'd5 Nc5',
    'dxc6 Qxd1+',
    'Rxd1 a6',
    'Be5 Be7',
    'h4 g4',
    'Nd4 O-O',
    'f3 gxf3',
    'gxf3 Ne8',
    'Kf2 f6',
    'Bg3 e5',
    'Nf5 Bxf5',
    'exf5 Ng7',
    'Rhg1 Kh8',
    'Bh2 Nxf5',
    'Nd5 Rac8',
    'f4 Rxc6',
    'Bg4 Nd3+',
    'Kf1 Rd6',
    'Nc3 Ne3+',
    'Ke2 Nxd1',
    'Rxd1 Nxf4+',
    'Bxf4 Rxd1',
    'Kxd1 exf4',
    'Ke2 f5',
    'Bh5 Bf6',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Nc3 e6',
    'g4 Bg6',
    'Nge2 f6',
    'h4 Nd7',
    'f4 Bf7',
    'Be3 h5',
    'g5 Ne7',
    'exf6 gxf6',
    'Ng3 Qc7',
    'Qf3 f5',
    'O-O-O Bg6',
    'Kb1 Nc8',
    'Nce2 Nd6',
    'Nc1 Ne4',
    'c4 O-O-O',
    'cxd5 exd5',
    'Nd3 Bg7',
    'Bh3 Rdf8',
    'Ka1 Kb8',
    'Rhf1 Ka8',
    'Ne5 Nxe5',
    'fxe5 Rf7',
    'Bf4 Nxg3',
    'Qxg3 Qe7',
    'Rfe1 Qe6',
    'Rc1 a6',
    'Rc3 Rc8',
    'Rec1 Bf8',
    'Bf1 Rfc7',
    'Be3 Bb4',
    'R3c2 f4',
    'Bxf4 c5',
    'Bh3 Bxc2',
    'Rxc2 Qg6',
    'Rc1 cxd4',
    'Bxc8 Rxc1+',
    'Bxc1 Qc2',
    'Qg1 Qxc8',
    'g6 Bf8',
    'e6 d3',
    'e7 Bxe7',
    'g7 Qg8',
    'Qd4 Qf7',
    'a3 Bxh4',
    'Be3 Kb8',
  ],
  [
    'e4 c6',
    'd4 d5',
    'e5 Bf5',
    'Nc3 e6',
    'g4 Bg6',
    'Nge2 c5',
    'h4 h5',
    'Nf4 Bh7',
    'Nxh5 Nc6',
    'dxc5 Bxc5',
    'Bb5 d4',
    'Bxc6+ bxc6',
    'Qf3 Qd7',
    'Ne4 Bb4+',
    'Bd2 Bxe4',
    'Qxe4 Bxd2+',
    'Kxd2 Kf8',
    'Rad1 Rb8',
    'Kc1 c5',
    'Rh3 Ne7',
    'Nf4 Qc6',
    'Qe2 Nd5',
    'Nxd5 Qxd5',
    'c4 Qd8',
    'Qe4 Rb4',
    'b3 Rb8',
    'f4 a5',
    'Qc6 Rc8',
    'Qb7 a4',
    'f5 exf5',
    'gxf5 Qc7',
    'Qxc7 Rxc7',
    'h5 axb3',
    'axb3 Re7',
    'Re1 Rh6',
    'Kc2 Ke8',
    'Kd3 g6',
    'Ke4 d3',
    'Rxd3 gxf5+',
    'Kf4 Rxh5',
    'Ree3 Rc7',
    'Rh3 Rxh3',
    'Rxh3 Ke7',
    'Kxf5 Rd7',
    'Ke4 Rd4+',
    'Kf5 Rd1',
    'Rf3 Rd2',
    'Ke4 Rd4+',
    'Ke3 Rd8',
    'Ke4 Rd4+',
    'Kf5 Rd1',
    'Re3 Rd8',
    'Rh3 Rd1',
    'Rg3 Rd2',
    'Rg8 Rf2+',
    'Ke4 Rd2',
    'Rc8 Rb2',
    'Rb8 Rd2',
    'Rb5 Rd4+',
    'Ke3 Rh4',
    'Kd3 Rh3+',
    'Kc2 Rh2+',
    'Kc3 Rh3+',
    'Kb2 Kd7',
    'Rxc5 Rh5',
    'b4 Rh3',
    'b5 Rf3',
    'b6 Ke8',
  ],
  [
    'Nf3 Nf6',
    'c4 e6',
    'g3 d5',
    'd4 Bb4+',
    'Bd2 Be7',
    'Bg2 c6',
    'O-O O-O',
    'Bf4 b6',
    'Nc3 Ba6',
    'Ne5 Nfd7',
    'cxd5 Nxe5',
    'd6 Qxd6',
    'Bxe5 Qd7',
    'Rc1 Bb7',
    'Qa4 Rd8',
    'Rfd1 Na6',
    'a3 Nc7',
    'Bxc7 Qxc7',
    'd5 b5',
    'Nxb5 cxb5',
    'Rxc7 bxa4',
    'Rxb7 Bf6',
    'd6 Kf8',
    'e3 a5',
    'Rb5 Ra6',
    'd7 Ke7',
    'Rb7 Rd6',
    'Rxd6 Kxd6',
    'Rb5 Rxd7',
    'Rxa5 Rc7',
    'Be4 Rc4',
    'Bd3 Rc1+',
    'Kg2 Bxb2',
    'Rxa4 Rc3',
    'Ra6+ Rc6',
    'Rxc6+ Kxc6',
    'Bxh7 Kd6',
    'a4 g6',
    'a5 Ba3',
    'a6 Kd5',
  ],
  [
    'Nf3 Nf6',
    'c4 e6',
    'g3 d5',
    'd4 Bb4+',
    'Bd2 Be7',
    'Bg2 c6',
    'Qc2 O-O',
    'O-O b6',
    'Rd1 Ba6',
    'Ne5 Nfd7',
    'Nxd7 Nxd7',
    'b3 Rc8',
    'Bf4 Nf6',
    'Nd2 c5',
    'e3 cxd4',
    'exd4 Bb4',
    'Qb2 dxc4',
    'Nxc4 Nd5',
    'Rac1 Nxf4',
    'gxf4 Bxc4',
    'bxc4 Bd6',
    'f5 Qh4',
    'h3 exf5',
    'Qb5 Qf6',
    'c5 Bf4',
    'Rc4 Bc7',
    'c6 Rfe8',
    'd5 Rcd8',
    'Qa4 a5',
    'Qc2 g6',
    'Qd2 Qd6',
    'Rd4 h5',
    'Kf1 b5',
    'Qc2 b4',
    'Qc4 Bb6',
    'R4d2 Bc7',
    'Rc2 Re7',
    'Qc5 Qe5',
    'Qd4 Qd6',
    'Bf3 Ree8',
    'Qc5 Qf4',
    'Bg2 Kh7',
    'Qa7 Qd6',
    'Qd4 Rb8',
    'Qc5 Re7',
    'Qxd6 Bxd6',
    'Rb1 a4',
    'Rc4 a3',
    'Bf3 Kg7',
    'Rb3 Kf6',
    'Rc2 Rc7',
  ],
  [
    'd4 d5',
    'c4 c6',
    'Nc3 Nf6',
    'Nf3 e6',
    'e3 Nbd7',
    'Qc2 Bd6',
    'e4 dxe4',
    'Nxe4 Nxe4',
    'Qxe4 Bb4+',
    'Bd2 Bxd2+',
    'Nxd2 c5',
    'dxc5 Qa5',
    'a3 Qxc5',
    'b4 Qe7',
    'Bd3 a5',
    'Rb1 axb4',
    'axb4 Nf6',
    'Qe3 O-O',
    'O-O Rd8',
    'Nf3 Qc7',
    'Ne5 b6',
    'Rfd1 Bb7',
    'Bf1 Be4',
    'Nd3 Ra3',
    'Ra1 Rc3',
    'Qf4 Qxf4',
    'Nxf4 Rxd1',
    'Rxd1 g5',
    'Nh3 g4',
    'Nf4 Kg7',
    'h3 h5',
    'hxg4 hxg4',
    'Rd6 e5',
    'Ne2 Rxc4',
    'Rxb6 Bd3',
    'Ng3 Rc1',
    'Rd6 e4',
    'Nf5+ Kg6',
    'Ne3 g3',
    'fxg3 Bxf1',
    'Kf2 Bd3',
    'Nd5 Kg5',
  ],
  [
    'd4 Nf6',
    'c4 e6',
    'Nf3 d5',
    'g3 Bb4+',
    'Bd2 Be7',
    'Bg2 O-O',
    'O-O Nbd7',
    'Qb3 Nb6',
    'c5 Nc4',
    'Qc2 b6',
    'b3 Nxd2',
    'Nbxd2 bxc5',
    'dxc5 c6',
    'b4 Qc7',
    'e4 a5',
    'a3 Ba6',
    'Rfe1 Rfb8',
    'e5 Nd7',
    'Qc3 Bb5',
    'Nd4 Nxe5',
    'Nxb5 cxb5',
    'Rxe5 Bf6',
    'f4 axb4',
    'axb4 Ra4',
    'Rb1 Rba8',
    'Bf1 Ra2',
    'Bxb5 R8a3',
    'Qd4 Rc2',
    'Bf1 Raa2',
    'Nf3 Qa7',
    'Rd1 Qa3',
    'Qxd5 g6',
    'Rxe6 Qb2',
    'Re8+ Kg7',
    'Rd2 Qxb4',
    'Qxa2 Qxc5+',
    'Kg2 Rxa2',
    'Rxa2 Qd5',
    'Rae2 Bc3',
    'h4 h6',
    'R8e3 Bb4',
    'Rc2 Qd1',
    'Rce2 Qd5',
  ],
  [
    'e4 e5',
    'Nf3 Nf6',
    'Nxe5 d6',
    'Nf3 Nxe4',
    'd4 d5',
    'Bd3 Bd6',
    'O-O O-O',
    'Re1 Bf5',
    'c4 c6',
    'cxd5 cxd5',
    'Nc3 Nxc3',
    'bxc3 Bxd3',
    'Qxd3 Nd7',
    'Qf5 Nf6',
    'Bg5 Qc8',
    'Nh4 Ne4',
    'Qxd5 Nxg5',
    'Qxg5 Qd8',
    'Qg4 g6',
    'g3 Rc8',
    'Qf3 Qa5',
    'Re3 Rc7',
    'Rae1 Qxa2',
    'Qf6 Qd5',
    'Nf5 Qxf5',
    'Qxd6 Qc8',
    'Re8 Rxe8',
    'Rxe8+ Qxe8',
    'Qxc7 Qe1+',
    'Kg2 Qe4+',
    'Kg1 Kg7',
    'c4 Qxd4',
    'Qxb7 Qc5',
    'Qa6 Kf8',
    'h4 h5',
    'Kf1 Ke7',
    'Ke2 Kd7',
    'Kf1 Kc7',
    'Kg1 Qd4',
    'Qa5+ Kd6',
    'Qb4+ Qc5',
    'Qb8+ Ke6',
    'Qe8+ Kf6',
    'Qd8+ Kg7',
    'Qd7 Kf6',
    'Qd8+ Ke5',
    'Qe8+ Kd4',
    'Qxf7 a5',
    'Qxg6 a4',
    'g4 a3',
    'gxh5 a2',
    'Qg7+ Kxc4',
    'Qb2 Qa5',
    'Qc2+ Kd4',
    'Qb2+ Ke4',
    'Qe2+ Kf4',
    'Qe3+ Kg4',
  ],
];
